import { Statuses } from './Statuses';
import { observer } from 'mobx-react';
import { useStore } from '../../../../../hooks/useStore';
import { useState } from 'react';
import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  updateDoc,
} from 'firebase/firestore';
import { StatusEditDrawer } from '../StatusEditDrawer/StatusEditDrawer';
import { SettingObjectRoutes } from 'App/Settings/common';
import { statusesAtomFamily } from 'atoms/firestore/customStatuses';
import { db } from '../../../../../firebase';
import { useAtomValue } from 'jotai';
import { CustomStatus, MessageStatus } from 'lib';
import { Button, Loading } from 'components/basics';
import { Checkmark } from 'components/basics/Checkmark/Checkmark';
import { useSignInCompanyStripeProduct } from 'atoms/firestore/signInCompanyStripeProduct';
import StatusesImage from './images/StatusesImage.png';

type Props = {
  teamId: string;
  routes?: SettingObjectRoutes<{ teamId: string }, 'signatureId'>;
};

const StatusesSettings = observer(({ teamId, routes }: Props) => {
  const store = useStore();
  const readonly = store.me.isReadOnly;
  const [openDrawer, setOpenDrawer] = useState(false);
  // drawerを開く際に表示するstatus
  const [selectedStatusForDrawer, setSelectedStatusForDrawer] =
    useState<CustomStatus | null>(null);

  const [loading, statuses] = useAtomValue(statusesAtomFamily(teamId));
  const customStatusesRefString = `companies/${store.company.id}/teams/${teamId}/customStatuses`;

  const onEdit = async (
    id: CustomStatus['id'] | null,
    data: Omit<CustomStatus, 'id'>
  ) => {
    const isCreated = !id;

    if (isCreated) {
      db.collection(customStatusesRefString)
        .add(data)
        .catch(function (error) {
          console.error('Error adding document: ', error);
        });
      return;
    }

    const updateRef = doc(db, customStatusesRefString, id);
    await updateDoc(updateRef, data);
  };

  const onDelete = async (id: string) => {
    await deleteDoc(doc(db, customStatusesRefString, id));
    setOpenDrawer(false);
  };

  const handleOpenDraw = async (status: CustomStatus | string) => {
    setOpenDrawer(true);

    if (typeof status === 'string') {
      let systemStatus = statuses.find((s) => s.name === status);
      if (!systemStatus) {
        const newStatus = {
          name: status,
          nextStatus: null,
          order: 0,
        };
        const docRef = await addDoc(
          collection(db, customStatusesRefString),
          newStatus
        );

        systemStatus = {
          id: docRef.id,
          ...newStatus,
        };
      }

      setSelectedStatusForDrawer(systemStatus);
      return;
    }

    setSelectedStatusForDrawer(status);
  };

  const handleDrag = async (data: CustomStatus[]) => {
    data.forEach((item, index) => {
      db.collection(customStatusesRefString)
        .doc(item.id)
        .update({ order: index });
    });
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Statuses
        statuses={statuses}
        onCreate={(formData) => onEdit(null, formData)}
        readonly={store.me.isReadOnly}
        supported={store.featureStore.isCustomStatusSupported}
        maxStatuses={3}
        onOpenDrawer={handleOpenDraw}
        onDrag={handleDrag}
      />
      <StatusEditDrawer
        statuses={statuses}
        status={selectedStatusForDrawer}
        isSystemStatus={
          selectedStatusForDrawer?.name === MessageStatus.Unprocessed
        }
        onUpdate={onEdit}
        onDelete={onDelete}
        open={openDrawer}
        onOpenChange={() => setOpenDrawer(!openDrawer)}
        readonly={readonly}
      />
    </>
  );
});

export const StatusesWithLogic = ({ teamId, routes }: Props) => {
  const [showStatusesSettings, setStatusesSettings] = useState(false);
  const [stripeProductData] = useSignInCompanyStripeProduct();
  const canAddStatus = useMemo(() => {
    return (
      stripeProductData?.plan === 'business' ||
      stripeProductData?.plan === 'enterprise'
    );
  }, [stripeProductData]);
  const history = useHistory();

  const statusesGuideMessage = () => (
    <>
      <div className="mb-4 text-base font-bold">
        ステータスのカスタマイズを使いこなしましょう！
      </div>
      <Checkmark>
        「対応中」「保留」など利用用途に応じたステータスをカスタマイズできるようになります
      </Checkmark>
      <Checkmark className="mb-4">
        ステータスを細かく管理することで、さらに効果的なメールの管理ができるようになります
      </Checkmark>
      <img
        src={StatusesImage}
        className="mb-4 block"
        alt="ステータスのカスタマイズ"
      />
    </>
  );

  if (showStatusesSettings) {
    return <StatusesSettings teamId={teamId} />;
  } else {
    return (
      <div className="text-sm">
        {statusesGuideMessage()}
        {canAddStatus ? (
          <Button onClick={() => setStatusesSettings(true)}>
            ステータスのカスタマイズを設定
          </Button>
        ) : (
          <Button onClick={() => history.push('/settings/company/payments')}>
            プランをアップグレード
          </Button>
        )}
      </div>
    );
  }
};
