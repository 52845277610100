import * as React from 'react';
import type { SVGProps } from 'react';
const SvgAddLink = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M17.038 16.538h-2.236a.69.69 0 0 1-.518-.215.74.74 0 0 1-.207-.535.73.73 0 0 1 .216-.53.718.718 0 0 1 .534-.22h2.211v-2.211c0-.213.072-.39.216-.535a.727.727 0 0 1 .535-.215c.212 0 .39.072.534.215a.726.726 0 0 1 .215.535v2.211h2.212c.212 0 .39.072.534.216a.726.726 0 0 1 .216.535c0 .212-.072.39-.216.534a.726.726 0 0 1-.534.215h-2.212v2.212c0 .212-.071.39-.215.534a.726.726 0 0 1-.535.216.725.725 0 0 1-.534-.216.726.726 0 0 1-.216-.534v-2.212Zm-6.98 0h-3.02c-1.255 0-2.325-.442-3.21-1.327C2.942 14.326 2.5 13.256 2.5 12c0-1.256.442-2.326 1.328-3.212.885-.885 1.955-1.328 3.21-1.328h3.02c.206 0 .383.074.53.22.146.148.22.326.22.535 0 .21-.074.387-.22.53a.73.73 0 0 1-.53.215h-3.02c-.84 0-1.556.297-2.149.89A2.928 2.928 0 0 0 4 12c0 .84.296 1.556.89 2.149.592.593 1.308.89 2.147.89h3.02c.207 0 .384.073.53.22.147.146.22.325.22.534 0 .21-.073.386-.22.53a.73.73 0 0 1-.53.215ZM9 12.75a.725.725 0 0 1-.534-.216A.726.726 0 0 1 8.25 12c0-.213.072-.391.216-.535A.726.726 0 0 1 9 11.25h6c.213 0 .39.072.534.216a.726.726 0 0 1 .216.534c0 .213-.072.39-.216.534a.726.726 0 0 1-.534.216H9ZM21.5 12H20c0-.84-.297-1.556-.89-2.15a2.926 2.926 0 0 0-2.147-.889h-3.046a.69.69 0 0 1-.517-.215.74.74 0 0 1-.208-.535.73.73 0 0 1 .216-.53.718.718 0 0 1 .534-.22h3.02c1.255 0 2.326.443 3.21 1.328.886.885 1.328 1.955 1.328 3.21Z"
    />
  </svg>
);
export default SvgAddLink;
