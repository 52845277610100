import { tv } from 'tailwind-variants';
import { Radio } from '../Radio/Radio';

type Option<T> = {
  value: T;
  label: string;
};

type Props<T> = {
  options: Option<T>[];
  value: T | undefined;
  onChange: (value: T) => void;
  direction?: 'row' | 'column';
  disabled?: boolean;
};

const wrapper = tv({
  base: 'flex gap-4',
  variants: {
    direction: {
      row: 'w-full flex-row',
      column: 'flex-col',
    },
  },
});

export const RadioSelect = <T,>({
  options,
  value,
  onChange,
  direction = 'row',
  disabled,
}: Props<T>) => {
  return (
    <div className={wrapper({ direction })}>
      {options.map((option, i) => (
        <div key={i} className="w-full">
          <Radio
            label={option.label}
            checked={option.value === value}
            onCheck={() => onChange(option.value)}
            disabled={disabled}
          />
        </div>
      ))}
    </div>
  );
};
