import React from 'react';
import SplitPane from 'react-split-pane';
import './PCSplitPane.css';
import lStorage, { storageKeys } from '../../../localStorage';
import { isSP } from 'shared/util';

type Props = {
  preferenceKey: string;
  defaultWidth: number;
  minWidth: number;
  maxWidth: number;
  children?: React.ReactNode;
};

export const PCSplitPane = ({
  preferenceKey,
  defaultWidth,
  minWidth,
  maxWidth,
  children,
}: Props) => {
  const localPreferences = lStorage.getObject(storageKeys.localPreferences);

  const width =
    localPreferences?.paneWidths?.[preferenceKey] ||
    Math.max(defaultWidth, minWidth);

  const saveWidth = (width: number) => {
    const localPreferences = lStorage.getObject(storageKeys.localPreferences);
    const newLocalPreferences = {
      ...localPreferences,
      paneWidths: {
        ...localPreferences?.paneWidths,
        [preferenceKey]: Math.round(width),
      },
    };
    lStorage.setObject(storageKeys.localPreferences, newLocalPreferences);
  };

  // refactor: App/Top.tsx の方でrefactorを行えばこちらのif文が不要となる。
  // また、コンポーネントに明確とPCと記載があるため、この分岐は不要と思われる。
  if (isSP()) {
    return <>{children}</>;
  }

  return (
    <SplitPane
      split="vertical"
      defaultSize={width}
      maxSize={maxWidth}
      minSize={minWidth}
      onDragFinished={saveWidth}
      style={{ overflow: 'auto' }}
      pane1Style={{ zIndex: 2 }}
      className={'z-[0]'}
    >
      {children}
    </SplitPane>
  );
};
