type Props = {
  children?: React.ReactNode;
};

const MessageListWrapper = ({ children }: Props) => {
  return (
    <div className="h-full w-full overflow-auto px-3 sm:pl-4 sm:pr-0">
      {children}
    </div>
  );
};

export default MessageListWrapper;
