import { SettingObjectRoutes } from '../../../common';
import { observer } from 'mobx-react';
import { useStore } from '../../../../../hooks/useStore';
import { useToast } from '../../../../../hooks/useToast';
import { Tags } from './Tags';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { TagEditDrawer, TagUpdate } from '../TagEditDrawer/TagEditDrawer';
import { ComponentProps } from 'react';
import { TagColor } from 'lib';
import { Store } from '../../../../../store';
import {
  addDoc,
  deleteDoc,
  serverTimestamp,
  updateDoc,
} from 'firebase/firestore';
import { eventNames, logEvent } from '../../../../../analytics';

type Props = {
  teamId: string;
  routes: SettingObjectRoutes<{ teamId: string }, 'tagId'>;
};

export const TagsWithLogic = observer(({ teamId, routes }: Props) => {
  const store = useStore();
  const readonly = store.me.isReadOnly;
  const { showToast } = useToast();

  const handleUpdate = async (id: string | undefined, update: TagUpdate) => {
    const data = {
      name: update.name,
      color: update.color,
      parentTagId: update.parentId,
      teamId,
    };
    if (id) {
      await updateDoc(store.doc('tags', id), {
        ...data,
        updatedAt: serverTimestamp(),
      })
        .then(() => {
          showToast('success', 'タグを更新しました');
          routes.toIndex({ teamId });
        })
        .catch((e) => {
          console.error(e);
          showToast('error', 'タグの更新に失敗しました');
        });
    } else {
      await addDoc(store.collection('tags'), {
        ...data,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      })
        .then(() => {
          logEvent(eventNames.add_tag);
          showToast('success', 'タグを作成しました');
          routes.toIndex({ teamId });
        })
        .catch((e) => {
          console.error(e);
          showToast('error', 'タグの作成に失敗しました');
        });
    }
  };

  const handleDelete = async (id: string) => {
    await deleteDoc(store.doc('tags', id))
      .then(() => {
        showToast('success', 'タグを削除しました');
      })
      .catch((e) => {
        console.error(e);
        showToast('error', 'タグの削除に失敗しました');
      });
  };

  const parentTargetTags: ComponentProps<
    typeof TagEditDrawer
  >['parentTargetTags'] = store
    .getTags(teamId)
    .filter((t) => t.parentTagId == null)
    .map((t) => ({ id: t.id, name: t.name, color: t.color as TagColor }));
  return (
    <>
      <Tags
        tags={store.getTags(teamId).map((t) => {
          const parentName = t.parentTagId
            ? store.getTag(t.parentTagId)?.name
            : null;
          return {
            id: t.id,
            name: [parentName, t.name].filter((s) => !!s).join('/'),
            color: t.color as TagColor,
            isDefault: t.isDefault,
          };
        })}
        onOpenCreateDrawer={() => routes.toNew({ teamId })}
        onOpenEditDrawer={(id) => routes.toDetail({ teamId, tagId: id })}
        loading={store.tagsLoading}
        readonly={readonly}
      />
      <Switch>
        {!readonly && (
          <Route exact path={routes.paths.new}>
            <TagEditDrawer
              tagId={undefined}
              tag={{}}
              parentTargetTags={parentTargetTags}
              onUpdate={handleUpdate}
              onDelete={handleDelete}
              open={true}
              onOpenChange={() => routes.toIndex({ teamId })}
              readonly={readonly}
            />
          </Route>
        )}
        <Route exact path={routes.paths.detail}>
          <EditDrawer
            store={store}
            path={routes.paths.detail}
            parentTargetTags={parentTargetTags}
            onUpdate={handleUpdate}
            onDelete={handleDelete}
            open={true}
            onOpenChange={() => routes.toIndex({ teamId })}
            readonly={readonly}
          />
        </Route>
      </Switch>
    </>
  );
});

type EditDrawerProps = Omit<
  ComponentProps<typeof TagEditDrawer>,
  'tag' | 'tagId'
> & {
  store: Store;
  path: string;
};

const EditDrawer = ({ store, path, ...props }: EditDrawerProps) => {
  const detailMatch = useRouteMatch<{ tagId: string }>(path);

  if (!detailMatch) {
    return null;
  }

  const tag = store.getTag(detailMatch.params.tagId);
  if (!tag) {
    return null;
  }

  return (
    <TagEditDrawer
      key={detailMatch.params.tagId}
      tagId={detailMatch.params.tagId}
      tag={{
        name: tag.name,
        color: tag.color as TagColor,
        parentId: tag.parentTagId,
      }}
      {...props}
    />
  );
};
