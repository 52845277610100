import { generateHtml, TagColor } from 'lib';
import React, { ComponentProps } from 'react';
import { Dialog } from '../../../basics/dialog/Dialog';
import * as RadixDialog from '@radix-ui/react-dialog';
import SimpleBar from 'simplebar-react';
import { DialogHeader } from '../../../basics/dialog/DialogHeader';
import { DialogContent } from '../../../basics/dialog/DialogContent';
import { Button, Icon } from '../../../basics';
import { InputGroup } from '../../../forms/InputGroup/InputGroup';
import { Tooltip } from '../../../basics/Tooltip/Tooltip';
import { Tag } from '../../../basics/Tag/Tag';
import { Attach } from '../../../icons';
import bytes from 'bytes';
import { useLoadableFunc } from '../../../../hooks/useLoadableFunc';

type Props = Pick<
  ComponentProps<typeof Dialog>,
  'open' | 'onOpenChange' | 'container' | 'modal'
> & {
  onSend?: () => Promise<void>;
  address: {
    name: string | null;
    email: string;
  };
  tags: {
    team: string;
    name: string;
    color: TagColor | null;
  }[];
  subject: string;
  html?: string;
  text: string;
  attachments: {
    name: string;
    size: number;
  }[];
  recipients: {
    total: number;
    count: number;
  };
};

export const DeliveryMessagePreviewDialog = ({
  onSend,
  address,
  tags,
  subject,
  html,
  text,
  attachments,
  recipients,
  open,
  onOpenChange,
  modal,
  container,
}: Props) => {
  const { func: handleSend, loading: sending } = useLoadableFunc(
    async () => onSend?.()
  );
  return (
    <RadixDialog.Root
      open={open}
      onOpenChange={(open) => {
        if (sending) {
          return;
        }
        onOpenChange?.(open);
      }}
      modal={modal}
    >
      <RadixDialog.Portal container={container}>
        <RadixDialog.Overlay className="fixed inset-0 z-50 bg-black/50 data-[state=open]:animate-dialog-overlay-show" />
        <RadixDialog.Content className="pointer-events-none absolute left-0 top-0 z-50 h-full w-full">
          <SimpleBar
            className="max-h-[100dvh] py-12 outline-none"
            classNames={{
              contentWrapper: 'simplebar-content-wrapper outline-none',
            }}
          >
            <div className="flex w-full justify-center">
              <div className="pointer-events-auto w-[800px] max-w-[95dvw] animate-dialog-content-show rounded bg-white text-black">
                <DialogHeader
                  title={onSend ? 'メッセージ内容の確認' : 'メッセージの内容'}
                />
                <DialogContent className="flex flex-col gap-6 text-sm text-black">
                  <InputGroup label="送信元アドレス">
                    <>
                      {address.name
                        ? `${address.name} <${address.email}>`
                        : address.email}
                    </>
                  </InputGroup>
                  <InputGroup label="送信先グループ">
                    <div className="flex gap-2">
                      {tags.map((tag, i) => (
                        <Tooltip key={i} content={tag.team}>
                          <Tag color={tag.color}>{tag.name}</Tag>
                        </Tooltip>
                      ))}
                    </div>
                  </InputGroup>
                  <InputGroup label="件名">
                    <>{subject}</>
                  </InputGroup>
                  <InputGroup label="本文">
                    <>
                      {html && (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: generateHtml({ body: html }),
                          }}
                        />
                      )}
                      {!html && <div>{text}</div>}
                    </>
                  </InputGroup>
                  <InputGroup label="添付ファイル">
                    <div className="flex flex-col gap-1.5">
                      {attachments.map((attachment, i) => (
                        <div
                          key={i}
                          className="grid grid-cols-[auto_1fr] items-center justify-start"
                        >
                          <div className="mr-1 flex h-5 w-5 items-center justify-center">
                            <Icon icon={Attach} size={20} />
                          </div>
                          <span className="truncate">
                            {attachment.name} ({bytes.format(attachment.size)})
                          </span>
                        </div>
                      ))}
                    </div>
                  </InputGroup>
                </DialogContent>
                <div className="grid h-[76px] grid-cols-[auto_auto] items-center justify-between gap-4 border-y border-b-transparent border-t-sumi-300 px-4">
                  <div className="flex flex-nowrap items-end gap-1.5 text-xs">
                    <div>{recipients.total}件中</div>
                    <div className="text-[30px] font-bold leading-8">
                      {recipients.count}
                    </div>
                    {onSend && <div>件の宛先に送信します</div>}
                    {!onSend && <div>件の宛先に送信しました</div>}
                  </div>
                  <div className="flex items-center gap-2">
                    {onSend && (
                      <>
                        <RadixDialog.Close asChild>
                          <Button disabled={sending} variant="outlined">
                            キャンセル
                          </Button>
                        </RadixDialog.Close>
                        <Button onClick={() => handleSend()} loading={sending}>
                          送信
                        </Button>
                      </>
                    )}
                    {!onSend && (
                      <RadixDialog.Close asChild>
                        <Button>閉じる</Button>
                      </RadixDialog.Close>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </SimpleBar>
        </RadixDialog.Content>
      </RadixDialog.Portal>
    </RadixDialog.Root>
  );
};
