import { TagListNode } from './LeftSidebarTagList';
import { convertTagColorToHexColor } from '../../../../color';
import { LeftSidebarInnerTagList } from './LeftSidebarInnerTagList';
import { observer } from 'mobx-react';
import { Icon } from '../../../../components/basics';
import { Tag } from '../../../../components/icons';
import { useCollapseState } from '../hooks/useCollapseState';
import { LeftSidebarCollapseWithCount } from '../button/LeftSidebarCollapseWithCount';
import { LeftSidebarLinkWithCount } from '../button/LeftSidebarLinkWithCount';

type Props = {
  node: TagListNode;
  level: number;
};

export const LeftSidebarTagListItem = observer(
  ({ node, level }: Props): JSX.Element => {
    const [open, setOpen] = useCollapseState(`tag:${node.id}`);
    const hasChildren = node.children.length > 0;
    const tag = node.tag;
    const tagIcon = hasChildren ? null : (
      <Icon
        icon={Tag}
        size={20}
        style={{ color: convertTagColorToHexColor(node.tag.color) }}
      />
    );
    const button = hasChildren ? (
      <LeftSidebarCollapseWithCount
        teamId={tag.teamId}
        tagId={tag.id}
        icon={tagIcon}
        level={level}
        label={node.name}
        active={node.selected}
        opened={open}
        onToggleOpen={setOpen}
        to={node.url}
        bold
      />
    ) : (
      <LeftSidebarLinkWithCount
        teamId={tag.teamId}
        tagId={tag.id}
        icon={tagIcon}
        level={level}
        label={node.name}
        active={node.selected}
        to={node.url}
      />
    );
    return (
      <>
        {button}
        {open && (
          <LeftSidebarInnerTagList nodes={node.children} level={level + 1} />
        )}
      </>
    );
  }
);
