import { InputGroup } from '../../../../../components/forms/InputGroup/InputGroup';
import { Input, Select } from '../../../../../components/forms';
import { Button, Icon } from '../../../../../components/basics';
import { Attach, Delete, Plus, Upload } from '../../../../../components/icons';
import { SettingWysiwygEditor } from '../../../common/SettingFields/SettingWysiwygEditor';
import React, { ReactNode, useRef, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { TemplateAttatchment } from 'lib';
import { Dialog } from '../../../../../components/basics/dialog/Dialog';
import { DialogHeader } from '../../../../../components/basics/dialog/DialogHeader';
import { DialogContent } from '../../../../../components/basics/dialog/DialogContent';
import { DialogFooter } from '../../../../../components/basics/dialog/DialogFooter';
import { uniq, uniqBy } from 'lodash';
import { useConfirmDialog } from '../../../../../hooks/confirmDialog';
import { maxRecipientsCount } from '../../../../../shared/constants';
import { validateEmail } from '../../../../../validate';

type Template = {
  id: string;
  title: string;
  subject: string;
  body: string;
  bodyHtml: string;
  attachments: TemplateAttatchment[];
  to: string;
  cc: string;
  bcc: string;
  category: string | null;
};

export type TemplateEditFormDefaultValue = Partial<Template>;

type Props = {
  template: Template | undefined;
  onUpdate: (
    id: string | undefined,
    template: Omit<Template, 'id'>
  ) => Promise<void>;
  onDelete: (templateId: string) => Promise<void>;
  onUploadAttachment: (
    file: File,
    currentAttachments: TemplateAttatchment[]
  ) => Promise<TemplateAttatchment>;
  onRemoveAttachment: (attachment: TemplateAttatchment) => Promise<void>;
  categories: string[];
  defaultValue?: TemplateEditFormDefaultValue;
  readonly: boolean;
};

export const TemplateEditForm = ({
  template,
  onUpdate,
  onDelete,
  onUploadAttachment,
  onRemoveAttachment,
  categories,
  defaultValue,
  readonly,
}: Props) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [fileUploading, setFileUploading] = useState(false);
  const [newCategoryName, setNewCategoryName] = useState('');
  const [newCategoryDialogOpen, setNewCategoryDialogOpen] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [allAddressesCount, setAllAddressesCount] = useState(0);
  const [invalidEmailsCount, setInvalidEmailsCount] = useState(0);
  const [emailDuplicateError, setEmailDuplicateError] = useState(false);
  const openDialog = useConfirmDialog();
  const { register, control, handleSubmit, setValue, watch } =
    useForm<Template>({
      defaultValues: template ?? {
        title: defaultValue?.title ?? '',
        subject: defaultValue?.subject ?? '',
        body: defaultValue?.body ?? '',
        bodyHtml: defaultValue?.bodyHtml ?? '',
        attachments: defaultValue?.attachments ?? [],
        to: defaultValue?.to ?? '',
        cc: defaultValue?.cc ?? '',
        bcc: defaultValue?.bcc ?? '',
        category: defaultValue?.category ?? null,
      },
    });
  const attachmentsField = useFieldArray({ control, name: 'attachments' });
  const handleFileChange = async (file: File) => {
    fileInputRef.current!.value = '';
    setFileUploading(true);
    try {
      const res = await onUploadAttachment(file, watch('attachments'));
      // Storybookのfnのモックが機能しないのでundefinedになる可能性がある
      if (!res) {
        return;
      }
      attachmentsField.append(res);
    } catch (e) {
      console.error(e);
    } finally {
      setFileUploading(false);
    }
  };
  const handleRemoveAttachment = async (
    index: number,
    attachment: TemplateAttatchment
  ) => {
    try {
      attachmentsField.remove(index);
      await onRemoveAttachment(attachment);
    } catch (e) {
      console.error(e);
    }
  };
  const onSubmit = async (update: Template) => {
    validate();
    if (!isValid) {
      return;
    }

    setUpdating(true);
    try {
      await onUpdate(template?.id, update);
    } catch (e) {
      console.error(e);
    } finally {
      setUpdating(false);
    }
  };
  const disabled = readonly || updating;

  const getAllAddresses = () => {
    const to = watch('to');
    const cc = watch('cc');
    const bcc = watch('bcc');
    return [...to.split(','), ...cc.split(','), ...bcc.split(',')]
      .map((s) => s.trim())
      .filter((s) => !!s);
  };

  const validate = () => {
    const allAddresses = getAllAddresses();
    setAllAddressesCount(allAddresses.length);
    setInvalidEmailsCount(allAddresses.filter((a) => !validateEmail(a)).length);
    setEmailDuplicateError(allAddresses.length !== uniq(allAddresses).length);
  };

  const isValid =
    allAddressesCount <= maxRecipientsCount &&
    invalidEmailsCount <= 0 &&
    !emailDuplicateError;
  return (
    <>
      <form
        action=""
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col gap-4 text-sm"
      >
        <Controller
          name="title"
          control={control}
          rules={{
            required: '入力してください',
          }}
          render={({
            field: { value, onChange, ref },
            fieldState: { error },
          }) => (
            <InputGroup
              label="テンプレート名"
              errorMessage={error?.message}
              required
            >
              <Input
                placeholder="問い合わせ初動対応"
                value={value}
                onChange={onChange}
                disabled={disabled}
                ref={ref}
              />
            </InputGroup>
          )}
        />
        <InputGroup
          label="件名"
          description="※空欄の場合、テンプレート使用時に件名を上書きしません。"
        >
          <Input
            placeholder="お問い合わせありがとうございます"
            disabled={disabled}
            {...register('subject')}
          />
        </InputGroup>
        <InputGroup label="本文">
          <Controller
            name="bodyHtml"
            control={control}
            render={({ field: { value, onChange } }) => (
              <SettingWysiwygEditor
                defaultValue={value}
                onChange={(text, html) => {
                  onChange(html);
                  setValue('body', text);
                }}
                placeholder="この度はyaritoriへお問い合わせ頂き誠にありがとうございます。
追って、担当の者から再度ご連絡差し上げますので今しばらくお待ちいただけますと幸いです。"
                disabled={disabled}
              />
            )}
          />
        </InputGroup>
        <InputGroup label="添付ファイル">
          <div className="flex flex-col items-start gap-2">
            <button
              type="button"
              className="flex cursor-pointer select-none items-center gap-1 rounded-lg border border-sumi-200 bg-transparent p-2 text-sm disabled:cursor-not-allowed disabled:bg-sumi-100 disabled:text-sumi-500"
              onClick={() => fileInputRef.current?.click()}
              disabled={disabled || fileUploading}
            >
              <Icon icon={Upload} size={20} />
              ファイルをアップロード
            </button>
            <div className="w-full">
              {attachmentsField.fields.map((f, i) => (
                <div
                  key={f.id}
                  className="group grid w-full grid-cols-[auto_1fr_auto] items-center gap-1"
                >
                  <Icon icon={Attach} size={18} />
                  <div className="w-full truncate whitespace-nowrap">
                    {f.name}
                  </div>
                  <button
                    type="button"
                    className="h-5 w-5 cursor-pointer items-center justify-center bg-transparent p-0 text-sumi-800 opacity-0 focus:opacity-100 disabled:hidden group-hover:opacity-100"
                    disabled={disabled}
                    onClick={() => handleRemoveAttachment(i, f)}
                  >
                    <Icon icon={Delete} size={18} />
                  </button>
                </div>
              ))}
            </div>
          </div>
        </InputGroup>
        <InputGroup
          label="To"
          description='※ "," で複数のメールアドレスを記入可能です。'
        >
          <Input
            placeholder="contact@onebox.tokyo, sales@onebox.tokyo"
            disabled={disabled}
            {...register('to', {
              onBlur: () => validate(),
            })}
          />
        </InputGroup>
        <InputGroup
          label="Cc"
          description='※ "," で複数のメールアドレスを記入可能です。'
        >
          <Input
            placeholder="contact@onebox.tokyo, sales@onebox.tokyo"
            disabled={disabled}
            {...register('cc', {
              onBlur: () => validate(),
            })}
          />
        </InputGroup>
        <InputGroup
          label="Bcc"
          description='※ "," で複数のメールアドレスを記入可能です。'
        >
          <Input
            placeholder="contact@onebox.tokyo, sales@onebox.tokyo"
            disabled={disabled}
            {...register('bcc', {
              onBlur: () => validate(),
            })}
          />
        </InputGroup>
        <InputGroup label="カテゴリー">
          <Controller
            name="category"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Select
                value={value}
                onChange={onChange}
                options={uniqBy(
                  [
                    { value: null, label: '未分類' },
                    ...categories.map((c) => ({ value: c, label: c })),
                    ...(newCategoryName.length > 0
                      ? [{ value: newCategoryName, label: newCategoryName }]
                      : []),
                  ],
                  (o) => o.value
                )}
                className="h-10"
                variants={{
                  rounded: 'lg',
                  width: 'full',
                }}
                footerElement={
                  <>
                    <div className="h-[1px] w-full bg-sumi-200" />
                    <button
                      type="button"
                      className="grid h-8 cursor-pointer select-none grid-cols-[auto_1fr] items-center gap-1 rounded-lg bg-transparent p-0 px-1.5 text-start hover:bg-sumi-100"
                      onClick={() => setNewCategoryDialogOpen(true)}
                    >
                      <Icon icon={Plus} size={14} />
                      <span>カテゴリーを追加する</span>
                    </button>
                  </>
                }
                disabled={disabled}
              />
            )}
          />
        </InputGroup>
        {allAddressesCount > maxRecipientsCount && (
          <ErrorAlert>{`To, Cc, Bccの宛先の合計は最大${maxRecipientsCount}件までです`}</ErrorAlert>
        )}
        {emailDuplicateError && (
          <ErrorAlert>
            To, Cc, Bcc内で同じメールアドレスは指定できません
          </ErrorAlert>
        )}
        {invalidEmailsCount > 0 && (
          <ErrorAlert>メールアドレスの形式が不正です</ErrorAlert>
        )}
        <div className="flex gap-2">
          {template == null ? (
            <Button type="submit">作成</Button>
          ) : (
            <>
              <Button
                color="danger"
                disabled={disabled}
                onClick={() =>
                  openDialog({
                    title: 'テンプレートを削除しますか？',
                    description: '一度削除すると元に戻せません',
                    okType: 'danger',
                    okText: '削除',
                    onOk: async () => {
                      if (!template) {
                        return;
                      }
                      await onDelete(template.id);
                    },
                  })
                }
              >
                削除
              </Button>
              <Button type="submit" loading={updating} disabled={readonly}>
                更新
              </Button>
            </>
          )}
        </div>
      </form>
      <Dialog
        open={newCategoryDialogOpen}
        onOpenChange={setNewCategoryDialogOpen}
        width="sm"
      >
        <DialogHeader title="カテゴリーを追加する" />
        <DialogContent>
          <InputGroup label="カテゴリー名">
            <Input
              value={newCategoryName}
              onChange={(e) => setNewCategoryName(e.target.value)}
              autoFocus
            />
          </InputGroup>
        </DialogContent>
        <DialogFooter>
          <Button
            onClick={() => {
              if (newCategoryName.length <= 0) {
                return;
              }
              setValue('category', newCategoryName);
              setNewCategoryDialogOpen(false);
            }}
            disabled={newCategoryName.length <= 0}
          >
            追加
          </Button>
        </DialogFooter>
      </Dialog>
      <input
        type="file"
        onChange={(e) => handleFileChange(e.target.files![0])}
        ref={fileInputRef}
        hidden
      />
    </>
  );
};

const ErrorAlert = ({ children }: { children: ReactNode }) => {
  return (
    <div className="rounded-lg border border-sun-500 bg-sun-100 p-2">
      {children}
    </div>
  );
};
