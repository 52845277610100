import { useMemo } from 'react';
import styles from './Switch.module.css';
import { twMerge } from 'tailwind-merge';

type Props = {
  value: boolean;
  onChange: (value: boolean) => void;
  label?: string;
  disabled?: boolean;
};

export const Switch = ({ value, onChange, label, disabled }: Props) => {
  const id = useMemo(() => `switch` + Math.floor(Math.random() * 1000), []);
  return (
    <div className="flex items-center">
      <input
        id={id}
        type="checkbox"
        className={twMerge(
          'h-0 w-0 appearance-none outline-none',
          styles.input
        )}
        checked={value}
        onChange={(e) => onChange(e.target.checked)}
        disabled={disabled}
      />
      <label
        htmlFor={id}
        className={twMerge(
          'relative inline-block h-6 w-10 select-none rounded-full',
          styles.switchBg
        )}
        data-testid="switch-label"
      >
        <span
          className={twMerge(
            'pointer-events-none absolute top-1 h-4 w-4 rounded-full',
            styles.switchCircle
          )}
        />
      </label>
      {!!label && (
        <div
          className="select-none pl-2 text-sm"
          onClick={() => onChange(!value)}
        >
          {label}
        </div>
      )}
    </div>
  );
};
