import { useStore } from 'hooks/useStore';
import { BaseMessagePane } from './BaseMessagePane';
import { Scheduled } from '../MessageList/Me/Scheduled/Scheduled';
import { MessageListEmpty } from 'components/MessageList';
import { observer } from 'mobx-react';
import { scheduledDraftsAtom } from '../../../atoms/firestore/draft';
import { useAtomValue } from 'jotai';

export const PATH = '/me/scheduled';

// 自分 - 送信予約
export const MessagePaneMeScheduled = observer(() => {
  const scheduledDraftsPaginate = useAtomValue(scheduledDraftsAtom);

  const { searchStore } = useStore();

  const getEmptyDescription = () => {
    const { inSearch } = searchStore;
    return inSearch
      ? `検索条件と一致する送信予約はありません`
      : '送信予約はありません';
  };

  return (
    <BaseMessagePane
      listPagePath={PATH}
      empty={
        scheduledDraftsPaginate.state === 'hasData' &&
        scheduledDraftsPaginate.data.length === 0
      }
      emptyComponent={<MessageListEmpty description={getEmptyDescription()} />}
      listComponent={<Scheduled />}
    />
  );
});
