import * as React from 'react';
import type { SVGProps } from 'react';
const SvgAddPhotoAlternate = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M5.115 20.5a1.74 1.74 0 0 1-1.276-.53 1.74 1.74 0 0 1-.531-1.277V5.307c0-.497.177-.923.53-1.277a1.74 1.74 0 0 1 1.277-.53h7.943c.212 0 .39.071.534.215a.726.726 0 0 1 .216.534c0 .213-.072.391-.216.535a.726.726 0 0 1-.534.215H5.115a.3.3 0 0 0-.22.087.3.3 0 0 0-.087.221v13.384a.3.3 0 0 0 .086.222.3.3 0 0 0 .221.086H18.5a.3.3 0 0 0 .221-.086.3.3 0 0 0 .087-.222V10.75c0-.212.072-.39.215-.534a.726.726 0 0 1 .535-.216c.212 0 .39.072.534.216a.726.726 0 0 1 .216.534v7.942c0 .498-.177.923-.531 1.277a1.74 1.74 0 0 1-1.277.531H5.115ZM17.192 6.615h-1.25a.725.725 0 0 1-.534-.215.726.726 0 0 1-.216-.535c0-.212.072-.39.216-.534a.726.726 0 0 1 .534-.215h1.25v-1.25c0-.213.072-.39.216-.535a.727.727 0 0 1 .535-.215c.212 0 .39.072.534.215a.726.726 0 0 1 .215.534v1.25h1.25c.213 0 .39.073.535.216a.727.727 0 0 1 .215.535c0 .212-.072.39-.215.534a.727.727 0 0 1-.535.215h-1.25v1.25c0 .213-.072.391-.216.535a.726.726 0 0 1-.534.215.725.725 0 0 1-.534-.215.727.727 0 0 1-.216-.535v-1.25Zm-6.154 9.405L9.4 13.92a.442.442 0 0 0-.357-.172.433.433 0 0 0-.357.181L7.11 16.027c-.12.154-.137.313-.048.477a.429.429 0 0 0 .4.246h8.769c.177 0 .31-.082.4-.246a.413.413 0 0 0-.039-.477l-2.384-3.194a.438.438 0 0 0-.362-.18.437.437 0 0 0-.362.182l-2.446 3.184Z"
    />
  </svg>
);
export default SvgAddPhotoAlternate;
