import { MessageRecipient } from '../Message';
import { Sent } from 'lib';
import { HeaderContactBase } from '../HeaderContactBase';

type Props = {
  sender: any;
  sent: Sent;
};

const SentHeaderContact = ({ sender, sent }: Props) => {
  return (
    <HeaderContactBase
      user={sender}
      iconName={sent.fromIconName}
      contactElement={
        <div className={'max-w-full truncate'}>
          <span className="mb-px text-sm" title={sent.from}>
            {sent.from}
          </span>
        </div>
      }
      recipientElement={
        <MessageRecipient
          subject={sent.subject}
          mailAddresses={{
            from: [{ address: sent.from }],
            to: sent.to?.map((address) => ({ address })),
            cc: sent.cc?.map((address) => ({ address })),
            bcc: sent.bcc?.map((address) => ({ address })),
          }}
          bold
        />
      }
    />
  );
};

export default SentHeaderContact;
