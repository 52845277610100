import { IsActiveRouteFunction, MainNavItem } from './MainNavItem';
import { Icon } from '../../../components/basics';
import { Mail } from '../../../components/icons';
import React from 'react';
import _ from 'lodash';
import { useStore } from '../../../hooks/useStore';
import { useTopPagePath } from '../../../hooks/useTopPagePath';
import { observer } from 'mobx-react';

type Props = {
  onClick?: () => void;
};

export const MainNavTopButton = observer(({ onClick }: Props) => {
  const store = useStore();
  const topPagePath = useTopPagePath(store);
  return (
    <MainNavItem
      to={topPagePath}
      isActiveRoute={isTopItemActive}
      onClick={() => onClick?.()}
      label="メール"
    >
      <Icon icon={Mail} className="text-sumi-900" />
    </MainNavItem>
  );
});

const REGEXS_FOR_PATH_TO_ACTIVATE_TOP_ITEM = [
  /^\/$/,
  /^\/me/,
  /^\/teams/,
  /^\/rooms/,
  /^\/search/,
];

const isTopItemActive: IsActiveRouteFunction = (_match, location) => {
  return _.some(REGEXS_FOR_PATH_TO_ACTIVATE_TOP_ITEM, (regex) => {
    return regex.test(location.pathname);
  });
};
