import * as React from 'react';
import type { SVGProps } from 'react';
const SvgFilter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M10.75 18a.724.724 0 0 1-.534-.217.732.732 0 0 1-.216-.537.731.731 0 0 1 .75-.746h2.5c.213 0 .39.072.534.217a.732.732 0 0 1 .216.537.731.731 0 0 1-.75.746h-2.5Zm-4-5.25a.723.723 0 0 1-.534-.217.732.732 0 0 1-.216-.537.731.731 0 0 1 .75-.746h10.5c.212 0 .39.072.534.217a.732.732 0 0 1 .216.537.731.731 0 0 1-.75.746H6.75Zm-3-5.25a.724.724 0 0 1-.534-.217A.732.732 0 0 1 3 6.746.731.731 0 0 1 3.75 6h16.5c.212 0 .39.072.534.217a.732.732 0 0 1 .216.537.731.731 0 0 1-.75.746H3.75Z"
    />
  </svg>
);
export default SvgFilter;
