import * as Popover from '@radix-ui/react-popover';
import { Button, Icon } from '../../../components/basics';
import { CaretDown } from '../../../components/icons';
import { useState } from 'react';
import { Select } from '../../../components/forms';

type Props = {
  accounts: { id: string; name: string }[];
  onChange: (id: string) => void;
  onRemove: () => void;
};

export const AccountMenuButton = ({ accounts, onChange, onRemove }: Props) => {
  const [editing, setEditing] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState<string>();
  return (
    <Popover.Root
      onOpenChange={() => {
        setEditing(false);
        setSelectedAccount(undefined);
      }}
    >
      <Popover.Trigger asChild>
        <Button
          size="sm"
          color="sumi"
          variant="outlined"
          className="grid grid-cols-[1fr_auto] items-center gap-1.5 border-sumi-300 font-normal text-sumi-900"
        >
          <span>取引先の編集</span>
          <Icon icon={CaretDown} size={20} />
        </Button>
      </Popover.Trigger>
      <Popover.Portal>
        {editing ? (
          <Popover.Content className="flex w-[200px] min-w-[var(--radix-popover-trigger-width)] flex-col gap-2 rounded bg-white p-2 text-xs shadow-dropdown">
            <Select
              value={selectedAccount}
              onChange={setSelectedAccount}
              placeholder="取引先を選択してください"
              options={accounts.map((a) => ({ value: a.id, label: a.name }))}
            />
            <div className="flex justify-end gap-1">
              <Button
                size="sm"
                variant="outlined"
                onClick={() => setEditing(false)}
              >
                キャンセル
              </Button>
              <Popover.Close asChild>
                <Button
                  size="sm"
                  onClick={() => selectedAccount && onChange(selectedAccount)}
                  disabled={!selectedAccount}
                >
                  追加
                </Button>
              </Popover.Close>
            </div>
          </Popover.Content>
        ) : (
          <Popover.Content className="min-w-[var(--radix-popover-trigger-width)] rounded bg-white py-1 text-xs shadow-dropdown">
            <button
              type="button"
              className="block h-8 w-full cursor-pointer bg-transparent px-4 text-start hover:bg-sumi-100"
              onClick={() => setEditing(true)}
            >
              取引先に追加
            </button>
            <button
              type="button"
              className="block h-8 w-full cursor-pointer bg-transparent px-4 text-start text-sun-500 hover:bg-sumi-100"
              onClick={() => onRemove()}
            >
              取引先から削除
            </button>
          </Popover.Content>
        )}
      </Popover.Portal>
    </Popover.Root>
  );
};
