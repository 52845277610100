import * as React from 'react';
import type { SVGProps } from 'react';
const SvgAt = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M10 18.334a8.115 8.115 0 0 1-3.25-.657 8.415 8.415 0 0 1-2.646-1.78 8.416 8.416 0 0 1-1.782-2.647A8.115 8.115 0 0 1 1.666 10c0-1.152.219-2.236.656-3.25a8.415 8.415 0 0 1 1.782-2.646 8.415 8.415 0 0 1 2.645-1.78A8.115 8.115 0 0 1 10 1.666c1.153 0 2.236.219 3.25.656a8.416 8.416 0 0 1 2.646 1.781 8.415 8.415 0 0 1 1.781 2.646 8.115 8.115 0 0 1 .657 3.25v1.104c0 .778-.275 1.435-.823 1.97-.549.534-1.219.801-2.01.801-.5 0-.973-.121-1.417-.364a2.476 2.476 0 0 1-1.021-1.031 3.296 3.296 0 0 1-1.354 1.052 4.341 4.341 0 0 1-1.709.343c-1.083 0-2.003-.375-2.76-1.125C6.482 12 6.104 11.084 6.104 10c0-1.083.378-2.007 1.135-2.77.757-.764 1.677-1.146 2.76-1.146 1.084 0 2.004.382 2.76 1.145.758.764 1.136 1.688 1.136 2.771v1.104c0 .431.156.792.469 1.084.312.291.69.437 1.135.437.43 0 .802-.146 1.115-.437.312-.292.469-.653.469-1.084V10c0-1.972-.688-3.646-2.063-5.02-1.375-1.376-3.048-2.063-5.02-2.063-1.973 0-3.646.687-5.021 2.062C3.604 6.354 2.916 8.03 2.916 10c0 1.973.688 3.646 2.063 5.021 1.375 1.375 3.048 2.063 5.02 2.063h3.834a.607.607 0 0 1 .625.625.607.607 0 0 1-.625.625H9.999Zm0-5.709a2.57 2.57 0 0 0 1.874-.76c.514-.507.771-1.129.771-1.865 0-.75-.257-1.382-.77-1.896a2.552 2.552 0 0 0-1.876-.77c-.736 0-1.36.257-1.875.77-.514.514-.77 1.146-.77 1.896 0 .736.256 1.358.77 1.865a2.57 2.57 0 0 0 1.875.76Z"
    />
  </svg>
);
export default SvgAt;
