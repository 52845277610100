import { ComponentPropsWithoutRef } from 'react';
import { twMerge } from 'tailwind-merge';

type Props = ComponentPropsWithoutRef<'div'>;

export const DialogContent = ({ children, className, ...props }: Props) => {
  return (
    <div className={twMerge(className, 'p-4 text-sm leading-6')} {...props}>
      {children}
    </div>
  );
};
