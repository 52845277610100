import { Store } from '../../../store';
import { generatePath, useHistory } from 'react-router-dom';
import { useMsgRouteMatch } from '../../../hooks/useMsgRouteMatch';
import { useEffect, useMemo } from 'react';
import { MessageView } from '../../../store/messages';
import { allTeamStatusesInGroupAtom } from 'atoms/firestore/customStatuses';
import { useAtomValue, useSetAtom } from 'jotai';
import { CustomStatus, MessageStatus } from 'lib';
import { flatStatusesDataAtom } from 'utils/customStatuses';
import { getStatusesForFront, hasCustomStatus } from 'utils/frontMessageStatus';
import {
  tabCountsAtom,
  tabCountsFilterAtom,
} from '../../../atoms/firestore/count/tab';

type Entry = { value: string; label: string };

type UseMessageStatusResult = {
  statuses: Entry[];
  status: string;
  onChange: (status: string) => void;
};

export const useMessageStatus = (store: Store): UseMessageStatusResult => {
  const history = useHistory();
  const match = useMsgRouteMatch();
  const [, customStatusesInGroup] = useAtomValue(allTeamStatusesInGroupAtom);
  const setTabCountsFilter = useSetAtom(tabCountsFilterAtom);
  const tabCounts = useAtomValue(tabCountsAtom);

  const { teamId, tagId, inboxId } = match.params;
  useEffect(() => {
    if (teamId && tagId) {
      setTabCountsFilter({
        teamId,
        tagId,
        inboxId,
      });
    }
  }, [teamId, tagId, inboxId]);

  const customStatuses = useMemo<CustomStatus[]>(() => {
    switch (match.page) {
      case 'line':
        return [];
      case 'me':
        return flatStatusesDataAtom(customStatusesInGroup);
      default:
        if (!teamId) {
          return [];
        }
        return teamId in customStatusesInGroup
          ? customStatusesInGroup[teamId]
          : [];
    }
  }, [store, match, customStatusesInGroup, teamId]);

  const { showSent, showDeleted } = useMemo(() => {
    let showSent = false,
      showDeleted = false;
    if (['me', 'line'].includes(match.page || '')) {
      return { showSent, showDeleted };
    }
    const tag = store.getTag(tagId);
    if (tag) {
      // タグがinboxの場合、isDefaultがtrue
      showSent = tag.isDefault;
      showDeleted = tag.isDefault;
    }
    return { showSent, showDeleted };
  }, [store, match]);

  const onChange = (tab: string) => {
    if (match.path) {
      history.push(
        generatePath(match.path, {
          ...match.params,
          tab,
          msgId: undefined,
        })
      );
    }
  };

  const unprocessedLabel = useMemo(() => {
    if (!tagId || !hasCustomStatus(customStatuses)) {
      return `未対応`;
    }
    return `未対応${
      tabCounts[MessageStatus.Unprocessed]
        ? `（${tabCounts[MessageStatus.Unprocessed]}）`
        : ''
    }`;
  }, [tabCounts, tagId, inboxId, customStatuses]);

  const memoStatuses: Entry[] = useMemo(() => {
    const statuses: Entry[] = [];
    statuses.push({ label: unprocessedLabel, value: MessageView.Unprocessed });

    if (match.page === 'me' && hasCustomStatus(customStatuses)) {
      statuses.push({ label: 'その他', value: MessageView.Other });
    }

    if (match.page === 'team') {
      const entries: Entry[] = getStatusesForFront(customStatuses).map(
        (status) => {
          return {
            label: tagId
              ? tabCounts[status.customStatusId ?? status.statusName]
                ? `${status.statusName}（${
                    tabCounts[status.customStatusId ?? status.statusName]
                  }）`
                : status.statusName
              : status.statusName,
            value: `_${
              status.statusType === MessageStatus.CustomStatus
                ? status.customStatusId
                : status.statusType
            }`,
          };
        }
      );
      statuses.push(...entries);
    }

    statuses.push({ label: '対応済み', value: MessageView.Processed });
    if (showSent) {
      statuses.push({ label: '送信済み', value: MessageView.Sent });
    }

    if (showDeleted) {
      statuses.push({ label: 'ゴミ箱', value: MessageView.Deleted });
    }
    statuses.push({ label: 'すべて', value: MessageView.All });

    return statuses;
  }, [customStatuses, match, tagId, inboxId]);

  return { status: match.tab, statuses: memoStatuses, onChange };
};
