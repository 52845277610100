import { MessageArrowUp } from 'components/icons';

type Props = {
  onClick: () => void;
  collapsed: boolean;
};

const MessageCollapse = ({ collapsed, onClick }: Props) => {
  return (
    <div className="mb-1 flex justify-center">
      <button
        className="flex w-full cursor-pointer justify-center bg-transparent"
        onClick={() => onClick()}
      >
        <div
          className="text-black"
          style={{
            width: 20,
            height: 20,
            transform: collapsed ? 'rotate(180deg)' : 'rotate(0deg)',
          }}
        >
          <MessageArrowUp />
        </div>
      </button>
    </div>
  );
};

export default MessageCollapse;
