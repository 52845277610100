import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Icon } from 'antd';
import { SPOnly } from '../../../Common/MediaQuery';
import styled from 'styled-components';
import * as color from '../../../../color';

type Props = {
  backLink: string | (() => string);
};

export const SPBackButton: React.FC<Props> = ({ backLink }) => {
  const history = useHistory();
  const back = () => {
    if (typeof backLink === 'string') {
      history.push(backLink);
    } else {
      history.push(backLink());
    }
  };

  return (
    <SPOnly>
      <SPButtonWrapper>
        <Button onClick={back}>
          <Icon type="left" />
          戻る
        </Button>
      </SPButtonWrapper>
    </SPOnly>
  );
};

const SPButtonWrapper = styled.div`
  border-bottom: solid 0.5px #f1f1f3;
  width: 100%;
  background-color: ${color.sidebar.background.normal};
`;
