import { atom } from 'jotai';
import {
  companyCollection,
  companyDoc,
  registerUnsubscribe,
} from '../../firestore';
import { Draft, draftConverter } from '../../firestore/entity/draft';
import {
  getCountFromServer,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import { store } from '../../providers/StoreProvider';
import { meAtom } from '../auth';
import { atomWithPaginate } from '../../utils/atom';
import { atomEffect } from 'jotai-effect';
import { atomWithRefresh, unwrap } from 'jotai/utils';
import { joinedTeamIdsAtom } from './team';

export const modalDraftAddingAtom = atom(false);

export const modalDraftAtom = atom<Draft | undefined>(undefined);

export const modalDraftEffect = atomEffect((get, set) => {
  const draft = get(modalDraftAtom);
  if (!draft) {
    return;
  }
  return registerUnsubscribe(
    modalDraftAtom,
    onSnapshot(companyDoc('drafts', draft.id, draftConverter), (snapshot) =>
      set(modalDraftAtom, snapshot.data())
    )
  );
});

export const scheduledDraftsAtom = atomWithPaginate(
  () => {
    return query(
      companyCollection('drafts', draftConverter),
      where('teamId', 'in', store.get(joinedTeamIdsAtom)),
      where('drafter', '==', store.get(meAtom).id),
      where('scheduledAt', '!=', null),
      orderBy('scheduledAt')
    );
  },
  (snapshot) => snapshot.docs.map((doc) => doc.data())
);

export const failedScheduledDraftCountAtom = unwrap(
  atomWithRefresh(async (get) => {
    const snap = await getCountFromServer(
      query(
        companyCollection('drafts'),
        where('teamId', 'in', get(joinedTeamIdsAtom)),
        where('drafter', '==', get(meAtom).id),
        where('scheduledAt', '!=', null)
      )
    );
    return snap.data().count;
  }),
  (prev) => prev ?? 0
);

failedScheduledDraftCountAtom.onMount = (set) =>
  registerUnsubscribe(
    failedScheduledDraftCountAtom,
    onSnapshot(
      query(
        companyCollection('drafts'),
        where('teamId', 'in', store.get(joinedTeamIdsAtom)),
        where('drafter', '==', store.get(meAtom).id),
        orderBy('updatedAt', 'desc'),
        limit(1)
      ),
      () => set()
    )
  );

export const draftsAtom = atomWithPaginate(
  () => {
    const me = store.get(meAtom);
    return query(
      companyCollection('drafts', draftConverter),
      where('teamId', 'in', store.get(joinedTeamIdsAtom)),
      where('drafter', '==', me.id),
      where('isSending', '==', false),
      where('scheduledAt', '==', null),
      orderBy('createdAt', 'desc')
    );
  },
  (snapshot) => snapshot.docs.map((doc) => doc.data()),
  15
);
