import { useStore } from 'hooks/useStore';
import { BaseMessagePane } from './BaseMessagePane';
import Sent from '../MessageList/Teams/Sent';
import { TeamMessageListEmpty } from 'components/MessageList';
import { observer } from 'mobx-react';
import { useAtomValue } from 'jotai';
import { sentAtom } from '../../../atoms/firestore/sent';

export const PATHS = [
  '/teams/:teamId/tags/:tagId/sent',
  '/teams/:teamId/inboxes/:inboxId/tags/:tagId/sent',
  '/search/sent/:query',
];

// チーム - 送信済み
// チーム - 検索
export const MessagePaneTeamSent: React.FC = observer(() => {
  const store = useStore();

  const sentPaginate = useAtomValue(sentAtom);

  const { searching: searchingSent, sortedSent, inSearch } = store.searchStore;
  const searchedSentList =
    !searchingSent && inSearch
      ? sortedSent
      : sentPaginate.state === 'hasData'
      ? sentPaginate.data
      : [];
  const isEmpty = searchedSentList.length === 0;

  const getEmptyDescription = () => {
    const {
      searchStore: { inSearch },
    } = store;
    let description = '送信済みはありません';
    if (inSearch) {
      description = `検索条件と一致する` + description;
    }
    return description;
  };

  const loading = inSearch ? searchingSent : sentPaginate.state === 'loading';

  return (
    <BaseMessagePane
      listPagePath={PATHS}
      empty={!loading && isEmpty}
      emptyComponent={
        <TeamMessageListEmpty description={getEmptyDescription()} />
      }
      listComponent={<Sent />}
    />
  );
});
