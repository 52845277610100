import {
  addDoc,
  getDocs,
  limit,
  query,
  serverTimestamp,
  Timestamp,
  updateDoc,
  where,
} from 'firebase/firestore';
import { makeObservable, observable } from 'mobx';
import { LRUCache } from 'lru-cache';
import Papa from 'papaparse';

import type { Store } from './index';
import { BaseStore } from './base';
import { ContactData, emailText } from 'lib';
import { searchContactFunction, unindexContactsFunction } from '../functions';
import { subscribeQueryInArray } from '../utils/firebase';
import { downloadBlob } from '../util.js';

export interface ContactObject {
  id?: string;
  teamId: string;
  accountId?: string;
  name: string;
  email: string;
  companyName: string;
  phoneNumber: string;
  memo: string;
  tags: string[];
}

interface ContactHit {
  _source: ContactObject;
}

interface Email {
  name: string;
  address: string;
}

export class ContactStore extends BaseStore<ContactData> {
  PATH = 'contacts';

  pageSize = 25;
  page = 1;
  hasMore = true;
  searching = false;
  searchedContacts: ContactObject[] = [];
  teamId = '';
  keyword = '';
  sortOrder = '';
  tags: string[] = [];

  selectedContact: ContactObject | null = null;

  _unsubscribeContacts!: () => void;

  constructor(rootStore: Store) {
    super(rootStore);

    makeObservable(this, {
      timestamp: observable,

      searchedContacts: observable,
      searching: observable,

      selectedContact: observable,
    });
  }

  /**
   * Value has three cases:
   *   - undefined, means never queried firestore
   *   - '', means queried firestore, but not found or not returned
   *   - string, means found from firestore
   */
  private _emailNameCache: LRUCache<string, string> = new LRUCache({
    max: 256,
  });

  /** Used to trigger re-rendering. */
  timestamp = Date.now();

  /**
   * Convert `'name' via service-name` to name.
   */
  prettifyName(name?: string): string {
    if (!name) {
      return '';
    }
    const matches = name.match(/['"]?([^'"]+)['"]? *via [\w-.]+/);
    if (!matches) {
      return name;
    }
    return matches[1];
  }

  /**
   * Get contact name by the teamId and email address.
   */
  async getContactNameByEmail({
    teamId,
    emailAddress,
    emailName,
  }: {
    teamId: string;
    emailAddress: string;
    emailName?: string;
  }): Promise<string> {
    const key = teamId + '/' + emailAddress;
    let name = this._emailNameCache.get(key);
    if (name !== undefined) {
      // For an emailAddress, there can be `From: emailName1 <emailAddress>`,
      // `From: emailName2 <emailAddress>` and so on. If name is '', which means
      // it's not a contact, fallback to emailName.
      return name || this.prettifyName(emailName) || emailAddress;
    }
    if (!emailAddress) {
      return this.prettifyName(emailName);
    }

    this._emailNameCache.set(key, '');
    const snap = await getDocs(
      query(
        this.collection(),
        where('teamId', '==', teamId),
        where('email', '==', emailAddress),
        limit(1)
      )
    );
    if (snap.docs[0]) {
      name = snap.docs[0].get('name');
      if (name) {
        this._emailNameCache.set(key, name);
        this.timestamp = Date.now();
      }
    }

    return name || this.prettifyName(emailName) || emailAddress;
  }

  updateCachedName(teamId: string, emailAddress: string, name: string) {
    this._emailNameCache.set(`${teamId}/${emailAddress}`, name);
  }

  async getContactByEmail(
    teamId: string,
    email: Email
  ): Promise<ContactObject> {
    const snap = await getDocs(
      query(
        this.collection(),
        where('teamId', '==', teamId),
        where('email', '==', email.address),
        limit(1)
      )
    );
    return snap.docs.length
      ? {
          id: snap.docs[0].id,
          ...snap.docs[0].data(),
        }
      : {
          teamId,
          name: email.name,
          email: email.address,
          companyName: '',
          phoneNumber: '',
          memo: '',
          tags: [],
        };
  }

  async autoComplete(
    teamId: string,
    keyword: string,
    limit: number
  ): Promise<void> {
    const result = await searchContactFunction({
      companyId: this.rootStore.signInCompany,
      teamId,
      keyword,
      offset: 0,
      limit,
    });
    return (
      result.data?.hits.map(({ _source }: ContactHit) => ({
        ..._source,
        tags: _source.tags || [],
        emailText: emailText(_source.name, _source.email),
      })) || []
    );
  }

  async search(
    page: number,
    teamId: string = this.teamId,
    keyword: string = this.keyword,
    sortOrder: string = this.sortOrder,
    tags: string[] = this.tags
  ): Promise<void> {
    this.searching = true;
    this.page = page;
    this.teamId = teamId;
    this.keyword = keyword;
    this.sortOrder = sortOrder;
    this.tags = tags;
    const result = await searchContactFunction({
      companyId: this.rootStore.signInCompany,
      teamId,
      keyword,
      offset: (page - 1) * this.pageSize,
      limit: this.pageSize,
      sort: sortOrder,
      tags,
    });
    if (this.page * this.pageSize >= result.data.total.value) {
      this.hasMore = false;
    } else {
      this.hasMore = true;
    }
    this.searchedContacts = result.data.hits.map(({ _source }: ContactHit) => ({
      ..._source,
      tags: _source.tags || [],
    }));
    this.searching = false;
  }

  reload = async (): Promise<void> => {
    return this.search(this.page);
  };

  toPrevPage = async (): Promise<void> => {
    if (this.page > 1) {
      return this.search(this.page - 1);
    }
  };

  toNextPage = async (): Promise<void> => {
    return this.search(this.page + 1);
  };

  changePageSize = (size: number): Promise<void> => {
    this.pageSize = size;
    return this.search(1);
  };

  syncContacts = async (): Promise<void> => {
    this._unsubscribeContacts?.();

    this._unsubscribeContacts = subscribeQueryInArray<ContactData>(
      query(this.collection(), where('updatedAt', '>', Timestamp.now())),
      'teamId',
      this.rootStore.joinedTeamIds,
      (snap) => {
        snap.docChanges().forEach((change) => {
          const index = this.searchedContacts.findIndex(
            (x) => x.id === change.doc.id
          );
          const searchedContacts = this.searchedContacts.slice();
          switch (change.type) {
            case 'added':
            case 'modified':
              const data = change.doc.data();
              const contact = {
                id: change.doc.id,
                ...data,
                tags: data.tags ?? [],
              };
              if (index > -1) {
                searchedContacts.splice(index, 1, contact);
                this.searchedContacts = searchedContacts;
              } else if (this.searchedContacts.length < this.pageSize) {
                searchedContacts.push(contact);
                this.searchedContacts = searchedContacts;
              }
              break;
            case 'removed':
              if (index > -1) {
                searchedContacts.splice(index, 1);
                this.searchedContacts = searchedContacts;
              }
              break;
          }
        });
      }
    );
  };

  unindexContacts = async (
    teamId: string,
    contactIds: string[]
  ): Promise<void> => {
    await unindexContactsFunction({
      companyId: this.rootStore.signInCompany,
      teamId,
      contactIds,
    });
    this.searchedContacts = this.searchedContacts.filter(
      (x) => !contactIds.includes(x.id as string)
    );
  };

  exportContacts = async (teamId: string): Promise<void> => {
    const snap = await getDocs(
      query(this.collection(), where('teamId', '==', teamId))
    );
    const data = snap.docs.map((doc) => {
      const { name, email, tags, companyName, phoneNumber, memo } = doc.data();
      return [
        name,
        email,
        tags ? tags.join(',') : '',
        companyName,
        phoneNumber,
        memo,
      ];
    });
    const csvContent = Papa.unparse({
      data,
      fields: ['名前', 'メールアドレス', 'タグ', '会社名', '電話番号', 'メモ'],
    });
    downloadBlob(
      'contacts.csv',
      // Prepend BOM to make Excel happy, see https://github.com/eligrey/FileSaver.js/issues/28.
      new Blob(['\uFEFF' + csvContent], {
        type: 'text/csv;charset=utf-8',
      })
    );
  };

  selectContact = async (teamId: string, email: Email): Promise<void> => {
    this.selectedContact = await this.getContactByEmail(teamId, email);
  };

  updateSelectedContact = async (contact: ContactObject): Promise<void> => {
    const data = contact;
    const id = data.id;
    if (id) {
      delete data.id;
      await updateDoc(this.doc(id), {
        ...data,
        updatedAt: serverTimestamp(),
      });
    } else {
      await addDoc(this.collection(), {
        accountId: '',
        ...data,
        updatedAt: serverTimestamp(),
        createdAt: serverTimestamp(),
      });
    }
    await this.selectContact(data.teamId, {
      address: data.email,
      name: data.name,
    });
  };
}
