import { SettingPageDrawer } from '../../../common/SettingPageDrawer/SettingPageDrawer';
import { Controller, useForm } from 'react-hook-form';
import { SenderNameField } from '../../inbox/fields/SenderNameField';
import { InputGroup } from '../../../../../components/forms/InputGroup/InputGroup';
import { Input } from '../../../../../components/forms';
import { validateCcAndBcc } from '../../inbox/validate';
import { Button } from '../../../../../components/basics';
import { SignatureField } from '../../inbox/fields/SignatureField';

export type MyEmailAddData = {
  name: string;
  autoCc: string;
  autoBcc: string;
  defaultSignatureId: string | null;
};

type Props = {
  email: string;
  signatures: { id: string; name: string }[];
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onCreate: (data: MyEmailAddData) => Promise<void>;
  container?: HTMLElement;
};

export const MyEmailAddDrawer = ({
  email,
  signatures,
  open,
  onOpenChange,
  onCreate,
  container,
}: Props) => {
  const {
    register,
    handleSubmit,
    control,
    watch,
    setError,
    clearErrors,
    formState: { isSubmitting },
  } = useForm<MyEmailAddData>({
    defaultValues: {
      name: '',
      autoCc: '',
      autoBcc: '',
      defaultSignatureId: null,
    },
  });

  const autoCcValue = watch('autoCc');
  const autoBccValue = watch('autoBcc');
  const validate = (): boolean => {
    if (validateCcAndBcc(autoCcValue, autoBccValue)) {
      clearErrors('autoCc');
      clearErrors('autoBcc');
      return true;
    } else {
      const message = '自動Ccと自動Bccに同じ宛先を含めることはできません';
      setError('autoCc', {
        type: 'manual',
        message: message,
      });
      setError('autoBcc', {
        type: 'manual',
        message: message,
      });
      return false;
    }
  };

  const onSubmit = async (update: MyEmailAddData) => {
    if (!validate()) {
      return;
    }
    try {
      await onCreate(update);
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <SettingPageDrawer
      title="新規メールアドレス作成"
      open={open}
      onOpenChange={onOpenChange}
      container={container}
    >
      <form
        action=""
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col gap-4 text-sm"
      >
        <InputGroup label="転送元メールアドレス">
          <Input value={email} readOnly />
        </InputGroup>
        <Controller
          name="name"
          control={control}
          render={({ field: { value, onChange } }) => (
            <SenderNameField
              value={value}
              onChange={onChange}
              readonly={false}
            />
          )}
        />
        <Controller
          name="autoCc"
          control={control}
          render={({ fieldState: { error } }) => (
            <InputGroup
              label="自動的にCcに追加するメールアドレス"
              description={`メール作成時、自動的にCcに追加されます。※ ","で複数のメールアドレスを記入可能です。`}
              errorMessage={error?.message}
            >
              <Input
                placeholder="contact@onebox.tokyo, sales@onebox.tokyo"
                {...register('autoCc', { onBlur: () => validate() })}
              />
            </InputGroup>
          )}
        />
        <Controller
          name="autoBcc"
          control={control}
          render={({ fieldState: { error } }) => (
            <InputGroup
              label="自動的にBccに追加するメールアドレス"
              description={`メール作成時、自動的にBccに追加されます。※ ","で複数のメールアドレスを記入可能です。`}
              errorMessage={error?.message}
            >
              <Input
                placeholder="contact@onebox.tokyo, sales@onebox.tokyo"
                {...register('autoBcc', { onBlur: () => validate() })}
              />
            </InputGroup>
          )}
        />
        <InputGroup
          label="デフォルトの署名"
          description="メール作成時、自動的に署名に追加されます。"
        >
          <Controller
            name="defaultSignatureId"
            control={control}
            render={({ field: { value, onChange } }) => (
              <SignatureField
                value={value}
                onChange={onChange}
                signatures={signatures}
              />
            )}
          />
        </InputGroup>
        <div>
          <Button type="submit" loading={isSubmitting}>
            作成
          </Button>
        </div>
      </form>
    </SettingPageDrawer>
  );
};
