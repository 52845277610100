import * as React from 'react';
import type { SVGProps } from 'react';
const SvgArrowDownward = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 -960 960 960"
    {...props}
  >
    <path
      fill="currentColor"
      d="M440-800v487L216-537l-56 57 320 320 320-320-56-57-224 224v-487h-80Z"
    />
  </svg>
);
export default SvgArrowDownward;
