import { generatePath } from 'react-router-dom';

export interface SettingObjectPaths {
  index: string;
  new: string;
  detail: string;
}

export class SettingObjectRoutes<
  BaseParams extends { [key: string]: string },
  IdKey extends string,
> {
  constructor(
    readonly paths: SettingObjectPaths,
    private router: {
      push: (location: string) => void;
      replace: (location: string) => void;
    }
  ) {}

  getIndexPathName(params: BaseParams): string {
    return generatePath(this.paths.index, params);
  }

  getNewPathName(params?: BaseParams): string {
    return generatePath(this.paths.new, params);
  }
  getDetailPathName(params: BaseParams & { [key in IdKey]: string }): string {
    return generatePath(this.paths.detail, params);
  }

  toIndex(params: BaseParams, options: { replace?: boolean } = {}): void {
    const pathname = this.getIndexPathName(params);
    if (options.replace) {
      this.router.replace(pathname);
    } else {
      this.router.push(pathname);
    }
  }
  toNew(params?: BaseParams, options: { replace?: boolean } = {}): void {
    const pathname = this.getNewPathName(params);
    if (options.replace) {
      this.router.replace(pathname);
    } else {
      this.router.push(pathname);
    }
  }
  toDetail(
    params: BaseParams & { [key in IdKey]: string },
    options: { replace?: boolean } = {}
  ): void {
    const pathname = this.getDetailPathName(params);
    if (options.replace) {
      this.router.replace(pathname);
    } else {
      this.router.push(pathname);
    }
  }
}
