import {
  ComponentProps,
  createElement,
  useCallback,
  useLayoutEffect,
  useRef,
} from 'react';
import { Emoji as EmojiMartEmoji, init } from 'emoji-mart';
import data from '@emoji-mart/data/sets/15/google.json';
import { twMerge } from 'tailwind-merge';
import { useWindowEvent } from '../../../hooks/useWindowEvent';
import styles from './Emoji.module.css';

init({ data }).then();

type Props = ComponentProps<'span'> & {
  tag?: string;
  fallback?: string;
  emoji?: string;
  native?: string;
  shortcodes?: string;
  size: number | string;
  skin?: number;
};

export const Emoji = ({
  tag = 'span',
  fallback,
  emoji,
  native,
  shortcodes,
  size,
  skin,
  ...props
}: Props) => {
  const ref = useRef<Element | null>(null);
  const instance = useRef<EmojiMartEmoji | null>(null);

  const emojiProps = {
    fallback,
    id: emoji,
    native,
    shortcodes,
    size: typeof size === 'number' ? `${size}px` : size,
    skin,
    set: 'google',
    draggable: false,
  };

  useLayoutEffect(() => {
    (instance.current as any | undefined)?.update(emojiProps);
  }, [emojiProps]);

  useLayoutEffect(() => {
    instance.current = new EmojiMartEmoji({ ...emojiProps, ref });
  }, []);

  useWindowEvent(
    'dragstart',
    useCallback((e) => {
      const target = e.target;
      if (target instanceof HTMLImageElement && target.closest('em-emoji')) {
        e.preventDefault();
      }
    }, [])
  );

  return createElement(tag, {
    ...props,
    className: twMerge(props.className, styles.emoji),
    style: {
      ['--emoji-size' as never]: typeof size === 'number' ? `${size}px` : size,
      ...props.style,
    },
    ref,
  });
};
