import { atomFamily } from 'jotai/utils';
import { isEqual, sum } from 'lodash';
import { registerUnsubscribe } from '../../../firestore';
import { MessageStatus } from 'lib';
import { atom } from 'jotai';
import { onSnapshot, query, where } from 'firebase/firestore';
import { counterCollection } from './utils';

type TagCountParams = {
  teamId: string;
  tagId: string;
  inboxId?: string;
};

export const tagCountAtomFamily = atomFamily(
  ({ teamId, tagId, inboxId }: TagCountParams) => {
    const writableTagCountAtom = atom(0);
    writableTagCountAtom.onMount = (set) => {
      let q = query(
        counterCollection(MessageStatus.Unprocessed, 'tag'),
        where('teamId', '==', teamId),
        where('tagId', '==', tagId)
      );
      if (inboxId) {
        q = query(q, where('inboxId', '==', inboxId));
      }
      return registerUnsubscribe(
        writableTagCountAtom,
        onSnapshot(q, (snapshot) => {
          set(sum(snapshot.docs.map((doc) => doc.data().count)));
        })
      );
    };
    return atom((get) => get(writableTagCountAtom));
  },
  isEqual
);
