import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../../../../hooks/useStore';
import {
  deleteSmtpServerFunction,
  getSmtpServerFunction,
  setSmtpServerFunction,
} from '../../../../../functions';
import { SmtpEditDialog, SmtpUpdate } from './SmtpEditDialog';
import { useConfirmDialog } from '../../../../../hooks/confirmDialog';
import { useToast } from '../../../../../hooks/useToast';

type Props = {
  inboxId: string;
  onClose: () => void;
};

type SmtpData = {
  host: string;
  port: 465 | 587;
  username: string;
};

export const SmtpEditDialogWithLogic = observer(
  ({ inboxId, onClose }: Props) => {
    const store = useStore();
    const { showToast } = useToast();
    const openDialog = useConfirmDialog();
    const { loading, data } = useSmtpServer(
      store.getInbox(inboxId)?.smtp === true,
      store.signInCompany,
      inboxId
    );
    const handleUpdate = async (update: SmtpUpdate) => {
      await setSmtpServerFunction({
        companyId: store.signInCompany,
        inboxId,
        host: update.host,
        port: update.port,
        username: update.username,
        password: update.password,
      })
        .then(() => {
          onClose();
          showToast('success', 'SMTP連携しました');
        })
        .catch((e) => {
          openDialog({
            title: 'SMTP連携設定の登録に失敗しました。',
            description:
              e.code === 'failed-precondition'
                ? 'SMTPサーバーにログインすることができませんでした。各接続情報が正しいかをご確認ください。'
                : e.message,
          });
        });
    };
    const handleDelete = async () => {
      await deleteSmtpServerFunction({
        companyId: store.signInCompany,
        inboxId,
      })
        .then(() => {
          onClose();
          showToast('success', 'SMTP連携を解除しました');
        })
        .catch((e) => {
          openDialog({
            title: 'SMTP連携の解除に失敗しました。',
            description: e.message,
          });
        });
    };
    return (
      <SmtpEditDialog
        settings={data}
        loading={loading}
        onUpdate={handleUpdate}
        onDelete={handleDelete}
        open={true}
        onOpenChange={() => onClose()}
      />
    );
  }
);

const useSmtpServer = (
  smtpEnabled: boolean,
  companyId: string,
  inboxId: string
): { loading: boolean; data: SmtpData | undefined } => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<SmtpData | undefined>(undefined);
  const fetch = async () => {
    if (!smtpEnabled) {
      setLoading(false);
      return;
    }
    try {
      const { data } = await getSmtpServerFunction({ companyId, inboxId });
      setData(data);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetch().then();
  }, []);
  return { loading, data };
};
