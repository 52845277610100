import { ReactNode } from 'react';
import * as RadixDialog from '@radix-ui/react-dialog';
import { tv } from 'tailwind-variants';
import { twMerge } from 'tailwind-merge';

type Props = {
  children: ReactNode;
  open: boolean;
  onOpenChange?: (value: boolean) => void;
  width?: 'lg' | 'md' | 'sm';
  overlayClassName?: string;
  contentClassName?: string;
  container?: HTMLElement;
  modal?: boolean;
};

const content = tv({
  base: 'fixed left-[50%] top-[25%] z-[1020] max-h-[85vh] w-[90vw] translate-x-[-50%] translate-y-[0%]',
  variants: {
    width: {
      lg: 'max-w-[800px]',
      md: 'max-w-[600px]',
      sm: 'max-w-[400px]',
    },
  },
  defaultVariants: {
    width: 'md',
  },
});

export const Dialog = ({
  children,
  open,
  onOpenChange,
  width,
  overlayClassName,
  contentClassName,
  container,
  modal,
}: Props) => {
  return (
    <RadixDialog.Root open={open} onOpenChange={onOpenChange} modal={modal}>
      <RadixDialog.Portal container={container}>
        <RadixDialog.Overlay
          className={twMerge(
            'fixed inset-0 z-[1020] bg-black/50 data-[state=open]:animate-dialog-overlay-show',
            overlayClassName
          )}
        />
        <RadixDialog.Content
          className={content({ width, className: contentClassName })}
        >
          <div className="w-full animate-dialog-content-show rounded bg-white">
            {children}
          </div>
        </RadixDialog.Content>
      </RadixDialog.Portal>
    </RadixDialog.Root>
  );
};
