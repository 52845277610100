import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Icon } from 'antd';
import QuotedMessage from './quotedMessage';
import { isSP } from '../../../shared/util';
import QuotedMessageGenerator from './quotedMessageGenerator';

export default ({
  useQuote,
  addQuote,
  removeQuote,
  draft,
  plainTextMode,
  readOnly,
}) => {
  const [hover, setHover] = useState(false);
  const [open, setOpen] = useState(false);

  const [header, setHeader] = useState('');
  const [text, setText] = useState('');
  const [html, setHtml] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!draft?.id) return;

    (async () => {
      const generator = new QuotedMessageGenerator({
        inReplyToSentRef: draft?.inReplyToSentRef,
        inReplyToMessageRef: draft?.inReplyToMessageRef,
        inReplyToSentId: draft?.inReplyToSentId,
        isForwarded: draft?.isForwarded,
      });

      const [header, text, html] = await generator.execute();
      setHeader(header);
      setText(text);
      setHtml(html);
      setLoading(false);
    })();

    // quotedMessageGeneratorに渡す引数はdraft作成時以降は更新されない
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draft?.id]);

  if (!readOnly && !useQuote) {
    return (
      <div onClick={addQuote} style={{ marginTop: 4 }}>
        <AddWrapper>
          <AddIcon type="plus" />
          引用を追加
        </AddWrapper>
      </div>
    );
  }

  return (
    <Wrapper
      onMouseEnter={() => !isSP() && setHover(true)}
      onMouseLeave={() => !isSP() && setHover(false)}
    >
      {useQuote && <QuoteIcon type="dash" onClick={() => setOpen(!open)} />}
      {useQuote && open && (
        <div>
          {!readOnly && (
            <QuoteOptions>
              <ExIcon
                type="close-circle"
                theme="filled"
                visible={(isSP() || hover).toString()}
                onClick={removeQuote}
              />
            </QuoteOptions>
          )}
          <QuotedMessage
            header={header}
            text={text}
            html={html}
            plainTextMode={plainTextMode}
            loading={loading}
          />
        </div>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-top: 8px;
  position: relative;
`;

const AddWrapper = styled.span`
  margin-left: -4px;
  padding: 4px;
  cursor: pointer;
  border-radius: 4px;
  color: #c5c6c5;
  :hover {
    background-color: #f0f0f1;
    color: #000000a6;
  }
`;

const AddIcon = styled(Icon)`
  margin-right: 4px;
`;

const QuoteIcon = styled(Icon)`
  cursor: pointer;
  border-radius: 8px;
  background-color: #f0f0f1;
  padding: 2px 4px;
`;

const QuoteOptions = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  padding-right: 8px;
`;

const ExIcon = styled(Icon)`
  cursor: pointer;
  margin-right: 8px;
  ${({ visible }) => (visible ? 'visibility: visible' : 'visibility: hidden')};
`;
