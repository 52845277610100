import { Tag } from 'antd';
import { CSSProperties } from 'styled-components';
import { MailAddress } from 'lib';

const tagStyle: CSSProperties = {
  whiteSpace: 'break-spaces',
  wordWrap: 'break-word',
  maxWidth: '100%',
  marginRight: '0',
};

export const Address: React.FC<MailAddress> = (props) => {
  return props.name && props.name.length > 0 ? (
    <Tag key={props.name + props.address} style={tagStyle}>
      {`${props.name} <${props.address || ''}>`}
    </Tag>
  ) : (
    <Tag key={props.address} style={tagStyle}>
      {props.address || ''}
    </Tag>
  );
};
