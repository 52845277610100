import { BaseMessagePane } from './BaseMessagePane';
import { FC } from 'react';

// /teams/:teamId/messages/:messageId用 何も表示しない
export const MessagePaneMessages: FC = () => {
  return (
    <BaseMessagePane
      listPagePath={[]}
      empty={false}
      emptyComponent={null}
      listComponent={null}
    />
  );
};
