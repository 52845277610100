import { observer } from 'mobx-react';
import { TeamMemberEntry, TeamMembers } from './TeamMembers';
import { useStore } from '../../../hooks/useStore';
import { Redirect, useRouteMatch } from 'react-router-dom';
import Avatar from '../../Common/Avatar';
import React from 'react';
import { User } from 'lib';
import { deleteField, doc, updateDoc } from 'firebase/firestore';
import { db9 } from '../../../firebase';
import { eventNames, logEvent } from '../../../analytics';
import { Store } from '../../../store';
import { useToast } from '../../../hooks/useToast';

export const TeamMembersWithLogic = observer(() => {
  const match = useRouteMatch<{ teamId: string }>();
  const store = useStore();
  const { showToast } = useToast();
  const teamId = match.params.teamId;
  const teamRef = doc(db9, `companies/${store.signInCompany}/teams/${teamId}`);

  if (store.teamsLoading) {
    return null;
  }

  const team = store.getTeam(teamId);

  if (!store.teamsLoading && !team) {
    return <Redirect to="/settings/me/profile" />;
  }
  if (!team) {
    return null;
  }
  const availableMembers: TeamMemberEntry[] = getEntries(
    store,
    store.users.filter((u) => !team.isMember(u.id))
  );
  const addedMembers = getEntries(
    store,
    store.users.filter((u) => team.isMember(u.id))
  );
  const onAddMember = async (id: string) => {
    await updateDoc(teamRef, {
      [`roles.${id}`]: 'member',
    })
      .then(() => {
        logEvent(eventNames.add_member_to_team);
        showToast('success', 'メンバーを追加しました');
      })
      .catch((e) => {
        console.error(e);
        showToast('error', 'メンバーの追加に失敗しました');
      });
  };
  const onRemoveMember = async (id: string) => {
    await updateDoc(teamRef, {
      [`roles.${id}`]: deleteField(),
    })
      .then(() => {
        showToast('success', 'メンバーを除外しました');
      })
      .catch((e) => {
        console.error(e);
        showToast('error', 'メンバーの除外に失敗しました');
      });
  };
  return (
    <TeamMembers
      availableMembers={availableMembers}
      addedMembers={addedMembers}
      onAddMember={onAddMember}
      onRemoveMember={onRemoveMember}
      readonly={!store.me.isAdmin}
    />
  );
});

const getEntries = (store: Store, users: User[]): TeamMemberEntry[] => {
  const meId = store.me.id;
  return users.map((u) => ({
    id: u.id,
    name: u.name,
    email: u.email,
    isMe: u.id === meId,
    joinedTeamCount: store.joinedTeamLength(u.id),
    avatar: <Avatar user={u} />,
  }));
};
