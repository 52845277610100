export const DeliveryMessageCardSkeleton = () => {
  return (
    <div className="m-0 grid w-full cursor-pointer grid-cols-[240px_2fr] gap-4 rounded-lg border border-sumi-300 bg-transparent p-4 text-left text-sm text-black">
      <div className="flex flex-col gap-4">
        <div className="h-4 w-[80%] animate-pulse rounded bg-sumi-100" />
        <div className="grid grid-cols-[auto_1fr] items-center gap-2">
          <div className="h-4 w-4 animate-pulse rounded-full bg-sumi-100" />
          <div className="h-4 w-[30%] animate-pulse rounded bg-sumi-100" />
        </div>
        <div className="h-4 w-[70%] animate-pulse rounded bg-sumi-100" />
      </div>
      <div className="flex flex-col gap-4">
        <div className="h-4 w-[80%] animate-pulse rounded bg-sumi-100" />
        <div className="h-4 w-[60%] animate-pulse rounded bg-sumi-100" />
        <div className="h-4 w-[70%] animate-pulse rounded bg-sumi-100" />
        <div className="h-4 w-[30%] animate-pulse rounded bg-sumi-100" />
        <div className="h-4 w-[80%] animate-pulse rounded bg-sumi-100" />
      </div>
    </div>
  );
};
