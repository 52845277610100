import { Input } from '../../../../../components/forms';
import { InputGroup } from '../../../../../components/forms/InputGroup/InputGroup';

type Props = {
  value: string;
  onChange: (value: string) => void;
  readonly: boolean;
  largeLabel?: boolean;
};

export const SenderNameField = ({
  value,
  onChange,
  readonly,
  largeLabel,
}: Props) => {
  return (
    <InputGroup
      label={
        <span className={largeLabel ? 'text-base font-bold' : undefined}>
          送信先に表示される名前
        </span>
      }
      description={
        <>
          送信先に表示される名称を設定できます。{'{{me.name}}'}
          を入力することで、ログインユーザーの名前に置き換わります。
          <br />
          例）Onebox {'{{me.name}}'} → Onebox 奥村'
        </>
      }
    >
      <Input
        placeholder="〇〇株式会社"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        disabled={readonly}
      />
    </InputGroup>
  );
};
