import { Product } from '../Product';

type Props = {
  product: Product;
  version: number;
};

export const PlanLimitItems: React.FC<Props> = ({ product, version }) => {
  return (
    <div className="mb-6 last-of-type:mb-0">
      <PlanLimitItem
        label="共有アドレス数:"
        value={
          product.maxInboxes === null ? (
            <>無制限</>
          ) : (
            <>
              {product.maxInboxes}個
              <span className="text-xs font-normal">まで</span>
            </>
          )
        }
      />
      <PlanLimitItem
        label="ユーザー数:"
        value={
          product.maxUsers === null ? (
            <>無制限</>
          ) : (
            <>
              {product.maxUsers}名
              <span className="text-xs font-normal">まで</span>
            </>
          )
        }
      />
      {version >= 7 && (
        <>
          <PlanLimitItem
            label="テンプレート数:"
            value={
              product.maxTemplates === null ? (
                <>無制限</>
              ) : (
                <>
                  {product.maxTemplates}個
                  <span className="text-xs font-normal">まで</span>
                </>
              )
            }
          />
          <PlanLimitItem
            label="ユーザグループ:"
            value={
              product.maxUserGroups === null ? (
                <>無制限</>
              ) : (
                <>
                  {product.maxUserGroups}個
                  <span className="text-xs font-normal">まで</span>
                </>
              )
            }
          />
        </>
      )}
    </div>
  );
};

const PlanLimitItem: React.FC<{ label: string; value: any }> = ({
  label,
  value,
}) => {
  return (
    <div className="flex items-center gap-2 text-center leading-normal">
      <div className="w-2/5 text-right text-[10px]">{label}</div>
      <div className="text-xl font-bold text-[#1A1A1C]">{value}</div>
    </div>
  );
};
