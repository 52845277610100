import { ComponentPropsWithoutRef } from 'react';
import { twMerge } from 'tailwind-merge';

type Props = ComponentPropsWithoutRef<'h1'>;

export const SettingPageTitle = ({ className, children, ...props }: Props) => {
  return (
    <h1 className={twMerge(className, 'mb-0 text-[20px] font-bold')} {...props}>
      {children}
    </h1>
  );
};
