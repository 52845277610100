import {
  CommentInput,
  CommentInputHandle,
} from '../../../../components/comment/CommentInput/CommentInput';
import { useAtomValue } from 'jotai';
import { companyAtom, meAtom } from '../../../../atoms/auth';
import { ComponentProps, useEffect, useRef, useState } from 'react';
import { joinedTeamsAtom } from '../../../../atoms/firestore/team';
import { usersAtom } from '../../../../atoms/firestore/user';
import { Avatar } from '../../../../components/basics/Avatar/Avatar';
import { groupsAtom } from '../../../../atoms/firestore/group';
import { companyCollection, db9 } from '../../../../firebase';
import {
  doc,
  DocumentReference,
  runTransaction,
  serverTimestamp,
  Timestamp,
} from 'firebase/firestore';
import { CommentData, MessageData } from 'lib';
import { companyDoc } from '../../../../firestore';
import { extractMentionedUsers } from '../../../../util';
import { eventNames, logEvent } from '../../../../analytics';
import { getScrollContainerId } from '../util';
import { animateScroll } from 'react-scroll';
import { sumBy } from 'lodash';
import {
  useCommentInputReply,
  useUpdateCommentInputReply,
} from '../../../../components/comment/CommentInput/CommentInputReplyProvider';
import { useUpload } from '../../../../hooks/upload/useUpload';
import { UploadFile, UploadingFile } from '../../../../hooks/upload';
import { useToast } from '../../../../hooks/useToast';

type Props = {
  messageId: string;
  threadId: string;
  inboxId: string;
  teamId: string;
};

type MentionTarget = NonNullable<
  ComponentProps<typeof CommentInput>['mentionTargets']
>[number];

export const ConversationCommentInput = ({
  messageId,
  threadId,
  inboxId,
  teamId,
}: Props) => {
  const inputHandleRef = useRef<CommentInputHandle>(null);
  const company = useAtomValue(companyAtom);
  const me = useAtomValue(meAtom);
  const teams = useAtomValue(joinedTeamsAtom);
  const users = useAtomValue(usersAtom);
  const groups = useAtomValue(groupsAtom);
  const replyData = useCommentInputReply();
  const updateReplyData = useUpdateCommentInputReply();
  const { showToast } = useToast();
  const [commentId, setCommentId] = useState(() => generateCommentId());

  const uploadPath = (uploadingFile: UploadingFile) => {
    return `companies/${company.id}/comments/${commentId}/attachments/${uploadingFile.id}/${uploadingFile.file.name}`;
  };

  const validateFileUpload = (file: File, files: UploadFile[]): boolean => {
    const totalSize = sumBy(files, (f) => f.file.size);
    if ((file.size + totalSize) / 1024 / 1024 >= 15) {
      showToast('error', '添付ファイルの合計は15MBまでです');
      return false;
    }
    return true;
  };

  const metadata = () => {
    return {
      customMetadata: {
        teamId: teamId,
        uploader: me.id,
      },
    };
  };

  const { upload, remove, clearFiles, uploadedFiles, allUploaded } = useUpload({
    uploadPath,
    validate: validateFileUpload,
    metadata,
  });

  const onSubmit = async (content: string) => {
    if (!allUploaded) {
      return false;
    }

    const mentionedUsers = extractMentionedUsers(
      content,
      users,
      groups,
      teamMembers
    );
    const messageRef = companyDoc('messages', messageId);
    const commentData: Omit<CommentData, 'id' | 'edited'> = {
      teamId,
      commenter: me.id,
      messageId,
      messageRef: messageRef as DocumentReference<MessageData>,
      threadId,
      inboxId,
      text: content,
      mentionedUsers,
      unreadUsers: mentionedUsers,
      attachments: uploadedFiles.map((uploadedFile) => ({
        filename: uploadedFile.file.name,
        type: uploadedFile.file.type,
        size: uploadedFile.file.size,
        storagePath: uploadedFile.ref.fullPath,
      })),
      createdAt: serverTimestamp() as Timestamp,
      updatedAt: serverTimestamp() as Timestamp,
    };

    if (replyData) {
      commentData.replyTo = replyData.id;
    }

    const commentRef = companyDoc('comments', commentId);

    await runTransaction(db9, async (tx) => {
      // コメントを作成する
      tx.set(commentRef, commentData);
      tx.update(messageRef, {
        latestComment: {
          commentId,
          ...commentData,
        },
        [`readers.${me.id}`]: serverTimestamp(),
        updatedAt: serverTimestamp(),
      });
    });

    logEvent(eventNames.add_comment, { mentioned: mentionedUsers.length > 0 });

    setCommentId(generateCommentId());
    clearFiles();
    updateReplyData(undefined);

    setTimeout(() => {
      animateScroll.scrollToBottom({
        containerId: getScrollContainerId(),
        duration: 300,
      });
    }, 100);

    return true;
  };
  const onCancelReply = () => {
    updateReplyData(undefined);
  };

  useEffect(() => {
    if (replyData) {
      inputHandleRef.current?.focus();
    }
  }, [replyData]);

  const team = teams.find((team) => team.id === teamId);
  const teamMembers = team ? users.filter((u) => team.isMember(u.id)) : [];
  const mentionMembers: MentionTarget[] = [...teamMembers]
    .sort((a, b) => {
      const isUserATeamMember = teamMembers.some(
        (member) => member.id === a.id
      );
      const isUserBTeamMember = teamMembers.some(
        (member) => member.id === b.id
      );

      if (isUserATeamMember && !isUserBTeamMember) {
        return -1;
      }

      if (!isUserATeamMember && isUserBTeamMember) {
        return 1;
      }

      return 0;
    })
    .map((u) => {
      const isMember = team?.isMember(u.id) === true;
      return {
        icon: <Avatar size={24} user={u} />,
        label: isMember ? u.name : `(チーム外) ${u.name}`,
        value: u.name,
        disabled: !isMember,
      };
    });

  const mentionGroups: MentionTarget[] = groups
    .sort((groupA, groupB) => groupA.name.localeCompare(groupB.name))
    .map((group) => ({
      icon: <Avatar size={24} name={group.name} />,
      label: group.name,
      value: group.name,
    }));

  return (
    <CommentInput
      user={me}
      attachments={uploadedFiles.map((f) => ({
        id: f.id,
        name: f.file.name,
      }))}
      onUploadAttachment={upload}
      onRemoveAttachment={remove}
      onSubmit={onSubmit}
      mentionTargets={[
        {
          icon: <Avatar size={24} name="全員" />,
          label: 'all (チーム全員にメンションします)',
          value: 'all',
        },
        ...mentionGroups,
        ...mentionMembers,
      ]}
      reply={
        replyData
          ? {
              name: replyData.name,
              content: replyData.content,
            }
          : undefined
      }
      onCancelReply={onCancelReply}
      placeholder="チーム内コメント（@でメンション）"
      disabled={me.isReadOnly}
      ref={inputHandleRef}
    />
  );
};

const generateCommentId = () => doc(companyCollection('comments')).id;
