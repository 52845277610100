import { FormEvent, ReactNode } from 'react';
import { SettingPageContainer } from '../../Settings/common/SettingPageContainer/SettingPageContainer';
import { Input } from '../../../components/forms';
import { Icon } from '../../../components/basics';
import { Search } from '../../../components/icons';

type Props = {
  buttons: ReactNode;
  onSearch: (keyword: string) => void;
  children: ReactNode;
  searchInputKey?: string;
};

export const ContactsLayout = ({
  buttons,
  onSearch,
  children,
  searchInputKey,
}: Props) => {
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    const keyword = (
      (e.target as HTMLFormElement).elements[0] as HTMLInputElement
    ).value;
    onSearch(keyword);
  };
  return (
    <SettingPageContainer title="お客さま情報" full>
      <div className="mb-2 grid grid-cols-2">
        <form
          action=""
          onSubmit={handleSubmit}
          className="relative max-w-[400px]"
        >
          <div className="pointer-events-none absolute left-0 top-0 flex h-10 w-10 items-center justify-center">
            <Icon
              icon={Search}
              size={24}
              className="text-sumi-500"
              name="keyword"
            />
          </div>
          <Input
            key={searchInputKey}
            placeholder="キーワードで検索"
            className="border-none bg-sumi-50 pl-10 text-sm"
          />
        </form>
        <div className="flex justify-end gap-2">{buttons}</div>
      </div>
      <div className="text-sm">{children}</div>
    </SettingPageContainer>
  );
};
