import { Drafts } from '../MessageList/Me/Drafts';
import { useStore } from 'hooks/useStore';
import { BaseMessagePane } from './BaseMessagePane';
import { MessageListEmpty } from 'components/MessageList';
import { observer } from 'mobx-react';
import { draftsAtom } from '../../../atoms/firestore/draft';
import { useAtomValue } from 'jotai';

export const PATH = '/me/drafts';

// 自分 - 下書き
export const MessagePaneMeDraft = observer(() => {
  const draftsPaginate = useAtomValue(draftsAtom);

  const { searchStore } = useStore();

  const getEmptyDescription = () => {
    const { inSearch } = searchStore;
    let description = '下書きはありません';
    if (inSearch) {
      description = `「検索条件と一致する` + description;
    }
    return description;
  };

  return (
    <BaseMessagePane
      listPagePath={PATH}
      empty={draftsPaginate.state === 'hasData' && !draftsPaginate.data.length}
      emptyComponent={<MessageListEmpty description={getEmptyDescription()} />}
      listComponent={<Drafts />}
    />
  );
});
