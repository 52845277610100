import * as React from 'react';
import type { SVGProps } from 'react';
const SvgConversation = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M15.958 17.5c-.57 0-1.066-.17-1.49-.51-.424-.34-.69-.754-.802-1.24H9.229c-.917 0-1.678-.302-2.282-.906-.604-.604-.906-1.365-.906-2.281 0-.917.302-1.678.906-2.282.604-.604 1.365-.906 2.282-.906h1.604c.57 0 1.034-.18 1.396-.542.36-.36.541-.826.541-1.396 0-.569-.18-1.034-.541-1.395-.362-.361-.827-.542-1.396-.542h-4.5a2.32 2.32 0 0 1-.813 1.24c-.416.34-.91.51-1.479.51-.667 0-1.23-.23-1.687-.688-.459-.458-.688-1.02-.688-1.687s.23-1.23.688-1.688C2.812 2.73 3.374 2.5 4.04 2.5c.57 0 1.063.17 1.48.51.416.34.687.754.812 1.24h4.5c.916 0 1.677.302 2.28.906.605.604.907 1.365.907 2.282 0 .916-.302 1.677-.906 2.28-.604.605-1.365.907-2.281.907H9.229c-.57 0-1.035.18-1.396.542-.361.36-.542.826-.542 1.396 0 .569.18 1.034.542 1.395.36.361.826.542 1.396.542h4.437c.125-.486.396-.9.813-1.24.416-.34.91-.51 1.479-.51.666 0 1.229.23 1.687.688.459.458.688 1.02.688 1.687s-.23 1.23-.688 1.688c-.458.458-1.02.687-1.687.687ZM4.04 6c.32 0 .587-.108.802-.323.215-.215.323-.483.323-.802 0-.32-.108-.587-.323-.802a1.089 1.089 0 0 0-.802-.323c-.32 0-.587.108-.802.323a1.089 1.089 0 0 0-.323.802c0 .32.108.587.323.802.215.215.483.323.802.323Z"
    />
  </svg>
);
export default SvgConversation;
