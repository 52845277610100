import {
  ComponentPropsWithoutRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  EditorHandle,
  WysiwygEditor,
} from '../../Common/Editor/WysiwygEditor/WysiwygEditor';
import { debounce } from 'lodash';
import { Transaction } from '@tiptap/pm/state';
import { Node } from '@tiptap/pm/model';
import { shouldSubmit } from '../../../utils/keyboard';

type Props = ComponentPropsWithoutRef<typeof WysiwygEditor> & {
  onChange: (plainText: string, html: string) => void;
  plainTextMode: boolean;
  onCtrlEnter?: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onInsertImage?: (contentId: string) => void;
  onDeleteImage?: (contentId: string) => void;
  bubbleMenuContainerId: string;
};

export const CreateMessageWysiwygEditor = ({
  onChange,
  plainTextMode,
  onCtrlEnter,
  onFocus,
  onBlur,
  onInsertImage,
  onDeleteImage,
  initEditorHandle,
  bubbleMenuContainerId,
  ...props
}: Props) => {
  const [handle, setHandle] = useState<EditorHandle | undefined>();
  const lastUpdatedValueRef = useRef('');
  const notifyChanges = useCallback(() => {
    if (!handle) {
      return;
    }
    const html = handle.getHtml();
    if (lastUpdatedValueRef.current === html) {
      return;
    }
    onChange(handle.getText(), html);
    lastUpdatedValueRef.current = html;
  }, [handle, onChange]);
  useEffect(() => {
    const editor = handle?.editor;
    if (!editor) {
      return;
    }
    const onUpdate = debounce(notifyChanges, 500, {
      maxWait: 2000,
    });
    editor.on('blur', notifyChanges);
    editor.on('update', onUpdate);
    return () => {
      editor.off('blur', notifyChanges);
      editor.off('update', onUpdate);
    };
  }, [handle?.editor, notifyChanges]);
  useEffect(() => {
    const editor = handle?.editor;
    if (!editor) {
      return;
    }
    const onKeyDown = (e: KeyboardEvent) => {
      if (!shouldSubmit(e) || !editor.isFocused) {
        return;
      }

      notifyChanges();
      onCtrlEnter?.();
      e.preventDefault();
      e.stopPropagation();
    };
    window.addEventListener('keydown', onKeyDown, true);
    return () => {
      window.removeEventListener('keydown', onKeyDown, true);
    };
  }, [onCtrlEnter, handle?.editor, notifyChanges]);
  useEffect(() => {
    const editor = handle?.editor;
    if (!editor) {
      return;
    }
    if (onFocus) {
      editor.on('focus', onFocus);
    }
    if (onBlur) {
      editor.on('blur', onBlur);
    }
    return () => {
      if (onFocus) {
        editor.off('focus', onFocus);
      }
      if (onBlur) {
        editor.off('blur', onBlur);
      }
    };
  }, [onFocus, onBlur, handle?.editor]);
  useEffect(() => {
    const editor = handle?.editor;
    if (!editor) {
      return;
    }

    const onUpdate = ({ transaction }: { transaction: Transaction }) => {
      const beforeNodes = new Set<Node>();
      const afterNodes = new Set<Node>();
      transaction.before.descendants((node) => {
        if (node.type.name === 'image' && node.attrs['data-content_id']) {
          beforeNodes.add(node);
        }
      });
      transaction.doc.descendants((node) => {
        if (node.type.name === 'image' && node.attrs['data-content_id']) {
          afterNodes.add(node);
        }
      });

      for (const afterNode of afterNodes) {
        if (
          ![...beforeNodes].some(
            (beforeNode) =>
              beforeNode.attrs['data-content_id'] ===
              afterNode.attrs['data-content_id']
          )
        ) {
          onInsertImage?.(afterNode.attrs['data-content_id']);
        }
      }

      for (const beforeNode of beforeNodes) {
        if (
          ![...afterNodes].some(
            (afterNode) =>
              afterNode.attrs['data-content_id'] ===
              beforeNode.attrs['data-content_id']
          )
        ) {
          onDeleteImage?.(beforeNode.attrs['data-content_id']);
        }
      }
    };

    editor.on('update', onUpdate);
    return () => {
      editor.off('update', onUpdate);
    };
  }, [handle?.editor, onInsertImage, onDeleteImage]);
  return (
    <WysiwygEditor
      initEditorHandle={(handle) => {
        setHandle(handle);
        initEditorHandle?.(handle);
      }}
      getBubbleMenuContainer={() =>
        document.getElementById(bubbleMenuContainerId)!
      }
      {...props}
    />
  );
};
