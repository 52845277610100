import { useStore } from 'hooks/useStore';
import { BaseMessagePane } from './BaseMessagePane';
import { useLayoutEffect } from 'react';
import { useParams } from 'react-router-dom';
import { LineThreads } from '../MessageList/Teams/Line';
import { MessageListEmpty } from 'components/MessageList';
import { observer } from 'mobx-react';

export const PATHS = [
  `/teams/:teamId/lineaccounts/:lineAccountId/:tab`,
  `/teams/:teamId/lineaccounts/:lineAccountId/:tab/:lineThreadId`,
];

// LINE
export const MessagePaneLineThreads: React.FC = observer(() => {
  const { lineStore } = useStore();
  const { lineAccountId } = useParams<{
    lineAccountId: string;
  }>();

  useLayoutEffect(() => {
    lineStore.lineAccountId = lineAccountId;
    lineStore.syncLineThreads();
  }, [lineAccountId]);

  const empty = lineStore.threads.length === 0 && !lineStore.loadingThread;

  return (
    <BaseMessagePane
      listPagePath={PATHS}
      empty={empty}
      emptyComponent={<MessageListEmpty description="メッセージはありません" />}
      listComponent={<LineThreads />}
    />
  );
});
