import { SettingObjectRoutes } from '../../../common';
import { observer } from 'mobx-react';
import { useStore } from '../../../../../hooks/useStore';
import { useToast } from '../../../../../hooks/useToast';
import {
  FilterAction,
  FilterEditDrawer,
  FilterUpdate,
} from '../FilterEditDrawer/FilterEditDrawer';
import { Filters } from './Filters';
import {
  ComponentProps,
  ComponentPropsWithoutRef,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  addDoc,
  deleteDoc,
  serverTimestamp,
  updateDoc,
  writeBatch,
} from 'firebase/firestore';
import { db9 } from '../../../../../firebase';
import { isEqual } from 'lodash';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import Avatar from '../../../../Common/Avatar';
import { eventNames, logEvent } from '../../../../../analytics';
import { Store } from '../../../../../store';
import { FilterAction as LibFilterAction } from 'lib';
import {
  convertToClientConditions,
  convertToServerConditions,
} from './convert';

type Props = {
  teamId: string;
  routes: SettingObjectRoutes<{ teamId: string }, 'filterId'>;
};

type FilterEditDrawerProps = ComponentPropsWithoutRef<typeof FilterEditDrawer>;

export const FiltersWithLogic = observer(({ teamId, routes }: Props) => {
  const store = useStore();
  const readonly = store.me.isReadOnly;
  const { showToast } = useToast();

  const filters = store.getFilters(teamId);
  const latestFiltersRef = useRef<{ id: string; name: string }[]>([]);
  const [optimisticFilters, setOptimisticFilters] = useState<
    {
      id: string;
      name: string;
      stopOtherFilters: boolean;
    }[]
  >([]);
  useEffect(() => {
    const updatedFilters = filters.map((f) => ({
      id: f.id,
      name: f.name,
      stopOtherFilters: f.stopOtherFilters ?? false,
    }));
    if (isEqual(latestFiltersRef.current, updatedFilters)) {
      return;
    }
    latestFiltersRef.current = updatedFilters;
    setOptimisticFilters(updatedFilters);
  }, [filters]);

  const handleUpdate = async (id: string | undefined, update: FilterUpdate) => {
    const data = {
      name: update.name,
      inboxIds: update.inboxIds,
      conditions: convertToServerConditions(update.conditions),
      trigger: update.trigger,
      actions: update.actions,
      stopOtherFilters: update.stopOtherFilters,
      teamId,
    };

    if (id) {
      await updateDoc(store.doc('filters', id), {
        ...data,
        updatedAt: serverTimestamp(),
      })
        .then(() => {
          showToast('success', 'フローを更新しました');
          routes.toIndex({ teamId });
        })
        .catch((e) => {
          console.error(e);
          showToast('error', 'フローの更新に失敗しました');
        });
    } else {
      const order =
        filters.reduce(
          (accumulator, current) =>
            accumulator > current.order ? accumulator : current.order,
          -1
        ) + 1;
      await addDoc(store.collection('filters'), {
        ...data,
        order,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      })
        .then(() => {
          logEvent(eventNames.add_filter);
          showToast('success', 'フローを作成しました');
          routes.toIndex({ teamId });
        })
        .catch((e) => {
          console.error(e);
          showToast('error', 'フローの作成に失敗しました');
        });
    }
  };

  const handleDelete = async (id: string) => {
    await deleteDoc(store.doc('filters', id))
      .then(() => {
        showToast('success', 'フローを削除しました');
      })
      .catch((e) => {
        console.error(e);
        showToast('error', 'フローの削除に失敗しました');
      });
  };

  const handleSort: ComponentProps<typeof Filters>['onSort'] = async (
    sortedFilters
  ) => {
    setOptimisticFilters(sortedFilters);

    const batch = writeBatch(db9);
    sortedFilters.forEach((filter, i) => {
      batch.update(store.doc('filters', filter.id), {
        order: i,
      });
    });

    await batch.commit();
  };

  const inboxes: FilterEditDrawerProps['inboxes'] = store
    .getTeamInboxes(teamId)
    .map((inbox) => ({ id: inbox.id, email: inbox.email }));

  const members: FilterEditDrawerProps['members'] = store
    .getUsersByTeamId(teamId)
    .map((user) => ({
      id: user.id,
      name: user.name,
      renderAvatar: () => <Avatar user={user} size={24} />,
    }));

  const tags: FilterEditDrawerProps['tags'] = store
    .getTags(teamId)
    .map((tag) => ({ id: tag.id, name: tag.name }));

  return (
    <>
      <Filters
        filters={optimisticFilters}
        loading={store.filtersLoading}
        readonly={readonly}
        onSort={handleSort}
        onOpenCreateDrawer={() => routes.toNew({ teamId })}
        onOpenEditDrawer={(id) => routes.toDetail({ teamId, filterId: id })}
      />
      <Switch>
        {!readonly && (
          <Route exact path={routes.paths.new}>
            <FilterEditDrawer
              filterId={undefined}
              filter={{}}
              inboxes={inboxes}
              members={members}
              tags={tags}
              onUpdate={handleUpdate}
              onDelete={handleDelete}
              open={true}
              onOpenChange={() => routes.toIndex({ teamId })}
              readonly={readonly}
            />
          </Route>
        )}
        <Route exact path={routes.paths.detail}>
          <EditDrawer
            store={store}
            path={routes.paths.detail}
            inboxes={inboxes}
            members={members}
            tags={tags}
            onUpdate={handleUpdate}
            onDelete={handleDelete}
            open={true}
            onOpenChange={() => routes.toIndex({ teamId })}
            readonly={readonly}
          />
        </Route>
      </Switch>
    </>
  );
});

type EditDrawerProps = Omit<FilterEditDrawerProps, 'filterId' | 'filter'> & {
  store: Store;
  path: string;
};

const EditDrawer = ({ store, path, ...props }: EditDrawerProps) => {
  const detailMatch = useRouteMatch<{ filterId: string }>(path);

  if (!detailMatch) {
    return null;
  }

  const filter = store.getFilter(detailMatch.params.filterId);
  if (!filter) {
    return null;
  }

  return (
    <FilterEditDrawer
      key={detailMatch.params.filterId}
      filterId={detailMatch.params.filterId}
      filter={{
        name: filter.name,
        inboxIds: filter.inboxIds,
        trigger: filter.trigger,
        conditions: convertToClientConditions(filter.conditions),
        actions: convertActions(filter.actions),
        stopOtherFilters: filter.stopOtherFilters,
      }}
      {...props}
    />
  );
};

const convertActions = (actions: LibFilterAction[]): FilterAction[] => {
  return actions.map((action) => {
    if (action.type === 'assign') {
      return {
        id: action.id,
        type: action.type,
        assignee: action.assignee ?? '',
      };
    } else if (action.type === 'addTag') {
      return {
        id: action.id,
        type: action.type,
        tags: action.tags ?? [],
      };
    } else {
      return {
        id: action.id,
        type: action.type,
      };
    }
  });
};
