import { H3 } from 'App/Common/H3';
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

export const SettingField: React.FC<
  PropsWithChildren<{
    label: string;
    hidden?: boolean;
  }>
> = ({ label, hidden, children }) => {
  if (hidden) {
    return null;
  }

  return (
    <SettingFieldWrapper>
      <H3 className="label">{label}</H3>
      {children}
    </SettingFieldWrapper>
  );
};

const SettingFieldWrapper = styled.div`
  & > .label {
    margin-bottom: 16px;
  }
`;
