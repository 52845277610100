import { Alert, Button } from 'antd';
import React from 'react';
import styled from 'styled-components';

type Props = {
  target: string;
  isIntegrated: boolean;
  gotoIntegration: () => void;
};

export const UserNotificationAlert: React.FC<Props> = ({
  target,
  isIntegrated,
  gotoIntegration,
}) => {
  return (
    <Alert
      message={
        <MesasgeWrapper>
          <div>
            {isIntegrated
              ? '通知に必要な権限の取得のため再連携の必要があります'
              : `${target}にyaritoriを連携すると通知を設定できます`}
          </div>
          <div>
            <Button onClick={gotoIntegration} type="primary">
              連携設定
            </Button>
          </div>
        </MesasgeWrapper>
      }
    />
  );
};

const MesasgeWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
