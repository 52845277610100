import React from 'react';
import styled from 'styled-components';
import clip from '../../../assets/icons/clip.png';
import { List } from 'antd';

export default ({ attachments }) => {
  const nonRelatedAttachments = attachments.filter((a) => !a.related);
  if (nonRelatedAttachments.length === 0) return <></>;

  return (
    <>
      <List
        itemLayout="horizontal"
        dataSource={nonRelatedAttachments}
        renderItem={(attachment) => (
          <List.Item>
            <Attachments>
              <IconWrapper>
                <Icon src={clip} />
              </IconWrapper>
              <AttachmentText>
                {attachment.filename ? attachment.filename : 'file'}
              </AttachmentText>
            </Attachments>
          </List.Item>
        )}
      />
    </>
  );
};

const Attachments = styled.div`
  display: flex;
  align-items: center;
`;

const IconWrapper = styled.div`
  margin-right: 6px;
`;

const Icon = styled.img`
  width: 18px;
  height: 18px;
`;

const AttachmentText = styled.p`
  padding: 0;
  margin: 0;
  white-space: break-spaces;
  text-align: left;
  color: #3577ef;
`;
