import React from 'react';
import styled from 'styled-components';
import { Icon, Spin, Tooltip } from 'antd';
import * as color from '../../../../../color';
import { SlackChannelSelect } from '../SlackChannelSelect';
import { Team as TeamEntity } from 'lib';
import { Inbox } from '../../../../../firestore/entity/inbox';
import { serverTimestamp, updateDoc } from 'firebase/firestore';

const onSelect = async (inbox: Inbox, value: any) => {
  await updateDoc(inbox.ref, {
    slackIntegrationChannel: value,
    updatedAt: serverTimestamp(),
  });
};

type Props = {
  team: TeamEntity;
  inboxes: Inbox[];
  channels: any[];
  channelsLoading: boolean;
  isAdmin: boolean;
};

export const SlackIntegrationTeam = ({
  team,
  inboxes,
  channels,
  channelsLoading,
  isAdmin,
}: Props): React.ReactElement => {
  return (
    <Team>
      <TeamNameWrapper>
        <TeamName>{team.name}</TeamName>
      </TeamNameWrapper>

      {inboxes.map((inbox) => (
        <InboxContainer key={inbox.id}>
          <div>{inbox.email}</div>
          {channelsLoading ? (
            <Spin
              indicator={<Icon type="loading" style={{ fontSize: 12 }} spin />}
              spinning={channelsLoading}
            />
          ) : (
            <Tooltip title="権限がありません" visible={!isAdmin}>
              <SlackChannelSelect
                style={{ width: 200 }}
                channelId={inbox.slackIntegrationChannel}
                channels={channels}
                loading={channelsLoading}
                disabled={channelsLoading || !isAdmin}
                onSelectChannelId={(value) => onSelect(inbox, value)}
              />
            </Tooltip>
          )}
        </InboxContainer>
      ))}
    </Team>
  );
};

const Team = styled.div`
  max-width: 600px;
  margin-bottom: 20px;
`;

const TeamNameWrapper = styled.div`
  display: flex;
  margin-bottom: 16px;
`;

const TeamName = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: ${color.common.text2};
  margin-right: 16px;
`;

const InboxContainer = styled.div`
  min-width: 400px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;
