import { useEffect, useRef } from 'react';
import { useToast } from '../../../../../hooks/useToast';
import { inboxOAuthCallbackFunction } from '../../../../../functions';
import { getOAuthRedirectUri } from '../../../../../util';
import { InboxType } from 'lib';

type Service = 'google' | 'outlook';

export type AuthData = {
  service: Service;
  email: string;
  id: string;
};

export const useOAuthHandler = (
  inboxType: InboxType,
  companyId: string,
  teamId: string,
  onAuth: (data: AuthData) => Promise<void>
): (() => Promise<void>) => {
  const closedAtRef = useRef<number>(0);
  const processingRef = useRef(false);
  const authenticatingRef = useRef(false);
  const erroredRef = useRef(false);
  const authDataRef = useRef<AuthData | undefined>(undefined);
  const { showToast } = useToast();

  useEffect(() => {
    window.addEventListener('message', handleOAuthCallback);
    return () => {
      window.removeEventListener('message', handleOAuthCallback);
    };
  }, []);

  const handleOAuthCallback = async (e: {
    data: {
      type: string;
      query: { code: string };
      service: 'google' | 'outlook';
    };
  }) => {
    const { type, query, service } = e.data;
    if (
      type === 'oauth-callback' &&
      query.code &&
      inboxType === service &&
      !authenticatingRef.current
    ) {
      authenticatingRef.current = true;
      try {
        const oAuthRes: { data: Omit<AuthData, 'service'> } =
          await inboxOAuthCallbackFunction({
            service,
            companyId,
            teamId,
            code: query.code,
            redirectUri: getOAuthRedirectUri(service),
          });
        authDataRef.current = { service, ...oAuthRes.data };
      } catch (e) {
        erroredRef.current = true;
        showToast('error', '連携に失敗しました');
        throw e;
      }
    }
  };

  const startAuth = async (openPopup: () => Window) => {
    if (processingRef.current) {
      return;
    }

    processingRef.current = true;
    const child = openPopup();
    let intervalId: unknown;
    await new Promise<void>((resolve) => {
      intervalId = setInterval(() => {
        if (closedAtRef.current === 0 && child.closed) {
          closedAtRef.current = Date.now();
          return;
        }

        // 認証中でなく、子ウィンドウが閉じられてから1秒たっても認証中にならなかったら中断する
        if (
          !authenticatingRef.current &&
          closedAtRef.current > 0 &&
          Date.now() - closedAtRef.current >= 1000
        ) {
          resolve();
          return;
        }

        if (erroredRef.current) {
          resolve();
          return;
        }

        if (authDataRef.current) {
          const authData = authDataRef.current!;
          onAuth(authData)
            .finally(() => resolve())
            .catch((e) => console.error(e));
          clearInterval(intervalId as never);
        }
      }, 1000);
    }).finally(() => {
      clearTimeout(intervalId as never);
      processingRef.current = false;
      authDataRef.current = undefined;
      erroredRef.current = false;
      authenticatingRef.current = false;
      closedAtRef.current = 0;
    });
  };

  return async () => {
    if (inboxType === 'email') {
      return;
    }
    switch (inboxType) {
      case 'google':
        return startAuth(openGoogleOAuthPopup);
      case 'outlook':
        return startAuth(openOutlookOAuthPopup);
      default:
        throw new Error('Assertion error');
    }
  };
};

const openGoogleOAuthPopup = (): Window => {
  const popup = window.open(
    'about:blank',
    '',
    'status=no,location=no,toolbar=no,menubar=no,width=500,height=720'
  ) as Window;
  popup.document.write(
    `
<script src='https://accounts.google.com/gsi/client'></script>
<script>
 google.accounts.oauth2.initCodeClient({
  client_id: "${process.env.REACT_APP_GOOGLE_CLIENT_ID}",
  scope: "https://mail.google.com/ https://www.googleapis.com/auth/userinfo.email profile",
  ux_mode: "redirect",
  redirect_uri: "${getOAuthRedirectUri('google')}",
}).requestCode();
</script>
`
  );
  return popup;
};

const openOutlookOAuthPopup = (): Window => {
  const url =
    'https://login.microsoftonline.com/common/oauth2/v2.0/authorize?' +
    new URLSearchParams({
      client_id: process.env.REACT_APP_OUTLOOK_CLIENT_ID as string,
      scope:
        'offline_access user.read https://outlook.office.com/IMAP.AccessAsUser.All https://outlook.office.com/SMTP.Send',
      redirect_uri: getOAuthRedirectUri('outlook'),
      response_type: 'code',
      response_mode: 'query',
    });
  return window.open(
    url,
    '',
    'status=no,location=no,toolbar=no,menubar=no,width=500,height=720'
  ) as Window;
};
