import { getMarkAttributes, Mark, mergeAttributes } from '@tiptap/core';

export interface TextClassOptions {
  HTMLAttributes: Record<string, any>;
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    textClass: {
      removeEmptyTextStyle: () => ReturnType;
    };
  }
}

// https://tiptap.dev/docs/editor/api/marks/text-style のClass対応版
export const ExtendedTextStyle = Mark.create<TextClassOptions>({
  name: 'textStyle',

  priority: 101,

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  parseHTML() {
    return [
      {
        tag: 'span',
        getAttrs: (element) => {
          const hasStyles = (element as HTMLElement).hasAttribute('style');
          const hasClasses = (element as HTMLElement).hasAttribute('class');

          if (!hasStyles && !hasClasses) {
            return false;
          }

          return {};
        },
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'span',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ];
  },

  addCommands() {
    return {
      removeEmptyTextStyle:
        () =>
        ({ state, commands }) => {
          const attributes = getMarkAttributes(state, this.type);
          const hasStyles = Object.entries(attributes).some(
            ([, value]) => !!value
          );

          if (hasStyles) {
            return true;
          }

          return commands.unsetMark(this.name);
        },
    };
  },
});
