import React from 'react';
import { observer } from 'mobx-react';
import { InboxData } from 'lib';
import _ from 'lodash';
import { MyEmailRoutes } from './MyEmailRoutes';
import { MyEmailTabKey } from './MyEmailTabKey';
import {
  DocumentReference,
  serverTimestamp,
  updateDoc,
} from 'firebase/firestore';
import { MyEmailSettingsWithLogic } from './MyEmailSettings/MyEmailSettingsWithLogic';
import { MyEmailEmptyWithLogic } from './MyEmailEmpty/MyEmailEmptyWithLogic';
import { TabsSettingPageContainer } from '../../common/TabsSettingPageContainer/TabsSettingPageContainer';
import { useStore } from '../../../../hooks/useStore';
import { TemplatesWithLogic } from '../templates/Templates/TemplatesWithLogic';
import { SignaturesWithLogic } from '../signatures/Signatures/SignaturesWithLogic';
import { TagsWithLogic } from '../tags/Tags/TagsWithLogic';
import { FiltersWithLogic } from '../filters/Filters/FiltersWithLogic';
import { MyAutoReply } from './MyAutoReply';
import { MyEmailDetailEdit } from './MyEmailDetailEdit';

type Props = {
  routes: MyEmailRoutes;
  title: string;
};

export const MyEmailTabs: React.FC<Props> = observer(
  ({ title, routes }: Props): React.ReactElement => {
    const store = useStore();
    const isReadOnly = store.me.isReadOnly;

    const { me } = store;
    const teamId = store.privateTeam?.id;
    if (!teamId) {
      return <></>;
    }

    const inbox = _.first(store.getTeamInboxes(teamId));
    const signatures = store.getSignatures(teamId);

    const handleUpdateInbox = async (
      ref: DocumentReference<InboxData>,
      inbox: Partial<InboxData>
    ) => {
      // 更新
      await updateDoc(ref, {
        ...inbox,
        updatedAt: serverTimestamp(),
      });
    };

    return (
      <>
        <TabsSettingPageContainer
          title={title}
          pages={[
            {
              pathname: routes.getPathNameForTab(MyEmailTabKey.Inbox),
              label: 'メール送受信',
              element: () =>
                inbox ? (
                  <MyEmailSettingsWithLogic inbox={inbox} />
                ) : (
                  <MyEmailEmptyWithLogic routes={routes} />
                ),
            },
            {
              pathname: routes.getPathNameForTab(MyEmailTabKey.Template),
              label: 'テンプレート',
              element: () => (
                <TemplatesWithLogic
                  teamId={teamId}
                  routes={routes.templateRoutes}
                />
              ),
            },
            {
              pathname: routes.getPathNameForTab(MyEmailTabKey.Signature),
              label: '署名',
              element: () => (
                <SignaturesWithLogic
                  teamId={teamId}
                  routes={routes.signatureRoutes}
                />
              ),
            },
            {
              pathname: routes.getPathNameForTab(MyEmailTabKey.Tag),
              label: 'タグ',
              element: () => (
                <TagsWithLogic teamId={teamId} routes={routes.tagRoutes} />
              ),
            },
            {
              pathname: routes.getPathNameForTab(MyEmailTabKey.Filter),
              label: 'フロー',
              element: () => (
                <FiltersWithLogic
                  teamId={teamId}
                  routes={routes.filterRoutes}
                />
              ),
            },
            ...(inbox
              ? [
                  {
                    pathname: routes.getPathNameForTab(MyEmailTabKey.AutoReply),
                    label: '自動返信',
                    element: () => (
                      <MyAutoReply
                        update={handleUpdateInbox}
                        inbox={inbox}
                        autoReplySupported={store.isAutoReplySupported}
                        disabled={isReadOnly}
                      />
                    ),
                  },
                  {
                    pathname: routes.getPathNameForTab(MyEmailTabKey.Detail),
                    label: '詳細設定',
                    element: () => (
                      <MyEmailDetailEdit
                        update={handleUpdateInbox}
                        inbox={inbox}
                        signatures={signatures}
                        me={me}
                        disabled={isReadOnly}
                      />
                    ),
                  },
                ]
              : []),
          ]}
        />
      </>
    );
  }
);
