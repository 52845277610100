import { useState } from 'react';
import { Alert } from '../../../../../components/basics/Alert/Alert';
import { Button, Loading } from '../../../../../components/basics';
import { TemplateCategoryTabs } from '../TemplateCategoryTabs/TemplateCategoryTabs';
import { Tooltip } from '../../../../../components/basics/Tooltip/Tooltip';

type Template = {
  id: string;
  title: string;
  category: string | null;
};

type Props = {
  categories: string[];
  templates: Template[];
  onOpenCreateDrawer: () => void;
  onOpenEditDrawer: (templateId: string) => void;
  onUpdateCategory: (oldCategory: string, newCategory: string) => Promise<void>;
  onDeleteCategory: (category: string) => Promise<void>;
  canCreateTemplate: boolean;
  loading: boolean;
  readonly: boolean;
};

export const Templates = ({
  categories,
  templates,
  onOpenCreateDrawer,
  onOpenEditDrawer,
  onUpdateCategory,
  onDeleteCategory,
  canCreateTemplate,
  loading,
  readonly,
}: Props) => {
  const [category, setCategory] = useState<string | undefined>(undefined);
  const filteredTemplates = templates
    .filter((t) => category === undefined || t.category === category)
    .sort((a, b) => a.title.localeCompare(b.title));
  return (
    <div className="flex flex-col items-start gap-6 text-sm">
      {templates.length === 0 && !loading && (
        <Alert className="mb-4">
          テンプレートはよく使う文章を保存し、メール作成時にすぐに呼び出すことができる機能です。
        </Alert>
      )}
      <Tooltip
        content="上限に達したため、プランをアップグレードしてください。"
        visible={!canCreateTemplate}
      >
        <Button
          onClick={() => onOpenCreateDrawer()}
          disabled={readonly || !canCreateTemplate}
        >
          テンプレートを作成
        </Button>
      </Tooltip>
      <div className="flex max-w-full flex-col items-start gap-4">
        <div className="text-base font-bold">作成済みのテンプレート</div>
        <div className="max-w-full">
          {loading && (
            <div className="h-8">
              <Loading />
            </div>
          )}
          {!loading && (
            <TemplateCategoryTabs
              categories={categories}
              value={category}
              onChange={setCategory}
              onUpdateCategory={async (oldCategory, newCategory) => {
                try {
                  setCategory(newCategory);
                  await onUpdateCategory(oldCategory, newCategory);
                } catch (e) {
                  console.error(e);
                  setCategory(oldCategory);
                }
              }}
              onDeleteCategory={async (category) => {
                setCategory(undefined);
                await onDeleteCategory(category);
              }}
              readonly={readonly}
            />
          )}
        </div>
      </div>
      <div className="flex flex-col items-start gap-4">
        <div className="text-base font-bold">テンプレート名</div>
        {loading && <Loading />}
        {!loading && filteredTemplates.length <= 0 && (
          <div className="text-sumi-500">
            まだテンプレートが作成されていません
          </div>
        )}
        {!loading &&
          filteredTemplates.map((template, i) => (
            <button
              key={i}
              type="button"
              className="cursor-pointer bg-transparent p-0 text-sea-500"
              onClick={() => onOpenEditDrawer(template.id)}
            >
              {template.title}
            </button>
          ))}
      </div>
    </div>
  );
};
