import React, { useState, useCallback, useEffect } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { Icon } from 'antd';
import { PrinterOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import '../../../css/pdf.css';
import 'react-image-lightbox/style.css';

const PreviewPdfModal = ({ open, onClose, file, onDownload }) => {
  const [iframe, setIframe] = useState(null);
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [pdfContents, setPdfContents] = useState([]);

  const onLoadSuccess = useCallback(({ numPages }) => {
    setTotalPages(numPages);
  }, []);

  const onPrint = useCallback(() => {
    if (!iframeLoaded || !iframe) return;

    iframe.focus();
    iframe.contentWindow.print();
  }, [iframe, iframeLoaded]);

  // 印刷用にiframeにてpdfを読み込んでいる。
  useEffect(() => {
    if (!open) {
      return;
    }

    setIframeLoaded(false);
    const iframe = document.createElement('iframe');
    document.body.appendChild(iframe);
    iframe.style.display = 'none';

    iframe.onload = () => {
      setIframeLoaded(true);
    };

    const dataUrl = window.URL.createObjectURL(file);
    iframe.src = dataUrl;
    setIframe(iframe);
  }, [file, open]);

  useEffect(() => {
    if (!open) {
      return;
    }

    const _pdfContents = [];
    for (let i = 0; i < totalPages; i++) {
      const d = (
        <StyledPage
          key={i}
          pageNumber={i + 1}
          scale={4 / 3}
          renderAnnotationLayer={false}
        />
      );
      _pdfContents.push(d);
    }
    setPdfContents(_pdfContents);
  }, [open, totalPages]);

  if (!open) {
    return <></>;
  }

  return (
    <Root>
      <Toolbar>
        <ToolbarLeft />
        <ToolbarRight>
          <ToolbarItem>
            {iframeLoaded && (
              <ToolbarItemButton onClick={onPrint}>
                <PrinterOutlined
                  style={{ fontSize: 24, verticalAlign: 'initial' }}
                />
              </ToolbarItemButton>
            )}
            <ToolbarItemButton onClick={onDownload}>
              <Icon
                type="download"
                style={{ fontSize: 24, verticalAlign: 'initial' }}
              />
            </ToolbarItemButton>
            <button
              className="ril-close ril-toolbar__item__child ril__toolbarItemChild ril__builtinButton ril__closeButton"
              onClick={onClose}
            />
          </ToolbarItem>
        </ToolbarRight>
      </Toolbar>
      <Inner>
        <StyledDocument
          file={file}
          onLoadSuccess={onLoadSuccess}
          // NOTE: 日本語が上手く表示できないことがあるため、cmapを設定している。
          // https://github.com/wojtekmaj/react-pdf#support-for-non-latin-characters
          options={{
            cMapUrl: 'cmaps/',
            cMapPacked: true,
          }}
        >
          {pdfContents}
        </StyledDocument>
      </Inner>
    </Root>
  );
};

const Root = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
`;

const Toolbar = styled.div`
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
`;

const ToolbarLeft = styled.ul`
  padding-left: 20px;
  padding-right: 0;
  height: 50px;
  margin: 0;
`;

const ToolbarRight = styled.ul`
  padding-left: 0;
  padding-right: 20px;
  height: 50px;
  margin: 0;
`;

const ToolbarItem = styled.li`
  display: inline-block;
  line-height: 50px;
  color: #fff;
  font-size: 120%;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ToolbarItemButton = styled.button`
  width: 40px;
  height: 35px;
  cursor: pointer;
  border: none;
  opacity: 0.7;
  padding: 1px 6px;
  vertical-align: middle;
  background-color: transparent;
  &:hover {
    opacity: 1;
  }
`;

const Inner = styled.div`
  width: 100%;
  height: 100%;
  overflow: scroll;
  padding-top: 50px;
  background-color: rgba(0, 0, 0, 0.85);
`;

const StyledDocument = styled(Document)`
  height: 100%;
`;

const StyledPage = styled(Page)`
  margin: 0 auto 20px;
  margin-bottom
`;

export default PreviewPdfModal;

export const isPdfMimeType = (mimeType = '') => mimeType === 'application/pdf';
