type Props = {
  className?: string;
  children: React.ReactNode;
};

const MessageItemTag = ({ className, children }: Props) => (
  <span className={`${className} rounded-lg px-2 text-xs leading-6`}>
    {children}
  </span>
);

export default MessageItemTag;
