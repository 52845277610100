// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*noinspection CssInvalidHtmlTagReference*/\n.aomHMUV3b1XDUS53ApM5 em-emoji-picker {\n  width: 100%;\n}\n", "",{"version":3,"sources":["webpack://./src/components/comment/Comment/Comment.module.css"],"names":[],"mappings":"AAAA,0CAA0C;AAC1C;EACE,WAAW;AACb","sourcesContent":["/*noinspection CssInvalidHtmlTagReference*/\n.mobileEmojiPickerWrapper em-emoji-picker {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mobileEmojiPickerWrapper": "aomHMUV3b1XDUS53ApM5"
};
export default ___CSS_LOADER_EXPORT___;
