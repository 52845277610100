import * as React from 'react';
import type { SVGProps } from 'react';
const SvgCreateMessageHtmlToolbar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M4 24c-.483 0-.896-.17-1.237-.512a1.686 1.686 0 0 1-.513-1.238c0-.483.17-.896.513-1.238A1.686 1.686 0 0 1 4 20.5h16c.483 0 .896.17 1.238.512.341.342.512.755.512 1.238s-.17.896-.512 1.238A1.686 1.686 0 0 1 20 24H4Zm2.775-7c-.35 0-.604-.113-.763-.337-.158-.226-.17-.505-.037-.838l4.675-11.9c.1-.25.28-.467.537-.65.259-.183.53-.275.813-.275.283 0 .554.092.813.275.258.183.437.4.537.65l4.675 11.9c.133.333.125.613-.025.838-.15.224-.408.337-.775.337a.798.798 0 0 1-.475-.163.957.957 0 0 1-.325-.387l-1.225-3.2H8.8l-1.225 3.2a.992.992 0 0 1-.313.387.781.781 0 0 1-.487.163ZM9.4 11.65h5.2l-2.55-6.775h-.1L9.4 11.65Z"
    />
  </svg>
);
export default SvgCreateMessageHtmlToolbar;
