import * as React from 'react';
import type { SVGProps } from 'react';
const SvgChevronLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="#000"
      d="M8.925 12c0-.1.017-.191.05-.275a.763.763 0 0 1 .175-.25l4.95-4.95c.133-.133.312-.196.537-.187.225.008.405.079.538.212.167.167.237.346.212.538a.94.94 0 0 1-.237.512l-4.4 4.4 4.4 4.4a.816.816 0 0 1 .225.538.592.592 0 0 1-.225.537.551.551 0 0 1-.525.213.973.973 0 0 1-.55-.238L9.15 12.525a.76.76 0 0 1-.175-.25.734.734 0 0 1-.05-.275Z"
    />
  </svg>
);
export default SvgChevronLeft;
