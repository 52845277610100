import { estimateServerTimestampsOption } from './common';
import firebase from 'firebase/compat/app';

export interface ContactData {
  teamId: string;
  accountId: string;
  name: string;
  email: string;
  companyName: string;
  phoneNumber: string;
  memo: string;
  tags: string[];
  createdAt: firebase.firestore.Timestamp;
  updatedAt: firebase.firestore.Timestamp;
}

export class Contact {
  private _id: string;
  private _data: ContactData;
  private _ref: firebase.firestore.DocumentReference<ContactData>;

  constructor(doc: firebase.firestore.DocumentSnapshot<ContactData>) {
    if (!doc) {
      /* eslint-disable  @typescript-eslint/no-non-null-assertion */
      // TODO: contactが存在しないが、contactとしてfromを擬似的に作成するため、あまり良くないので修正したい
      // FIXME: TSでエラーが起きないように元のJSコードの実装を残している
      this._id = undefined!;
      this._data = undefined!;
      this._ref = undefined!;
      return;
      /* eslint-enable  @typescript-eslint/no-non-null-assertion */
    }

    const data = doc.data(estimateServerTimestampsOption);
    if (!data) {
      throw new Error(`no data for contact#${doc.id}`);
    }

    this._id = doc.id;
    this._data = data;
    this._ref = doc.ref;
  }

  get id() {
    return this._id;
  }

  get ref() {
    return this._ref;
  }

  get teamId() {
    return this._data.teamId;
  }

  get accountId() {
    return this._data.accountId;
  }

  get name() {
    return this._data.name;
  }

  get email() {
    return this._data.email;
  }

  get emailText() {
    return emailText(this.name, this.email);
  }

  get contactText() {
    return this.name || this.email;
  }

  get companyName() {
    return this._data.companyName || '';
  }

  get phoneNumber() {
    return this._data.phoneNumber || '';
  }

  get memo() {
    return this._data.memo || '';
  }

  get tags() {
    if (!this._data.tags) return [];
    return this._data.tags.slice().sort();
  }

  get iconName() {
    if (!this.name || this.name.length === 0)
      return this.email[0].toUpperCase();

    return this.name[0].toUpperCase();
  }

  get createdAt() {
    return this._data.createdAt;
  }

  get updatedAt() {
    return this._data.updatedAt;
  }
}

export function emailText(name: string, email: string) {
  if (!name) return email;
  return `${name} <${email}>`;
}
