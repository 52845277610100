import { useRouteMatch } from 'react-router-dom';
import { Sent as SentEntity } from 'lib';
import { getMessageLink } from 'utils/messageListItem';
import { Sent } from '../../Common/Sent';
import { SearchMessage } from '../../../../../store/search';

const TeamSent = () => {
  const match = useRouteMatch();

  const generateLink = (sent: SentEntity | SearchMessage) => {
    const threadView = false;

    return getMessageLink(match, sent.id, threadView);
  };

  return <Sent generateLink={generateLink} />;
};

export default TeamSent;
