import * as React from 'react';
import type { SVGProps } from 'react';
const SvgFormatBold = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M8.375 19c-.417 0-.77-.146-1.063-.438a1.447 1.447 0 0 1-.437-1.062v-11c0-.417.146-.77.438-1.063A1.447 1.447 0 0 1 8.374 5h4.2c1.1 0 2.054.35 2.863 1.05.808.7 1.212 1.583 1.212 2.65 0 .633-.175 1.217-.525 1.75a3.44 3.44 0 0 1-1.4 1.225v.15c.717.233 1.296.65 1.737 1.25.442.6.663 1.275.663 2.025 0 1.133-.438 2.067-1.313 2.8-.874.733-1.895 1.1-3.062 1.1H8.375Zm.65-1.9h3.6c.633 0 1.183-.208 1.65-.625.467-.417.7-.942.7-1.575 0-.617-.233-1.133-.7-1.55a2.397 2.397 0 0 0-1.65-.625h-3.6V17.1Zm0-6.175h3.4c.583 0 1.087-.192 1.512-.575.426-.383.638-.867.638-1.45s-.212-1.07-.637-1.463a2.157 2.157 0 0 0-1.513-.587h-3.4v4.075Z"
    />
  </svg>
);
export default SvgFormatBold;
