// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HK6zKSFrA8AvncJGLP7P {\n  background-color: #ffffff;\n}\n.TjgK6q_q7lfSCxpS3z8L:enabled:checked + .HK6zKSFrA8AvncJGLP7P {\n  background-color: #4984F2;\n}\n.TjgK6q_q7lfSCxpS3z8L:disabled:checked + .HK6zKSFrA8AvncJGLP7P {\n  background-color: #B4B4B7;\n}\n.TjgK6q_q7lfSCxpS3z8L:disabled:not(:checked) + .HK6zKSFrA8AvncJGLP7P {\n  background-color: transparent;\n}\n", "",{"version":3,"sources":["webpack://./src/components/basics/Radio/Radio/Radio.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;AACA;EACE,yBAAyC;AAC3C;AACA;EACE,yBAA0C;AAC5C;AACA;EACE,6BAA6B;AAC/B","sourcesContent":[".checkedIndicator {\n  background-color: #ffffff;\n}\n.radio:enabled:checked + .checkedIndicator {\n  background-color: theme('colors.sea.500');\n}\n.radio:disabled:checked + .checkedIndicator {\n  background-color: theme('colors.sumi.400');\n}\n.radio:disabled:not(:checked) + .checkedIndicator {\n  background-color: transparent;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkedIndicator": "HK6zKSFrA8AvncJGLP7P",
	"radio": "TjgK6q_q7lfSCxpS3z8L"
};
export default ___CSS_LOADER_EXPORT___;
