import { Avatar, AvatarProps } from '../Avatar/Avatar';
import React, { useMemo } from 'react';
import { tv } from 'tailwind-variants';

type Props = {
  size: number;
  entries: Omit<AvatarProps, 'size'>[];
  max: number;
  total?: number;
};

const extraCountText = tv({
  base: 'w-fit select-none font-bold',
  variants: {
    small: {
      true: '',
      false: 'text-lg',
    },
  },
});

export const AvatarGroup = ({ size, entries, max, total }: Props) => {
  const noOverlapWidth = useMemo(() => size * 0.6, [size]);
  const displayEntries = useMemo(
    () => entries.slice(0, max).reverse(),
    [entries, max]
  );
  const extraCount = useMemo(
    () => (total ?? entries.length) - displayEntries.length,
    [total, entries, displayEntries]
  );

  const avatarsWidth = useMemo(
    () => noOverlapWidth * displayEntries.length + (size - noOverlapWidth),
    [size, displayEntries]
  );

  return (
    <div
      className="grid items-center gap-2"
      style={{
        gridTemplateColumns:
          extraCount > 0 ? `${avatarsWidth}px auto` : `${avatarsWidth}px`,
      }}
    >
      <div
        className="relative flex flex-row-reverse justify-start"
        style={{ height: size, width: avatarsWidth }}
      >
        {displayEntries.map((entry, i) => (
          <div
            key={i}
            className="absolute"
            style={{
              width: size,
              height: size,
              left: noOverlapWidth * (displayEntries.length - i - 1),
            }}
          >
            <Avatar {...entry} size={size} />
          </div>
        ))}
      </div>
      {extraCount > 0 && (
        <div className={extraCountText({ small: size <= 32 })}>
          +{extraCount}
        </div>
      )}
    </div>
  );
};
