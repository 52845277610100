import { Product } from '../Product';
import { Tooltip } from 'antd';
import { PlanFeatureItemProps, PlanFeatures } from './PlanFeatures';
import { PlanLimitItems } from './PlanLimitItem';
import { Button, Loading } from 'components/basics';

type Props = {
  product: Product;
  disabled?: boolean;
  buttonLabel?: string;
  tooltip?: string;
  onSelect: () => any;
  updating: boolean;
  version: number;
};

export const PlanCard: React.FC<Props> = ({
  product,
  onSelect,
  disabled,
  buttonLabel,
  tooltip,
  updating,
  version,
}) => {
  const handleClickSelectButton = () => {
    typeof onSelect === 'function' &&
      window.confirm('プラン変更でよろしいでしょうか。') &&
      onSelect();
  };

  const lead = getLead(product.plan);
  const price = getPrice(product.plan)?.toLocaleString();
  const initialCost = getInitialCost10KYen();
  const featureItems = getFeaturePropList(product.plan);

  return (
    <div className="relative w-60 rounded-lg bg-[#F8F8FB] p-4 pb-16 leading-none">
      {product.badge && (
        <div className="absolute -left-2 -top-2 flex h-14 w-14 items-center justify-center rounded-full bg-[#F2B749]">
          <p className="m-0 text-xs font-bold leading-5 text-white">人気！</p>
        </div>
      )}
      <div className="text-center text-lg font-bold">{product.name}</div>
      <div className="mt-4 text-center text-xs leading-normal">{lead}</div>
      <div className="my-4 text-center">
        <div className="mt-1 text-sm leading-normal text-[#1A1A1C]">
          月額 <span className="text-3xl font-bold">{price ? price : '0'}</span>{' '}
          <span className="text-xs">円</span>
        </div>
        <p className="mb-0 mr-8 text-right text-[10px]">※1ユーザー</p>
        {initialCost && (
          <div className="mt-1 text-sm text-[#3f82f9]">
            初期費用:{initialCost}万円
          </div>
        )}
      </div>
      <div className="h-px bg-[#D8D8DB]"></div>
      <div className="my-4">
        <PlanLimitItems product={product} version={version} />
      </div>
      <div className="h-px bg-[#D8D8DB]"></div>
      <div className="my-4 space-y-2">
        <PlanFeatures items={featureItems} />
      </div>
      <div className="absolute bottom-4 left-4 right-4 flex justify-center">
        <Tooltip title={tooltip}>
          <div className="w-full">
            {updating ? (
              <div className="h-10 w-full px-4 py-2.5 text-sm font-bold">
                <Loading />
              </div>
            ) : (
              <Button
                disabled={disabled}
                className="h-10 w-full px-4 py-2.5 text-sm font-bold"
                onClick={handleClickSelectButton}
              >
                {buttonLabel || 'このプランに変更'}
              </Button>
            )}
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

const getLead = (plan: Product['plan']): React.ReactElement => {
  switch (plan) {
    case 'light':
      return (
        <>
          メール共有を
          <br />
          試してみたい方向け
        </>
      );
    case 'standard':
      return (
        <>
          基本機能がすべて
          <br />
          利用できるプラン
        </>
      );
    case 'business':
      return (
        <>
          自動化やCRM連携で
          <br />
          さらなる効率化を実現
        </>
      );
    case 'enterprise':
      return (
        <>
          セキュリティ対策や
          <br />
          手厚いサポート体制
        </>
      );
  }
};

// version 6
// 価格
const getPrice = (plan: Product['plan']): number | null => {
  switch (plan) {
    case 'light':
      return 1980;
    case 'standard':
      return 2980;
    case 'business':
      return 4980;
  }

  return null;
};

// version 6
// 初期費用
const getInitialCost10KYen = (): number | null => {
  return null;
};

// 機能リスト(PlanFeatureItemsのProps)
const getFeaturePropList = (plan: Product['plan']): PlanFeatureItemProps[] => {
  switch (plan) {
    case 'light':
      return [
        { text: '2重対応防止' },
        { text: '@メンション・既読未読' },
        { text: '対応履歴の表示' },
        { text: '個人メールの管理' },
      ];
    case 'standard':
      return [
        { bold: true, text: 'ライトの全機能' },
        { text: '送信予約' },
        { text: '開封履歴' },
        { text: 'Slack・Chatwork連携' },
        { text: 'yaritori AI' },
      ];
    case 'business':
      return [
        { text: 'スタンダードの全機能', bold: true },
        { text: '自動返信' },
        { text: 'メール開封の通知' },
        { text: 'ステータスのカスタマイズ' },
        { text: 'Salesforce連携' },
      ];
  }

  return [];
};
