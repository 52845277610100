import {
  UploadedFile,
  UploadFile,
  UploadingFile,
  UploadOptions,
  UploadState,
} from './types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useToast } from '../useToast';
import {
  deleteObject,
  ref,
  uploadBytesResumable,
  UploadResult,
} from 'firebase/storage';
import { storage } from '../../firebase';
import { v4 } from 'uuid';
import { message as antdMessage } from 'antd';

export const useUpload = ({
  uploadPath,
  validate,
  metadata,
  onUpload,
  onRemove,
}: UploadOptions): UploadState => {
  const [files, setFiles] = useState<UploadFile[]>([]);
  const { uploadedFiles, allUploaded } = useMemo(
    () => ({
      uploadedFiles: files.filter((f) => !f.uploading) as UploadedFile[],
      allUploaded: files.every((f) => !f.uploading),
    }),
    [files]
  );
  const removeFilesRef = useRef<() => void>();
  const { showToast } = useToast();

  useEffect(() => {
    removeFilesRef.current = async () => {
      await Promise.allSettled(
        uploadedFiles.map(async (f) => {
          await deleteObject(f.ref).catch((e) => {
            if (e.code !== 'storage/object-not-found') {
              throw e;
            }
          });
          await onRemove?.(f);
        })
      );
    };
  }, [uploadedFiles]);

  useEffect(() => {
    return () => {
      if (removeFilesRef.current) {
        removeFilesRef.current();
        removeFilesRef.current = undefined;
      }
    };
  }, []);

  const remove = useCallback(
    async (fileId: string) => {
      setFiles((prev) => prev.filter((f) => f.id !== fileId));
      const uploadedFile = uploadedFiles.find((f) => f.id === fileId);
      if (uploadedFile) {
        await deleteObject(uploadedFile.ref).catch((e) => {
          if (e.code !== 'storage/object-not-found') {
            throw e;
          }
        });
        await onRemove?.(uploadedFile);
      }
    },
    [uploadedFiles]
  );

  const upload = useCallback(
    async (file) => {
      if (!validate || validate(file, files)) {
        const uploadingFile: UploadingFile = {
          id: v4(),
          file,
          uploading: true,
        };
        setFiles((prev) => [...prev, uploadingFile]);
        try {
          const storagePath = uploadPath(uploadingFile);

          const fileRef = ref(storage, storagePath);

          const toastId = v4();

          const uploadTask = uploadBytesResumable(
            fileRef,
            uploadingFile.file,
            metadata?.(uploadingFile)
          );

          uploadTask.on(
            'state_changed',
            (snapshot) => {
              const progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              antdMessage.loading({
                content: `${file.name}をアップロード中です（${Math.floor(
                  progress
                )}%完了）`,
                key: toastId,
              });
            },
            (error) => {
              console.error('CreateMessage.uploadFileToStorage:', error);
              antdMessage.error({
                content: `${file.name}のアップロードに失敗しました`,
                key: toastId,
              });
            }
          );

          const result = await new Promise<UploadResult>((resolve, reject) => {
            uploadTask.then(
              (snap) => {
                // 成功
                antdMessage.success({
                  content: `${file.name}のアップロードが完了しました。`,
                  key: toastId,
                  duration: 2,
                });
                resolve({
                  metadata: snap.metadata,
                  ref: snap.ref,
                });
              },
              (error) => reject(error)
            );
          });

          const uploadedFile: UploadedFile = {
            ...uploadingFile,
            ...result,
            uploading: false,
          };
          await onUpload?.(uploadedFile);
          setFiles((prev) =>
            prev.map((f) => (f.id === uploadingFile.id ? uploadedFile : f))
          );
        } catch (e) {
          showToast('error', `${file.name}のアップロードに失敗しました`);
          setFiles((prev) => prev.filter((f) => f.id !== uploadingFile.id));
          console.error(e);
        }
      }
    },
    [uploadPath, validate, metadata, onUpload, files]
  );

  return useMemo(
    () => ({
      files,
      uploadedFiles,
      allUploaded,
      upload,
      remove,
      clearFiles: () => setFiles([]),
    }),
    [files, uploadedFiles, allUploaded, upload, remove]
  );
};
