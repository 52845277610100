import { db, db9 } from '../firebase';
import { writeBatch } from 'firebase/firestore';

/**
 * Break an array to an array of chunks.
 * @param {K[]} arr
 * @param {number} chunkSize
 * @returns {Array<K[]>}
 */
function chunk(arr, chunkSize = 10) {
  const ret = [];
  for (let i = 0; i * chunkSize < arr.length; i++) {
    ret.push(arr.slice(i * chunkSize, (i + 1) * chunkSize));
  }
  return ret;
}

/**
 * Because `in` operator has 10 elements limit, split arr to send multiple
 * queries.
 * @template [T = any]
 * @param {Query} query
 * @param {string | import(import('../firebase').FieldPath} field
 * @param {any[]} arr
 * @returns {Promise<import('../firebase').DocumentSnapshot<T>[]>}
 */
export const fetchQueryInArray = async (query, field, arr) => {
  const docs = await Promise.all(
    chunk(arr).map(async (x) => (await query.where(field, 'in', x).get()).docs)
  );
  return docs.flat();
};

/**
 * Because `in` operator has 10 elements limit, split arr to send multiple
 * queries.
 * @template [T = any]
 * @param {import('../firebase').Query} query
 * @param {string | import('../firebase').FieldPath} field
 * @param {any[]} arr
 * @param {(docs: import('../firebase').QueryDocumentSnapshot<T>[], snapshot: import('../firebase').QuerySnapshot<T>) => any} callback
 * @returns {()=>any} A function to unsubscribe all the queries.
 */
export const subscribeQueryInArray = (query, field, arr, callback) => {
  const unsubscribeFunctions = [];
  const snapDocs = [];
  for (let i = 0; i * 10 < arr.length; i++) {
    const unsubscribe = query
      .where(field, 'in', arr.slice(i * 10, (i + 1) * 10))
      .onSnapshot(
        (snap) => {
          snapDocs.splice(i, 1, snap.docs);
          callback(snapDocs.flat(), snap);
        },
        (err) => console.error(err)
      );
    unsubscribeFunctions.push(unsubscribe);
  }
  return () => {
    unsubscribeFunctions.forEach((unsubscribe) => unsubscribe());
  };
};

/**
 * Firestore's WriteBatch can only contain 500 writes, this is a wrapper so that
 * users don't need to care about the limit.
 */
export class WriteBatch {
  constructor(version = 8) {
    this.version = version;
  }

  newBatch() {
    if (this.version === 8) {
      return db.batch();
    }
    return writeBatch(db9);
  }

  _writes = [];

  // @see https://firebase.google.com/docs/reference/js/v8/firebase.firestore.WriteBatch
  create(ref, data) {
    this._writes.push(['create', ref, data]);
  }

  set(ref, data) {
    this._writes.push(['set', ref, data]);
  }

  update(ref, data) {
    this._writes.push(['update', ref, data]);
  }

  delete(ref) {
    this._writes.push(['delete', ref]);
  }

  /**
   * This is where the magic happens. We push every 200 writes into a batch,
   * then run all the batches at the end.
   */
  async commit() {
    const batches = [];
    let batch = this.newBatch();
    let count = 0;

    for (let [op, ...rest] of this._writes) {
      if (count > 200) {
        // serverTimestamp is counted as a write, see
        // https://github.com/firebase/firebase-admin-node/issues/456
        batches.push(batch);
        batch = this.newBatch();
        count = 0;
      }
      batch[op](...rest);
      count++;
    }
    batches.push(batch);

    await Promise.all(batches.map((b) => b.commit()));
  }
}
