import { generatePath, match } from 'react-router-dom';

/**
 * メール画面へ遷移するリンク
 *
 * @param match
 * @param messageId
 * @param threadView threadViewの設定ありの場合はTRUE。 store.preferences.threadView から取得する。
 * @returns
 */
export const getMessageLink = (
  match: match,
  messageId: string,
  threadView: boolean
): string => {
  let path = decodeURI(
    generatePath(match.path, {
      ...match.params,
      msgId: messageId,
    })
  );
  if (
    match.path.endsWith(':query') ||
    match.path.endsWith(':tab') ||
    match.path.endsWith('sent')
  ) {
    path += '/' + messageId;
  }
  if (threadView) {
    path += '?view=thread';
  }

  return path;
};
