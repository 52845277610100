import { EditorHandle } from '../WysiwygEditor/WysiwygEditor';
import { BubbleMenu } from '@tiptap/react';
import { useAtomValue } from 'jotai/index';
import { linkEditingEditorsAtom } from '../LinkBubbleMenu/LinkBubbleMenu';
import { tv } from 'tailwind-variants';

export type AiAssistantType = 'proofreader' | 'translator';

type Props = {
  handle: EditorHandle | undefined;
  onConvert: (type: AiAssistantType) => void;
};

const button = tv({
  base: 'relative flex h-full cursor-pointer items-center justify-center border border-sumi-300 bg-white px-2 duration-100 hover:z-10 hover:border-sea-500 hover:text-sea-500',
});

export const AiBubbleMenu = ({ handle, onConvert }: Props) => {
  const editor = handle?.editor;
  const linkEditingEditors = useAtomValue(linkEditingEditorsAtom);

  const content = (
    <div className="flex h-6 items-center text-xs text-sumi-500">
      <button
        className={button({ className: 'rounded-l' })}
        onClick={() => onConvert('proofreader')}
      >
        丁寧にする
      </button>
      <button
        className={button({
          className: 'left-[-1px] rounded-r',
        })}
        onClick={() => onConvert('translator')}
      >
        翻訳する
      </button>
    </div>
  );

  return handle && editor ? (
    <BubbleMenu
      pluginKey="aiBubbleMenu"
      editor={editor}
      shouldShow={(props) => {
        return (
          !editor.isActive('image') &&
          !editor.isActive('link') &&
          props.from < props.to
        );
      }}
      tippyOptions={{
        appendTo: () => document.getElementById('editorScroll')!,
      }}
    >
      {!linkEditingEditors.includes(editor) && content}
    </BubbleMenu>
  ) : (
    content
  );
};
