import { estimateServerTimestampsOption } from './common';
import moment from 'moment';
import firebase from 'firebase/compat/app';
import { createConverter, createEntityFactory } from '../converter';
import { FirestoreDataConverter, Timestamp } from 'firebase/firestore';
import { omit } from 'lodash';

export interface StarData {
  type: string;
  teamId: string;
  companyId: string;
  messageId: string;
  createdAt: firebase.firestore.Timestamp;
  updatedAt: firebase.firestore.Timestamp;
  messageDate: firebase.firestore.Timestamp;
}

export const starTypes = {
  message: 'message',
};

export const createStar = createEntityFactory('Star', (data: StarData) => ({
  // 毎回生成するのはコストがかかるため、初回のみ
  messageDate: moment(data.messageDate.toMillis()),
}));

export type Star = ReturnType<typeof createStar>;

export const starConverter: FirestoreDataConverter<Star> =
  createConverter(createStar);

export type StarredThreadsData = Omit<
  {
    [key: string]: { date: Timestamp; teamId: string };
  },
  'updatedAt'
> & {
  updatedAt: Timestamp;
};

export const createStarredThreads = createEntityFactory(
  'StarredThreads',
  (data: StarredThreadsData) => {
    return {
      ...omit(data, 'updatedAt'),
      updatedAt: data.updatedAt,
    };
  }
);

export type StarredThreads = ReturnType<typeof createStarredThreads>;

export const starredThreadConverter: FirestoreDataConverter<StarredThreads> =
  createConverter(createStarredThreads);

// mobx用のentity。jotaiでは使わない
export class MobxStar {
  private _id: string;
  private _data: StarData;
  private _messageDate: moment.Moment;
  private _ref: firebase.firestore.DocumentReference<StarData>;

  constructor(doc: firebase.firestore.DocumentSnapshot<StarData>) {
    this._id = doc.id;
    const data = doc.data(estimateServerTimestampsOption);
    if (!data) {
      throw new Error(`no data for star#${doc.id}`);
    }
    this._data = data;
    this._messageDate = moment(this._data.messageDate.toMillis()); // 毎回生成するのはコストがかかるため
    this._ref = doc.ref;
  }

  get id() {
    return this._id;
  }

  get ref() {
    return this._ref;
  }

  get type() {
    return this._data.type;
  }

  get messageId() {
    return this._data.messageId;
  }

  get messageDate() {
    return this._messageDate;
  }

  get createdAt() {
    return this._data.createdAt;
  }

  get updatedAt() {
    return this._data.updatedAt;
  }
}
