import { MailAddress } from 'lib';
import _ from 'lodash';
import styled from 'styled-components';
import { CmpIcon, CmpPopover } from 'utils/antdComp';
import { MessageMeta, MessageMailAddresses } from './MessageMeta';

const MAX_DISPLAY_RECIPIENT_LENGTH = 30;
const NAME_ADDR_REGEX = /^(.*)<(.*)>$/;

type Props = {
  subject?: string;
  mailAddresses: MessageMailAddresses;
  bold?: boolean;
};

export const MessageRecipient: React.FC<Props> = ({
  subject,
  mailAddresses,
  bold,
}) => {
  const addressList = [
    ...(mailAddresses.to || []),
    ...(mailAddresses.cc || []),
    ...(mailAddresses.bcc || []),
  ];

  // MailAddressから宛先表示テキスト取得
  const getTextFromMailAddress = (mailAddress: MailAddress): string => {
    if (mailAddress.name) {
      // MailAddress.nameがあればnameを表示
      return mailAddress.name;
    }

    // name <address> の形式なら nameのみ表示
    const matches = mailAddress.address.match(NAME_ADDR_REGEX);
    if (matches && matches[1]) {
      return matches[1].trim();
    } else {
      return mailAddress.address;
    }
  };

  const displayRecipients: string[] = [];

  // 文字数合計が規定の長さを超えるまでdisplayRecipientsに表示テキスト追加 (最低1個は追加)
  for (const a of addressList) {
    const text = getTextFromMailAddress(a);
    // 次の宛先を追加した場合の合計文字数
    const nextLength =
      _.sumBy(displayRecipients, (r) => r.length) + text.length;

    if (
      displayRecipients.length === 0 ||
      nextLength < MAX_DISPLAY_RECIPIENT_LENGTH
    ) {
      displayRecipients.push(text);
    } else {
      break;
    }
  }

  const omitedCount = addressList.length - displayRecipients.length;

  return (
    <Container>
      <CmpPopover
        className="popover"
        trigger="hover"
        placement="bottom"
        content={
          <MessageMetaWrapper>
            <MessageMeta subject={subject} mailAddresses={mailAddresses} />
          </MessageMetaWrapper>
        }
      >
        <RecipientContainer className={bold ? 'bold' : ''}>
          <div className="label">宛先:</div>
          <div className="recipients">
            {displayRecipients.map((r, i) => (
              <span className="recipient" key={i}>
                {r}
                {i < displayRecipients.length - 1 ? (
                  <span className="comma">,</span>
                ) : null}
              </span>
            ))}
            {omitedCount > 0 ? (
              <span className="omited">+{omitedCount}</span>
            ) : null}
          </div>
          <div className="more">
            <CmpIcon type="caret-down" />
          </div>
        </RecipientContainer>
      </CmpPopover>
    </Container>
  );
};

const Container = styled.div`
  & > .popover {
    margin: -6px;
    padding: 6px;
  }
`;

const RecipientContainer = styled.div`
  display: flex;
  color: rgb(0, 0, 0, 0.65);
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  & > .label {
    flex-shrink: 0;
  }

  & > .recipients {
    margin-left: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    & > .recipient {
      & > .comma {
        margin-left: 1px;
        margin-right: 4px;
      }
    }

    & > .omited {
      font-size: 11px;
      margin-left: 5px;
    }
  }

  & > .more {
    margin-left: 4px;
    padding-top: 2px;
    font-size: 10px;
    flex-shrink: 0;
  }

  &.bold {
    & > .address {
      font-weight: bold;
    }
  }
`;

const MessageMetaWrapper = styled.div`
  padding: 4px;
`;
