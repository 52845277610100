import moment from 'moment';
import { decorateHtml } from '../util';
import firebase from 'firebase/compat/app';
import { MessageData } from './message';
import { createConverter, createEntityFactory } from '../converter';

export interface SentData {
  inboxId: string;
  isReply: boolean;
  isInReplyToMessageDeleted: boolean;
  inReplyToMessageId?: string;
  inReplyToMessageRef?: firebase.firestore.DocumentReference<MessageData>;
  subject: string;
  text: string;
  teamId: string;
  html: string;
  storagePath: string;
  emlStoragePath: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  attachments: any[];
  from: string;
  to?: string[];
  cc?: string[];
  bcc?: string[];
  inReplyTo: string | null;
  references: string[];
  createdAt: firebase.firestore.Timestamp;
  updatedAt: firebase.firestore.Timestamp;
  openEvents: { [email: string]: firebase.firestore.Timestamp };
  date: firebase.firestore.Timestamp;
  sender: string;
}

export const createSent = createEntityFactory('Sent', (data: SentData) => ({
  date: moment(data.date.toMillis()),

  subject: data.subject ?? '',

  _decoratedHtml: undefined as string | undefined,
  get html() {
    const html = data.html;
    if (!html) return html; // html対応ではないメールの場合
    if (!this._decoratedHtml) {
      // パフォーマンスのため、一度しか読み込まない
      this._decoratedHtml = decorateHtml(html);
    }
    return this._decoratedHtml;
  },

  fromIconName: data.from.length > 0 ? data.from[0].toUpperCase() : '',

  inReplyTo: data.inReplyTo || null,

  references: data.references || [],

  get openEvents() {
    if (!data.openEvents) {
      return [];
    }
    return Object.entries(data.openEvents)
      .map<[string, moment.Moment]>(([email, ts]) => [
        email,
        moment(ts.toMillis()),
      ])
      .sort(([_email1, ts1], [_email2, ts2]) => ts2.diff(ts1));
  },
}));

export type Sent = ReturnType<typeof createSent>;

export const sentConverter = createConverter(createSent);
