import {
  CollectionReference,
  onSnapshot,
  serverTimestamp,
  updateDoc,
} from 'firebase/firestore';
import {
  getChatworkRoomsFunction,
  integrateChatworkFunction,
  unintegrateChatworkFunction,
} from 'functions';
import {
  ChatworkIntegration,
  ChatworkIntegrationData,
  ChatworkRoom,
  Team,
  UserChatworkNotificationSettings,
} from 'lib';
import { makeAutoObservable } from 'mobx';
import { Store } from 'store';
import { Inbox } from '../../firestore/entity/inbox';

export const CHATWORK_INTEGRATION_SCOPES = [
  'offline_access',
  'rooms.info:read',
  'rooms.messages:write',
  'users.profile.me:read',
];

export interface IChatworkIntegrationStore {
  integrations: ChatworkIntegration[];
  isLoadingIntegrations: boolean;
  isIntegrating: boolean;
  isUnintegrating: boolean;
  rooms: ChatworkRoom[];
  isLoadingRooms: boolean;
  integration: ChatworkIntegration | null;
  hasPermission: boolean;
  isScopeMissing: boolean;
  isSupported: boolean;
  integratableTeams: Team[];
  myNotificationSettings: UserChatworkNotificationSettings | undefined;

  init(): void;

  unsubscribeSyncs(): void;

  loadRooms(): Promise<any>;

  integrate(code: string, redirectUri?: string): Promise<any>;

  unintegrate(): Promise<any>;

  getTeamInboxes(teamId: string): Inbox[];

  updateMyNotificationSettings<
    K extends keyof UserChatworkNotificationSettings,
  >(
    key: K,
    value: UserChatworkNotificationSettings[K]
  ): Promise<any>;
}

export class ChatworkIntegrationStore implements IChatworkIntegrationStore {
  integrations: ChatworkIntegration[] = [];
  isLoadingIntegrations = true;
  isIntegrating = false;
  isUnintegrating = false;
  rooms: ChatworkRoom[] = [];
  isLoadingRooms = false;

  constructor(
    private rootStore: Store,
    private support: { isSlackNotificationV2Supported: boolean }
  ) {
    makeAutoObservable(this);
  }

  get integration(): ChatworkIntegration | null {
    return this.integrations.length > 0 ? this.integrations[0] : null;
  }

  get hasPermission(): boolean {
    return this.rootStore.me.isAdmin;
  }

  get isSupported(): boolean {
    return this.support.isSlackNotificationV2Supported;
  }

  get isScopeMissing(): boolean {
    const integration = this.integration;
    if (!integration) {
      return false;
    }
    return CHATWORK_INTEGRATION_SCOPES.some(
      (s) => !integration.scope.includes(s)
    );
  }

  get integratableTeams(): Team[] {
    return this.rootStore.joinedTeams;
  }

  private _unsubscribeChatworkIntegrations?: () => void;

  init(): void {
    this.syncIntegrations();
  }

  syncIntegrations(): void {
    if (this._unsubscribeChatworkIntegrations) {
      this._unsubscribeChatworkIntegrations();
    }

    this._unsubscribeChatworkIntegrations = onSnapshot(
      this.rootStore.collection(
        'chatworkIntegrations'
      ) as CollectionReference<ChatworkIntegrationData>,
      (snapshot) => {
        this.integrations = snapshot.docs.map(
          (doc) => new ChatworkIntegration(doc)
        );
        this.isLoadingIntegrations = false;
      },
      (err) => {
        console.error('Store.syncChatworkIntegrations:', err);
      }
    );
  }

  unsubscribeSyncs(): void {
    if (this._unsubscribeChatworkIntegrations) {
      this._unsubscribeChatworkIntegrations();
    }
  }

  async loadRooms(): Promise<any> {
    if (this.isLoadingRooms) {
      return;
    }
    this.isLoadingRooms = true;
    try {
      const res = await getChatworkRoomsFunction({
        companyId: this.rootStore.signInCompany,
      });
      const rooms = res.data as ChatworkRoom[];
      this.rooms = rooms;
    } catch (e) {
      throw e;
    } finally {
      this.isLoadingRooms = false;
    }
  }

  async integrate(code: string, redirectUri?: string): Promise<any> {
    try {
      this.isIntegrating = true;
      await integrateChatworkFunction({
        companyId: this.rootStore.signInCompany,
        code,
        redirectUri,
      });
    } catch (e) {
      throw e;
    } finally {
      this.isIntegrating = false;
    }
  }

  async unintegrate(): Promise<any> {
    try {
      this.isUnintegrating = true;
      await unintegrateChatworkFunction({
        companyId: this.rootStore.signInCompany,
      });
    } catch (e) {
      throw e;
    } finally {
      this.isUnintegrating = false;
    }
  }

  getTeamInboxes(teamId: string): Inbox[] {
    return this.rootStore.getTeamInboxes(teamId);
  }

  async updateMyNotificationSettings<
    K extends keyof UserChatworkNotificationSettings,
  >(key: K, value: UserChatworkNotificationSettings[K]): Promise<any> {
    return updateDoc(this.rootStore.me.ref, {
      [`notificationSettings.chatwork.${key}`]: value,
      updatedAt: serverTimestamp(),
    });
  }

  get myNotificationSettings(): UserChatworkNotificationSettings | undefined {
    return this.rootStore.me.notificationSettings.chatwork;
  }
}
