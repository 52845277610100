import * as React from 'react';
import type { SVGProps } from 'react';
const SvgFormatItalic = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M6.6 19.025a.964.964 0 0 1-.71-.291.97.97 0 0 1-.29-.713.96.96 0 0 1 .29-.709.97.97 0 0 1 .71-.287h2.35L12.425 6.8H9.45a.964.964 0 0 1-.71-.291.97.97 0 0 1-.29-.713.96.96 0 0 1 .29-.709.97.97 0 0 1 .71-.287h7.5c.28 0 .516.097.71.29a.97.97 0 0 1 .29.713.96.96 0 0 1-.29.71.97.97 0 0 1-.71.287H14.6l-3.475 10.225H14.1c.28 0 .516.097.71.29a.97.97 0 0 1 .29.713.96.96 0 0 1-.29.71.97.97 0 0 1-.71.287H6.6Z"
    />
  </svg>
);
export default SvgFormatItalic;
