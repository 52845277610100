import { useCallback, useLayoutEffect, useState } from 'react';
import lStorage, { storageKeys } from '../../../../localStorage';

type UseCollapseStatesResult = [boolean, (open: boolean) => void];

export const useCollapseState = (
  key: string,
  defaultValue = true
): UseCollapseStatesResult => {
  const [open, setOpen] = useState(false);
  useLayoutEffect(() => {
    const { collapsedItems } = lStorage.getOrInitObject(
      storageKeys.localPreferences,
      {}
    );
    const hasData = collapsedItems != null && collapsedItems[key] != null;
    setOpen(hasData ? collapsedItems[key] : defaultValue);
  }, []);
  const setState = useCallback(
    (open: boolean) => {
      setOpen(open);
      const localPreferences = lStorage.getObject(storageKeys.localPreferences);
      const newLocalPreferences = {
        ...localPreferences,
        collapsedItems: {
          ...(localPreferences?.collapsedItems || {}),
          [key]: open,
        },
      };
      lStorage.setObject(storageKeys.localPreferences, newLocalPreferences);
    },
    [key]
  );
  return [open, setState];
};
