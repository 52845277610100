import * as React from 'react';
import type { SVGProps } from 'react';
const SvgAttach = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M11.5 21.885c-1.463 0-2.707-.513-3.732-1.538-1.025-1.025-1.538-2.269-1.538-3.731V5.808c0-1.053.375-1.953 1.125-2.703.75-.75 1.65-1.124 2.702-1.124 1.053 0 1.954.375 2.703 1.124.75.75 1.124 1.65 1.124 2.703v9.75c0 .668-.23 1.232-.691 1.693a2.303 2.303 0 0 1-1.694.691c-.667 0-1.232-.23-1.693-.691a2.303 2.303 0 0 1-.69-1.693V6.404a.58.58 0 0 1 .595-.596.58.58 0 0 1 .596.596v9.154c0 .338.115.62.343.85.229.228.512.342.85.342.338 0 .62-.114.85-.343.228-.228.342-.511.342-.85v-9.75c0-.737-.255-1.36-.764-1.87-.51-.51-1.134-.764-1.871-.764-.738 0-1.362.255-1.87.764-.51.51-.764 1.133-.764 1.87v10.809c0 1.125.398 2.086 1.194 2.882.796.796 1.757 1.194 2.883 1.194 1.125 0 2.086-.398 2.882-1.194.796-.796 1.195-1.757 1.195-2.882V6.404a.58.58 0 0 1 .595-.596.58.58 0 0 1 .597.596v10.212c0 1.462-.513 2.706-1.538 3.731s-2.268 1.538-3.731 1.538Z"
    />
  </svg>
);
export default SvgAttach;
