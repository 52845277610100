import { estimateServerTimestampsOption } from './common';
import { convertNtoBr } from '../util';
import firebase from 'firebase/compat/app';

export interface SignatureData {
  teamId: string;
  title: string;
  body: string;
  bodyHtml: string;
}

export class Signature {
  private _id: string;
  private _data: SignatureData;
  private _ref: firebase.firestore.DocumentReference<SignatureData>;

  constructor(doc: firebase.firestore.DocumentSnapshot<SignatureData>) {
    this._id = doc.id;
    const data = doc.data(estimateServerTimestampsOption);
    if (!data) {
      throw new Error(`no data for signature#${doc.id}`);
    }
    this._data = data;
    this._ref = doc.ref;
  }

  get id() {
    return this._id;
  }

  get ref() {
    return this._ref;
  }

  get teamId() {
    return this._data.teamId;
  }

  get title() {
    return this._data.title;
  }

  get body() {
    return this._data.body;
  }

  get bodyHtml() {
    return this._data.bodyHtml || convertNtoBr(this.body);
  }

  get signature() {
    return {
      id: this.id,
      teamId: this.teamId,
      title: this.title,
      body: this.body,
      bodyHtml: this.bodyHtml,
    };
  }
}
