import { useHistory } from 'react-router-dom';
import { Icon, Spin } from 'antd';
import Draft from './Draft';
import { useAtom } from 'jotai';
import { draftsAtom } from '../../../../../atoms/firestore/draft';
import React from 'react';
import { WrappedInfiniteScroll } from 'components/WrappedInfiniteScroll';

export const Drafts = (): JSX.Element | null => {
  const [draftsPaginate, dispatch] = useAtom(draftsAtom);
  const history = useHistory();
  if (draftsPaginate.state === 'hasError') {
    return null;
  }
  return (
    <WrappedInfiniteScroll
      initialLoad={false}
      pageStart={0}
      loadMore={() => dispatch('loadMore')}
      hasMore={draftsPaginate.hasMore}
      useWindow={false}
      className="pt-4"
    >
      <Spin
        spinning={draftsPaginate.state === 'loading'}
        indicator={<Icon type="loading" spin />}
      >
        {draftsPaginate.state === 'hasData' &&
          draftsPaginate.data.map((draft) => (
            <Draft
              draft={draft}
              toFunc={(link: string) => history.push(link)}
              to={
                draft.isReply
                  ? `/me/drafts/${draft.id}/teams/${draft.teamId}/inboxes/${draft.inboxId}`
                  : `/me/drafts/${draft.id}`
              }
              key={draft.id}
            />
          ))}
      </Spin>
    </WrappedInfiniteScroll>
  );
};
