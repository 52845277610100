import { MessageData, Team, User } from 'lib';
import { computed, makeObservable } from 'mobx';
import { MessageFilterStore } from './messageFilter';
import { MessageView } from './types';
import { ThreadStore } from 'store/thread';
import { BaseStore } from 'store/base';
import type { Store } from '../index';

type Delegate = {
  me: User;
  signInCompany: string;
  joinedTeams: Team[];
  messageFilterStore: MessageFilterStore;
  threadStore: ThreadStore;
  isInThreadView: boolean;
  messageLimitPerPage: number;
};

type Listener = {
  onMessageViewChanged: (view: MessageView) => void;
};

export class MessagesStore extends BaseStore<MessageData> {
  PATH = 'messages';

  private delegate: Delegate;
  private listener: Listener;

  constructor(rootStore: Store) {
    super(rootStore);

    // TODO: remove these redirections, just use this.rootStore.
    this.delegate = rootStore as any;
    this.listener = rootStore as any;

    makeObservable(this, {
      view: computed,
    });
  }

  get filterStore(): MessageFilterStore {
    return this.delegate.messageFilterStore;
  }

  get view(): MessageView {
    return this.filterStore.view;
  }

  get threadStore(): ThreadStore {
    return this.delegate.threadStore;
  }

  get joinedTeams(): Team[] {
    return this.delegate.joinedTeams;
  }

  get joinedTeamIds(): string[] {
    return this.joinedTeams.map((t) => t.id);
  }

  get signInCompany(): string {
    return this.delegate.signInCompany;
  }

  get me(): User {
    return this.delegate.me;
  }

  get isInThreadView(): boolean {
    return this.delegate.isInThreadView;
  }

  selectView(view: MessageView): void {
    this.filterStore.setView(view);
    if (this.view !== view) {
      this.filterStore.setOptions({
        ...this.filterStore.options,
        startAfter: undefined,
      });
      this.listener.onMessageViewChanged(view);
    }
  }

  resetView(): void {
    this.selectView(MessageView.Unprocessed);
  }
}
