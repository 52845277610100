import * as React from 'react';
import type { SVGProps } from 'react';
const SvgUpload = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M5.5 20c-.4 0-.75-.15-1.05-.45-.3-.3-.45-.65-.45-1.05v-2.825c0-.213.072-.39.217-.535a.732.732 0 0 1 .537-.215.731.731 0 0 1 .746.75V18.5h13v-2.825c0-.213.072-.39.217-.535a.732.732 0 0 1 .537-.215.731.731 0 0 1 .746.75V18.5c0 .4-.15.75-.45 1.05-.3.3-.65.45-1.05.45h-13Zm5.75-13.1L8.775 9.375a.665.665 0 0 1-.523.212.805.805 0 0 1-.537-.237.75.75 0 0 1-.215-.538c0-.208.075-.387.225-.537l3.75-3.75a.75.75 0 0 1 .253-.175.751.751 0 0 1 .275-.05c.098 0 .189.016.272.05a.762.762 0 0 1 .25.175L16.3 8.3a.736.736 0 0 1 .01 1.05.766.766 0 0 1-.547.225.734.734 0 0 1-.538-.225L12.75 6.9v8.525c0 .212-.072.39-.217.534a.732.732 0 0 1-.537.216.731.731 0 0 1-.746-.75V6.9Z"
    />
  </svg>
);
export default SvgUpload;
