import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { sanitizeHtml } from 'lib';
import { debounce, throttle } from 'lodash';

type Props = {
  srcDoc: string;
};

const RESIZE_THROTTLE_WAIT = 100;
const BODY_PADDING = 8;
const WRAPPER_ID = 'yaritori-message-root';
// HTMLメール内の要素の最大幅 (700はHubspotが送るtableタグのwidth)
const EMAIL_CHILD_ELEMENT_MAX_WIDTH = 700;

const FullHeightIframe = ({ srcDoc }: Props) => {
  const contentRef = useRef<HTMLIFrameElement>(null);
  const hasXScrollbarRef = useRef(false);
  const lastHeightRef = useRef(0);
  const [initialized, setInitialized] = useState(false);
  const { scrollbarHeight, updateScrollbarHeight } = useXScrollbarHeight();

  const setOverflowYToAuto = useMemo(
    () =>
      debounce(() => {
        const element = contentRef.current?.contentDocument?.documentElement;
        if (element) {
          element.style.setProperty('overflow-y', 'auto');
        }
      }, RESIZE_THROTTLE_WAIT),
    []
  );

  const _updateIframeHeight = useCallback(() => {
    if (!initialized) {
      return;
    }
    const iframeElement = contentRef.current;
    const contentDocument = contentRef.current?.contentDocument;
    const element = contentDocument?.documentElement;
    if (!iframeElement || !contentDocument || !element) {
      return;
    }

    const wrapperEl = contentDocument.getElementById(WRAPPER_ID);
    if (!wrapperEl) {
      return;
    }

    let scrollHeight = wrapperEl.scrollHeight;
    scrollHeight += BODY_PADDING * 2;

    if (
      iframeElement.scrollWidth < element.scrollWidth ||
      hasXScrollbarRef.current
    ) {
      // ResizeObserverの不具合が解消されたらこのif文は消していい。それまでは初回のみ更新する。
      if (!hasXScrollbarRef.current) {
        updateScrollbarHeight();
      }

      hasXScrollbarRef.current = true;
      scrollHeight += scrollbarHeight; // 横方向のスクロールバーの高さを考慮する
    }
    scrollHeight += 1; // 1ピクセル以下の小数点がある可能性がある。Macなどだとそれによりスクロールバーが出現する。

    if (scrollHeight === lastHeightRef.current) {
      return;
    }
    // htmlにheight: 100%が適用されていると、高さが伸び続けるので初期値にする。
    element.style.setProperty('height', 'initial', 'important');
    element.style.setProperty('min-height', 'initial', 'important');
    element.style.setProperty('max-height', 'initial', 'important');
    element.style.setProperty('overflow-y', 'hidden');
    contentRef.current.setAttribute('height', `${scrollHeight}px`);
    lastHeightRef.current = scrollHeight;
    setOverflowYToAuto();
  }, [initialized, scrollbarHeight, setOverflowYToAuto]);
  const debouncedUpdateIframeHeight = useMemo(
    () => debounce(() => _updateIframeHeight(), 200),
    [_updateIframeHeight]
  );
  const updateIframeHeight = useCallback(() => {
    _updateIframeHeight();
    debouncedUpdateIframeHeight(); // 念のため更新後少しあとにもう一度更新しておく。あんまり美しくない。
  }, [_updateIframeHeight, debouncedUpdateIframeHeight]);

  useEffect(() => {
    const element = contentRef.current?.contentDocument;
    if (!element) {
      return;
    }
    const parsedElement = new DOMParser().parseFromString(
      sanitizeHtml(srcDoc),
      'text/html'
    );
    parsedElement.documentElement.style.overflowY = 'hidden';

    // height: 100%とか指定されていても、無限に伸びていかないようにbodyの高さを0にする
    parsedElement.body.style.setProperty('position', 'relative', 'important');
    parsedElement.body.style.setProperty('height', '0', 'important');
    parsedElement.body.innerHTML = `
      <div id="${WRAPPER_ID}" style="position: absolute; width: 100%; height: 100%;">
        ${parsedElement.body.innerHTML}
      </div>`;
    // 動作保証外だがFirefoxでの動作に必要
    element.open();
    element.close();
    element.replaceChild(
      parsedElement.documentElement,
      element.documentElement
    );
    setInitialized(true);
    setOverflowYToAuto();
    registerImageLoadListener(element.body);
  }, []);

  const registerImageLoadListener = (contentHtml: HTMLElement) => {
    Array.from(contentHtml.querySelectorAll('img')).forEach((el) => {
      el.onload = () => updateIframeHeight();
    });
  };

  const updateIframeContentStyle = () => {
    const contentDocument = contentRef.current?.contentDocument;
    const element = contentDocument?.documentElement;

    if (contentDocument == null || element == null) {
      return;
    }
    // 画面幅を小要素の横幅が上回ると横スクロールが発生してしまう
    if (element.offsetWidth > EMAIL_CHILD_ELEMENT_MAX_WIDTH) {
      return;
    }

    const wrapperEl = contentDocument.getElementById(WRAPPER_ID);
    if (wrapperEl == null) {
      return;
    }

    // tableタグに指定されているwidthが画面幅を超えてしまう為、それを防ぐ為のstyleを当てる
    const tableEls = wrapperEl.getElementsByTagName('table');
    for (const table of tableEls) {
      // 横幅一杯にする
      table.style.setProperty('width', '100%', 'important');
      table.style.setProperty('max-width', '100%', 'important');
      table.style.setProperty('min-width', 'unset', 'important');

      // Stripeから送られるHTMLメール用のstyle
      if (table.classList.contains('st-Background')) {
        table.style.setProperty('padding', '0 8px', 'important');
        for (const td of table.getElementsByTagName('td')) {
          // 不要な空白を非表示にする
          if (td.textContent === '     ') {
            td.style.setProperty('display', 'none', 'important');
          }
        }
      }
    }
    const block = wrapperEl.getElementsByTagName('blockquote')?.[0];
    if (block != null && block.style != null) {
      block.style.setProperty('margin', '0 auto');
      block.style.setProperty('padding', '0', 'important');
    }
  };

  // 画面サイズが変更/スクロールされたら高さを更新する
  useEffect(() => {
    if (!initialized) {
      return;
    }
    const bodyEl = contentRef.current?.contentDocument?.body;
    if (!bodyEl) {
      return;
    }
    updateIframeContentStyle();
    // 一部環境でResizeObserverがエラーを吐くので一旦無効化
    // const throttled = throttle(updateIframeHeight, RESIZE_THROTTLE_WAIT);
    // const observer = new ResizeObserver(() => throttled());
    // observer.observe(bodyEl);
    // return () => observer.disconnect();
    const id = setInterval(() => _updateIframeHeight(), 1000 / 30);
    return () => clearInterval(id);
  }, [initialized, _updateIframeHeight]);

  return (
    <iframe
      title="iframe"
      className={'w-full border-none'}
      height={100}
      src="about:blank"
      ref={contentRef}
      referrerPolicy={'no-referrer'}
    />
  );
};

type UseXScrollbarHeightResult = {
  updateScrollbarHeight: () => void;
  scrollbarHeight: number;
};

const useXScrollbarHeight = (): UseXScrollbarHeightResult => {
  const [height, setHeight] = useState(0);
  const update = () => setHeight(calcXScrollbarHeight());
  const updateScrollbarHeight = useCallback(throttle(update, 200), []);
  useEffect(update, []);
  return { updateScrollbarHeight, scrollbarHeight: height };
};

const calcXScrollbarHeight = () => {
  const element = document.createElement('div');
  element.style.visibility = 'hidden';
  element.style.overflowX = 'scroll';
  element.style.pointerEvents = 'none';
  element.style.position = 'fixed';
  element.style.top = '0';
  element.style.left = '0';

  document.body.appendChild(element);
  const height = element.offsetHeight - element.clientHeight;
  document.body.removeChild(element);
  return height;
};

export default FullHeightIframe;
