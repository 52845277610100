import firebase from 'firebase/compat/app';
import { connectAuthEmulator, getAuth } from 'firebase/auth';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';
import {
  connectFirestoreEmulator,
  initializeFirestore,
} from 'firebase/firestore';
import { connectStorageEmulator, getStorage } from 'firebase/storage';

import 'firebase/compat/functions';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/auth';
import 'firebase/compat/messaging';
import 'firebase/compat/analytics';
import 'firebase/compat/performance';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

export const firebaseApp = firebase.initializeApp(firebaseConfig);

if (process.env.NODE_ENV !== 'test') {
  // テストでこけないようにする
  firebase.analytics.isSupported().then((isSupported) => {
    if (isSupported) {
      firebase.analytics();
    }
  });
}

export const db = firebase.firestore();
db.settings({
  experimentalAutoDetectLongPolling: true,
});
export const db9 = initializeFirestore(firebaseApp, {
  experimentalAutoDetectLongPolling: true,
});

export const storage = getStorage();

const isEmulating = process.env.REACT_APP_USE_EMULATOR === 'true';
if (isEmulating) {
  connectAuthEmulator(getAuth(), 'http://localhost:9099');
  connectFunctionsEmulator(getFunctions(), 'localhost', 5001);
  connectFirestoreEmulator(db, 'localhost', 8080);
  connectFirestoreEmulator(db9, 'localhost', 8080);
  connectStorageEmulator(storage, 'localhost', 9199);
}

export default firebase;
export const FieldValue = firebase.firestore.FieldValue;
export const FieldPath = firebase.firestore.FieldPath;
export const QueryDocumentSnapshot = firebase.firestore.QueryDocumentSnapshot;
export const QuerySnapshot = firebase.firestore.QuerySnapshot;
export const Query = firebase.firestore.Query;
export const DocumentSnapshot = firebase.firestore.DocumentSnapshot;

export const companyCollection = (companyId, path) =>
  db.collection(`companies/${companyId}/${path}`);

export const siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
