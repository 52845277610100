import { ReactNode } from 'react';
import { tv } from 'tailwind-variants';

type Props = {
  children: ReactNode;
  border?: boolean;
};

const footer = tv({
  base: 'grid h-[72px] grid-cols-[1fr_1fr] items-center gap-4 px-4 sm:flex sm:justify-end',
  variants: {
    border: {
      true: 'border-y border-b-transparent border-t-sumi-300',
    },
  },
});

export const DialogFooter = ({ children, border = true }: Props) => {
  return <div className={footer({ border })}>{children}</div>;
};
