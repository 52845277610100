import * as React from 'react';
import type { SVGProps } from 'react';
const SvgStarFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="m12 17.688-3.89 2.346a.72.72 0 0 1-.436.107.79.79 0 0 1-.396-.147.84.84 0 0 1-.273-.332.647.647 0 0 1-.03-.453l1.032-4.417-3.434-2.973a.737.737 0 0 1-.245-.388.702.702 0 0 1 .027-.428.845.845 0 0 1 .233-.34.807.807 0 0 1 .416-.171l4.532-.396 1.76-4.171a.68.68 0 0 1 .291-.345.819.819 0 0 1 .825 0 .68.68 0 0 1 .291.345l1.76 4.17 4.533.397a.807.807 0 0 1 .415.172.846.846 0 0 1 .233.34.703.703 0 0 1 .028.427.738.738 0 0 1-.245.388l-3.435 2.973 1.033 4.417a.648.648 0 0 1-.031.453.839.839 0 0 1-.273.332.79.79 0 0 1-.395.147.72.72 0 0 1-.436-.107L12 17.688Z"
    />
  </svg>
);
export default SvgStarFilled;
