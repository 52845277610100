import * as React from 'react';
import type { SVGProps } from 'react';
const SvgYaritoriAi = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M20.063 6v11.772h-3.06V6h3.06ZM10.885 13.4 9.512 9.482 8.138 13.4h2.747Zm.812 2.327H7.326l-.703 2.045H3.36L7.841 6h3.341l4.48 11.772H12.4l-.703-2.045Z"
    />
  </svg>
);
export default SvgYaritoriAi;
