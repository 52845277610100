import * as React from 'react';
import type { SVGProps } from 'react';
const SvgPerson = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M10 9.979c-.916 0-1.666-.292-2.25-.875-.583-.583-.874-1.333-.874-2.25s.291-1.667.875-2.25c.583-.583 1.333-.875 2.25-.875.916 0 1.666.292 2.25.875.583.583.875 1.333.875 2.25s-.292 1.667-.875 2.25c-.584.583-1.334.875-2.25.875Zm5.417 6.688H4.584c-.344 0-.638-.123-.883-.368a1.204 1.204 0 0 1-.367-.883v-.708c0-.528.132-.979.396-1.354.264-.375.604-.66 1.02-.854.931-.417 1.824-.73 2.678-.938A10.824 10.824 0 0 1 10 11.25c.86 0 1.715.107 2.562.323.847.215 1.736.525 2.665.93.435.197.783.482 1.046.854.262.373.393.823.393 1.351v.708c0 .344-.122.639-.367.883a1.204 1.204 0 0 1-.883.367Zm-10.833-1.25h10.833v-.709c0-.222-.066-.434-.198-.635a1.254 1.254 0 0 0-.49-.448c-.888-.43-1.7-.726-2.437-.886-.736-.16-1.5-.24-2.291-.24-.792 0-1.563.08-2.313.24-.75.16-1.562.455-2.437.886a1.184 1.184 0 0 0-.48.448 1.186 1.186 0 0 0-.187.635v.708Zm5.417-6.688c.541 0 .99-.177 1.343-.531.355-.354.532-.802.532-1.344s-.177-.99-.532-1.344c-.354-.354-.802-.531-1.343-.531-.542 0-.99.177-1.344.531-.354.354-.531.802-.531 1.344s.177.99.53 1.344c.355.354.803.531 1.345.531Z"
    />
  </svg>
);
export default SvgPerson;
