import React from 'react';
import { useParams } from 'react-router-dom';

import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react';
import { Conversation } from './Conversation/Conversation';
import { useAtomValue } from 'jotai';
import { threadViewAtom } from '../../../atoms/auth';

const Index = observer(() => {
  const store = useStore();
  const threadView = useAtomValue(threadViewAtom);
  const { messageId, commentId } = useParams<{
    messageId: string;
    commentId: string;
  }>();
  if (store.preferencesLoading) {
    return null;
  }

  return (
    <Conversation
      threadView={threadView}
      messageId={messageId}
      commentId={commentId}
      key={messageId}
    />
  );
});

export default Index;
