import React, {
  ComponentProps,
  forwardRef,
  PropsWithChildren,
  ReactNode,
} from 'react';
import { match, NavLink } from 'react-router-dom';
import { Location } from 'history';
import { twMerge } from 'tailwind-merge';

export type IsActiveRouteFunction = (
  match: match | null,
  location: Location
) => boolean;

type Props = ComponentProps<'div'> & {
  to?: string;
  exact?: boolean;
  badge?: number;
  isActiveRoute?: IsActiveRouteFunction;
  label?: string;
  children?: ReactNode;
};

export const MainNavItem = forwardRef<HTMLDivElement, PropsWithChildren<Props>>(
  (
    { to, exact, badge, isActiveRoute, label, children, className, ...props },
    ref
  ) => {
    return (
      <div
        className={twMerge(
          'relative flex h-10 w-10 cursor-pointer items-center justify-center',
          className
        )}
        {...props}
        ref={ref}
      >
        {to ? (
          <NavLink
            to={to}
            exact={exact}
            isActive={isActiveRoute}
            className="flex h-full w-full items-center justify-center rounded-lg [&:not([aria-current])]:hover:bg-sumi-100"
            activeClassName="bg-sumi-200"
            aria-label={label}
          >
            {children}
          </NavLink>
        ) : (
          children
        )}
        {badge != null && badge > 0 && (
          <div className="absolute right-0.5 top-0.5 flex h-4 w-4 animate-bounce items-center justify-center rounded-3xl bg-[#FF5500] text-white">
            {badge}
          </div>
        )}
      </div>
    );
  }
);

MainNavItem.displayName = 'MainNavItem';
