import { DatePicker, Form, Input, Button, Select } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { RecordType, SalesforceStore } from 'store/salesforce';

interface Account {
  Id: string;
  Name: string;
}

interface Props {
  form: WrappedFormUtils;
  type: RecordType;
  value: any;
  salesforceStore: SalesforceStore;
  onCancel: () => void;
  onSubmit: (data: any) => void;
}

export const SalesforceForm = Form.create<Props>({
  name: 'SalesforceForm',
})(({ form, type, value, salesforceStore, onCancel, onSubmit }: Props) => {
  const [submitting, setSubmitting] = useState(false);
  const [account, setAccount] = useState(null);
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [stages, setStages] = useState<string[]>([]);
  const [statuses, setStatuses] = useState<string[]>([]);

  useEffect(() => {
    switch (type) {
      case 'Contact':
        if (value.AccountId) {
          setAccounts([{ Id: value.AccountId, Name: value.Account.Name }]);
        }
        salesforceStore.getRecords('Account').then((data) => setAccounts(data));
        break;
      case 'Opportunity':
        salesforceStore
          .getRecords('OpportunityStage')
          .then((data) => setStages(data));
        break;
      case 'Task':
        salesforceStore
          .getRecords('TaskStatus')
          .then((data) => setStatuses(data));
        break;
    }
  }, [type]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setSubmitting(true);
    form.validateFields(async (err, values) => {
      if (err) {
        console.error(err, values);
      } else {
        if (values.AccountId) {
          const account = accounts.find((a) =>
            [a.Id, a.Name].includes(values.AccountId)
          );
          if (account) {
            values.AccountId = account.Id;
          } else {
            // Create a new Account.
            const newAccount = await salesforceStore.saveRecord('Account', {
              Name: values.AccountId,
            });
            values.AccountId = newAccount.id;
          }
        } else if (values.CloseDate) {
          values.CloseDate = values.CloseDate.format('YYYY-MM-DD');
        } else if (values.ActivityDate) {
          values.ActivityDate = values.ActivityDate.format('YYYY-MM-DD');
        }
        await onSubmit(values);
      }
      setSubmitting(false);
    });
  };

  const onChangeSelect = (v: any) => {
    if (v) {
      setAccount(v);
    }
  };

  const onSearchSelect = (v: any) => {
    if (v) {
      setAccount(v);
    }
  };

  const onBlurSelect = () => {
    form.setFieldsValue({ AccountId: account });
  };

  const renderNameEmailFields = () => (
    <>
      <Form.Item label="姓">
        {form.getFieldDecorator('LastName', {
          initialValue: value.LastName,
          rules: [{ required: true, message: '姓を入力してください' }],
        })(<Input />)}
      </Form.Item>
      <Form.Item label="名">
        {form.getFieldDecorator('FirstName', {
          initialValue: value.FirstName,
        })(<Input />)}
      </Form.Item>
      <Form.Item label="メール">
        {form.getFieldDecorator('Email', {
          initialValue: value.Email,
          rules: [
            {
              required: true,
              message: 'メールを入力してください',
              pattern:
                /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
            },
          ],
        })(<Input />)}
      </Form.Item>
    </>
  );
  let fields;
  switch (type) {
    case 'Account':
    case 'Contact':
      fields = (
        <>
          {renderNameEmailFields()}

          <Form.Item label="取引先">
            {form.getFieldDecorator('AccountId', {
              initialValue: value.AccountId,
            })(
              <Select
                showSearch
                optionFilterProp="label"
                optionLabelProp="label"
                onChange={onChangeSelect}
                onBlur={onBlurSelect}
                onSearch={onSearchSelect}
                disabled={submitting}
              >
                {accounts.map((account: Account) => (
                  <Select.Option key={account.Id} label={account.Name}>
                    {account.Name}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </>
      );
      break;
    case 'Case':
      fields = (
        <Form.Item label="件名">
          {form.getFieldDecorator('Subject', {
            initialValue: value.Subject,
          })(<Input />)}
        </Form.Item>
      );
      break;
    case 'Lead':
      fields = (
        <>
          {renderNameEmailFields()}
          <Form.Item label="会社名">
            {form.getFieldDecorator('Company', {
              initialValue: value.Company,
              rules: [
                {
                  required: true,
                  message: '会社名を入力してください',
                },
              ],
            })(<Input />)}
          </Form.Item>
        </>
      );
      break;
    case 'Opportunity':
      fields = (
        <>
          <Form.Item label="商談名">
            {form.getFieldDecorator('Name', {
              initialValue: value.Name,
              rules: [
                {
                  required: true,
                  message: '商談名を入力してください',
                },
              ],
            })(<Input />)}
          </Form.Item>
          <Form.Item label="金額">
            {form.getFieldDecorator('Amount', {
              initialValue: value.Amount,
            })(<Input type="number" />)}
          </Form.Item>
          <Form.Item label="フェーズ">
            {form.getFieldDecorator('StageName', {
              initialValue: value.StageName,
              rules: [
                {
                  required: true,
                  message: 'フェーズを入力してください',
                },
              ],
            })(
              <Select showSearch disabled={submitting}>
                {stages.map((stage) => (
                  <Select.Option key={stage}>{stage}</Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item label="完了予定日">
            {form.getFieldDecorator('CloseDate', {
              initialValue: moment(value.CloseDate),
              rules: [
                {
                  required: true,
                  message: '完了予定日を入力してください',
                },
              ],
            })(
              <DatePicker
                disabledDate={(date) =>
                  date?.isBefore(moment(), 'day') || false
                }
              />
            )}
          </Form.Item>
        </>
      );
      break;
    case 'Task':
      fields = (
        <>
          <Form.Item label="件名">
            {form.getFieldDecorator('Subject', {
              initialValue: value.Subject,
              rules: [
                {
                  required: true,
                  message: '件名を入力してください',
                },
              ],
            })(<Input />)}
          </Form.Item>
          <Form.Item label="状況">
            {form.getFieldDecorator('Status', {
              initialValue: value.Status,
              rules: [
                {
                  required: true,
                  message: '状況を入力してください',
                },
              ],
            })(
              <Select showSearch disabled={submitting}>
                {statuses.map((status) => (
                  <Select.Option key={status}>{status}</Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item label="期日">
            {form.getFieldDecorator('ActivityDate', {
              initialValue: moment(value.ActivityDate),
            })(
              <DatePicker
                disabledDate={(date) =>
                  date?.isBefore(moment(), 'day') || false
                }
              />
            )}
          </Form.Item>
        </>
      );
      break;
  }

  return (
    <div>
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        {fields}

        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button onClick={onCancel}>キャンセル</Button>
          <Button
            type="primary"
            htmlType="submit"
            style={{ marginLeft: '1rem' }}
            loading={submitting}
          >
            保存
          </Button>
        </div>
      </Form>
    </div>
  );
});
