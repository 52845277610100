import {
  convertHtmlToQuote,
  convertNtoBr,
  escapeHtml,
  quoteText,
} from './util';
import { MessageConfig } from './messageComposer';
import {
  createMessage,
  createSent,
  StorageMessage,
  StorageSent,
} from './entity';

export class QuotedBodyGenerator {
  isReplyToSent: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  message: any | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sent: any | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  storageMessage: any | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  storageSent: any | undefined;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(private storageFetcher: any, private draft: MessageConfig) {
    this.isReplyToSent = Boolean(this.draft.inReplyToSentRef);
  }

  async getMessage() {
    if (this.message) return this.message;

    const inReplyToMessage = await this.draft.inReplyToMessageRef.get();
    this.message = createMessage(inReplyToMessage.ref, inReplyToMessage.data());
    return this.message;
  }

  async getSent() {
    if (this.sent) return this.sent;

    const inReplyToSent = await this.draft.inReplyToSentRef.get();
    this.sent = createSent(inReplyToSent.ref, inReplyToSent.data());
    return this.sent;
  }

  async fetchStorageSent() {
    if (this.storageSent) return this.storageSent;

    const sent = await this.getSent();
    this.storageSent = new StorageSent(
      this.draft.inReplyToSentId!,
      await this.storageFetcher.fetchJson(sent.storagePath)
    );
    return this.storageSent;
  }

  async fetchStorageMessage() {
    if (this.storageMessage) return this.storageMessage;

    const message = await this.getMessage();
    this.storageMessage = new StorageMessage(
      message.id,
      await this.storageFetcher.fetchJson(message.storagePath)
    );
    return this.storageMessage;
  }

  quoteHtml(header: string, html: string) {
    return `<br><br>${convertNtoBr(
      escapeHtml(header)
    )}<blockquote style="margin:0 0 0 0.8ex;border-left:1px solid rgb(204,204,204);padding-left:1ex">${html}</blockquote>`;
  }

  /* for StorageSent */
  async headerForStorageSent() {
    const storageSent = await this.fetchStorageSent();
    return this.draft.isForwarded
      ? storageSent.generateForwardedHeader()
      : storageSent.generateQuotedHeader();
  }

  async textForStorageSent() {
    const storageSent = await this.fetchStorageSent();
    const header = await this.headerForStorageSent();
    return '\n\n' + header + storageSent.quotedText;
  }

  async htmlForStorageSent() {
    const storageSent = await this.fetchStorageSent();
    const header = await this.headerForStorageSent();
    return this.quoteHtml(
      header,
      storageSent.htmlForQuote || convertNtoBr(storageSent.text)
    );
  }

  /* for StorageMessage */
  async headerForStorageMessage() {
    const storageMessage = await this.fetchStorageMessage();
    return this.draft.isForwarded
      ? storageMessage.generateForwardedHeader()
      : storageMessage.generateQuotedHeader();
  }

  async htmlForStorageMessage() {
    const storageMessage = await this.fetchStorageMessage();
    const header = await this.headerForStorageMessage();
    return this.quoteHtml(
      header,
      storageMessage.htmlForQuote ||
        (storageMessage.textAsHtml
          ? storageMessage.sanitizedTextAsHtml
          : convertNtoBr(storageMessage.text))
    );
  }

  /* for Message */
  async headerForMessage() {
    const message = await this.getMessage();
    return this.draft.isForwarded
      ? message.generateForwardedHeader()
      : message.generateQuotedHeader();
  }

  async textForMessage() {
    const message = await this.getMessage();
    let quotedText = '（テキストパートが存在しません）';
    if (message.storagePath) {
      const storageMessage = await this.fetchStorageMessage();
      quotedText = storageMessage.quotedText;
    }

    if (message.textStoragePath) {
      quotedText = quoteText(
        await this.storageFetcher.fetchText(message.textStoragePath)
      );
    }
    const header = await this.headerForMessage();
    return '\n\n' + header + quotedText;
  }

  async htmlForMessage() {
    const message = await this.getMessage();
    if (message.storagePath) {
      return await this.htmlForStorageMessage();
    }

    const header = await this.headerForMessage();

    if (message.htmlStoragePath) {
      const html =
        message.htmlForQuote ||
        convertHtmlToQuote(
          await this.storageFetcher.fetchText(message.htmlStoragePath)
        );
      return this.quoteHtml(header, html);
    }

    if (message.textAsHtmlStoragePath) {
      const html = convertHtmlToQuote(
        message.textAsHtml
          ? message.sanitizedTextAsHtml
          : await this.storageFetcher.fetchText(message.textAsHtmlStoragePath)
      );
      return this.quoteHtml(header, html ?? '');
    }

    return this.quoteHtml(header, '');
  }

  async execute() {
    if (this.isReplyToSent) {
      // 送信に対する返信
      const text = await this.textForStorageSent();
      const html = await this.htmlForStorageSent();
      return [text, html];
    }

    // 受信に対する返信
    const text = await this.textForMessage();
    const html = await this.htmlForMessage();
    return [text, html];
  }
}

type FetchJson = (path: string) => Promise<string>;
type FetchText = (path: string) => Promise<string>;

export class StorageFetcher {
  _fetchJson: FetchJson;
  _fetchText: FetchText;

  constructor(fetchJson: FetchJson, fetchText: FetchText) {
    this._fetchJson = fetchJson;
    this._fetchText = fetchText;
  }

  fetchJson(path: string) {
    return this._fetchJson(path);
  }

  fetchText(path: string) {
    return this._fetchText(path);
  }
}
