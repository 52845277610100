import * as React from 'react';
import type { SVGProps } from 'react';
const SvgGroups = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M.625 15a.605.605 0 0 1-.445-.18.605.605 0 0 1-.18-.445v-.48c0-.547.286-.986.86-1.316.572-.33 1.328-.496 2.265-.496a7.464 7.464 0 0 1 .958.063 3.493 3.493 0 0 0-.333 1.52V15H.625Zm5 0a.605.605 0 0 1-.445-.18.605.605 0 0 1-.18-.445v-.708c0-.903.462-1.636 1.385-2.198.924-.563 2.129-.844 3.615-.844 1.5 0 2.708.281 3.625.844.917.562 1.375 1.295 1.375 2.198v.708c0 .177-.06.325-.18.445a.605.605 0 0 1-.445.18h-8.75Zm10.625 0v-1.333c0-.281-.028-.546-.083-.794a3.622 3.622 0 0 0-.25-.733c.152-.024.309-.04.468-.046.16-.007.324-.01.492-.01.93 0 1.682.164 2.258.495.577.33.865.769.865 1.317v.479c0 .177-.06.325-.18.445a.605.605 0 0 1-.445.18H16.25ZM10 11.875c-1.125 0-2.031.17-2.719.51-.687.34-1.031.768-1.031 1.282v.083h7.5v-.104c0-.5-.344-.92-1.031-1.26-.688-.34-1.594-.511-2.719-.511Zm-6.877-.417a1.4 1.4 0 0 1-1.03-.429A1.409 1.409 0 0 1 1.668 10a1.4 1.4 0 0 1 .429-1.03 1.41 1.41 0 0 1 1.03-.427 1.4 1.4 0 0 1 1.03.429c.285.286.427.63.427 1.03a1.4 1.4 0 0 1-.429 1.03c-.286.285-.63.427-1.03.427Zm13.75 0a1.4 1.4 0 0 1-1.03-.429 1.409 1.409 0 0 1-.426-1.03 1.4 1.4 0 0 1 .429-1.03c.285-.285.63-.427 1.03-.427a1.4 1.4 0 0 1 1.03.429c.285.286.427.63.427 1.03a1.4 1.4 0 0 1-.429 1.03c-.285.285-.63.427-1.03.427ZM10 10a2.41 2.41 0 0 1-1.77-.73A2.41 2.41 0 0 1 7.5 7.5c0-.694.243-1.285.73-1.77A2.41 2.41 0 0 1 10 5a2.41 2.41 0 0 1 1.77.73c.487.485.73 1.076.73 1.77s-.243 1.285-.73 1.77A2.41 2.41 0 0 1 10 10Zm0-3.75c-.354 0-.651.12-.89.36-.24.239-.36.536-.36.89s.12.651.36.89c.239.24.536.36.89.36s.651-.12.89-.36c.24-.239.36-.536.36-.89s-.12-.651-.36-.89a1.21 1.21 0 0 0-.89-.36Z"
    />
  </svg>
);
export default SvgGroups;
