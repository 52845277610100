import { FirestoreDataConverter, Timestamp } from 'firebase/firestore';
import { createConverter, createEntityFactory } from '../converter';

export type CompanyData = {
  name: string;
  storageSize: number;
  stripeCustomerId: string;
  stripeProductId: string;
  importImapSupported?: boolean;
  yaritoriAISupported?: boolean;
  lineIntegrationCount?: number;

  /**
   * メールテンプレートの上限登録数である。値以上に追加することができない。
   */
  maxTemplates?: number | null;

  /**
   * ユーザグループの上限登録数である。値以上に追加することができない。
   */
  maxUserGroups?: number | null;
  ipBlockCount?: number;
  alertComplaintEmail?: boolean;
  createdAt: Timestamp;
  updatedAt: Timestamp;
};

export const createCompany = createEntityFactory<'Company', CompanyData>(
  'Company'
);

export type Company = ReturnType<typeof createCompany>;

export const companyConverter: FirestoreDataConverter<Company> =
  createConverter(createCompany);
