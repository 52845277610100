import React, { useState } from 'react';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/ja_JP';
import { Modal, DatePicker } from 'antd';
import 'moment/locale/ja';

const loc = {
  ...locale,
  lang: {
    ...locale.lang,
    dateSelect: '日付を選択',
  },
};

interface Props {
  title: string;
  okText: string;
  initialValue?: moment.Moment;
  close: () => void;
  onChange: (value: moment.Moment) => void;
}
export const DatePickerModal: React.FC<Props> = ({
  title,
  okText,
  initialValue,
  close,
  onChange,
}) => {
  const [value, setValue] = useState<moment.Moment>(
    initialValue ?? moment().seconds(0).add(1, 'hours')
  );

  return (
    <Modal
      title={title}
      visible={true}
      okText={okText}
      cancelText="キャンセル"
      onOk={() => {
        onChange(value);
        close();
      }}
      onCancel={() => {
        close();
      }}
    >
      <DatePicker
        showTime={{
          format: 'HH:mm',
        }}
        locale={loc}
        autoFocus
        showToday
        allowClear={false}
        value={value}
        format="YYYY/MM/DD HH:mm"
        disabledDate={(date) => date?.isBefore(moment(), 'day') || false}
        disabledTime={(date) => {
          const now = moment();

          if (date?.isAfter(now, 'day')) return {};

          return {
            disabledHours: () => [...Array(now.hour()).keys()],
            disabledMinutes: () =>
              date?.isAfter(now, 'hour')
                ? []
                : [...Array(now.minute() + 1).keys()],
          };
        }}
        onChange={(date) => setValue(date!)}
      />
    </Modal>
  );
};
