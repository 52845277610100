import React, { Component } from 'react';
import parse from 'html-react-parser';
import { withRouter } from 'react-router-dom';
import { Skeleton } from 'antd';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import level from '../../../font/level';
import Attachments from './attachments';
import media from 'styled-media-query';

class Index extends Component {
  render() {
    const { message, loading, attachments, html } = this.props;
    return (
      <BodyWrapper>
        {loading && <Skeleton loading={loading} active />}
        {!loading && (
          <>
            <MessageInfo>
              <div>
                <div style={{ display: 'flex' }}>
                  <MessageInfoText>{message.fromText}</MessageInfoText>
                </div>
                {message.replyAddress &&
                  message.replyAddress !== message.fromText && (
                    <div style={{ display: 'flex' }}>
                      <MessageInfoText>返信先:</MessageInfoText>
                      <MessageInfoText>{message.replyAddress}</MessageInfoText>
                    </div>
                  )}
                {message.to && (
                  <div style={{ display: 'flex' }}>
                    <MessageInfoText>To:</MessageInfoText>
                    {message.toAddresses.map((address) => (
                      <MessageInfoText
                        key={address}
                      >{`　${address}`}</MessageInfoText>
                    ))}
                  </div>
                )}
                {message.cc && (
                  <div style={{ display: 'flex' }}>
                    <MessageInfoText>Cc:</MessageInfoText>
                    {message.ccAddresses.map((address) => (
                      <MessageInfoText
                        key={address}
                      >{`　${address}`}</MessageInfoText>
                    ))}
                  </div>
                )}
                {message.bcc && (
                  <div style={{ display: 'flex' }}>
                    <MessageInfoText>Bcc:</MessageInfoText>
                    {message.bccAddresses.map((address) => (
                      <MessageInfoText
                        key={address}
                      >{`　${address}`}</MessageInfoText>
                    ))}
                  </div>
                )}
              </div>
              <div>
                <MessageInfoText>
                  {message.date.format('YYYY年M月D日 HH:mm')}
                </MessageInfoText>
              </div>
            </MessageInfo>
            <MessageBody>
              <Attachments attachments={attachments} />
              {parse(html)}
            </MessageBody>
          </>
        )}
      </BodyWrapper>
    );
  }
}

export default compose(withRouter, inject('store'), observer)(Index);

const MessageInfo = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
`;

const MessageInfoText = styled.p`
  margin-bottom: 2px;
  font-size: ${level[1]};
`;

const MessageBody = styled.div`
  margin-bottom: 20px;
  font-size: ${level[1]};

  ${media.lessThan('small')`
    margin-bottom: 12px;
  `};

  @media print {
    scrollbar-width: none;

    & *::-webkit-scrollbar{
      display: none;
      }

    // 廃止プロパティだが一部のメーラーが混入させてくるため無効化
    & div.WordSection1 {
      page: initial;
    }
`;

const BodyWrapper = styled.div`
  word-break: break-word;
`;
