import React, { ReactNode } from 'react';
import { MediaQuery } from './MediaQuery';
import { SCREEN_LG, SCREEN_SM } from '../../../constants';

type Props = {
  children: ReactNode;
};

export const PCOnly = ({ children }: Props) => {
  return <MediaQuery minWidth={SCREEN_LG + 1}>{children}</MediaQuery>;
};

export const TabletOnly = ({ children }: Props) => {
  return (
    <MediaQuery minWidth={SCREEN_SM + 1} maxWidth={SCREEN_LG}>
      {children}
    </MediaQuery>
  );
};

export const SPOnly = ({ children }: Props) => {
  return <MediaQuery maxWidth={SCREEN_SM}>{children}</MediaQuery>;
};

export const TabletOrSPOnly = ({ children }: Props) => {
  return <MediaQuery maxWidth={SCREEN_LG}>{children}</MediaQuery>;
};

export const PCOrTabletOnly = ({ children }: Props) => {
  return <MediaQuery minWidth={SCREEN_SM + 1}>{children}</MediaQuery>;
};
