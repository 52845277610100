import * as React from 'react';
import type { SVGProps } from 'react';
const SvgSearch = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M19.35 20.425 13.325 14.4c-.5.433-1.083.77-1.749 1.012a6.183 6.183 0 0 1-2.126.363c-1.803 0-3.328-.625-4.577-1.875S3 11.14 3 9.375C3 7.608 3.625 6.1 4.875 4.85c1.25-1.25 2.763-1.875 4.537-1.875 1.775 0 3.284.625 4.525 1.875C15.18 6.1 15.8 7.61 15.8 9.378c0 .714-.117 1.405-.35 2.072a6.343 6.343 0 0 1-1.05 1.875l6.075 6.025c.15.14.225.313.225.518 0 .204-.083.39-.248.554a.758.758 0 0 1-.558.228.719.719 0 0 1-.544-.225Zm-9.925-6.15c1.354 0 2.505-.48 3.453-1.438S14.3 10.725 14.3 9.375s-.474-2.505-1.422-3.463c-.948-.958-2.099-1.437-3.453-1.437-1.368 0-2.53.479-3.489 1.437C4.98 6.87 4.5 8.025 4.5 9.375s.479 2.504 1.436 3.462c.958.958 2.121 1.438 3.489 1.438Z"
    />
  </svg>
);
export default SvgSearch;
