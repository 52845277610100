import {
  ComponentPropsWithRef,
  forwardRef,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';
import { Icon } from '../../basics';
import { Tick } from '../../icons';

type Props = ComponentPropsWithRef<'input'> & {
  label?: string;
  indeterminate?: boolean;
};

export const Checkbox = forwardRef<HTMLInputElement, Props>(
  ({ label, indeterminate, className, ...inputProps }, ref) => {
    const inputRef = useRef<HTMLInputElement>(null);

    useImperativeHandle(ref, () => inputRef.current!);

    const checkbox = useMemo(() => {
      return (
        <div className="relative h-5 w-5">
          <input
            type="checkbox"
            className="disabled:border-sumi-40 peer peer h-5 w-5 cursor-pointer appearance-none rounded border-2 border-sumi-800 bg-white checked:border-sea-500 checked:bg-sea-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-sea-200 disabled:cursor-not-allowed disabled:border-sumi-400 disabled:checked:bg-sumi-400"
            {...inputProps}
            ref={inputRef}
          />
          <Icon
            icon={Tick}
            size={20}
            className="pointer-events-none invisible absolute left-0 top-0 text-white peer-checked:visible"
          />
          {indeterminate && (
            <div className="pointer-events-none absolute inset-[5px] rounded-[2px] bg-sea-500 peer-checked:invisible peer-disabled:bg-sumi-400" />
          )}
        </div>
      );
    }, [indeterminate, inputProps]);
    return label ? (
      <label className="grid grid-cols-[20px_auto] gap-2 text-sm">
        {checkbox}
        {label}
      </label>
    ) : (
      checkbox
    );
  }
);

Checkbox.displayName = 'Checkbox';
