import {
  atomWithSubscribeCollection,
  companyCollection,
} from '../../firestore';
import { orderBy, query } from 'firebase/firestore';
import { deliveryAddressConverter } from 'lib';

export const [deliveryAddressesLoadingAtom, deliveryAddressesAtom] =
  atomWithSubscribeCollection(
    () =>
      query(
        companyCollection('deliveryAddresses', deliveryAddressConverter),
        orderBy('email')
      ),
    (snapshot) => snapshot.docs.map((doc) => doc.data())
  );
