import { createConverter, createEntityFactory } from '../converter';
import { FirestoreDataConverter, Timestamp } from 'firebase/firestore';

export interface TeamData {
  name: string;
  roles: { [userId: string]: string };
  statuses: string[];
  isPrivate: boolean;
  autoReplyDrafterId?: string;
  createdAt: Timestamp;
  updatedAt: Timestamp;
}

export const createTeam = createEntityFactory('Team', (data: TeamData) => ({
  isMember(userId: string) {
    return Boolean(data.roles[userId]);
  },

  isOwner(userId: string) {
    return data.roles[userId] === 'owner';
  },
}));

export type Team = ReturnType<typeof createTeam>;

export const teamConverter: FirestoreDataConverter<Team> =
  createConverter(createTeam);
