import * as React from 'react';
import type { SVGProps } from 'react';
const SvgEdit = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M4.5 19.5h1.1L16.675 8.425l-1.1-1.1L4.5 18.4v1.1ZM19.85 7.35l-3.2-3.2 1.046-1.046a1.407 1.407 0 0 1 1.066-.417c.425.009.78.155 1.063.438L20.9 4.2c.283.283.425.633.425 1.05 0 .417-.141.766-.424 1.049l-1.051 1.05ZM3.758 21a.734.734 0 0 1-.54-.218.733.733 0 0 1-.218-.54v-2.14c0-.102.017-.194.05-.277a.76.76 0 0 1 .175-.25L15.6 5.2l3.2 3.2L6.425 20.775a.745.745 0 0 1-.255.175.748.748 0 0 1-.27.05H3.758ZM16.125 7.875l-.55-.55 1.1 1.1-.55-.55Z"
    />
  </svg>
);
export default SvgEdit;
