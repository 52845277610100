import React from 'react';
import styled from 'styled-components';
import { Menu } from 'antd';
import Avatar from '../../../../Common/Avatar';

const { SubMenu } = Menu;

export const AssigneeSubOptions = ({
  users,
  teamMembers,
  onClickAssignee,
  ...props
}) => {
  return (
    <SubMenu title="担当者を設定" {...props}>
      <Menu.Item onClick={() => onClickAssignee(null)}>担当者なし</Menu.Item>
      {users.map((user) => {
        const notInTeam = teamMembers.every((m) => m.id !== user.id);
        return (
          <Menu.Item
            key={user.id}
            onClick={() => onClickAssignee(user)}
            disabled={notInTeam}
          >
            <Avatar user={user} size="small" style={{ marginTop: -3 }} />
            <Name>
              {notInTeam ? '(チーム外)' : ''}
              {user.name}
            </Name>
          </Menu.Item>
        );
      })}
    </SubMenu>
  );
};

const Name = styled.span`
  margin-left: 4px;
`;
