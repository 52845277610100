import { query, orderBy } from 'firebase/firestore';
import {
  atomWithSubscribeCollection,
  companyCollection,
} from '../../firestore';
import { CustomStatus, customStatusesConverter } from 'lib';
import { atom } from 'jotai';
import { atomFamily } from 'jotai/utils';
import { joinedTeamIdsAtom } from './team';

export type CustomStatusesData = Record<string, CustomStatus[]>;

export const statusesAtomFamily = atomFamily((teamId: string) => {
  const [loadingAtom, dataAtom] = atomWithSubscribeCollection<CustomStatus>(
    () =>
      query(
        companyCollection(
          `teams/${teamId}/customStatuses`,
          customStatusesConverter
        ),
        orderBy('order', 'asc')
      ),
    (snapshot) => {
      return snapshot.docs.map((doc) => doc.data());
    }
  );

  return atom<[boolean, CustomStatus[]]>((get) => [
    get(loadingAtom),
    get(dataAtom),
  ]);
});

export const allTeamStatusesInGroupAtom = atom<[boolean, CustomStatusesData]>(
  (get) => {
    const result: CustomStatusesData = {};
    let loading = true;

    get(joinedTeamIdsAtom).forEach((teamId) => {
      const [loadingByTeam, dataByTeam] = get(statusesAtomFamily(teamId));
      result[teamId] = dataByTeam;

      if (!loadingByTeam) {
        loading = false;
      }
    });

    return [loading, result];
  }
);

export const isAllStatusesLoadedAtom = atom((get) => {
  let allLoaded = true;
  get(joinedTeamIdsAtom).forEach((teamId) => {
    const [loading] = get(statusesAtomFamily(teamId));

    if (loading) {
      allLoaded = false;
    }
  });

  return allLoaded;
});
