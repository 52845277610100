import * as React from 'react';
import type { SVGProps } from 'react';
const SvgCaretDown = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M12 15.075a.735.735 0 0 1-.276-.05.76.76 0 0 1-.25-.175L6.524 9.9c-.133-.134-.196-.313-.187-.538a.769.769 0 0 1 .212-.537c.167-.167.346-.238.538-.213a.94.94 0 0 1 .512.238l4.4 4.4 4.4-4.4a.816.816 0 0 1 .538-.225.592.592 0 0 1 .537.225.551.551 0 0 1 .213.525.974.974 0 0 1-.238.55l-4.925 4.925a.763.763 0 0 1-.25.175.735.735 0 0 1-.275.05Z"
    />
  </svg>
);
export default SvgCaretDown;
