import { Select } from '../../../../../components/forms';

type Signature = {
  id: string;
  name: string;
};

type Props = {
  value: string | null;
  onChange: (value: string | null) => void;
  signatures: Signature[];
  disabled?: boolean;
};

export const SignatureField = ({
  value,
  onChange,
  signatures,
  disabled,
}: Props) => {
  return (
    <Select
      value={value}
      onChange={onChange}
      options={[
        { value: null, label: 'なし' },
        ...signatures.map((s) => ({ value: s.id, label: s.name })),
      ]}
      className="h-10"
      variants={{ rounded: 'lg', width: 'full' }}
      disabled={disabled}
    />
  );
};
