import { Dialog } from '../../../../../components/basics/dialog/Dialog';
import { DialogHeader } from '../../../../../components/basics/dialog/DialogHeader';
import { DialogContent } from '../../../../../components/basics/dialog/DialogContent';
import { DialogFooter } from '../../../../../components/basics/dialog/DialogFooter';
import { Button, Loading } from '../../../../../components/basics';
import { InputGroup } from '../../../../../components/forms/InputGroup/InputGroup';
import { Input } from '../../../../../components/forms';
import { RadioSelect } from '../../../../../components/basics/Radio/RadioSelect/RadioSelect';
import { useConfirmDialog } from '../../../../../hooks/confirmDialog';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

export type SmtpSettings = {
  host: string;
  port: 465 | 587;
  username: string;
};

export type SmtpUpdate = SmtpSettings & {
  password: string;
};

type Props = {
  settings: SmtpSettings | undefined;
  loading: boolean;
  onUpdate: (update: SmtpUpdate) => Promise<void>;
  onDelete: () => Promise<void>;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  modal?: boolean;
  container?: HTMLElement;
};

export const SmtpEditDialog = ({
  settings,
  loading,
  onUpdate,
  onDelete,
  open,
  onOpenChange,
  container,
  modal,
}: Props) => {
  const openDialog = useConfirmDialog();
  const {
    control,
    register,
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<SmtpUpdate>({
    defaultValues: {
      host: '',
      port: 465,
      username: '',
      password: '',
      ...(settings ?? {}),
    },
  });

  useEffect(() => {
    if (settings) {
      reset(settings);
    }
  }, [reset, settings]);

  const onSubmit = async (update: SmtpUpdate) => {
    try {
      await onUpdate(update);
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <Dialog
      width="sm"
      open={open}
      onOpenChange={(open) => {
        if (!open && isSubmitting) {
          return;
        }
        onOpenChange(open);
      }}
      container={container}
      contentClassName="top-[20%]"
      modal={modal}
    >
      <form action="" onSubmit={handleSubmit(onSubmit)}>
        <DialogHeader title="SMTP連携設定" />
        <DialogContent className="flex flex-col gap-4">
          {loading ? (
            <div className="flex min-h-[200px] items-center justify-center">
              <Loading size={24} className="text-sea-500" />
            </div>
          ) : (
            <>
              <Controller
                name="host"
                control={control}
                rules={{
                  required: '入力してください',
                }}
                render={({ fieldState: { error } }) => (
                  <InputGroup label="サーバー" errorMessage={error?.message}>
                    <Input
                      placeholder="smtp.example.com"
                      {...register('host')}
                    />
                  </InputGroup>
                )}
              />
              <Controller
                name="port"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <InputGroup label="ポート番号">
                    <RadioSelect
                      options={[
                        { value: 465, label: '465' },
                        { value: 587, label: '587' },
                      ]}
                      value={value}
                      onChange={onChange}
                    />
                  </InputGroup>
                )}
              />
              <Controller
                name="username"
                control={control}
                rules={{
                  required: '入力してください',
                }}
                render={({ fieldState: { error } }) => (
                  <InputGroup label="ユーザー名" errorMessage={error?.message}>
                    <Input
                      placeholder="name@example.com"
                      {...register('username')}
                    />
                  </InputGroup>
                )}
              />
              <Controller
                name="password"
                control={control}
                rules={{
                  required: '入力してください',
                }}
                render={({ fieldState: { error } }) => (
                  <InputGroup label="パスワード" errorMessage={error?.message}>
                    <Input {...register('password')} />
                  </InputGroup>
                )}
              />
            </>
          )}
        </DialogContent>
        <DialogFooter>
          {settings && (
            <Button
              color="danger"
              variant="text"
              onClick={() =>
                openDialog({
                  title: `SMTP連携を解除しますか？`,
                  okType: 'danger',
                  okText: '解除',
                  onOk: onDelete,
                })
              }
              disabled={isSubmitting || loading}
            >
              SMTP連携を解除
            </Button>
          )}
          <Button type="submit" disabled={loading} loading={isSubmitting}>
            接続テストおよび登録
          </Button>
        </DialogFooter>
      </form>
    </Dialog>
  );
};
