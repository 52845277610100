import React from 'react';
import { Form, Input, Button } from 'antd';
import styled from 'styled-components';
import { WrappedFormUtils } from 'antd/lib/form/Form';

type Props = {
  form: WrappedFormUtils;
  submit: (email: string) => Promise<any>;
};

type State = {
  submitting: boolean;
};

class _MyEmailUpdateForm extends React.Component<Props, State> {
  state = {
    submitting: false,
  };

  handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        this.setState({ submitting: true });
        await this.props.submit(values.email);
        this.setState({ submitting: false });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { submitting } = this.state;
    return (
      <Form onSubmit={this.handleSubmit} layout="vertical">
        <Form.Item label="変更後のメールアドレス">
          {getFieldDecorator('email', {
            rules: [
              {
                type: 'email',
                message: 'メールアドレスの形式が不正です',
              },
              {
                required: true,
                message: 'メールアドレスを入力してください',
              },
            ],
          })(<Input />)}
        </Form.Item>
        <Form.Item>
          <ButtonWrapper>
            <Button
              loading={submitting}
              disabled={submitting}
              type="primary"
              htmlType="submit"
            >
              変更する
            </Button>
          </ButtonWrapper>
        </Form.Item>
      </Form>
    );
  }
}

export const MyEmailUpdateForm = Form.create<Props>({
  name: 'myEmailUpdateForm',
})(_MyEmailUpdateForm);

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;
