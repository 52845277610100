import { BaseStore } from './base';
import {
  addDoc,
  deleteDoc,
  DocumentReference,
  onSnapshot,
  serverTimestamp,
  updateDoc,
} from 'firebase/firestore';
import { Store } from './index';
import { makeObservable, observable } from 'mobx';
import { Group, GroupData } from './entity';

export class GroupStore extends BaseStore<GroupData> {
  PATH = 'groups';

  groups: Group[] = [];
  _unsubscribeGroups?: () => void;

  constructor(rootStore: Store) {
    super(rootStore);

    makeObservable(this, {
      groups: observable,
    });
  }

  syncGroups = (): void => {
    if (this._unsubscribeGroups) {
      this._unsubscribeGroups();
    }

    this._unsubscribeGroups = onSnapshot(this.collection(), async (snap) => {
      this.groups = snap.docs.map((doc) => new Group(doc));
    });
  };

  getGroupByName = (name: string): Group | undefined => {
    return this.groups.find((group) => group.name === name);
  };

  addGroup = async (
    name: string,
    members: string[]
  ): Promise<DocumentReference<GroupData>> => {
    return await addDoc<GroupData>(this.collection(), {
      name,
      members,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    });
  };

  updateGroup = async (id: string, data: Partial<GroupData>): Promise<void> => {
    await updateDoc<GroupData>(this.doc(id), {
      ...data,
      updatedAt: serverTimestamp(),
    });
  };

  deleteGroup = async (id: string): Promise<void> => {
    await deleteDoc(this.doc(id));
  };
}
