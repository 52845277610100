import firebase, { FieldPath, FieldValue } from '../firebase';
import { fetchQueryInArray, WriteBatch } from '../utils/firestore';
import { ZipWriter } from '../utils/ZipWriter';

export class MessageStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  /**
   * @param {string} [path]
   * @returns {CollectionReference}
   */
  collection(path) {
    return this.rootStore.companyCollection(
      path ? `messages/${path}` : 'messages'
    );
  }

  /**
   * @param {{teamId, messageId}[]} messages
   * @param {'未対応'|'対応済み' | string} status
   */
  async updateStatuses(messages, status) {
    const { me } = this.rootStore;
    const batch = new WriteBatch();

    messages.forEach(({ teamId, messageId }) => {
      const messageRef = this.collection().doc(messageId);
      batch.update(messageRef, {
        status,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      });

      const statusToProcessed = status === '対応済み';
      const eventRef = this.rootStore.companyCollection('events').doc();
      batch.set(eventRef, {
        user: me.id,
        name: me.name,
        teamId,
        messageId,
        type: statusToProcessed
          ? 'status:update:processed'
          : 'status:update:backlog',
        text: `${me.name}が「${status}」に変更しました。`,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      });
    });

    await batch.commit();
  }

  /**
   * @param {string[]} messageIds
   * @param {Tag} tag
   * @param {boolean} isAdding
   */
  async _updateTags(messageIds, tag, isAdding) {
    const batch = new WriteBatch();
    const fieldOp = isAdding ? FieldValue.arrayUnion : FieldValue.arrayRemove;

    messageIds.forEach((messageId) => {
      const messageRef = this.collection().doc(messageId);
      batch.update(messageRef, {
        tags: fieldOp(tag.id),
        updatedAt: FieldValue.serverTimestamp(),
      });
    });

    await batch.commit();
  }

  /**
   * @param {string[]} messageIds
   * @param {Tag} tag
   */
  async addTags(messageIds, tag) {
    return this._updateTags(messageIds, tag, true);
  }

  /**
   * @param {string[]} messageIds
   * @param {Tag} tag
   */
  async removeTags(messageIds, tag) {
    return this._updateTags(messageIds, tag, false);
  }

  /**
   * @param {{teamId, messageId}[]} messages
   * @param {User} assignee
   */
  async updateAssignees(messages, assignee) {
    const { me } = this.rootStore;
    const value = assignee?.id || null;
    const assigneeName = assignee?.name || '担当者未設定';
    const batch = new WriteBatch();

    messages.forEach(({ teamId, messageId }) => {
      const messageRef = this.collection().doc(messageId);
      batch.update(messageRef, {
        assignee: value,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      });

      const eventRef = this.rootStore.companyCollection('events').doc();
      batch.set(eventRef, {
        user: me.id,
        name: me.name,
        assignee: value,
        assigneeName,
        teamId,
        messageId,
        type: 'assignee:update',
        text: `${me.name}が「${assigneeName}」に担当者を設定しました。`,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      });
    });

    await batch.commit();
  }

  /**
   * @param {string[]} messageIds
   */
  async markAsRead(messageIds) {
    const batch = new WriteBatch();
    messageIds.forEach((messageId) => {
      const messageRef = this.collection().doc(messageId);
      batch.update(messageRef, {
        [`readers.${this.rootStore.me.id}`]:
          firebase.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      });
    });
    await batch.commit();
  }

  /**
   * @param {string[]} messageIds
   * @param {boolean} deleted
   */
  async markAsDeleted(messageIds, deleted) {
    const batch = new WriteBatch();

    messageIds.forEach((messageId) => {
      const messageRef = this.collection().doc(messageId);
      batch.update(messageRef, {
        deleted,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      });
    });

    await batch.commit();
  }

  /**
   * @param {string[]} messageIds
   */
  async delete(messageIds) {
    const batch = new WriteBatch();

    messageIds.forEach((messageId) => {
      const messageRef = this.collection().doc(messageId);
      // 物理的に削除する
      batch.delete(messageRef);
    });

    await batch.commit();
  }

  /**
   * @param {string[]} teamIds
   * @param {string[]} messageIds
   */
  async downloadAsZip(teamIds, messageIds) {
    const [messageDocs, ...sentRest] = await Promise.all([
      fetchQueryInArray(this.collection(), FieldPath.documentId(), messageIds),
      ...teamIds.map((teamId) =>
        fetchQueryInArray(
          this.rootStore
            .companyCollection('sent')
            .where('teamId', '==', teamId),
          'inReplyToMessageId',
          messageIds
        )
      ),
    ]);
    const sentDocs = sentRest.flat();

    const zipWriter = new ZipWriter();

    const storage = firebase.storage();
    await Promise.all(
      [...messageDocs, ...sentDocs].map(async (doc) => {
        const { emlStoragePath, subject } = doc.data();
        if (!emlStoragePath) {
          return;
        }
        const url = await storage.ref(emlStoragePath).getDownloadURL();
        zipWriter.addEntry((subject || 'no_subject').slice(0, 70), url);
      })
    );

    return zipWriter.getBlob('.eml');
  }
}
