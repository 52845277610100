import { observer } from 'mobx-react';
import React, { ComponentProps, useEffect, useMemo, useState } from 'react';
import { Route, Switch, useHistory, useParams } from 'react-router-dom';
import { useStore } from '../../../hooks/useStore';
import { atom, useAtomValue } from 'jotai';
import { loadable } from 'jotai/utils';
import { ContactsDetail } from './ContactsDetail';
import { meAtom } from '../../../atoms/auth';
import { ContactEditDrawerWithLogic } from '../Team/ContactEditDrawer/ContactEditDrawerWithLogic';
import {
  ContactData,
  extractEmail,
  normalizeAddress,
  unsubscribeConverter,
  UnsubscribeData,
} from 'lib';
import {
  addDoc,
  arrayRemove,
  deleteField,
  DocumentReference,
  getDocs,
  query,
  runTransaction,
  serverTimestamp,
  updateDoc,
  where,
} from 'firebase/firestore';
import { companyCollection, companyDoc } from '../../../firestore';
import { Store } from 'store';
import { db9 } from '../../../firebase';
import { range, uniq } from 'lodash';
import { ContactObject } from '../../../store/contact';
import { WriteBatch } from '../../../utils/firestore';
import { useConfirmDialog } from '../../../hooks/confirmDialog';
import { message } from 'antd';
import { eventNames, logEvent } from '../../../analytics';
import { wait } from '../../../utils/wait';

type Props = {
  keyword: string;
};

type ContactStatus = ComponentProps<
  typeof ContactsDetail
>['contacts'][number]['status'];

export const ContactsDetailWithLogic = observer(({ keyword }: Props) => {
  const { teamId } = useParams<{ teamId: string }>();
  const history = useHistory();
  const store = useStore();
  const contactStore = store.contactStore;
  const { searchedContacts, page, hasMore, searching } = contactStore;
  const showDialog = useConfirmDialog();
  const me = useAtomValue(meAtom);
  const [filterTags, setFilterTags] = useState<string[]>([]);
  const [sort, setSort] = useState<
    { column: string; dir: 'asc' | 'desc' } | undefined
  >(undefined);

  const accountsAtom = useMemo(
    () => loadable(atom(async () => store.getAccountByTeamId(teamId))),
    [store, teamId]
  );
  const unsubscribeEntriesAtom = useMemo(
    () => createUnsubscribeDataAtom(store, teamId),
    [store, teamId]
  );

  const accounts = useAtomValue(accountsAtom);
  const unsubscribeEntries = useAtomValue(unsubscribeEntriesAtom);
  const { isReadOnly } = useAtomValue(meAtom);

  const contactTags = store.getContactTags(teamId);
  const tags = contactTags.map((tag) => ({
    id: tag.id,
    name: tag.name,
    color: tag.color,
  }));

  useEffect(() => {
    const sortOrder = sort ? `${sort.column}:${sort.dir}` : '';
    contactStore.search(1, teamId, keyword, sortOrder, filterTags).then();
  }, [teamId, keyword, sort, filterTags]);

  const onCreateMessage: ComponentProps<
    typeof ContactsDetail
  >['onCreateMessage'] = async (ids, target) => {
    const teamFirstInbox = store.getTeamFirstInbox(teamId);
    const defaultInbox = store.defaultInbox;
    const inbox = defaultInbox || teamFirstInbox;
    if (!inbox) {
      message.error(
        'チームにメールアドレスが登録されていないため、メールを作成することができません'
      );
      return;
    }

    const removeAutoRecipients = (values: string[]) =>
      values.filter(
        (v) =>
          !inbox.autoRecipients.some(
            (r) =>
              normalizeAddress(extractEmail(r)) ===
              normalizeAddress(extractEmail(v))
          )
      );

    const to = removeAutoRecipients(
      target === 'to'
        ? searchedContacts
            .filter((c) => c.id && ids.includes(c.id))
            .map((c) => (c.name ? `${c.name} <${c.email}>` : c.email))
        : []
    );
    const cc = removeAutoRecipients(
      target === 'cc'
        ? searchedContacts
            .filter((c) => c.id && ids.includes(c.id))
            .map((c) => (c.name ? `${c.name} <${c.email}>` : c.email))
        : []
    );
    const bcc = removeAutoRecipients(
      target === 'bcc'
        ? searchedContacts
            .filter((c) => c.id && ids.includes(c.id))
            .map((c) => (c.name ? `${c.name} <${c.email}>` : c.email))
        : []
    );

    const draft = await addDoc(companyCollection('drafts'), {
      inboxId: inbox.id,
      teamId: inbox.teamId,
      to: to,
      cc: cc ? [...cc, ...inbox.autoCcs] : inbox.autoCcs,
      bcc: bcc ? [...bcc, ...inbox.autoBccs] : inbox.autoBccs,
      subject: '',
      body: '',
      signature: inbox.defaultSignatureId
        ? store.getSignature(inbox.defaultSignatureId)?.signature || null
        : null,
      attachments: [],
      plainTextMode: me.plainTextMode,
      drafter: me.id,
      isReply: false,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    });
    logEvent(eventNames.add_draft_from_contacts);

    await wait(100); // 少し待つ

    history.push(`/me/drafts/${draft.id}`);
  };

  const onDelete = async (ids: string[]) => {
    const batch = new WriteBatch(9);
    ids.forEach((id) => batch.delete(companyDoc('contacts', id)));

    const selectedContacts = searchedContacts.filter(
      (contact) => contact.id && ids.includes(contact.id)
    );

    await Promise.all([
      batch.commit(),
      contactStore.unindexContacts(teamId, ids),
      ...selectedContacts.map((c) =>
        store.removeContactTagsIfNotAttached(c.tags, c.teamId)
      ),
    ]);
  };

  const onAddTag: ComponentProps<typeof ContactsDetail>['onAddTag'] = async (
    ids,
    tag
  ) => {
    const addedTag = (await store.getOrCreateContactTags([tag], teamId))[0];

    await runTransaction(db9, async (tx) => {
      searchedContacts
        .filter((contact) => contact.id && ids.includes(contact.id))
        .forEach((contact) => {
          const ref = companyDoc(
            'contacts',
            contact.id!
          ) as DocumentReference<ContactData>;

          tx.update(ref, {
            tags: uniq([...contact.tags, addedTag.name]).sort((a, b) =>
              a.localeCompare(b, 'ja')
            ),
            updatedAt: serverTimestamp(),
          });
        });
    });
  };

  const onEditTag: ComponentProps<typeof ContactsDetail>['onEditTag'] = async (
    tagId,
    tag
  ) => {
    await updateDoc(companyDoc('contactTags', tagId), {
      color: tag.color,
      updatedAt: serverTimestamp(),
    });
  };

  const onRemoveTag: ComponentProps<
    typeof ContactsDetail
  >['onRemoveTag'] = async (ids, tagId) => {
    const tag = contactTags.find((tag) => tag.id === tagId);
    if (!tag) {
      return;
    }

    await runTransaction(db9, async (tx) => {
      searchedContacts
        .filter((contact) => contact.id && ids.includes(contact.id))
        .forEach((contact) => {
          const ref = companyDoc(
            'contacts',
            contact.id!
          ) as DocumentReference<ContactData>;

          tx.update(ref, {
            tags: arrayRemove(tag.name),
            updatedAt: serverTimestamp(),
          });
        });
    });

    await store.removeContactTagIfNotAttached(tag.name, teamId);
  };

  const onSetAccount: ComponentProps<
    typeof ContactsDetail
  >['onSetAccount'] = async (ids, accountId) => {
    await runTransaction(db9, async (tx) => {
      ids.forEach((id) => {
        const ref = companyDoc(
          'contacts',
          id
        ) as DocumentReference<ContactData>;
        tx.update(ref, {
          accountId,
          updatedAt: serverTimestamp(),
        });
      });
    });
  };

  const onUnsetAccount: ComponentProps<
    typeof ContactsDetail
  >['onUnsetAccount'] = async (ids) => {
    showDialog({
      title: `${ids.length}件のコンタクトを取引先から削除しますか？`,
      okText: '削除',
      okType: 'danger',
      onOk: () =>
        runTransaction(db9, async (tx) => {
          ids.forEach((id) => {
            const ref = companyDoc(
              'contacts',
              id
            ) as DocumentReference<ContactData>;
            tx.update(ref, {
              accountId: deleteField(),
              updatedAt: serverTimestamp(),
            });
          });
        }),
    });
  };

  if (
    accounts.state !== 'hasData' ||
    store.contactTagsLoading ||
    (searching && !searchedContacts.length)
  ) {
    return (
      <div className="flex flex-col gap-3">
        <div className="h-5 w-32 animate-pulse rounded bg-sumi-100" />
        {range(7).map((i) => (
          <div
            key={i}
            className="h-5 w-full animate-pulse rounded bg-sumi-100"
          />
        ))}
      </div>
    );
  }

  return (
    <>
      <ContactsDetail
        contacts={searchedContacts.map((contact) => {
          const status =
            unsubscribeEntries.state === 'hasData'
              ? getContactStatus(contact, unsubscribeEntries.data)
              : 'loading';
          return {
            id: contact.id ?? '',
            name: contact.name,
            accountId: contact.accountId,
            email: contact.email,
            tags: contact.tags
              .map((tagName) => tags.find((t) => t.name === tagName)?.id)
              .filter((id) => id) as string[],
            companyName: contact.companyName,
            phoneNumber: contact.phoneNumber,
            status,
            memo: contact.memo,
          };
        })}
        tags={tags}
        accounts={accounts.data!.map((account) => ({
          id: account.id,
          name: account.name,
        }))}
        onOpenDrawer={(id) =>
          history.push(`/contacts/${teamId}/contacts/contacts/${id}`)
        }
        onCreateMessage={onCreateMessage}
        onDelete={onDelete}
        onAddTag={onAddTag}
        onEditTag={onEditTag}
        onRemoveTag={onRemoveTag}
        pageSize={contactStore.pageSize}
        onChangePageSize={(size) => contactStore.changePageSize(size)}
        page={page}
        onChangePage={(page) => contactStore.search(page)}
        hasMore={hasMore}
        sort={sort}
        onChangeSort={setSort}
        onSetAccount={onSetAccount}
        onUnsetAccount={onUnsetAccount}
        onChangeTagFilter={setFilterTags}
        searching={searching}
      />
      <Switch>
        {!isReadOnly && (
          <Route
            exact
            path="/contacts/:teamId/contacts/contacts/new"
            render={() => (
              <ContactEditDrawerWithLogic
                teamId={teamId}
                contactId={undefined}
              />
            )}
          />
        )}

        <Route
          exact
          path="/contacts/:teamId/contacts/contacts/:contactId"
          render={({ match }) => (
            <ContactEditDrawerWithLogic
              teamId={teamId}
              contactId={match.params.contactId}
            />
          )}
        />
      </Switch>
    </>
  );
});

const createUnsubscribeDataAtom = (store: Store, teamId: string) =>
  loadable(
    atom(async (): Promise<UnsubscribeData[]> => {
      const inboxes = store.getTeamInboxes(teamId);
      if (!inboxes) {
        return [];
      }

      const teamEmails = inboxes.map((i) => normalizeAddress(i.email));
      if (!teamEmails.length) {
        return [];
      }

      const res = await getDocs(
        query(
          companyCollection('unsubscribes', unsubscribeConverter),
          where('email', 'in', teamEmails)
        )
      );

      const array = res.docs.flatMap((d) => d.data().data);
      const defaultData: UnsubscribeData[] = teamEmails
        .filter((e) => array.every((a) => a.email !== e))
        .map((email) => ({
          email,
          targets: [],
        }));

      return [...defaultData, ...array];
    })
  );

const getContactStatus = (
  contact: ContactObject,
  unsubscribes: UnsubscribeData[]
): ContactStatus => {
  const normalizedEmail = normalizeAddress(contact.email);
  const foundEntries = unsubscribes.filter((d) =>
    d.targets.includes(normalizedEmail)
  );
  if (foundEntries.length === 0) {
    return 'available';
  } else if (foundEntries.length === unsubscribes.length) {
    return 'unavailable';
  } else {
    return 'partial';
  }
};
