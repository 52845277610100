import React, { useRef, useEffect, useCallback } from 'react';

export const MultipleCopy = ({ children, ...props }) => {
  const copyRef = useRef(null);

  const copyHandler = useCallback(async (event) => {
    try {
      const section = window.getSelection();
      const range = section.getRangeAt(0);

      const targetAddresses = [];

      // コピー範囲が複数の時
      range.commonAncestorContainer.childNodes.forEach((node) => {
        if (section.containsNode(node, true)) {
          targetAddresses.push(node.textContent);
        }
      });

      // コピー範囲が一つのみの時
      if (!range.commonAncestorContainer.hasChildNodes()) {
        targetAddresses.push(range.commonAncestorContainer.textContent);
      }

      const copyText = targetAddresses.join(',');
      await navigator.clipboard.writeText(copyText);
    } catch (e) {}
  }, []);

  useEffect(() => {
    const target = copyRef.current;
    if (!target) return;
    target.addEventListener('copy', copyHandler);

    return () => {
      target.removeEventListener('copy', copyHandler);
    };
  }, [copyHandler]);

  return (
    <div ref={copyRef} {...props}>
      {children}
    </div>
  );
};
