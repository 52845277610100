import { DocumentSnapshot, Timestamp } from 'firebase/firestore';
import { estimateServerTimestampsOption } from 'lib';

export interface GroupData {
  name: string;
  members: string[];
  createdAt: Timestamp;
  updatedAt: Timestamp;
}

export class Group {
  private readonly _id: string;
  private _data: GroupData;

  constructor(doc: DocumentSnapshot<GroupData>) {
    this._id = doc.id;
    const data = doc.data(estimateServerTimestampsOption);
    if (!data) {
      throw new Error(`no data for #${doc.id}`);
    }
    this._data = data;
  }

  get id(): string {
    return this._id;
  }

  get name(): string {
    return this._data.name;
  }

  get members(): string[] {
    return this._data.members;
  }

  isMember(userId: string): boolean {
    return this.members.includes(userId);
  }
}
