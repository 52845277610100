import React from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import { DefaultInput } from '../../Common/Input';
import { Button, message, Modal, notification, Upload, Alert } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { H1 } from '../../Common/H1';
import { H2 } from '../../Common/H2';
import { Comment } from '../../Common/Comment';
import Avatar from '../../Common/Avatar';
import { db } from '../../../firebase';
import * as color from '../../../color';
import firebase from 'firebase.js';
import * as Sentry from '@sentry/react';
import { List } from '../common';
import { v4 as uuidv4 } from 'uuid';
import ImgCrop from 'antd-img-crop';
import ImageTools from '../../../shared/imageTools';
import { eventNames, logEvent } from '../../../analytics';
import { MyEmailUpdateForm } from './MyEmailUpdateForm';
import { Store } from '../../../store';
import { RcFile } from 'antd/lib/upload';
import { MFASetting } from './MFASetting';

const { confirm } = Modal;

const banNames = ['all'];

type Props = {
  store: Store;
};

type State = {
  name: string;
  isUpdatingName: boolean;
  resetEmailModalVisible: boolean;
  updatingEmail: boolean;
  sendingResetEmail: boolean;
};

class _MyProfile extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      name: props.store.me.name,
      isUpdatingName: false,
      resetEmailModalVisible: false,
      updatingEmail: false,
      sendingResetEmail: false,
    };
  }

  uploadAvatar = async (file: RcFile) => {
    // リサイズしてアップロード
    ImageTools.resize(
      // FIXME: any
      file as any,
      {
        width: 200,
        height: 200,
      },
      (blob, didItResize) => {
        const storageRef = firebase
          .storage()
          .ref(
            `/companies/${this.props.store.signInCompany}/users/${this.props.store.me.id}/avatar/${file.name}`
          );
        const uploadTask = storageRef.put(blob);
        const messageKey = uuidv4();
        message.loading({
          content: `アイコンをアップロード中です（0%完了）`,
          key: messageKey,
        });

        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            message.loading({
              content: `アイコンをアップロード中です（${Math.floor(
                progress
              )}%完了）`,
              key: messageKey,
            });
          },
          (error) => {
            console.error('Profile.uploadAvatar:', error);
            message.error({
              content: `アイコンのアップロードに失敗しました`,
              key: messageKey,
            });
          },
          async () => {
            // 成功
            const avatarStoragePath = uploadTask.snapshot.ref.fullPath;
            const storageRef = firebase.storage().ref(avatarStoragePath);
            const avatarURL = await storageRef.getDownloadURL();
            await db
              .collection(`companies/${this.props.store.signInCompany}/users`)
              .doc(this.props.store.currentUser?.uid)
              .update({
                avatarStoragePath,
                avatarURL,
                updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
              });
            logEvent(eventNames.update_icon);
            message.success({
              content: `アイコンのアップロードが完了しました。`,
              key: messageKey,
              duration: 2,
            });
          }
        );
      }
    );
  };

  removeAvatar = () => {
    confirm({
      title: 'アイコンを削除しますか？',
      onOk: async () => {
        await db
          .collection(`companies/${this.props.store.signInCompany}/users`)
          .doc(this.props.store.currentUser?.uid)
          .update({
            avatarStoragePath: null,
            avatarURL: null,
            updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          });
        logEvent(eventNames.remove_icon);
      },
      onCancel: () => {
        //
      },
      okText: '削除',
      cancelText: 'キャンセル',
      okType: 'danger',
      maskClosable: true,
    });
  };

  onChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ name: e.target.value });
  };

  updateName = async (e: React.FormEvent) => {
    e.preventDefault();

    const { name } = this.state;

    if (
      name.length === 0 ||
      this.props.store.me.name === name ||
      banNames.includes(name)
    )
      return;

    this.setState({ isUpdatingName: true });

    // 更新
    await db
      .collection(`companies/${this.props.store.signInCompany}/users`)
      .doc(this.props.store.currentUser?.uid)
      .update({
        name,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      });
    logEvent(eventNames.update_username);

    // 更新を取得
    this.setState({ isUpdatingName: false });

    // お知らせ
    notification.open({
      message: '名前を変更しました',
      icon: <CheckOutlined style={{ color: color.common.success }} />,
    });
  };

  openResetEmailModal = () => {
    this.setState({ resetEmailModalVisible: true });
  };

  closeResetEmailModal = () => {
    this.setState({ resetEmailModalVisible: false });
  };

  resetEmail = async (email: string) => {
    this.setState({ updatingEmail: true });
    try {
      // メールアドレスの確認メール送信
      await firebase.auth().currentUser?.verifyBeforeUpdateEmail(email);
      message.success('確認メールを送信しました。');
      logEvent(eventNames.update_email);
    } catch (e) {
      console.error('Profile.resetEmail:', e);
      // FIXME: any
      const { code } = e as any;
      let errorMessage;
      switch (code) {
        case 'auth/invalid-email':
          errorMessage = '※入力したメールアドレスが不正です。';
          break;
        case 'auth/email-already-in-use':
          errorMessage =
            '※すでに登録されているメールアドレスへは変更できません。';
          break;
        case 'auth/requires-recent-login':
          errorMessage =
            '※一定期間ログインがなかったため、セキュリティ上の理由により実行できません。再度ログインしてからお試し下さい。';
          break;
        default:
          // FIXME: any
          Sentry.captureMessage(e as any);
          errorMessage = '予期せぬエラーが発生しました';
      }
      Modal.error({
        title: errorMessage,
      });
    }
    this.setState({ updatingEmail: false });
    this.setState({ resetEmailModalVisible: false });
  };

  resetPassword = () => {
    confirm({
      title: 'パスワードを再設定しますか？',
      content: `パスワード再設定用リンクが ${this.props.store.me.email} に送信されます`,
      onOk: async () => {
        this.setState({ sendingResetEmail: true });
        const auth = firebase.auth();
        const { email } = this.props.store.me;
        try {
          await auth.sendPasswordResetEmail(email);
          logEvent(eventNames.reset_password);
          Modal.success({
            title: 'パスワードの再設定リンクが送信されました。',
            content: `${this.props.store.me.email} をご確認ください。`,
          });
        } catch (e) {
          console.error('Profile.resetPassword:', e);
          // "auth/user-not-found"
          // FIXME: any
          const { code } = e as any;
          let errorMessage;
          switch (code) {
            case 'auth/too-many-requests':
              errorMessage =
                '※リクエスト回数が上限を超えました。時間をおいてから再度お試し下さい。';
              break;
            default:
              // FIXME: any
              Sentry.captureMessage(e as any);
              errorMessage = '予期せぬエラーが発生しました';
          }
          Modal.error({
            title: errorMessage,
          });
        }
        this.setState({ sendingResetEmail: false });
      },
      onCancel: () => {
        //
      },
      okText: '再設定',
      cancelText: 'キャンセル',
      maskClosable: true,
    });
  };

  render() {
    const { name, isUpdatingName, updatingEmail, sendingResetEmail } =
      this.state;
    return (
      <List>
        <H1>プロフィール設定</H1>

        <Content>
          <H2>アイコン</H2>
          <AvatarSetting>
            <Avatar user={this.props.store.me} size={75} />
            <ImgCrop modalTitle="アイコンの範囲を指定する" grid>
              {/* FIMME: any */}
              <Upload action={this.uploadAvatar as any} showUploadList={false}>
                <Button type="primary">変更</Button>
              </Upload>
            </ImgCrop>
            <Button onClick={this.removeAvatar}>削除</Button>
          </AvatarSetting>
        </Content>

        <Content>
          <H2>名前</H2>
          <form onSubmit={this.updateName}>
            <Input
              value={name}
              onChange={this.onChangeName}
              onBlur={this.updateName}
              placeholder="名前"
              disabled={isUpdatingName}
              required
            />
          </form>
          <Comment>例：奥村、イチロー、ichiro…</Comment>
          {banNames.includes(name) && (
            <AlertWrapper>
              <Alert
                message="使用できない名前です。別の名前をご入力下さい"
                type="error"
              />
            </AlertWrapper>
          )}
        </Content>

        <Content>
          <H2>
            メールアドレス
            <ResetButton
              onClick={this.openResetEmailModal}
              disabled={updatingEmail}
              loading={updatingEmail}
              type="link"
            >
              再設定
            </ResetButton>
            <Modal
              title="メールアドレスの変更"
              visible={this.state.resetEmailModalVisible}
              footer={
                <div style={{ display: 'flex' }}>
                  <Button
                    onClick={this.closeResetEmailModal}
                    disabled={this.state.updatingEmail}
                  >
                    キャンセル
                  </Button>
                </div>
              }
              onCancel={this.closeResetEmailModal}
              maskClosable={!updatingEmail}
            >
              <MyEmailUpdateForm submit={this.resetEmail} />
            </Modal>
          </H2>
          <form>
            <Input value={this.props.store.me.email} disabled />
          </form>
        </Content>

        <Content>
          <H2>
            パスワード
            <ResetButton
              onClick={this.resetPassword}
              disabled={sendingResetEmail}
              loading={sendingResetEmail}
              type="link"
            >
              再設定
            </ResetButton>
          </H2>
          <form>
            <Input value="************" disabled />
          </form>
        </Content>

        <MFASetting />
      </List>
    );
  }
}

const ResetButton = styled(Button)`
  padding-left: 8px;
`;

const Input = styled(DefaultInput)`
  width: 400px;
  height: 40px;
  margin-bottom: 10px;
`;

const Content = styled.div`
  margin-bottom: 30px;
`;

const AlertWrapper = styled.div`
  margin-top: 10px;
`;

const AvatarSetting = styled.div`
  display: flex;
  align-items: center;

  button {
    margin-left: 12px;
  }
`;

export const MyProfile = compose<Props, Omit<Props, 'store'>>(
  withRouter,
  inject('store'),
  observer
)(_MyProfile);
