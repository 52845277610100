const v1Scopes = [
  'channels:read',
  'chat:write',
  'chat:write.public',
  'users:read',
];

const v2Scopes = [
  'channels:read',
  'chat:write',
  'chat:write.public',
  'groups:read',
  'im:write',
  'users:read',
  'users:read.email',
];

export const slackAuthorizeUrl = (isV2Plan: boolean): string =>
  `https://slack.com/oauth/v2/authorize?client_id=${
    process.env.REACT_APP_SLACK_CLIENT_ID
  }&scope=${encodeURIComponent(
    (isV2Plan ? v2Scopes : v1Scopes).join(',')
  )}&redirect_uri=${encodeURIComponent(
    window.location.origin + '/settings/integrations/slack'
  )}`;

export const hasMissingV2Scopes = (currentScope?: string): boolean =>
  // FIXME
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  v2Scopes.some((s) => !currentScope!.includes(s));
