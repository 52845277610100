import {
  SidebarCommonButton,
  SidebarCommonButtonProps,
} from '../SidebarCommonButton/SidebarCommonButton';
import { Link, useRouteMatch } from 'react-router-dom';

type Props = SidebarCommonButtonProps & {
  to: string;
};

export const SidebarLinkButton = ({ to, ...props }: Props): JSX.Element => {
  const isActive =
    useRouteMatch({
      path: to,
    }) != null;
  return (
    <SidebarCommonButton
      as={props.disabled ? 'a' : Link}
      active={isActive || props.active}
      {...(!props.disabled ? { to } : {})}
      {...props}
    />
  );
};
