import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import Message from './Message';
import Sent from './Sent';

class Index extends Component {
  render() {
    return (
      <Wrapper>
        <Switch>
          {/* メッセージ */}
          <Route path="/print/messages/:messageId" component={Message} />
          {/* 送信済み */}
          <Route path="/print/sent/:sentId" component={Sent} />
        </Switch>
      </Wrapper>
    );
  }
}

export default compose(withRouter, inject('store'), observer)(Index);

const Wrapper = styled.div`
  height: 100vh;
  display: flex;
`;
