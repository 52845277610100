import { ComponentPropsWithoutRef, useState } from 'react';
import { DrafterSelectField } from '../DrafterSelectField/DrafterSelectField';

type DraftSelectFieldProps = ComponentPropsWithoutRef<
  typeof DrafterSelectField
>;

type Props = {
  users: DraftSelectFieldProps['users'];
  currentDrafterId: DraftSelectFieldProps['currentUserId'];
  onChangeDrafterId: (userId: string) => Promise<void>;
  readonly: boolean;
};

export const AutoReplySettings = ({
  users,
  currentDrafterId,
  onChangeDrafterId,
  readonly,
}: Props) => {
  const [idChanging, setIdChanging] = useState(false);
  const handleChangeUserId = async (userId: string) => {
    setIdChanging(true);
    try {
      await onChangeDrafterId(userId);
    } catch (e) {
      console.error(e);
    } finally {
      setIdChanging(false);
    }
  };
  return (
    <div className="flex flex-col gap-4 text-sm">
      <DrafterSelectField
        users={users}
        currentUserId={currentDrafterId}
        onChangeUserId={handleChangeUserId}
        disabled={readonly || idChanging}
      />
    </div>
  );
};
