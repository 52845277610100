import { Avatar, Icon, Select, Tag } from 'antd';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';

import level from '../../../font/level';
import { ContactObject } from 'store/contact';
import styled from 'styled-components';
import { LineInput, LineInputRoot } from './LineInput';

const { Option } = Select;

interface Props {
  view: 'contact' | 'salesforce';
  contact: ContactObject;
}

export const ContactInfo = observer(({ view, contact }: Props) => {
  const [open, setOpen] = useState(true);
  const store = useStore();
  const isReadOnly = store.me.isReadOnly;
  const { contactStore } = store;
  const contactData = contactStore.selectedContact;
  const [tags, setTags] = useState(contactData?.tags || []);
  const [editingTags, setEditingTags] = useState(false);
  useEffect(() => {
    setTags(contactData?.tags || []);
  }, [contactData]);

  if (!contactData) {
    return null;
  }
  const updateField = (
    field: keyof ContactObject,
    value: string | string[]
  ) => {
    if (value !== contactData[field]) {
      contactStore.updateSelectedContact({
        ...contactData,
        [field]: value,
      });
    }
  };
  const existingTags = store.getContactTags(contactData.teamId);
  const submitTags = () => {
    updateField('tags', tags);
    setEditingTags(false);
  };
  const onKeyDownTagInput = (e: React.KeyboardEvent) => {
    switch (e.key) {
      case 'Escape':
        setTags(contactData.tags);
        setEditingTags(false);
        return;
      case 'Enter':
        submitTags();
        return;
    }
  };
  return (
    <>
      <Wrapper>
        <Avatar style={{ marginRight: '0.5rem', flexShrink: 0 }} size={48}>
          {contact.name[0]?.toUpperCase() || contact.email[0].toUpperCase()}
        </Avatar>
        <div>
          <Name>
            <LineInput
              value={contactData.name}
              readOnly={isReadOnly || view !== 'contact'}
              onChange={(v) => updateField('name', v)}
            />
          </Name>
          <Email>{contact.email}</Email>
        </div>
      </Wrapper>
      {view === 'contact' && (
        <>
          <Details onClick={() => setOpen(!open)}>
            <span style={{ display: 'flex' }}>
              連絡先情報
              <CaretIcon
                type={open ? 'caret-up' : 'caret-down'}
                className="icon"
              />
            </span>
          </Details>
          {open && (
            <div style={{ padding: '0.25rem 0 0.25rem 1rem' }}>
              <Label>
                <span>タグ</span>
                <LineInputRoot>
                  {editingTags ? (
                    <Select
                      mode="tags"
                      style={{ width: '100%', minWidth: 0 }}
                      onChange={setTags}
                      onInputKeyDown={onKeyDownTagInput}
                      onBlur={submitTags}
                      value={tags}
                      tokenSeparators={[',']}
                    >
                      {existingTags.map((tag) => (
                        <Option key={tag.name}>{tag.name}</Option>
                      ))}
                    </Select>
                  ) : (
                    <div style={{ overflow: 'hidden', flex: 1 }}>
                      {tags.map((tag) => (
                        <Tag
                          style={{
                            maxWidth: '100%',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                          }}
                          key={tag}
                        >
                          {tag}
                        </Tag>
                      ))}
                    </div>
                  )}
                  {!isReadOnly && (
                    <Icon type="edit" onClick={() => setEditingTags(true)} />
                  )}
                </LineInputRoot>
              </Label>
              <Label>
                <span>会社名</span>
                <LineInput
                  value={contactData.companyName}
                  onChange={(v) => updateField('companyName', v)}
                  readOnly={isReadOnly}
                />
              </Label>
              <Label>
                <span>電話番号</span>
                <LineInput
                  value={contactData.phoneNumber}
                  onChange={(v) => updateField('phoneNumber', v)}
                  readOnly={isReadOnly}
                />
              </Label>
              <Label>
                <span>メモ</span>
                <LineInput
                  value={contactData.memo}
                  multiLine
                  onChange={(v) => updateField('memo', v)}
                  readOnly={isReadOnly}
                />
              </Label>
            </div>
          )}
        </>
      )}
    </>
  );
});

export const CaretIcon = styled(Icon)`
  display: flex;
  align-items: center;
  margin-left: 4px;
  margin-right: 10px;
  font-size: 12px;
  color: inherit;
`;

export const Details = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 4px 8px 16px;
  color: rgba(0, 0, 0, 0.7);
  font-weight: bold;
  cursor: pointer;
  color: #9d9da0;

  :hover {
    color: rgba(0, 0, 0);
  }
`;

export const Label = styled.span`
  display: flex;
  align-items: center;

  > span {
    padding: 0.25rem;
    width: 3.5rem;
    color: #9d9da0;
    flex-shrink: 0;
  }
`;

const Wrapper = styled.div`
  padding: 1rem;
  padding-right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: solid 0.5px #f1f1f3;
`;

const Name = styled.div`
  margin-bottom: 0;
  font-size: ${level[4]};

  > div {
    align-items: center;
  }
`;

const Email = styled.p`
  margin-bottom: 0;
  padding-left: 0.25rem;
  font-size: ${level[1]};
`;
