import { twMerge } from 'tailwind-merge';
import { Icon } from '../../../../../components/basics';
import { ContentCopy } from '../../../../../components/icons';
import { Tooltip } from '../../../../../components/basics/Tooltip/Tooltip';
import { useState } from 'react';

type Props = {
  value: string;
  className?: string;
};

export const ForwardEmail = ({ value, className }: Props) => {
  const [copied, setCopied] = useState(false);
  const copy = async () => {
    await navigator.clipboard.writeText(value);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };
  return (
    <div
      className={twMerge(
        'grid grid-cols-[1fr_auto] items-center gap-2 text-base',
        className
      )}
    >
      <div className="select-all break-all" data-testid="forwardingAddress">
        {value}
      </div>
      <Tooltip content="コピーしました" open={copied}>
        <button
          type="button"
          onClick={() => copy()}
          className="cursor-pointer bg-transparent p-0"
        >
          <Icon icon={ContentCopy} size={24} className="block text-sea-500" />
        </button>
      </Tooltip>
    </div>
  );
};
