import * as React from 'react';
import type { SVGProps } from 'react';
const SvgOpenInNew = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="m19.437 6.338 1.973 1.974c.325.324.59.216.59-.245V2.833A.832.832 0 0 0 21.167 2h-5.236c-.46 0-.57.263-.245.59l2.039 2.038-8.65 8.646a1.21 1.21 0 1 0 1.712 1.712l8.65-8.648ZM13.458 3.67v-.002h-9.79C2.746 3.667 2 4.414 2 5.334v14.999C2 21.253 2.747 22 3.667 22h14.999c.92 0 1.667-.747 1.667-1.667v-8.334a.832.832 0 1 0-1.665 0v7.5c0 .458-.374.832-.833.832H4.5a.833.833 0 0 1-.833-.833V6.167c0-.46.374-.833.833-.833h8.958v-.002a.832.832 0 1 0 .067-1.663.371.371 0 0 0-.067 0Z"
    />
  </svg>
);
export default SvgOpenInNew;
