import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import Avatar from '../../../../../../Common/Avatar';
import { highlightMention } from '../../../../../../../util';

class Index extends Component {
  render() {
    const { message, comment } = this.props;
    const latestComment = comment || message.latestComment; // コメントが指定されている場合は使用する
    if (!latestComment) return <></>; // コメントが存在しない場合

    const commenter = this.props.store.getUser(latestComment.commenter);

    return (
      <Wrapper aria-label="最新メールコメント">
        <div>
          <Avatar size="small" user={commenter} />
        </div>
        <Comment>
          {highlightMention(
            latestComment.text,
            this.props.store.users,
            this.props.store.groupStore.groups
          )}
        </Comment>
      </Wrapper>
    );
  }
}

export default compose(withRouter, inject('store'), observer)(Index);

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
`;

const Comment = styled.div`
  margin-left: 8px;
  border-radius: 8px;
  background: #f8f8fb;
  padding: 8px 12px;

  font-size: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
