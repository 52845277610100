import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { DragIndicator } from '../../../../../components/icons';
import { Icon } from '../../../../../components/basics';
import { StatusListItemText } from './StatusListItemText';
import { CustomStatus } from 'lib';

type Props = {
  status: CustomStatus;
  draggable: boolean;
  editable: boolean;
  onOpenDrawer?: (status: CustomStatus) => void;
};

export const StatusListItem = ({
  status,
  draggable,
  editable,
  onOpenDrawer,
}: Props) => {
  const {
    attributes,
    listeners,
    isDragging,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: 'id' in status ? status.id : '' });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? 10 : undefined,
  };

  return (
    <div
      className="grid h-10 grid-cols-[auto_1fr] items-center"
      style={style}
      {...attributes}
      ref={setNodeRef}
    >
      {draggable ? (
        <button
          type="button"
          className="h-full cursor-grab bg-transparent p-0 text-sumi-500"
          {...listeners}
        >
          <Icon icon={DragIndicator} size={20} className="block" />
        </button>
      ) : (
        <span />
      )}
      <StatusListItemText
        text={status.name}
        onClick={() => {
          onOpenDrawer && onOpenDrawer(status);
        }}
        editable={editable}
      />
    </div>
  );
};
