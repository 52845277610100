import { MobileNav } from 'App/Common/MainNav/MobileNav';
import React from 'react';
import { MessageTabs } from '../status/MessageTabs';
import { Route, Switch } from 'react-router-dom';
import { isSP } from 'shared/util';
import { MSG_LIST_PATHS } from 'hooks/useMsgRouteMatch';
import { FilterDisplay } from '../FilterDisplay';
import { BreadcrumbList } from '../BreadcrumbList/BreadcrumbList';

export const SPMessageListNav: React.FC = () => {
  return (
    <>
      <MobileNav />
      <Switch>
        <Route path="/search/:tray/:query" exact={isSP()}>
          <FilterDisplay />
        </Route>
        <Route path={MSG_LIST_PATHS} exact={isSP()}>
          <div className="flex w-full flex-col gap-2">
            <div className="w-full px-3 pt-4">
              <BreadcrumbList />
            </div>
            <MessageTabs scrollElementClassName="pl-3" />
          </div>
        </Route>
        <Route path={'/me/:meTab'}>
          <div className="w-full px-3 pt-4">
            <BreadcrumbList />
          </div>
        </Route>
      </Switch>
    </>
  );
};
