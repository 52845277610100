import styled from 'styled-components';
import { Product } from '../../Product';
import { DefaultButton } from 'App/Common/Button';
import { Tooltip } from 'antd';
import { PlanFeatureItemProps, PlanFeatures } from './PlanFeatures';
import { PlanLimitItems } from './PlanLimitItem';

type Props = {
  product: Product;
  disabled?: boolean;
  buttonLabel?: string;
  tooltip?: string;
  onSelect: () => any;
};

export const PlanCard: React.FC<Props> = (props) => {
  const { product } = props;

  const handleClickSelectButton = () => {
    props.onSelect && props.onSelect();
  };

  const buttonLabel = props.buttonLabel
    ? props.buttonLabel
    : 'このプランに変更';

  const lead = getLead(product.plan, product.version);
  const price = getPrice(product.plan, product.version)?.toLocaleString();
  const initialCost = getInitialCost10KYen(product.version);
  const featureItems = getFeaturePropList(product.plan, product.version);

  return (
    <Wrapper>
      <div className="name">{product.name}</div>
      <div className="lead">{lead}</div>
      <div className="price">
        <div className="label">1ユーザー/月</div>
        <div className="value">&yen;{price}</div>
        {initialCost && (
          <div className="initial-cost">初期費用:{initialCost}万円</div>
        )}
      </div>
      <div className="sep"></div>
      <div className="limit">
        <PlanLimitItems product={product} />
      </div>
      <div className="sep"></div>
      <div className="features">
        <PlanFeatures items={featureItems} />
      </div>
      <div className="select">
        <Tooltip title={props.tooltip}>
          {/* ボタンがdisabledでもtooltipを表示させるためにdivでラップ */}
          <div className="button-wrapper">
            <DefaultButton
              disabled={props.disabled}
              className="button"
              type="primary"
              onClick={handleClickSelectButton}
            >
              {buttonLabel}
            </DefaultButton>
          </div>
        </Tooltip>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 32px 24px 96px;
  box-shadow: 0 2px 16px rgb(0 0 0 / 10%);
  border-radius: 10px;
  line-height: 1;
  position: relative;

  & > .sep {
    height: 1px;
    background-color: #eee;
  }

  & > .name {
    text-align: center;
    font-size: 20px;
    font-weight: 600;
  }

  & > .lead {
    margin-top: 16px;
    text-align: center;
    font-size: 16px;
    line-height: 1.5;
  }

  & > .price {
    margin-top: 24px;
    margin-bottom: 24px;
    text-align: center;
    & > .label {
      font-size: 14px;
    }
    & > .value {
      margin-top: 4px;
      font-size: 40px;
      line-height: 46px;
      font-weight: bold;
      color: #3f82f9;
    }
    & > .initial-cost {
      margin-top: 4px;
      color: #3f82f9;
      font-size: 14px;
    }
  }

  & > .limit {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  & > .features {
    margin-top: 24px;
    & > * {
      margin-bottom: 10px;
    }
  }

  & > .select {
    position: absolute;
    bottom: 0;
    left: 24px;
    right: 24px;
    display: flex;
    justify-content: center;
    padding-top: 16px;
    padding-bottom: 24px;
    border-top: 1px solid #eee;

    & > .button-wrapper {
      width: 100%;
      & > .button {
        padding: 10px 16px;
        font-size: 14px;
        width: 100%;
      }
    }
  }
`;

// -- 以下、プランやバージョンごとに変わる要素の取得 --

// リード
const getLead = (
  plan: Product['plan'],
  version: number
): React.ReactElement => {
  switch (plan) {
    case 'light':
      return (
        <>
          メール共有を
          <br />
          試してみたい方向け
        </>
      );
    case 'standard':
      return (
        <>
          基本機能がすべて
          <br />
          利用できるプラン
        </>
      );
    case 'business':
      if (version < 6) {
        return (
          <>
            自動化や分析で
            <br />
            さらなる効率化を実現
          </>
        );
      } else {
        return (
          <>
            自動化やCRM連携で
            <br />
            さらなる効率化を実現
          </>
        );
      }
    case 'enterprise':
      return (
        <>
          セキュリティ対策や
          <br />
          手厚いサポート体制
        </>
      );
  }
};

// 価格
const getPrice = (plan: Product['plan'], version: number): number | null => {
  if (version === 4 || version === 6) {
    switch (plan) {
      case 'light':
        return 1980;
      case 'standard':
        return 2980;
      case 'business':
        return 4980;
      default:
        return null;
    }
  } else if (version === 5) {
    switch (plan) {
      case 'light':
        return 1980;
      case 'standard':
        return 3980;
      case 'business':
        return 6980;
      case 'enterprise':
        return 9980;
      default:
        return null;
    }
  }
  return null;
};

// 初期費用
const getInitialCost10KYen = (version: number): number | null => {
  if (version === 5) {
    return 3;
  }
  return null;
};

// 機能リスト(PlanFeatureItemsのProps)
// TODO: フラグでうまく自動で出し分けられるようにする
const getFeaturePropList = (
  plan: Product['plan'],
  version: number
): PlanFeatureItemProps[] => {
  if (version === 4) {
    // version4 (version X)
    switch (plan) {
      case 'light':
        return [
          { text: '二重対応防止機能' },
          { text: '@メンション・既読未読' },
          { text: '対応履歴の表示' },
        ];
      case 'standard':
        return [
          {
            bold: true,
            text: 'ライトの全機能',
          },
          {
            text: '個人メールの管理',
          },
          {
            text: '送信予約',
          },
          {
            text: '開封履歴',
          },
          {
            text: 'Slack・Chatwork連携',
          },
        ];
      case 'business':
        return [
          { text: 'スタンダードの全機能', bold: true },
          { text: '担当者の自動設定' },
          { text: '自動返信' },
          { text: 'レポート' },
          { text: 'CRM連携（予定）' },
        ];
    }
  } else if (version === 5) {
    // version 5 (version Y)
    switch (plan) {
      case 'light':
        return [
          { text: '二重対応防止機能' },
          { text: '@メンション・既読未読' },
          { text: '対応履歴の表示' },
        ];
      case 'standard':
        return [
          {
            bold: true,
            text: 'ライトの全機能',
          },
          {
            text: '個人メールの管理',
          },
          {
            text: '送信予約',
          },
          {
            text: '開封履歴',
          },
          {
            text: 'Slack・Chatwork連携',
          },
        ];
      case 'business':
        return [
          { text: 'スタンダードの全機能', bold: true },
          { text: '担当者の自動設定' },
          { text: '自動返信' },
          { text: 'レポート' },
          { text: 'CRM連携(予定)' },
        ];
      case 'enterprise':
        return [
          { text: 'ビジネスの全機能', bold: true },
          { text: 'IPアドレス制限(予定)' },
        ];
    }
  } else if (version === 6) {
    // version 6 (version Z)
    switch (plan) {
      case 'light':
        return [
          {
            bold: true,
            text: 'メール共有・管理に必要な標準機能',
          },
          { text: '個人メールの管理' },
          { text: '共有メールの管理' },
        ];
      case 'standard':
        return [
          {
            bold: true,
            text: 'ライトの全機能',
          },
          {
            text: '送信予約',
          },
          {
            text: 'yaritori AI (α)',
          },
          {
            text: 'Slack・Chatwork連携',
          },
          {
            text: 'メール開封履歴',
          },
        ];
      case 'business':
        return [
          { text: 'スタンダードの全機能', bold: true },
          { text: '自動返信' },
          { text: 'Salesforce連携' },
          { text: 'メール開封通知' },
        ];
    }
  }

  return [];
};
