import { SCREEN_LG, SCREEN_SM } from '../constants';

export const isTablet = (): boolean =>
  Boolean(
    window.matchMedia &&
      window.matchMedia(`(max-width: ${SCREEN_LG}px)`).matches
  );

export const isSP = (): boolean =>
  Boolean(
    window.matchMedia &&
      window.matchMedia(`(max-width: ${SCREEN_SM}px)`).matches
  );
