import { ReactNode } from 'react';
import { createStore, Provider } from 'jotai';
import { useSubscribeAuthState } from '../atoms/auth';
import { useAtom } from 'jotai/index';
import { attachmentDeletionSchedulerEffect } from '../components/delivery/form/DeliveryMessageCreateForm/attachmentDeletionScheduler';

export type Props = {
  children: ReactNode;
};

export const store = createStore();

export const StoreProvider = ({ children }: Props): JSX.Element => {
  return (
    <Provider store={store}>
      <GlobalEffects>{children}</GlobalEffects>
    </Provider>
  );
};

const GlobalEffects = ({ children }: { children: ReactNode }): JSX.Element => {
  useSubscribeAuthState();
  useAtom(attachmentDeletionSchedulerEffect);
  return <>{children}</>;
};
