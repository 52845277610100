import { Icon } from '../../../../../components/basics';
import { AutoReplied } from '../../../../../components/icons';

export const AutoRepliedIndication = () => {
  return (
    <div className="grid grid-cols-[auto_1fr] items-center gap-2 text-sm text-[#fc9a33]">
      <Icon icon={AutoReplied} size="1.1em" />
      <span>このメールは自動返信されました</span>
    </div>
  );
};
