import { Checkbox } from 'antd';
import { UserChatworkNotificationType } from 'lib';

const NOTIFICATION_TYPE_OPTIONS: {
  label: string;
  value: UserChatworkNotificationType;
}[] = [
  { label: '自分が担当者', value: 'assigned' },
  {
    label: '自分宛て（@メンション）の新規コメント',
    value: 'mentionedMessage',
  },
];

type Props = {
  value?: UserChatworkNotificationType[];
  onChange: (type: UserChatworkNotificationType[]) => void;
  disabled?: boolean;
};

export const UserNotificationTypeCheckboxGroup: React.FC<Props> = ({
  value: types,
  onChange: onChangeTypes,
  disabled,
}) => {
  return (
    <Checkbox.Group
      options={NOTIFICATION_TYPE_OPTIONS}
      value={types}
      onChange={(types) =>
        onChangeTypes(types as UserChatworkNotificationType[])
      }
      disabled={disabled}
    />
  );
};
