import { Tooltip } from '../../basics/Tooltip/Tooltip';
import { IconButton, Loading } from '../../basics';
import { ArrowDownward, Delete } from '../../icons';
import React, { useState } from 'react';

type Props = {
  name: string;
  onDownload?: () => void;
  onRemove?: () => Promise<void> | void;
  disabled?: boolean;
  defaultRemoving?: boolean; // デバッグ用
};

export const CommentAttachment = ({
  name,
  onDownload,
  onRemove,
  disabled,
  defaultRemoving = false,
}: Props) => {
  const [removing, setRemoving] = useState(defaultRemoving);
  const handleRemove = async () => {
    if (!onRemove) {
      return;
    }
    setRemoving(true);
    try {
      await onRemove();
      setRemoving(false);
    } catch (e) {
      console.error(e);
      setRemoving(false);
    }
  };
  return (
    <div className="grid min-h-[34px] grid-cols-[1fr_auto] items-center gap-1 rounded border border-sumi-200 bg-white p-1 pl-2 text-sm">
      <div className="truncate text-xs" title={name}>
        {name}
      </div>
      <div className="flex gap-[1px]">
        {onDownload && (
          <Tooltip content="ダウンロード" visible={!disabled}>
            <IconButton
              aria-label="添付ファイルをダウンロード"
              component={ArrowDownward}
              className="text-sumi-500"
              onClick={() => onDownload()}
              disabled={disabled || removing}
            />
          </Tooltip>
        )}
        {onRemove && (
          <Tooltip content="削除" visible={!disabled}>
            {removing ? (
              <div className="flex h-[24px] w-[24px] cursor-wait items-center justify-center text-sumi-500">
                <Loading size={12} className="block" />
              </div>
            ) : (
              <IconButton
                aria-label="添付ファイルを削除"
                component={Delete}
                className="text-sumi-500"
                onClick={() => handleRemove()}
                disabled={disabled}
              />
            )}
          </Tooltip>
        )}
      </div>
    </div>
  );
};
