import { Button } from 'antd';
import { SettingField } from 'App/Settings/common';
import { Comment } from 'App/Common/Comment';
import { useState } from 'react';
import styled from 'styled-components';
import { InboxAutoReply } from 'lib';
import { CheckCircleFilled } from '@ant-design/icons';

type Props = {
  hidden?: boolean;
  autoReply?: InboxAutoReply | null;
  notSupported?: boolean;
  renderModal: (params: { close: () => void }) => React.ReactElement;
  disabled?: boolean;
};

export const AutoReplyField: React.FC<Props> = (props) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>();

  return (
    <SettingField label="自動返信" hidden={props.hidden}>
      <Inner>
        {props.notSupported ? (
          <Comment>自動返信は現在のプランではサポートされていません</Comment>
        ) : (
          <>
            <div className="status">
              <div className="message">
                {props.autoReply?.enabled ? (
                  <span className="enabled">
                    <CheckCircleFilled className="icon"></CheckCircleFilled>
                    自動返信は現在有効です
                  </span>
                ) : (
                  <span className="disabled">自動返信は現在無効です</span>
                )}
              </div>
              <Button
                className="edit"
                type="primary"
                onClick={() => {
                  setIsModalVisible(true);
                }}
                disabled={props.disabled}
              >
                設定
              </Button>
            </div>
            <Comment className="note">
              メールを受信した際に、予め設定したメッセージを自動的に差出人に返信します。
            </Comment>
            {isModalVisible &&
              props.renderModal({
                close: () => {
                  setIsModalVisible(false);
                },
              })}
          </>
        )}
      </Inner>
    </SettingField>
  );
};

const Inner = styled.div`
  & > .status {
    display: flex;
    align-items: center;
    margin-top: 10px;

    & > .message {
      color: #a5a5a5;
      width: 220px;
      font-size: 13px;
      font-weight: 600;

      & .icon {
        font-size: 14px;
        margin-right: 3px;
      }

      & > .enabled {
        color: #49d319;
      }
    }

    & > .edit {
      width: 98px;
    }
  }

  & > .note {
    margin-top: 14px;
  }
`;
