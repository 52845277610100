import { isProduction } from '../util';

// FIXME: loadScript()完了のタイミング次第で boot()が失敗しうる
class ChannelService {
  constructor() {
    this.loadScript();
    this.badgeChangeListeners = [];
  }

  loadScript() {
    var w = window;
    if (w.ChannelIO) {
      return (window.console.error || window.console.log || function () {})(
        'ChannelIO script included twice.'
      );
    }
    var d = window.document; // eslint-disable-line
    var ch = function () {
      ch.c(arguments);
    };
    ch.q = [];
    ch.c = function (args) {
      ch.q.push(args);
    };
    w.ChannelIO = ch;

    const onInitialized = () => {
      if (w.ChannelIOInitialized) {
        return;
      }
      // testコケるから無視
      if (process.env.NODE_ENV === 'test') {
        return;
      }
      w.ChannelIOInitialized = true;
      var s = document.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = 'https://cdn.channel.io/plugin/ch-plugin-web.js';
      s.charset = 'UTF-8';
      var x = document.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(s, x);

      w.ChannelIO('onBadgeChanged', (unreadCount) => {
        this.badgeChangeListeners.forEach((listener) => {
          listener(unreadCount);
        });
      });
    };

    if (document.readyState === 'complete') {
      onInitialized();
    } else if (window.attachEvent) {
      window.attachEvent('onload', onInitialized);
    } else {
      window.addEventListener('DOMContentLoaded', onInitialized, false);
      window.addEventListener('load', onInitialized, false);
    }
  }

  boot(userId, username, email, companyId, companyName) {
    const onBoot = (error, user) => {
      if (error) {
        console.error('ChannelService.boot.onBoot:', error);
        return;
      }

      this.badgeChangeListeners.forEach((listener) => {
        listener(user.alert);
      });

      if (!canShowChannel(window.location.pathname)) {
        // 表示しない画面の場合、ログイン情報をチャネルトークに送りたいため、boot -> shutdown としている
        ChannelService.shutdown();
      }
    };

    if (isProduction()) {
      window.ChannelIO(
        'boot',
        {
          pluginKey: '965ebf54-2c5a-4c56-997e-7691d0a9848e',
          memberId: userId,
          hideChannelButtonOnBoot: true,
          hidePopup: true,
          profile: {
            name: username,
            email,
            companyId,
            companyName,
            tagVersion: process.env.REACT_APP_TAG_VERSION,
            buildVersion: Number(process.env.REACT_APP_BUILD_VERSION),
          },
          zIndex: 100,
        },
        onBoot
      );
    }
  }

  shutdown() {
    if (isProduction()) {
      window.ChannelIO('shutdown');
    }
  }

  showMessenger() {
    window.ChannelIO('showMessenger');
  }

  /**
   *
   * @param {(unreadCount: number)=>void} listener
   */
  listenBadgeChanged(listener) {
    this.badgeChangeListeners.push(listener);
  }

  /**
   *
   * @param {(unreadCount: number)=>void} listener
   */
  unlistenBadgeChanged(listener) {
    this.badgeChangeListeners = this.badgeChangeListeners.filter(
      (l) => l !== listener
    );
  }
}

// MEMO: 設定画面のみ出す場合、下記をコメントアウトする
// export const canShowChannel = pathname => whiteList.some(whitePath => pathname.startsWith(whitePath));
// const whiteList = ["/settings", "/signIn", "/resetPassword", "/register"];

// 現在はすべての画面でチャンネルを出すようにしている
export const canShowChannel = (pathname) => true;

// eslint-disable-next-line import/no-anonymous-default-export
export default new ChannelService();
