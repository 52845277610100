import { Extension } from '@tiptap/core';

export const DisableShiftEnter = Extension.create({
  name: 'DisableShiftEnter',
  addKeyboardShortcuts() {
    return {
      'Shift-Enter': ({ editor }) => {
        editor.commands.enter();
        return true;
      },
    };
  },
});
