import InfiniteScroll from 'react-infinite-scroller';
import { ComponentProps, useRef } from 'react';
import SimpleBar from 'simplebar-react';
import { twMerge } from 'tailwind-merge';
import SimpleBarCore from 'simplebar-core';

type Props = Omit<ComponentProps<typeof InfiniteScroll>, 'getScrollParent'> & {
  simpleBarProps?: ComponentProps<typeof SimpleBar>;
};

export const WrappedInfiniteScroll = ({
  id = 'scroll-target',
  simpleBarProps: { scrollableNodeProps, className, ...simpleBarProps } = {},
  ...props
}: Props) => {
  const ref = useRef<SimpleBarCore>(null);
  return (
    <SimpleBar
      {...simpleBarProps}
      className={twMerge('h-full', className)}
      scrollableNodeProps={{ ...scrollableNodeProps, id }}
      ref={ref}
    >
      <InfiniteScroll
        {...props}
        getScrollParent={() => ref.current?.getScrollElement() as HTMLElement}
      />
    </SimpleBar>
  );
};
