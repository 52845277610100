import { Dialog } from 'components/basics/dialog/Dialog';
import { DialogHeader } from 'components/basics/dialog/DialogHeader';
import { DialogFooter } from 'components/basics/dialog/DialogFooter';
import { DialogContent } from 'components/basics/dialog/DialogContent';
import {
  StripeProductOption,
  StripeProductOptionType,
  StripeProductOptionValue,
} from './types';
import React, { useEffect, useMemo, useState } from 'react';
import { Button } from 'components/basics';

type Props = {
  defaultValues: StripeProductOptionValue[];
  stripeProductOptions: StripeProductOption[];
  openChange: boolean;
  setOpenChange: (value: boolean) => void;
  onSubmit: (newValues: StripeProductOptionValue[]) => void;
};

export const StripeProductOptionDialog: React.FC<Props> = ({
  defaultValues,
  stripeProductOptions,
  openChange,
  setOpenChange,
  onSubmit,
}) => {
  const [values, setValues] =
    useState<StripeProductOptionValue[]>(defaultValues);

  useEffect(() => {
    setValues(defaultValues);
  }, [defaultValues]);

  const getValue = (optionId: StripeProductOption['id']) =>
    values.find((v) => optionId === v.optionId);

  const updateValues = (
    optionId: StripeProductOption['id'],
    value: Partial<StripeProductOptionValue>
  ) => {
    setValues((prevValues) => {
      const exist = prevValues.find((v) => v.optionId === optionId);
      if (!exist) {
        return [
          ...prevValues,
          {
            optionId,
            checked: false,
            ...value,
            seat: value.checked ? 1 : value.seat,
          },
        ];
      }

      return prevValues.map((v) => {
        return v.optionId === optionId
          ? {
              ...v,
              ...value,
              seat: value.checked ? 1 : value.seat,
            }
          : v;
      });
    });
  };

  const total = useMemo<number>(
    () =>
      values.reduce((acc, currentValue) => {
        const productOption = stripeProductOptions.find(
          (opt) => opt.id === currentValue.optionId
        );
        const productPrice = productOption?.price || 0;
        if (!currentValue.checked) {
          return acc;
        }

        if (currentValue.seat) {
          return acc + productPrice * currentValue.seat;
        }

        return acc + productPrice;
      }, 0),

    [values, stripeProductOptions]
  );

  return (
    <Dialog open={openChange} width="sm" onOpenChange={setOpenChange}>
      <DialogHeader title="オプションの変更" />
      <DialogContent className="text-sm">
        <div className="space-y-4">
          {stripeProductOptions.map((option) => (
            <div key={option.id}>
              <div className="flex items-start">
                <input
                  className="mt-1 h-5 w-5"
                  type="checkbox"
                  checked={getValue(option.id)?.checked || false}
                  onChange={(evt) =>
                    updateValues(option.id, { checked: evt.target.checked })
                  }
                />
                <p className="my-0 ml-2">{option.name}</p>
                <p className="my-0 ml-auto">
                  {option.price.toLocaleString()}円/
                  {option.interval === 'year' ? '年' : '月'}
                </p>
              </div>
              {option.optionType === StripeProductOptionType.seat && (
                <div className="mt-2">
                  <p className="mb-3">アカウント数</p>
                  <input
                    className="h-10 w-full rounded-lg border border-sumi-300 px-3 py-2.5"
                    value={getValue(option.id)?.seat || ''}
                    onChange={(evt) =>
                      updateValues(option.id, {
                        seat: evt.target.valueAsNumber,
                      })
                    }
                    type="number"
                    placeholder="5"
                  />
                </div>
              )}
            </div>
          ))}
        </div>
      </DialogContent>
      <DialogFooter>
        <p className="mb-0 mr-auto text-sm text-sumi-900">
          月額
          {total >= 10000 ? (
            <>
              <span className="ml-1 text-3xl font-bold">
                {(total / 10000).toLocaleString()}
              </span>{' '}
              <span className="text-xs">万円</span>
            </>
          ) : (
            <>
              <span className="ml-1 text-3xl font-bold">
                {total.toLocaleString()}
              </span>{' '}
              <span className="text-xs">円</span>
            </>
          )}
        </p>
        <div className="flex gap-2">
          <Button
            className="font-bold"
            variant="outlined"
            onClick={() => setOpenChange(false)}
          >
            キャンセル
          </Button>
          <Button onClick={() => onSubmit(values)} className="font-bold">
            変更する
          </Button>
        </div>
      </DialogFooter>
    </Dialog>
  );
};
