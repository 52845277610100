import { Alert } from '../../../../../components/basics/Alert/Alert';
import React, { Fragment } from 'react';
import { Button, Icon, Loading } from '../../../../../components/basics';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { arrayMove, SortableContext } from '@dnd-kit/sortable';
import { FilterListItem } from '../FilterListItem/FilterListItem';
import { ArrowDownward } from '../../../../../components/icons';

type Filter = {
  id: string;
  name: string;
  stopOtherFilters: boolean;
};

type Props = {
  filters: Filter[];
  onOpenCreateDrawer: () => void;
  onOpenEditDrawer: (id: string) => void;
  onSort: (sortedFilters: Filter[]) => void;
  loading: boolean;
  readonly: boolean;
};

export const Filters = ({
  filters,
  onOpenCreateDrawer,
  onOpenEditDrawer,
  onSort,
  loading,
  readonly,
}: Props) => {
  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id == null || over == null || active.id === over.id) {
      return;
    }

    const filterIds = filters.map((i) => i.id);
    const oldIndex = filterIds.indexOf(active.id as string);
    const newIndex = filterIds.indexOf(over.id as string);
    const sortedFilters = arrayMove(filters, oldIndex, newIndex);
    onSort(sortedFilters);
  };

  return (
    <div className="text-sm">
      {filters.length <= 0 && !loading && (
        <div className="mb-4">
          <Alert>
            フローは件名、本文、Fromアドレスなどから自動的に
            <br />
            ・担当者を設定する
            <br />
            ・対応済みにする
            <br />
            ・タグを付ける
            <br />
            ことができる機能です。
          </Alert>
        </div>
      )}
      <Button onClick={() => onOpenCreateDrawer()} disabled={readonly}>
        フローを作成
      </Button>
      <div className="mt-8 flex flex-col items-start gap-4">
        <div className="text-base font-bold">作成済みのフロー</div>
        <div className="flex w-full flex-col gap-3">
          {loading && <Loading />}
          {!loading && filters.length <= 0 && (
            <div className="text-sumi-500">設定中のフローがありません</div>
          )}
          {!loading && (
            <DndContext onDragEnd={handleDragEnd}>
              <SortableContext items={filters}>
                {filters.map((f, i) => (
                  <Fragment key={f.id}>
                    <div>
                      <FilterListItem
                        id={f.id}
                        name={f.name}
                        onClick={() => onOpenEditDrawer(f.id)}
                      />
                      {f.stopOtherFilters && (
                        <div className="text-sumi-500">
                          *適用された場合、フローを停止
                        </div>
                      )}
                    </div>
                    {filters.length - 1 > i && (
                      <div className="pl-4">
                        <Icon
                          icon={ArrowDownward}
                          size={18}
                          className="block text-sumi-500"
                        />
                      </div>
                    )}
                  </Fragment>
                ))}
              </SortableContext>
            </DndContext>
          )}
        </div>
      </div>
    </div>
  );
};
