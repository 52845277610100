import { Tooltip } from './basics/Tooltip/Tooltip';

type Props = {
  children: JSX.Element;
  enabled: boolean;
  action: 'use' | 'change';
};

export const ReadOnlyTooltip = ({
  children,
  enabled,
  action,
}: Props): JSX.Element => {
  let message = '';
  switch (action) {
    case 'use':
      message = 'メンバーのみ利用できます';
      break;
    case 'change':
      message = 'メンバーのみ変更できます';
      break;
  }
  return enabled ? <Tooltip content={message}>{children}</Tooltip> : children;
};
