import { SettingPageDrawer } from '../../../common/SettingPageDrawer/SettingPageDrawer';
import { v4 } from 'uuid';
import { ReactElement, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useConfirmDialog } from '../../../../../hooks/confirmDialog';
import { InputGroup } from '../../../../../components/forms/InputGroup/InputGroup';
import { Checkbox, Input, Select } from '../../../../../components/forms';
import { MultipleInput } from '../../../../../components/forms/MultipleInput/MultipleInput';
import { Button } from '../../../../../components/basics';
import { FilterConditionInput } from '../FilterConditionInput/FilterConditionInput';
import { FilterActionInput } from '../FilterActionInput/FilterActionInput';

export type FilterCondition = {
  id: string;
  filterText: string;
  caseSensitive: boolean;
} & (
  | {
      target: 'subject' | 'body';
      operator: 'contain' | 'notContain' | 'equal';
    }
  | {
      target: 'from' | 'to' | 'cc' | 'bcc';
      operator: 'contain' | 'notContain';
    }
);

export type FilterAction =
  | {
      id: string;
      type: 'assign';
      assignee: string;
    }
  | {
      id: string;
      type: 'assignSender';
    }
  | {
      id: string;
      type: 'updateStatusAsProcessed';
    }
  | { id: string; type: 'addTag'; tags: string[] }
  | { id: string; type: 'skipInbox' };

export type FilterActionType = FilterAction['type'];

type Filter = {
  name: string;
  inboxIds: string[];
  trigger: 'receive' | 'reply';
  conditions: FilterCondition[][];
  actions: FilterAction[];
  stopOtherFilters: boolean;
};

export type FilterUpdate = Filter;

type Props = {
  filterId: string | undefined;
  filter: Partial<Filter>;
  inboxes: {
    id: string;
    email: string;
  }[];
  members: {
    id: string;
    name: string;
    renderAvatar: () => ReactElement;
  }[];
  tags: {
    id: string;
    name: string;
    // tagColor: TagColor // TODO
  }[];
  onUpdate: (id: string | undefined, update: FilterUpdate) => Promise<void>;
  onDelete: (id: string) => Promise<void>;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  readonly: boolean;
  container?: HTMLElement;
};

export const createDefaultFilterCondition = (): FilterCondition => ({
  id: v4(),
  target: 'subject',
  filterText: '',
  caseSensitive: true,
  operator: 'contain',
});

export const createDefaultFilterAction = (): FilterAction => ({
  id: v4(),
  type: 'assign',
  assignee: '',
});

export const FilterEditDrawer = ({
  filterId,
  filter,
  inboxes,
  members,
  tags,
  onUpdate,
  onDelete,
  open,
  onOpenChange,
  readonly,
  container,
}: Props) => {
  const openDialog = useConfirmDialog();
  const [conditionsValid, setConditionsValid] = useState(false);
  const [actionsValid, setActionsValid] = useState(false);
  const {
    register,
    control,
    handleSubmit,
    formState: { isSubmitting, isDirty },
    reset,
  } = useForm<Filter>({
    defaultValues: {
      name: filter?.name ?? '',
      trigger: filter?.trigger ?? 'receive',
      inboxIds: filter.inboxIds ?? [],
      conditions: filter.conditions ?? [[createDefaultFilterCondition()]],
      actions: filter.actions ?? [createDefaultFilterAction()],
      stopOtherFilters: filter.stopOtherFilters ?? false,
    },
  });
  const onSubmit = async (update: Filter) => {
    try {
      await onUpdate(filterId, {
        name: update.name,
        trigger: update.trigger,
        inboxIds: update.inboxIds,
        conditions: update.conditions,
        actions: update.actions,
        stopOtherFilters: update.stopOtherFilters,
      });
      reset(update);
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <SettingPageDrawer
      title={filterId ? 'フロー編集' : 'フロー作成'}
      open={open}
      onOpenChange={onOpenChange}
      container={container}
      drawerClassName="w-[560px]"
    >
      <form
        action=""
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col gap-4 text-sm"
      >
        <Controller
          name="name"
          control={control}
          rules={{ required: '入力してください' }}
          render={({ fieldState: { error } }) => (
            <InputGroup label="フロー名" errorMessage={error?.message} required>
              <Input
                placeholder="担当者設定フロー"
                disabled={readonly || isSubmitting}
                {...register('name')}
              />
            </InputGroup>
          )}
        />
        <Controller
          name="inboxIds"
          control={control}
          rules={{ required: '入力してください' }}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <InputGroup
              label="対象のメールアドレス"
              errorMessage={error?.message}
              required
            >
              <MultipleInput
                value={value}
                onChange={onChange}
                suggests={inboxes.map((inbox) => ({
                  value: inbox.id,
                  text: inbox.email,
                }))}
                disabled={readonly || isSubmitting}
              />
            </InputGroup>
          )}
        />
        <Controller
          name="trigger"
          control={control}
          rules={{ required: '入力してください' }}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <InputGroup label="トリガー" errorMessage={error?.message}>
              <Select
                value={value}
                onChange={onChange}
                options={[
                  { value: 'receive', label: '受信時' },
                  { value: 'reply', label: '返信時' },
                ]}
                className="h-10"
                variants={{ rounded: 'lg', width: 'full' }}
                disabled={readonly || isSubmitting}
              />
            </InputGroup>
          )}
        />
        <Controller
          name="conditions"
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <InputGroup label="条件" errorMessage={error?.message}>
              <FilterConditionInput
                value={value}
                onChange={onChange}
                onValidate={setConditionsValid}
                disabled={readonly || isSubmitting}
              />
            </InputGroup>
          )}
        />
        <Controller
          name="actions"
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <InputGroup label="処理" errorMessage={error?.message}>
              <FilterActionInput
                value={value}
                onChange={onChange}
                onValidate={setActionsValid}
                members={members}
                tags={tags}
                disabled={readonly || isSubmitting}
              />
            </InputGroup>
          )}
        />
        <Checkbox
          label="このフローが適用されたら、その後のフローを停止する"
          {...register('stopOtherFilters')}
        />
        <div className="flex gap-4">
          {filterId ? (
            <>
              <Button
                color="danger"
                disabled={readonly || isSubmitting}
                onClick={() =>
                  openDialog({
                    title: 'フィルターを削除しますか？',
                    description: '一度削除すると元に戻せません',
                    okType: 'danger',
                    okText: '削除',
                    onOk: async () => await onDelete(filterId),
                  })
                }
              >
                削除
              </Button>
              <Button
                type="submit"
                disabled={readonly || !isDirty}
                loading={isSubmitting}
              >
                更新
              </Button>
            </>
          ) : (
            <Button
              type="submit"
              disabled={
                readonly || !isDirty || !conditionsValid || !actionsValid
              }
              loading={isSubmitting}
            >
              作成
            </Button>
          )}
        </div>
      </form>
    </SettingPageDrawer>
  );
};
