import { Icon as AntdIcon } from 'antd';
import { ComponentType, SVGProps } from 'react';

type Props = SVGProps<SVGSVGElement> & {
  icon: ComponentType<SVGProps<SVGSVGElement>> | string;
  size?: number | string;
};

export const Icon = (props: Props): JSX.Element => {
  const { icon, size, className, ...svgProps } = props;
  if (typeof icon === 'string') {
    return <AntdIcon type={icon} className={className} />;
  }
  if (size) {
    svgProps.width = size;
    svgProps.height = size;
  }
  const IconComponent = icon;
  return <IconComponent {...svgProps} className={className} />;
};
