import {
  DocumentData,
  DocumentReference,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
} from 'firebase/firestore';

export type EntityBase<T, D, P> = {
  id: string;
  ref: DocumentReference<D>;
  _type: T;
  data: D;
  props: P;
  clone: (data?: Partial<D>) => Entity<T, D, P>;
};

export type Merge<A, B> = {
  [K in keyof A | keyof B]: K extends keyof B
    ? B[K]
    : K extends keyof A
    ? A[K]
    : never;
};

export type Entity<T, D, P> = Readonly<Merge<Merge<D, P>, EntityBase<T, D, P>>>;

export type EntityFactory<T, D, P> = (
  ref: DocumentReference<D>,
  data: D
) => Entity<T, D, P>;

export const createEntityFactory = <
  T extends string,
  D extends object,
  P = Record<never, never>,
>(
  type: T,
  getProps: (data: D, ref: DocumentReference<D>) => P = () => ({}) as P
): EntityFactory<T, D, P> => {
  const factory = (ref: DocumentReference<D>, data: D) => {
    const props = getProps(data, ref);
    const entity = { ...data };
    Object.defineProperties(entity, Object.getOwnPropertyDescriptors(props));
    Object.assign(entity, {
      id: ref.id,
      ref,
      _type: type,
      data,
      props,
      clone(d?: Partial<D>): Entity<T, D, P> {
        return factory(ref, {
          ...data,
          ...d,
        });
      },
    });
    return entity as Entity<T, D, P>;
  };
  return factory;
};

export const createConverter = <T, D, P>(
  factory: EntityFactory<T, D, P>
): FirestoreDataConverter<Entity<T, D, P>> => {
  return {
    toFirestore: (model: Entity<T, D, P>): DocumentData => {
      if (!model.data || typeof model.data !== 'object') {
        return {};
      }
      return model.data;
    },
    fromFirestore: (
      snapshot: QueryDocumentSnapshot,
      options?: SnapshotOptions
    ): Entity<T, D, P> => {
      const snap = snapshot as QueryDocumentSnapshot<D>;
      const data = snap.data({
        serverTimestamps: 'estimate',
        ...options,
      });
      return factory(snap.ref, data);
    },
  };
};
