import { createConverter, createEntityFactory } from '../converter';
import { FirestoreDataConverter, Timestamp } from 'firebase/firestore';

export type ReactionsData = {
  teamId: string;
  emojis: string[];
  createdAt: Timestamp;
  updatedAt: Timestamp;
};

export const createReactions = createEntityFactory<'Reactions', ReactionsData>(
  'Reactions'
);

export type Reactions = ReturnType<typeof createReactions>;

export const reactionsConverter: FirestoreDataConverter<Reactions> =
  createConverter(createReactions);
