import * as React from 'react';
import type { SVGProps } from 'react';
const SvgInbox = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M3.75 17.5a1.2 1.2 0 0 1-.875-.375 1.2 1.2 0 0 1-.375-.875V3.75a1.2 1.2 0 0 1 .375-.875A1.2 1.2 0 0 1 3.75 2.5h12.5a1.2 1.2 0 0 1 .875.375c.25.25.375.542.375.875v12.5a1.2 1.2 0 0 1-.375.875 1.2 1.2 0 0 1-.875.375H3.75Zm0-1.25h12.5v-2.833h-3.042c-.36.555-.83.982-1.406 1.28a3.868 3.868 0 0 1-1.802.449c-.625 0-1.226-.15-1.802-.448a3.665 3.665 0 0 1-1.406-1.281H3.75v2.833ZM10 13.896a2.607 2.607 0 0 0 2.312-1.375.712.712 0 0 1 .386-.281c.16-.049.323-.073.49-.073h3.062V3.75H3.75v8.417h3.063c.166 0 .33.024.49.073.159.048.287.142.385.28A2.607 2.607 0 0 0 10 13.897Z"
    />
  </svg>
);
export default SvgInbox;
