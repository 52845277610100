import { SidebarBase } from '../../../components/sidebar/SidebarBase/SidebarBase';
import { LeftSidebarLink } from '../../Top/LeftSidebar/button/LeftSidebarLink';
import { Group, Person } from '../../../components/icons';
import { SidebarSectionTitle } from '../../../components/sidebar/SidebarSectionTitle/SidebarSectionTitle';

type Props = {
  privateTeamId: string;
  teams: {
    id: string;
    name: string;
  }[];
};

export const ContactsSidebar = ({ privateTeamId, teams }: Props) => {
  return (
    <SidebarBase>
      <LeftSidebarLink
        icon={Person}
        label="自分のコンタクト"
        to={`/contacts/${privateTeamId}/contacts/contacts`}
        active={{
          type: 'parentPath',
          path: [`/contacts/${privateTeamId}/contacts`],
        }}
      />
      <SidebarSectionTitle>会社のコンタクト</SidebarSectionTitle>
      <div className="flex flex-col gap-[1px]">
        {teams.map((team, i) => (
          <LeftSidebarLink
            key={i}
            icon={Group}
            label={team.name}
            to={`/contacts/${team.id}/contacts/contacts`}
            active={{
              type: 'parentPath',
              path: [`/contacts/${team.id}/contacts`],
            }}
          />
        ))}
      </div>
    </SidebarBase>
  );
};
