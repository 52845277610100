import React, { useState } from 'react';
import { Select } from 'antd';
import { OptionProps } from 'antd/lib/select';
import * as color from '../../../../color';
import { ChatworkRoom } from 'lib';

type Props = {
  style: any;
  roomId?: number | null;
  rooms: ChatworkRoom[];
  disabled?: boolean;
  loading?: boolean;
  onSelectRoomId?: (roomId: number | null) => Promise<any>;
};

const Option = Select.Option as any as React.ClassicComponentClass<
  OptionProps & { texts: string[] }
>;

export const ChatworkRoomSelect: React.FC<Props> = ({
  style = {},
  roomId,
  rooms = [],
  disabled = false,
  loading = true,
  onSelectRoomId,
}) => {
  const [selecting, setSelecting] = useState(false);

  const formatRoomName = (chatworkRoom: ChatworkRoom | null) => {
    if (!chatworkRoom) {
      return <span style={{ color: color.common.text3 }}>ルームを選択</span>;
    }

    return <>{chatworkRoom.name}</>;
  };

  const chatworkRoomOption = (chatworkRoom: ChatworkRoom | null) => (
    <Option
      value={chatworkRoom?.room_id ?? (null as any)}
      key={chatworkRoom?.room_id.toString() ?? 'none'}
      texts={chatworkRoom ? [chatworkRoom.name] : []}
    >
      {formatRoomName(chatworkRoom)}
    </Option>
  );

  const chatworkRoomOptions = [null, ...rooms].map((c) =>
    chatworkRoomOption(c)
  );

  const handleSelect = async (roomId: number | null) => {
    setSelecting(true);
    if (onSelectRoomId) {
      try {
        await onSelectRoomId(roomId);
      } finally {
        setSelecting(false);
      }
    }
  };

  return (
    <Select
      showSearch
      style={{ ...style }}
      value={roomId ?? null}
      onSelect={handleSelect}
      optionFilterProp="texts"
      filterOption={(input, option) =>
        (option.props as { texts: string[] }).texts.some((r) =>
          r.toLowerCase().includes(input.toLowerCase())
        )
      }
      dropdownMatchSelectWidth={false}
      loading={loading || selecting}
      disabled={disabled || selecting}
    >
      {chatworkRoomOptions}
    </Select>
  );
};
