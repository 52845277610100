import {
  atomWithSubscribeCollection,
  companyCollection,
} from '../../firestore';
import { orderBy, query } from 'firebase/firestore';
import { deliveryMessageConverter } from 'lib';

export const [deliveryMessagesLoadingAtom, deliveryMessagesAtom] =
  atomWithSubscribeCollection(
    () =>
      query(
        companyCollection('deliveryMessages', deliveryMessageConverter),
        orderBy('createdAt', 'desc')
      ),
    (snapshot) => snapshot.docs.map((doc) => doc.data())
  );
