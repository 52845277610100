import * as React from 'react';
import type { SVGProps } from 'react';
const SvgAddSnippet = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={31.645}
    height={40.275}
    viewBox="0 0 31.645 40.275"
    {...props}
  >
    <g fill="currentColor">
      <path d="M30.207 2.158h-4.855v-1.8a.361.361 0 0 0-.36-.36h-2.517a.361.361 0 0 0-.36.36v1.8h-6.832v-1.8a.361.361 0 0 0-.36-.36h-2.517a.361.361 0 0 0-.36.36v1.8H7.191a1.437 1.437 0 0 0-1.438 1.438V8.99H1.438A1.437 1.437 0 0 0 0 10.428v28.409a1.437 1.437 0 0 0 1.438 1.438h23.015a1.437 1.437 0 0 0 1.438-1.438v-4.315h4.315a1.437 1.437 0 0 0 1.438-1.438V3.6a1.437 1.437 0 0 0-1.437-1.442Zm-7.552 34.881H3.236V12.226h9.8v7.821a1.8 1.8 0 0 0 1.8 1.8h7.821Zm0-18.07h-6.743v-6.743h.009l6.734 6.734Zm5.754 12.316h-2.517V17.621l-8.63-8.63H8.99v-3.6h3.057v1.438a.361.361 0 0 0 .36.36h2.517a.361.361 0 0 0 .36-.36V5.394h6.832v1.438a.361.361 0 0 0 .36.36h2.517a.361.361 0 0 0 .36-.36V5.394h3.057Z" />
      <path d="M13.703 27.102V22.73h-2.186v4.372H7.145v2.186h4.372v4.372h2.186v-4.372h4.372v-2.186Z" />
    </g>
  </svg>
);
export default SvgAddSnippet;
