import { useBreadcrumbList } from './useBreadcrumbList';
import { observer } from 'mobx-react';
import { useStore } from '../../../hooks/useStore';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { tv } from 'tailwind-variants';
import styles from './BreadcrumbList.module.css';
import { twMerge } from 'tailwind-merge';

const text = tv({
  base: 'whitespace-nowrap text-sm leading-4 text-sumi-600',
});

export const BreadcrumbList = observer(() => {
  const store = useStore();
  const breadcrumbList = useBreadcrumbList(store);
  if (breadcrumbList == null) {
    return null;
  }
  const elements: ReactNode[] = [];
  breadcrumbList.forEach((entry, index) => {
    if (entry.href) {
      elements.push(
        <Link key={index} className={text()} to={entry.href}>
          {entry.label}
        </Link>
      );
    } else {
      elements.push(
        <span key={index} className={text()}>
          {entry.label}
        </span>
      );
    }
    if (index < breadcrumbList.length - 1) {
      elements.push(
        <span key={`${index}-separator`} className={text()}>
          &gt;
        </span>
      );
    }
  });
  return (
    <div
      className={twMerge(
        'flex max-w-full gap-2 overflow-x-auto font-bold',
        styles.hideScrollbar
      )}
    >
      {elements}
    </div>
  );
});
