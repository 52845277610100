import * as React from 'react';
import type { SVGProps } from 'react';
const SvgAddColumnLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="currentColor"
    viewBox="0 -960 960 960"
    {...props}
  >
    <path d="M548.85-200h238.84q5.39 0 8.85-3.46t3.46-8.85v-535.38q0-5.39-3.46-8.85t-8.85-3.46H548.85v560Zm-296.54 60q-29.83 0-51.07-21.24Q180-182.48 180-212.31v-40q0-12.75 8.63-21.37 8.63-8.63 21.38-8.63 12.76 0 21.37 8.63 8.62 8.62 8.62 21.37v40q0 5.39 3.46 8.85t8.85 3.46h236.54v-560H252.31q-5.39 0-8.85 3.46t-3.46 8.85v40q0 12.75-8.63 21.37-8.63 8.63-21.38 8.63-12.76 0-21.37-8.63-8.62-8.62-8.62-21.37v-40q0-29.83 21.24-51.07Q222.48-820 252.31-820h535.38q29.83 0 51.07 21.24Q860-777.52 860-747.69v535.38q0 29.83-21.24 51.07Q817.52-140 787.69-140H252.31Zm-42.32-230q-12.76 0-21.37-8.63Q180-387.25 180-400v-50h-50q-12.75 0-21.37-8.63-8.63-8.63-8.63-21.38 0-12.76 8.63-21.37Q117.25-510 130-510h50v-50q0-12.75 8.63-21.37 8.63-8.63 21.38-8.63 12.76 0 21.37 8.63Q240-572.75 240-560v50h50q12.75 0 21.38 8.63 8.62 8.63 8.62 21.38 0 12.76-8.62 21.37Q302.75-450 290-450h-50v50q0 12.75-8.63 21.37-8.63 8.63-21.38 8.63Zm278.86-110Zm60 0h-60 60Zm0 0Z" />
  </svg>
);
export default SvgAddColumnLeft;
