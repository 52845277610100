import { Icon, Switch } from 'antd';
import { H2 } from 'App/Common/H2';
import styled from 'styled-components';

type Props = {
  enabled: boolean;
  title: string;
  onChangeEnabled: (enabled: boolean) => void;
  icon?: React.ReactNode;
  switchDisabled?: boolean;
};
export const UserNotificationHeading: React.FC<Props> = ({
  enabled,
  title,
  icon,
  onChangeEnabled,
  switchDisabled,
}) => {
  return (
    <Wrapper>
      {icon ? <div className="icon-wrappwer">{icon}</div> : null}
      <Icon type="chatwork" style={{ marginRight: 4, fontSize: 21 }} />
      {title}
      <Switch
        className="switch"
        checked={enabled}
        onChange={onChangeEnabled}
        style={{ marginLeft: 12 }}
        disabled={switchDisabled}
      />
    </Wrapper>
  );
};

const Wrapper = styled(H2)`
  display: flex;
  align-items: center;
`;
