export function parseEmailAddresses(input: string): string[] {
  const emails = [];
  input = input.replace(/[\r\n]/g, ' ');
  while (input) {
    const atIndex = input.indexOf('@');
    if (atIndex === -1) {
      break;
    }
    const commaIndex = input.indexOf(',', atIndex);
    const spaceIndex = input.indexOf(' ', atIndex);
    let endIndex;
    if (commaIndex === -1 && spaceIndex === -1) {
      break;
    } else if (commaIndex === -1) {
      endIndex = spaceIndex;
    } else if (spaceIndex === -1) {
      endIndex = commaIndex;
    } else {
      endIndex = Math.min(commaIndex, spaceIndex);
    }
    emails.push(input.slice(0, endIndex).trim());
    input = input.slice(endIndex + 1);
  }
  if (input) {
    emails.push(input.trim());
  }
  return emails;
}

/**
 * 文字列 "myname <test@example.com>" から "myname" を抽出する。
 * 文字列 "test@example.com" から "text@example.com" を抽出する。
 *
 * フロントでの表示に使い、メールアドレスと名前の組み合わせの場合は名前を表示する。
 * もしメールアドレスのみの組み合わせの場合はメールアドレスを表示する。
 *
 * @param input
 */
export const extractLabelFromAddress = (input: string): string => {
  return input.replace(/<[^>]*>/g, '').trim();
};
