import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import store from '../../../../../store';
import moment from 'moment';
import { CmpButton, CmpDropdown, CmpIcon } from 'utils/antdComp';

const DEFAULT_COUNT = 5;

type Props = {
  teamId: string;
  openEvents: [string, moment.Moment][];
};

export const SeenDropdown: React.FC<Props> = observer(
  ({ teamId, openEvents }) => {
    const supported = store.isSeenHistorySupported;
    const [visible, setVisible] = useState(false);
    const [showAllSeen, setShowAllSeen] = useState(false);
    // TODO: union typeの配列だと扱いづらいのでオブジェクト配列にしたい
    const [seenEvents, setSeenEvents] = useState<
      ([string, moment.Moment] | [string, moment.Moment, string])[]
    >(openEvents.slice(0, DEFAULT_COUNT));

    useEffect(() => {
      if (!visible || !supported) {
        return;
      }
      const shownEvents = showAllSeen
        ? openEvents
        : openEvents.slice(0, DEFAULT_COUNT);
      (async () => {
        const events = await Promise.all(
          shownEvents.map<Promise<[string, moment.Moment, string]>>(
            async ([email, ts]) => {
              const name = await store.contactStore.getContactNameByEmail({
                teamId,
                emailAddress: email,
              });
              return [email, ts, name];
            }
          )
        );
        setSeenEvents(events);
      })();
    }, [teamId, openEvents, showAllSeen, visible]);

    const renderSeenDropdown = () => {
      const items = !supported ? (
        <li>現在のプランでサポートされていません</li>
      ) : seenEvents.length > 0 ? (
        // FIXME: nameを展開するとコンパイルエラーになるのでとりあえずanyにキャスト
        (seenEvents as any[]).map(
          ([email, ts, name]: [string, moment.Moment, string]) => (
            <li key={email}>
              <SeenEmail title={email}>{name || email}</SeenEmail>
              <span title={ts.format('LLL')} style={{ whiteSpace: 'nowrap' }}>
                {ts.fromNow()}
              </span>
            </li>
          )
        )
      ) : (
        <li>まだ閲覧されていません</li>
      );
      const showMore =
        openEvents.length > DEFAULT_COUNT && !showAllSeen ? (
          <div style={{ textAlign: 'center' }}>
            <ButtonMore onClick={() => setShowAllSeen(true)}>
              もっと見る
            </ButtonMore>
          </div>
        ) : null;

      return (
        <SeenDropdownOverlay>
          <SeenHeader>閲覧履歴</SeenHeader>
          <ul>{items}</ul>
          {showMore}
        </SeenDropdownOverlay>
      );
    };

    const count = openEvents.length > 9 ? '9+' : String(openEvents.length);

    return (
      <CmpDropdown
        overlay={renderSeenDropdown()}
        trigger={['click']}
        visible={visible}
        onVisibleChange={setVisible}
      >
        <IconWrapper className={seenEvents.length === 0 ? 'none' : ''}>
          <CmpIcon className="eye" type="eye" />
          <div className="count">{count}</div>
        </IconWrapper>
      </CmpDropdown>
    );
  }
);

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  color: #4c87f2;

  :hover {
    font-size: 14px;
    background-color: #f0f0f1;
    color: rgb(56, 56, 56);
  }

  & > .count {
    width: 10px;
    text-align: left;
    font-weight: 600;
    position: absolute;
    font-size: 7px;
    bottom: 4px;
    right: -1px;
  }

  &.none {
    & > .eye {
      color: rgb(200, 200, 200);
    }

    & > .count {
      display: none;
    }
  }
`;

const SeenDropdownOverlay = styled.div`
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  max-width: 90vw;

  ul {
    list-style: none;
    margin: 0;
    padding: 0.5rem 0;
    max-height: 50vh;
    overflow-y: auto;
  }

  li {
    margin: 0;
    padding: 0.25rem 0.5rem;
    display: flex;
    justify-content: space-between;
    gap: 2rem;
  }

  & > .not-supported {
    color: rgb(0, 0, 0, 0.5);
    padding: 8px;
  }
`;

const SeenHeader = styled.h3`
  text-align: center;
  font-weight: 500;
  border-bottom: 1px solid rgb(230, 230, 230);
  padding: 4px;
  margin: 0;
  font-size: 13px;
`;

const SeenEmail = styled.span`
  max-width: 24em;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const ButtonMore = styled(CmpButton)`
  border-radius: 28px;
  margin-bottom: 0.5rem;
  height: 28px;
`;
