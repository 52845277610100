import React, { SVGProps } from 'react';
import { Button, Icon } from 'components/basics';

export type Props = {
  component: React.ComponentType<SVGProps<SVGSVGElement>>;
  text: string;
  disabled?: boolean;
  onClick: () => void;
};

const MessageBodyTextButton = ({
  component,
  text,
  disabled,
  ...otherProps
}: Props) => {
  return (
    <Button
      variant="outlined"
      color="sumi"
      className="h-7 rounded-lg px-1 py-0 text-xs hover:border-sea-500 hover:text-sea-500 sm:px-1"
      disabled={disabled}
      {...otherProps}
    >
      <div className="flex items-center gap-1">
        <Icon icon={component} />
        <span>{text}</span>
      </div>
    </Button>
  );
};

export default MessageBodyTextButton;
