import { Sent as SentEntity, sentConverter, SentData } from 'lib';
import { action, computed, makeObservable, observable } from 'mobx';
import firebase from '../firebase';
import { searchFunction } from '../functions';
import { eventNames, logEvent } from '../analytics';
import { Store } from './index';
import { onSnapshot } from 'firebase/firestore';
import { companyDoc } from '../firestore';

export class SentStore {
  rootStore: Store;
  searchingSent: boolean;
  hasMoreSentOnSearch: boolean;
  unsortedSearchedSent: SentEntity[];
  private _unsubscribeSearchSent: (() => void)[];
  defaultSearchLimitPerPageSent: number;

  constructor(rootStore: Store) {
    this.rootStore = rootStore;

    this.searchingSent = false;
    this.hasMoreSentOnSearch = true;
    this.unsortedSearchedSent = [];
    this._unsubscribeSearchSent = [];
    this.defaultSearchLimitPerPageSent = rootStore.messageLimitPerPage;

    makeObservable(this, {
      searchingSent: observable,
      hasMoreSentOnSearch: observable,
      unsortedSearchedSent: observable,
      sortedSearchedSent: computed,

      searchSent: action,
    });
  }

  get sortedSearchedSent() {
    return this.unsortedSearchedSent
      .slice()
      .sort((a, b) => b.date.valueOf() - a.date.valueOf());
  }

  collection(): firebase.firestore.CollectionReference<SentData> {
    return this.rootStore.companyCollection<SentData>('sent');
  }

  syncSent(sentId: string, callback: (sent?: SentEntity) => void) {
    return onSnapshot(companyDoc('sent', sentId, sentConverter), (doc) =>
      callback(doc.data())
    );
  }

  unsubscribeSearchSent = () => {
    if (this._unsubscribeSearchSent)
      this._unsubscribeSearchSent.forEach((unsubscribe) => unsubscribe());
    this._unsubscribeSearchSent = [];
  };

  addUnsubscribeSent = (unsubscribe: () => void) => {
    this._unsubscribeSearchSent.push(unsubscribe);
  };

  searchSent = async ({
    params,
    teamIds,
    sender,
    limit = this.defaultSearchLimitPerPageSent,
    offset = 0,
  }: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    params: any;
    teamIds: string[];
    sender: string;
    limit: number;
    offset: number;
  }) => {
    if (!offset) {
      this.unsubscribeSearchSent();
      this.unsortedSearchedSent = [];
      this.hasMoreSentOnSearch = true;
    }

    if (Object.keys(params).length === 0) {
      // パラメータが指定されていない場合は検索しない
      return;
    }

    this.searchingSent = true;

    const queryParam = {
      companyId: this.rootStore.signInCompany,
      teamIds: teamIds || this.rootStore.joinedTeams.map((t) => t.id),
      sender,
      offset,
      limit,
      ...params,
      type: 'sent',
      version: 1,
    };

    // 検索を実行してIDを取得する
    const result = await searchFunction({
      query: queryParam,
    });
    logEvent(eventNames.search_messages);

    const hitSentIds: string[] = result.data.hits.map(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (hit: any) => hit.fields.id[0]
    );

    // offset + limitが検索結果合計数より多いもしくは等しい場合、次ページがない
    if (
      offset + this.defaultSearchLimitPerPageSent >=
      result.data.total.value
    ) {
      this.hasMoreSentOnSearch = false;
    }

    if (hitSentIds.length === 0) {
      this.searchingSent = false;
      return;
    }

    hitSentIds.forEach((id) => {
      this.addUnsubscribeSent(
        onSnapshot(
          companyDoc('sent', id, sentConverter),
          (doc) => {
            if (!doc.exists()) {
              console.error('SentStore.searchSent: !doc.exists:', {
                sentId: id,
              });
              return;
            }
            const searchedSent = doc.data();
            this.unsortedSearchedSent = [
              ...this.unsortedSearchedSent.filter(
                (m) => m.id !== searchedSent.id
              ),
              searchedSent,
            ];

            // 検索結果がある場合、内容の取得待ちで空にならないようにするため、初回の読み込みが完了したらsearchingMessagesを変更する
            this.searchingSent = false;
          },
          () => {
            // 同期されていないタイミングで検索をかけた場合に権限エラーが発生してローディングが続くのを防いでいる
            // 例）削除後、すぐにElasticsearchに検索をかけるなど
            this.searchingSent = false;
          }
        )
      );
    });
  };
}
