import { ChangeEvent, forwardRef, useEffect, useRef, useState } from 'react';

import { DayPicker, DayPickerSingleProps } from 'react-day-picker';
import { Dropdown, DropdownHandler, DropdownPlacement, Icon } from '../basics';
import { Calendar } from '../icons';
import { Input } from './Input';
import { ja } from 'date-fns/locale';
import moment from 'moment';

type Props = DayPickerSingleProps & {
  onSelect: (day?: Date) => void;
  placement?: DropdownPlacement;
};

export const DatePicker = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { id, placement, selected, onSelect, ...dayPickerProps } = props;
  const [value, setValue] = useState('');
  const [month, setMonth] = useState(new Date());
  const dropdownRef = useRef<DropdownHandler>(null);

  const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const v = e.target.value;
    setValue(v);
    if (!v) {
      onSelect(undefined);
      return;
    }
    const m = moment(v, 'YYYY-MM-DD', true);
    if (!m.isValid()) {
      return;
    }
    if (dayPickerProps.fromDate && m.isBefore(dayPickerProps.fromDate)) {
      return;
    }
    if (dayPickerProps.toDate && m.isAfter(dayPickerProps.toDate)) {
      return;
    }
    const date = m.toDate();
    onSelect(date);
    setMonth(date);
  };

  useEffect(() => {
    if (!selected) {
      setValue('');
      return;
    }

    const m = moment(selected);
    if (m.isValid()) {
      setValue(m.format('YYYY-MM-DD'));
    }
  }, [selected]);

  return (
    <div className="relative w-full">
      <Input
        id={id}
        value={value}
        onChange={onChangeInput}
        className="pl-3 pr-11"
        size="sm"
        ref={ref}
      />
      <div className="absolute right-0 top-0 grid h-8 w-11">
        <Dropdown
          overlay={
            <div className="p-4">
              <DayPicker
                locale={ja}
                {...dayPickerProps}
                selected={selected}
                month={month}
                onMonthChange={setMonth}
                onSelect={onSelect}
                className="m-0 rounded-lg bg-white"
              />
            </div>
          }
          placement={placement}
          ref={dropdownRef}
        >
          <div className="flex h-full w-full items-center justify-center">
            <Icon icon={Calendar} className="text-sumi-900" />
          </div>
        </Dropdown>
      </div>
    </div>
  );
});
