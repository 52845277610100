import * as React from 'react';
import type { SVGProps } from 'react';
const SvgServiceLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={41}
    height={22}
    fill="none"
    viewBox="0 0 41 22"
    {...props}
  >
    <path
      fill="#4984F2"
      d="m.5.259 20 8.788 20-8.788L13.427 21.74l4.878-7.2L.5.258Z"
    />
  </svg>
);
export default SvgServiceLogo;
