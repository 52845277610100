import { User } from 'lib';

export type AssigneeOption = {
  user: User | null;
  isTeamMember?: boolean;
};

export const getAssigneeOptions = (users: User[], teamMembers: User[]) => {
  const assigneeOptions: AssigneeOption[] = [
    {
      user: null,
    },
    ...users.map((u) => {
      return {
        user: u,
        isTeamMember: teamMembers.some((m) => u.id === m.id),
      };
    }),
  ];

  const sortedAssigneeOptions = assigneeOptions.sort((a, b) => {
    if (!a.user && b.user) return -1;
    if (a.user && !b.user) return 1;
    if (!a.user && !b.user) return 0;

    const isAUserTeamMember = a.isTeamMember;
    const isBUserTeamMember = b.isTeamMember;

    if (isAUserTeamMember && !isBUserTeamMember) return -1;
    if (!isAUserTeamMember && isBUserTeamMember) return 1;
    return 0;
  });

  return sortedAssigneeOptions;
};
