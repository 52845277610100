import { estimateServerTimestampsOption } from './common';
import {
  Timestamp,
  DocumentSnapshot,
  DocumentReference,
} from 'firebase/firestore';

export interface TemplateData {
  teamId: string;
  title: string;
  to: string;
  cc: string;
  bcc: string;
  subject: string;
  body: string;
  bodyHtml: string;
  category: string | null;
  attachments: TemplateAttatchment[];
  createdAt: Timestamp;
  updatedAt: Timestamp;
}

export type TemplateAttatchment = {
  disposition: 'attatchment';
  name: string;
  size: number;
  storagePath: string;
  type: string;
  uid: string;
};

export class Template {
  private _id: string;
  private _data: TemplateData;
  private _ref: DocumentReference<TemplateData>;

  constructor(doc: DocumentSnapshot<TemplateData>) {
    this._id = doc.id;
    const data = doc.data(estimateServerTimestampsOption);
    if (!data) {
      throw new Error(`no data for #${doc.id}`);
    }
    this._data = data;
    this._ref = doc.ref;
  }

  get id() {
    return this._id;
  }

  get ref() {
    return this._ref;
  }

  get teamId() {
    return this._data.teamId;
  }

  get title() {
    return this._data.title;
  }

  get to() {
    return this._data.to || '';
  }

  get cc() {
    return this._data.cc || '';
  }

  get bcc() {
    return this._data.bcc || '';
  }

  get subject() {
    return this._data.subject || '';
  }

  get body() {
    return this._data.body;
  }

  get bodyHtml() {
    return this._data.bodyHtml || '';
  }

  get category() {
    return this._data.category || null;
  }

  get attachments() {
    return this._data.attachments || [];
  }

  updateData(data: Partial<TemplateData>) {
    Object.assign(this._data, data);
  }
}
