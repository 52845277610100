import { InboxesNoData } from './InboxesNoData';
import React from 'react';
import { Button } from '../../../../../components/basics';
import { Tooltip } from '../../../../../components/basics/Tooltip/Tooltip';

type Props = {
  onAdd: () => void;
  isPrivateTeam: boolean;
  readonly: boolean;
};

export const InboxesEmpty = ({ onAdd, isPrivateTeam, readonly }: Props) => {
  return (
    <div className="flex h-full w-full flex-col items-center justify-center gap-4 px-4 py-32 text-sm">
      <InboxesNoData />
      <div className="text-base font-bold">
        まだ{isPrivateTeam ? 'あなたの' : 'このチームの'}
        メールアドレスは未設定です
      </div>
      <div className="text-center">
        {isPrivateTeam ? (
          <>
            あなたのメールアドレスを登録し、yaritoriでメールを受信できるようにしましょう。
            <br />
            個人メールはログインユーザー（あなた）だけに表示されます。
          </>
        ) : (
          'チームで共有したいメールアドレスを登録し、yaritoriでメールを受信できるようにしましょう。'
        )}
      </div>
      <Tooltip visible={readonly} content="権限がありません">
        <Button
          onClick={() => onAdd()}
          className="h-12 rounded-lg font-bold"
          disabled={readonly}
        >
          メールアドレスを追加
        </Button>
      </Tooltip>
    </div>
  );
};
