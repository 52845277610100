import React from 'react';
import { Icon, Select } from 'antd';
import * as color from '../../../../color';

type Props = {
  style: any;
  channelId?: string | null;
  channels: any[];
  disabled?: boolean;
  loading?: boolean;
  onSelectChannelId: (id: string | null) => void;
};

export const SlackChannelSelect = ({
  style = {},
  channelId = null,
  channels = [],
  disabled = false,
  loading = true,
  onSelectChannelId,
}: Props): React.ReactElement => {
  // FIXME: any
  const channelNameTexts = (channelOrUser: any) => {
    if (!channelOrUser) return [];
    if (channelOrUser.is_channel) return [channelOrUser.name];
    return [
      channelOrUser.profile?.display_name,
      channelOrUser.real_name,
    ].filter((s) => Boolean(s));
  };

  // FIXME: any
  const formatChannelName = (slackChannelOrUser: any) => {
    if (!slackChannelOrUser)
      return <span style={{ color: color.common.text3 }}>通知先を選択</span>;

    if (slackChannelOrUser.is_channel) {
      const prefix = slackChannelOrUser.is_private ? <Icon type="lock" /> : '#';
      return (
        <>
          {prefix} {slackChannelOrUser.name}
        </>
      );
    }

    return (
      <>
        {slackChannelOrUser.profile?.image_24 && (
          <img
            src={slackChannelOrUser.profile?.image_24}
            alt="slack profile"
            style={{ marginRight: 4 }}
          />
        )}
        {slackChannelOrUser.profile?.display_name || ''}{' '}
        <span style={{ color: color.common.text1 }}>
          {slackChannelOrUser?.real_name || ''}
        </span>
      </>
    );
  };

  // FIXME: any
  const slackChannelOption = (channelOrUser: any) => {
    // FIXME: Select.OptionのPropsにtextsが存在しないのでとりあえずany
    const Option = Select.Option as any;

    return (
      <Option
        value={channelOrUser?.id || null}
        key={channelOrUser?.id || null}
        texts={channelNameTexts(channelOrUser)}
      >
        {formatChannelName(channelOrUser)}
      </Option>
    );
  };

  const slackChannelOptions = channels.map((c) => slackChannelOption(c));

  return (
    <Select
      showSearch
      style={{ ...style }}
      value={channelId}
      onSelect={onSelectChannelId}
      optionFilterProp="texts"
      filterOption={(input, option) =>
        // FIXME: any
        (option as any).props.texts.some((t: any) =>
          t.toLowerCase().includes(input.toLowerCase())
        )
      }
      loading={loading}
      dropdownMatchSelectWidth={false}
      disabled={disabled}
    >
      {slackChannelOptions}
    </Select>
  );
};
