import { Component } from 'react';
import styled from 'styled-components';
import { Menu, Popover, Tooltip } from 'antd';
import { PCOnly, SPOnly } from '../../../../Common/MediaQuery';
import ExAvatar from '../../../../Common/Avatar';
import CommonTooltip from '../../../../Common/Tooltip';
import firebase from 'firebase.js';
import { downloadFromUrl } from '../../../../../util';
import { ReactComponent as OptionsIcon } from '../../../../../assets/icons/options.svg';
import { eventNames, logEvent } from '../../../../../analytics';
import FilterModal from './filterModal';
import { Store } from '../../../../../store';
import { CmpDropdown, CmpIcon, CmpMenu } from 'utils/antdComp';
import { observer } from 'mobx-react';
import { MessageCollapse } from 'components/Message';
import MessageHeaderContact from './MessageHeaderContact';
import { IconButton } from 'components/basics';
import {
  MessageForward,
  MessageReply,
  MessageReplyAll,
} from 'components/icons';
import { StarButton } from '../../../../Common/Icons/StarButton';
import { compose } from 'recompose';
import { MessageHeaderProps } from './type';
import SPMessageHeader from './SPMessageHeader';
import { Attachments } from './Attachments';

type InjectedProps = MessageHeaderProps & {
  // TODO: Presentational ComponentをStoreに依存させない
  store: Store;
};

const MAX_READERS = 5;

// TODO: Function Component化
class Index extends Component<InjectedProps> {
  state = {
    renderModal: false,
    modalVisible: false,
  };

  showReaders = (readers: { [uid: string]: any }) => {
    const users = Object.keys(readers).flatMap((uid) => {
      const user = this.props.store.getUser(uid);
      return user ? [user] : [];
    });
    const omitCount = users.length - MAX_READERS;
    return (
      <div>
        {users.slice(0, MAX_READERS).map((user) => (
          <ExAvatar
            size="small"
            user={user}
            key={user.id}
            showNameOnHover={true}
          />
        ))}
        {omitCount > 0 && (
          <Popover
            placement="bottom"
            className="cursor-default"
            content={
              <div className="flex flex-col gap-1">
                {users.slice(-omitCount).map((u) => (
                  <div>{u.name}</div>
                ))}
              </div>
            }
          >
            <span className="ml-1 text-sumi-600">+{omitCount}</span>
          </Popover>
        )}
      </div>
    );
  };

  downloadEml = async () => {
    const { message } = this.props;
    const ref = firebase.storage().ref(message.emlStoragePath);
    const url = await ref.getDownloadURL();
    const subject =
      (message.subject || '').length > 0 ? message.subject : 'no_subject';
    downloadFromUrl(url, `${subject}.eml`);
    logEvent(eventNames.download_eml);
  };

  openFilterModal = () => {
    this.setState({ renderModal: true, modalVisible: true });
  };

  openPrint = async () => {
    const { message } = this.props;
    window.open(`/print/messages/${message.id}`);
  };

  toggleStar = () => this.props.store.toggleStar(this.props.message);

  isStarred = () => this.props.store.isStarredMessage(this.props.message.id);

  render() {
    const {
      message,
      startReply,
      startReplyAll,
      startForwarding,
      lock,
      collapsed,
      markMessageAsDeleted,
      restoreMessage,
      deleteMessage,
    } = this.props;
    const user = this.props.store.getUserByEmail(message.from.value[0].address);
    const locked = Boolean(lock);
    const isStarred = this.isStarred();

    const timestamp = message.date.format('YYYY年M月D日 HH:mm');
    const timestampShort = message.date.format('YYYY年M月D日');

    const isReadOnly = this.props.store.me.isReadOnly;

    return (
      <div className="px-4 pt-2">
        <MessageCollapse
          collapsed={collapsed}
          onClick={this.props.toggleCollapsed}
        />
        <SPOnly>
          <SPMessageHeader {...this.props} />
        </SPOnly>

        <PCOnly>
          <div className="grid grid-cols-[1fr_auto] items-start justify-between gap-2 pb-6">
            <MessageHeaderContact user={user} message={message} />
            <div className="flex items-center gap-2 text-xs">
              <p className="m-0 whitespace-nowrap text-sumi-600">既読</p>
              <div className="flex items-center -space-x-2">
                {this.showReaders(message.readers)}
              </div>
              <p className="m-0 whitespace-nowrap text-sumi-600">
                <span className={'hidden xl:inline'}>{timestamp}</span>
                <Tooltip title={timestamp} mouseEnterDelay={0.5}>
                  <span className={'inline xl:hidden'}>{timestampShort}</span>
                </Tooltip>
              </p>

              <Tooltip title="お気に入り" mouseEnterDelay={0.5}>
                {/* star button */}
                <StarButton
                  onClick={this.toggleStar}
                  starred={isStarred}
                  disabled={isReadOnly}
                />
              </Tooltip>
              <div className="flex items-center gap-1">
                {!locked && (
                  <Tooltip title="全員に返信" mouseEnterDelay={0.5}>
                    <IconButton
                      color="sumi"
                      onClick={startReplyAll}
                      component={MessageReplyAll}
                      disabled={isReadOnly}
                    />
                  </Tooltip>
                )}
                {!locked && (
                  <Tooltip title="返信" mouseEnterDelay={0.5}>
                    <IconButton
                      color="sumi"
                      onClick={startReply}
                      component={MessageReply}
                      disabled={isReadOnly}
                    />
                  </Tooltip>
                )}
                {
                  <Tooltip title="転送" mouseEnterDelay={0.5}>
                    <IconButton
                      color="sumi"
                      onClick={startForwarding}
                      component={MessageForward}
                      disabled={isReadOnly}
                    />
                  </Tooltip>
                }
                {message.emlStoragePath && (
                  <CmpDropdown
                    overlay={
                      <CmpMenu>
                        <Menu.Item
                          key="1"
                          data-testid="download-email"
                          onClick={this.downloadEml}
                        >
                          ダウンロードする
                        </Menu.Item>
                        <Menu.Item
                          key="2"
                          onClick={this.openFilterModal}
                          disabled={isReadOnly}
                          data-testid="create-flow"
                        >
                          フローを作成
                        </Menu.Item>
                        <Menu.Item
                          data-testid="print"
                          key="3"
                          onClick={this.openPrint}
                        >
                          印刷する
                        </Menu.Item>
                        {message.deleted ? (
                          <Menu.Item
                            key="4"
                            onClick={() => restoreMessage(message)}
                            disabled={isReadOnly}
                            data-testid="undo"
                          >
                            元に戻す
                          </Menu.Item>
                        ) : (
                          <Menu.Item
                            key="4"
                            onClick={() => markMessageAsDeleted(message)}
                            disabled={isReadOnly || locked}
                            data-testid="move-to-trash"
                          >
                            <CommonTooltip
                              title="返信中は削除できません"
                              visible={locked}
                            >
                              ゴミ箱に移動する
                            </CommonTooltip>
                          </Menu.Item>
                        )}
                        {message.deleted && (
                          <Menu.Item
                            key="5"
                            onClick={() => deleteMessage(message)}
                            disabled={locked}
                            data-tesid="delete-permanent"
                          >
                            <CommonTooltip
                              title="返信中は削除できません"
                              visible={locked}
                            >
                              完全に削除する
                            </CommonTooltip>
                          </Menu.Item>
                        )}
                      </CmpMenu>
                    }
                    trigger={['click']}
                  >
                    <Tooltip title="その他" mouseEnterDelay={0.5}>
                      <ExIcon
                        component={OptionsIcon}
                        aria-label="メッセージメニュー"
                      />
                    </Tooltip>
                  </CmpDropdown>
                )}
              </div>
            </div>
          </div>
        </PCOnly>

        {collapsed && (
          <>
            <Attachments attachments={message.attachments} />
            <div className="pt-3 lg:p-0"></div>
          </>
        )}

        {this.state.renderModal && (
          <FilterModal
            store={this.props.store}
            message={message}
            visible={this.state.modalVisible}
            onCancel={() => this.setState({ modalVisible: false })}
            afterClose={() => this.setState({ renderModal: false })}
          />
        )}
      </div>
    );
  }
}

export const MessageHeader = compose<InjectedProps, MessageHeaderProps>(
  observer
)(Index);

const ButtonIcon = styled(CmpIcon)`
  cursor: pointer;
  font-size: 14px;
  padding: 8px;
  border-radius: 50%;

  :hover {
    background-color: #f0f0f1;
  }
`;

const ExIcon = styled(ButtonIcon)`
  :hover {
    path,
    circle {
      fill: rgb(56 56 56) !important;
    }
  }
`;
