import { TagListNode } from './LeftSidebarTagList';
import { observer } from 'mobx-react';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import firebase, { db } from '../../../../firebase';
import { LeftSidebarTagListItem } from './LeftSidebarTagListItem';
import { useStore } from '../../../../hooks/useStore';

type Props = {
  nodes: TagListNode[];
  level: number;
};

const INDICATOR_PADDING = 12;

export const LeftSidebarInnerTagList = observer(
  ({ nodes, level }: Props): JSX.Element => {
    const store = useStore();
    const [dragging, setDragging] = useState(false);
    const dragIndexRef = useRef<number | null>(null);
    const dropIndicatorRef = useRef<HTMLDivElement | null | undefined>(null);
    const dropIndexRef = useRef<number | undefined>(undefined);

    const handleDragOverDocument = useCallback((e: MouseEvent) => {
      e.preventDefault();
    }, []);

    const onDragStart = useCallback(
      (index: number, event: React.DragEvent) => {
        event.stopPropagation();
        const { x, y } = event.currentTarget.getBoundingClientRect();
        event.dataTransfer.setDragImage(
          event.currentTarget,
          event.pageX - x,
          event.pageY - y
        );
        document.addEventListener('dragover', handleDragOverDocument);
        setDragging(true);
        dragIndexRef.current = index;
      },
      [handleDragOverDocument]
    );

    const onDragOver = useCallback(
      (index: number, event: React.DragEvent) => {
        if (!dragging) {
          return;
        }
        event.preventDefault();
        const el = event.currentTarget as HTMLElement;
        const rect = el.getBoundingClientRect();
        let offset = rect.top;
        dropIndexRef.current = index;
        if (event.pageY > rect.y + rect.height / 2) {
          offset += rect.height;
          dropIndexRef.current++;
        }
        if (dropIndicatorRef.current) {
          dropIndicatorRef.current.style.display = 'block';
          dropIndicatorRef.current.style.left = `${
            rect.left + INDICATOR_PADDING
          }px`;
          dropIndicatorRef.current.style.width = `${
            rect.width - INDICATOR_PADDING * 2
          }px`;
          dropIndicatorRef.current.style.transform = `translateY(${offset}px)`;
        }
      },
      [dragging]
    );

    const onDragEnd = useCallback(
      async (event: React.DragEvent) => {
        if (dropIndicatorRef.current) {
          dropIndicatorRef.current.style.display = 'none';
        }

        if (
          dragging &&
          ['move', 'copy'].includes(event.dataTransfer.dropEffect)
        ) {
          const dragIndex = dragIndexRef.current;
          const dropIndex = dropIndexRef.current;
          if (dragIndex == null || dropIndex == null) {
            return;
          }
          const draggingNode = nodes[dragIndex];
          const newNodes = [...nodes];
          newNodes.splice(dropIndex, 0, draggingNode);
          newNodes.splice(dragIndex < dropIndex ? dragIndex : dragIndex + 1, 1);
          const batch = db.batch();
          const preferencesRef = store
            .companyCollection(`users/${store.me.id}/private`)
            .doc('preferences');
          const preferences = await preferencesRef.get();
          if (!preferences.exists) {
            preferencesRef.set({
              updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
            });
          }
          newNodes.forEach(({ tag }, index) => {
            batch.update(preferencesRef, {
              [`tagIndexes.${tag.id}`]: index,
            });
          });
          batch.update(preferencesRef, {
            updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          });
          await batch.commit();
        }

        document.removeEventListener('dragover', handleDragOverDocument);
        setDragging(false);
      },
      [dragging, handleDragOverDocument, nodes, store.me.ref]
    );

    const list = useMemo(
      () =>
        nodes.map((node, index) => (
          <div
            key={node.id}
            draggable={!store.me.isReadOnly}
            onDragStart={(e) => onDragStart(index, e)}
            onDragOver={(e) => onDragOver(index, e)}
            onDragEnd={onDragEnd}
          >
            <LeftSidebarTagListItem node={node} level={level} />
          </div>
        )),
      [onDragStart, onDragOver, onDragEnd, nodes, level]
    );

    return (
      <div>
        {list}
        {dragging && (
          <div
            className={'fixed top-0 z-10 h-[2px] bg-forest-400'}
            style={{ display: 'none' }}
            ref={(el) => (dropIndicatorRef.current = el)}
          />
        )}
      </div>
    );
  }
);
