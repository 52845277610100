import { estimateServerTimestampsOption } from './common';
import {
  Timestamp,
  DocumentReference,
  DocumentSnapshot,
} from 'firebase/firestore';
import { UserData } from './user';

// Chatwork APIのルーム一覧のレスポンス
// https://developer.chatwork.com/reference/get-rooms
export type ChatworkRoom = {
  room_id: number;
  name: string;
  type: 'my' | 'direct' | 'group';
  role: 'admin' | 'member' | 'readonly';
  last_update_time: number;
};

export interface ChatworkIntegrationData {
  authedUser: { id: string; name: string };
  integratedUserId: string;
  integratedUserRef: DocumentReference<UserData>;
  scope: string;
  createdAt: Timestamp;
  updatedAt: Timestamp;
}

export class ChatworkIntegration {
  private _id: string;
  private _data: ChatworkIntegrationData;
  private _ref: DocumentReference<ChatworkIntegrationData>;

  constructor(doc: DocumentSnapshot<ChatworkIntegrationData>) {
    this._id = doc.id;
    const data = doc.data(estimateServerTimestampsOption);
    if (!data) {
      throw new Error(`no data for slackIntegration#${doc.id}`);
    }
    this._data = data;
    this._ref = doc.ref;
  }

  get id() {
    return this._id;
  }

  get ref() {
    return this._ref;
  }

  get authedUser() {
    return this._data.authedUser;
  }

  get integratedUserId() {
    return this._data.integratedUserId;
  }

  get integratedUserRef() {
    return this._data.integratedUserRef;
  }

  get scope() {
    return this._data.scope;
  }

  get createdAt() {
    return this._data.createdAt;
  }

  get updatedAt() {
    return this._data.updatedAt;
  }
}
