import * as React from 'react';
import type { SVGProps } from 'react';
const SvgMessageArrowUp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M10 12.563a.611.611 0 0 1-.23-.042.634.634 0 0 1-.207-.146L5.436 8.25a.573.573 0 0 1-.156-.448.64.64 0 0 1 .177-.448c.14-.139.289-.198.448-.177.16.02.302.087.427.198L10 11.042l3.667-3.667a.68.68 0 0 1 .448-.188.493.493 0 0 1 .447.188.46.46 0 0 1 .178.438.811.811 0 0 1-.198.458l-4.104 4.104a.634.634 0 0 1-.209.146.612.612 0 0 1-.229.041Z"
    />
  </svg>
);
export default SvgMessageArrowUp;
