import * as React from 'react';
import type { SVGProps } from 'react';
const SvgInsights = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M2.748 20.25a1.68 1.68 0 0 1-1.236-.515A1.69 1.69 0 0 1 1 18.497c0-.482.17-.894.512-1.235a1.68 1.68 0 0 1 1.232-.513c.087 0 .173.004.256.013a2.4 2.4 0 0 1 .325.062l5-5a2.38 2.38 0 0 1-.063-.325 2.57 2.57 0 0 1-.012-.256c0-.48.172-.89.515-1.231a1.691 1.691 0 0 1 1.237-.513c.482 0 .894.172 1.236.516.341.344.512.757.512 1.24 0 .03-.025.22-.075.57l2.75 2.75a2.4 2.4 0 0 1 .325-.063 2.521 2.521 0 0 1 .5 0 2.4 2.4 0 0 1 .325.062l4-4a2.38 2.38 0 0 1-.063-.325 2.58 2.58 0 0 1-.012-.256c0-.48.172-.89.515-1.231a1.691 1.691 0 0 1 1.237-.513c.482 0 .894.172 1.236.515.341.343.512.756.512 1.237 0 .482-.17.894-.512 1.236a1.679 1.679 0 0 1-1.232.512 2.55 2.55 0 0 1-.256-.012 2.368 2.368 0 0 1-.325-.063l-4 4c.033.134.054.242.063.325.008.084.012.169.012.256 0 .48-.172.89-.515 1.232a1.692 1.692 0 0 1-1.237.512c-.482 0-.894-.17-1.236-.512a1.68 1.68 0 0 1-.512-1.232c0-.087.004-.172.012-.256.009-.083.03-.191.063-.325l-2.75-2.75a2.368 2.368 0 0 1-.325.063 2.553 2.553 0 0 1-.256.012c-.03 0-.219-.025-.569-.075l-5 5c.033.134.054.242.063.325.008.084.012.169.012.256 0 .48-.172.89-.515 1.232a1.691 1.691 0 0 1-1.237.512ZM4 9.2l-.506-1.094L2.4 7.599l1.094-.506L4 5.999l.506 1.094 1.094.506-1.094.507L4 9.199Zm11-1.276-.768-1.657-1.657-.768 1.657-.768L15 3.074l.768 1.657 1.657.768-1.657.768L15 7.924Z"
    />
  </svg>
);
export default SvgInsights;
