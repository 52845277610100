import { useStore } from 'hooks/useStore';
import React, { useEffect } from 'react';
import { BaseMessagePane } from './BaseMessagePane';
import { MessageListEmpty } from 'components/MessageList';
import { observer } from 'mobx-react';
import { Sent } from '../MessageList/Common/Sent';
import { useAtomValue } from 'jotai/index';
import { sentAtom, sentFilterAtom } from '../../../atoms/firestore/sent';
import { useSetAtom } from 'jotai';

export const PATH = '/me/sent';

// 自分 - 送信済み
export const MessagePaneMeSent: React.FC = observer(() => {
  const store = useStore();

  const { searching: searchingSent, sortedSent, inSearch } = store.searchStore;

  const sentPaginate = useAtomValue(sentAtom);
  const searchedSentList =
    !searchingSent && inSearch
      ? sortedSent
      : sentPaginate.state === 'hasData'
      ? sentPaginate.data
      : [];
  const isEmpty = searchedSentList.length === 0;

  const setSentFilter = useSetAtom(sentFilterAtom);
  useEffect(() => {
    setSentFilter({
      mySent: true,
    });
  }, []);

  const getEmptyDescription = () => {
    let description = '送信済みはありません';
    if (inSearch) {
      description = `検索条件と一致する` + description;
    }
    return description;
  };

  const generateLink = (sent: { id: string }) => `/me/sent/${sent.id}`;

  const loading = inSearch ? searchingSent : sentPaginate.state === 'loading';

  return (
    <BaseMessagePane
      listPagePath={PATH}
      empty={!loading && isEmpty}
      emptyComponent={<MessageListEmpty description={getEmptyDescription()} />}
      listComponent={<Sent generateLink={generateLink} />}
    />
  );
});
