import styled from 'styled-components';
import * as color from '../../../color';
import { Input } from 'antd';

const { TextArea } = Input;

export const DefaultInput = styled(Input)`
  &&& {
    font-size: 14px;
    padding: 0 15px;
    border-color: rgba(232, 233, 236, 1);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    outline: none;

    :focus,
    :hover {
      border-color: #609cfc;
    }

    ::placeholder {
      color: ${color.input.text.placeholder};
    }
  }
`;

export const DefaultTextarea = styled(TextArea)`
  font-size: 14px;
  padding: 10px 15px;
  border-color: rgba(232, 233, 236, 1);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  outline: none;

  ::placeholder {
    color: ${color.input.text.placeholder};
  }
`;
