import { FilterCondition } from '../FilterEditDrawer/FilterEditDrawer';
import { FilterCondition as LibFilterCondition } from 'lib';

/**
 * クライアント用のデータ形式からDB用のデータ形式に変換する
 */
export const convertToServerConditions = (
  conditions: FilterCondition[][]
): LibFilterCondition[] => {
  return conditions.reduce<LibFilterCondition[]>(
    (result, conds) =>
      result.concat(
        conds.map((c, i) => ({
          equalType: c.operator,
          filterText: c.filterText.trim(),
          caseSensitive: c.caseSensitive,
          id: c.id,
          type: c.target,
          isOr: i > 0,
        }))
      ),
    []
  );
};

/**
 * DB用のデータ形式からクライアント用のデータ形式に変換する
 */
export const convertToClientConditions = (
  conditions: LibFilterCondition[]
): FilterCondition[][] => {
  return conditions
    .reduce((tree: LibFilterCondition[][], c: LibFilterCondition) => {
      c.isOr ? tree[tree.length - 1].push(c) : tree.push([c]);
      return tree;
    }, [])
    .map((orConditions) =>
      orConditions.map((c) => {
        return {
          id: c.id,
          target: c.type,
          filterText: c.filterText,
          caseSensitive: c.caseSensitive ?? true,
          operator: c.equalType,
        } as FilterCondition;
      })
    );
};
