import styled from 'styled-components';
import * as color from '../../../color';
import level from '../../../font/level';

export const H3 = styled.h3`
  font-size: ${level[3]};
  font-weight: 600;
  color: ${color.common.text2};
  margin: 0;
`;
