import { Select } from '../../../../../components/forms';
import { ReactElement } from 'react';
import { InputGroup } from '../../../../../components/forms/InputGroup/InputGroup';

type User = {
  id: string;
  avatar: ReactElement;
  name: string;
};

type Props = {
  users: User[];
  currentUserId: string | undefined;
  onChangeUserId: (userId: string) => void;
  disabled?: boolean;
};

export const DrafterSelectField = ({
  users,
  currentUserId,
  onChangeUserId,
  disabled = false,
}: Props) => {
  return (
    <InputGroup
      label={<span className="text-base font-bold">自動返信時の送信者</span>}
    >
      <Select
        value={currentUserId ?? ''}
        onChange={onChangeUserId}
        options={users.map((user) => ({
          value: user.id,
          label: user.name,
        }))}
        className="h-10 w-full bg-white"
        disabled={disabled}
        variants={{ rounded: 'lg' }}
        placeholder="メンバーを選択してください"
        renderLabel={(v, l) => {
          const found = users.find((u) => u.id === v);
          if (!found) {
            return l;
          }
          return (
            <div className="grid grid-cols-[auto_1fr] items-center gap-2">
              <div className="h-[24px] w-[24px] overflow-hidden rounded-full">
                {found.avatar}
              </div>
              <span className="truncate whitespace-nowrap">{found.name}</span>
            </div>
          );
        }}
        renderOption={(v, l) => {
          const found = users.find((u) => u.id === v);
          if (!found) {
            return l;
          }
          return (
            <div className="grid grid-cols-[auto_1fr] items-center gap-2">
              <div className="h-[24px] w-[24px] overflow-hidden rounded-full">
                {found.avatar}
              </div>
              <span className="truncate whitespace-nowrap">{found.name}</span>
            </div>
          );
        }}
      />
    </InputGroup>
  );
};
