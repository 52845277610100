import {
  Button,
  Form,
  Icon,
  Input,
  Modal,
  Radio,
  Spin,
  Switch,
  TimePicker,
} from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import { InboxAutoReply } from 'lib';
import moment, { Moment } from 'moment';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

type Errors = {
  timeRange: string | null;
  messageBody: string | null;
};

const DEFAULT_AUTO_REPLY_SETTINGS: InboxAutoReply = {
  enabled: false,
  allDay: true,
  endTime: null,
  startTime: null,
  messageBody: '',
};

const EMPTY_ERRORS: Errors = {
  timeRange: null,
  messageBody: null,
};

type Props = {
  visible?: boolean;
  title?: string;
  settings?: InboxAutoReply;
  onClose?: () => void;
  onSave?: (settings: InboxAutoReply) => Promise<any>;
};

export const AutoReplyEditModal = (props: Props): React.ReactElement => {
  const [origSettings, setOrigSettings] = useState<InboxAutoReply>({
    ...DEFAULT_AUTO_REPLY_SETTINGS,
  });

  const [settings, setSettings] = useState<InboxAutoReply>({
    ...DEFAULT_AUTO_REPLY_SETTINGS,
  });

  const [errors, setErrors] = useState<Errors>({ ...EMPTY_ERRORS });

  const [saving, setSaving] = useState<boolean>(false);

  useEffect(() => {
    if (props.settings) {
      setOrigSettings(props.settings);
      setSettings(props.settings);
    }
  }, [props.settings]);

  const timeEnabled = !!settings.enabled;
  const timeRangeEnabled = timeEnabled && !settings.allDay;
  const messageBodyEnabled = !!settings.enabled;

  const handleChangeEnabled = (value: boolean) => {
    if (!value) {
      setErrors({ ...EMPTY_ERRORS });
    }

    setSettings({
      ...settings,
      enabled: value,
    });
  };

  const handleChangeAllDay = (event: RadioChangeEvent) => {
    if (event.target.value && errors.timeRange !== null) {
      setErrors({
        ...errors,
        timeRange: null,
      });
    }

    setSettings({
      ...settings,
      allDay: event.target.value,
    });
  };

  const handleChangeStartTime = (value: Moment | null) => {
    setSettings({
      ...settings,
      startTime: value?.format('HH:mm') ?? null,
    });
  };

  const handleChangeEndTime = (value: Moment | null) => {
    setSettings({
      ...settings,
      endTime: value?.format('HH:mm') ?? null,
    });
  };

  /**
   * @params {{target:{value: string}}} e
   */
  const handleChangeMessageBody = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setSettings({
      ...settings,
      messageBody: e.target.value,
    });
  };

  const validate = () => {
    let hasError = false;
    const _errors = { ...EMPTY_ERRORS };

    if (timeRangeEnabled) {
      if (!settings.startTime || settings.startTime === '') {
        _errors.timeRange = '開始時刻を入力してください';
        hasError = true;
      } else if (!settings.endTime || settings.endTime === '') {
        _errors.timeRange = '終了時刻を入力してください';
        hasError = true;
      }
    }

    if (messageBodyEnabled) {
      if (!settings.messageBody || settings.messageBody === '') {
        _errors.messageBody = 'メッセージ本文を入力してください';
        hasError = true;
      }
    }

    setErrors(_errors);
    return !hasError;
  };

  const handleSave = async () => {
    const isValid = validate();
    if (isValid && props.onSave) {
      setSaving(true);
      await props.onSave(settings);
      setSaving(false);
    }
  };

  const handleCancel = () => {
    setSettings({
      ...origSettings,
    });
    props.onClose && props.onClose();
  };

  const startTimeValue = settings.startTime
    ? moment(settings.startTime, 'HH:mm')
    : null;

  const endTimeValue = settings.endTime
    ? moment(settings.endTime, 'HH:mm')
    : null;

  const footer = (
    <FooterWrapper>
      <Button disabled={saving} onClick={handleCancel}>
        キャンセル
      </Button>
      <Button disabled={saving} onClick={handleSave} type="primary">
        {saving ? (
          <Spin
            size="small"
            indicator={<Icon type="loading" spin style={{ color: 'white' }} />}
          />
        ) : (
          '保存'
        )}
      </Button>
    </FooterWrapper>
  );

  return (
    <Modal
      closable={false}
      title={props.title}
      visible={props.visible}
      footer={footer}
    >
      <FormWrapper>
        <Form layout="vertical">
          <Form.Item label="自動返信を有効にする">
            <Switch
              checked={settings.enabled ?? false}
              onChange={handleChangeEnabled}
            ></Switch>
          </Form.Item>
          <Form.Item label="時間帯" className="form-item time">
            <Radio.Group
              value={settings.allDay ?? true}
              onChange={handleChangeAllDay}
              disabled={!timeEnabled}
            >
              <Radio className="radio" value={true}>
                すべての時間帯で自動返信する
              </Radio>
              <Radio className="radio" value={false}>
                指定した時間帯に自動返信する
              </Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            className="form-item time-range"
            validateStatus={errors.timeRange !== null ? 'error' : undefined}
            help={errors.timeRange}
          >
            <TimePicker
              placeholder="開始時刻"
              format="HH:mm"
              value={startTimeValue ?? undefined}
              onChange={handleChangeStartTime}
              disabled={!timeRangeEnabled}
            />
            <div className="wave-dash">〜</div>
            <TimePicker
              placeholder="終了時刻"
              format="HH:mm"
              value={endTimeValue ?? undefined}
              onChange={handleChangeEndTime}
              disabled={!timeRangeEnabled}
            />
          </Form.Item>
          <Form.Item
            label="メッセージ本文"
            validateStatus={errors.messageBody !== null ? 'error' : undefined}
            help={errors.messageBody}
          >
            <Input.TextArea
              rows={8}
              value={settings.messageBody ?? undefined}
              onChange={handleChangeMessageBody}
              disabled={!messageBodyEnabled}
            ></Input.TextArea>
          </Form.Item>
        </Form>
      </FormWrapper>
    </Modal>
  );
};

export default AutoReplyEditModal;

const FooterWrapper = styled.div`
  display: flex;
  justify-content: center;
  & > * {
    width: 120px;
  }
`;

const FormWrapper = styled.div`
  margin-top: 16px;

  & .form-item.time {
    margin-bottom: 8px;
    & .radio {
      display: block;
      margin-top: 10px;
      &:first-of-type {
        margin-top: 2px;
      }
    }
  }

  & .form-item.time-range {
    & .ant-form-item-children {
      display: flex;
      align-items: center;

      & .wave-dash {
        margin-left: 8px;
        margin-right: 8px;
        color: #aaa;
      }
    }
  }
`;
