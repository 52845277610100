import { InboxType } from 'lib';
import { Button, Icon } from '../../../../../components/basics';
import { Gmail, Microsoft } from '../../../../../components/icons';
import React from 'react';

type Props = {
  type: Omit<InboxType, 'email'> | 'all';
  onClick: (type: Omit<InboxType, 'email'>) => void;
  readonly: boolean;
};

export const InboxOAuthButtons = ({ type, onClick, readonly }: Props) => {
  return (
    <div className="flex gap-4">
      {(type === 'all' || type === 'outlook') && (
        <Button
          onClick={() => onClick('outlook')}
          variant="outlined"
          className="flex w-full items-center justify-center gap-2"
          disabled={readonly}
        >
          <Icon icon={Microsoft} size="1.1em" />
          <span>Microsoft Outlook</span>
        </Button>
      )}
      {(type === 'all' || type === 'google') && (
        <Button
          onClick={() => onClick('google')}
          variant="outlined"
          className="flex w-full items-center justify-center"
          disabled={readonly}
        >
          <Icon icon={Gmail} size="1.75em" />
          <span>Gmail</span>
        </Button>
      )}
    </div>
  );
};
