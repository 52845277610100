import { LeftSidebarButton } from './button/LeftSidebarButton';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { LeftSidebarTeam } from './LeftSidebarTeam';
import { ChatCreateDialogWithLogic } from '../../../components/chat/ChatSettingsDialog/ChatCreateDialogWithLogic';
import { PCOrTabletOnly, TabletOrSPOnly } from '../../Common/MediaQuery';
import { useStore } from '../../../hooks/useStore';
import { Icon } from '../../../components/basics';
import { LeftSidebarChatRooms } from './LeftSidebarChatRooms';
import CreateDraftButton from './CreateDraftButton';
import {
  At,
  CalendarClock,
  Chat,
  Edit,
  Logout,
  Person,
  Send,
  ServiceLogo,
  Star,
} from '../../../components/icons';
import { LeftSidebarLink } from './button/LeftSidebarLink';
import { SidebarBase } from '../../../components/sidebar/SidebarBase/SidebarBase';
import { useLogout } from '../../../hooks/useLogout';
import { useConfirmDialog } from '../../../hooks/confirmDialog';
import { useAtomValue } from 'jotai';
import { mentionedCountAtom } from '../../../atoms/firestore/count/mentioned';
import { assignedCountAtom } from '../../../atoms/firestore/count/assigned';
import {
  failedScheduledDraftCountAtom,
  scheduledDraftsAtom,
} from '../../../atoms/firestore/draft';
import { signInCompanyStripeProductAtom } from '../../../atoms/firestore/signInCompanyStripeProduct';

export const LeftSidebar = observer((): JSX.Element => {
  const store = useStore();
  const isReadOnly = store.me.isReadOnly;
  const [memberModalOpen, setMemberModalOpen] = useState(false);

  const scheduledDraftsPaginate = useAtomValue(scheduledDraftsAtom);
  const failedScheduledDraftCount = useAtomValue(failedScheduledDraftCountAtom);
  const mentionedCount = useAtomValue(mentionedCountAtom);
  const assignedCount = useAtomValue(assignedCountAtom);
  const product = useAtomValue(signInCompanyStripeProductAtom);

  const logout = useLogout();
  const openDialog = useConfirmDialog();
  const openLogoutDialog = () => {
    openDialog({
      title: 'ログアウトしますか？',
      okText: 'ログアウト',
      onOk: async () => await logout(),
    });
  };

  const teams = !store.tagsLoading
    ? store.joinedTeamsWithInbox.map((t) => (
        <LeftSidebarTeam key={t.id} team={t} />
      ))
    : [];
  return (
    <SidebarBase>
      <TabletOrSPOnly>
        <div className="pb-5 pt-5 text-sea-500 sm:pb-0 lg:pb-5">
          <Icon icon={ServiceLogo} width={40} height="auto" className="block" />
        </div>
      </TabletOrSPOnly>
      <PCOrTabletOnly>
        <div className="sticky top-0 z-10 bg-white py-4">
          <CreateDraftButton shadow={false} />
        </div>
      </PCOrTabletOnly>
      <LeftSidebarLink
        icon={Edit}
        label={'下書き'}
        to={'/me/drafts'}
        disabled={isReadOnly}
      />
      {scheduledDraftsPaginate.state === 'hasData' &&
        !!scheduledDraftsPaginate.data.length && (
          <LeftSidebarLink
            icon={CalendarClock}
            label={'予約'}
            to={'/me/scheduled'}
            count={failedScheduledDraftCount}
            disabled={isReadOnly}
          />
        )}
      <LeftSidebarLink
        icon={Send}
        label={'送信済み'}
        to={'/me/sent'}
        disabled={isReadOnly}
      />
      <LeftSidebarLink icon={Chat} label={'コメント'} to={'/me/commented'} />
      <LeftSidebarLink
        icon={At}
        label={'メンション'}
        to={'/me/mentioned'}
        count={mentionedCount}
      />
      <LeftSidebarLink
        icon={Person}
        label={'自分が担当'}
        to={'/me/assigned/messages'}
        count={assignedCount}
        active={{ type: 'parentPath', path: ['/me/assigned/'] }}
        disabled={isReadOnly}
      />
      <LeftSidebarLink
        icon={Star}
        label={'お気に入り'}
        to={'/me/starred'}
        disabled={isReadOnly}
      />
      <div className={'pb-4'} />
      {teams}

      <div className={'pb-4'} />
      <LeftSidebarChatRooms
        onNewRoom={() => setMemberModalOpen(true)}
        supported={product?.groupChatSupported === true}
      />

      <TabletOrSPOnly>
        <div className={'mt-auto pb-2 pt-6'}>
          <LeftSidebarButton
            icon={Logout}
            label={'ログアウト'}
            onClick={openLogoutDialog}
          />
        </div>
      </TabletOrSPOnly>

      {/* チャット新規作成モーダル */}
      <ChatCreateDialogWithLogic
        open={memberModalOpen}
        onOpenChange={setMemberModalOpen}
      />
    </SidebarBase>
  );
});
