import { useRouteMatch } from 'react-router-dom';
import { MessageItemWrapper } from 'components/MessageList';
import styled from 'styled-components';
import level from '../../../../../../font/level';
import moment from 'moment';

export default function Draft({ draft, to, toFunc }) {
  let match = useRouteMatch({
    path: to,
  });

  return (
    <MessageItemWrapper onClick={() => toFunc(to)} selected={!!match}>
      <div className="pl-5">
        <Header>
          <Sender>
            {draft.to.length > 0 ? draft.to.join(' ') : '（宛先がありません）'}
          </Sender>
          <SentTime>
            {moment(draft.date).isSame(moment(), 'day')
              ? draft.date.format('HH:mm')
              : draft.date.format('M月D日')}
          </SentTime>
        </Header>
        <Title>
          {draft.subject.length > 0 ? draft.subject : '（件名がありません）'}
        </Title>
        <Body>
          {draft.body.length > 0 ? draft.body : '（本文がありません）'}
        </Body>
      </div>
    </MessageItemWrapper>
  );
}

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Sender = styled.div`
  padding-right: 8px;
  font-size: ${level[1]};
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const SentTime = styled.div`
  font-size: ${level[0]};
  color: #bcbccb;
  white-space: nowrap;
`;

const Title = styled.div`
  font-size: ${level[1]};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Body = styled.div`
  font-size: ${level[1]};
  color: #bcbccb;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
