import React from 'react';
import { Popover, Table } from 'antd';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { isSP } from 'shared/util';
import './table.css';
import { PCOnly } from '../../../Common/MediaQuery';

export default (props) => {
  return (
    <ExTable
      size="small"
      columns={generateColumns(props.parseReservedWords, props.privateTeamId)}
      dataSource={props.dataSource}
      rowKey="id"
      pagination={{ defaultPageSize: 15 }}
      scroll={{ x: true }}
      onRow={(record) => ({
        onClick: () => {
          props.onClickRow(record);
        },
      })}
    />
  );
};

const generateColumns = (parseReservedWords, privateTeamId) => [
  {
    title: 'テンプレート名',
    dataIndex: 'title',
    defaultSortOrder: 'ascend',
    width: isSP() ? 120 : 200,
    ellipsis: false,
    fixed: 'left',
    sorter: (x, y) => x.title.localeCompare(y.title, 'ja'),
    render: (title) => (
      <Popover placement="topLeft" content={title}>
        <ExSpan>{title}</ExSpan>
      </Popover>
    ),
  },
  {
    title: '件名',
    dataIndex: 'subject',
    width: isSP() ? 130 : 140,
    ellipsis: false,
    sorter: (x, y) => x.subject.localeCompare(y.subject, 'ja'),
    render: (subject) => (
      <Popover placement="topLeft" content={subject}>
        <ExSpan>{subject}</ExSpan>
      </Popover>
    ),
  },
  {
    title: '本文',
    dataIndex: 'body',
    width: 140,
    ellipsis: true,
    sorter: (x, y) => x.body.localeCompare(y.body, 'ja'),
    render: (body) => {
      const value = parseReservedWords(body);
      return (
        <Popover
          placement="topLeft"
          content={<pre style={{ margin: 0 }}>{value}</pre>}
        >
          <ExSpan>{value}</ExSpan>
        </Popover>
      );
    },
  },
  {
    title: '添付',
    dataIndex: 'attachments',
    ellipsis: true,
    width: 140,
    render: (attachments) => {
      const value = attachments.map((attachment) => attachment.name).join(',');
      return (
        <Popover placement="topLeft" content={value}>
          <ExSpan>{value}</ExSpan>
        </Popover>
      );
    },
  },
  {
    title: 'To',
    dataIndex: 'to',
    width: 140,
    ellipsis: true,
    sorter: (x, y) => x.to.localeCompare(y.to, 'ja'),
    render: (to) => (
      <Popover placement="topLeft" content={to}>
        <ExSpan>{to}</ExSpan>
      </Popover>
    ),
  },
  {
    title: 'Cc',
    dataIndex: 'cc',
    width: 140,
    ellipsis: true,
    sorter: (x, y) => x.cc.localeCompare(y.cc, 'ja'),
    render: (cc) => (
      <Popover placement="topLeft" content={cc}>
        <ExSpan>{cc}</ExSpan>
      </Popover>
    ),
  },
  {
    title: 'Bcc',
    dataIndex: 'bcc',
    width: 140,
    ellipsis: true,
    sorter: (x, y) => x.bcc.localeCompare(y.bcc, 'ja'),
    render: (bcc) => (
      <Popover placement="topLeft" content={bcc}>
        <ExSpan>{bcc}</ExSpan>
      </Popover>
    ),
  },
  {
    title: '',
    key: 'action',
    width: 40,
    className: 'yaritori-template-table-row-cell-link',
    render: (text, record) => (
      <PCOnly>
        <ExLink
          to={
            privateTeamId === record.teamId
              ? `/settings/teams/${record.teamId}/me/mail/templates/${record.id}`
              : `/settings/teams/${record.teamId}/mail/templates/${record.id}`
          }
          onClick={(e) => {
            // onClickRowを発火しない
            e.stopPropagation();
          }}
        >
          編集
        </ExLink>
      </PCOnly>
    ),
  },
];

const ExTable = styled(Table)`
  td {
    cursor: pointer;
    max-width: 40px;
  }
  tr {
    white-space: nowrap;
  }
`;

const ExSpan = styled.span`
  height: 100%;
  width: 100%;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const ExLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: absolute; // 「編集」のクリック領域を広げるため
  top: 0;
`;
