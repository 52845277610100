import React, { ReactNode } from 'react';
import { MainNav } from './MainNav';
import { observer } from 'mobx-react';
import { isSP } from '../../../shared/util';
import { MobileNav } from './MobileNav';

type Props = {
  children?: ReactNode;
};

export const PageWithMainNav: React.FC<Props> = observer(
  (props): JSX.Element => {
    return (
      <div className="grid h-[100dvh] grid-rows-[theme(height.header)_1fr]">
        <div className="relative z-20 h-header">
          {isSP() ? <MobileNav /> : <MainNav />}
        </div>
        <div className="h-[calc(100dvh_-_theme(height.header))] max-w-[100dvw]">
          {props.children}
        </div>
      </div>
    );
  }
);
