import React from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import { ContactInfo } from './ContactInfo';
import * as color from '../../../color';
import { ReactComponent as CrossIcon } from '../../../assets/icons/cross.svg';
import { Message } from '../MessageList/Teams/Messages/Message';
import { Icon, Spin, Tabs } from 'antd';
import { messageConverter } from 'lib';
import { eventNames, logEvent } from '../../../analytics';
import { SalesforceContact } from './SalesforceContact';
import { limit, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import { companyCollection } from '../../../firestore';

const isDomainDisabled = (domain) =>
  [
    'yahoo.co.jp',
    'gmail.com',
    'ezweb.ne.jp',
    'au.com',
    'docomo.ne.jp',
    'i.softbank.jp',
    'softbank.ne.jp',
    'excite.co.jp',
    'googlemail.com',
    'hotmail.co.jp',
    'hotmail.com',
    'icloud.com',
    'live.jp',
    'me.com',
    'mineo.jp',
    'nifty.com',
    'outlook.com',
    'outlook.jp',
    'yahoo.ne.jp',
    'ybb.ne.jp',
    'ymobile.ne.jp',
  ].includes(domain);

class RightSidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contact: null,
      messages: [],
      messagesLoading: true,
      domainMessages: [],
      domainMessagesLoading: true,
      tab: 'local',
      view: 'contact',
    };
  }

  componentWillUnmount() {
    if (this._unsubscribeSyncMessage) this._unsubscribeSyncMessage();
  }

  componentDidMount() {
    this.syncMessages(this.props.contact);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.contact === this.props.contact) {
      return;
    }

    if (!this.props.contact) {
      return;
    }
    this.setState({ tab: 'local' });
    this.syncMessages(this.props.contact);
  }

  get contactDomain() {
    return this.props.contact.email.split('@')[1];
  }

  setTab = async (tab) => {
    this.setState({ tab });
    if (tab === 'domain') {
      this.setState({ domainMessagesLoading: true, domainMessages: [] });
      const domainMessages =
        await this.props.store.searchStore.searchMessagesByDomain(
          this.props.contact.teamId,
          this.contactDomain
        );
      this.setState({ domainMessagesLoading: false, domainMessages });
    }
  };

  syncMessages = async (contact) => {
    if (this._unsubscribeSyncMessage) this._unsubscribeSyncMessage();

    this.setState({ messagesLoading: true, messages: [] });
    this._unsubscribeSyncMessage = onSnapshot(
      query(
        companyCollection('messages', messageConverter),
        where('teamId', '==', contact.teamId),
        where('referenceAddresses', 'array-contains', contact.email),
        orderBy('date', 'desc'),
        limit(300)
      ),
      async (querySnapShot) => {
        let messages = [];
        querySnapShot.forEach((doc) => messages.push(doc.data()));
        this.setState({ messagesLoading: false, messages });
      },
      (err) => {
        console.error('RightSidebar.syncMessages:', err);
      }
    );
  };

  closeContact = () => {
    this.props.store.contactStore.selectedContact = null;
    logEvent(eventNames.hide_message_histories);
  };

  open = async (message) => {
    logEvent(eventNames.open_message_on_new_tab);
    const messageId = this.props.store.isInThreadView
      ? message.threadId
      : message.id;
    let url = `/teams/${message.teamId}/messages/${messageId}`;
    if (this.props.store.preferences.threadView) {
      url += '?view=thread';
    }
    window.open(url, '_blank', '');
  };

  setView = (view) => {
    this.setState({ view });
  };

  getViewStyle = (view) => {
    if (view === this.state.view) {
      return {
        fontWeight: 600,
      };
    }
    return {
      cursor: 'pointer',
      color: 'rgba(0, 0, 0, 0.65)',
    };
  };

  render() {
    const {
      view,
      messagesLoading,
      messages,
      domainMessagesLoading,
      domainMessages,
    } = this.state;
    const { store, contact } = this.props;

    return (
      <Right>
        <div>
          {/* コンタクト選択 */}
          <ContactSelect>
            <div
              style={{ display: 'flex', alignItems: 'center', gap: '0.75rem' }}
            >
              <span
                style={this.getViewStyle('contact')}
                onClick={() => this.setView('contact')}
              >
                コンタクト
              </span>
              {store.isCRMIntegrationSupported &&
                store.oauthStore.salesforceIntegrated && (
                  <span
                    style={this.getViewStyle('salesforce')}
                    onClick={() => this.setView('salesforce')}
                  >
                    Salesforce
                  </span>
                )}
            </div>
            <ExCrossIcon onClick={this.closeContact} />
          </ContactSelect>

          <ContactInfo contact={contact} view={view} />
        </div>

        {view === 'contact' ? (
          <StyledTabs activeKey={this.state.tab} onChange={this.setTab}>
            <Tabs.TabPane
              tab={
                store.contactStore.selectedContact?.name ||
                contact.name ||
                contact.email.split('@')[0]
              }
              key="local"
            >
              {messagesLoading && (
                <SpinWrapper>
                  <Spin
                    indicator={
                      <Icon type="loading" style={{ fontSize: 24 }} spin />
                    }
                  />
                </SpinWrapper>
              )}

              {!messagesLoading && messages.length === 0 && (
                <NoHistory>履歴が存在しません</NoHistory>
              )}
              {messages.map((message) => (
                <Message
                  message={message}
                  checkedMessages={[]}
                  onClick={() => this.open(message)}
                  key={message.id}
                  me={this.props.store.me}
                  getTag={this.props.store.getTag}
                  checkDisabled
                  showOpenInTab
                  isReadOnly={this.props.store.me.isReadOnly}
                />
              ))}
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={'@' + this.contactDomain}
              key="domain"
              disabled={isDomainDisabled(this.contactDomain)}
            >
              {domainMessagesLoading && (
                <SpinWrapper>
                  <Spin
                    indicator={
                      <Icon type="loading" style={{ fontSize: 24 }} spin />
                    }
                  />
                </SpinWrapper>
              )}

              {!domainMessagesLoading && domainMessages.length === 0 && (
                <NoHistory>履歴が存在しません</NoHistory>
              )}
              {domainMessages.map((message) => (
                <Message
                  message={message}
                  checkedMessages={[]}
                  onClick={() => this.open(message)}
                  key={message.id}
                  me={this.props.store.me}
                  getTag={this.props.store.getTag}
                  checkDisabled
                  showOpenInTab
                />
              ))}
            </Tabs.TabPane>
          </StyledTabs>
        ) : (
          <SalesforceContact />
        )}
      </Right>
    );
  }
}

export default compose(withRouter, inject('store'), observer)(RightSidebar);

const Right = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 300px;
  background-color: ${color.common.white};
  border-left: solid 0.5px #f1f1f3;
`;

const ContactSelect = styled.div`
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 0.5px #f1f1f3;
`;

const ExCrossIcon = styled(CrossIcon)`
  margin-right: 6px;
  width: 24px;
  height: 24px;
  padding: 6px;
  border-radius: 4px;
  cursor: pointer;

  :hover {
    background-color: #f0f0f1;
  }
`;

const NoHistory = styled.div`
  padding: 8px 16px;
  color: #a7a7aa;
`;

const SpinWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 48px;
`;

const StyledTabs = styled(Tabs)`
  .ant-tabs-bar {
    margin: 0 1.25rem;
  }

  .ant-tabs-nav {
    width: 100%;
  }

  .ant-tabs-tab {
    width: 50%;
    margin: 0;
    text-align: center;
  }

  .ant-tabs-ink-bar {
    width: 50%;
  }

  .ant-tabs-content {
    height: 100%;
    padding-bottom: 3rem;
  }

  .ant-tabs-tabpane {
    overflow: auto;
  }
`;
