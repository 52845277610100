import { twMerge } from 'tailwind-merge';
import styles from './Radio.module.css';
import { ComponentPropsWithoutRef, useMemo } from 'react';

type Props = Omit<
  ComponentPropsWithoutRef<'input'>,
  'type' | 'onChange' | 'className'
> & {
  label?: string;
  onCheck?: () => void;
};

export const Radio = ({
  id: externalId,
  label,
  onCheck,
  checked,
  disabled,
  ...props
}: Props) => {
  const randomId = useMemo(
    () => 'radio' + Math.floor(Math.random() * 1000),
    []
  );
  const id = !!externalId ? externalId : randomId;
  return (
    <div className="flex items-center gap-2">
      <div className="relative h-5 w-5">
        <input
          id={id}
          className={twMerge(
            'h-[20px] w-[20px] cursor-pointer appearance-none rounded-full border-2 border-sumi-900 transition-[border-color] duration-100 checked:border-sea-500 disabled:cursor-not-allowed disabled:border-sumi-400 disabled:bg-sumi-100',
            styles.radio
          )}
          type="radio"
          checked={checked}
          disabled={disabled}
          onChange={() => onCheck?.()}
          data-testid="radio-input"
          {...props}
        />
        <div
          className={twMerge(
            'pointer-events-none absolute left-[5px] top-[5px] h-[10px] w-[10px] rounded-full transition-[background-color] duration-100',
            styles.checkedIndicator
          )}
        />
      </div>
      {label && (
        <label
          className="select-none text-sm"
          htmlFor={id}
          data-testid="radio-label"
        >
          {label}
        </label>
      )}
    </div>
  );
};
