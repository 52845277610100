import * as React from 'react';
import type { SVGProps } from 'react';
const SvgTag = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="m20.4 12.875-3.625 5.1c-.217.3-.475.546-.775.737-.3.192-.633.288-1 .288H4.5c-.412 0-.766-.147-1.06-.44A1.445 1.445 0 0 1 3 17.5v-11c0-.412.147-.766.44-1.06.294-.293.648-.44 1.06-.44H15c.367 0 .7.096 1 .287.3.192.558.438.775.738l3.625 5.1c.183.266.275.558.275.875 0 .316-.092.608-.275.875ZM15.275 17.5l3.85-5.5-3.85-5.5H4.5v11h10.775Z"
    />
  </svg>
);
export default SvgTag;
