import * as React from 'react';
import type { SVGProps } from 'react';
const SvgMessageReply = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    viewBox="0 0 18 18"
    {...props}
  >
    <path
      fill="currentColor"
      d="M4.425 8.738 7.087 11.4a.508.508 0 0 1 .16.394.575.575 0 0 1-.178.393.55.55 0 0 1-.403.17.55.55 0 0 1-.404-.17L2.644 8.57a.54.54 0 0 1-.169-.394.54.54 0 0 1 .169-.394L6.28 4.144a.55.55 0 0 1 .403-.169.55.55 0 0 1 .403.169.55.55 0 0 1 .17.403.55.55 0 0 1-.17.403L4.425 7.613h7.612c1.063 0 1.947.353 2.654 1.059.706.706 1.059 1.59 1.059 2.653v2.363a.546.546 0 0 1-.563.562.546.546 0 0 1-.562-.563v-2.362c0-.75-.244-1.369-.731-1.856-.488-.488-1.107-.731-1.857-.731H4.425Z"
    />
  </svg>
);
export default SvgMessageReply;
