import { AutoReplySettings } from './AutoReplySettings';
import { useAtomValue } from 'jotai';
import { meAtom } from '../../../../../atoms/auth';
import { teamsAtom } from '../../../../../atoms/firestore/team';
import { usersAtom } from '../../../../../atoms/firestore/user';
import Avatar from '../../../../Common/Avatar';
import React from 'react';
import { companyDoc } from '../../../../../firestore';
import { teamConverter } from 'lib';
import { serverTimestamp, updateDoc } from 'firebase/firestore';
import { useToast } from '../../../../../hooks/useToast';

type Props = {
  teamId: string;
};

export const AutoReplySettingsWithLogic = ({ teamId }: Props) => {
  const { isReadOnly } = useAtomValue(meAtom);
  const teams = useAtomValue(teamsAtom);
  const allUsers = useAtomValue(usersAtom);
  const { showToast } = useToast();
  const team = teams.find((t) => t.id === teamId);
  const roles = team?.roles ?? {};
  const teamUserIds = Object.entries(roles).map((e) => e[0]);
  const currentDrafterId =
    team?.autoReplyDrafterId ??
    Object.entries(roles).find(([, role]) => role === 'owner')?.[0];
  const teamUsers = allUsers.filter((u) => teamUserIds.includes(u.id));

  const handleChangeDrafterId = async (userId: string) => {
    if (!team) {
      return;
    }
    const teamRef = companyDoc('teams', team.id, teamConverter);
    await updateDoc(teamRef, {
      autoReplyDrafterId: userId,
      updatedAt: serverTimestamp(),
    });
    showToast('success', '送信者を更新しました');
  };

  return (
    <AutoReplySettings
      users={teamUsers.map((u) => ({
        id: u.id,
        name: u.name,
        avatar: <Avatar user={u} size={24} />,
      }))}
      currentDrafterId={currentDrafterId}
      onChangeDrafterId={handleChangeDrafterId}
      readonly={isReadOnly}
    />
  );
};
