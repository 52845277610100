import styled from 'styled-components';
import * as color from '../../../color';
import level from '../../../font/level';

export const H1 = styled.h1<{ noUnderline?: boolean }>`
  font-size: ${level[5]};
  font-weight: 600;
  color: ${color.common.text2};
  padding-bottom: 5px;
  margin-bottom: 32px;

  ${({ noUnderline }) => {
    if (noUnderline) {
      return null;
    } else {
      return `
      border-bottom: solid 0.5px #f1f1f3;
      `;
    }
  }}
`;
