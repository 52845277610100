import React, {
  ReactNode,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import { throttle } from 'lodash';

type MediaQueryProps = {
  minWidth?: number;
  maxWidth?: number;
  children: ReactNode;
};

export const MediaQuery = ({
  minWidth,
  maxWidth,
  children,
}: MediaQueryProps) => {
  const [show, setShow] = useState(false);
  const update = useCallback(() => {
    if (
      maxWidth != null &&
      !window.matchMedia(`(max-width: ${maxWidth - 1}px)`).matches
    ) {
      setShow(false);
      return;
    }
    if (
      minWidth != null &&
      !window.matchMedia(`(min-width: ${minWidth - 1}px)`).matches
    ) {
      setShow(false);
      return;
    }
    setShow(true);
  }, [maxWidth, minWidth]);
  useLayoutEffect(() => update(), [update]);
  useEffect(() => {
    const throttled = throttle(update, 50);
    window.addEventListener('resize', throttled);
    return () => window.removeEventListener('resize', throttled);
  }, [update]);
  return show ? <>{children}</> : null;
};
