import { ComponentProps } from 'react';
import { Button } from '../../basics';
import { twMerge } from 'tailwind-merge';

type Props = Omit<ComponentProps<typeof Button>, 'color' | 'size' | 'variant'>;

export const FormButton = ({ children, className, ...props }: Props) => {
  return (
    <Button
      {...props}
      className={twMerge(
        'rounded-lg border-sumi-300 font-normal text-black hover:bg-sumi-50 hover:text-black active:bg-sumi-100 disabled:border-sumi-200 disabled:bg-transparent',
        className
      )}
      color="sumi"
      variant="outlined"
      size="sm"
    >
      {children}
    </Button>
  );
};
