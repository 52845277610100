import { Contact } from './contact';
import { MessageRecipient } from './MessageRecipient';
import { Message, User } from 'lib';
import { HeaderContactBase } from '../HeaderContactBase';

type Props = {
  user: User | undefined;
  message: Message;
};

const MessageHeaderContact = ({ user, message }: Props) => {
  return (
    <HeaderContactBase
      user={user}
      iconName={message.fromIconName}
      contactElement={
        <div className="mb-px ml-px w-full">
          <Contact
            style={{ maxWidth: '100%', textAlign: 'left' }}
            teamId={message.teamId}
            email={message.from.value[0]}
            size={13}
            bold
          />
        </div>
      }
      recipientElement={
        <MessageRecipient
          subject={message.subject}
          mailAddresses={{
            from: message.from.value,
            to: message.to?.value,
            cc: message.cc?.value,
            bcc: message.bcc?.value,
          }}
        />
      }
    />
  );
};

export default MessageHeaderContact;
