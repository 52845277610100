import {
  SidebarCommonButton,
  SidebarCommonButtonProps,
} from '../../../../components/sidebar/SidebarCommonButton/SidebarCommonButton';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useStore } from '../../../../hooks/useStore';
import { Icon } from '../../../../components/basics';
import { CaretDown } from '../../../../components/icons';
import React, { MouseEvent } from 'react';
import { matchPath } from 'react-router';

type Props = SidebarCommonButtonProps & {
  to?: string;
  opened: boolean;
  onToggleOpen: (open: boolean) => void;
};

export const LeftSidebarCollapse = observer(
  ({ to, opened, onToggleOpen, active, ...props }: Props): JSX.Element => {
    const store = useStore();
    const location = useLocation();
    const history = useHistory();
    const isCollapseActive =
      to != null &&
      useRouteMatch({
        path: to,
      }) != null;
    const onClick = (e: MouseEvent) => {
      const target = e.target;
      if (to == null) {
        onToggleOpen(!opened);
      } else if (
        target instanceof Element &&
        target.closest('[data-collapse-arrow=true]') != null
      ) {
        // 矢印がクリックされた場合は、リンク先に遷移せずに開閉のみを行う
        onToggleOpen(!opened);
      } else {
        const match = matchPath(to, {
          path: location.pathname,
          exact: true,
        });
        if (!opened || match != null) {
          onToggleOpen(!opened);
        }
        history.push(to);
        store.closeDrawer();
      }
    };
    return (
      <SidebarCommonButton
        active={isCollapseActive || active}
        leftIcon={
          <span
            className={
              'relative h-[var(--icon-size)] w-[var(--icon-size)] [--arrow-button-size:30px] [--icon-size:16px]'
            }
            style={{
              transform: opened ? undefined : 'rotateZ(-90deg)',
            }}
            data-collapse-arrow={true}
          >
            <span
              className={
                'absolute left-[var(--offset)] top-[var(--offset)] flex h-[var(--arrow-button-size)] w-[var(--arrow-button-size)] items-center justify-center [--offset:calc((var(--arrow-button-size)_-_var(--icon-size))_*_-0.5)]'
              }
            >
              <Icon
                icon={CaretDown}
                size={20}
                style={{
                  transform: opened ? `translateY(1px)` : undefined,
                }}
              />
            </span>
          </span>
        }
        onClick={onClick}
        {...props}
      />
    );
  }
);
