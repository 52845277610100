import React, {
  forwardRef,
  ReactElement,
  ReactNode,
  Ref,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { Icon } from '../basics';
import { tv, VariantProps } from 'tailwind-variants';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { MenuContentProps } from '@radix-ui/react-dropdown-menu';
import { twMerge } from 'tailwind-merge';
import { CaretDown } from '../icons';

const select = tv({
  base: 'grid cursor-pointer select-none grid-cols-[1fr_auto] items-center justify-between gap-[12px] bg-transparent px-2 text-sm text-sumi-900 disabled:cursor-not-allowed',
  variants: {
    size: {
      sm: 'h-7',
      md: 'h-8',
      lg: 'h-10',
    },
    width: {
      auto: '',
      full: 'w-full',
    },
    border: {
      false: 'border-0',
      true: 'border border-sumi-300',
    },
    rounded: {
      none: 'rounded-none',
      md: 'rounded',
      lg: 'rounded-lg',
    },
    disabled: {
      true: 'cursor-not-allowed bg-sumi-100',
    },
  },
  defaultVariants: {
    size: 'md',
    width: 'auto',
    border: true,
    rounded: 'md',
    disabled: false,
  },
});

type SelectVariants = VariantProps<typeof select>;

export type Option<T> = {
  value: T;
  label: string;
};

export type Options<T> = Option<T>[];

export type GroupedOption<T> = {
  group: string;
  options: Option<T>[];
};

type Props<T> = {
  id?: string;
  value: T;
  onChange?: (value: T) => void;
  visible?: boolean;
  onVisibleChange?: (visible: boolean) => void;
  renderLabel?: (value: T, label: string) => string | JSX.Element | undefined;
  renderOption?: (value: T, label: string) => string | JSX.Element | undefined;
  placeholder?: string;
  options: (Option<T> | GroupedOption<T> | T)[];
  optionsId?: string;
  gap?: number;
  collisionPadding?: number;
  variants?: SelectVariants;
  className?: string;
  dropdownClassName?: string;
  align?: MenuContentProps['align'];
  dropdownRef?: Ref<HTMLDivElement>;
  disabled?: boolean;
  footerElement?: ReactElement | undefined;
};

export const Select = forwardRef<HTMLButtonElement, Props<unknown>>(
  (props, ref): JSX.Element => {
    return <InternalSelect {...props} triggerRef={ref} />;
  }
) as <T>(
  p: Props<T> & {
    ref?: Ref<HTMLButtonElement>;
  }
) => JSX.Element;

export const InternalSelect = <T,>({
  id,
  value,
  onChange,
  visible: forceVisible,
  onVisibleChange,
  renderLabel,
  renderOption,
  placeholder = '',
  options,
  optionsId,
  gap,
  collisionPadding = 16,
  variants,
  className,
  dropdownClassName,
  align = 'start',
  dropdownRef,
  triggerRef,
  disabled = false,
  footerElement,
}: Props<T> & {
  triggerRef: Ref<HTMLButtonElement>;
}): JSX.Element => {
  const [visible, setVisible] = useState(false);
  const onOpenChange = useCallback(
    (visible: boolean) => {
      if (forceVisible == null) {
        setVisible(visible);
      }
      onVisibleChange?.(visible);
    },
    [onVisibleChange]
  );
  const normalizedOptions: GroupedOption<T>[] = useMemo(() => {
    switch (getOptionsType(options)) {
      case 'groups':
        return options as GroupedOption<T>[];
      case 'options':
        return [
          {
            group: '',
            options: options as Option<T>[],
          },
        ];
      default:
        return [
          {
            group: '',
            options: (options as T[]).map((option) => ({
              value: option,
              label: `${option}`,
            })) as Option<T>[],
          },
        ];
    }
  }, [options]);
  const items = useMemo(() => {
    if (normalizedOptions.length === 0) {
      return null;
    }
    return normalizedOptions.map(({ group, options }, i) => {
      if (group.length === 0 && options.length === 0) {
        return null;
      }
      return (
        <div key={i} className="flex flex-col gap-2">
          {group.length > 0 && (
            <div className="w-fit select-none text-xs text-sumi-600">
              {group}
            </div>
          )}
          {options.length > 0 && (
            <div className="flex flex-col gap-2">
              {options.map(({ value: key, label }, j) => {
                const selected = key === value;
                return (
                  <SelectButton
                    key={j}
                    children={
                      renderOption
                        ? renderOption(key, label)
                        : label || '未選択'
                    }
                    selected={selected}
                    onClick={() => {
                      onChange?.(key);
                      onOpenChange(false);
                    }}
                  />
                );
              })}
            </div>
          )}
        </div>
      );
    });
  }, [normalizedOptions, onChange, renderOption]);

  const vLabel = useMemo(() => {
    const type = getOptionsType(options);
    if (type === 'values') {
      return `${value}`;
    }
    const array =
      type === 'options'
        ? options.map((o) => o as Option<T>)
        : options.flatMap((o) => (o as GroupedOption<T>).options);
    return array.find((o) => o.value === value)?.label ?? '';
  }, [options, value]);
  const valueLabel = renderLabel ? renderLabel(value, vLabel) : vLabel;

  const shouldVisible = useMemo(
    () => forceVisible || visible,
    [forceVisible, visible]
  );
  return (
    <DropdownMenu.Root open={shouldVisible} modal={false}>
      <DropdownMenu.Trigger
        onPointerDown={undefined} // Radixの標準の挙動を上書きする
        onClick={() => onOpenChange(!shouldVisible)}
        asChild
      >
        <button
          id={id}
          type="button"
          className={twMerge(select({ ...variants, disabled }), className)}
          data-select-trigger={true}
          disabled={disabled}
          ref={triggerRef}
        >
          <span
            className={twMerge(
              'truncate whitespace-nowrap text-start',
              value && valueLabel ? '' : 'text-sumi-500',
              disabled ? 'opacity-30' : 'cursor-pointer'
            )}
          >
            {value && valueLabel ? valueLabel : placeholder}
          </span>
          <Icon
            icon={CaretDown}
            size={24}
            className={'transform ' + (shouldVisible ? 'rotate-180' : '')}
          />
        </button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content
        id={optionsId}
        onInteractOutside={(e) => {
          if (
            !(e.target as HTMLElement).closest('[data-select-trigger=true]')
          ) {
            onOpenChange(false);
          }
        }}
        className={twMerge(
          'z-50 flex max-h-[40dvh] min-w-[var(--radix-dropdown-menu-trigger-width)] flex-col gap-2 overflow-auto rounded-lg bg-white p-2.5 shadow-dropdown',
          dropdownClassName
        )}
        align={align}
        sideOffset={gap}
        ref={dropdownRef}
        collisionPadding={collisionPadding}
      >
        {items}
        {footerElement}
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};

type ButtonProps = {
  onClick: () => void;
  selected: boolean;
  children: ReactNode;
};

const selectButton = tv({
  base: 'block w-full cursor-pointer whitespace-nowrap rounded-lg px-1.5 py-1 text-left text-sm outline-none',
  variants: {
    selected: {
      true: 'bg-sumi-200 hover:bg-sumi-300',
      false: 'bg-white hover:bg-sumi-100',
    },
    string: {
      true: 'h-8',
      false: '',
    },
  },
});

const SelectButton = ({ onClick, selected, children }: ButtonProps) => {
  return (
    <DropdownMenu.Item asChild>
      <button
        type="button"
        onClick={() => onClick()}
        className={selectButton({
          selected,
          string: typeof children === 'string',
        })}
      >
        {children}
      </button>
    </DropdownMenu.Item>
  );
};

const getOptionsType = <T,>(
  options: (T | GroupedOption<T> | Option<T>)[]
): 'values' | 'options' | 'groups' => {
  if (options.length === 0) {
    return 'values';
  }
  const first = options[0];
  if (typeof first !== 'object') {
    return 'values';
  }
  const keys = Object.keys(first as object);
  return keys.includes('value') && keys.includes('label')
    ? 'options'
    : 'groups';
};
