import type { Store } from './index';
import { isProduction } from 'util.js';

export class FeatureStore {
  constructor(private rootStore: Store) {}

  get metadata() {
    return this.rootStore.stripeProduct?.metadata || {};
  }

  get isCustomStatusSupported() {
    return this.metadata.customStatusSupported === 'true' || !isProduction();
  }

  get isFollowUpMessageSupported() {
    return this.metadata.followUpMessageSupported === 'true' || !isProduction();
  }
}
