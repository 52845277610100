import { observer } from 'mobx-react';
import { useStore } from '../../../../../../hooks/useStore';
import { StarButton } from '../../../../../Common/Icons/StarButton';
import { MouseEvent } from 'react';
import { MessageLike } from 'lib';
import { SearchMessage } from '../../../../../../store/search';
import { twMerge } from 'tailwind-merge';

type Props = {
  message: MessageLike | SearchMessage;
  disabled?: boolean;
  className?: string;
  starredClassName?: string;
  notStarredClassName?: string;
};

export const MessageStar = observer(
  ({
    message,
    disabled,
    starredClassName,
    notStarredClassName,
    className,
  }: Props) => {
    const store = useStore();
    const starred = store.isStarredThread(message.id);
    const onClick = async (e: MouseEvent) => {
      e.stopPropagation();
      await store.toggleThreadStar(message);
    };
    return (
      <div
        className={twMerge(
          className,
          starred ? starredClassName : notStarredClassName
        )}
      >
        <StarButton onClick={onClick} starred={starred} disabled={disabled} />
      </div>
    );
  }
);
