import { SettingsSidebar } from './SettingsSidebar';
import lStorage, { storageKeys } from '../../../localStorage';
import { ComponentProps, useEffect, useState } from 'react';
import { useLogout } from '../../../hooks/useLogout';
import { observer } from 'mobx-react';
import { useStore } from '../../../hooks/useStore';
import { isIpBlockSupported, isLineSupported } from 'store/companyPermission';

type Team = ComponentProps<typeof SettingsSidebar>['teams'][number];

export const SettingsSidebarWithLogic = observer(() => {
  const store = useStore();
  const logout = useLogout();
  const { closedTeams, onTeamOpenChange } = useClosedTeams();

  useEffect(() => {
    // 古いキーを消す
    const oldKey = 'settingsOpenTeams';
    lStorage.removeString(oldKey);
  }, []);

  const teams: Team[] = store.teams.map((team) => ({
    id: team.id,
    name: team.name,
    showMemberMenu: team.isMember(store.me.id),
  }));

  return (
    <SettingsSidebar
      isAdmin={store.me.isAdmin}
      isPrivateInboxSupported={store.privateTeam?.id != null}
      isIpBlockSupported={isIpBlockSupported(store.ipBlockCount)}
      isIntegrationsSupported={
        store.isSlackNotificationV2Supported || store.isCRMIntegrationSupported
      }
      isYaritoriAiSupported={store.isImpressionClassificationSupported}
      isLineSupported={isLineSupported(store.lineIntegrationCount)}
      teams={teams}
      closedTeams={closedTeams}
      onTeamOpenChange={onTeamOpenChange}
      onLogout={logout}
    />
  );
});

type UseClosedTeamsResult = {
  closedTeams: string[];
  onTeamOpenChange: (teamId: string, open: boolean) => void;
};

const useClosedTeams = (): UseClosedTeamsResult => {
  const [closedTeams, setClosedTeams] = useState(
    lStorage.getOrInitObject(storageKeys.settingsClosedTeams, []) as string[]
  );
  const onTeamOpenChange = (teamId: string, open: boolean) => {
    const updated = open
      ? closedTeams.filter((id) => id !== teamId)
      : [...closedTeams, teamId];
    lStorage.setObject(storageKeys.settingsClosedTeams, updated);
    setClosedTeams(updated);
  };
  return { closedTeams, onTeamOpenChange };
};
