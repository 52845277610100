import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import { H2 } from '../../../Common/H2';
import {
  Alert,
  Button,
  Checkbox,
  Col,
  Icon,
  Row,
  Skeleton,
  Switch,
  Tooltip,
} from 'antd';
import firebase from 'firebase.js';
import { getSlackChannelsFunction } from '../../../../functions';
import * as color from '../../../../color';
import { useHistory } from 'react-router-dom';
import { hasMissingV2Scopes } from './authorize';
import { Store } from '../../../../store';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { SlackChannelSelect } from './SlackChannelSelect';
import { updateDoc } from 'firebase/firestore';

type Props = {
  store: Store;
};

export const UserSlackNotification = inject('store')(
  observer((props: Props) => {
    const history = useHistory();
    // FIXME
    const [channels, setChannels] = useState<any[]>([]);
    const [channelId, setChannelId] = useState<string | null>(null);
    const [channelsLoading, setChannelsLoading] = useState(true);

    const {
      signInCompany,
      slackIntegrationsLoading,
      slackIntegration,
      isV2Plan,
      isSlackNotificationV2Supported,
      isSeenNotificationSupported,
    } = props.store;
    const hasSlackIntegration = Boolean(slackIntegration);
    const {
      slackNotificationChannelId,
      slackNotificationEnabled,
      slackNotificationTypes,
    } = props.store.me;

    useEffect(() => {
      if (!signInCompany || slackIntegrationsLoading) return;

      if (!slackIntegration || !slackNotificationEnabled) {
        setChannelsLoading(false);
        return;
      }

      (async () => {
        const result = await getSlackChannelsFunction({
          companyId: signInCompany,
        });
        const cs = result.data.slice();
        // FIXME: any
        cs.sort((a: any, b: any) => {
          if (a.is_channel !== b.is_channel) return a.is_channel ? 1 : -1;
          return a.name.localeCompare(b.name);
        });
        setChannels([null, ...cs]);
        setChannelId(
          // FIXME: any
          cs.find((c: any) => c.id === slackNotificationChannelId)?.id || null
        );
        setChannelsLoading(false);
      })();
    }, [
      slackNotificationChannelId,
      slackNotificationEnabled,
      signInCompany,
      slackIntegration,
      slackIntegrationsLoading,
    ]);

    const onChangeSlackEnabled = async (enabled: boolean) => {
      await updateSlackOption('enabled', enabled);
    };

    const onChangeSlackTypes = (types: CheckboxValueType[]) =>
      updateSlackOption('types', types);

    const updateSlackOption = (key: string, val: any) =>
      updateDoc(props.store.me.ref, {
        [`notificationSettings.slack.${key}`]: val,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      });

    const onSelectSlackChannel = async (channelId: string | null) => {
      await updateSlackOption('channelId', channelId);
      setChannelId(channelId);
    };

    const reintegrationNeeded =
      !hasSlackIntegration || hasMissingV2Scopes(slackIntegration?.scope);

    return isV2Plan ? (
      <Skeleton loading={slackIntegrationsLoading}>
        <Tooltip
          title={
            !isSlackNotificationV2Supported &&
            'Slack通知は現在のプランではサポートされていません'
          }
        >
          <Title>
            <Icon type="slack" style={{ marginRight: 4, fontSize: 21 }} />
            Slack通知
            <Switch
              checked={hasSlackIntegration && slackNotificationEnabled}
              onChange={onChangeSlackEnabled}
              style={{ marginLeft: 12 }}
              disabled={!isSlackNotificationV2Supported || reintegrationNeeded}
            />
          </Title>
          {isSlackNotificationV2Supported && reintegrationNeeded && (
            <Alert
              message={
                <Row type="flex" justify="space-between" align="middle">
                  <Col>
                    {hasSlackIntegration
                      ? '通知に必要な権限の取得のため再連携の必要があります'
                      : 'Slackワークスペースにyaritoriを連携すると通知を設定できます'}
                  </Col>
                  <Col>
                    <Button
                      onClick={() => history.push('/settings/integrations')}
                      type="primary"
                    >
                      連携設定
                    </Button>
                  </Col>
                </Row>
              }
            />
          )}
          <Content>
            <Checkbox.Group
              options={[
                { label: '自分が担当者', value: 'assigned' },
                {
                  label: '自分宛て（@メンション）の新規コメント',
                  value: 'mentionedMessage',
                },
                {
                  label: 'メールの開封',
                  value: 'opened',
                  disabled: !isSeenNotificationSupported,
                },
              ]}
              value={slackNotificationTypes}
              onChange={onChangeSlackTypes}
              disabled={!hasSlackIntegration || !slackNotificationEnabled}
            />
          </Content>

          {hasSlackIntegration && (
            <Content>
              通知先チャンネル
              <SlackChannelSelect
                style={{ marginLeft: 12, width: 240 }}
                channelId={channelId}
                onSelectChannelId={onSelectSlackChannel}
                disabled={!slackNotificationEnabled}
                loading={channelsLoading}
                channels={channels}
              />
            </Content>
          )}
        </Tooltip>
      </Skeleton>
    ) : (
      <></>
    );
  })
) as any as React.FC<Omit<Props, 'store'>>;

const Content = styled.div`
  margin-top: 20px;
  color: ${color.common.text2};
`;

const Title = styled(H2)`
  margin-top: 30px;
  display: flex;
  align-items: center;
`;
