import React, {
  ComponentType,
  CSSProperties,
  isValidElement,
  SVGProps,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { matchPath } from 'react-router';
import { useLocation } from 'react-router-dom';
import { Icon } from '../../basics';
import { LeftSidebarCountText } from '../../../App/Top/LeftSidebar/LeftSidebarCountText';
import { ComponentWithAs } from '../../../utils/system.types';
import { tv } from 'tailwind-variants';
import { twMerge } from 'tailwind-merge';
import { Tooltip } from '../../basics/Tooltip/Tooltip';
import { throttle } from 'lodash';

export type SidebarCommonButtonProps = {
  icon: ComponentType<SVGProps<SVGSVGElement>> | JSX.Element | null | undefined;
  label: string;
  count?: number;
  active?: Active;
  level?: number;
  leftIcon?: JSX.Element;
  bold?: boolean;
  disabled?: boolean;
  tooltip?: string;
  tooltipOpen?: boolean;
};

type Active =
  | boolean
  | {
      type: 'parentPath';
      path: string | string[];
    };

const labelStyle = tv({
  base: 'max-w-full overflow-hidden overflow-ellipsis whitespace-nowrap',
  variants: {
    bold: {
      true: 'font-bold',
    },
  },
});

const button = tv({
  base: 'pointer-events-auto flex h-[38px] min-h-[38px] w-full max-w-full cursor-pointer select-none items-center gap-2 rounded-lg text-[14px] text-black outline-none',
  variants: {
    active: {
      true: 'bg-sumi-100',
      false: 'bg-transparent',
    },
    disabled: {
      true: 'cursor-not-allowed opacity-50',
      false: '',
    },
    isDefaultLevel: {
      true: 'p-2',
      false: 'py-2 pr-2',
    },
  },
  compoundVariants: [
    {
      disabled: false,
      active: false,
      class: 'hover:bg-sumi-100 focus-visible:bg-sumi-100',
    },
    {
      disabled: false,
      active: true,
      class: 'hover:bg-sumi-200 focus-visible:bg-sumi-200',
    },
  ],
  defaultVariants: {
    disabled: false,
  },
});

export const SidebarCommonButton: ComponentWithAs<
  'button',
  SidebarCommonButtonProps
> = ({
  icon,
  label,
  count,
  active,
  level,
  leftIcon,
  bold,
  disabled,
  tooltip,
  tooltipOpen,
  as: Component = 'button',
  ...props
}): JSX.Element => {
  const componentRef = useRef<HTMLDivElement>(null);
  const labelRef = useRef<HTMLSpanElement>(null);
  const location = useLocation();
  const isActive = useMemo(() => {
    if (active == null) {
      return false;
    }
    if (typeof active === 'boolean') {
      return active;
    }
    if (active.type === 'parentPath') {
      return matchPath(location.pathname, active.path) != null;
    }
    return false;
  }, [active, location.pathname]);
  const buttonStyles = useMemo((): CSSProperties | undefined => {
    if (level == null || level <= 1) {
      return undefined;
    }
    const space = 32 * (level - 1);
    return {
      paddingLeft: `${space}px`,
    };
  }, [level]);
  const content = useMemo(() => {
    return (
      <>
        {leftIcon}
        {icon && (
          <span className="h-[20px] w-[20px]">
            {isValidElement(icon) ? (
              icon
            ) : (
              <Icon
                icon={icon as ComponentType<SVGProps<SVGSVGElement>>}
                size={20}
              />
            )}
          </span>
        )}
        <span className={labelStyle({ bold })} ref={labelRef}>
          {label}
        </span>
        <LeftSidebarCountText count={count} className={'ml-auto'} />
      </>
    );
  }, [icon, label, count, leftIcon, bold, tooltip, tooltipOpen]);

  const overriddenProps = {
    ...props,
    className: twMerge(
      button({
        active: isActive,
        isDefaultLevel: level == null || level <= 1,
        disabled,
      }),
      props.className
    ),
    style: { ...buttonStyles, ...props.style },
    children: content,
    disabled,
  };

  const [tooltipOffset, setTooltipOffset] = useState(0);
  const updateTooltipOffset = () => {
    const label = labelRef.current;
    const component = componentRef.current;
    if (!label || !component) {
      return;
    }
    const labelRight = label.getBoundingClientRect().right;
    const componentRight = component.getBoundingClientRect().right;
    setTooltipOffset(labelRight - componentRight + 4);
  };
  useEffect(() => updateTooltipOffset(), []);
  useEffect(() => {
    const update = throttle(updateTooltipOffset, 100);
    window.addEventListener('resize', update);
    return () => window.removeEventListener('resize', update);
  }, []);

  return tooltip ? (
    <Tooltip
      open={tooltipOpen}
      content={tooltip}
      side="right"
      sideOffset={tooltipOffset}
    >
      <Component {...overriddenProps} ref={componentRef} />
    </Tooltip>
  ) : (
    <Component {...overriddenProps} ref={componentRef} />
  );
};
