import { FirestoreDataConverter } from 'firebase/firestore';
import { createConverter, createEntityFactory } from '../converter';

export type CustomStatus = {
  id: string;
  name: string;
  nextStatus: string | null;

  // 並び順、値がundefinedだとorderByから除外されるため、number型にしている
  order: number;
};

export const createCustomStatus = createEntityFactory<
  'CustomStatus',
  CustomStatus
>('CustomStatus');

export const customStatusesConverter: FirestoreDataConverter<CustomStatus> =
  createConverter(createCustomStatus);
