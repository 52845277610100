import { BaseMessagePane } from './BaseMessagePane';
import Commented from '../MessageList/Me/Commented';
import { MessageListEmpty } from 'components/MessageList';
import { observer } from 'mobx-react';
import { useAtomValue } from 'jotai';
import { commentedMessagesAtom } from '../../../atoms/firestore/comment';

export const PATH = '/me/commented';

// 自分 - コメント
export const MessagePaneMeCommented: React.FC = observer(() => {
  const messagesPaginate = useAtomValue(commentedMessagesAtom);
  const isEmpty =
    messagesPaginate.state === 'hasData' && messagesPaginate.data.length === 0;

  return (
    <BaseMessagePane
      listPagePath={PATH}
      empty={isEmpty}
      emptyComponent={
        <MessageListEmpty description="コメントがあるメッセージはありません" />
      }
      listComponent={<Commented />}
    />
  );
});
