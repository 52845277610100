import * as React from 'react';
import type { SVGProps } from 'react';
const SvgAddCommentSubmit = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="m20.35 12.7-16.3 6.85c-.25.1-.487.08-.712-.063A.682.682 0 0 1 3 18.876V5.125c0-.267.112-.47.337-.612a.747.747 0 0 1 .713-.063l16.3 6.85c.3.133.45.367.45.7 0 .333-.15.567-.45.7ZM4.5 17.675 18.1 12 4.5 6.25v4.2L10.55 12 4.5 13.5v4.175Z"
    />
  </svg>
);
export default SvgAddCommentSubmit;
