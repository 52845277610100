import { tv } from 'tailwind-variants';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Icon, Loading } from '../../../basics';
import { Delete } from '../../../icons';
import { Tooltip } from '../../../basics/Tooltip/Tooltip';
import { useState } from 'react';
import { wait } from '../../../../utils/wait';
import { useConfirmDialog } from '../../../../hooks/confirmDialog';

type State = 'draft' | 'sending' | 'sent';

type Props = {
  subject: string;
  bodyText: string;
  createdAt: Date;
  sentAt: Date | undefined;
  state: State;
  href: string;
  onDelete?: () => Promise<void>;
};

const STATE_LABELS: { [k in State]: string } = {
  draft: '下書き',
  sending: '送信中',
  sent: '送信済み',
};

const card = tv({
  base: 'group relative m-0 grid w-full grid-cols-[240px_2fr] gap-4 rounded-lg border border-sumi-300 bg-transparent p-4 pr-8 text-left text-sm text-black focus-visible:border-sea-500',
  variants: {
    deleting: {
      true: 'opacity-50',
      false: 'hover:border-sea-500',
    },
  },
});

const subjectClass = tv({
  base: 'line-clamp-3 text-base font-bold',
  variants: {
    empty: {
      true: 'text-sumi-600',
    },
  },
});

const stateBadge = tv({
  base: 'h-2 w-2 rounded-full',
  variants: {
    state: {
      sent: 'bg-sea-500',
      sending: 'bg-wood-400',
      draft: 'bg-sumi-400',
    },
  },
});

const bodyClass = tv({
  base: 'line-clamp-[10] whitespace-pre-line',
  variants: {
    empty: {
      true: 'text-sumi-600',
    },
  },
});

export const DeliveryMessageCard = ({
  subject,
  bodyText,
  createdAt,
  sentAt,
  state,
  href,
  onDelete,
}: Props) => {
  const [deleting, setDeleting] = useState(false);
  const showDialog = useConfirmDialog();
  const deleteDraft = async () => {
    setDeleting(true);
    try {
      await wait(1000);
      await onDelete?.();
    } catch (e) {
      setDeleting(false);
      throw e;
    }
  };
  return (
    <Link
      className={card({ deleting })}
      to={href}
      onClick={(e) => {
        if (deleting) {
          e.preventDefault();
        }
      }}
    >
      <div className="flex flex-col gap-4">
        <div className={subjectClass({ empty: !subject })}>
          {!!subject ? subject : '件名がありません'}
        </div>
        <div className="grid grid-cols-[auto_1fr] items-center gap-2">
          <div className={stateBadge({ state })} />
          <div>{STATE_LABELS[state]}</div>
        </div>
        {!sentAt && createdAt && (
          <div className="truncate pl-4 text-sumi-600">
            作成日時: {moment(createdAt).format('YYYY/MM/DD HH:mm')}
          </div>
        )}
        {sentAt && (
          <div className="truncate pl-4 text-sumi-600">
            送信日時: {moment(sentAt).format('YYYY/MM/DD HH:mm')}
          </div>
        )}
      </div>
      <div className={bodyClass({ empty: !bodyText })}>
        {!!bodyText ? bodyText : '本文がありません'}
      </div>
      {deleting && (
        <div className="absolute right-3 top-3 flex h-8 w-8 items-center justify-center">
          <Loading size={18} className="text-sumi-500" />
        </div>
      )}
      {!deleting && state === 'draft' && (
        <Tooltip content="下書きを削除">
          <button
            type="button"
            className="absolute right-3 top-3 flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-transparent p-0 text-sumi-500 opacity-0 hover:bg-red-500/10 hover:text-red-500 focus:opacity-100 group-hover:opacity-100"
            onClick={async (e) => {
              e.stopPropagation();
              e.preventDefault();
              showDialog({
                title: '下書きを削除しますか？',
                okText: '削除',
                okType: 'danger',
                onOk: () => {
                  deleteDraft().then();
                },
              });
            }}
          >
            <Icon icon={Delete} size={20} />
          </button>
        </Tooltip>
      )}
    </Link>
  );
};
