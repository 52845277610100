import { LineAccount, LineContact, LineThread, LineThreadStatus } from 'lib';
import { Button } from 'antd';
import React from 'react';
import { serverTimestamp, updateDoc } from 'firebase/firestore';
import { SPBackButton } from '../Conversations/Conversation/SPBackButton';
import { useStore } from '../../../hooks/useStore';

type Props = {
  account: LineAccount;
  thread: LineThread;
  contacts: LineContact[];
};

export const LineConversationHeader = ({
  account,
  thread,
  contacts,
}: Props): JSX.Element => {
  const store = useStore();

  const updateStatusToProcessed = async () => {
    if (!thread) {
      return;
    }
    await updateDoc(thread.ref, {
      status: LineThreadStatus.Processed,
      updatedAt: serverTimestamp(),
    });
  };

  return (
    <div className="flex flex-col gap-1 bg-sumi-50 p-4">
      <SPBackButton
        backLink={`/teams/${account.teamId}/lineaccounts/${account.id}/${store.selectedMessageView}`}
      />
      <div className="text-sm">{account.name}</div>
      <div className="flex items-center justify-between">
        <div className="text-lg font-bold">
          {contacts.map((c) => c.displayName).join(', ')}
        </div>
        <div>
          <Button
            icon="check"
            type={
              thread.status === LineThreadStatus.Unprocessed
                ? 'dashed'
                : 'default'
            }
            onClick={updateStatusToProcessed}
            disabled={thread.status === LineThreadStatus.Processed}
          >
            {thread.status === LineThreadStatus.Unprocessed
              ? '対応済みにする'
              : '対応済み'}
          </Button>
        </div>
      </div>
    </div>
  );
};
