import { Timestamp } from 'firebase/firestore';
import { createConverter, createEntityFactory } from '../converter';
import moment from 'moment';

export type EventData = {
  teamId: string;
  messageId: string;
  user: string;
  name: string;
  type: string;
  text: string;
  createdAt: Timestamp;
  updatedAt: Timestamp;
};

export const createEvent = createEntityFactory('Event', (data: EventData) => ({
  date: moment(data.createdAt.toMillis()),

  get iconName() {
    const name = data.name;
    if (name.length === 0) return '';
    return name.length > 1 ? (name[0] + name[1]).toUpperCase() : name[0];
  },
}));

export type Event = ReturnType<typeof createEvent>;

export const eventConverter = createConverter(createEvent);
