import { CompanyData } from 'lib';

export const isPermitAddUserGroup = (
  company: CompanyData | null,
  groupsLength: number
): boolean => {
  if (!company || typeof company.maxUserGroups !== 'number') {
    return true;
  }

  return groupsLength < Number(company?.maxUserGroups);
};

export const isPermitAddTemplate = (
  company: CompanyData | null,
  teamTemplatesLength: number
): boolean => {
  if (!company || typeof company.maxTemplates !== 'number') {
    return true;
  }

  return teamTemplatesLength < Number(company.maxTemplates);
};
