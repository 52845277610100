import { action, computed, configure, makeObservable, observable } from 'mobx';
import * as Sentry from '@sentry/react';
import {
  Account,
  AccountData,
  Contact,
  ContactData,
  ContactTag,
  ContactTagData,
  deduplicateById,
  Filter,
  InvitedUser,
  InvitedUserData,
  Message,
  messageConverter,
  MessageLike,
  MessageStatus,
  MobxStar as Star,
  normalizeStr,
  PreferencesData,
  RefreshTokenData,
  Sent,
  Signature,
  SlackIntegration,
  SlackIntegrationData,
  StarData,
  starTypes,
  Tag,
  TagColor,
  Team,
  teamConverter,
  User,
  userConverter,
  UserData,
} from 'lib';
import firebase, { db, db9 } from '../firebase';
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  Timestamp,
  updateDoc,
  where,
} from 'firebase/firestore';
import lStorage, { storageKeys } from '../localStorage';
import { eventNames, logEvent, setUserProperties } from '../analytics';
import { setCustomClaimsFunction } from '../functions';
import { CommentStore } from './comment';
import { ContactStore } from './contact';
import { FeatureStore } from './feature';
import { MessageStore } from './message';
import { PrivateStore } from './private';
import { SentStore } from './sent';
import { ThreadStore } from './thread';
import { createPromised } from '../utils/promise';
import { ChannelStore } from './channel';
import { subscribeQueryInArray as subscribeQueryInArrayV8 } from 'utils/firestore';
import { SearchMessage, SearchStore } from './search';
import { IIntegrationStore, IntegrationStore } from './integration';
import { ITemplateStore, TemplateStore } from './template';
import { GroupStore } from './group';
import { OAuthStore } from './oauth';
import { MessageFilterStore, MessagesStore, MessageView } from './messages';
import { SalesforceStore } from './salesforce';
import { SettingsStore } from './settings';
import { ServerCounterStore } from './serverCounter';
import { LineStore } from './line';
import { DomainAuthStore } from './domainAuth';
import { User as AuthUser } from 'firebase/auth';
import { subscribeQueryInArray } from '../utils/firebase';
import { Inbox, inboxConverter } from '../firestore/entity/inbox';

export class Store {
  groupStore = new GroupStore(this);
  channelStore: ChannelStore;
  commentStore: CommentStore;
  contactStore: ContactStore;
  featureStore = new FeatureStore(this);
  messageFilterStore: MessageFilterStore;
  messagesStore: MessagesStore;
  messageStore: MessageStore;
  lineStore: LineStore;
  privateStore: PrivateStore;
  searchStore: SearchStore;
  sentStore: SentStore;
  threadStore: ThreadStore;
  integrationStore: IIntegrationStore;
  oauthStore = new OAuthStore(this);
  salesforceStore = new SalesforceStore(this);
  templateStore: ITemplateStore;
  serverCounterStore = new ServerCounterStore(this);
  settingsStore = new SettingsStore(this);
  domainAuthStore = new DomainAuthStore(this);

  /*
    ログイン中Firebase Authのユーザー
  -------------------------------------*/
  currentUser: AuthUser | null = null;
  currentUserLoading = true;
  skipAutoSignIn = false;

  /*
    ログイン中yaritori ユーザー(Firestoreに格納されているユーザー)
  -------------------------------------*/

  signInUser: UserData | null = null;
  signInUserLoading = true;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  signInCompany: any | null = null;

  /*
    所属会社のusers
  -------------------------------------*/

  users: User[] = [];
  usersLoading = true;

  // 招待中のユーザー
  invitedUsers: InvitedUser[] = [];

  /*
    所属チーム
  -------------------------------------*/

  teams: Team[] = [];
  hasPendingTeamWrites = false;
  privateTeam: Team | null = null;
  teamsLoading = true;

  /*  inboxes  */
  inboxes: Inbox[] = [];
  inboxesLoading = true;

  /*  tags  */
  tags: Tag[] = [];
  tagsLoading = true;

  /*  signatures  */
  signatures: Signature[] = [];
  signaturesLoading = true;

  /* private */
  preferences: PreferencesData = {};
  preferencesLoading = true;

  /*  filters  */
  filters: Filter[] = [];
  filtersLoading = true;

  /*  messages  */

  messageLimitPerPage = 40;

  /* starredMessages */
  starredMessages: Message[] = [];
  starredMessagesLoading = true;
  starredMessagesSize = this.messageLimitPerPage;

  /*  contactTags  */
  contactTags: ContactTag[] = [];
  contactTagsLoading = true;

  /* メッセージ送信 */
  cancelableSendingMessageIds: string[] = [];

  /* selectedContact */
  selectedContact = null;

  /* drawer */
  drawerOpen = false;

  /* checkedMessages */
  checkedMessages: MessageLike[] = [];

  /* checkedSent */
  checkedSent: Sent[] = [];

  /* notificationPermission */
  notificationPermission: string | null = null;
  notificationPermissionError: string | null = null;

  /* stars */
  stars: Star[] = [];
  starsLoading = true;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  company: any | null = null;

  // stripeProduct

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  stripeProduct: any | null = null;

  /*  slackIntegrations  */
  slackIntegrations: SlackIntegration[] = [];
  slackIntegrationsLoading = true;

  // unsubscribe methods
  private _unsubscribeSyncUsers?: () => void;
  private _unsubscribeSyncTeams?: () => void;
  private _unsubscribeSyncInvitedUsers?: () => void;
  private _unsubscribePrivate?: () => void;
  private _unsubscribeSlackIntegrations?: () => void;
  private _unsubscribeSyncStars?: () => void;
  private _unsubscribeSyncInboxes?: () => void;
  private _unsubscribeSignatures?: () => void;
  private _unsubscribeTags?: () => void;
  private _unsubscribeFilters?: () => void;
  private _unsubscribeContactTags?: () => void;

  unsubscribeStarredMessages: (() => void)[] = [];

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private unsubscribeCompany = () => {};

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private unsubscribeStripeProduct = () => {};

  constructor() {
    // チームに紐づくStateプロパティは基本全チーム分格納される
    makeObservable(this, {
      init: action,

      // 会社
      company: observable,

      /*
        ログイン中Firebase Authのユーザー
      -------------------------------------*/
      currentUser: observable,
      currentUserLoading: observable,

      /*
        ログイン中yaritori ユーザー(Firestoreに格納されているユーザー)
      -------------------------------------*/

      signInUser: observable,
      signInUserLoading: observable,
      fetchSignInUser: action,

      /*
        所属会社のusers
      -------------------------------------*/

      users: observable,
      usersLoading: observable,
      me: computed,

      // 招待中のユーザー
      invitedUsers: observable,

      /*
        所属チーム
      -------------------------------------*/
      teams: observable,
      privateTeam: observable,
      teamsLoading: observable,
      joinedTeams: computed,
      joinedTeamsWithInbox: computed,

      /*
        受信トレイ
      -------------------------------------*/
      inboxes: observable,
      inboxesLoading: observable,

      firstInbox: computed,
      defaultInbox: computed,
      inboxesLength: computed,

      /*
        署名
      -------------------------------------*/
      signatures: observable,
      signaturesLoading: observable,

      /*
        タグ
      -------------------------------------*/
      tags: observable,
      tagsLoading: observable,

      /*
        フロー
      -------------------------------------*/
      filters: observable,
      filtersLoading: observable,

      /*
        メッセージ(メール)
      -------------------------------------*/
      selectedMessageView: computed,
      selectedStatus: computed,

      /*
        コンタクトタグ
      -------------------------------------*/
      contactTags: observable,
      contactTagsLoading: observable,

      /*
        キャンセル可能な送信から5秒以内のメッセージ
      -------------------------------------*/
      cancelableSendingMessageIds: observable,

      /*
        連携Slack情報
      -------------------------------------*/
      slackIntegrations: observable,
      slackIntegrationsLoading: observable,
      slackIntegration: computed,

      /*
        チェックボックス選択中のメッセージ
      -------------------------------------*/
      checkedMessages: observable,
      checkedSent: observable,

      /*
        ブラウザ通知設定
      -------------------------------------*/
      notificationPermission: observable,
      notificationPermissionError: observable,

      /*
        受信メッセージで選択中のコンタクト
      -------------------------------------*/
      selectedContact: observable,

      /*
        お気に入り
      -------------------------------------*/
      stars: observable,
      starsLoading: observable,
      starsForMessages: computed,
      starsForMessagesOrderByMessageDate: computed,

      /*
        お気に入りされたメッセージ
      -------------------------------------*/
      starredMessages: observable,
      starredMessagesLoading: observable,
      sortedStarredMessages: computed,
      hasMoreStarredMessages: computed,

      /*
        Stripeのプロダクト(プラン設定)
      -------------------------------------*/
      stripeProduct: observable,
      isV2Plan: computed,
      isScheduledDraftSupported: computed,
      isSlackNotificationV2Supported: computed,
      isSeenNotificationSupported: computed,
      isSeenHistorySupported: computed,
      isCRMIntegrationSupported: computed,
      isAutoReplySupported: computed,
      invitable: computed,

      /*
        個人設定
      -------------------------------------*/
      preferences: observable,
      preferencesLoading: observable,

      /*
        その他UI状態
      -------------------------------------*/

      // スマホ版ドロワー開閉
      drawerOpen: observable,
    });
    configure({ enforceActions: 'never' });

    this.channelStore = new ChannelStore();
    this.commentStore = new CommentStore(this);
    this.contactStore = new ContactStore(this);

    this.messageFilterStore = new MessageFilterStore(this);
    this.messagesStore = new MessagesStore(this);
    this.messageStore = new MessageStore(this);
    this.lineStore = new LineStore(this);
    this.privateStore = new PrivateStore(this);
    this.sentStore = new SentStore(this);
    this.threadStore = new ThreadStore(this);
    this.searchStore = new SearchStore(this);
    this.integrationStore = new IntegrationStore(this);
    this.templateStore = new TemplateStore(this);
    this.settingsStore = new SettingsStore(this);
  }

  /* init */
  async init(user: AuthUser | null) {
    this.channelStore.init();

    if (user) {
      if (this.skipAutoSignIn) return;
      // 自動ログイン時の処理
      this.currentUser = user;

      // ユーザ情報は後の関数で必須(自分の情報など)なので、先に取得しておく
      await this.fetchSignInUser();

      await Promise.all([
        // Some features are controlled by company and stripe product.
        this.syncCompany(this.signInCompany),
        // syncMessages or syncThreads is controlled by preferences.threadView.
        this.fetchPreferences(),
      ]);

      const [companyName] = await Promise.all([
        this.fetchCompanyName(this.signInCompany),
        this.syncUsers(),
        this.syncPrivateTeam(),
      ]);
      this.syncTeams();

      // Authenticationのメールアドレスとユーザー情報のメールアドレスが違う場合、Authenticationのメールアドレスに更新する。
      if (this.currentUser.email !== this.signInUser?.email) {
        // FIXME
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.setEmail(this.currentUser.email as any);
      }

      setUserProperties({
        company_id: this.signInCompany,
        company_name: companyName,
        user_id: this.me.id,
        user_name: this.me.name,
      });
      logEvent(eventNames.login);

      Sentry.setUser({
        id: this.me.id,
        username: this.me.name,
        email: this.me.email,
        companyId: this.signInCompany,
      });

      // Note: sentryでuser.companyIdを絞り込むことができないため、sentryのcontextでcompanyの絞り込みを行う
      Sentry.setContext('company', {
        id: this.signInCompany,
        name: companyName,
      });
      Sentry.setTag('company', this.signInCompany);

      this.currentUserLoading = false;

      this.syncInvitedUsers();
      this.privateStore.syncStars();
      this.syncStars();
      this.syncSlackIntegrations();
      this.integrationStore.init();
      this.oauthStore.syncIntegrations();
      this.settingsStore.syncSettings();

      // channelにユーザでログインする
      this.channelStore.boot(this.me, this.signInCompany, companyName);

      this.groupStore.syncGroups();

      // 通知許可をリクエストする
      this.requestPermission();
    } else {
      this.unsubscribeSyncs();

      // 未ログイン時にログイン画面に飛ばす
      this.currentUser = null;
      this.currentUserLoading = false;
      this.signInUser = null;
      this.signInUserLoading = true;
      this.signInCompany = null;

      // channelに匿名でログインする
      this.channelStore.bootAsAnonymous();
    }
  }

  unsubscribeSyncs = () => {
    if (this._unsubscribeSyncUsers) this._unsubscribeSyncUsers();
    if (this._unsubscribeSyncTeams) this._unsubscribeSyncTeams();
    if (this._unsubscribeSyncInvitedUsers) this._unsubscribeSyncInvitedUsers();
    if (this._unsubscribePrivate) this._unsubscribePrivate();
    if (this._unsubscribeSyncStars) this._unsubscribeSyncStars();
    if (this._unsubscribeSlackIntegrations) {
      this._unsubscribeSlackIntegrations();
    }
    this.unsubscribeStripeProduct();
    this.privateStore.unsubscribeSyncs();
    this.integrationStore.unsubscribeSyncs();
    this.settingsStore.unsubscribeSyncs();
  };

  fetchCompanyName = async (companyId: string) => {
    try {
      const companyDocSnap = await db
        .collection('companies')
        .doc(companyId)
        .get();
      return companyDocSnap.get('name');
    } catch (err) {
      console.error('Store.fetchCompanyName:', err);
    }
  };

  get isSignedIn() {
    return this.currentUser !== null;
  }

  async fetchSignInUser() {
    const signInUser = await (
      db.collection(`users`) as firebase.firestore.CollectionReference<UserData>
    )
      .doc(this.currentUser?.uid)
      .get();
    if (!signInUser.exists) {
      console.error('Store.fetchSignInUser: !signInUser.exists:', {
        currentUser: this.currentUser,
      });
    }
    this.signInUser = signInUser.data() ?? null;
    this.signInUserLoading = false;
    this.signInCompany = this.signInUser?.companies[0];
  }

  // メールアドレスを更新する
  setEmail = async (email: string) => {
    try {
      // Firestoreの更新
      await db.runTransaction(async (tx) => {
        await Promise.all([
          // users/{userId} の更新
          tx.update(db.collection('users').doc(this.currentUser?.uid), {
            email,
            updatedAt: serverTimestamp(),
          }),
          // companies/{companyId}/users/{userId} の更新
          tx.update(this.companyCollection('users').doc(this.me.id), {
            email,
            updatedAt: serverTimestamp(),
          }),
        ]);
      });
    } catch (e) {
      console.error('Store.setEmail:', { email }, e);
    }
  };

  /* custom claims */
  // カスタムクレームをセットする
  // ※基本はonCreateTeam/onUpdateTeamで呼ばれるので呼ばなくて良い
  setMyCustomClaims = async () => {
    await setCustomClaimsFunction({
      companyId: this.signInCompany,
    });
  };

  // （必要に応じて）カスタムクレームを適用する
  applyCustomClaimsIfNeeded = async (
    refreshTokenSnap: firebase.firestore.DocumentSnapshot<RefreshTokenData>
  ) => {
    const localSystemValues = lStorage.getOrInitObject(
      storageKeys.localSystemValues,
      {}
    );

    const refreshToken = refreshTokenSnap.data();

    if (!refreshToken) {
      return;
    }

    if (
      !localSystemValues.lastGetIdTokenResultAt ||
      localSystemValues.lastGetIdTokenResultAt <
        refreshToken.updatedAt.toMillis()
    ) {
      // カスタムクレームを適用する
      await this.applyCustomClaims();
      const newLocalSystemValues = {
        ...localSystemValues,
        lastGetIdTokenResultAt: refreshToken.updatedAt.toMillis(), // 取得したミリ秒で更新する
      };
      lStorage.setObject(storageKeys.localSystemValues, newLocalSystemValues);
    }
  };

  // カスタムクレームを適用する
  applyCustomClaims = async () => {
    await firebase.auth().currentUser?.getIdTokenResult(true);
  };

  async fetchPreferences() {
    if (this.preferencesLoading) {
      const doc = await db
        .collection(
          `companies/${this.signInCompany}/users/${this.currentUser?.uid}/private`
        )
        .doc('preferences')
        .get();
      this.preferencesLoading = false;
      this.preferences = doc.data() || {};
    }
  }

  syncPrivate() {
    if (this._unsubscribePrivate) this._unsubscribePrivate();

    const query = this.companyCollection(`users/${this.me.id}/private`);
    this._unsubscribePrivate = query.onSnapshot(
      async (querySnapShot) => {
        for (const doc of querySnapShot.docs) {
          switch (doc.id) {
            case 'preferences':
              this.preferences = doc.data();
              this.preferencesLoading = false;
              break;
            case 'refreshToken':
              // refreshTokenが更新された場合、カスタムクレームを更新する
              await this.applyCustomClaimsIfNeeded(
                doc as firebase.firestore.QueryDocumentSnapshot<RefreshTokenData>
              );
              break;
            default:
              break;
          }
        }
      },
      (err) => {
        console.error('Store.syncPrivate:', err);
      }
    );
  }

  async syncUsers() {
    return new Promise<void>((resolve, reject) => {
      if (this._unsubscribeSyncUsers) this._unsubscribeSyncUsers();

      const q = query(
        collection(db9, `companies/${this.signInCompany}/users`),
        orderBy('name')
      ).withConverter(userConverter);

      let firstLoaded = false;
      this._unsubscribeSyncUsers = onSnapshot(
        q,
        (querySnapShot) => {
          const users: User[] = [];
          querySnapShot.forEach((doc) => users.push(doc.data()));
          this.users = users;

          if (!firstLoaded) {
            firstLoaded = true;
            this.usersLoading = false;
            resolve();
          }
        },
        (err) => {
          console.error('Store.syncUsers:', err);
          reject(err);
        }
      );
    });
  }

  getUser = (userId: string) => {
    return this.users.find((u) => u.id === userId);
  };

  getUserByEmail = (email: string) => {
    return this.users.find((u) => u.email === email);
  };

  getUsersByTeamId = (teamId: string) => {
    const team = this.getTeam(teamId);
    if (!team) return [];
    return this.users.filter((u) => team.isMember(u.id));
  };

  get me(): User {
    const me = this.users.find((u) => u.id === this.currentUser?.uid);
    if (!me)
      throw new Error(
        `me not found this.currentUser.uid: ${this.currentUser?.uid}`
      );
    return me;
  }

  syncInvitedUsers() {
    if (this._unsubscribeSyncInvitedUsers) this._unsubscribeSyncInvitedUsers();

    const query = (
      db.collection(
        `companies/${this.signInCompany}/invitedUsers`
      ) as firebase.firestore.CollectionReference<InvitedUserData>
    ).orderBy('email');
    this._unsubscribeSyncInvitedUsers = query.onSnapshot(
      (querySnapShot) => {
        const invitedUsers: InvitedUser[] = [];
        querySnapShot.forEach((doc) => invitedUsers.push(new InvitedUser(doc)));
        this.invitedUsers = invitedUsers;
      },
      (err) => {
        console.error('Store.syncInvitedUsers:', err);
      }
    );
  }

  getInvitedUserByEmail = (email: string) =>
    this.invitedUsers.find((u) => u.email === email);

  get joinedTeams() {
    const teams = this.teams.filter((t) => t.isMember(this.me.id));
    if (this.privateTeam) {
      teams.unshift(this.privateTeam);
    }
    return teams;
  }

  get joinedTeamIds() {
    return this.joinedTeams.map((t) => t.id);
  }

  // Do not show private team without inbox.
  get joinedTeamsWithInbox() {
    return this.joinedTeams.filter(
      (t) => !t.isPrivate || (t.isPrivate && this.getTeamFirstInbox(t.id))
    );
  }

  syncTeams() {
    if (this._unsubscribeSyncTeams) this._unsubscribeSyncTeams();

    const q = query(this.collection('teams'), orderBy('name')).withConverter(
      teamConverter
    );

    this._unsubscribeSyncTeams = onSnapshot(
      q,
      {
        // hasPendingWritesを使用して、firestoreに書き込みが行われたかどうかを確認している
        // 書き込みを確認できないとfetchやsync時にチーム権限が反映されずにエラーとなるため
        includeMetadataChanges: true,
      },
      async (querySnapShot) => {
        if (querySnapShot.metadata.hasPendingWrites) {
          // 書き込み待ちの処理が残っており正しい情報が取得できない
          this.hasPendingTeamWrites = true;
          return;
        }

        if (!querySnapShot.docChanges().length && !this.hasPendingTeamWrites) {
          // ドキュメントの変更も書き込み待ちもなく実行することがない
          return;
        }

        this.teams = querySnapShot.docs
          .filter((doc) => doc.get('isPrivate') === false)
          .map((doc) => doc.data());

        this.hasPendingTeamWrites = false;
        this.teamsLoading = false;

        await this.syncDatabasesDependingOnTeams();
      },
      (err) => {
        console.error('Store.syncTeams:', err);
        throw new Error(`Store.syncTeams: ${err}`);
      }
    );
  }

  async syncPrivateTeam() {
    if (this.privateTeam) {
      return;
    }
    const snapshot = await getDocs(
      query(
        this.collection('teams'),
        where(`roles.${this.currentUser?.uid}`, '==', 'owner')
      ).withConverter(teamConverter)
    );
    snapshot.docs.forEach((doc) => {
      if (doc.data().isPrivate) {
        this.privateTeam = doc.data();
        return;
      }
    });
  }

  getTeam(teamId?: string) {
    return this.teams.find((t) => t.id === teamId);
  }

  joinedTeamLength = (userId: string) =>
    this.teams.filter((t) => t.isMember(userId)).length;

  // チームに依存するデータを同期する
  async syncDatabasesDependingOnTeams() {
    this.syncPrivate();
    this.syncTags();
    this.templateStore.syncTemplates();
    this.syncFilters();
    this.syncSignatures();
    this.lineStore.syncLine();
    this.syncContactTags();
    this.contactStore.syncContacts();

    await this.syncInboxes();
  }

  async syncInboxes() {
    this.unsubscribeSyncInboxes();
    if (this.joinedTeams.length === 0) {
      return;
    }
    return new Promise<void>((resolve) => {
      let firstLoaded = false;
      this._unsubscribeSyncInboxes = subscribeQueryInArray(
        this.collection('inboxes').withConverter(inboxConverter),
        'teamId',
        this.joinedTeamIds,
        async (snap, docs) => {
          const inboxes: Inbox[] = [];
          docs.forEach((doc) => {
            if (!doc.get('deleted')) {
              inboxes.push(doc.data());
            }
          });

          inboxes.sort((a, b) => {
            if (a.email > b.email) return 1;
            if (a.email === b.email) return 0;
            return -1;
          });
          this.inboxes = inboxes;
          if (!firstLoaded) {
            firstLoaded = true;
            this.inboxesLoading = false;
            resolve();
          }
        }
      );
    });
  }

  unsubscribeSyncInboxes = () => {
    if (this._unsubscribeSyncInboxes) this._unsubscribeSyncInboxes();
  };

  get firstInbox() {
    return this.inboxes.length > 0 ? this.inboxes[0] : null;
  }

  getTeamInboxes = (teamId: string | undefined) =>
    this.inboxes.filter((inbox) => inbox.teamId === teamId);

  getTeamFirstInbox = (teamId: string) => {
    const teamInboxes = this.getTeamInboxes(teamId);
    return teamInboxes.length > 0 ? teamInboxes[0] : null;
  };

  getInbox = (inboxId: string) =>
    this.inboxes.find((inbox) => inbox.id === inboxId);

  get defaultInbox() {
    return this.inboxes.find((inbox) => inbox.id === this.me.defaultInboxId);
  }

  get inboxesLength() {
    return this.teams.reduce(
      (sum, team) => sum + this.getTeamInboxes(team.id).length,
      0
    );
  }

  // Template関連のプロパティをTemplateStoreに移動. 呼び出し元が影響受けないよう一旦proxy
  // TODO: StoreからTemplate関連プロパティ削除. 呼び出し元でTemplateStoreを使うようにする

  get templatesLoading() {
    return this.templateStore.isTemplatesLoading;
  }

  getTemplates = (teamId: string) =>
    this.templateStore.getTeamTemplates(teamId);

  getTemplate = (templateId: string) =>
    this.templateStore.getTemplate(templateId);

  unsubscribeSignatures = () => {
    if (this._unsubscribeSignatures) this._unsubscribeSignatures();
  };

  syncSignatures() {
    this.unsubscribeSignatures();

    if (this.joinedTeams.length === 0) {
      this.signatures = [];
      this.signaturesLoading = false;
      return;
    }

    this._unsubscribeSignatures = subscribeQueryInArrayV8(
      db.collection(`companies/${this.signInCompany}/signatures`),
      'teamId',
      this.joinedTeamIds,
      async (docs) => {
        const signatures: Signature[] = [];
        docs.forEach((doc) => signatures.push(new Signature(doc)));
        signatures.sort((a, b) => {
          if (a.title > b.title) return 1;
          if (a.title === b.title) return 0;
          return -1;
        });
        this.signatures = signatures;
        this.signaturesLoading = false;
      }
    );
  }

  getSignatures = (teamId: string | undefined) =>
    this.signatures.filter((s) => s.teamId === teamId);

  getFirstSignature = (teamId: string) => {
    const teamSignatures = this.getSignatures(teamId);
    return (teamSignatures.length > 0 && teamSignatures[0]) || null;
  };

  getSignature = (signatureId: string) =>
    this.signatures.find((s) => s.id === signatureId);

  unsubscribeTags = () => {
    if (this._unsubscribeTags) this._unsubscribeTags();
  };

  compareTag(tags: Tag[], a: Tag, b: Tag): number {
    const parentA = tags.find((t) => t.id === a.parentTagId);
    const parentB = tags.find((t) => t.id === b.parentTagId);
    if (parentA === b) {
      // [b, a], always put parent tag before child tag.
      return 1;
    } else if (parentB === a) {
      // [a, b], always put parent tag before child tag.
      return -1;
    } else if (parentA === parentB) {
      const indexA = this.preferences.tagIndexes?.[a.id];
      const indexB = this.preferences.tagIndexes?.[b.id];
      if (
        indexA !== undefined &&
        indexB !== undefined &&
        indexA >= 0 &&
        indexB >= 0
      ) {
        // Both have index field, compare the index.
        return indexA - indexB;
      } else if (!indexA && !indexB) {
        // Neither has index field, compare the name.
        return a.name > b.name ? 1 : -1;
      } else if (indexA !== undefined && indexA >= 0) {
        // [a, b], if a has index but b doesn't, then b is created after a.
        return -1;
      } else {
        // [b, a], if b has index but a doesn't, then a is created after b.
        return 1;
      }
    }
    return this.compareTag(tags, parentA || a, parentB || b);
  }

  syncTags() {
    this.unsubscribeTags();

    if (this.joinedTeams.length === 0) {
      this.tags = [];
      this.tagsLoading = false;
      return;
    }

    this._unsubscribeTags = subscribeQueryInArrayV8(
      db.collection(`companies/${this.signInCompany}/tags`),
      'teamId',
      this.joinedTeamIds,
      async (docs) => {
        const tags: Tag[] = [];
        docs.forEach((doc) => tags.push(new Tag(doc)));
        this.tags = tags;
        this.tagsLoading = false;
      }
    );
  }

  getTags = (teamId: string) =>
    this.tags
      .filter((tag) => tag.teamId === teamId)
      .sort((a, b) => this.compareTag(this.tags, a, b));

  getInboxTag = (teamId: string) => this.getTags(teamId).find((t) => t.isInbox);

  getTagsExceptInbox = (teamId: string) =>
    this.getTags(teamId).filter((t) => !t.isInbox);

  getTag = (tagId?: string) => this.tags.find((tag) => tag.id === tagId);

  unsubscribeFilters = () => {
    if (this._unsubscribeFilters) this._unsubscribeFilters();
  };

  syncFilters() {
    this.unsubscribeFilters();

    if (this.joinedTeams.length === 0) {
      this.filters = [];
      this.filtersLoading = false;
      return;
    }
    this._unsubscribeFilters = subscribeQueryInArrayV8(
      db.collection(`companies/${this.signInCompany}/filters`),
      'teamId',
      this.joinedTeamIds,
      async (docs) => {
        const filters: Filter[] = [];
        docs.forEach((doc) => filters.push(new Filter(doc)));
        filters.sort((a, b) => a.order - b.order);
        this.filters = filters;
        this.filtersLoading = false;
      }
    );
  }

  getFilters = (teamId: string) => {
    return this.filters.filter((filter) => filter.teamId === teamId);
  };

  getFilter = (filterId: string) => {
    return this.filters.find((filter) => filter.id === filterId);
  };

  get selectedMessageView(): MessageView {
    return this.messagesStore.view;
  }

  get selectedStatus() {
    switch (this.messagesStore.view) {
      case MessageView.Unprocessed:
        return MessageStatus.Unprocessed;
      case MessageView.Processed:
        return MessageStatus.Processed;
      default:
        return undefined;
    }
  }

  resetMessageView = () => {
    this.messagesStore.resetView();
    this.lineStore.threads = [];
  };

  onMessageViewChanged(view: MessageView) {
    this.checkedMessages = [];
  }

  /*  accounts  */
  getAccount = async (accountId: string) => {
    try {
      const snapshot = await (
        db.collection(
          `companies/${this.signInCompany}/accounts`
        ) as firebase.firestore.CollectionReference<AccountData>
      )
        .doc(accountId)
        .get();
      if (!snapshot.exists) return null;
      return new Account(snapshot);
    } catch (e) {
      // 権限エラーの場合
      return null;
    }
  };
  /*  contacts  */
  getContact = async (contactId: string) => {
    try {
      const snapshot = await (
        db.collection(
          `companies/${this.signInCompany}/contacts`
        ) as firebase.firestore.CollectionReference<ContactData>
      )
        .doc(contactId)
        .get();
      if (!snapshot.exists) return null;
      return new Contact(snapshot);
    } catch (e) {
      // 権限エラーの場合
      return null;
    }
  };

  getContactRef = (contactId: string) => {
    return (
      db.collection(
        `companies/${this.signInCompany}/contacts`
      ) as firebase.firestore.CollectionReference<ContactData>
    ).doc(contactId);
  };

  /*  contacts by accountId  */
  getContactByAccountId = async (accountId: string, teamId: string) => {
    try {
      const snapshots = await (
        db.collection(
          `companies/${this.signInCompany}/contacts`
        ) as firebase.firestore.CollectionReference<ContactData>
      )
        .where('teamId', '==', teamId)
        .where('accountId', '==', accountId)
        .get();
      const contacts: Contact[] = [];
      snapshots.forEach((doc) => contacts.push(new Contact(doc)));
      return contacts;
    } catch (e) {
      // 権限エラーの場合
      return null;
    }
  };

  getContactsByTeamIdEmail = async (teamId: string, email: string) => {
    try {
      const snapshots = await (
        db.collection(
          `companies/${this.signInCompany}/contacts`
        ) as firebase.firestore.CollectionReference<ContactData>
      )
        .where('teamId', '==', teamId)
        .where('email', '==', email)
        .get();
      const contacts: Contact[] = [];
      snapshots.forEach((doc) => contacts.push(new Contact(doc)));
      return contacts;
    } catch (e) {
      // 権限エラーの場合
      return null;
    }
  };

  getContactsByTeamId = async (teamId: string) => {
    try {
      const snapshots = await (
        db.collection(
          `companies/${this.signInCompany}/contacts`
        ) as firebase.firestore.CollectionReference<ContactData>
      )
        .where('teamId', '==', teamId)
        .get();
      const contacts: Contact[] = [];
      snapshots.forEach((doc) => contacts.push(new Contact(doc)));
      return contacts;
    } catch (e) {
      // 権限エラーの場合
      return null;
    }
  };

  getAccountByTeamId = async (teamId: string) => {
    try {
      const snapshots = await (
        db.collection(
          `companies/${this.signInCompany}/accounts`
        ) as firebase.firestore.CollectionReference<AccountData>
      )
        .where('teamId', '==', teamId)
        .get();
      const accounts: Account[] = [];
      snapshots.forEach((doc) => accounts.push(new Account(doc)));
      return accounts;
    } catch (e) {
      // 権限エラーの場合
      return null;
    }
  };

  getAccountsByName = async (teamId: string, name: string) => {
    try {
      const snapshots = await (
        db.collection(
          `companies/${this.signInCompany}/accounts`
        ) as firebase.firestore.CollectionReference<AccountData>
      )
        .where('teamId', '==', teamId)
        .where('name', '==', name)
        .get();
      const accounts: Account[] = [];
      snapshots.forEach((doc) => accounts.push(new Account(doc)));
      return accounts;
    } catch (e) {
      // 権限エラーの場合
      return null;
    }
  };

  getAccountsByDomains = async (teamId: string, domains: string) => {
    try {
      const snapshots = await (
        db.collection(
          `companies/${this.signInCompany}/accounts`
        ) as firebase.firestore.CollectionReference<AccountData>
      )
        .where('teamId', '==', teamId)
        .where('domains', 'array-contains-any', domains)
        .get();
      const accounts: Account[] = [];
      snapshots.forEach((doc) => accounts.push(new Account(doc)));
      return accounts;
    } catch (e) {
      // 権限エラーの場合
      return null;
    }
  };

  getContactsByContactTag = async (name: string, teamId: string) => {
    const snapshots = await (
      db.collection(
        `companies/${this.signInCompany}/contacts`
      ) as firebase.firestore.CollectionReference<ContactTagData>
    )
      .where('teamId', '==', teamId)
      .where('tags', 'array-contains', name)
      .get();
    const contactTags: ContactTag[] = [];
    snapshots.forEach((doc) => contactTags.push(new ContactTag(doc)));
    return contactTags;
  };

  searchAccounts = async (teamId: string, text: string, limit: number) => {
    const normalized = normalizeStr(text);
    const querySnapshots = await Promise.all([
      (
        db.collection(
          `companies/${this.signInCompany}/accounts`
        ) as firebase.firestore.CollectionReference<AccountData>
      )
        .where('teamId', '==', teamId)
        .orderBy('nameNormalized')
        .startAt(normalized)
        .endAt(normalized + '\uf8ff')
        .limit(limit)
        .get(),
      (
        db.collection(
          `companies/${this.signInCompany}/accounts`
        ) as firebase.firestore.CollectionReference<AccountData>
      )
        .where('teamId', '==', teamId)
        .where('externalId', '==', text)
        .limit(limit)
        .get(),
    ]);

    const accounts = querySnapshots.flatMap((qs) =>
      qs.docs.map((doc) => new Account(doc))
    );

    return deduplicateById(accounts)
      .sort((a, b) => a.name.localeCompare(b.name, 'ja'))
      .slice(0, limit);
  };

  unsubscribeContactTags = () => {
    if (this._unsubscribeContactTags) this._unsubscribeContactTags();
  };

  syncContactTags() {
    this.unsubscribeContactTags();

    if (this.joinedTeams.length === 0) {
      this.contactTags = [];
      this.contactTagsLoading = false;
      return;
    }

    this._unsubscribeContactTags = subscribeQueryInArrayV8(
      db.collection(`companies/${this.signInCompany}/contactTags`),
      'teamId',
      this.joinedTeamIds,
      async (docs) => {
        const contactTags: ContactTag[] = [];
        docs.forEach((doc) => contactTags.push(new ContactTag(doc)));
        contactTags.sort((a, b) => a.name.localeCompare(b.name, 'ja'));
        this.contactTags = contactTags;
        this.contactTagsLoading = false;
      }
    );
  }

  getContactTags = (teamId: string): ContactTag[] =>
    this.contactTags.filter((contactTag) => contactTag.teamId === teamId);

  getContactTag = (contactTagId: string) =>
    this.contactTags.find((contactTag) => contactTag.id === contactTagId);

  getContactTagByName = (name: string, teamId: string) =>
    this.contactTags.find(
      (contactTag) => contactTag.teamId === teamId && contactTag.name === name
    );

  getOrCreateContactTags = (
    tags: { name: string; color: TagColor | null | undefined }[],
    teamId: string
  ): Promise<ContactTag[]> =>
    Promise.all(
      tags.map((tag) => this.getOrCreateContactTag(tag.name, tag.color, teamId))
    );

  getOrCreateContactTag = async (
    name: string,
    color: TagColor | null | undefined,
    teamId: string
  ): Promise<ContactTag> => {
    const existingContactTag = this.getContactTagByName(name, teamId);
    if (existingContactTag) {
      if (existingContactTag.color !== color) {
        await existingContactTag.ref.update({
          color: color ?? null,
          updatedAt: serverTimestamp() as Timestamp,
        });
        return new ContactTag(await existingContactTag.ref.get());
      }
      return existingContactTag;
    }

    const tagRef = await (
      db.collection(
        `companies/${this.signInCompany}/contactTags`
      ) as firebase.firestore.CollectionReference<ContactTagData>
    ).add({
      name,
      color: color ?? null,
      teamId,
      // コンパイル通すためにキャスト
      createdAt: serverTimestamp() as Timestamp,
      updatedAt: serverTimestamp() as Timestamp,
    });

    return new ContactTag(await tagRef.get());
  };

  removeContactTag = async (name: string, teamId: string): Promise<void> => {
    const snapshot = await db
      .collection(`companies/${this.signInCompany}/contactTags`)
      .where('teamId', '==', teamId)
      .where('name', '==', name)
      .get();
    await Promise.all(snapshot.docs.map((doc) => doc.ref.delete()));
  };

  removeContactTagsIfNotAttached = async (
    names: string[],
    teamId: string
  ): Promise<void> => {
    await Promise.all(
      names.map((name) => this.removeContactTagIfNotAttached(name, teamId))
    );
  };

  removeContactTagIfNotAttached = async (
    name: string,
    teamId: string
  ): Promise<void> => {
    const contacts = await this.getContactsByContactTag(name, teamId);
    if (contacts.length > 0) return;
    await this.removeContactTag(name, teamId);
  };

  syncSlackIntegrations() {
    if (this._unsubscribeSlackIntegrations)
      this._unsubscribeSlackIntegrations();

    const query = db.collection(
      `companies/${this.signInCompany}/slackIntegrations`
    ) as firebase.firestore.CollectionReference<SlackIntegrationData>;
    this._unsubscribeSlackIntegrations = query.onSnapshot(
      (snapshot) => {
        const slackIntegrations: SlackIntegration[] = [];
        snapshot.forEach((doc) =>
          slackIntegrations.push(new SlackIntegration(doc))
        );
        this.slackIntegrations = slackIntegrations;
        this.slackIntegrationsLoading = false;
      },
      (err) => {
        console.error('Store.syncSlackIntegrations:', err);
      }
    );
  }

  get slackIntegration() {
    return this.slackIntegrations.length > 0 ? this.slackIntegrations[0] : null;
  }

  openDrawer = () => {
    this.drawerOpen = true;
  };

  closeDrawer = () => {
    this.drawerOpen = false;
  };

  checkMessage = (message: MessageLike) => {
    this.checkedMessages = [...this.checkedMessages, message];
  };

  uncheckMessage = (message: Message) => {
    this.checkedMessages = this.checkedMessages.filter(
      (m) => m.id !== message.id
    );
  };

  checkSent = (sent: Sent) => {
    this.checkedSent = [...this.checkedSent, sent];
  };

  uncheckSent = (sent: Sent) => {
    this.checkedSent = this.checkedSent.filter((s) => s.id !== sent.id);
  };

  setPermission = async (permission: string) => {
    this.notificationPermission = permission;
    if (permission !== 'granted') {
      // 拒否された場合
      console.log('permission not granted. permission:', permission);
      return;
    }

    const messaging = firebase.messaging();
    const currentToken = await messaging.getToken();
    if (currentToken) {
      // トークン取得成功
      const notificationToken = await getDocs(
        query(
          collection(this.me.ref, 'notificationTokens'),
          where('token', '==', currentToken)
        )
      );

      if (notificationToken.empty) {
        // 同じトークンが存在しない場合、保存する
        await addDoc(collection(this.me.ref, 'notificationTokens'), {
          token: currentToken,
          type: 'web',
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
        });

        if (Object.keys(this.me.notificationSettings).length === 0) {
          // 初めてトークンを許可した場合、通知を有効にする
          await updateDoc(this.me.ref, {
            'notificationSettings.web.enabled': true,
            'notificationSettings.web.types': [
              'newMessage',
              'mentionedMessage',
              'newComment',
              'newChat',
            ],
            createdAt: serverTimestamp(),
            updatedAt: serverTimestamp(),
          });
        }
      }
    } else {
      // トークン取得失敗
      this.notificationPermissionError = '通知トークンの取得に失敗しました。';
    }
  };

  requestPermission = async () => {
    if (!firebase.messaging.isSupported()) return;
    try {
      const permission = await Notification.requestPermission();
      if ('permissions' in navigator) {
        const notificationPerm = await navigator.permissions.query({
          name: 'notifications',
        });
        notificationPerm.onchange = async () => {
          await this.setPermission(notificationPerm.state);
        };
      }
      await this.setPermission(permission);
    } catch (e) {
      console.error('Store.requestPermission:', e);
      this.notificationPermissionError = '通知設定に失敗しました。';
    }
  };

  syncStars() {
    if (this._unsubscribeSyncStars) this._unsubscribeSyncStars();
    this.starsLoading = true;

    const query = this.companyCollection(
      `users/${this.me.id}/stars`
    ) as firebase.firestore.CollectionReference<StarData>;

    this._unsubscribeSyncStars = query.onSnapshot(
      (querySnapShot) => {
        this.stars = querySnapShot.docs.map((doc) => new Star(doc));
        if (this.starsLoading) {
          this.starsLoading = false;
        }
      },
      (err) => {
        console.error('Store.syncStars:', err);
      }
    );
  }

  get starsForMessages() {
    return this.stars.filter((s) => s.type === starTypes.message);
  }

  get starsForMessagesOrderByMessageDate() {
    return this.starsForMessages.sort((a, b) =>
      b.messageDate.diff(a.messageDate)
    );
  }

  isStarredThread = (messageId: string) =>
    this.isInThreadView
      ? this.privateStore.isStarredThread(messageId)
      : this.isStarredMessage(messageId);

  isStarredMessage = (messageId: string) =>
    this.starsForMessages.some((s) => s.messageId === messageId);

  /**
   * Toggle star in MessageList.
   */
  toggleThreadStar = async (message: MessageLike | SearchMessage) => {
    return this.isInThreadView
      ? this.privateStore.toggleThreadStar(message)
      : this.toggleStar(message.asMessage());
  };

  toggleStar = async (message: Message) => {
    if (this.isStarredMessage(message.id)) {
      await this.unstarMessage(message.id);
      return;
    }
    await this.starMessage(message.id);
    if (message.threadId) {
      await this.privateStore.starThread(
        message.teamId,
        message.threadId,
        message.data.date
      );
    }
  };

  starMessage = async (messageId: string) => {
    const messageSnapshot = await getDoc(
      this.doc('messages', messageId).withConverter(messageConverter)
    );
    if (!messageSnapshot.exists()) {
      console.error('Store.starMessage: !messageSnapshot.exists:', {
        messageId,
      });
      return;
    }
    const message = messageSnapshot.data();
    await this.companyCollection(`users/${this.me.id}/stars`).add({
      type: starTypes.message,
      messageId,
      teamId: message.teamId,
      companyId: this.signInCompany,
      messageDate: message.data.date,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    });
  };

  unstarMessage = async (messageId: string) => {
    const starredMessage = this.starsForMessages.find(
      (s) => s.messageId === messageId
    );
    if (!starredMessage) return;

    await starredMessage.ref.delete();
  };

  get sortedStarredMessages() {
    return this.starredMessages
      .slice()
      .sort((a, b) => b.date.valueOf() - a.date.valueOf());
  }

  get hasMoreStarredMessages() {
    return this.starredMessages.length >= this.starredMessagesSize;
  }

  unsubscribeSyncStarredMessages = () => {
    this.unsubscribeStarredMessages.forEach((unsubscribe) => unsubscribe());
  };

  loadMoreStarredMessages = () => {
    this.starredMessagesSize += this.messageLimitPerPage;
    this.syncStarredMessages();
  };

  syncStarredMessages = () => {
    if (this.isInThreadView) {
      this.threadStore.syncStarredThreads();
      return;
    }

    if (
      this.joinedTeams.length === 0 ||
      this.starsForMessagesOrderByMessageDate.length === 0
    ) {
      this.starredMessagesLoading = false;
      return;
    }

    this.starsForMessagesOrderByMessageDate
      .slice(0, this.starredMessagesSize)
      .filter((s) => !this.starredMessages.some((m) => m.id === s.messageId))
      .forEach((star) => this.syncStarredMessage(star.messageId));
  };

  syncStarredMessage = (id: string) => {
    const q = this.doc('messages', id).withConverter(messageConverter);
    const unsubscribe = onSnapshot(
      q,
      (doc) => {
        if (!doc.exists()) {
          console.error('Store.syncStarredMessage: !doc.exists:', {
            messageId: id,
          });
          return;
        }

        const message = doc.data();
        this.starredMessages = [
          ...this.starredMessages.filter((m) => m.id !== message.id),
          message,
        ];
        if (this.starredMessagesLoading) {
          this.starredMessagesLoading = false;
        }
      },
      (err) => {
        console.error(
          'Store.syncStarredMessage:',
          {
            messageId: id,
          },
          err
        );
      }
    );
    this.unsubscribeStarredMessages.push(unsubscribe);
  };

  // company

  syncCompany = async (companyId: string) => {
    this.unsubscribeCompany();

    const promised = createPromised<void>();
    this.unsubscribeCompany = db
      .collection('companies')
      .doc(companyId)
      .onSnapshot(
        async (doc) => {
          this.company = {
            id: doc.id,
            name: doc.get('name'),
            storageSize: doc.get('storageSize'),
            stripeCustomerId: doc.get('stripeCustomerId'),
            stripeProductId: doc.get('stripeProductId'),
            importImapSupported: doc.get('importImapSupported'),
            yaritoriAISupported: doc.get('yaritoriAISupported'),
            lineIntegrationCount: doc.get('lineIntegrationCount'),
            ipBlockCount: doc.get('ipBlockCount'),
            alertComplaintEmail: doc.get('alertComplaintEmail'),
          };
          await this.syncStripeProduct();
          promised.resolve();
        },
        (err) => {
          promised.reject(err);
        }
      );
    return promised.promise;
  };

  syncStripeProduct = async () => {
    this.unsubscribeStripeProduct();

    const productId = this.company?.stripeProductId;
    if (!productId) return;

    const promised = createPromised<void>();
    this.unsubscribeStripeProduct = db
      .collection('stripe')
      .doc('collections')
      .collection('products')
      .doc(productId)
      .onSnapshot(
        (docsnap) => {
          promised.resolve();
          if (!docsnap.exists) return;

          this.stripeProduct = docsnap.data();
        },
        (err) => {
          promised.reject(err);
          console.error(
            'Store.syncStripeProduct:',
            {
              companyId: this.company?.id,
            },
            err
          );
        }
      );
    return promised.promise;
  };

  get isV2Plan() {
    return Number(this.stripeProduct?.metadata?.version) >= 2;
  }

  get isScheduledDraftSupported() {
    return (
      this.stripeProduct?.metadata?.scheduledDraftSupported === 'true' ||
      Number(this.stripeProduct?.metadata?.version) < 5
    );
  }

  get isSlackNotificationV2Supported() {
    return (
      this.isV2Plan &&
      this.stripeProduct?.metadata?.slackNotificationSupported === 'true'
    );
  }

  get isCRMIntegrationSupported() {
    return (
      this.stripeProduct?.metadata?.crmIntegrationSupported === 'true' ||
      Number(this.stripeProduct?.metadata?.version) < 6
    );
  }

  get ipBlockCount() {
    return this.company?.ipBlockCount ?? 0;
  }

  get isSeenNotificationSupported() {
    return (
      this.stripeProduct?.metadata?.seenNotificationSupported === 'true' ||
      Number(this.stripeProduct?.metadata?.version) < 6
    );
  }

  get isSeenHistorySupported() {
    return (
      this.stripeProduct?.metadata?.seenHistorySupported === 'true' ||
      Number(this.stripeProduct?.metadata?.version) < 6
    );
  }

  get isAutoReplySupported() {
    return this.stripeProduct?.metadata?.autoReplySupported === 'true';
  }

  get isImportImapSupported() {
    return this.company?.importImapSupported;
  }

  get isYaritoriAISupported() {
    return (
      this.company?.yaritoriAISupported ||
      this.stripeProduct?.metadata?.yaritoriAISupported === 'true'
    );
  }

  get lineIntegrationCount(): number {
    return this.company?.lineIntegrationCount ?? 0;
  }

  get isImpressionClassificationSupported() {
    return (
      this.stripeProduct?.metadata?.impressionClassificationSupported === 'true'
    );
  }

  get isInThreadView() {
    return Boolean(this.preferences.threadView);
  }

  get invitable() {
    if (this.usersLoading || !this.stripeProduct) return false;

    const maxUsers = this.stripeProduct.metadata?.maxUsers;
    if (!maxUsers) return true;

    return maxUsers > this.users.length + this.invitedUsers.length;
  }

  get isEnterprise() {
    return this.stripeProduct.name === 'エンタープライズ';
  }

  async hasIPLimitedSetting() {
    try {
      if (this.ipBlockCount > 0) {
        const doc = await db
          .doc(`companies/${this.signInCompany}/settings/ipLimited`)
          .get();
        return doc.exists;
      }
    } catch {}
    return false;
  }

  /**
   * @param {string} path
   * @returns {CollectionReference}
   */
  companyCollection<T extends firebase.firestore.DocumentData>(path: string) {
    return db.collection(
      `companies/${this.signInCompany}/${path}`
    ) as firebase.firestore.CollectionReference<T>;
  }

  collection(path: string) {
    return collection(db9, `companies/${this.signInCompany}/${path}`);
  }

  doc(path: string, id: string) {
    return doc(db9, `companies/${this.signInCompany}/${path}`, id);
  }
}

const store = new Store();
export default store;
