import { useRouteMatch } from 'react-router-dom';
import { observer } from 'mobx-react';
import { SidebarLinkButton } from '../../../../components/sidebar/SidebarLinkButton/SidebarLinkButton';
import { ComponentPropsWithoutRef } from 'react';

type Props = ComponentPropsWithoutRef<typeof SidebarLinkButton>;

export const LeftSidebarLink = observer(
  ({ active, ...props }: Props): JSX.Element => {
    const isActive =
      useRouteMatch({
        path: props.to,
      }) != null;
    return <SidebarLinkButton active={active || isActive} {...props} />;
  }
);
