import { useMemo } from 'react';
import { DeliveryMessageCreateFormWithLogic } from '../../form/DeliveryMessageCreateForm/DeliveryMessageCreateFormWithLogic';
import { useAtomValue } from 'jotai/index';
import {
  deliveryMessagesAtom,
  deliveryMessagesLoadingAtom,
} from '../../../../atoms/firestore/deliveryMessages';
import { DeliveryMessagePreviewDialogWithLogic } from '../../list/DeliveryMessagePreviewDialog/DeliveryMessagePreviewDialogWithLogic';

type Props = {
  messageId: string | undefined;
};

export const DeliveryMessageDetailPage = ({ messageId }: Props) => {
  const messagesLoading = useAtomValue(deliveryMessagesLoadingAtom);
  const messages = useAtomValue(deliveryMessagesAtom);
  const message = useMemo(
    () => messages.find((m) => m.id === messageId),
    [messages, messageId]
  );
  if (!messageId) {
    return <DeliveryMessageCreateFormWithLogic messageId={undefined} />;
  }
  if (messagesLoading) {
    return null;
  }
  return message?.isSent ? (
    <DeliveryMessagePreviewDialogWithLogic messageId={messageId} />
  ) : (
    <DeliveryMessageCreateFormWithLogic messageId={messageId} />
  );
};
