import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNoteAdd = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={20}
    fill="none"
    viewBox="0 0 16 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M7.25 12.925V15.4c0 .213.072.39.217.534a.732.732 0 0 0 .537.216.731.731 0 0 0 .746-.75v-2.475h2.5c.213 0 .39-.072.534-.217a.732.732 0 0 0 .216-.537.731.731 0 0 0-.75-.746h-2.5v-2.5a.724.724 0 0 0-.217-.534.732.732 0 0 0-.537-.216.731.731 0 0 0-.746.75v2.5h-2.5a.723.723 0 0 0-.534.217.732.732 0 0 0-.216.537.731.731 0 0 0 .75.746h2.5ZM1.5 20c-.4 0-.75-.15-1.05-.45-.3-.3-.45-.65-.45-1.05v-17C0 1.1.15.75.45.45.75.15 1.1 0 1.5 0h8.4c.207 0 .405.042.593.125.188.083.349.192.482.325l4.575 4.575c.133.133.242.294.325.482.083.188.125.386.125.593v12.4c0 .4-.15.75-.45 1.05-.3.3-.65.45-1.05.45h-13ZM9.775 5.4V1.5H1.5v17h13V6.15h-3.975a.726.726 0 0 1-.534-.216.726.726 0 0 1-.216-.534Z"
    />
  </svg>
);
export default SvgNoteAdd;
