import * as React from 'react';
import type { SVGProps } from 'react';
const SvgCreateMessageTemplate = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M8.725 17.75h6.55c.213 0 .39-.072.534-.217a.732.732 0 0 0 .216-.537.731.731 0 0 0-.75-.746h-6.55a.723.723 0 0 0-.534.217.732.732 0 0 0-.216.537.731.731 0 0 0 .75.746Zm0-4.25h6.55c.213 0 .39-.072.534-.217a.732.732 0 0 0 .216-.537.731.731 0 0 0-.75-.746h-6.55a.723.723 0 0 0-.534.217.732.732 0 0 0-.216.537.731.731 0 0 0 .75.746ZM5.5 22c-.4 0-.75-.15-1.05-.45-.3-.3-.45-.65-.45-1.05v-17c0-.4.15-.75.45-1.05.3-.3.65-.45 1.05-.45h8.4c.207 0 .405.042.593.125.188.083.349.192.482.325l4.575 4.575c.133.133.242.294.325.482.083.188.125.386.125.593v12.4c0 .4-.15.75-.45 1.05-.3.3-.65.45-1.05.45h-13Zm8.275-14.6V3.5H5.5v17h13V8.15h-3.975a.726.726 0 0 1-.534-.216.726.726 0 0 1-.216-.534Z"
    />
  </svg>
);
export default SvgCreateMessageTemplate;
