import Avatar from '../../../Common/Avatar';
import { Comment } from '../../../Common/Comment';
import React from 'react';
import styled from 'styled-components';
import * as color from '../../../../color';
import { User } from 'lib';
import { useStore } from 'hooks/useStore';
import { DefaultButton } from '../../../Common/Button';

type Props = {
  user: User;
  onClick: () => void;
  inGroup?: boolean;
};

export const GroupMember = ({
  user,
  onClick,
  inGroup = false,
}: Props): JSX.Element => {
  const { me } = useStore();

  const isMe = user.id === me.id;

  return (
    <Wrapper>
      <Profile>
        <Avatar user={user} />
        <NameAndEmail>
          <Name>
            {user.name}
            {isMe && <Me>（自分）</Me>}
          </Name>
          <Comment>{user.email}</Comment>
        </NameAndEmail>
      </Profile>
      <ButtonWrapper>
        <Button type={inGroup ? 'danger' : 'primary'} onClick={onClick}>
          {inGroup ? '除外' : '追加'}
        </Button>
      </ButtonWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  margin-top: 10px;
  display: flex;
  align-items: center;
`;

const Profile = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

const Name = styled.div`
  font-size: 13px;
  font-weight: bold;
  color: ${color.common.text2};
`;

const Me = styled.span`
  font-size: 11px;
  font-weight: normal;
  color: ${color.common.text3};
`;

const NameAndEmail = styled.div`
  margin-left: 10px;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ButtonWrapper = styled.div`
  margin-left: 12px;
`;

const Button = styled(DefaultButton)`
  height: 40px;
  width: 100px;
`;
