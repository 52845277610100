import { Input } from '../../../../../components/forms';
import { InputGroup } from '../../../../../components/forms/InputGroup/InputGroup';

type Props = {
  value: string;
  onChange: (value: string) => void;
  readonly: boolean;
  largeLabel?: boolean;
};

export const YaritoriNameField = ({
  value,
  onChange,
  readonly,
  largeLabel,
}: Props) => {
  return (
    <InputGroup
      label={
        <span className={largeLabel ? 'text-base font-bold' : undefined}>
          yaritori上での表示名
        </span>
      }
    >
      <Input
        placeholder="〇〇株式会社"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        disabled={readonly}
      />
    </InputGroup>
  );
};
