import React from 'react';
import CreateDraftButton from './CreateDraftButton';

export const FloatingCreateDraftButton = () => {
  return (
    <div className="fixed bottom-4 right-4 z-10">
      <CreateDraftButton shadow={true} />
    </div>
  );
};
