import { ComponentPropsWithoutRef } from 'react';

type Props = ComponentPropsWithoutRef<'svg'>;

export const InboxesNoData = (props: Props) => {
  return (
    <svg
      width="188"
      height="184"
      viewBox="0 0 188 184"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2010_30279)">
        <path
          d="M119.35 41.381H68.6917C67.5373 41.3824 66.4306 41.8429 65.6143 42.6613C64.798 43.4798 64.3388 44.5894 64.3374 45.7469V158.634L63.7568 158.812L51.3297 162.628C50.7407 162.808 50.1046 162.746 49.5609 162.456C49.0172 162.166 48.6104 161.672 48.4298 161.082L11.4646 40.016C11.2847 39.4254 11.346 38.7874 11.6351 38.2422C11.9241 37.697 12.4172 37.2891 13.0061 37.1083L32.1563 31.2289L87.6736 14.1903L106.824 8.31088C107.115 8.22095 107.421 8.18953 107.725 8.2184C108.029 8.24728 108.323 8.3359 108.593 8.47918C108.862 8.62247 109.101 8.8176 109.295 9.05342C109.489 9.28925 109.635 9.56112 109.724 9.8535L119.172 40.7989L119.35 41.381Z"
          fill="#F2F2F2"
        />
        <path
          d="M130.405 40.799L119.017 3.50263C118.827 2.8812 118.517 2.3033 118.105 1.80194C117.693 1.30059 117.186 0.885604 116.614 0.580707C116.041 0.275811 115.415 0.0869728 114.77 0.0249921C114.124 -0.0369886 113.473 0.0291024 112.854 0.219482L85.9297 8.48265L30.4154 25.5242L3.49138 33.7903C2.24063 34.1753 1.19336 35.0423 0.579444 36.2008C-0.034471 37.3593 -0.164875 38.7146 0.216861 39.9694L39.1385 167.433C39.4486 168.446 40.0743 169.332 40.9238 169.963C41.7733 170.593 42.8019 170.933 43.8586 170.934C44.3477 170.935 44.834 170.861 45.3013 170.716L63.7577 165.052L64.3383 164.872V164.263L63.7577 164.441L45.13 170.16C44.026 170.498 42.8336 170.382 41.8143 169.839C40.7951 169.296 40.0322 168.37 39.693 167.264L0.77433 39.7977C0.60628 39.2496 0.54771 38.6737 0.601972 38.1029C0.656234 37.532 0.822258 36.9776 1.09055 36.4712C1.35883 35.9648 1.72412 35.5164 2.16547 35.1518C2.60682 34.7872 3.11557 34.5134 3.66259 34.3462L30.5867 26.0801L86.1011 9.04149L113.025 0.77541C113.44 0.64845 113.871 0.583715 114.305 0.58331C115.236 0.585406 116.142 0.886348 116.89 1.44206C117.638 1.99777 118.189 2.77904 118.462 3.67145L129.798 40.799L129.978 41.3811H130.582L130.405 40.799Z"
          fill="#3F3D56"
        />
        <path
          d="M35.613 37.2035C35.0535 37.2031 34.5089 37.0229 34.059 36.6894C33.6091 36.3559 33.2777 35.8866 33.1133 35.3504L29.3743 23.105C29.2738 22.7761 29.2389 22.4305 29.2717 22.0881C29.3044 21.7457 29.4041 21.413 29.5651 21.1092C29.726 20.8054 29.9451 20.5364 30.2098 20.3175C30.4745 20.0986 30.7796 19.9341 31.1076 19.8335L82.1803 4.15592C82.8429 3.9532 83.5586 4.02234 84.1703 4.34815C84.7821 4.67396 85.2399 5.22983 85.4434 5.89375L89.1824 18.1393C89.3844 18.8036 89.3154 19.5211 88.9905 20.1345C88.6655 20.7478 88.1112 21.2069 87.4492 21.411L36.3763 37.0885C36.129 37.1646 35.8717 37.2034 35.613 37.2035Z"
          fill="#4984F2"
        />
        <path
          d="M55.1983 13.0845C58.4047 13.0845 61.004 10.4783 61.004 7.26332C61.004 4.04837 58.4047 1.44214 55.1983 1.44214C51.9919 1.44214 49.3926 4.04837 49.3926 7.26332C49.3926 10.4783 51.9919 13.0845 55.1983 13.0845Z"
          fill="#4984F2"
        />
        <path
          d="M55.1998 10.9495C57.2302 10.9495 58.8762 9.29911 58.8762 7.2633C58.8762 5.2275 57.2302 3.57715 55.1998 3.57715C53.1694 3.57715 51.5234 5.2275 51.5234 7.2633C51.5234 9.29911 53.1694 10.9495 55.1998 10.9495Z"
          fill="white"
        />
        <path
          d="M174.938 169.447H76.821C76.1668 169.446 75.5396 169.185 75.077 168.722C74.6145 168.258 74.3543 167.629 74.3535 166.973V49.0941C74.3542 48.4382 74.6144 47.8094 75.077 47.3455C75.5396 46.8817 76.1668 46.6208 76.821 46.6201H174.938C175.592 46.6209 176.219 46.8818 176.682 47.3456C177.144 47.8094 177.404 48.4382 177.405 49.0941V166.973C177.404 167.629 177.144 168.258 176.682 168.722C176.219 169.185 175.592 169.446 174.938 169.447Z"
          fill="#E6E6E6"
        />
        <path
          d="M129.798 40.7988H68.6927C67.3844 40.8007 66.1303 41.3226 65.2052 42.2501C64.2802 43.1776 63.7597 44.4351 63.7578 45.7468V164.441L64.3384 164.263V45.7468C64.3398 44.5894 64.799 43.4797 65.6153 42.6612C66.4316 41.8428 67.5383 41.3824 68.6927 41.3809H129.978L129.798 40.7988ZM183.066 40.7988H68.6927C67.3844 40.8007 66.1303 41.3226 65.2052 42.2501C64.2802 43.1776 63.7597 44.4351 63.7578 45.7468V179.052C63.7597 180.364 64.2802 181.621 65.2052 182.549C66.1303 183.476 67.3844 183.998 68.6927 184H183.066C184.374 183.998 185.628 183.476 186.553 182.549C187.478 181.621 187.999 180.364 188 179.052V45.7468C187.999 44.4351 187.478 43.1776 186.553 42.2501C185.628 41.3226 184.374 40.8007 183.066 40.7988ZM187.42 179.052C187.418 180.209 186.959 181.319 186.143 182.138C185.327 182.956 184.22 183.416 183.066 183.418H68.6927C67.5383 183.416 66.4316 182.956 65.6153 182.138C64.799 181.319 64.3398 180.209 64.3384 179.052V45.7468C64.3398 44.5894 64.799 43.4797 65.6153 42.6612C66.4316 41.8428 67.5383 41.3824 68.6927 41.3809H183.066C184.22 41.3824 185.327 41.8428 186.143 42.6612C186.959 43.4797 187.418 44.5894 187.42 45.7468V179.052Z"
          fill="#3F3D56"
        />
        <path
          d="M152.586 53.6055H99.1731C98.4805 53.6047 97.8164 53.3285 97.3266 52.8374C96.8368 52.3463 96.5613 51.6805 96.5605 50.986V38.1793C96.5613 37.4848 96.8368 36.819 97.3266 36.3279C97.8164 35.8368 98.4805 35.5606 99.1731 35.5598H152.586C153.278 35.5606 153.943 35.8368 154.432 36.3279C154.922 36.819 155.198 37.4848 155.198 38.1793V50.986C155.198 51.6805 154.922 52.3463 154.432 52.8374C153.943 53.3285 153.278 53.6047 152.586 53.6055Z"
          fill="#4984F2"
        />
        <path
          d="M125.878 36.4329C129.084 36.4329 131.684 33.8267 131.684 30.6117C131.684 27.3968 129.084 24.7905 125.878 24.7905C122.672 24.7905 120.072 27.3968 120.072 30.6117C120.072 33.8267 122.672 36.4329 125.878 36.4329Z"
          fill="#4984F2"
        />
        <path
          d="M125.878 34.1575C127.831 34.1575 129.414 32.57 129.414 30.6118C129.414 28.6536 127.831 27.0662 125.878 27.0662C123.925 27.0662 122.342 28.6536 122.342 30.6118C122.342 32.57 123.925 34.1575 125.878 34.1575Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2010_30279">
          <rect width="188" height="184" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
