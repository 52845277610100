import { User } from 'lib';
import { makeAutoObservable } from 'mobx';
import channelService, { canShowChannel } from '../service/channel.js';

export class ChannelStore {
  unreadCount = 0;

  constructor() {
    makeAutoObservable(this);
  }

  init(): void {
    channelService.listenBadgeChanged((unreadCount) => {
      this.unreadCount = unreadCount;
    });
  }

  boot(me: User, companyId: string, companyName: string): void {
    if (this.canShowChannel(window.location.pathname)) {
      channelService.boot(me.id, me.name, me.email, companyId, companyName);
    }
  }

  bootAsAnonymous(): void {
    if (this.canShowChannel(window.location.pathname)) {
      channelService.boot();
    }
  }

  /**
   *
   * @param {string} pathName
   * @returns
   */
  canShowChannel(pathName: string): boolean {
    return canShowChannel(pathName);
  }

  showMessenger(): void {
    channelService.showMessenger();
  }
}
