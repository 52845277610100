import { ComponentProps } from 'react';
import { SidebarSectionTitle } from '../../../components/sidebar/SidebarSectionTitle/SidebarSectionTitle';
import { useAtomValue } from 'jotai/index';
import { tagCountAtomFamily } from '../../../atoms/firestore/count/tag';

type Props = ComponentProps<typeof SidebarSectionTitle> & {
  teamId: string;
  tagId: string;
};
export const SidebarSectionTitleWithCount = ({
  teamId,
  tagId,
  ...props
}: Props): JSX.Element => {
  const count = useAtomValue(
    tagCountAtomFamily({
      teamId,
      tagId,
    })
  );
  return (
    <SidebarSectionTitle {...props} count={count}>
      {props.children}
    </SidebarSectionTitle>
  );
};
