import { ComponentProps, FC } from 'react';
import { ChatSettingsDialog } from './ChatSettingsDialog';
import { useAtomValue } from 'jotai';
import { usersAtom } from '../../../atoms/firestore/user';
import { meAtom } from '../../../atoms/auth';
import { Room } from '../../../firestore/entity/room';
import { deleteField, serverTimestamp, updateDoc } from 'firebase/firestore';

type DialogProps = ComponentProps<typeof ChatSettingsDialog>;

type Props = Pick<DialogProps, 'open' | 'onOpenChange'> & { room: Room };

export const ChatUpdateDialogWithLogic: FC<Props> = ({
  open,
  onOpenChange,
  room,
}) => {
  const me = useAtomValue(meAtom);
  const users = useAtomValue(usersAtom);

  const members: DialogProps['members'] = users
    .filter((u) => u.id !== me.id)
    .map((u) => ({
      id: u.id,
      name: u.name,
      email: u.email,
      avatar: u,
    }));

  const onSubmit = async (subject: string, members: string[]) => {
    await updateDoc(room.ref, {
      subject: subject.length ? subject : deleteField(),
      members,
      updatedAt: serverTimestamp(),
    });
    onOpenChange?.(false);
    return true;
  };

  return (
    <ChatSettingsDialog
      open={open}
      onOpenChange={onOpenChange}
      members={members}
      onSubmit={onSubmit}
      defaultValues={{
        subject: room.subject ?? '',
        memberIds: room.members,
      }}
    />
  );
};
