import { useMemo, useState } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import level from '../../../../../../font/level';
import moment from 'moment';
import { MessageItemWrapper } from 'components/MessageList';
import { Checkbox } from 'antd';
import { isSP } from '../../../../../../shared/util';

export default function SentItem({
  item,
  to,
  toFunc,
  checkSent,
  uncheckSent,
  checkedSent,
}) {
  const [hover, setHover] = useState(false);
  const checked = checkedSent.some((s) => s.id === item.id);
  const location = useLocation();
  const match = useMemo(
    () =>
      matchPath(decodeURI(location.pathname), {
        path: decodeURI(to),
      }),
    [location.pathname, to]
  );
  const onClickCheckboxWrapper = (e) => {
    e.stopPropagation();
    if (checked) {
      uncheckSent(item);
      return;
    }
    checkSent(item);
  };
  const showCheckbox = hover || checkedSent.length > 0;
  return (
    <MessageItemWrapper
      onClick={() => toFunc(to)}
      selected={!!match}
      onMouseEnter={() => !isSP() && setHover(true)}
      onMouseLeave={() => !isSP() && setHover(false)}
    >
      <div className="pl-5">
        <Header>
          {showCheckbox && (
            <div className="absolute -left-5 ">
              <div
                className="flex w-4 justify-center"
                onClick={onClickCheckboxWrapper}
              >
                <Checkbox checked={checked} />
              </div>
            </div>
          )}
          <Sender>{item.to.join(' ')}</Sender>
          <SentTime>
            {moment(item.date).isSame(moment(), 'day')
              ? item.date.format('HH:mm')
              : item.date.format('M月D日')}
          </SentTime>
        </Header>
        <Title>{item.subject.length > 0 ? item.subject : 'ありません'}</Title>
        <Body>{item.text.length > 0 ? item.text : 'ありません'}</Body>
      </div>
    </MessageItemWrapper>
  );
}

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
`;

const Sender = styled.div`
  padding-right: 8px;
  font-size: ${level[1]};
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const SentTime = styled.div`
  font-size: ${level[0]};
  color: #bcbccb;
  white-space: nowrap;
`;

const Title = styled.div`
  font-size: ${level[1]};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Body = styled.div`
  font-size: ${level[1]};
  color: #bcbccb;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
