import { estimateServerTimestampsOption } from './common';
import moment from 'moment';
import firebase from 'firebase/compat/app';

export interface InvitedUserData {
  email: string;
  role: string;
  invitationId: string;
  expiredAt: firebase.firestore.Timestamp;
}

export class InvitedUser {
  private _id: string;
  private _data: InvitedUserData;
  private _ref: firebase.firestore.DocumentReference<InvitedUserData>;
  private _expiredAt: moment.Moment;

  constructor(doc: firebase.firestore.DocumentSnapshot<InvitedUserData>) {
    this._id = doc.id;
    const data = doc.data(estimateServerTimestampsOption);
    if (!data) {
      throw new Error(`no data for invitedUser#${doc.id}`);
    }
    this._data = data;
    this._ref = doc.ref;
    this._expiredAt = moment(this._data.expiredAt.toMillis()); // 毎回生成するのはコストがかかるため
  }

  get id() {
    return this._id;
  }

  get ref() {
    return this._ref;
  }

  get email() {
    return this._data.email;
  }

  get role() {
    return this._data.role;
  }

  get expiredAt() {
    return this._expiredAt;
  }

  get isExpired() {
    return moment().isAfter(this.expiredAt);
  }

  get iconName() {
    if (this.email.length === 0) return '';
    return this.email.length > 1
      ? (this.email[0] + this.email[1]).toUpperCase()
      : this.email[0];
  }

  get iconBackgroundColor() {
    return '';
  }

  get invitationId() {
    return this._data.invitationId;
  }
}
