import * as React from 'react';
import type { SVGProps } from 'react';
const SvgPayments = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M11.25 11.25a2.411 2.411 0 0 1-1.77-.729 2.41 2.41 0 0 1-.73-1.77c0-.695.244-1.286.73-1.772a2.41 2.41 0 0 1 1.77-.729 2.41 2.41 0 0 1 1.771.73c.487.485.73 1.076.73 1.77 0 .695-.243 1.285-.73 1.771a2.411 2.411 0 0 1-1.77.73Zm-6.666 2.917c-.344 0-.638-.123-.883-.367a1.204 1.204 0 0 1-.367-.883V4.583c0-.343.122-.638.367-.882.245-.245.54-.368.883-.368h13.333c.344 0 .638.123.883.368.245.244.367.539.367.882v8.334c0 .344-.122.638-.367.883a1.204 1.204 0 0 1-.883.367H4.584Zm2.083-1.25h9.167c0-.583.201-1.077.604-1.48a2.011 2.011 0 0 1 1.48-.603V6.667a2.011 2.011 0 0 1-1.48-.604 2.012 2.012 0 0 1-.604-1.48H6.667c0 .584-.201 1.077-.604 1.48a2.012 2.012 0 0 1-1.479.604v4.167c.583 0 1.076.2 1.48.604.402.402.603.896.603 1.479Zm-4.583 3.75c-.344 0-.638-.123-.883-.367a1.204 1.204 0 0 1-.367-.883V6.458c0-.177.06-.325.18-.445a.61.61 0 0 1 .449-.18.61.61 0 0 1 .621.625v8.959h13.958c.177 0 .326.06.446.18.12.121.18.27.18.449a.61.61 0 0 1-.625.621H2.083Z"
    />
  </svg>
);
export default SvgPayments;
