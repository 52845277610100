import { ReactNode } from 'react';
import { tv } from 'tailwind-variants';
import { SettingPageTitle } from '../SettingPageTitle';

type Props = {
  title?: ReactNode;
  full?: boolean;
  children: ReactNode;
};

const container = tv({
  base: 'max-w-full',
  variants: {
    full: {
      true: 'w-full',
      false: 'w-[750px]',
    },
  },
  defaultVariants: {
    full: false,
  },
});

export const SettingPageContainer = ({
  title,
  full = false,
  children,
}: Props) => {
  return (
    <div className="px-4 py-2">
      {title && <SettingPageTitle className="pb-6">{title}</SettingPageTitle>}
      <div className={container({ full })}>{children}</div>
    </div>
  );
};
