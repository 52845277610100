import * as React from 'react';
import type { SVGProps } from 'react';
const SvgMore = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M10.5 4.476a1.5 1.5 0 1 0 3 0 1.5 1.5 0 0 0-3 0Zm0 7.5a1.5 1.5 0 1 0 3 0 1.5 1.5 0 0 0-3 0Zm0 7.5a1.5 1.5 0 1 0 3 0 1.5 1.5 0 0 0-3 0Z"
    />
  </svg>
);
export default SvgMore;
