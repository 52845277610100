import * as React from 'react';
import type { SVGProps } from 'react';
const SvgLoading = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="currentColor"
      d="M8 15.5a7.452 7.452 0 0 1-2.944-.581 7.445 7.445 0 0 1-2.381-1.594 7.445 7.445 0 0 1-1.594-2.381A7.453 7.453 0 0 1 .5 8c0-1.05.194-2.031.581-2.944a7.445 7.445 0 0 1 1.594-2.381 7.445 7.445 0 0 1 2.381-1.594A7.452 7.452 0 0 1 8 .5a.54.54 0 0 1 .394.169.54.54 0 0 1 .168.393.54.54 0 0 1-.168.394.54.54 0 0 1-.394.169c-1.763 0-3.266.622-4.51 1.866C2.248 4.734 1.626 6.237 1.626 8c0 1.762.622 3.266 1.866 4.51C4.734 13.752 6.237 14.374 8 14.374c1.762 0 3.266-.622 4.51-1.866 1.243-1.243 1.865-2.747 1.865-4.509a.54.54 0 0 1 .169-.394.54.54 0 0 1 .788 0A.54.54 0 0 1 15.5 8c0 1.05-.194 2.031-.581 2.944a7.445 7.445 0 0 1-1.594 2.381 7.445 7.445 0 0 1-2.381 1.594A7.453 7.453 0 0 1 8 15.5Z"
    />
  </svg>
);
export default SvgLoading;
