import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Skeleton } from 'antd';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import level from '../../../font/level';
import Attachments from './attachments';
import SentMessage from '../../Top/Conversations/Conversation/Sent/sentMessage';

class Index extends Component {
  render() {
    const { sent, loading, attachments, onStorageSentLoaded } = this.props;
    return (
      <BodyWrapper>
        {loading && <Skeleton loading={loading} active />}
        {!loading && (
          <>
            <MessageInfo>
              <div>
                <div style={{ display: 'flex' }}>
                  <MessageInfoText>{sent.from}</MessageInfoText>
                </div>
                {sent.to && (
                  <div style={{ display: 'flex' }}>
                    <MessageInfoText>To:</MessageInfoText>
                    {sent.to.map((address) => {
                      return (
                        <MessageInfoText key={address}>
                          {`　${address}`}
                        </MessageInfoText>
                      );
                    })}
                  </div>
                )}
                {sent.cc && sent.cc.length > 0 && (
                  <div style={{ display: 'flex' }}>
                    <MessageInfoText>Cc:</MessageInfoText>
                    {sent.cc.map((address) => {
                      return (
                        <MessageInfoText key={address}>
                          {`　${address}`}
                        </MessageInfoText>
                      );
                    })}
                  </div>
                )}
                {sent.bcc && sent.bcc.length > 0 && (
                  <div style={{ display: 'flex' }}>
                    <MessageInfoText>Bcc:</MessageInfoText>
                    {sent.bcc.map((address) => {
                      return (
                        <MessageInfoText key={address}>
                          {`　${address}`}
                        </MessageInfoText>
                      );
                    })}
                  </div>
                )}
              </div>
              <div>
                <MessageInfoText>
                  {sent.date.format('YYYY年M月D日 HH:mm')}
                </MessageInfoText>
              </div>
            </MessageInfo>
            <Attachments attachments={attachments} />
            <SentMessage
              sent={sent}
              print={true}
              onStorageSentLoaded={onStorageSentLoaded}
            />
          </>
        )}
      </BodyWrapper>
    );
  }
}

export default compose(withRouter, inject('store'), observer)(Index);

const MessageInfo = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
`;

const MessageInfoText = styled.p`
  margin-bottom: 2px;
  font-size: ${level[1]};
`;

const BodyWrapper = styled.div`
  word-break: break-word;

  @media print {
    scrollbar-width: none;

    & *::-webkit-scrollbar{
      display: none;
      }

    // 廃止プロパティだが一部のメーラーが混入させてくるため無効化
    & div.WordSection1 {
      page: initial;
    }
`;
