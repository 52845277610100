import Scheduled from './Conversation/Scheduled';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import * as color from '../../../color';
import media from 'styled-media-query';
import { SPBackButton } from './Conversation/SPBackButton';
import { Tooltip } from 'antd';
import { companyDoc } from '../../../firestore';
import { draftConverter } from '../../../firestore/entity/draft';
import { useSubscribeDocument } from '../../../hooks/firestore/subscription';
import { Conversation } from './Conversation/Conversation';
import { useAtomValue } from 'jotai';
import { threadViewAtom } from '../../../atoms/auth';

export const ScheduledConversation = (): JSX.Element => {
  const { draftId } = useParams<{ draftId: string }>();
  const threadView = useAtomValue(threadViewAtom);

  const draft = useSubscribeDocument(
    companyDoc('drafts', draftId, draftConverter)
  );
  if (!draft) return <div />;

  if (draft.isReply) {
    // 返信の場合は会話を表示
    return (
      <Conversation
        threadView={threadView}
        messageId={draft.inReplyToMessageId!}
        key={draft.id}
      />
    );
  }

  return (
    <Wrapper>
      <Header>
        <SPBackButton backLink="/me/scheduled" />
        <div className="subject">
          <Tooltip title={draft.subject}>{draft.subject}</Tooltip>
        </div>
      </Header>
      <Scheduled draft={draft} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  flex: 1;
  overflow: auto;
  background-color: ${color.sidebar.background.normal};

  ${media.lessThan('small')`
    height: 100vh;
  `};
`;

const Header = styled.div`
  position: sticky;
  top: 0;
  background-color: ${color.sidebar.background.normal};
  z-index: 2;
  padding: 10px;

  & > .subject {
    font-size: 18px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;
