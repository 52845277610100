import { forwardRef } from 'react';
import { tv } from 'tailwind-variants';
import TextareaAutosize, {
  TextareaAutosizeProps,
} from 'react-textarea-autosize';

type Props = TextareaAutosizeProps & {
  error?: boolean;
};

const textarea = tv({
  base: 'min-h-[64px] w-full rounded-lg border border-sumi-300 px-2 py-1.5 text-sm leading-6 placeholder-sumi-500 placeholder:select-none placeholder:opacity-[1] focus-visible:outline focus-visible:outline-2 focus-visible:outline-sea-200 disabled:cursor-not-allowed disabled:text-sumi-500',
  variants: {
    error: {
      true: 'border border-sun-500',
    },
  },
});

export const Textarea = forwardRef<HTMLTextAreaElement, Props>(
  ({ className, error = false, ...props }, ref) => {
    return (
      <TextareaAutosize
        className={textarea({ error, className })}
        {...props}
        ref={ref}
      />
    );
  }
);

Textarea.displayName = 'Textarea';
