import './WysiwygEditor.css';
import './message.css';
import { StarterKit } from '@tiptap/starter-kit';
import {
  createDocument,
  Editor,
  EditorContent,
  PureEditorContent,
  useEditor,
} from '@tiptap/react';
import { Placeholder } from '@tiptap/extension-placeholder';
import { TextAlign } from '@tiptap/extension-text-align';
import { FontSize } from '../extension/fontSize';
import { Underline } from '@tiptap/extension-underline';
import { FontFamily } from '../extension/fontFamily';
import { Color } from '@tiptap/extension-color';
import { ExtendedTextStyle } from '../extension/extendedTextStyle';
import { ExtendedImage } from '../extension/extendedImage';
import { Link } from '@tiptap/extension-link';
import { LinkBubbleMenu } from '../LinkBubbleMenu/LinkBubbleMenu';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import {
  ComponentPropsWithoutRef,
  forwardRef,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { Highlight } from '@tiptap/extension-highlight';
import { twMerge } from 'tailwind-merge';
import { flushSync } from 'react-dom';
import Table from '@tiptap/extension-table';
import { useWindowEvent } from '../../../../hooks/useWindowEvent';
import { Fragment, Node, ResolvedPos, Slice } from 'prosemirror-model';
import { Transaction } from '@tiptap/pm/state';
import { DisableShiftEnter } from '../extension/disableShiftEnter';
import { applyMessageStyles } from 'lib';
import Document from '@tiptap/extension-document';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import {
  handleHTML,
  VDocumentFragment,
  VElement,
  VHTMLDocument,
} from 'zeed-dom';

type SetHtmlOptions = {
  addToHistory?: boolean;
  emitUpdate?: boolean;
};

export type EditorHandle = {
  editor: Editor | null;
  isPlaintextMode: boolean;
  getHtml: () => string;
  setHtml: (html: string, options?: SetHtmlOptions) => void;
  getText: () => string;
  insertText: (text: string) => void;
  insertImage: (src: string, contentId: string) => void;
  hasImage: (contentId: string) => Promise<boolean>;
  deleteImage: (contentId: string) => Promise<boolean>;
  hasUploadingImages: () => Promise<boolean>;
};

export type WysiwygEditorProps = ComponentPropsWithoutRef<'div'> & {
  defaultValue?: string;
  placeholder?: string;
  initEditorHandle?: (handle: EditorHandle) => void;
  editorClassName?: string;
  readonly?: boolean;
  disabled?: boolean;
  children?: ReactNode;
  uploadImage?: (
    file: File
  ) => Promise<{ src: string; contentId: string } | undefined>;
  onInsertImage?: (contentId: string) => void;
  onDeleteImage?: (contentId: string) => void;
  getBubbleMenuContainer?: () => HTMLElement;
  autoFocus?: boolean;
  isPlaintextMode?: boolean;
};

// TiptapのflushSyncに問題がある
// https://github.com/ueberdosis/tiptap/issues/4492
PureEditorContent.prototype.maybeFlushSync = function maybeFlushSync(fn) {
  if (this.initialized) {
    flushSync(fn);
    this.initialized = false;
  } else {
    fn();
  }
};

const extensions = [
  StarterKit.configure({
    heading: false,
    horizontalRule: false,
  }),
  ExtendedTextStyle,
  Underline,
  FontFamily,
  Color,
  FontSize,
  Highlight.configure({
    multicolor: true,
  }),
  TextAlign.configure({
    types: ['heading', 'paragraph'],
  }),
  Link.configure({
    openOnClick: false,
  }),
  Table.configure({
    resizable: true,
  }),
  TableRow,
  TableHeader,
  TableCell,
  DisableShiftEnter,
];

export const WysiwygEditor = forwardRef<HTMLDivElement, WysiwygEditorProps>(
  (
    {
      defaultValue,
      placeholder,
      initEditorHandle,
      editorClassName,
      readonly = false,
      disabled = false,
      uploadImage,
      onInsertImage,
      onDeleteImage,
      getBubbleMenuContainer,
      autoFocus,
      isPlaintextMode = false,
      children,
      ...props
    },
    ref
  ) => {
    const initializedRef = useRef(false);
    const _extensions = useMemo(() => {
      if (isPlaintextMode) {
        return [Document, Paragraph, Text];
      }
      return [
        ...extensions,
        ...(placeholder
          ? [
              Placeholder.configure({
                placeholder,
                emptyEditorClass: 'is-editor-empty text-sumi-500',
              }),
            ]
          : []),
        ExtendedImage.configure({
          inline: true,
          allowBase64: true,
          upload: uploadImage,
        }),
      ];
    }, [isPlaintextMode]);
    const editor = useEditor({
      extensions: _extensions,
      editorProps: {
        clipboardTextParser,
        transformPastedHTML,
        attributes: {
          class: twMerge('outline-none min-h-full', editorClassName),
        },
      },
      editable: !disabled && !readonly,
    });

    useEffect(() => {
      editor?.setEditable(!disabled && !readonly);
    }, [editor, disabled, readonly]);

    const setHtml = useCallback(
      (
        html: string,
        options: SetHtmlOptions = {
          addToHistory: true,
          emitUpdate: true,
        }
      ) => {
        if (!editor) {
          return;
        }

        html = convertQuillHtml(html);

        const document = createDocument(html, editor.schema);
        const tr = editor.state.tr
          .replaceWith(0, editor.state.doc.content.size, document)
          .setMeta('preventUpdate', !options.emitUpdate)
          .setMeta('addToHistory', options.addToHistory === true);
        editor.view.dispatch(tr);
      },
      [editor]
    );

    // 上記のflushSyncのバグの回避
    useEffect(() => {
      if (initializedRef.current) {
        return;
      }
      if (editor) {
        initializedRef.current = true;
        if (defaultValue) {
          setTimeout(() =>
            setHtml(defaultValue, {
              addToHistory: false,
              emitUpdate: false,
            })
          );
        }
      }
    }, [editor, defaultValue, setHtml]);

    useEffect(() => {
      if (!editor) {
        return;
      }
      initEditorHandle?.({
        editor,
        isPlaintextMode,
        getHtml: () => editor.getHTML(),
        setHtml,
        getText: () => editor.getText({ blockSeparator: '\n' }),
        insertText: (text) => {
          if (!editor) {
            return;
          }
          const transaction = editor.state.tr.insertText(text);
          editor.view.dispatch(transaction);
          editor.commands.focus();
        },
        insertImage: (src, contentId) => {
          editor
            .chain()
            .focus()
            .setImage({ src, 'data-content_id': contentId } as never)
            .run();
        },
        hasImage: async (contentId) => {
          return new Promise((resolve) => {
            const view = editor.view;
            view.state.doc.descendants((node) => {
              if (
                node.type.name === 'image' &&
                node.attrs['data-content_id'] === contentId
              ) {
                resolve(true);
              }
            });
            resolve(false);
          });
        },
        deleteImage: async (contentId) => {
          return new Promise((resolve) => {
            let deleted = false;
            const view = editor.view;
            view.state.doc.descendants((node, pos) => {
              if (
                node.type.name === 'image' &&
                node.attrs['data-content_id'] === contentId
              ) {
                const tr = view.state.tr.delete(pos, pos + 1);
                tr.setMeta('addToHistory', false);
                view.dispatch(tr);
                deleted = true;
              }
            });
            resolve(deleted);
          });
        },
        hasUploadingImages: () => {
          return new Promise((resolve) => {
            const view = editor.view;
            view.state.doc.descendants((node) => {
              if (node.type.name === 'image' && node.attrs['data-upload_id']) {
                resolve(true);
              }
            });
            resolve(false);
          });
        },
      });
    }, [editor, setHtml]);

    useWindowEvent('keydown', (e) => {
      if (editor?.isFocused && e.ctrlKey && e.altKey && e.key === 'd') {
        console.log(editor.getHTML());
      }
    });

    // Tiptap(Prosemirror)からコピーするときにpタグとpタグの間に改行が入ってしまうので、それを上書きする
    useEffect(() => {
      if (!editor) {
        return;
      }
      const onCopy = (e: ClipboardEvent) => {
        if (!editor.isFocused) {
          return;
        }
        const slice = editor.view.state.selection.content();
        const text = slice.content.textBetween(0, slice.content.size, '\n');

        const html = e.clipboardData?.getData('text/html') ?? '';
        const convertedHtml =
          `<meta charset='utf-8'>` + applyMessageStyles(html);
        e.clipboardData?.setData('text/html', convertedHtml);
        e.clipboardData?.setData('text/plain', text);
      };
      document.addEventListener('copy', onCopy);
      return () => document.removeEventListener('copy', onCopy);
    }, [editor]);

    const autoFocusedRef = useRef(false);
    useEffect(() => {
      if (autoFocusedRef.current || !editor || !autoFocus) {
        return;
      }
      autoFocusedRef.current = true;
      editor.commands.focus();
    }, [editor, autoFocus]);

    useEffect(() => {
      if (!editor) {
        return;
      }

      const onUpdate = ({ transaction }: { transaction: Transaction }) => {
        const beforeNodes = new Set<Node>();
        const afterNodes = new Set<Node>();
        transaction.before.descendants((node) => {
          if (node.type.name === 'image' && node.attrs['data-content_id']) {
            beforeNodes.add(node);
          }
        });
        transaction.doc.descendants((node) => {
          if (node.type.name === 'image' && node.attrs['data-content_id']) {
            afterNodes.add(node);
          }
        });

        for (const afterNode of afterNodes) {
          if (
            ![...beforeNodes].some(
              (beforeNode) =>
                beforeNode.attrs['data-content_id'] ===
                afterNode.attrs['data-content_id']
            )
          ) {
            onInsertImage?.(afterNode.attrs['data-content_id']);
          }
        }

        for (const beforeNode of beforeNodes) {
          if (
            ![...afterNodes].some(
              (afterNode) =>
                afterNode.attrs['data-content_id'] ===
                beforeNode.attrs['data-content_id']
            )
          ) {
            onDeleteImage?.(beforeNode.attrs['data-content_id']);
          }
        }
      };

      editor.on('update', onUpdate);
      return () => {
        editor.off('update', onUpdate);
      };
    }, [onInsertImage, onDeleteImage]);

    if (!editor) {
      return null;
    }
    return (
      <div
        {...props}
        className={twMerge('flex h-full flex-col', props.className)}
        ref={ref}
      >
        <EditorContent editor={editor} className="message-container grow" />
        <LinkBubbleMenu
          editor={editor}
          hidden={disabled}
          getContainer={getBubbleMenuContainer}
        />
        {children}
      </div>
    );
  }
);

WysiwygEditor.displayName = 'WysiwygEditor';

const clipboardTextParser = (
  text: string,
  context: ResolvedPos,
  _plain: boolean
) => {
  const blocks = text.split(/\r\n?|\n/);
  const nodes: Node[] = [];

  blocks.forEach((line) => {
    const nodeJson: any = { type: 'paragraph' };
    if (line.length > 0) {
      nodeJson.content = [{ type: 'text', text: line }];
    }
    const node = Node.fromJSON(context.doc.type.schema, nodeJson);
    nodes.push(node);
  });

  const fragment = Fragment.fromArray(nodes);
  return Slice.maxOpen(fragment);
};

const transformPastedHTML = (html: string) => {
  return handleHTML(convertQuillHtml(html), (doc) => {
    doc
      .querySelectorAll('br')
      .filter(
        (el) =>
          el.parentNode.tagName === 'BODY' || el.parentNode.tagName === 'DIV'
      )
      .forEach((el) => el.replaceWith(doc.createElement('p')));
  });
};

const convertQuillHtml = (html: string): string => {
  return handleHTML(html, (doc) => {
    convertParagraph(doc);
    convertDecorations(doc);
  });
};

/**
 * Quillのときの ```<p><br></p>``` を ```<p></p>``` に変換する
 */
const convertParagraph = (doc: VHTMLDocument | VDocumentFragment) => {
  doc
    .querySelectorAll('p')
    .filter(
      (p) =>
        p.children.length === 1 &&
        p.firstChild instanceof VElement &&
        p.firstChild.tagName === 'BR'
    )
    .forEach((p) => {
      const emptyP = doc.createElement('p');
      p.replaceWith(emptyP);
    });
};

/**
 * Bold+colorなどの修正
 */
const convertDecorations = (doc: VHTMLDocument | VDocumentFragment) => {
  const tags = ['strong', 'em', 'u', 's'];
  for (const tag of tags) {
    doc
      .querySelectorAll(tag)
      .filter((el) => el.hasAttribute('style'))
      .forEach((el) => {
        const styleAttr = el.getAttribute('style')!;
        el.removeAttribute('style');

        const inner = doc.createElement(tag);
        inner.innerHTML = el.innerHTML;

        const wrapper = doc.createElement('span');
        wrapper.setAttribute('style', styleAttr);
        wrapper.appendChild(inner);
        el.replaceWith(wrapper);
      });
  }
};
