import * as React from 'react';
import type { SVGProps } from 'react';
const SvgAddRowBelow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="currentColor"
    viewBox="0 -960 960 960"
    {...props}
  >
    <path d="M760-548.85v-238.84q0-5.39-3.46-8.85t-8.85-3.46H212.31q-5.39 0-8.85 3.46t-3.46 8.85v238.84h560ZM212.31-180q-29.83 0-51.07-21.24Q140-222.48 140-252.31v-535.38q0-29.83 21.24-51.07Q182.48-860 212.31-860h535.38q29.83 0 51.07 21.24Q820-817.52 820-787.69v535.38q0 29.83-21.24 51.07Q777.52-180 747.69-180h-40q-12.75 0-21.37-8.63-8.63-8.63-8.63-21.38 0-12.76 8.63-21.37 8.62-8.62 21.37-8.62h40q5.39 0 8.85-3.46t3.46-8.85v-236.54H200v236.54q0 5.39 3.46 8.85t8.85 3.46h40q12.75 0 21.37 8.63 8.63 8.63 8.63 21.38 0 12.76-8.63 21.37-8.62 8.62-21.37 8.62h-40Zm267.68 80q-12.76 0-21.37-8.63Q450-117.25 450-130v-50h-50q-12.75 0-21.37-8.63-8.63-8.63-8.63-21.38 0-12.76 8.63-21.37Q387.25-240 400-240h50v-50q0-12.75 8.63-21.38 8.63-8.62 21.38-8.62 12.76 0 21.37 8.62Q510-302.75 510-290v50h50q12.75 0 21.37 8.63 8.63 8.63 8.63 21.38 0 12.76-8.63 21.37Q572.75-180 560-180h-50v50q0 12.75-8.63 21.37-8.63 8.63-21.38 8.63Zm.01-388.85Zm0-60v60-60Zm0 0Z" />
  </svg>
);
export default SvgAddRowBelow;
