import { PageWithMainNav } from '../Common/MainNav';
import { ContactsSidebarWithLogic } from './ContactsSidebar/ContactsSidebarWithLogic';
import SimpleBar from 'simplebar-react';
import { Route, Switch } from 'react-router-dom';
import React from 'react';
import { ContactsDetailPage } from './ContactsDetailPage';

export const ContactsPage = () => {
  return (
    <PageWithMainNav>
      <div className="grid h-full max-w-full grid-cols-[250px_1fr] items-start">
        <div className="h-full overflow-hidden">
          <ContactsSidebarWithLogic />
        </div>
        <div className="h-full overflow-hidden">
          <SimpleBar
            className="h-full"
            classNames={{
              contentWrapper: 'simplebar-content-wrapper h-full',
              contentEl: 'simplebar-content h-full',
            }}
          >
            <Switch>
              <Route
                path="/contacts/:teamId/contacts"
                component={ContactsDetailPage}
              />
            </Switch>
          </SimpleBar>
        </div>
      </div>
    </PageWithMainNav>
  );
};
