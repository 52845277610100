import { message as antdMessage, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { GroupMember } from './GroupMember';
import { useStore } from '../../../../hooks/useStore';
import styled from 'styled-components';
import { DefaultInput } from '../../../Common/Input';
import { H3 } from '../../../Common/H3';
import { DefaultButton } from '../../../Common/Button';
import { H1 } from '../../../Common/H1';
import _ from 'lodash';
import { Group } from '../../../../store/entity';
import { User } from 'lib';

type Props = {
  group?: Group;
  visible: boolean;
  close: () => void;
};

export const GroupModal = ({ group, visible, close }: Props): JSX.Element => {
  const [name, setName] = useState('');
  const [error, setError] = useState('');
  const [users, setUsers] = useState<User[]>([]);
  const [members, setMembers] = useState<User[]>([]);
  const [saving, setSaving] = useState(false);

  const store = useStore();
  const { groupStore } = store;

  useEffect(() => {
    if (visible) {
      setError('');
      if (!group) {
        setName('');
        setUsers(store.users);
        setMembers([]);
        return;
      }
      const memberIds = group.members;
      setName(group.name);
      setUsers(store.users.filter((user) => !memberIds.includes(user.id)));
      setMembers(store.users.filter((user) => memberIds.includes(user.id)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group, visible]);

  const updateUsers = (action: (prev: User[]) => User[]) => {
    setUsers((prev) => _.sortBy(action(prev), 'name'));
  };

  const addMember = (user: User) => {
    updateUsers((prev) => prev.filter((p) => p.id !== user.id));
    setMembers((prev) => [...prev, user]);
  };

  const removeMember = (user: User) => {
    setMembers((prev) => prev.filter((p) => p.id !== user.id));
    updateUsers((prev) => [...prev, user]);
  };

  const onClickSave = async () => {
    const trimmedName = name.trim();
    const g = groupStore.getGroupByName(trimmedName);
    if (g && g.id !== group?.id) {
      setError('すでに作成されているグループ名です');
      return;
    } else {
      setError('');
    }

    setSaving(true);
    if (!group) {
      await groupStore.addGroup(
        trimmedName,
        members.map((member) => member.id)
      );
      antdMessage.success('グループを作成しました');
      close();
    } else {
      await groupStore.updateGroup(group.id, {
        name: trimmedName,
        members: members.map((member) => member.id),
      });
      antdMessage.success('グループを更新しました');
    }
    setSaving(false);
  };

  return (
    <Modal visible={visible} onCancel={close} footer={null} width={600}>
      <Title>{group ? 'グループ設定' : '新規グループ'}</Title>
      <Label>表示名</Label>
      <Content>
        <DefaultInput value={name} onChange={(e) => setName(e.target.value)} />
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </Content>
      <Label>メンバーを追加する</Label>
      <Users>
        {users.length
          ? users.map((user) => (
              <GroupMember
                key={user.id}
                user={user}
                onClick={() => addMember(user)}
              />
            ))
          : '追加可能なメンバーがいません'}
      </Users>
      <Label>追加済みのメンバー</Label>
      <Users>
        {members.length
          ? members.map((member) => (
              <GroupMember
                key={member.id}
                user={member}
                onClick={() => removeMember(member)}
                inGroup
              />
            ))
          : 'メンバーがまだ追加されていません'}
      </Users>
      <Buttons>
        <Button type="danger" onClick={close}>
          キャンセル
        </Button>
        <Button type="primary" onClick={onClickSave} disabled={!name || saving}>
          保存
        </Button>
      </Buttons>
    </Modal>
  );
};

const Title = styled(H1)`
  text-align: center;
  margin-top: 30px;
  padding-bottom: 30px;
`;

const Content = styled.div`
  padding: 0 32px;
  margin-bottom: 12px;
`;

const Label = styled(H3)`
  padding: 0 32px;
  margin-bottom: 4px;
`;

const ErrorMessage = styled.div`
  color: red;
`;

const Users = styled.div`
  padding: 0 32px;
  width: 100%;
  margin-bottom: 16px;
  max-height: 30vh;
  overflow: auto;
`;

const Buttons = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: center;
  gap: 32px;
`;

const Button = styled(DefaultButton)`
  height: 40px;
  width: 100px;
  max-width: 100px;
`;
