import * as React from 'react';
import type { SVGProps } from 'react';
const SvgClose = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={14}
    fill="none"
    viewBox="0 0 14 14"
    {...props}
  >
    <path
      fill="currentColor"
      d="M7 8.05 1.75 13.3a.72.72 0 0 1-.525.225A.72.72 0 0 1 .7 13.3a.72.72 0 0 1-.225-.525c0-.2.075-.375.225-.525L5.95 7 .7 1.75a.72.72 0 0 1-.225-.525C.475 1.025.55.85.7.7a.72.72 0 0 1 .525-.225c.2 0 .375.075.525.225L7 5.95 12.25.7a.72.72 0 0 1 .525-.225c.2 0 .375.075.525.225a.72.72 0 0 1 .225.525.72.72 0 0 1-.225.525L8.05 7l5.25 5.25a.72.72 0 0 1 .225.525.72.72 0 0 1-.225.525.72.72 0 0 1-.525.225.72.72 0 0 1-.525-.225L7 8.05Z"
    />
  </svg>
);
export default SvgClose;
