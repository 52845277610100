import firebase from 'firebase.js';

if (firebase.messaging.isSupported()) {
  const messaging = firebase.messaging();

  // アクティブ状態で通知を受信した場合に発火する
  messaging.onMessage((payload) => {
    // 現状、なにもしない。
  });
}
