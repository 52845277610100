import { useEffect, useState } from 'react';
import { matchPath, useHistory, useParams } from 'react-router-dom';
import { SearchFilterData } from './MainSearch';
import { SearchQuery, SearchRange } from '../../../store/search';
import { useStore } from '../../../hooks/useStore';
import moment from 'moment';
import { Options } from '../../../components/forms';
import { useForm } from 'react-hook-form';
import { useCurrentPageLabel } from '../../../hooks/useCurrentPage';
import { Store } from '../../../store';
import { atom, useSetAtom } from 'jotai';

export const Tray = {
  messages: '受信トレイ',
  sent: '送信済み',
  deleted: 'ゴミ箱',
} as const;
export type Tray = keyof typeof Tray;

type UseSearchResult = {
  filterForm: ReturnType<typeof useForm<SearchFilterData>>;
  keyword: string;
  onChangeKeyword: (value: string) => void;
  searchRangeOptions: Options<SearchRange>;
  searchRange: SearchRange;
  onChangeSearchRange: (value: SearchRange) => void;
  renderSearchRangeLabel: (value: SearchRange, label: string) => string;
  trayOptions: Options<Tray>;
  tagOptions: Options<string>;
  memberOptions: Options<string | null>;
  search: () => void;
};

const trayOptions: Options<Tray> = Object.entries(Tray).map(
  ([value, label]) => ({
    value: value as Tray,
    label,
  })
);

const resetAtom = atom<(() => void) | undefined>(undefined);
const dirtyAtom = atom(false);
export const dirtyResetAtom = atom<boolean, [], void>(
  (get) => get(dirtyAtom),
  (get) => get(resetAtom)?.()
);

export const useSearch = (onSearch?: () => void): UseSearchResult => {
  const [currentSelectDisabled, setCurrentSelectDisabled] = useState(false);
  const [keyword, setKeyword] = useState('');
  const setReset = useSetAtom(resetAtom);
  const setDirty = useSetAtom(dirtyAtom);
  const pageLabel = useCurrentPageLabel();

  const history = useHistory();
  const store = useStore();
  const { searchStore } = store;
  const { query } = searchStore;
  const params = useParams<{
    teamId?: string;
    tray?: Tray;
    inboxId?: string;
    tagId?: string;
    tab?: string;
    query?: string;
  }>();

  const filterToQuery = (
    keyword: string,
    filter: SearchFilterData
  ): SearchQuery => {
    const {
      status,
      from,
      toOrCc,
      subjectOrText,
      tag,
      assignee,
      after,
      before,
      hasAttachments,
      attachmentsFilename,
    } = filter;
    return {
      ...query,
      keywords: keyword ? keyword.replace('　', ' ').split(' ') : undefined,
      inbox: query.inbox || undefined,
      status: status || undefined,
      from: from || undefined,
      toOrCc: toOrCc || undefined,
      subjectOrText: subjectOrText || undefined,
      tags: tag ? [tag] : undefined,
      assignee: assignee === '' ? undefined : assignee,
      after: after ? moment(after).format('YYYY-MM-DD') : undefined,
      before: before ? moment(before).format('YYYY-MM-DD') : undefined,
      hasAttachments: hasAttachments || undefined,
      attachmentsFilename: attachmentsFilename || undefined,
    };
  };

  const rangeOptions: Options<SearchRange> = [
    { value: 'allInboxes', label: 'すべての受信メール' },
    { value: 'allSent', label: 'すべての送信メール' },
    ...(currentSelectDisabled
      ? []
      : [{ value: SearchRange.CurrentSelect, label: '選択されたトレイ' }]),
  ];

  const filterForm = useForm<SearchFilterData>({
    defaultValues: {
      tray: 'messages',
      status: '',
      from: '',
      toOrCc: '',
      subjectOrText: '',
      tag: '',
      assignee: '',
      after: null,
      before: null,
      hasAttachments: false,
      attachmentsFilename: '',
    },
  });

  const onSubmit = (data: SearchFilterData) => {
    history.push(
      `/search/${data.tray}/${encodeURIComponent(
        JSON.stringify(filterToQuery(keyword, data))
      )}`
    );
    onSearch?.();
  };

  setReset(() => () => {
    filterForm.reset();
    onSubmit(filterForm.getValues());
  });
  setDirty(filterForm.formState.isDirty);

  const renderSearchRangeLabel = (value: SearchRange, label: string) => {
    if (value === 'currentSelect') {
      return pageLabel;
    }
    return label;
  };

  const updateQuery = () => {
    if (searchStore.searchRange === 'currentSelect') {
      const query = searchStore.query;
      const { teamId, inboxId, tagId, tab } = params;
      const { getValues, reset, formState } = filterForm;
      query.teamIds = teamId ? [teamId] : undefined;
      query.inboxId = inboxId ? inboxId : undefined;
      query.inbox = Boolean(inboxId);

      let defaultValues = {
        ...formState.defaultValues,
      };
      if (tagId) {
        const tag = store.getTag(tagId);
        if (tag) {
          const isInbox = tag.isInbox;
          query.inbox = isInbox;
          defaultValues = {
            ...defaultValues,
            tag: isInbox ? '' : tagId,
          };
          reset(defaultValues);
        } else {
          defaultValues = {
            ...defaultValues,
            tag: '',
          };
          reset(defaultValues);
        }
      } else {
        defaultValues = {
          ...defaultValues,
          tag: '',
        };
        reset(defaultValues);
      }

      const pathname = history.location.pathname;
      const currentTray = getValues('tray');
      if (pathname.startsWith('/me/sent')) {
        query.sender = store.me.id;
        defaultValues = {
          ...defaultValues,
          tray: 'sent',
        };
        reset(defaultValues);
      } else {
        query.sender = undefined;
      }
      if (pathname.startsWith('/me/assigned')) {
        query.assignee = store.me.id;
        defaultValues = {
          ...defaultValues,
          assignee: store.me.id,
        };
        reset(defaultValues);
        if (currentTray === 'sent') {
          defaultValues = {
            ...defaultValues,
            tray: 'messages',
          };
          reset(defaultValues);
        }
      } else {
        query.assignee = undefined;
        defaultValues = {
          ...defaultValues,
          assignee: '',
        };
        reset(defaultValues);
      }
      if (tab) {
        let tray: Tray;
        if (tab === 'sent' || tab === 'deleted') {
          tray = tab;
        } else {
          tray = 'messages';
        }
        defaultValues = {
          ...defaultValues,
          tray,
        };
        reset(defaultValues);
      }
      searchStore.query = query;
    }
  };

  useEffect(() => {
    const range = searchStore.searchRange;
    const { getValues, reset, formState } = filterForm;
    const tray = getValues('tray');
    if (range !== 'allSent' && tray === 'sent') {
      reset({ ...formState.defaultValues, tray: 'messages' });
    }
    if (range === 'allSent' && tray !== 'sent') {
      reset({ ...formState.defaultValues, tray: 'sent' });
    }
    const query = searchStore.query;
    if (range === 'allInboxes' || range === 'allSent') {
      query.inbox = false;
    }
    if (range !== 'currentSelect') {
      query.teamIds = undefined;
      query.inboxId = undefined;
    }
    searchStore.query = query;
    updateQuery();
  }, [searchStore.searchRange]);

  useEffect(() => {
    const pathname = history.location.pathname;
    const { tray, query } = params;
    if (query) {
      let filter: SearchQuery | undefined = undefined;
      try {
        filter = JSON.parse(query) as SearchQuery;
        const { after, before } = filter;
        filterForm.reset(
          {
            tray: tray,
            ...filter,
            after: after ? new Date(after) : null,
            before: before ? new Date(before) : null,
          },
          {
            keepDefaultValues: true,
          }
        );
      } catch (e) {}
    }

    if (pathname.startsWith('/search')) {
      searchStore.inSearch = true;
      setCurrentSelectDisabled(true);
      return;
    } else {
      searchStore.inSearch = false;
    }

    const prefixes = [
      '/me/drafts',
      '/me/scheduled',
      '/me/mentioned',
      '/me/commented',
      '/me/starred',
      '/reports',
      '/settings',
    ];
    const isLineThreads = Boolean(
      matchPath(pathname, [`/teams/:teamId/lineaccounts/:lineAccountId/:tab`])
    );
    if (
      prefixes.some((prefix) => pathname.startsWith(prefix)) ||
      isLineThreads
    ) {
      searchStore.searchRange = SearchRange.AllInboxes;
      setCurrentSelectDisabled(true);
      return;
    }
    searchStore.searchRange = SearchRange.CurrentSelect;
    setCurrentSelectDisabled(false);
    updateQuery();
  }, [history.location.pathname]);

  return {
    filterForm,
    searchRangeOptions: rangeOptions,
    searchRange: searchStore.searchRange,
    onChangeSearchRange: (v) => {
      searchStore.searchRange = v;
    },
    renderSearchRangeLabel,
    keyword,
    onChangeKeyword: setKeyword,
    search: filterForm.handleSubmit(onSubmit),
    trayOptions,
    tagOptions: getTagOptions(store),
    memberOptions: getMemberOptions(store),
  };
};

const getTagOptions = (store: Store): Options<string> => {
  const teamIds = store.searchStore.query.teamIds;
  const ids = teamIds ?? store.joinedTeamIds;
  const tags: Options<string> = store.tags
    .filter((tag) => !tag.isInbox)
    .filter((tag) => ids.includes(tag.teamId))
    .map((tag) => ({ value: tag.id, label: tag.name }));
  return [{ value: '', label: '' }, ...tags];
};

const getMemberOptions = (store: Store): Options<string | null> => {
  const teamIds = store.searchStore.query.teamIds;
  const ids = teamIds ?? store.joinedTeamIds;
  const teams = store.joinedTeams.filter((team) => ids.includes(team.id));
  const members: Options<string> = store.users
    .filter((user) => teams.some((team) => team.isMember(user.id)))
    .map((user) => ({ value: user.id, label: user.name }));
  return [{ value: '', label: '' }, { value: null, label: 'なし' }, ...members];
};
