import { ReactNode } from 'react';
import * as RadixTooltip from '@radix-ui/react-tooltip';
import { tv } from 'tailwind-variants';

type Props = {
  delayDuration?: number;
  defaultOpen?: boolean;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  side?: 'top' | 'right' | 'bottom' | 'left';
  sideOffset?: number;
  content: string | ReactNode;
  unstyled?: boolean;
  className?: string;
  children: ReactNode;
  visible?: boolean;
};

const contentStyles = tv({
  base: 'z-[1100] data-[side=bottom]:animate-tooltip-slide-bottom data-[side=left]:animate-tooltip-slide-left data-[side=right]:animate-tooltip-slide-right data-[side=top]:animate-tooltip-slide-top',
  variants: {
    styled: {
      true: 'pointer-events-none max-w-[250px] select-none rounded bg-sumi-900 p-2 text-xs text-sumi-100',
    },
  },
});

export const Tooltip = ({
  delayDuration = 200,
  defaultOpen,
  open,
  onOpenChange,
  side,
  sideOffset,
  content,
  unstyled = false,
  className,
  children,
  visible = true,
}: Props) => {
  return (
    <RadixTooltip.Provider>
      <RadixTooltip.Root
        delayDuration={delayDuration}
        defaultOpen={defaultOpen}
        open={visible && open}
        onOpenChange={onOpenChange}
      >
        <RadixTooltip.Trigger asChild>{children}</RadixTooltip.Trigger>
        <RadixTooltip.Portal>
          <RadixTooltip.Content
            className={contentStyles({ styled: !unstyled, className })}
            collisionPadding={6}
            side={side}
            sideOffset={sideOffset ?? 4}
            data-testid="tooltip-content"
          >
            {content}
            {!unstyled && <RadixTooltip.Arrow className="fill-sumi-900" />}
          </RadixTooltip.Content>
        </RadixTooltip.Portal>
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  );
};
