import { Tag } from 'lib';
import { useCallback, useMemo } from 'react';
import { getTagLabel } from '../../../../util';
import { matchPath, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import { LeftSidebarInnerTagList } from './LeftSidebarInnerTagList';
import { useStore } from '../../../../hooks/useStore';

type Props = {
  teamId: string;
  tags: Tag[];
  level: number;
};

export type TagListNode = {
  id: string;
  name: string;
  selected: boolean;
  url: string;
  tag: Tag;
  children: TagListNode[];
};

export const LeftSidebarTagList = observer(
  ({ teamId, tags, level }: Props): JSX.Element => {
    const store = useStore();
    const location = useLocation();

    const tagToTreeNode = useCallback(
      (tag: Tag): TagListNode => {
        const { shortLabel, label } = getTagLabel(tag.name, {
          tagMaxLength: 100,
        });

        return {
          id: tag.id,
          name: shortLabel ?? label,
          selected:
            matchPath(location.pathname, {
              path: `/teams/${teamId}/tags/${tag.id}/:tab`,
            }) != null,
          url: `/teams/${teamId}/tags/${tag.id}/messages`,
          tag,
          children: [],
        };
      },
      [teamId, store, location.pathname]
    );
    const aggregateTags = useCallback(
      (tags: Tag[]) => {
        const nodes = tags.map(tagToTreeNode);
        for (const node of nodes) {
          if (node.tag.parentTagId) {
            const parentNode: any = nodes.find(
              (n) => n.id === node.tag.parentTagId
            );
            if (parentNode) {
              parentNode.children.push(node);
            }
          }
        }
        return nodes.filter((node) => !node.tag.parentTagId);
      },
      [tagToTreeNode]
    );
    const aggregatedTagNodes = useMemo(
      () => aggregateTags(tags),
      [tags, aggregateTags]
    );
    return <LeftSidebarInnerTagList nodes={aggregatedTagNodes} level={level} />;
  }
);
