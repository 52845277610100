import { useAtomValue } from 'jotai/index';
import { meAtom } from '../../../atoms/auth';
import { usersAtom } from '../../../atoms/firestore/user';
import {
  signInCompanyStripeProductAtom,
  signInCompanyStripeProductLoadingAtom,
} from '../../../atoms/firestore/signInCompanyStripeProduct';
import { ComponentProps, useEffect, useMemo, useState } from 'react';
import { Comment } from '../../comment/Comment/Comment';
import { Chat } from '../../../firestore/entity/chat';
import { useUpdateCommentInputReply } from '../../comment/CommentInput/CommentInputReplyProvider';
import {
  arrayRemove,
  arrayUnion,
  doc,
  getDoc,
  onSnapshot,
  serverTimestamp,
  setDoc,
  updateDoc,
} from 'firebase/firestore';
import { companyCollection, companyDoc } from '../../../firestore';
import { reactionsConverter } from 'lib/dist/entity/reactions';
import { uniq } from 'lodash';
import { LRUCache } from 'lru-cache';

type ChatWithLogicProps = {
  roomId: string;
  chat: Chat;
  chats: Chat[];
  intersecting: boolean;
};

const reactionsCache = new LRUCache<string, Record<string, string[]>>({
  max: 256,
});

export const ChatWithLogic = ({
  roomId,
  chat,
  chats,
  intersecting,
}: ChatWithLogicProps) => {
  const me = useAtomValue(meAtom);
  const users = useAtomValue(usersAtom);
  const author = users.find((u) => u.id === chat.creator);
  const userNames = users.map((u) => u.name);
  const [reactions, setReactions] = useState<Record<string, string[]>>(
    reactionsCache.get(chat.id) ?? {}
  );

  const updateReplyData = useUpdateCommentInputReply();

  const reply: ComponentProps<typeof Comment>['reply'] = useMemo(() => {
    if (!chat.replyTo) {
      return undefined;
    }

    const found = chats.find((c) => c.id === chat.replyTo);
    if (!found) {
      return 'deleted';
    }

    return {
      name: users.find((u) => u.id === found.creator)?.name ?? '不明なユーザー',
      content: found.text,
      mentionedUsers: userNames,
    };
  }, [chat.replyTo, chats]);

  const featuresLoading = useAtomValue(signInCompanyStripeProductLoadingAtom);
  const product = useAtomValue(signInCompanyStripeProductAtom);
  const reactionSupportStatus = useMemo(() => {
    if (featuresLoading) {
      return 'loading';
    }
    return product?.messageReactionSupported ? 'supported' : 'unsupported';
  }, [featuresLoading, product]);

  useEffect(() => {
    if (!intersecting) {
      return;
    }

    const unsubscribe = onSnapshot(
      companyCollection(
        `rooms/${roomId}/chats/${chat.id}/reactions`,
        reactionsConverter
      ),
      (snapshot) => {
        const reactionsArray = snapshot.docs.map((d) => d.data());
        const allEmojis = uniq(reactionsArray.flatMap((r) => r.emojis));
        const convertedReactions = Object.fromEntries(
          allEmojis
            .map((emoji) => ({
              emoji,
              users: reactionsArray
                .filter((r) => r.emojis.includes(emoji))
                .map((r) => r.id),
            }))
            .map((r) => [r.emoji, r.users])
        );
        setReactions(convertedReactions);
        reactionsCache.set(chat.id, convertedReactions);
      }
    );
    return () => unsubscribe();
  }, [chat.id, users, intersecting]);

  const onToggleEmoji = async (emoji: string) => {
    const doc = companyDoc(`rooms/${roomId}/chats/${chat.id}/reactions`, me.id);
    if (reactions[emoji]?.includes(me.id)) {
      await updateDoc(doc, {
        emojis: arrayRemove(emoji),
        updatedAt: serverTimestamp(),
      });
    } else {
      const d = await getDoc(doc);
      if (d.exists()) {
        await updateDoc(doc, {
          emojis: arrayUnion(emoji),
          updatedAt: serverTimestamp(),
        });
      } else {
        await setDoc(doc, {
          emojis: arrayUnion(emoji),
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
        });
      }
    }
  };
  const onEditContent = async (content: string) => {
    await updateDoc(doc(companyCollection(`rooms/${roomId}/chats`), chat.id), {
      text: content,
      edited: true,
    });
  };
  return (
    <Comment
      id={chat.id}
      user={author ?? {}}
      content={chat.text}
      timestamp={chat.createdAt.toDate()}
      mentionTargets={userNames}
      onReply={() =>
        updateReplyData({
          id: chat.id,
          name: author?.name ?? '削除されたユーザー',
          content: chat.text,
          mentionUsers: userNames,
        })
      }
      reply={reply}
      onToggleEmoji={onToggleEmoji}
      onEditContent={onEditContent}
      onDelete={undefined}
      reactions={Object.fromEntries(
        Object.entries(reactions).map(([emoji, userIds]) => [
          emoji,
          userIds.map((id) => ({
            id,
            name: users.find((u) => u.id === id)?.name ?? '',
          })),
        ])
      )}
      reactionSupportStatus={reactionSupportStatus}
      replySupportStatus="supported"
      currentUser={{ id: me.id, name: me.name }}
      isAuthor={me.id === chat.creator}
      isEdited={chat.edited}
      noDeleteButton
    />
  );
};
