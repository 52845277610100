import { useStore } from 'hooks/useStore';
import { useMessageStatus } from './useMessageStatus';
import { observer } from 'mobx-react';
import { Tabs } from '../../../components/basics/Tabs';
import { MessageStatusAddButton } from './button/MessageStatusButton';
import { ComponentProps } from 'react';
import { useMsgRouteMatch } from 'hooks/useMsgRouteMatch';

type Props = Omit<
  ComponentProps<typeof Tabs>,
  'entries' | 'value' | 'onChange'
>;

export const MessageTabs = observer((props: Props) => {
  const store = useStore();
  const match = useMsgRouteMatch();
  const { statuses, status, onChange } = useMessageStatus(store);
  const { teamId } = match.params;
  return (
    <div className="relative flex">
      <Tabs {...props} entries={statuses} value={status} onChange={onChange} />
      {store.getTeam(teamId)?.name && <MessageStatusAddButton label="＋" />}
    </div>
  );
});
