import { Avatar } from '../../basics/Avatar/Avatar';
import React, {
  ComponentProps,
  FormEvent,
  forwardRef,
  ReactElement,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { tv } from 'tailwind-variants';
import { Icon, Loading } from '../../basics';
import { Close, NoteAdd, Plus, Send } from '../../icons';
import SimpleBar from 'simplebar-react';
import { Tooltip } from '../../basics/Tooltip/Tooltip';
import { CommentAttachment } from '../CommentAttachment/CommentAttachment';
import styles from './CommentInput.module.css';
import Mentions from 'rc-mentions';
import { MentionsRef } from 'rc-mentions/es/Mentions';
import Dropzone from 'react-dropzone';
import { twMerge } from 'tailwind-merge';
import * as ScrollArea from '@radix-ui/react-scroll-area';
import { shouldSubmit } from '../../../utils/keyboard';

type Attachment = {
  id: string;
  name: string;
};

type Props = {
  user: ComponentProps<typeof Avatar>['user'];
  attachments: Attachment[];
  onRemoveAttachment: (id: string) => Promise<void>;
  onSubmit: (content: string) => Promise<boolean>;
  onUploadAttachment: (file: File) => Promise<void>;
  mentionTargets?: {
    icon: ReactElement;
    label: string;
    value: string;
    disabled?: boolean;
  }[];
  placeholder: string;
  reply?: {
    name: string;
    content: string;
  };
  onCancelReply?: () => void;
  disabled?: boolean;
  noUploadButton?: boolean;
  defaultSending?: boolean; // デバッグ用
};

export type CommentInputHandle = {
  focus: () => void;
};

const inputWrapper = tv({
  base: 'grid min-h-[theme(height.10)] items-end rounded border border-sumi-300 bg-white',
  variants: {
    disabled: {
      true: 'bg-sumi-100',
    },
    uploadButton: {
      true: 'grid-cols-[auto_1fr_auto]',
      false: 'grid-cols-[1fr_auto]',
    },
  },
});

const button = tv({
  base: 'relative z-[1] m-0 flex aspect-square h-[calc(theme(height.10)_-_2px)] cursor-pointer items-center justify-center rounded bg-transparent p-0 text-sea-500 disabled:cursor-not-allowed disabled:text-sumi-500',
});

const attachmentContainer = tv({
  base: 'grid w-full grid-cols-1 gap-2 py-2 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4',
  variants: {
    disabled: {
      true: 'opacity-50',
    },
  },
});

const textarea = tv({
  base: styles.textareaWrapper,
  variants: {
    leftPadding: {
      true: 'pl-3',
    },
  },
});

export const CommentInput = forwardRef<CommentInputHandle, Props>(
  (
    {
      user,
      attachments,
      onUploadAttachment,
      onRemoveAttachment,
      onSubmit,
      mentionTargets,
      placeholder,
      reply,
      onCancelReply,
      disabled,
      noUploadButton,
      defaultSending,
    },
    ref
  ) => {
    const widthObserverElementRef = useRef<HTMLDivElement>(null);
    const scrollAreaRef = useRef<HTMLDivElement>(null);
    const mentionsRef = useRef<MentionsRef>(null);
    const [input, setInput] = useState('');
    const [sending, setSending] = useState(defaultSending);

    useImperativeHandle(ref, () => ({
      focus: () => mentionsRef.current?.focus(),
    }));

    useEffect(() => {
      const current = mentionsRef.current;
      if (!current) {
        return;
      }

      const collection = current.nativeElement.getElementsByTagName('textarea');
      const textarea = Array.from(collection).at(0);
      if (!textarea) {
        return;
      }

      onHeightChange(textarea.clientHeight);

      const observer = new ResizeObserver((entries) => {
        entries.forEach((e) => {
          onHeightChange(e.target.clientHeight);
        });
      });
      observer.observe(textarea);
      return () => observer.disconnect();
    }, []);

    const onHeightChange = (height: number) => {
      const scrollArea = scrollAreaRef.current;
      if (!scrollArea) {
        return;
      }

      const thresholdHeight = scrollArea.clientHeight * 0.5;
      const shouldScrollToBottom =
        height - scrollArea.scrollTop - scrollArea.clientHeight <
        thresholdHeight;
      if (shouldScrollToBottom) {
        scrollArea.scrollTo({
          top: scrollArea.scrollHeight,
        });
      }
    };

    const handleSubmit = async (e: FormEvent | null) => {
      e?.preventDefault();

      if (!input.trim().length) {
        return;
      }

      setSending(true);
      try {
        if (await onSubmit(input.trim())) {
          setInput('');

          // フォーカスを戻す
          setTimeout(() => {
            if (document.activeElement === document.body) {
              mentionsRef.current?.focus();
            }
          });
        }
      } catch (e) {
        console.error(e);
      } finally {
        setSending(false);
      }
    };

    const onDrop = (acceptedFiles: File[]) => {
      const promises = acceptedFiles.map((file) => onUploadAttachment(file));
      Promise.allSettled(promises).catch((e) => console.error(e));
    };

    return (
      <div className="flex flex-col items-start">
        <div className="w-full" ref={widthObserverElementRef} />
        <Dropzone
          onDrop={onDrop}
          noClick
          noKeyboard
          disabled={disabled || sending}
        >
          {({ getRootProps, getInputProps, isDragActive, inputRef }) => (
            <div {...getRootProps({ className: 'relative w-full' })}>
              <input {...getInputProps()} />
              {isDragActive && (
                <div className="absolute left-0 top-0 z-10 flex h-full w-full select-none items-center justify-center gap-4 rounded bg-white/50 text-sm text-sumi-800">
                  <Icon icon={NoteAdd} size={24} />
                  <div>ドロップしてファイルをアップロード</div>
                </div>
              )}

              <div>
                {reply && (
                  <ReplyPreview
                    name={reply.name}
                    content={reply.content}
                    onCancel={onCancelReply}
                  />
                )}
                <div className="grid min-h-[theme(height.10)] max-w-full grid-cols-[auto_1fr] items-end gap-2 text-sm">
                  <Avatar size={40} user={user} />
                  <form
                    action=""
                    onSubmit={handleSubmit}
                    className={inputWrapper({
                      disabled: sending || disabled,
                      uploadButton: !noUploadButton,
                    })}
                  >
                    {!noUploadButton && (
                      <Tooltip
                        content="ファイルを追加"
                        visible={!sending && !disabled}
                      >
                        <button
                          type="button"
                          className={button()}
                          aria-label="ファイルを追加する"
                          onClick={() => inputRef.current?.click()}
                          disabled={sending || disabled}
                        >
                          <Icon icon={Plus} size={14} />
                        </button>
                      </Tooltip>
                    )}
                    <div>
                      {attachments.length > 0 && (
                        <div
                          className={attachmentContainer({
                            disabled: sending || disabled,
                          })}
                        >
                          {attachments.map((attachment) => (
                            <CommentAttachment
                              key={attachment.id}
                              name={attachment.name}
                              onRemove={() => onRemoveAttachment(attachment.id)}
                              disabled={sending || disabled}
                            />
                          ))}
                        </div>
                      )}
                      <ScrollArea.Root>
                        <ScrollArea.Viewport
                          className="h-full max-h-[126px]"
                          ref={scrollAreaRef}
                        >
                          <Mentions
                            autoSize={true}
                            placeholder={placeholder}
                            dropdownClassName="z-10"
                            className={textarea({
                              leftPadding: noUploadButton,
                            })}
                            value={input}
                            validateSearch={
                              mentionTargets?.length ? undefined : () => false
                            }
                            onChange={(value) => setInput(value)}
                            onKeyDown={async (e) => {
                              if (shouldSubmit(e)) {
                                await handleSubmit(null);
                              }
                            }}
                            ref={mentionsRef}
                            disabled={sending || disabled}
                          >
                            {mentionTargets?.map((target, i) => (
                              <Mentions.Option
                                key={`${i}`}
                                value={target.value}
                                className="m-0 grid h-8 cursor-pointer grid-cols-[auto_1fr] items-center gap-2 rounded-lg pl-[calc((theme(height.8)_-_24px)_/_2)] pr-2 [&:is(.rc-mentions-dropdown-menu-item-active)]:bg-sumi-100 [&:is(.rc-mentions-dropdown-menu-item-disabled)]:opacity-50"
                                disabled={target.disabled}
                              >
                                <div className="h-[24px] w-[24px]">
                                  {target.icon}
                                </div>
                                <div className="select-none">
                                  {target.label}
                                </div>
                              </Mentions.Option>
                            ))}
                          </Mentions>
                        </ScrollArea.Viewport>
                        <ScrollArea.Scrollbar
                          className="w-[7px] p-[2px] hover:w-[11px]"
                          orientation="vertical"
                        >
                          <ScrollArea.Thumb className="relative flex-1 rounded-full bg-black/50 before:absolute before:left-1/2 before:top-1/2 before:h-full before:min-h-[44px] before:-translate-x-1/2 before:-translate-y-1/2 before:content-['']" />
                        </ScrollArea.Scrollbar>
                      </ScrollArea.Root>
                    </div>
                    <Tooltip
                      content="送信 (Ctrl + Enter)"
                      visible={!!input.trim().length && !sending && !disabled}
                    >
                      <button
                        type="submit"
                        className={button()}
                        aria-label="送信する"
                        disabled={!input.trim().length || sending || disabled}
                      >
                        {sending ? (
                          <div className="flex h-8 w-8 items-center justify-center">
                            <Loading size={18} />
                          </div>
                        ) : (
                          <Icon icon={Send} size={24} />
                        )}
                      </button>
                    </Tooltip>
                  </form>
                </div>
              </div>
            </div>
          )}
        </Dropzone>
      </div>
    );
  }
);

CommentInput.displayName = 'CommentInput';

const ReplyPreview = ({
  name,
  content,
  onCancel,
}: {
  name: string;
  content: string;
  onCancel: (() => void) | undefined;
}) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [isLargeContent, setLargeContent] = useState(false);
  useEffect(() => {
    const contentEl = contentRef.current!;
    const update = () => {
      const height = contentEl.clientHeight;
      setLargeContent(height > 100);
    };
    update();
    const observer = new ResizeObserver(update);
    observer.observe(contentEl);
    return () => observer.disconnect();
  }, []);
  return (
    <div className="mb-2 grid grid-cols-[1fr_auto] items-start justify-between gap-2 rounded-r border-b border-l-4 border-b-sumi-100 border-l-sumi-300 bg-white p-2 pb-0 pl-4 text-sm">
      <div className="grid-row-[auto_auto] relative grid">
        <div className="relative mb-2 grid grid-cols-[auto_1fr] text-sumi-600">
          <div className="truncate whitespace-nowrap" title={name}>
            {name}
          </div>
          <div className="whitespace-nowrap">さんに返信中</div>
        </div>
        <div className="relative">
          <SimpleBar className="max-h-[100px]">
            <div className={isLargeContent ? 'pb-6' : 'pb-2'}>
              <div
                className="w-full whitespace-pre-line break-all leading-6"
                ref={contentRef}
              >
                {content}
              </div>
            </div>
          </SimpleBar>
          {isLargeContent && (
            <div
              className={twMerge(
                'absolute bottom-0 left-0 h-6 w-full',
                styles.previewCollapseButtonGradient
              )}
            ></div>
          )}
        </div>
      </div>
      <Tooltip content="返信取り消し">
        <button
          type="button"
          onClick={() => onCancel?.()}
          className="m-0 flex h-6 w-6 cursor-pointer items-center justify-center rounded-full bg-transparent p-0 text-sumi-800 hover:bg-sumi-300"
          aria-label="返信取り消し"
        >
          <Icon icon={Close} size={10} />
        </button>
      </Tooltip>
    </div>
  );
};
