import * as React from 'react';
import type { SVGProps } from 'react';
const SvgGmail = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={24}
    fill="none"
    viewBox="0 0 25 24"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#4285F4"
        d="M4.727 19.021h2.864v-6.955L3.5 8.998v8.796c0 .678.55 1.227 1.227 1.227Z"
      />
      <path
        fill="#34A853"
        d="M17.408 19.021h2.864c.678 0 1.227-.55 1.227-1.227V8.998l-4.09 3.069v6.954Z"
      />
      <path
        fill="#FBBC04"
        d="M17.408 6.748v5.318L21.5 8.998V7.362c0-1.517-1.731-2.383-2.945-1.473l-1.146.86Z"
      />
      <path
        fill="#EA4335"
        fillRule="evenodd"
        d="M7.592 12.066V6.748L12.5 10.43l4.909-3.682v5.318l-4.91 3.682-4.908-3.682Z"
        clipRule="evenodd"
      />
      <path
        fill="#C5221F"
        d="M3.5 7.362v1.636l4.09 3.068V6.748L6.446 5.89C5.231 4.98 3.5 5.845 3.5 7.362Z"
      />
      <path
        fill="#4285F4"
        d="M4.727 19.021h2.864v-6.954L3.5 8.999v8.795c0 .678.55 1.228 1.227 1.228Z"
      />
      <path
        fill="#34A853"
        d="M17.408 19.021h2.864c.678 0 1.227-.549 1.227-1.227V9l-4.09 3.068v6.954Z"
      />
      <path
        fill="#FBBC04"
        d="M17.408 6.749v5.318L21.5 8.999V7.362c0-1.516-1.731-2.383-2.945-1.472l-1.146.859Z"
      />
      <path
        fill="#EA4335"
        fillRule="evenodd"
        d="M7.592 12.067V6.749L12.5 10.43 17.41 6.75v5.318l-4.91 3.681-4.908-3.681Z"
        clipRule="evenodd"
      />
      <path
        fill="#C5221F"
        d="M3.5 7.362V9l4.09 3.068V6.749l-1.145-.86C5.231 4.98 3.5 5.847 3.5 7.363Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M3.5 3h18v18h-18z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgGmail;
