import React, { ReactNode } from 'react';
import Draggable from 'react-draggable';
import { Icon } from '../../../../components/basics';
import { Close } from '../../../../components/icons';
import SimpleBar from 'simplebar-react';
import { Alert } from '../../../../components/basics/Alert/Alert';
import * as Portal from '@radix-ui/react-portal';
import { tv } from 'tailwind-variants';

type Props = {
  description: ReactNode;
  words: readonly { name: string; description: string; value: string }[];
  focusEditor: () => void;
  insertReservedWord: (text: string) => void;
  open?: boolean;
  onClose: () => void;
  container?: HTMLElement;
  draggable?: boolean;
};

const wrapper = tv({
  base: 'pointer-events-auto w-[600px] max-w-full overflow-hidden rounded-lg bg-white text-sm shadow-lg',
  variants: {
    draggable: {
      true: 'absolute bottom-8 right-8 z-50',
    },
  },
});

export const ReservedWordsModal = ({
  description,
  words,
  focusEditor,
  insertReservedWord,
  open = true,
  onClose,
  container,
  draggable = true,
}: Props) => {
  const content = (
    <div className={wrapper({ draggable })}>
      <div
        className="grid h-9 cursor-move select-none grid-cols-[1fr_auto] items-center justify-between bg-sumi-100 pl-3"
        data-reserved-words-handle={true}
        onClick={() => focusEditor()}
      >
        <span>予約語</span>
        <button
          type="button"
          onClick={() => onClose()}
          className="flex h-9 w-9 cursor-pointer items-center justify-center bg-transparent p-0"
        >
          <Icon icon={Close} size={12} />
        </button>
      </div>
      <SimpleBar className="max-h-[50vh] max-w-full">
        <div className="p-4">
          <Alert className="mb-4">{description}</Alert>
          <table className="w-full table-auto">
            <thead>
              <tr>
                <th className="px-2 py-4">予約語</th>
                <th className="px-2 py-4">説明</th>
              </tr>
            </thead>
            <tbody>
              {words.map((word, i) => (
                <tr key={i} className="border-t border-t-sumi-300">
                  <td className="px-2 py-3">
                    <button
                      type="button"
                      className="cursor-pointer bg-transparent p-0 text-start text-sea-500 hover:underline"
                      onClick={() => insertReservedWord(word.value)}
                    >
                      {word.name}
                    </button>
                  </td>
                  <td className="px-2 py-3">{word.description}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </SimpleBar>
    </div>
  );

  if (!open) {
    return null;
  }

  return draggable ? (
    <Portal.Root container={container}>
      <Draggable bounds="body" handle={`[data-reserved-words-handle]`}>
        {content}
      </Draggable>
    </Portal.Root>
  ) : (
    content
  );
};
