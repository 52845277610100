import { ComponentProps, FC } from 'react';
import { ChatSettingsDialog } from './ChatSettingsDialog';
import { useAtomValue } from 'jotai';
import { usersAtom } from '../../../atoms/firestore/user';
import { meAtom } from '../../../atoms/auth';
import { addDoc, serverTimestamp, Timestamp } from 'firebase/firestore';
import { companyCollection } from '../../../firestore';
import { useToast } from '../../../hooks/useToast';
import { useHistory } from 'react-router-dom';
import { RoomData } from '../../../firestore/entity/room';

type DialogProps = ComponentProps<typeof ChatSettingsDialog>;

type Props = Pick<DialogProps, 'open' | 'onOpenChange'>;

export const ChatCreateDialogWithLogic: FC<Props> = ({
  open,
  onOpenChange,
}) => {
  const me = useAtomValue(meAtom);
  const users = useAtomValue(usersAtom);
  const history = useHistory();
  const { showToast } = useToast();

  const members: DialogProps['members'] = users
    .filter((u) => u.id !== me.id)
    .map((u) => ({
      id: u.id,
      name: u.name,
      email: u.email,
      avatar: u,
    }));

  const onSubmit = async (subject: string, members: string[]) => {
    try {
      const roomData: Partial<RoomData> = {
        members: [...members, me.id],
        createdAt: serverTimestamp() as Timestamp,
        updatedAt: serverTimestamp() as Timestamp,
      };
      if (subject) {
        roomData.subject = subject;
      }
      const roomRef = await addDoc(companyCollection('rooms'), roomData);
      onOpenChange?.(false);
      history.push(`/rooms/${roomRef.id}`);
      return true;
    } catch (e) {
      console.error(e);
      showToast('error', 'チャットを作成できませんでした');
      return false;
    }
  };

  return (
    <ChatSettingsDialog
      open={open}
      onOpenChange={onOpenChange}
      members={members}
      onSubmit={onSubmit}
    />
  );
};
