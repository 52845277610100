import { MessageData } from './message';
import { DocumentReference, Timestamp } from 'firebase/firestore';
import { createConverter, createEntityFactory } from '../converter';
import moment from 'moment';

export type CommentAttachment = {
  filename: string;
  type: string;
  size: number;
  storagePath: string;
};

export interface CommentData {
  id: string;
  commenter: string;
  inboxId: string;
  mentionedUsers: string[];
  unreadUsers?: string[];
  messageId: string;
  messageRef: DocumentReference<MessageData>;
  threadId: string;
  teamId: string;
  text: string;
  attachments: CommentAttachment[] | null;
  edited: boolean | null;
  replyTo?: string;
  createdAt: Timestamp;
  updatedAt: Timestamp;
}

export const createComment = createEntityFactory(
  'Comment',
  (data: CommentData) => ({
    attachments: data.attachments ?? [],

    date: moment(data.createdAt.toMillis()),
  })
);

export type Comment = ReturnType<typeof createComment>;

export const commentConverter = createConverter(createComment);
