import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { H1 } from '../../Common/H1';
import { Comment } from '../../Common/Comment';
import { DefaultInput } from '../../Common/Input';
import { DefaultButton } from '../../Common/Button';
import { useParams } from 'react-router-dom';
import { H3 } from '../../Common/H3';
import { usePrevious } from '../../Settings/common';
import NotFound from '../../Error/404';
import { Icon, Spin, Select, Alert } from 'antd';
import { normalizeStr } from 'lib';
import { Tag } from 'antd';
export default (props) => {
  const { accountId, teamId } = useParams();
  const prevAccountId = usePrevious(accountId);
  const [itemLoading, setItemLoading] = useState(true);
  const [selectedItem, setSelectedItem] = useState(null);

  const [name, setName] = useState('');
  const [defaultName, setDefaultName] = useState('');
  const [domains, setDomains] = useState([]);
  const [defaultDomains, setDefaultDomains] = useState([]);
  const [externalId, setExternalId] = useState('');

  const [updating, setUpdate] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [isDuplicateName, setIsDuplicateName] = useState(false);
  const [isDuplicateDomains, setIsDuplicateDomains] = useState(false);

  const onChangeName = async (e) => {
    const name = e.target.value;
    setName(name);
    if (name.length > 0 && name !== defaultName) {
      const isDuplicate = await props.checkDuplicateName(name);
      setIsDuplicateName(isDuplicate);
    }
  };
  const onChangeDomain = async (ds) => {
    const newDomains = [];
    ds.forEach((d) => {
      if (!defaultDomains.includes(d)) newDomains.push(d);
    });
    setDomains(ds);
    if (newDomains.length > 0) {
      const isDuplicate = await props.checkDuplicateDomains(newDomains);
      setIsDuplicateDomains(isDuplicate);
    } else {
      setIsDuplicateDomains(false);
    }
  };

  useEffect(() => {
    (async () => {
      if (prevAccountId !== accountId) {
        setItemLoading(true);
        const account = await props.getAccount(accountId);

        if (account) {
          const contacts = await props.getContactByAccountId(accountId, teamId);

          setSelectedItem(account);
          setName(account.name);
          setDefaultName(account.name);
          setDomains(account.domains);
          setDefaultDomains(account.domains);
          setExternalId(account.externalId);
          setContacts(contacts);
        }
        setItemLoading(false);
      }
    })();
  }, [prevAccountId, accountId, props, teamId]);

  const updateAccount = async () => {
    setUpdate(true);
    await props.update(selectedItem.ref, {
      name,
      nameNormalized: normalizeStr(name),
      domains,
      externalId,
    });
  };

  const deleteAccount = async () => {
    await props.delete(selectedItem);
  };

  if (itemLoading) {
    return (
      <SpinWrapper>
        <Spin size="large" indicator={<Icon type="loading" spin />} />
      </SpinWrapper>
    );
  }

  if (!selectedItem) {
    return <NotFound />;
  }

  const validName = () => {
    return name.length;
  };

  return (
    <div>
      <H1>{selectedItem?.name}</H1>
      <H3>
        名称 <RequireLabel>*</RequireLabel>
      </H3>
      <Input
        value={name}
        onChange={onChangeName}
        placeholder="Onebox株式会社"
        disabled={updating}
        required
      />
      <Comment>例）Onebox株式会社</Comment>
      {isDuplicateName && (
        <Alert
          message="すでに登録されている取引先を追加することはできません
          "
          type="error"
          style={{ marginTop: 8 }}
        />
      )}

      <H3 style={{ marginTop: 24 }}>ドメイン</H3>
      <Select
        mode="tags"
        style={{ width: 400 }}
        onChange={onChangeDomain}
        value={domains}
        tokenSeparators={[',']}
        disabled={updating}
      ></Select>
      <Comment>
        コンタクトに登録されているメールアドレスのドメイン (@以降の部分)
        と自動で紐付けます。 例) onebox.tokyo
      </Comment>
      {isDuplicateDomains && (
        <Alert
          message="すでに取引先に登録されているドメインを追加することはできません"
          type="error"
          style={{ marginTop: 8 }}
        />
      )}

      <H3 style={{ marginTop: 24 }}>外部ID</H3>
      <Input
        value={externalId}
        onChange={(e) => setExternalId(e.target.value)}
        placeholder=""
        disabled={updating}
      />
      <Comment>CRMツールとの連携で活用いただく予定です。</Comment>

      <H3 style={{ marginTop: 24 }}>コンタクト</H3>
      <TagWrapper>
        {contacts.map((c) => (
          <Tag>{c.name ? c.name : c.email}</Tag>
        ))}
      </TagWrapper>
      <Comment>
        コンタクトの追加・削除はコンタクト画面から実施していただけます。
      </Comment>

      <Buttons>
        <DeleteButton type="danger" disabled={updating} onClick={deleteAccount}>
          削除
        </DeleteButton>

        <UpdateButton
          type="primary"
          disabled={
            updating || !validName() || isDuplicateName || isDuplicateDomains
          }
          onClick={updateAccount}
        >
          更新
        </UpdateButton>
      </Buttons>
    </div>
  );
};

const Input = styled(DefaultInput)`
  width: 400px;
  height: 40px;
  margin: 10px 0;
`;

const Buttons = styled.div`
  display: flex;
  margin-top: 30px;
`;

const UpdateButton = styled(DefaultButton)`
  height: 40px;
  width: 100%;
  max-width: 100px;
`;

const DeleteButton = styled(UpdateButton)`
  margin-right: 16px;
`;

const SpinWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const RequireLabel = styled.span`
  color: #f5222d;
`;

const TagWrapper = styled.div`
  margin: 10px 0px;
`;
