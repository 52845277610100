import {
  cloneElement,
  ComponentProps,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react';
import { debounce } from 'lodash';
import { twMerge } from 'tailwind-merge';
import styles from './Tabs.module.css';

type Props = ComponentProps<'div'> & {
  leftArrow?: ReactElement;
  rightArrow?: ReactElement;
  wrapperElementClassName?: string;
};

export const HorizontalScrollArea = ({
  leftArrow,
  rightArrow,
  className,
  children,
  wrapperElementClassName,
  ...props
}: Props) => {
  const [visibleLeftArrow, setVisibleLeftArrow] = useState(false);
  const [visibleRightArrow, setVisibleRightArrow] = useState(false);
  const scrollableRef = useRef<HTMLDivElement | null>(null);
  const updateArrows = () => {
    const wrapper = scrollableRef.current;
    if (wrapper) {
      setVisibleLeftArrow(wrapper.scrollLeft > 10);
      setVisibleRightArrow(
        wrapper.scrollWidth - (wrapper.clientWidth + wrapper.scrollLeft) > 10
      );
    }
  };
  useEffect(() => {
    updateArrows();
  }, []);
  useEffect(() => {
    const current = scrollableRef.current;
    if (!current) {
      return;
    }
    const onWheel = (e: WheelEvent) => {
      if (e.deltaY === 0) {
        return;
      }
      e.preventDefault();
      const amount = e.deltaY / 2;
      current.scrollBy({ left: amount });
    };
    const debouncedUpdateArrows = debounce(updateArrows, 200);
    current.addEventListener('wheel', onWheel);
    current.addEventListener('scroll', updateArrows);
    window.addEventListener('resize', debouncedUpdateArrows);
    return () => {
      current.removeEventListener('wheel', onWheel);
      current.removeEventListener('scroll', updateArrows);
      window.removeEventListener('resize', debouncedUpdateArrows);
    };
  }, []);

  const onClickLeftArrow = () => {
    scrollableRef.current?.scrollBy({
      left: -150,
      behavior: 'smooth',
    });
  };
  const onClickRightArrow = () => {
    scrollableRef.current?.scrollBy({ left: 150, behavior: 'smooth' });
  };

  return (
    <div className={twMerge('relative', wrapperElementClassName)}>
      <div
        className={twMerge(
          'relative h-full overflow-x-auto',
          styles.hideScrollbar,
          className
        )}
        {...props}
        ref={scrollableRef}
      >
        {children}
      </div>
      {visibleLeftArrow &&
        (leftArrow ? (
          cloneElement(leftArrow, { onClick: onClickLeftArrow })
        ) : (
          <button
            className={twMerge(
              'absolute left-0 top-0 flex h-full w-6 cursor-pointer select-none items-center justify-center p-0 text-[1rem] text-sm font-bold transition-all hover:w-8',
              styles.bgLeft
            )}
            onClick={onClickLeftArrow}
          >
            &lt;
          </button>
        ))}
      {visibleRightArrow &&
        (rightArrow ? (
          cloneElement(rightArrow, { onClick: onClickRightArrow })
        ) : (
          <button
            className={twMerge(
              'absolute right-0 top-0 flex h-full w-6 cursor-pointer select-none items-center justify-center p-0 text-[1rem] font-bold transition-all hover:w-8',
              styles.bgRight
            )}
            onClick={onClickRightArrow}
          >
            &gt;
          </button>
        ))}
    </div>
  );
};
