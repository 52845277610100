import { Store } from '../store';

const TOP_PATH = '/me/drafts';
const READ_ONLY_USER_TOP_PATH = '/me/commented';

export const useTopPagePath = (store: Store): string => {
  if (!store.me.isReadOnly) {
    return TOP_PATH;
  }
  // MobXが空の配列にアクセスすると警告を出すのでチェックする
  if (store.teams.length <= 0) {
    return READ_ONLY_USER_TOP_PATH;
  }
  const firstTeam = store.teams[0];
  if (!firstTeam) {
    return READ_ONLY_USER_TOP_PATH;
  }
  const firstTag = store.getInboxTag(firstTeam.id);
  if (!firstTag) {
    return READ_ONLY_USER_TOP_PATH;
  }
  return `/teams/${firstTeam.id}/tags/${firstTag.id}/messages`;
};
