import { generatePath, matchPath } from 'react-router-dom';
import { SettingObjectRoutes } from '../../common';
import { CompanyEmailTabKey } from './CompnayEmailTabKey';

export interface CompanyEmailPaths {
  base: string;
}

type BaseParams = { teamId: string };

const PATH_STATUSES = '/settings/teams/:teamId/mail/statuses';
const PATH_AUTO_REPLY = '/settings/teams/:teamId/mail/auto_reply';

export class CompanyEmailRoutes {
  constructor(
    readonly paths: CompanyEmailPaths,
    readonly inboxRoutes: SettingObjectRoutes<BaseParams, 'inboxId'>,
    readonly templateRoutes: SettingObjectRoutes<BaseParams, 'templateId'>,
    readonly signatureRoutes: SettingObjectRoutes<BaseParams, 'signatureId'>,
    readonly tagRoutes: SettingObjectRoutes<BaseParams, 'tagId'>,
    readonly filterRoutes: SettingObjectRoutes<BaseParams, 'filterId'>,
    private router: { push: (location: string) => void }
  ) {}

  getBasePathName(teamId: string): string {
    return generatePath(this.paths.base, { teamId });
  }

  redirectToInboxIfNeeded(pathname: string, teamId: string): void {
    if (matchPath(pathname, { path: this.paths.base, exact: true })) {
      this.inboxRoutes.toIndex({ teamId: teamId }, { replace: true });
    }
  }

  toTab(tab: CompanyEmailTabKey, teamId: string): void {
    this.router.push(this.getPathNameForTab(tab, teamId));
  }

  getPathNameForTab(tab: CompanyEmailTabKey, teamId: string): string {
    switch (tab) {
      case CompanyEmailTabKey.Inbox:
        return this.inboxRoutes.getIndexPathName({ teamId });
      case CompanyEmailTabKey.Template:
        return this.templateRoutes.getIndexPathName({ teamId });
      case CompanyEmailTabKey.Signature:
        return this.signatureRoutes.getIndexPathName({ teamId });
      case CompanyEmailTabKey.Tag:
        return this.tagRoutes.getIndexPathName({ teamId });
      case CompanyEmailTabKey.Filter:
        return this.filterRoutes.getIndexPathName({ teamId });
      case CompanyEmailTabKey.AutoReply:
        return generatePath(PATH_AUTO_REPLY, { teamId });
      case CompanyEmailTabKey.Status:
        return generatePath(PATH_STATUSES, { teamId });
      default:
        return this.inboxRoutes.getIndexPathName({ teamId });
    }
  }

  getTabForPathName(pathname: string): CompanyEmailTabKey {
    if (matchPath(pathname, { path: this.inboxRoutes.paths.index })) {
      return CompanyEmailTabKey.Inbox;
    } else if (matchPath(pathname, { path: this.templateRoutes.paths.index })) {
      return CompanyEmailTabKey.Template;
    } else if (
      matchPath(pathname, { path: this.signatureRoutes.paths.index })
    ) {
      return CompanyEmailTabKey.Signature;
    } else if (matchPath(pathname, { path: this.tagRoutes.paths.index })) {
      return CompanyEmailTabKey.Tag;
    } else if (matchPath(pathname, { path: this.filterRoutes.paths.index })) {
      return CompanyEmailTabKey.Filter;
    } else if (matchPath(pathname, { path: PATH_STATUSES })) {
      return CompanyEmailTabKey.Status;
    }
    return CompanyEmailTabKey.Inbox;
  }
}
