import { SettingPageDrawer } from '../../../common/SettingPageDrawer/SettingPageDrawer';
import { InputGroup } from '../../../../../components/forms/InputGroup/InputGroup';
import { Controller, useForm } from 'react-hook-form';
import { Input } from '../../../../../components/forms';
import { SettingWysiwygEditor } from '../../../common/SettingFields/SettingWysiwygEditor';
import React from 'react';
import { Button } from '../../../../../components/basics';
import { useConfirmDialog } from '../../../../../hooks/confirmDialog';
import { range } from 'lodash';

type Signature = {
  title: string;
  body: string;
  bodyHtml: string;
};

export type SignatureUpdate = Signature;

type Props = {
  signatureId: string | undefined;
  signature: Partial<Signature>;
  onUpdate: (id: string | undefined, update: SignatureUpdate) => Promise<void>;
  onDelete: (id: string) => Promise<void>;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  readonly: boolean;
  container?: HTMLElement;
};

export const SignatureEditDrawer = ({
  signatureId,
  signature,
  onUpdate,
  onDelete,
  open,
  onOpenChange,
  readonly,
  container,
}: Props) => {
  const openDialog = useConfirmDialog();
  const {
    register,
    control,
    handleSubmit,
    formState: { isSubmitting, isDirty },
    setValue,
    reset,
  } = useForm<Signature>({
    defaultValues: {
      title: signature.title ?? '',
      body: signature.body ?? '',
      bodyHtml: signature.bodyHtml ?? '',
    },
  });
  const onSubmit = async (update: Signature) => {
    try {
      await onUpdate(signatureId, {
        ...update,
        bodyHtml: replaceWSWithNBSPInBodyHtml(update.bodyHtml),
      });
      reset(update);
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <SettingPageDrawer
      title={signatureId ? '署名編集' : '署名作成'}
      open={open}
      onOpenChange={onOpenChange}
      container={container}
      drawerClassName="w-[560px]"
    >
      <form
        action=""
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col gap-4"
        data-testid="signature-edit-drawer"
      >
        <Controller
          name="title"
          control={control}
          rules={{
            required: '入力してください',
          }}
          render={({ fieldState }) => (
            <InputGroup
              label="タイトル"
              description="例）署名①"
              errorMessage={fieldState.error?.message}
            >
              <Input
                {...register('title', {
                  setValueAs: (v) => (typeof v === 'string' ? v.trim() : v),
                })}
                placeholder="署名①"
                disabled={readonly}
                autoFocus
              />
            </InputGroup>
          )}
        />
        <Controller
          name="bodyHtml"
          control={control}
          rules={{
            required: '入力してください',
          }}
          render={({ fieldState, field: { value, onChange } }) => (
            <InputGroup
              label="本文"
              description="例）株式会社○○○○ ○○部 山田 太郎…"
              errorMessage={fieldState.error?.message}
            >
              <SettingWysiwygEditor
                data-testid="signature-body-editor"
                defaultValue={value}
                onChange={(text, html) => {
                  onChange(html);
                  setValue('body', text);
                }}
                placeholder="───────────────────
株式会社○○○○
○○部
山田 太郎
〒000-0000 東京都○○区1-2-3
TEL: 03-0000-0000 FAX: 03-0000-0000
Email: yamada@example.com
URL: https://yaritori.jp
───────────────────"
                disabled={readonly}
              />
            </InputGroup>
          )}
        />
        <div className="flex gap-4">
          {signatureId ? (
            <>
              <Button
                color="danger"
                disabled={readonly || isSubmitting}
                onClick={() =>
                  openDialog({
                    title: '署名を削除しますか？',
                    description: '一度削除すると元に戻せません',
                    okType: 'danger',
                    okText: '削除',
                    onOk: async () => await onDelete(signatureId),
                  })
                }
              >
                削除
              </Button>
              <Button
                type="submit"
                disabled={readonly || !isDirty}
                loading={isSubmitting}
              >
                更新
              </Button>
            </>
          ) : (
            <Button
              type="submit"
              disabled={readonly || !isDirty}
              loading={isSubmitting}
            >
              作成
            </Button>
          )}
        </div>
      </form>
    </SettingPageDrawer>
  );
};

const replaceWSWithNBSPInBodyHtml = (content: string) => {
  const doc = new DOMParser().parseFromString(
    '<body>' + content + '</body>',
    'text/html'
  );
  replaceInChildNodes(doc.body);

  return range(doc.body.children.length)
    .map((i) => {
      return new XMLSerializer().serializeToString(doc.body.children[i]);
    })
    .join('\n')
    .replace(/xmlns="[^"]+"/g, '');
};

const replaceInChildNodes = (node: Node) => {
  node.childNodes.forEach((n) => {
    if (n.nodeType === Node.TEXT_NODE) {
      // Text Node
      if (n.nodeValue?.search(' ') ?? -1 >= 0) {
        n.nodeValue = n.nodeValue?.replaceAll(' ', '\u00a0') ?? null;
      }
    } else if (n.hasChildNodes()) {
      replaceInChildNodes(n);
    }
  });
};
