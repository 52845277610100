// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FTg9oozFu9N2gSxkIdDH {\n  /* スクロールバーを表示しない */\n  -ms-overflow-style: none !important;\n  /* IE, Edge 対応 */\n  scrollbar-width: none !important;\n  /* Firefox 対応 */\n}\n.FTg9oozFu9N2gSxkIdDH::-webkit-scrollbar {\n  /* Chrome, Safari 対応 */\n  display: none !important;\n}\n", "",{"version":3,"sources":["webpack://./src/components/sidebar/SidebarBase/SidebarBase.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,mCAAmC;EACnC,gBAAgB;EAChB,gCAAgC;EAChC,eAAe;AACjB;AACA;EACE,sBAAsB;EACtB,wBAAwB;AAC1B","sourcesContent":[".hideScrollbar {\n  /* スクロールバーを表示しない */\n  -ms-overflow-style: none !important;\n  /* IE, Edge 対応 */\n  scrollbar-width: none !important;\n  /* Firefox 対応 */\n}\n.hideScrollbar::-webkit-scrollbar {\n  /* Chrome, Safari 対応 */\n  display: none !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hideScrollbar": "FTg9oozFu9N2gSxkIdDH"
};
export default ___CSS_LOADER_EXPORT___;
