import * as React from 'react';
import type { SVGProps } from 'react';
const SvgMessageForward = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    viewBox="0 0 18 18"
    {...props}
  >
    <path
      fill="currentColor"
      d="M13.575 8.738 10.912 11.4a.508.508 0 0 0-.159.394c.006.15.066.281.178.393a.55.55 0 0 0 .403.17.55.55 0 0 0 .403-.17l3.62-3.618a.54.54 0 0 0 .168-.394.54.54 0 0 0-.169-.394L11.72 4.144a.55.55 0 0 0-.403-.169.55.55 0 0 0-.404.169.55.55 0 0 0-.168.403c0 .156.056.29.168.403l2.663 2.663H5.962c-1.062 0-1.946.353-2.653 1.059-.706.706-1.06 1.59-1.06 2.653v2.363a.546.546 0 0 0 .563.562.546.546 0 0 0 .563-.563v-2.362c0-.75.244-1.369.731-1.856.488-.488 1.106-.731 1.856-.731h7.613Z"
    />
  </svg>
);
export default SvgMessageForward;
