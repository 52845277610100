import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { sentConverter } from 'lib';
import LogoImageSrc from '../../../assets/logos/logo_wide_fullcolor_RGB.png';
import * as color from '../../../color';
import Header from '../Message/header';
import Body from './body';
import { companyDoc } from '../../../firestore';
import { getDoc } from 'firebase/firestore';

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sent: null,
      storageSent: null,
    };
  }

  componentDidMount() {
    this.loadSent();
  }

  componentDidUpdate() {
    this.startprint();
  }

  startprint = () => {
    if (this.state.storageSent) {
      setTimeout(() => {
        window.print();
      }, 500);
    }
  };

  handleStorageSentLoaded = (storageSent) => {
    this.setState({ storageSent });
  };

  loadSent = async () => {
    const { sentId } = this.props.match.params;

    const sentSnapshot = await getDoc(
      companyDoc('sent', sentId, sentConverter)
    );
    if (!sentSnapshot.exists()) {
      return null;
    }

    const sent = sentSnapshot.data();
    this.setState({ sent });
    return sent;
  };

  render() {
    const { sent } = this.state;
    const { me } = this.props.store;

    return (
      <Wrapper>
        {sent && (
          <>
            <IntroHeader>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Logo src={LogoImageSrc} />
              </div>
              <MyEmailText>{`${me.name} <${me.email}>`}</MyEmailText>
            </IntroHeader>
            <hr />
            <Header>{sent.subject}</Header>
            <hr />
            <Body
              sent={sent}
              attachments={sent.attachments}
              onStorageSentLoaded={this.handleStorageSentLoaded}
            />
          </>
        )}
      </Wrapper>
    );
  }
}

export default compose(withRouter, inject('store'), observer)(Index);

const Wrapper = styled.div`
  width: 100%;
  margin: 8px;
  background-color: ${color.common.white};
`;

const IntroHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 12px;
  padding-bottom: 12px;
`;

const Logo = styled.img`
  height: 40px;
`;

const MyEmailText = styled.p`
  color: grey;
  font-weight: bold;
`;
