import * as React from 'react';
import type { SVGProps } from 'react';
const SvgError = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={25}
    fill="none"
    viewBox="0 0 24 25"
    {...props}
  >
    <path
      fill="currentColor"
      d="M12 17.5c.283 0 .52-.096.713-.288A.968.968 0 0 0 13 16.5a.968.968 0 0 0-.287-.713A.968.968 0 0 0 12 15.5a.968.968 0 0 0-.713.287.968.968 0 0 0-.287.713c0 .283.096.52.287.712.192.192.43.288.713.288Zm0-4c.283 0 .52-.096.713-.287A.968.968 0 0 0 13 12.5v-4a.967.967 0 0 0-.287-.713A.968.968 0 0 0 12 7.5a.968.968 0 0 0-.713.287A.967.967 0 0 0 11 8.5v4c0 .283.096.52.287.713.192.191.43.287.713.287Zm0 9a9.738 9.738 0 0 1-3.9-.788 10.099 10.099 0 0 1-3.175-2.137c-.9-.9-1.612-1.958-2.137-3.175A9.738 9.738 0 0 1 2 12.5c0-1.383.263-2.683.788-3.9a10.099 10.099 0 0 1 2.137-3.175c.9-.9 1.958-1.612 3.175-2.137A9.738 9.738 0 0 1 12 2.5c1.383 0 2.683.263 3.9.788a10.098 10.098 0 0 1 3.175 2.137c.9.9 1.613 1.958 2.137 3.175A9.738 9.738 0 0 1 22 12.5a9.738 9.738 0 0 1-.788 3.9 10.098 10.098 0 0 1-2.137 3.175c-.9.9-1.958 1.613-3.175 2.137a9.738 9.738 0 0 1-3.9.788Zm0-2c2.233 0 4.125-.775 5.675-2.325C19.225 16.625 20 14.733 20 12.5c0-2.233-.775-4.125-2.325-5.675C16.125 5.275 14.233 4.5 12 4.5c-2.233 0-4.125.775-5.675 2.325C4.775 8.375 4 10.267 4 12.5c0 2.233.775 4.125 2.325 5.675C7.875 19.725 9.767 20.5 12 20.5Z"
    />
  </svg>
);
export default SvgError;
