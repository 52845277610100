import { useHistory } from 'react-router-dom';
import { Message } from '../../Teams/Messages/Message';
import { WrappedInfiniteScroll } from '../../../../../components/WrappedInfiniteScroll';
import { useAtom, useAtomValue } from 'jotai';
import { useStore } from 'hooks/useStore';
import { starredMessagesPaginateAtom } from '../../../../../atoms/firestore/starred/starredMessage';
import { MessageLike } from 'lib';
import { threadViewAtom } from '../../../../../atoms/auth';
import { Loading } from '../../../../../components/basics';

const MessageListMeStarred = () => {
  const mobxStore = useStore();
  const history = useHistory();
  const isThreadView = useAtomValue(threadViewAtom);
  const [starredMessagesPaginate, dispatch] = useAtom(
    starredMessagesPaginateAtom
  );

  const to = (link: string) => history.push(link);

  const toLink = (message: MessageLike) => {
    let url = `/me/starred/messages/${message.id}`;
    if (isThreadView) {
      url += '?view=thread';
    }
    return url;
  };

  const loadMore = async () => {
    await dispatch('loadMore');
  };

  return (
    <WrappedInfiniteScroll
      initialLoad={false}
      pageStart={0}
      loadMore={loadMore}
      hasMore={starredMessagesPaginate.hasMore}
      useWindow={false}
      className="pt-4"
    >
      {starredMessagesPaginate.state === 'loading' && (
        <div className="absolute top-0 z-10 flex h-32 w-full items-center justify-center">
          <Loading />
        </div>
      )}

      {starredMessagesPaginate.state === 'hasData' &&
        starredMessagesPaginate.data.map((message) => (
          <Message
            message={message}
            checkMessage={mobxStore.checkMessage}
            uncheckMessage={mobxStore.uncheckMessage}
            checkedMessages={mobxStore.checkedMessages}
            onClick={() => to(toLink(message))}
            to={toLink(message)}
            key={message.id}
            me={mobxStore.me}
            checkDisabled={true}
            getTag={mobxStore.getTag}
            isReadOnly={mobxStore.me.isReadOnly}
          />
        ))}
    </WrappedInfiniteScroll>
  );
};

export default MessageListMeStarred;
