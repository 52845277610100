import MessageListEmpty from './MessageListEmpty';
import teamEmptyImage from './images/teamEmptyImage.png';

type Props = {
  description?: string;
};

const TeamMessageListEmpty = ({ description }: Props) => {
  return <MessageListEmpty image={teamEmptyImage} description={description} />;
};

export default TeamMessageListEmpty;
