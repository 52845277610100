/**
 * IPアドレス制限機能が使える場合は TRUE、使えない場合は FALSE。
 * TODO: ユニットテストを追加
 *
 * @param ipBlockCount
 * @returns
 */
export const isIpBlockSupported = (ipBlockCount: any): boolean => {
  return typeof ipBlockCount === 'number' && ipBlockCount > 0;
};

/**
 * LINE機能が使える場合は TRUE、使えない場合は FALSE。
 * TODO: ユニットテストを追加
 *
 * @param ipBlockCount
 * @returns
 */
export const isLineSupported = (lineIntegrationCount: any): boolean => {
  return typeof lineIntegrationCount === 'number' && lineIntegrationCount > 0;
};
