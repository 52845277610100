import { ReactNode } from 'react';
import SimpleBar from 'simplebar-react';

type Props = {
  sidebar: ReactNode;
  children: ReactNode;
};

export const SettingPageLayout = ({ sidebar, children }: Props) => {
  return (
    <div className="flex h-full max-h-full w-full">
      <div className="w-[250px]">{sidebar}</div>
      <SimpleBar className="max-h-full w-[calc(100%_-_250px)] flex-1">
        {children}
      </SimpleBar>
    </div>
  );
};
