import {
  messageConverter,
  quoteText,
  sentConverter,
  StorageMessage,
  StorageSent,
} from 'lib';
import { fetchAsJson, fetchAsText } from '../../../util';
import { companyDoc } from '../../../firestore';
import { getDoc } from 'firebase/firestore';

export default class QuotedMessageGenerator {
  #isReplyToSent;
  #inReplyToMessageRef;
  #inReplyToSentRef;
  #inReplyToSentId;
  #isForwarded;
  #storageSent;
  #storageMessage;
  #sent;
  #message;

  constructor({
    inReplyToMessageRef,
    inReplyToSentRef,
    inReplyToSentId,
    isForwarded,
  }) {
    this.#inReplyToMessageRef = inReplyToMessageRef;
    this.#inReplyToSentRef = inReplyToSentRef;
    this.#isReplyToSent = Boolean(inReplyToSentRef);
    this.#inReplyToSentId = inReplyToSentId;
    this.#isForwarded = isForwarded;
  }

  #getMessage = async () => {
    if (this.#message) return this.#message;

    this.#message = (
      await getDoc(
        companyDoc('messages', this.#inReplyToMessageRef.id, messageConverter)
      )
    ).data();
    return this.#message;
  };

  #getSent = async () => {
    if (this.#sent) return this.#sent;
    this.#sent = (
      await getDoc(companyDoc('sent', this.#inReplyToSentRef.id, sentConverter))
    ).data();
    return this.#sent;
  };

  #fetchStorageSent = async () => {
    if (this.#storageSent) return this.#storageSent;

    const sent = await this.#getSent();
    this.#storageSent = new StorageSent(
      this.#inReplyToSentId,
      await fetchAsJson(sent.storagePath)
    );
    return this.#storageSent;
  };

  #fetchStorageMessage = async () => {
    if (this.#storageMessage) return this.#storageMessage;

    const message = await this.#getMessage();
    this.#storageMessage = new StorageMessage(
      message.id,
      await fetchAsJson(message.storagePath)
    );
    return this.#storageMessage;
  };

  /* for StorageSent */
  #headerForStorageSent = async () => {
    const storageSent = await this.#fetchStorageSent();
    return this.#isForwarded
      ? storageSent.generateForwardedHeader()
      : storageSent.generateQuotedHeader();
  };

  #textForStorageSent = async () => {
    const storageSent = await this.#fetchStorageSent();
    return storageSent.quotedText;
  };

  #htmlForStorageSent = async () => {
    const storageSent = await this.#fetchStorageSent();
    return storageSent.html || storageSent.textAsHtml || storageSent.text;
  };

  /* for StorageMessage */
  #htmlForStorageMessage = async () => {
    const storageMessage = await this.#fetchStorageMessage();
    return (
      storageMessage.html || storageMessage.textAsHtml || storageMessage.text
    );
  };

  /* for Message */
  #headerForMessage = async () => {
    const message = await this.#getMessage();
    return this.#isForwarded
      ? // 転送
        message.generateForwardedHeader()
      : // 返信
        message.generateQuotedHeader();
  };

  #textForMessage = async () => {
    const message = await this.#getMessage();
    if (message.storagePath) {
      const storageMessage = await this.#fetchStorageMessage();
      return storageMessage.quotedText;
    }

    if (message.textStoragePath) {
      const text = await fetchAsText(message.textStoragePath);
      return quoteText(text);
    }
    return '（テキストパートが存在しません）';
  };

  #htmlForMessage = async () => {
    const message = await this.#getMessage();
    if (message.storagePath) {
      return await this.#htmlForStorageMessage();
    }

    if (message.htmlStoragePath) {
      return message.html || (await fetchAsText(message.htmlStoragePath));
    }

    if (message.textAsHtmlStoragePath) {
      return (
        message.textAsHtml || (await fetchAsText(message.textAsHtmlStoragePath))
      );
    }

    return '';
  };

  execute = async () => {
    if (this.#isReplyToSent) {
      // 送信に対する返信
      const header = await this.#headerForStorageSent();
      const text = await this.#textForStorageSent();
      const html = await this.#htmlForStorageSent();
      return [header, text, html];
    }

    // 受信に対する返信
    const header = await this.#headerForMessage();
    const text = await this.#textForMessage();
    const html = await this.#htmlForMessage();

    return [header, text, html];
  };
}
