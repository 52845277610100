import { MailAddress } from 'lib';
import { ReactNode } from 'react';
import styled from 'styled-components';
import { Address } from './Address';

const SPACING = 8;

type Props = {
  subject?: string;
  mailAddresses: MessageMailAddresses;
};

export type MessageMailAddresses = {
  from: MailAddress[];
  to?: MailAddress[];
  cc?: MailAddress[];
  bcc?: MailAddress[];
};

export const MessageMeta: React.FC<Props> = ({ subject, mailAddresses }) => {
  const { from, to, cc, bcc } = mailAddresses;
  return (
    <Container>
      {subject ? <SubjectRow subject={subject} /> : null}
      <AddressRow label="差出人" addresses={from}></AddressRow>
      {to && to.length > 0 ? (
        <AddressRow label="宛先" addresses={to}></AddressRow>
      ) : null}
      {cc && cc.length > 0 ? (
        <AddressRow label="Cc" addresses={cc}></AddressRow>
      ) : null}
      {bcc && bcc.length > 0 ? (
        <AddressRow label="Bcc" addresses={bcc}></AddressRow>
      ) : null}
    </Container>
  );
};

const Container = styled.div`
  & > * {
    margin-bottom: ${SPACING}px;
    &:last-of-type {
      margin-bottom: 0px;
    }
  }
`;

// ------------  SubjectRow -----------------

export const SubjectRow: React.FC<{ subject: string }> = (props) => {
  return (
    <SubjectRowContainer>
      <div className="label-wrapper">
        <Label>件名</Label>
      </div>
      <div className="value">{props.subject}</div>
    </SubjectRowContainer>
  );
};

const SubjectRowContainer = styled.div`
  display: flex;
  & > .label-wrapper {
    flex-shrink: 0;
    margin-right: 8px;
  }

  & > .value {
    font-size: 13px;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.85);
  }
`;

// ------------  AddressRow -----------------

export const AddressRow: React.FC<{
  label: string;
  addresses: MailAddress[];
}> = (props) => {
  return (
    <AddressRowContainer>
      <div className="label-wrapper">
        <Label>{props.label}</Label>
      </div>
      <div className="addresses">
        {props.addresses.map((v, i) => {
          return (
            <Address
              key={v.address}
              address={v.address}
              name={v.name}
            ></Address>
          );
        })}
      </div>
    </AddressRowContainer>
  );
};

const AddressRowContainer = styled.div`
  display: flex;

  & > .label-wrapper {
    padding-top: 2px;
    flex-shrink: 0;
    margin-right: 6px;
  }

  & > .addresses {
    display: flex;
    flex-wrap: wrap;
    & > * {
      margin-bottom: ${SPACING}px;
      margin-right: 6px;
    }
    margin-bottom: -${SPACING}px;
  }
`;

// ------------  Label -----------------

const Label: React.FC<{ children: ReactNode }> = (props) => {
  return <LabelContainer>{props.children}:</LabelContainer>;
};

const LabelContainer = styled.div`
  color: rgba(0, 0, 0, 0.65);
  font-weight: 600;
`;
