import * as React from 'react';
import type { SVGProps } from 'react';
const SvgPlus = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={15}
    height={17}
    fill="none"
    viewBox="0 0 15 17"
    {...props}
  >
    <path
      fill="currentColor"
      d="M8.135.867H6.863c-.113 0-.17.057-.17.17v6.658H.375c-.113 0-.17.057-.17.17v1.272c0 .113.057.17.17.17h6.318v6.658c0 .113.057.17.17.17h1.272c.113 0 .17-.057.17-.17V9.307h6.32c.113 0 .17-.057.17-.17V7.865c0-.113-.057-.17-.17-.17h-6.32V1.037c0-.113-.057-.17-.17-.17Z"
    />
  </svg>
);
export default SvgPlus;
