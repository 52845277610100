import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import Avatar from '../../../../../Common/Avatar';

class Index extends Component {
  render() {
    if (!this.props.assignee) return <div />;
    const assignee = this.props.store.getUser(this.props.assignee);
    return <Avatar aria-label="譲受人" user={assignee} size="small" />;
  }
}

export default compose(withRouter, inject('store'), observer)(Index);
