import { createConverter, createEntityFactory } from '../converter';

export type CounterData = {
  teamId: string;
  inboxId: string;
  count: number;
} & ({ assignee: string } | { tagId: string });

export const createCounter = createEntityFactory<'Counter', CounterData>(
  'Counter'
);

export type Counter = ReturnType<typeof createCounter>;

export const counterConverter = createConverter(createCounter);
