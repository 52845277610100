import { PropsWithChildren } from 'react';

type Props = {
  htmlFor?: string;
  label: string;
  className?: string;
};

export const Label = ({
  htmlFor,
  label,
  className = '',
  children,
}: PropsWithChildren<Props>): JSX.Element => {
  const onClick = () => {
    if (!htmlFor) {
      return;
    }
    const elem = document.getElementById(htmlFor);
    elem?.click();
  };

  return (
    <div className="flex items-center text-sm text-sumi-900">
      <label
        htmlFor={htmlFor}
        className={'mr-2.5 text-left ' + className}
        onClick={onClick}
      >
        {label}
      </label>
      <div className="flex-1">{children}</div>
    </div>
  );
};
