import React from 'react';
import styled from 'styled-components';
import { Skeleton } from 'antd';
import * as color from '../../../color';

export default () => {
  return (
    <MessageWrapper>
      <Head>
        {/* タイトルのスケルトン */}
        <Skeleton loading active paragraph={false} />
        {/* ユーザ情報のスケルトン */}
        <Skeleton loading avatar active paragraph={false} />
      </Head>
      <Body>
        <MessageBody>
          {/* メール内容のスケルトン */}
          <Skeleton loading active />
        </MessageBody>
      </Body>
    </MessageWrapper>
  );
};

const MessageWrapper = styled.div`
  padding: 20px 0;
  margin: 10px;
  border-radius: 10px;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.16);
  background-color: ${color.common.white};
`;

const Head = styled.div`
  padding: 0 30px 20px;
  border-bottom: solid 0.5px #f1f1f3;
`;

const Body = styled.div`
  padding: 20px 30px 0 30px;
`;

const MessageBody = styled.div`
  margin-bottom: 20px;
`;
