import { createConverter, createEntityFactory } from '../converter';
import { FirestoreDataConverter, Timestamp } from 'firebase/firestore';
import { DraftAttachment } from './draft';
import { TagColor } from '../tagColor';

export const SuppressionStatus = ['error'] as const;

export type SuppressionStatus = typeof SuppressionStatus[number];

export type SuppressionData = {
  email: string;
  targets: {
    [key: string]: SuppressionStatus;
  };
};

export const createSuppression = createEntityFactory<
  'Suppression',
  SuppressionData
>('Suppression');

export type Suppression = ReturnType<typeof createSuppression>;

export type DeliveryAddressData = {
  name?: string;
  email: string;
  createdAt: Timestamp;
  updatedAt: Timestamp;
};

export const createDeliveryAddress = createEntityFactory<
  'DeliveryAddress',
  DeliveryAddressData
>('DeliveryAddress');

export type DeliveryAddress = ReturnType<typeof createDeliveryAddress>;

export const deliveryAddressConverter: FirestoreDataConverter<DeliveryAddress> =
  createConverter(createDeliveryAddress);

export type DeliveryMessageData = {
  deliveryAddressId: string;
  contactTags: string[];
  subject: string;
  text: string;
  html?: string;
  plainTextMode: boolean;
  attachments: DraftAttachment[];
  isSent: boolean;
  isSending: boolean;
  sentAt?: Timestamp;
  isGenerating: boolean;
  createdAt: Timestamp;
  updatedAt: Timestamp;
  display?: {
    address: {
      name: string | null;
      email: string;
    };
    contactTags: {
      teamId: string;
      teamName: string;
      id: string;
      name: string;
      color: TagColor | null;
    }[];
    recipients: {
      count: number;
      total: number;
    };
  };
};

export const createDeliveryMessage = createEntityFactory<
  'DeliveryMessage',
  DeliveryMessageData
>('DeliveryMessage');

export type DeliveryMessage = ReturnType<typeof createDeliveryMessage>;

export const deliveryMessageConverter: FirestoreDataConverter<DeliveryMessage> =
  createConverter(createDeliveryMessage);
