import React from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import media from 'styled-media-query';
import bg1 from '../../assets/bg/bg1.png';
import * as color from '../../color';
import { DefaultInput } from '../Common/Input';
import { DefaultButton } from '../Common/Button';
import { Footer } from '../Common/Footer';
import firebase from '../../firebase';
import * as Sentry from '@sentry/react';
import { Divider } from 'antd';
import { MFAVerifyPhoneCodeForm } from './MFA';

class SignIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      isSigningIn: false,
      errorMessage: '',
    };
  }

  submit = async (e) => {
    e.preventDefault();
    this.setState({ isSigningIn: true });
    try {
      const { email, password } = this.state;
      // firebaseにログイン
      await firebase.auth().signInWithEmailAndPassword(email, password);
      // ログイン成功
      window.location.href = '/';
    } catch (error) {
      console.error('SignIn.submit:', error);
      // "auth/user-not-found"
      const { code } = error;
      let errorMessage;
      let mfaRequiredError = null;
      switch (code) {
        case 'auth/user-not-found':
          errorMessage = '※登録されていないメールアドレスです';
          break;
        case 'auth/wrong-password':
          errorMessage = '※パスワードが違います';
          break;
        case 'auth/too-many-requests':
          errorMessage =
            '※認証に一定回数失敗しました。時間をおいてから再度お試し下さい。';
          break;
        case 'auth/multi-factor-auth-required':
          mfaRequiredError = error;
          break;
        case 'auth/internal-error':
          if (error.message.includes('Unauthorized access')) {
            errorMessage =
              'ログインが許可されませんでした。自社のyaritori管理者権限を持つ担当に問い合わせください';
          } else {
            errorMessage = '予期せぬエラーが発生しました';
          }

          break;
        default:
          Sentry.captureMessage(e);
          errorMessage = '予期せぬエラーが発生しました';
      }
      this.setState({ errorMessage, isSigningIn: false, mfaRequiredError });
    }
  };

  onChangeEmail = (e) => this.setState({ email: e.target.value });

  onChangePassword = (e) => this.setState({ password: e.target.value });

  toResetPassword = () => this.props.history.push('/resetPassword');

  render() {
    const { isSigningIn, email, password, errorMessage, mfaRequiredError } =
      this.state;

    // MFAが設定されている場合にMFAの確認画面を設定する
    if (mfaRequiredError) {
      return (
        <Wrapper>
          <Left />
          <Right>
            <div />
            <Content>
              <RegisterWrapper>
                <MFAVerifyPhoneCodeForm mfaRequiredError={mfaRequiredError} />
              </RegisterWrapper>
            </Content>
            <Footer />
          </Right>
        </Wrapper>
      );
    }

    return (
      <Wrapper>
        <Left />
        <Right>
          <div />
          <Content>
            <RegisterWrapper>
              <form onSubmit={this.submit}>
                {errorMessage !== '' && (
                  <span style={{ color: 'red' }}>{errorMessage}</span>
                )}
                <InputWrapper>
                  <InputLabel>メールアドレス</InputLabel>
                  <Input
                    value={email}
                    onChange={this.onChangeEmail}
                    placeholder="yohei.tsubuku@example.com"
                    type="email"
                    disabled={isSigningIn}
                    required
                    autoComplete="username"
                  />
                </InputWrapper>
                <InputWrapper>
                  <InputLabel>パスワード</InputLabel>
                  <Input
                    value={password}
                    onChange={this.onChangePassword}
                    placeholder="********"
                    type="password"
                    disabled={isSigningIn}
                    required
                    autoComplete="current-password"
                  />
                </InputWrapper>
                <RegisterButton type="submit" disabled={isSigningIn}>
                  ログイン
                </RegisterButton>
                <ToLoginLink onClick={this.toResetPassword}>
                  パスワードを忘れた場合はこちら
                </ToLoginLink>
                <Divider />
                <ToLoginLink href="https://yaritori.jp/trial/">
                  新規登録はこちら
                </ToLoginLink>
              </form>
            </RegisterWrapper>
          </Content>
          <Footer />
        </Right>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  ${media.lessThan('medium')`
    display: block;
  `}
`;

const Left = styled.div`
  width: 50%;
  background-image: url(${bg1});
  background-size: cover;
  background-position: center;
`;

const Right = styled.div`
  width: 50%;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  ${media.lessThan('medium')`
    width: 100%;
    height: 100%;
  `}
`;

const Content = styled.div``;

const RegisterWrapper = styled.div`
  padding-bottom: 50px;
`;

export const InputWrapper = styled.div`
  position: relative;
  margin-bottom: 20px;
`;

export const Input = styled(DefaultInput)`
  height: 40px;
  width: 400px;
  max-width: calc(100vw - 60px);
`;

export const InputLabel = styled.label`
  display: block;
  position: absolute;
  left: -110px;
  top: 10px;

  ${media.lessThan('large')`
    position: static;
    padding-bottom: 1em;
  `}
`;

/**
 * @type {DefaultButton & React.FC<React.HTMLProps<HTMLButtonElement> & { type: "submit"}}
 */
export const RegisterButton = styled(DefaultButton)`
  height: 50px;
  width: 100%;
  max-width: 400px;
  margin-top: 20px;
`;

const ToLoginLink = styled.a`
  display: block;
  margin-top: 20px;
  color: ${color.a.text.normal};
  font-size: 15px;
  text-decoration: underline;
`;

export default compose(withRouter, inject('store'), observer)(SignIn);
