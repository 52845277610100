import { FormEvent } from 'react';
import { CaretLeft, Search } from '../../../components/icons';
import { Icon } from '../../../components/basics';
import { tv } from 'tailwind-variants';

const form = tv({
  base: 'flex h-full w-full items-center rounded-lg py-2.5',
  variants: {
    focused: {
      true: 'border border-sea-500 pr-4 shadow-dropdown',
      false: 'gap-[10px] bg-sumi-50 px-4',
    },
  },
});

type Props = {
  value: string;
  onChange: (value: string) => void;
  onSubmit: () => void;
  onFocus: () => void;
  onBack: () => void;
  focused: boolean;
};

export const MobileSearchInput = ({
  value,
  onChange,
  onSubmit,
  onFocus,
  onBack,
  focused,
}: Props) => {
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    onSubmit();
  };
  return (
    <form
      action="/" // iOS/Safariだとactionがないとキーボードで検索ボタンが表示されない
      onSubmit={handleSubmit}
      className={form({ focused })}
    >
      {focused ? (
        <button
          type="button"
          className="flex h-[24px] cursor-pointer items-center justify-center bg-transparent px-2"
          onClick={() => onBack()}
        >
          <Icon icon={CaretLeft} />
        </button>
      ) : (
        <Icon icon={Search} className="text-sumi-500" />
      )}
      <input
        type="search"
        className="flex-1 cursor-text bg-transparent p-0 text-sm outline-none placeholder:select-none"
        placeholder="検索"
        spellCheck={false}
        onClick={() => onFocus()}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    </form>
  );
};
