import { DeliveryMessagePreviewDialog } from './DeliveryMessagePreviewDialog';
import { useAtomValue } from 'jotai/index';
import { deliveryMessagesAtom } from '../../../../atoms/firestore/deliveryMessages';
import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { DELIVERY_PATHS } from '../../page/DeliveryPage/deliveryPaths';

type Props = {
  messageId: string;
  onSend?: () => Promise<void>;
};

export const DeliveryMessagePreviewDialogWithLogic = ({
  messageId,
  onSend,
}: Props) => {
  const history = useHistory();
  const messages = useAtomValue(deliveryMessagesAtom);
  const message = useMemo(
    () => messages.find((m) => m.id === messageId),
    [messages, messageId]
  );
  if (!message || !message.display) {
    return null;
  }
  return (
    <DeliveryMessagePreviewDialog
      onSend={onSend}
      onOpenChange={(open) => {
        if (!open) {
          history.push(DELIVERY_PATHS.getIndexPath());
        }
      }}
      open={true}
      address={{
        name: message.display.address.name,
        email: message.display.address.email,
      }}
      tags={message.display.contactTags.map((t) => ({
        name: t.name,
        color: t.color,
        team: t.teamName,
      }))}
      subject={message.subject}
      html={message.html}
      text={message.text}
      attachments={message.attachments.map((a) => ({
        name: a.name,
        size: a.size,
      }))}
      recipients={{
        count: message.display.recipients.count,
        total: message.display.recipients.total,
      }}
    />
  );
};
