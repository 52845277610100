import { matchPath, useLocation } from 'react-router-dom';
import { isSP } from '../../../../shared/util';

const paths = [
  '/me/drafts',
  '/me/scheduled',
  '/me/sent',
  '/me/commented',
  '/me/mentioned',
  '/me/starred',
  '/me/assigned/:tab',
  '/teams/:teamId/tags/:tagId/:tab',
  '/teams/:teamId/inboxes/:inboxId/tags/:tagId/:tab',
];

export const useShouldShowFloatingDraftButton = (): boolean => {
  const location = useLocation();
  return (
    isSP() && matchPath(location.pathname, { path: paths, exact: true }) != null
  );
};
