import { Button } from '../../../../../components/basics';
import { InputGroup } from '../../../../../components/forms/InputGroup/InputGroup';

type Props = {
  enabled: boolean;
  onOpenDialog: () => void;
  readonly: boolean;
  largeLabel?: boolean;
};

export const SmtpField = ({
  enabled,
  onOpenDialog,
  readonly,
  largeLabel,
}: Props) => {
  return (
    <InputGroup
      label={
        <span className={largeLabel ? 'text-base font-bold' : undefined}>
          SMTP連携
        </span>
      }
    >
      {enabled ? (
        <Button
          variant="text"
          onClick={() => onOpenDialog()}
          disabled={readonly}
        >
          SMTP連携設定済み
        </Button>
      ) : (
        <Button
          color="sumi"
          variant="text"
          onClick={() => onOpenDialog()}
          disabled={readonly}
        >
          SMTP連携未設定
        </Button>
      )}
    </InputGroup>
  );
};
