import { CustomIconComponentProps } from 'antd/lib/icon';
import React, { forwardRef, useMemo } from 'react';
import { Icon } from './Icon';
import { tv } from 'tailwind-variants';

const iconButtonVariants = tv({
  base: 'flex items-center justify-center rounded-md bg-transparent p-0',
  variants: {
    disabled: {
      true: 'cursor-not-allowed opacity-50',
      false: 'cursor-pointer hover:bg-gray-200',
    },
    size: {
      sm: 'h-4 w-4',
      md: 'h-6 w-6',
      lg: 'h-8 w-8',
    },
    color: {
      sumi: 'text-sumi-600',
    },
  },
  defaultVariants: {
    disabled: false,
    size: 'md',
  },
});

type Optional = 'children';
export type Props = Omit<React.HTMLAttributes<HTMLButtonElement>, Optional> & {
  component: React.ComponentType<
    CustomIconComponentProps | React.SVGProps<SVGSVGElement>
  >;
  size?: keyof (typeof iconButtonVariants)['variants']['size'];
  disabled?: boolean;
  color?: 'sumi';
};

const IconButton = forwardRef<HTMLButtonElement, Props>(
  (
    {
      onClick,
      className,
      component,
      color,
      size = 'md',
      disabled,
      ...otherProps
    },
    ref
  ) => {
    const iconSize = useMemo(() => {
      switch (size) {
        case 'sm':
          return 12;
        case 'md':
          return 16;
        case 'lg':
          return 24;
      }
    }, [size]);
    return (
      <button
        onClick={onClick}
        className={iconButtonVariants({
          color,
          size,
          disabled,
          class: className,
        })}
        disabled={disabled || false}
        {...otherProps}
        ref={ref}
      >
        <Icon icon={component} size={iconSize} />
      </button>
    );
  }
);

IconButton.displayName = 'IconButton';

export default IconButton;
