import React, { useEffect } from 'react';
import { MyEmailTabs } from './MyEmailTabs';
import { useLocation } from 'react-router-dom';
import { MyEmailRoutes } from './MyEmailRoutes';
import { observer } from 'mobx-react';
import _ from 'lodash';
import { useStore } from '../../../../hooks/useStore';

type Props = {
  routes: MyEmailRoutes;
};

export const MyEmail: React.FC<Props> = observer((props: Props) => {
  const location = useLocation();
  const { routes } = props;

  const store = useStore();

  useEffect(() => {
    routes.redirectToInboxIfNeeded(location.pathname);
  }, [location, routes]);

  const teamId = store.privateTeam?.id;
  if (!teamId && store.inboxesLoading) {
    return <></>;
  }

  const inbox = _.first(store.getTeamInboxes(teamId));

  return <MyEmailTabs title={`${inbox?.email}の設定`} routes={routes} />;
});
