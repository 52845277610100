import * as React from 'react';
import type { SVGProps } from 'react';
const SvgChatGradient = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    fill="none"
    viewBox="0 0 28 28"
    {...props}
  >
    <rect width={27} height={27} x={0.5} y={0.5} stroke="url(#a)" rx={13.5} />
    <path
      fill="url(#b)"
      d="m9 19-1.917 1.917c-.264.263-.566.322-.906.177-.34-.146-.51-.407-.51-.782V7.334c0-.458.163-.85.49-1.177.326-.326.718-.49 1.176-.49h13.334c.458 0 .85.164 1.177.49.326.327.49.719.49 1.177v10c0 .459-.164.851-.49 1.177-.327.327-.719.49-1.177.49H9Zm-.708-1.667h12.375v-10H7.333v10.938l.959-.938Zm1.541-1.666h5c.236 0 .434-.08.594-.24.16-.16.24-.358.24-.594a.807.807 0 0 0-.24-.594.806.806 0 0 0-.594-.239h-5a.806.806 0 0 0-.593.24.807.807 0 0 0-.24.593c0 .236.08.434.24.594.16.16.357.24.593.24Zm0-2.5h8.334c.236 0 .434-.08.593-.24.16-.16.24-.358.24-.594a.806.806 0 0 0-.24-.594.806.806 0 0 0-.593-.239H9.833a.806.806 0 0 0-.593.24.807.807 0 0 0-.24.593c0 .236.08.434.24.594.16.16.357.24.593.24Zm0-2.5h8.334c.236 0 .434-.08.593-.24.16-.16.24-.358.24-.594a.806.806 0 0 0-.24-.593.806.806 0 0 0-.593-.24H9.833a.806.806 0 0 0-.593.24.806.806 0 0 0-.24.593c0 .236.08.434.24.594.16.16.357.24.593.24Z"
    />
    <defs>
      <linearGradient
        id="a"
        x1={14}
        x2={14}
        y1={0}
        y2={28}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4E8BE6" />
        <stop offset={0.703} stopColor="#60CBED" />
        <stop offset={1} stopColor="#60DCED" />
      </linearGradient>
      <linearGradient
        id="b"
        x1={14}
        x2={14}
        y1={5.667}
        y2={21.172}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4E8BE6" />
        <stop offset={0.703} stopColor="#60CBED" />
        <stop offset={1} stopColor="#60DCED" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgChatGradient;
