import { useParams } from 'react-router-dom';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import {
  LineAccount,
  LineContact,
  LineMessage as LineMessageEntity,
  LineThread,
  LineThreadStatus,
} from 'lib';
import { useStore } from 'hooks/useStore';
import {
  arrayUnion,
  doc,
  documentId,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from 'firebase/firestore';
import {
  fetchQueryInArray,
  subscribeQueryInArray,
} from '../../../utils/firebase';
import { createEntity } from 'lib/dist/entity/entity';
import { Icon, Spin } from 'antd';
import { observer } from 'mobx-react';
import { LineConversationHeader } from './LineConversationHeader';
import { LineMessage } from './LineMessage';
import { SendMessage } from './SendMessage';
import { isSP } from '../../../shared/util';
import { tv } from 'tailwind-variants';
import SimpleBar from 'simplebar-react';
import { downloadFromUrl } from '../../../util';
import PreviewImageModal from '../../Common/Modal/previewImageModal';

const container = tv({
  base: 'grid max-h-full max-w-full grid-rows-[auto_1fr] px-4 pb-4',
  variants: {
    sp: {
      true: 'h-[100vh]',
      false: 'h-full',
    },
  },
});

export const LineConversation = observer((): JSX.Element => {
  const [account, setAccount] = useState<LineAccount>();
  const [thread, setThread] = useState<LineThread>();
  const [contacts, setContacts] = useState<LineContact[]>([]);
  const [messages, setMessages] = useState<LineMessageEntity[]>([]);
  const [previewImageUrl, setPreviewImageUrl] = useState<string | null>(null);
  const markerRef = useRef<HTMLDivElement>(null);

  const store = useStore();
  const isReadOnly = store.me.isReadOnly;
  const { lineStore } = store;
  const { lineAccountId, lineThreadId } = useParams<{
    lineAccountId: string;
    lineThreadId: string;
  }>();
  const getContact = (message: LineMessageEntity) => {
    return contacts.find((c) => c.id === message.lineContactId);
  };

  useEffect(() => {
    if (store.teams.length) {
      const unsubscribeAccount = onSnapshot(
        doc(lineStore.accountCollection(), lineAccountId),
        async (doc) => setAccount(createEntity(doc))
      );

      const unsubscribeThread = onSnapshot(
        doc(lineStore.threadCollection(), lineThreadId),
        async (doc) => setThread(createEntity(doc))
      );
      const unsubscribeMessages = subscribeQueryInArray(
        query(
          lineStore.messageCollection(),
          where('lineThreadId', '==', lineThreadId),
          orderBy('createdAt')
        ),
        'teamId',
        store.joinedTeamIds,
        async (snap, docs) => {
          setMessages(docs.map((doc) => createEntity(doc)));
        }
      );
      return () => {
        unsubscribeAccount();
        unsubscribeThread();
        unsubscribeMessages();
      };
    }
  }, [lineAccountId, lineThreadId, store.teams]);

  const [scrollBarInitialized, setScrollBarInitialized] = useState(false);
  useEffect(() => {
    if (!account || !thread || !contacts.length) {
      return;
    }
    setScrollBarInitialized(true);
  }, [account, thread, contacts.length]);
  useLayoutEffect(() => {
    if (!scrollBarInitialized) {
      return;
    }
    markerRef.current?.scrollIntoView();
  }, [scrollBarInitialized]);

  useEffect(() => {
    if (thread) {
      fetchQueryInArray(
        lineStore.contactCollection(),
        documentId(),
        thread.lineContacts
      ).then((docs) => setContacts(docs.map((doc) => createEntity(doc))));
      if (!thread.readers.includes(store.me.id)) {
        updateDoc(thread.ref, {
          readers: arrayUnion(store.me.id),
          updatedAt: serverTimestamp(),
        });
      }
    }
  }, [thread]);

  if (!account || !thread || !contacts.length) {
    return (
      <div className="flex h-full flex-1 items-center justify-center">
        <Spin indicator={<Icon type="loading" spin />} />
      </div>
    );
  }

  return (
    <div className={container({ sp: isSP() })}>
      <LineConversationHeader
        account={account}
        thread={thread}
        contacts={contacts}
      />
      <div className="grid h-full max-h-full grid-rows-[1fr_auto] flex-col rounded-xl bg-white px-4">
        <SimpleBar
          className="py-4"
          classNames={{
            wrapper: 'simplebar-wrapper h-0 w-0',
            track: 'simplebar-track my-4',
          }}
        >
          <div className="flex flex-col gap-4">
            {messages.map((message) => (
              <LineMessage
                key={message.id}
                contact={getContact(message)}
                message={message}
                setPreviewImageUrl={setPreviewImageUrl}
              />
            ))}
          </div>
          <div ref={markerRef} />
        </SimpleBar>
        <div className="pb-4">
          <SendMessage
            user={store.me}
            thread={thread}
            setPreviewImageUrl={setPreviewImageUrl}
            disabled={
              thread.status === LineThreadStatus.Processed || isReadOnly
            }
          />
        </div>
      </div>
      <PreviewImageModal
        open={previewImageUrl}
        onClose={() => setPreviewImageUrl(null)}
        url={previewImageUrl}
        onDownload={() => downloadFromUrl(previewImageUrl)}
      />
    </div>
  );
});
