import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { Alert, Button, message, Modal, Tag } from 'antd';
import * as color from '../../../../../color';
import level from '../../../../../font/level';
import media from 'styled-media-query';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import Attachments from '../Sent/attachments';
import moment from 'moment';
import { WysiwygEditor } from '../../../../Common/Editor/WysiwygEditor/WysiwygEditor';
import { MultipleCopy } from '../../../../Common/MultipleCopy';
import Signature from '../../../CreateMessage/signature';
import Quote from '../../../CreateMessage/quote';
import firebase, { db } from '../../../../../firebase';
import { deleteField, serverTimestamp, updateDoc } from 'firebase/firestore';

const { confirm } = Modal;

const Address = (props) => {
  return (
    <MultipleCopy>
      <Tag key={props.address} style={{ whiteSpace: 'break-spaces' }}>
        {props.address}
      </Tag>
    </MultipleCopy>
  );
};

const ValueComponent = ({ value, title }) => {
  if (!value) return <div />;

  if (Array.isArray(value))
    return value.length > 0 ? (
      <Addresses>
        {title}:{' '}
        {value.map((v) => (
          <Address key={v} address={v} />
        ))}
      </Addresses>
    ) : (
      <div />
    );

  return (
    <Addresses>
      {title}: <Address key={value} address={value} />
    </Addresses>
  );
};

class Scheduled extends Component {
  cancelScheduled = async () => {
    const { isFailed } = this.props.draft;

    await updateDoc(this.props.draft.ref, {
      scheduledAt: null,
      failedAt: null,
      ignoreUnsubscribes: deleteField(),
      updatedAt: serverTimestamp(),
    });

    if (this.props.draft.isReply) {
      await this.props.store.companyCollection('events').add({
        user: this.props.store.me.id,
        name: this.props.store.me.name,
        teamId: this.props.draft.teamId,
        messageId: this.props.draft.inReplyToMessageId,
        type: `draft:schedule${
          this.props.draft.isForwarded ? ':forward' : ''
        }:cancel${isFailed ? ':failed' : ''}`,
        text: isFailed
          ? `${this.props.store.me.name}が送信に失敗した予約を下書きに戻しました。`
          : `${this.props.store.me.name}が送信予約をキャンセルしました。`,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      });
    }

    message.success(
      isFailed ? '下書きに戻しました。' : '送信予約をキャンセルしました。'
    );
  };

  changeDrafter = async () => {
    const { companyId, teamId, inboxId, inReplyToMessageId, drafter } =
      this.props.draft;
    const { me } = this.props.store;

    const batch = db.batch();

    // ロックデータの制御
    const locksRef = await db
      .collection(`companies/${companyId}/locks`)
      .where('teamId', '==', teamId)
      .where('messageId', '==', inReplyToMessageId)
      .get();

    locksRef.docs.forEach((doc) => {
      batch.delete(doc.ref);
    });

    const newLockRef = db.collection(`companies/${companyId}/locks`).doc();
    batch.set(newLockRef, {
      teamId: teamId,
      messageId: inReplyToMessageId,
      inboxId: inboxId,
      locker: me.id,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    });

    // 下書きデータの制御
    batch.update(
      this.props.store.companyCollection('drafts').doc(this.props.draft.id),
      {
        drafter: me.id,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      }
    );

    // イベントを作成する
    const eventRef = db.collection(`companies/${companyId}/events`).doc();
    const currentDrafter = this.props.store.getUser(drafter);

    batch.set(eventRef, {
      user: me.id,
      name: me.name,
      teamId: teamId,
      messageId: inReplyToMessageId,
      type: 'draft:create:delegate',
      text: `${me.name}が${
        currentDrafter ? currentDrafter.name : '削除されたユーザ'
      }の代わりに返信を開始しました。`,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    });

    await batch.commit();

    message.success('自分の下書きに変えました。');
  };

  render() {
    const { draft, store } = this.props;
    if (!draft?.isScheduled) return <div />;

    const shouldRenderEditAlert =
      !store.me.isReadOnly && draft.drafter !== this.props.store.me.id;
    return (
      <Wrapper>
        <MessageWrapper name={`scheduled-${draft.id}`}>
          <Head>
            <Flex style={{ justifyContent: 'space-between' }}>
              <div>
                <div style={{ marginBottom: 12 }}>
                  {/* 他のメンバーの下書き */}
                  {shouldRenderEditAlert && (
                    <Alert
                      style={{ display: 'inline-block' }}
                      message={
                        <Button
                          type="link"
                          size="small"
                          disabled={draft.isSending}
                          loading={draft.isSending}
                          onClick={() =>
                            confirm({
                              title: 'メッセージを代わりに編集しますか？',
                              content:
                                '代わりに編集するとメッセージを編集・送信できるようになります。',
                              onOk: this.changeDrafter,
                              okText: '代わりに編集する',
                              cancelText: 'そのままにする',
                            })
                          }
                        >
                          {draft.isSending ? '送信中です…' : '代わりに編集する'}
                        </Button>
                      }
                      type="warning"
                    />
                  )}

                  {/* 自分の下書きで送信に失敗している */}
                  {draft.drafter === this.props.store.me.id &&
                    draft.isFailed && (
                      <Alert
                        message={
                          <>
                            <span>
                              このメールは
                              {moment(draft.scheduledAt.toMillis()).format(
                                'YYYY年M月D日 HH:mm'
                              )}
                              に送信予約がされていましたが、送信に失敗しました。
                            </span>
                            <Button
                              type="link"
                              size="small"
                              onClick={() =>
                                confirm({
                                  title: '下書きに戻しますか？',
                                  onOk: this.cancelScheduled,
                                  okText: '下書きに戻す',
                                  cancelText: 'そのままにする',
                                })
                              }
                            >
                              下書きに戻しますか？
                            </Button>
                          </>
                        }
                        type="error"
                      />
                    )}

                  {/* 自分の下書きで失敗していない */}
                  {draft.drafter === this.props.store.me.id &&
                    !draft.isFailed && (
                      <Alert
                        message={
                          <>
                            <span>
                              このメールは
                              {moment(draft.scheduledAt.toMillis()).format(
                                'YYYY年M月D日 HH:mm'
                              )}
                              に送信されます。
                            </span>
                            <Button
                              type="link"
                              size="small"
                              disabled={draft.isSending}
                              loading={draft.isSending}
                              onClick={() =>
                                confirm({
                                  title: '送信予約をキャンセルしますか？',
                                  content:
                                    '予約をキャンセルするとメッセージは下書きに戻ります。',
                                  onOk: this.cancelScheduled,
                                  okText: '予約をキャンセル',
                                  cancelText: 'そのままにする',
                                })
                              }
                            >
                              {draft.isSending
                                ? '送信中です…'
                                : 'キャンセルしますか？'}
                            </Button>
                          </>
                        }
                        type="warning"
                      />
                    )}
                </div>
                <Flex>
                  <div style={{ flex: 1, marginLeft: 10 }}>
                    <Addresses>
                      <ValueComponent value={draft.to} title="宛先" />
                    </Addresses>
                    <Addresses>
                      <ValueComponent value={draft.cc} title="Cc" />
                    </Addresses>
                    <Addresses>
                      <ValueComponent value={draft.bcc} title="Bcc" />
                    </Addresses>
                  </div>
                </Flex>
              </div>
            </Flex>
          </Head>
          <MessageBody>
            <BodyHtml>
              <WysiwygEditor
                key={draft.id}
                defaultValue={draft.bodyHtml}
                disabled={true}
              />
              <Signature
                key={`signature-${draft.id}`}
                signature={draft.signature}
                readOnly={true}
              />
              {(draft.isReply || draft.inReplyToSentRef) && (
                <Quote
                  useQuote={draft.useQuote}
                  draft={draft}
                  plainTextMode={draft.plainTextMode}
                  readOnly={true}
                />
              )}
            </BodyHtml>
            <Attachments attachments={draft.attachments} />
          </MessageBody>
        </MessageWrapper>
      </Wrapper>
    );
  }
}

export default compose(withRouter, inject('store'), observer)(Scheduled);

const Wrapper = styled.div``;

const MessageWrapper = styled.div`
  padding: 20px 0;
  ${media.greaterThan('small')`
    margin: 16px;
    border-radius: 10px;
  `};
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.16);
  background-color: ${color.common.white};
  ${({ highlight }) =>
    highlight &&
    css`
      box-shadow: 0 0 0 4px rgba(53, 119, 239, 0.2) inset;
    `}
`;

const Head = styled.div`
  padding: 0 30px 20px;
  border-bottom: solid 0.5px #f1f1f3;
`;

const MessageBody = styled.div`
  padding: 20px 30px 0 30px;

  font-size: ${level[1]};
`;

const BodyHtml = styled.div`
  margin: 8px 8px 42px 8px;
`;

const Flex = styled.div`
  display: flex;
`;

const Addresses = styled.div`
  margin-top: 4px;
`;
