import { useAtomValue } from 'jotai';
import { tagCountAtomFamily } from '../../../../atoms/firestore/count/tag';
import { ComponentProps } from 'react';
import { LeftSidebarLink } from './LeftSidebarLink';

type Props = ComponentProps<typeof LeftSidebarLink> & {
  teamId: string;
  tagId: string;
  inboxId?: string;
};

export const LeftSidebarLinkWithCount = ({
  teamId,
  tagId,
  inboxId,
  ...props
}: Props): JSX.Element => {
  const count = useAtomValue(
    tagCountAtomFamily({
      teamId,
      tagId,
      inboxId,
    })
  );
  return <LeftSidebarLink {...props} count={count} />;
};
