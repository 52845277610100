import {
  ComponentPropsWithoutRef,
  Fragment,
  ReactNode,
  useMemo,
  useState,
} from 'react';
import { DomainAuthDnsRecords, DomainAuthInfo } from 'lib';
import { useStore } from '../../../hooks/useStore';
import { observer } from 'mobx-react';
import { Button, Icon, Loading } from '../../../components/basics';
import { ContentCopy, Info } from '../../../components/icons';
import { twMerge } from 'tailwind-merge';
import { useToast } from '../../../hooks/useToast';
import { Dialog } from 'components/basics/dialog/Dialog';
import { DialogHeader } from '../../../components/basics/dialog/DialogHeader';
import { DialogContent } from '../../../components/basics/dialog/DialogContent';
import { DialogFooter } from '../../../components/basics/dialog/DialogFooter';
import SimpleBar from 'simplebar-react';
import { Tooltip } from '../../../components/basics/Tooltip/Tooltip';

type Props = {
  info: DomainAuthInfo;
};

type RecordName = keyof DomainAuthDnsRecords;

const RECORD_NAMES: readonly RecordName[] = [
  'mail_cname',
  'dkim1',
  'dkim2',
  'dmarc',
] as const;

export const DomainAuthEntry = observer(({ info }: Props) => {
  const { domainAuthStore, signInCompany } = useStore();
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [errorRecords, setErrorRecords] = useState<RecordName[]>([]);
  const [message, setMessage] = useState<ReactNode | undefined>(undefined);
  const authDomain = async () => {
    setMessage(undefined);
    setLoading(true);
    domainAuthStore
      .authDomain(info.domain)
      .catch((e) => {
        console.error(e);
        setMessage('想定外のエラーが発生しました');
      })
      .finally(() => setLoading(false));
  };
  const validateDomainAuth = async () => {
    const auth = info.auth;
    if (!auth) {
      return;
    }
    setErrorRecords([]);
    setMessage('');
    setLoading(true);
    await domainAuthStore
      .validateDomainAuth(auth.docId)
      .then((res) => {
        const errorRecords: RecordName[] = [];
        if (res.valid && !res.dns.dmarc.valid) {
          setMessage(
            <>
              DMARCレコードの検証に失敗しました
              <br />
              DNSサーバの設定後、反映まで最大48時間程度かかる場合があります。
            </>
          );
        } else if (res.valid) {
          setMessage('検証に成功しました');
        } else {
          if (!res.dns.mail_cname.valid) {
            errorRecords.push('mail_cname');
          }
          if (!res.dns.dkim1.valid) {
            errorRecords.push('dkim1');
          }
          if (!res.dns.dkim2.valid) {
            errorRecords.push('dkim2');
          }
          if (!res.dns.dmarc.valid) {
            errorRecords.push('dmarc');
          }
          setMessage(
            <>
              {errorRecords.length}件のレコードの検証に失敗しました。
              <br />
              DNSサーバの設定後、反映まで最大48時間程度かかる場合があります。
            </>
          );
        }
        setErrorRecords(errorRecords);
      })
      .catch((e) => {
        console.error(e);
        setMessage('想定外のエラーが発生しました');
      })
      .finally(() => setLoading(false));
  };
  const deleteDomainAuth = async () => {
    const auth = info.auth;
    if (!auth) {
      return;
    }
    setMessage('');
    setDeleting(true);
    await domainAuthStore
      .deleteDomainAuth(auth.docId)
      .catch((e) => {
        console.error(e);
        setMessage('想定外のエラーが発生しました');
      })
      .finally(() => setDeleting(false));
  };
  const deleteDomainAuthFromDialog = async () => {
    setDeleteDialogOpen(false);
    await deleteDomainAuth();
  };

  const label = useMemo(() => {
    if (info.valid) {
      if (info.auth && info.auth.ownerCompanyId === signInCompany) {
        return (
          <div className="flex gap-4">
            {info.valid && (
              <span className="text-sea-500">SPF/DKIM 認証済み</span>
            )}
            {info.auth.dns.dmarc.valid ? (
              <span className="text-sea-500">DMARC 認証済み</span>
            ) : (
              <span className="text-sun-500">DMARC 未認証</span>
            )}
          </div>
        );
      } else {
        return <span className="text-sea-500">認証済み</span>;
      }
    } else {
      return <span>未認証</span>;
    }
  }, [info, signInCompany]);

  const emailsTooltip = useMemo(() => {
    return (
      <Tooltip
        content={
          <>
            <div className="mb-2">
              以下のメールがSMTP連携または
              <br />
              Google/Outlook連携を利用していないためドメイン認証が必要です。
            </div>
            {info.emails.map((email, i) => (
              <Fragment key={i}>
                <span>・{email}</span>
                <br />
              </Fragment>
            ))}
          </>
        }
        side="bottom"
      >
        <button
          type="button"
          className="h-[1em] w-[1em] bg-transparent p-0 text-sumi-800"
        >
          <Icon icon={Info} size="1em" />
        </button>
      </Tooltip>
    );
  }, []);

  const showDeleteDialog = () => {
    setDeleteDialogOpen(true);
  };

  return (
    <>
      <tr className="border-b border-b-sumi-300">
        <td className="hidden break-all py-4 lg:table-cell" valign="top">
          <span className="flex items-center gap-1">
            {info.domain}
            {emailsTooltip}
          </span>
        </td>
        <td className="px-2 py-4">
          <div className="flex flex-col items-start gap-2 lg:gap-4">
            <h3 className="m-0 flex items-center gap-1 font-bold leading-6 lg:hidden">
              {info.domain}
              {emailsTooltip}
            </h3>
            {info.auth ? (
              <>
                {label}
                {info.auth.ownerCompanyId === signInCompany && (
                  <>
                    <SimpleBar className="w-full">
                      <Records
                        records={info.auth.dns}
                        errorRecords={errorRecords}
                      />
                    </SimpleBar>
                    <div className="flex gap-2">
                      <AuthButton
                        variant="outlined"
                        loading={loading}
                        disabled={loading || deleting}
                        onClick={() => validateDomainAuth()}
                      >
                        {info.valid ? '再検証' : 'レコードを検証'}
                      </AuthButton>
                      <AuthButton
                        variant="outlined"
                        color="danger"
                        loading={deleting}
                        disabled={loading || deleting}
                        onClick={() => showDeleteDialog()}
                      >
                        設定を削除
                      </AuthButton>
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                <span>未認証</span>
                <AuthButton
                  variant="outlined"
                  onClick={() => authDomain()}
                  loading={loading}
                  disabled={deleting}
                >
                  レコードを取得
                </AuthButton>
              </>
            )}
            {message && <div>{message}</div>}
          </div>
        </td>
      </tr>
      <Dialog
        open={deleteDialogOpen}
        onOpenChange={setDeleteDialogOpen}
        width="sm"
      >
        <DialogHeader title="設定を削除" />
        <DialogContent>
          <p>
            削除するとドメインが未認証状態になり、メールが届かなくなる可能性があります。
          </p>
          <p className="m-0">それでも削除しますか？</p>
        </DialogContent>
        <DialogFooter>
          <Button variant="outlined" onClick={() => setDeleteDialogOpen(false)}>
            キャンセル
          </Button>
          <Button color="danger" onClick={() => deleteDomainAuthFromDialog()}>
            削除
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
});

type RecordsProps = {
  records: DomainAuthDnsRecords;
  errorRecords: RecordName[];
};

const Records = ({ records, errorRecords }: RecordsProps) => {
  return (
    <table className="-mx-2 w-full border-separate border-spacing-2 text-sm">
      <thead>
        <tr>
          <td className="whitespace-nowrap">タイプ (Type)</td>
          <td className="whitespace-nowrap">ホスト (Host)</td>
          <td className="whitespace-nowrap">値 (Value)</td>
        </tr>
      </thead>
      <tbody>
        {RECORD_NAMES.map((recordName, i) => {
          const record = records[recordName];
          return (
            <tr
              key={i}
              className={
                errorRecords.includes(recordName)
                  ? 'text-sun-500 transition-colors'
                  : ''
              }
            >
              <td className="uppercase">{record.type}</td>
              <td>
                <span className="flex w-full items-center justify-between gap-4">
                  <span>{record.host}</span>
                  <CopyButton value={record.host} />
                </span>
              </td>
              <td>
                <span className="flex w-full items-center justify-between gap-4">
                  <span>{record.data}</span>
                  <CopyButton value={record.data} />
                </span>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

type CopyButtonProps = {
  value: string;
};

const CopyButton = ({ value }: CopyButtonProps) => {
  const { showToast } = useToast();
  const copyToClipBoard = async () => {
    if (value) {
      await navigator.clipboard.writeText(value);
      showToast('success', 'クリップボードにコピーしました');
    }
  };
  return (
    <button
      type="button"
      className="h-[18px] w-[18px] cursor-pointer bg-transparent p-0"
      title="値をコピー"
      onClick={() => copyToClipBoard()}
    >
      <Icon icon={ContentCopy} size={18} />
    </button>
  );
};

type ButtonProps = ComponentPropsWithoutRef<typeof Button> & {
  loading: boolean;
};

const AuthButton = ({
  children,
  className,
  disabled,
  loading,
  ...props
}: ButtonProps) => {
  return (
    <Button
      className={twMerge(
        className,
        'flex h-[32px] items-center gap-2 font-bold'
      )}
      disabled={disabled || loading}
      {...props}
    >
      {loading && <Loading />}
      {children}
    </Button>
  );
};
