import { observer } from 'mobx-react';
import { useStore } from '../../../hooks/useStore';
import { ReactNode, useEffect, useState } from 'react';
import { DomainAuthInfo } from 'lib';
import { SettingPageContainer } from '../common/SettingPageContainer/SettingPageContainer';
import { DomainAuthEntry } from './DomainAuthEntry';
import { Icon } from '../../../components/basics';
import { OpenInNew } from '../../../components/icons';

export const DomainAuth = observer(() => {
  const { me, domainAuthStore } = useStore();
  const [loading, setLoading] = useState(true);
  const [domains, setDomains] = useState<DomainAuthInfo[]>([]);
  useEffect(() => {
    if (!me.isAdmin) {
      return;
    }
    const unsubscribe = domainAuthStore.subscribeDomains((domains) => {
      setLoading(false);
      setDomains(domains);
    });
    return () => unsubscribe();
  }, [me.isAdmin, domainAuthStore]);

  return (
    <SettingPageContainer title="ドメイン認証" full>
      <Section title="SPF/DKIM/DMARC設定">
        <p className="break-words text-[16px] leading-6">
          GmailやYahooへ送付するドメインには、SPF/DKIMの設定が必要です。
          <br />
          レコードを取得後、ドメインのDNSに設定してください。DNS設定が反映された後、レコードを検証で設定が完了したことを確認します。
        </p>
        <p className="break-words text-[16px] leading-6">
          * DMARCは、
          <a
            href="https://support.google.com/a/answer/2466563?hl=ja"
            className="inline-flex items-center gap-1 hover:underline"
            target="_blank"
            rel="noreferrer"
          >
            DMARC レコードの追加 <Icon icon={OpenInNew} size="0.9em" />
          </a>
          に従って自由に編集して設定ができます。
        </p>
        <div>
          <table className="w-[900px] max-w-full table-fixed text-sm">
            <thead className="hidden lg:table-header-group">
              <tr className="border-b border-b-sumi-300">
                <td className="w-[15%] py-4">ドメイン</td>
                <td className="px-2 py-4">認証情報</td>
              </tr>
            </thead>
            <tbody className="w-full">
              {loading ? (
                <RecordsSkeleton />
              ) : (
                domains.map((info, i) => (
                  <DomainAuthEntry key={i} info={info} />
                ))
              )}
            </tbody>
          </table>
          {!loading && domains.length <= 0 && (
            <div className="py-4">表示するドメインがありません</div>
          )}
        </div>
      </Section>
    </SettingPageContainer>
  );
});

type SectionProps = {
  title: string;
  children: ReactNode;
};

const Section = ({ title, children }: SectionProps) => {
  return (
    <div className="mb-8 w-full text-[16px]">
      <h1 className="mb-4 text-lg font-bold">{title}</h1>
      {children}
    </div>
  );
};

const RecordsSkeleton = () => {
  return (
    <>
      {Array.from({ length: 3 }).map((_, i) => (
        <tr key={i}>
          <td className="h-10 p-2" valign="top">
            <div className="h-4 w-full animate-pulse rounded bg-sumi-100"></div>
          </td>
          <td className="h-10 p-2">
            <div className="flex w-2/3 flex-col gap-4">
              <div className="h-4 animate-pulse rounded bg-sumi-100"></div>
              <div className="h-4 animate-pulse rounded bg-sumi-100"></div>
              <div className="h-4 animate-pulse rounded bg-sumi-100"></div>
            </div>
          </td>
        </tr>
      ))}
    </>
  );
};
