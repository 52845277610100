import { Select } from '../../../components/forms';
import { useSearch } from './useSearch';
import { Controller } from 'react-hook-form';
import { MessageStatus } from 'lib';
import { SearchRange } from '../../../store/search';
import { HorizontalScrollArea } from '../../../components/basics/HorizontalScrollArea';
import { useEffect, useState } from 'react';

type Props = {
  search: ReturnType<typeof useSearch>;
};

export const MobileSearchFilter = ({
  search: {
    searchRange,
    searchRangeOptions,
    onChangeSearchRange,
    renderSearchRangeLabel,
    trayOptions,
    tagOptions,
    memberOptions,
    filterForm,
    search,
  },
}: Props) => {
  const [changed, setChanged] = useState(false);
  const { control, watch } = filterForm;
  const renderTrimmedSearchRangeLabel = (value: SearchRange, label: string) => {
    const s = renderSearchRangeLabel(value, label);
    return s.length > 14 ? s.substring(0, 14) + '…' : s;
  };
  useEffect(() => {
    if (filterForm.formState.isDirty) {
      setChanged(true);
    }
  }, [filterForm.formState.isDirty]);
  useEffect(() => {
    if (!changed) {
      return;
    }
    search();
  }, [
    changed,
    searchRange,
    watch('tray'),
    watch('status'),
    watch('tag'),
    watch('assignee'),
  ]);
  return (
    <HorizontalScrollArea className="flex h-mobile-search-filter flex-nowrap items-center gap-2 bg-white px-4">
      <Select
        value={searchRange}
        options={searchRangeOptions}
        onChange={(v) => {
          setChanged(true);
          onChangeSearchRange(v);
        }}
        renderLabel={renderTrimmedSearchRangeLabel}
        variants={{ rounded: 'lg' }}
        className="max-w-[180px]"
      />
      <Controller
        control={control}
        render={({ field: { ref, ...field } }) => (
          <Select
            id="tray"
            {...field}
            options={trayOptions}
            variants={{ rounded: 'lg' }}
          />
        )}
        name="tray"
      />
      <Controller
        control={control}
        render={({ field }) => (
          <Select
            id="status"
            {...field}
            options={[
              MessageStatus.None,
              MessageStatus.Unprocessed,
              MessageStatus.Processed,
            ]}
            placeholder="ステータス"
            variants={{ rounded: 'lg' }}
          />
        )}
        name="status"
      />
      <Controller
        control={control}
        render={({ field }) => (
          <Select
            id="tag"
            {...field}
            options={tagOptions}
            placeholder="タグ"
            variants={{ rounded: 'lg' }}
          />
        )}
        name="tag"
      />
      <Controller
        control={control}
        render={({ field }) => (
          <Select
            id="assignee"
            {...field}
            options={memberOptions}
            placeholder="担当者"
            variants={{ rounded: 'lg' }}
          />
        )}
        name="assignee"
      />
    </HorizontalScrollArea>
  );
};
