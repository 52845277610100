import {
  atomWithSubscribeCollection,
  companyCollection,
} from '../../firestore';
import { groupConverter } from 'lib/dist/entity/group';

export const [groupsLoadingAtom, groupsAtom] = atomWithSubscribeCollection(
  () => companyCollection('groups', groupConverter),
  (snapshot) => snapshot.docs.map((doc) => doc.data())
);
