import { TeamMemberEntry } from './TeamMembers';
import { tv } from 'tailwind-variants';
import { useState } from 'react';
import { Tooltip } from '../../../components/basics/Tooltip/Tooltip';

type Props = {
  title: string;
  placeholder: string;
  members: TeamMemberEntry[];
  type: 'add' | 'remove';
  onAction: (id: string) => Promise<void>;
  readonly: boolean;
};

const text = tv({
  base: 'cursor-pointer bg-transparent p-0 font-bold underline disabled:cursor-not-allowed disabled:text-sumi-500',
  variants: {
    type: {
      add: 'text-sea-500',
      remove: 'text-sun-500',
    },
  },
});

export const TeamMembersSection = ({
  title,
  members,
  placeholder,
  type,
  onAction,
  readonly,
}: Props) => {
  const [processingIds, setProcessingIds] = useState<string[]>([]);
  const handleClick = async (id: string) => {
    try {
      setProcessingIds([...processingIds, id]);
      await onAction(id);
    } catch (e) {
      //
    } finally {
      setProcessingIds(processingIds.filter((i) => i !== id));
    }
  };
  return (
    <div className="mb-8 flex flex-col gap-4">
      <h2 className="m-0 text-base font-bold">{title}</h2>
      <div className="flex w-[350px] flex-col gap-4 text-sm">
        {members.length ? (
          members.map((member, i) => {
            const maxTeamCount = type === 'add' && member.joinedTeamCount >= 10;
            return (
              <div
                key={i}
                className="grid grid-cols-[auto_1fr_auto] items-center gap-2"
              >
                <div className="h-[40px] w-[40px] overflow-hidden rounded-full">
                  {member.avatar}
                </div>
                <div className="flex h-full flex-col justify-around">
                  <div className="font-bold leading-4">
                    {member.name}
                    {member.isMe && (
                      <span className="text-xs font-normal text-sumi-500">
                        （自分）
                      </span>
                    )}
                  </div>
                  <div className="leading-4 text-sumi-500">{member.email}</div>
                </div>
                <div>
                  <Tooltip
                    content={
                      readonly
                        ? '管理者のみ変更可能です'
                        : '1ユーザーが所属できる最大のチーム数は10個までとなります'
                    }
                    visible={readonly || maxTeamCount}
                  >
                    <button
                      onClick={() => handleClick(member.id)}
                      className={text({ type: type })}
                      disabled={
                        readonly ||
                        processingIds.includes(member.id) ||
                        maxTeamCount
                      }
                    >
                      {type === 'add' ? '追加' : '除外'}
                    </button>
                  </Tooltip>
                </div>
              </div>
            );
          })
        ) : (
          <div className="text-sumi-500">{placeholder}</div>
        )}
      </div>
    </div>
  );
};
