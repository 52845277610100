import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import qs from 'qs';

export const OAuthCallback: React.FC = () => {
  const params = useParams();
  const location = useLocation();
  useEffect(() => {
    window.opener?.postMessage({
      ...params,
      type: 'oauth-callback',
      query: qs.parse(location.search, {
        ignoreQueryPrefix: true,
      }),
    });
    window.close();
  }, []);
  return null;
};
