import * as Select from '@radix-ui/react-select';
import { TAG_COLORS, TagColor } from 'lib';
import { Tag } from '../../../../components/basics/Tag/Tag';
import { twMerge } from 'tailwind-merge';
import { Icon } from '../../../../components/basics';
import { MoreHorizontal } from '../../../../components/icons';
import { MultipleInput } from '../../../../components/forms/MultipleInput/MultipleInput';
import React, { useRef, useState } from 'react';

type Props = {
  tags: { name: string; color: TagColor | null | undefined }[];
  value: { name: string; color: TagColor | null | undefined }[];
  onChange: (
    tags: {
      name: string;
      color: TagColor | null | undefined;
    }[]
  ) => void;
  disabled?: boolean;
};

export const ContactTagsInput = ({
  tags,
  value,
  onChange,
  disabled,
}: Props) => {
  const inputRef = useRef<HTMLInputElement>();
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [openedId, setOpenedId] = useState<string | null>(null);
  const newTagColorsRef = useRef<{
    [key: string]: TagColor | null | undefined;
  }>({});

  const getTagColorMap = () => {
    return {
      ...Object.fromEntries(tags.map((t) => [t.name, t.color])),
      ...newTagColorsRef.current,
    };
  };

  return (
    <MultipleInput
      placeholder="タグを入力してください"
      open={showColorPicker}
      value={value.map((t) => t.name)}
      onChange={(changed) => {
        onChange(
          changed.map((name) => {
            return {
              name,
              color: getTagColorMap()[name],
            };
          })
        );
      }}
      suggests={tags.map((t) => t.name)}
      addable
      disabled={disabled}
      inputRef={inputRef}
      renderItem={(state) => {
        const tagColor = getTagColorMap()[state.entry.value];
        return (
          <Select.Root
            open={
              state.closeButton ? openedId === state.entry.value : undefined
            }
            onOpenChange={(open) => {
              if (open) {
                setShowColorPicker(true);
              } else {
                setOpenedId(null);
              }
            }}
            onValueChange={(color) => {
              if (!color) {
                return;
              }
              newTagColorsRef.current = {
                ...newTagColorsRef.current,
                [state.entry.value]:
                  color === 'default' ? null : (color as TagColor),
              };

              const newColorMap = {
                ...Object.fromEntries(tags.map((t) => [t.name, t.color])),
                ...newTagColorsRef.current,
              };

              onChange(
                value.map((v) => ({ ...v, color: newColorMap[v.name] }))
              );
            }}
          >
            {state.closeButton ? (
              <>
                <Select.Trigger asChild>
                  <Tag
                    role="button"
                    color={tagColor}
                    className={twMerge(
                      'h-6 cursor-pointer',
                      state.preDelete ? 'opacity-50' : ''
                    )}
                    closable
                    onClick={() => setOpenedId(state.entry.value)}
                    onClose={state.remove}
                  >
                    {state.entry.text}
                  </Tag>
                </Select.Trigger>
              </>
            ) : (
              <>
                <div className="grid grid-cols-[auto_1fr_auto] items-center p-1">
                  {state.newItem ? (
                    <span className="mx-1 text-xs text-sumi-600">新規追加</span>
                  ) : (
                    <span />
                  )}
                  <Tag color={tagColor} className="h-6">
                    {state.entry.text}
                  </Tag>
                  <Select.Trigger asChild>
                    <div
                      role="button"
                      onClick={(e) => e.stopPropagation()}
                      className="flex h-6 w-6 cursor-pointer items-center justify-center rounded bg-transparent p-0 hover:bg-sumi-300"
                    >
                      <Icon icon={MoreHorizontal} size={20} />
                    </div>
                  </Select.Trigger>
                </div>
              </>
            )}
            <Select.Portal className="z-[50001]">
              <Select.Content
                position="popper"
                className="z-50 flex max-h-[40dvh] flex-col gap-2 overflow-hidden rounded-lg bg-white px-1 shadow-dropdown"
                onCloseAutoFocus={(e) => {
                  e.preventDefault();
                  inputRef.current?.focus();
                  setShowColorPicker(false);
                }}
              >
                <Select.Viewport className="flex flex-col gap-[1px] py-1.5">
                  {[null, ...TAG_COLORS].map((color, i) => (
                    <Select.Item
                      key={i}
                      value={color ?? 'default'}
                      className="flex h-7 w-full cursor-pointer items-center whitespace-nowrap rounded-lg p-1 text-left text-sm leading-none outline-none data-[state=checked]:bg-sumi-200 data-[state=checked]:hover:bg-sumi-300 data-[state=unchecked]:hover:bg-sumi-100 data-[state=checked]:focus-visible:bg-sumi-300 data-[state=unchecked]:focus-visible:bg-sumi-100"
                    >
                      <Tag className="h-6" color={color ? color : null}>
                        {state.entry.value}
                      </Tag>
                    </Select.Item>
                  ))}
                </Select.Viewport>
              </Select.Content>
            </Select.Portal>
          </Select.Root>
        );
      }}
    />
  );
};
