import { estimateServerTimestampsOption } from './common';
import firease from 'firebase/compat/app';

export interface AccountData {
  updatedAt: firease.firestore.Timestamp;
  createdAt: firease.firestore.Timestamp;
  teamId: string;
  externalId: string;
  domains: string[];
  name: string;
}

export class Account {
  private _id: string;
  private _data: AccountData;
  private _ref: firease.firestore.DocumentReference<AccountData>;

  constructor(doc: firease.firestore.DocumentSnapshot<AccountData>) {
    /* eslint-disable  @typescript-eslint/no-non-null-assertion */
    if (!doc) {
      // FIXME: TSでエラーが起きないように元のJSコードの実装を残している.そもそも必要か?
      this._id = undefined!;
      this._data = undefined!;
      this._ref = undefined!;
      return;
    }
    /* eslint-enable  @typescript-eslint/no-non-null-assertion */
    this._id = doc.id;
    const data = doc.data(estimateServerTimestampsOption);
    if (!data) {
      throw new Error(`no data for account#${doc.id}`);
    }
    this._data = data;
    this._ref = doc.ref;
  }

  get id() {
    return this._id;
  }

  get ref() {
    return this._ref;
  }

  get name() {
    return this._data.name;
  }

  get domains() {
    if (!this._data.domains) return [];
    return this._data.domains.slice().sort();
  }

  get externalId() {
    return this._data.externalId;
  }

  get teamId() {
    return this._data.teamId;
  }

  get createdAt() {
    return this._data.createdAt;
  }

  get updatedAt() {
    return this._data.updatedAt;
  }
}
