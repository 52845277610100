import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
  Draft as DraftEntity,
  draftConverter,
} from '../../../firestore/entity/draft';
import { companyDoc, registerUnsubscribe } from '../../../firestore';
import { onSnapshot } from 'firebase/firestore';
import { v4 } from 'uuid';
import { Conversation } from './Conversation/Conversation';
import { SPBackButton } from './Conversation/SPBackButton';
import styled from 'styled-components';
import * as color from '../../../color';
import media from 'styled-media-query';
import { Draft } from './Conversation/Draft';

export const DraftConversation = () => {
  const [draft, setDraft] = useState<DraftEntity>();

  const { draftId } = useParams<{ draftId: string }>();

  useEffect(() => {
    return registerUnsubscribe(
      v4(),
      onSnapshot(companyDoc('drafts', draftId, draftConverter), (snapshot) =>
        setDraft(snapshot.data())
      )
    );
  }, [draftId]);

  if (!draft) return <div />;

  if (draft.isReply) {
    // 返信の場合は会話を表示
    return (
      <Conversation
        threadView={false}
        messageId={draft.inReplyToMessageId!}
        key={draft.id}
      />
    );
  }

  // 新規作成は下書きのみを表示
  return (
    <Wrapper>
      <Header>
        <SPBackButton backLink="/me/drafts" />
      </Header>
      <Draft draft={draft} key={draft.id} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  flex: 1;
  overflow: auto;
  background-color: ${color.sidebar.background.normal};

  ${media.lessThan('small')`
    width: 100vw;
    height: 100vh;
    position: absolute;
    z-index: 2;
  `};
`;

const Header = styled.div`
  padding: 10px;
`;
