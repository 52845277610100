import React, { ComponentType, SVGProps } from 'react';
import { Icon } from '../../components/basics';

type Props = {
  icon: ComponentType<SVGProps<SVGSVGElement>>;
  onClick?: () => void;
};

export const MobileNavButton = ({ icon, onClick }: Props) => {
  return (
    <button
      type="button"
      className="flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-transparent p-0 hover:bg-sumi-100"
      onClick={() => onClick?.()}
    >
      <Icon icon={icon} size={24} />
    </button>
  );
};
