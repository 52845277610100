import React from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import media from 'styled-media-query';
import bg1 from '../../assets/bg/bg1.png';
import * as color from '../../color';
import { DefaultInput } from '../Common/Input';
import { DefaultButton } from '../Common/Button';
import { Footer } from '../Common/Footer';
import firebase from '../../firebase';
import qs from 'qs';
import { Icon, Spin } from 'antd';
import { joinCompanyFunction } from '../../functions';

class Join extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      isRegistering: false,
      errorMessage: '',
      tip: '',
    };
  }

  componentDidMount() {
    const query = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });
    const { email } = query;
    this.setState({ email });
  }

  setErrorState = (message) =>
    this.setState({
      errorMessage: message,
      isRegistering: false,
    });

  submit = async (e) => {
    e.preventDefault();
    this.setState({ isRegistering: true, tip: 'ユーザを作成中…' });
    const query = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });
    const { companyId, invitationId } = query;
    const { email, password } = this.state;

    try {
      // 自動ログインを行わない（ページを再読込するため）
      this.props.store.skipAutoSignIn = true;
      // firebaseに認証ユーザを作成
      await this.createUserWithEmailAndPasswordWithSigninRetry(email, password);
      this.setState({ tip: '会社にジョイン中…' });
      // ユーザを作成
      await joinCompanyFunction({
        companyId,
        invitationId,
      });
      this.setState({ tip: 'ログイン中…' });
      // ユーザプロフィール設定画面へ移動する
      window.location.href = '/settings/me/profile';
    } catch (error) {
      console.error('Join.submit:', error);

      if (error.code?.startsWith('auth/')) {
        switch (error.code) {
          case 'auth/email-already-in-use':
            this.setErrorState('※すでに登録されているメールアドレスです');
            break;
          default:
            this.setErrorState('ユーザーの作成に失敗しました');
        }
      } else {
        this.setErrorState(
          ['invalid-argument', 'permission-denied'].includes(error.code)
            ? error.message
            : '予期せぬエラーが発生しました'
        );
      }
    }
  };

  createUserWithEmailAndPasswordWithSigninRetry = async (email, password) => {
    let errorOnCreateUser = null;
    try {
      await firebase.auth().createUserWithEmailAndPassword(email, password);
      return;
    } catch (error) {
      errorOnCreateUser = error;
    }
    if (
      errorOnCreateUser &&
      errorOnCreateUser.message.includes('deadline exceeded')
    ) {
      // auth/internal-error
      const maxSigninRetry = 3;
      for (let i = 0; i < maxSigninRetry; i++) {
        // wait a sec for retry
        await new Promise((r) => setTimeout(r, 2000));
        try {
          await firebase.auth().signInWithEmailAndPassword(email, password);
          if (firebase.auth().currentUser != null) return;
        } catch (error) {
          // ignore errors while retry, just do next try
        }
      }
    }
    throw errorOnCreateUser;
  };

  onChangeEmail = (e) => this.setState({ email: e.target.value });

  onChangePassword = (e) => this.setState({ password: e.target.value });

  toSignIn = () => this.props.history.push('/signIn');

  render() {
    const { isRegistering, email, password, errorMessage, tip } = this.state;

    return (
      <Wrapper>
        <Left />
        <Right>
          <div />
          <Content>
            <RegisterWrapper>
              <form onSubmit={this.submit}>
                {errorMessage !== '' && (
                  <span style={{ color: 'red' }}>{errorMessage}</span>
                )}
                <InputWrapper>
                  <InputLabel>メールアドレス</InputLabel>
                  <Input
                    value={email}
                    onChange={this.onChangeEmail}
                    placeholder="yohei.tsubuku@example.com"
                    type="email"
                    disabled
                    required
                    autoComplete="username"
                  />
                </InputWrapper>
                <InputWrapper>
                  <InputLabel>新しいパスワード</InputLabel>
                  <Input
                    value={password}
                    onChange={this.onChangePassword}
                    placeholder="********"
                    type="password"
                    disabled={isRegistering}
                    required
                    minLength="8"
                    autoComplete="new-password"
                  />
                </InputWrapper>
                <Spin
                  tip={tip}
                  spinning={isRegistering}
                  indicator={<Icon type="loading" spin />}
                >
                  <RegisterButton type="submit" disabled={isRegistering}>
                    新規登録
                  </RegisterButton>
                </Spin>
                <ToLoginLink onClick={this.toSignIn}>
                  すでにアカウントをお持ちの方はこちら
                </ToLoginLink>
              </form>
            </RegisterWrapper>
          </Content>
          <Footer />
        </Right>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  ${media.lessThan('medium')`
    display: block;
  `}
`;

const Left = styled.div`
  width: 50%;
  background-image: url(${bg1});
  background-size: cover;
`;

const Right = styled.div`
  width: 50%;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  ${media.lessThan('medium')`
    width: 100%;
    height: 100%;
  `}
`;

const Content = styled.div``;

const RegisterWrapper = styled.div`
  padding-bottom: 50px;
`;

const InputWrapper = styled.div`
  position: relative;
  margin-bottom: 20px;
`;

const Input = styled(DefaultInput)`
  height: 40px;
  width: 400px;
  max-width: calc(100vw - 60px);
`;

const InputLabel = styled.label`
  display: block;
  position: absolute;
  left: -110px;
  top: 10px;

  ${media.lessThan('large')`
    position: static;
    padding-bottom: 1em;
  `}
`;

const RegisterButton = styled(DefaultButton)`
  height: 50px;
  width: 100%;
  max-width: 400px;
`;

const ToLoginLink = styled.a`
  display: block;
  margin-top: 20px;
  color: ${color.a.text.normal};
  font-size: 15px;
  text-decoration: underline;
`;

export default compose(withRouter, inject('store'), observer)(Join);
