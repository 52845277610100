import * as React from 'react';
import type { SVGProps } from 'react';
const SvgContentCopy = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M4.5 21.975c-.4 0-.75-.15-1.05-.45-.3-.3-.45-.65-.45-1.05V6.15c0-.212.072-.39.217-.534a.732.732 0 0 1 .537-.216.731.731 0 0 1 .746.75v14.325h11.1c.213 0 .39.072.534.217a.732.732 0 0 1 .216.538.731.731 0 0 1-.75.746H4.5Zm3-3c-.4 0-.75-.15-1.05-.45-.3-.3-.45-.65-.45-1.05v-14c0-.4.15-.75.45-1.05.3-.3.65-.45 1.05-.45h11c.4 0 .75.15 1.05.45.3.3.45.65.45 1.05v14c0 .4-.15.75-.45 1.05-.3.3-.65.45-1.05.45h-11Zm0-1.5h11v-14h-11v14Z"
    />
  </svg>
);
export default SvgContentCopy;
