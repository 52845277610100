import { Store } from './index';
import { makeObservable, observable } from 'mobx';
import {
  AuthDomainResponse,
  DomainAuthData,
  DomainAuthInfo,
  ValidateDomainAuthResponse,
} from 'lib';
import {
  collection,
  CollectionReference,
  deleteDoc,
  doc,
  onSnapshot,
} from 'firebase/firestore';
import {
  authDomainFunction,
  getDomainsFunction,
  validateDomainAuthFunction,
} from '../functions';
import { db } from '../firebase';

export class DomainAuthStore {
  private _loading = true;
  private _domains: { [domain: string]: DomainAuthInfo } = {};

  constructor(private rootStore: Store) {
    makeObservable<DomainAuthStore, '_loading' | '_domains'>(this, {
      _loading: observable,
      _domains: observable,
    });
  }

  /**
   * ドメイン認証の情報の更新をサブスクライブします
   * @param onUpdate
   * @return リスナーをデタッチする関数
   */
  subscribeDomains(onUpdate?: (domains: DomainAuthInfo[]) => void): () => void {
    if (this._loading) {
      this.syncDomains().then((domains) => {
        onUpdate?.(domains);
      });
    } else {
      onUpdate?.(this.domains);
    }

    const unsubscribe1 = onSnapshot(this.domainsRef, (snap) => {
      snap.docChanges().forEach((changed) => {
        if (changed.type === 'removed') {
          this.updateDomains(
            this.domains.map((info) => {
              if (info.auth?.docId !== changed.doc.id) {
                return info;
              } else {
                return {
                  domain: info.domain,
                  emails: info.emails,
                  valid: false,
                  auth: undefined,
                };
              }
            })
          );
        } else {
          const s = changed.doc;
          const data = s.data();

          const domain = data.domain;
          if (!this.domains.some((d) => d.domain === domain)) {
            return;
          }

          const updatedInfo: DomainAuthInfo = {
            domain: data.domain,
            valid: data.valid,
            emails: this._domains[data.domain]?.emails ?? [],
            auth: {
              docId: s.id,
              dns: data.dns,
              ownerCompanyId: data.ownerCompanyId,
            },
          };
          this._domains = { ...this._domains, [data.domain]: updatedInfo };
        }
        onUpdate?.(this.domains);
      });
    });

    let isFirstTime = true;
    const unsubscribe2 = onSnapshot(
      this.rootStore.collection('inboxes'),
      async () => {
        if (isFirstTime) {
          isFirstTime = false;
          return;
        }
        await this.syncDomains().then((domains) => onUpdate?.(domains));
      }
    );

    return () => {
      unsubscribe1();
      unsubscribe2();
    };
  }

  async syncDomains(): Promise<DomainAuthInfo[]> {
    const companyId = this.rootStore.signInCompany;
    const result = await getDomainsFunction({ companyId });
    this.updateDomains(result.data);
    return result.data;
  }

  async authDomain(domain: string): Promise<AuthDomainResponse> {
    const companyId = this.rootStore.signInCompany;
    const res = await authDomainFunction({
      companyId,
      domain,
    }).then((r) => r.data as AuthDomainResponse);

    const updatedInfo: DomainAuthInfo = {
      domain,
      valid: false,
      emails: this._domains[domain]?.emails ?? [],
      auth: {
        docId: res.docId,
        dns: res.dns,
        ownerCompanyId: res.ownerCompanyId,
      },
    };
    this._domains = { ...this._domains, [domain]: updatedInfo };

    return res;
  }

  async validateDomainAuth(docId: string): Promise<ValidateDomainAuthResponse> {
    const companyId = this.rootStore.signInCompany;
    return await validateDomainAuthFunction({ companyId, id: docId }).then(
      (r) => r.data as ValidateDomainAuthResponse
    );
  }

  async deleteDomainAuth(docId: string): Promise<void> {
    const ref = doc(this.domainsRef, docId);
    await deleteDoc(ref);
  }

  private updateDomains(domains: DomainAuthInfo[]) {
    this._domains = Object.fromEntries(domains.map((d) => [d.domain, d]));
    this._loading = false;
  }

  private get domainsRef(): CollectionReference<DomainAuthData> {
    return collection(
      db,
      'system/sendgrid/domains'
    ) as CollectionReference<DomainAuthData>;
  }

  get loading(): boolean {
    return this._loading;
  }

  get domains(): DomainAuthInfo[] {
    return Object.values(this._domains);
  }
}
