import { SettingPageContainer } from '../common/SettingPageContainer/SettingPageContainer';
import { Button } from '../../../components/basics';
import React, { useState } from 'react';
import { useConfirmDialog } from '../../../hooks/confirmDialog';
import { DeliveryAddEmailAddressDialog } from '../../../components/delivery/form/DeliveryAddEmailAddressDialog/DeliveryAddEmailAddressDialog';
import { isSimilarAddress } from 'lib';
import { DeliveryAddressEditDialog } from './DeliveryAddressEditDialog';

type Props = {
  loading: boolean;
  addresses: {
    id: string;
    name: string | undefined;
    email: string;
  }[];
  readonly: boolean;
  onAdd: (name: string | undefined, email: string) => Promise<void>;
  onDelete: (id: string) => Promise<void>;
  onEditName: (id: string, name: string | undefined) => Promise<void>;
};

export const DeliveryAddresses = ({
  loading,
  addresses,
  readonly,
  onAdd,
  onDelete,
  onEditName,
}: Props) => {
  const showDialog = useConfirmDialog();
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [editingId, setEditingId] = useState<string | undefined>();
  const checkDuplicate = async (address: string) => {
    return addresses.some((a) => isSimilarAddress(a.email, address));
  };
  return (
    <SettingPageContainer title="一斉配信メール設定">
      <div className="flex flex-col items-start gap-4 text-sm">
        <Button
          onClick={() => setShowAddDialog(true)}
          disabled={readonly || loading}
        >
          メールアドレスを追加
        </Button>
        <div className="text-base font-bold">設定中のメールアドレス</div>
        <div className="min-w-[400px]">
          {loading ? (
            <div className="flex flex-col gap-8 py-4">
              <div className="h-4 w-[50%] animate-pulse rounded bg-sumi-100" />
              <div className="h-4 w-[80%] animate-pulse rounded bg-sumi-100" />
              <div className="h-4 w-[60%] animate-pulse rounded bg-sumi-100" />
              <div className="h-4 w-[40%] animate-pulse rounded bg-sumi-100" />
            </div>
          ) : (
            <table className="w-full table-auto">
              <tbody>
                {addresses.map((address, i) => (
                  <tr key={i}>
                    <td className="px-2 py-3">
                      {address.name
                        ? `${address.name} <${address.email}>`
                        : address.email}
                    </td>
                    <td className="py-1">
                      <div className="flex items-center gap-2">
                        <Button
                          disabled={readonly}
                          onClick={() => setEditingId(address.id)}
                        >
                          編集
                        </Button>
                        <Button
                          color="danger"
                          onClick={() =>
                            showDialog({
                              title: '配信用メールを削除しますか？',
                              okText: '削除',
                              okType: 'danger',
                              onOk: () => onDelete(address.id),
                            })
                          }
                          disabled={readonly}
                        >
                          削除
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
      {showAddDialog && (
        <DeliveryAddEmailAddressDialog
          open={true}
          onOpenChange={setShowAddDialog}
          onAdd={onAdd}
          checkDuplicate={checkDuplicate}
        />
      )}
      {editingId && (
        <DeliveryAddressEditDialog
          defaultName={addresses.find((a) => a.id === editingId)?.name}
          email={addresses.find((a) => a.id === editingId)?.email ?? ''}
          onOpenChange={() => setEditingId(undefined)}
          onSubmit={(name) => onEditName(editingId, name)}
        />
      )}
    </SettingPageContainer>
  );
};
