import React from 'react';
import { Result, Button } from 'antd';
import styled from 'styled-components';
import * as color from '../../color';

function NotFound(): React.ReactElement {
  return (
    <Box>
      <Result
        status="404"
        title="404"
        subTitle="指定されたページが存在しません。"
        extra={
          <Button type="primary" onClick={() => (window.location.href = '/')}>
            トップへ
          </Button>
        }
      />
    </Box>
  );
}

export default NotFound;

const Box = styled.div`
  min-height: 100vh;
  background-color: ${color.sidebar.background.normal};
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;
