import { Check } from 'components/icons';
import { Icon } from '../../../../components/basics';

export const PlanFeatures: React.FC<{
  items: PlanFeatureItemProps[];
}> = ({ items }) => {
  return (
    <>
      {items.map((item, i) => (
        <PlanFeatureItem {...item} key={`feature${i}`} />
      ))}
    </>
  );
};

// PlanFeatureItem
export type PlanFeatureItemProps = {
  text: string;
  bold?: boolean;
};

const PlanFeatureItem: React.FC<PlanFeatureItemProps> = ({ text, bold }) => {
  return (
    <div
      className={`${
        bold ? 'font-semibold' : ''
      } grid grid-cols-[auto_1fr] items-center gap-1.5 text-sm`}
    >
      <Icon icon={Check} size={20} className="text-[#B4B4B7]" />
      {text}
    </div>
  );
};
