import { ComponentProps, ReactNode, useCallback } from 'react';
import { atom, useAtomValue, useSetAtom } from 'jotai';
import { v4 } from 'uuid';
import { Dialog } from 'components/basics/dialog/Dialog';

export type ConfirmDialogContext = {
  title: string;
  description?: ReactNode;
  okText?: string;
  okType?: 'primary' | 'danger';
  cancelText?: string;
  width?: ComponentProps<typeof Dialog>['width'];
  onOk?: () => void | Promise<unknown>;
  onCancel?: () => void;
};

type Dialog = {
  id: string;
  context: ConfirmDialogContext;
  onOk: () => Promise<unknown>;
  onCancel: () => void;
};

export type ConfirmFunction = (context: ConfirmDialogContext) => void;

const dialogAtom = atom<Dialog | undefined>(undefined);
const closeCurrentDialogAtom = atom<null, [string | undefined, boolean], void>(
  null,
  (get, set, id, callCancel) => {
    const current = get(dialogAtom);
    if (current && (!id || current.id === id)) {
      set(dialogAtom, undefined);
      if (callCancel) {
        current.onCancel();
      }
    }
  }
);

export const useConfirmDialog = (): ConfirmFunction => {
  const setDialog = useSetAtom(dialogAtom);
  const closeCurrentDialog = useSetAtom(closeCurrentDialogAtom);
  return useCallback(
    (context: ConfirmDialogContext) => {
      closeCurrentDialog(undefined, true);

      const id = v4();
      const dialog: Dialog = {
        id,
        context,
        onOk: async () => {
          await context.onOk?.();
          closeCurrentDialog(id, false);
        },
        onCancel: () => {
          context.onCancel?.();
          closeCurrentDialog(id, false);
        },
      };
      setDialog(dialog);
    },
    [closeCurrentDialog, setDialog]
  );
};

export const useCurrentConfirmDialog = (): Dialog | undefined => {
  return useAtomValue(dialogAtom);
};
