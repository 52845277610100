type Props = {
  color: string;
};

export const ToolbarBackgroundColorIcon = ({ color }: Props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="h-auto max-w-full"
    >
      <path
        d="M7.97999 0.844998L15.8 8.665C16.15 9.015 16.33 9.445 16.33 9.955C16.33 10.465 16.15 10.895 15.8 11.245L11.47 15.595C11.13 15.935 10.7 16.115 10.19 16.115C9.67999 16.115 9.24999 15.945 8.90999 15.595L4.57999 11.245C4.22999 10.895 4.04999 10.465 4.04999 9.955C4.04999 9.445 4.22999 9.015 4.57999 8.665L9.12999 4.115L6.89999 1.885C6.74999 1.735 6.67999 1.565 6.67999 1.365C6.67999 1.165 6.75999 0.994998 6.90999 0.834998C7.06999 0.694998 7.23999 0.614998 7.44999 0.614998C7.64999 0.614998 7.82999 0.684998 7.98999 0.844998H7.97999ZM10.2 5.185L5.61999 9.745C5.61999 9.745 5.56999 9.815 5.55999 9.845C5.55999 9.885 5.54999 9.915 5.54999 9.955H14.85C14.85 9.955 14.85 9.875 14.84 9.845C14.84 9.805 14.81 9.775 14.78 9.745L10.22 5.185H10.2ZM18.79 16.725C18.3 16.725 17.89 16.555 17.55 16.215C17.21 15.875 17.04 15.465 17.04 14.975C17.04 14.655 17.13 14.315 17.31 13.975C17.49 13.635 17.69 13.305 17.9 12.995C18.04 12.805 18.18 12.615 18.33 12.425C18.48 12.235 18.63 12.045 18.79 11.855C18.95 12.045 19.1 12.235 19.25 12.425C19.4 12.615 19.54 12.805 19.68 12.995C19.89 13.305 20.09 13.625 20.27 13.975C20.45 14.325 20.54 14.655 20.54 14.975C20.54 15.465 20.37 15.875 20.03 16.215C19.69 16.555 19.28 16.725 18.79 16.725Z"
        fill="currentColor"
      />
      <path
        d="M3.5 22.725C3.09 22.725 2.74 22.575 2.44 22.285C2.14 21.995 2 21.635 2 21.225C2 20.815 2.15 20.465 2.44 20.165C2.73 19.865 3.09 19.725 3.5 19.725H20.5C20.91 19.725 21.26 19.875 21.56 20.165C21.86 20.455 22 20.815 22 21.225C22 21.635 21.85 21.985 21.56 22.285C21.26 22.585 20.91 22.725 20.5 22.725H3.5Z"
        fill={color}
      />
    </svg>
  );
};
