import React, { FC, useMemo } from 'react';
import { TagColor } from 'lib';
import { MultipleInput } from '../../../forms/MultipleInput/MultipleInput';
import { Tag } from '../../../basics/Tag/Tag';
import { twMerge } from 'tailwind-merge';

type Props = {
  groups: {
    team: string;
    tags: {
      id: string;
      name: string;
      color: TagColor | null | undefined;
    }[];
  }[];
  value: string[];
  onChange: (value: string[]) => void;
  disabled?: boolean;
};

export const DeliveryTagsInput: FC<Props> = ({
  groups,
  value,
  onChange,
  disabled,
}) => {
  const colorMap = useMemo(
    () =>
      Object.fromEntries(
        groups.flatMap((g) => g.tags).map((t) => [t.id, t.color])
      ),
    [groups]
  );
  return (
    <MultipleInput
      placeholder="選択してください"
      value={value ?? []}
      onChange={onChange}
      suggests={groups.map((t) => ({
        group: t.team,
        entries: t.tags.map((g) => ({
          value: g.id,
          text: g.name,
        })),
      }))}
      disabled={disabled}
      renderItem={(state) => {
        const tagColor = colorMap[state.entry.value];
        return state.closeButton ? (
          <Tag
            role="button"
            color={tagColor}
            className={twMerge('h-6', state.preDelete ? 'opacity-50' : '')}
            closable={state.closeButton}
            onClose={state.remove}
          >
            {state.entry.text}
          </Tag>
        ) : (
          <div className="p-1">
            <Tag
              role="button"
              color={tagColor}
              className="h-6"
              closable={state.closeButton}
              onClose={state.remove}
            >
              {state.entry.text}
            </Tag>
          </div>
        );
      }}
      closeWhenSelect
    />
  );
};
