import { getAuth } from 'firebase/auth';
import { Button, List, Typography } from 'antd';
import { FirebaseError } from 'firebase/app';
import useMFAModal from '../../Auth/useMFAModal';

/**
 * @typedef {object} MFAModalProps
 * @prop {string} password
 * @prop {import('firebase/auth').MultiFactorError=} error
 */

/**
 * is auth/requires-recent-login error
 * @param {unknown} err
 * @returns {err is FirebaseError}
 */
function isRequiresRecentLoginError(err) {
  if (err instanceof FirebaseError) {
    return err.code === 'auth/requires-recent-login';
  }
  return false;
}

export const ListEnrolledFactors = (props) => {
  /**
   * @type {import('firebase/auth').MultiFactorUser}
   */
  const multiFactorUser = props.multiFactorUser;
  const { reauthenticate } = useMFAModal();

  if (
    props.disabled ||
    !multiFactorUser ||
    !multiFactorUser.enrolledFactors ||
    multiFactorUser.enrolledFactors.length === 0
  ) {
    return null;
  }

  return (
    <List
      style={{ width: '400px' }}
      bordered
      dataSource={multiFactorUser.enrolledFactors}
      renderItem={(item) => {
        return (
          <List.Item key={item.uid} style={{ display: 'flex' }}>
            <Typography.Text>[{item.factorId}]</Typography.Text>
            <Typography.Text style={{ marginLeft: '16px' }}>
              {item.displayName}
            </Typography.Text>
            <Button
              style={{ marginLeft: 'auto' }}
              onClick={() => {
                multiFactorUser
                  .unenroll(item)
                  .then(() => {
                    window.location.reload();
                  })
                  .catch((err) => {
                    if (!isRequiresRecentLoginError(err)) {
                      throw err;
                    }

                    return reauthenticate(getAuth().currentUser)
                      .then(() => {
                        return multiFactorUser.unenroll(item);
                      })
                      .then(() => {
                        window.location.reload();
                      });
                  });
              }}
            >
              削除
            </Button>
          </List.Item>
        );
      }}
    />
  );
};
