import { Button, Form, Modal, notification, Select } from 'antd';
import { SendOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import { useStore } from '../../../../hooks/useStore';
import styled from 'styled-components';
import { DefaultInput } from '../../../Common/Input';
import * as color from '../../../../color';
import { sendInvitationFunction } from '../../../../functions';
import { eventNames, logEvent } from '../../../../analytics';
import { H1 } from '../../../Common/H1';
import { RoleSelect } from '../RoleSelect';

const { Option } = Select;

type Props = {
  visible: boolean;
  close: () => void;
};

type Role = 'member' | 'admin' | 'readonly';

export const InviteModal = ({ visible, close }: Props): JSX.Element => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [role, setRole] = useState<Role>('member');
  const [teamId, setTeamId] = useState('');
  const [saving, setSaving] = useState(false);

  const store = useStore();
  const checkEmailValidity = (email: string) => {
    const user = store.getUserByEmail(email);
    if (user) return setError('すでに登録されているユーザです');

    const invitedUser = store.getInvitedUserByEmail(email);
    if (invitedUser) return setError('すでに招待されているユーザです');
    setError('');
  };
  const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    const email = e.target.value;
    setEmail(email);
    checkEmailValidity(email);
  };

  const sendInvitation = async (email: string, role: Role, teamId: string) => {
    const { signInCompany } = store;
    return await sendInvitationFunction({
      email,
      role,
      teamId,
      companyId: signInCompany,
    });
  };

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      setSaving(true);
      await sendInvitation(email, role, teamId);
      logEvent(eventNames.invite_user, { role });
      notification.open({
        message: '招待メールを送信しました',
        icon: <SendOutlined style={{ color: color.common.success }} />,
      });
      onClose();
    } catch (error) {
      console.error('CompanyMembers.invite:', error);
      // FIXME: any
      const { code, message } = error as any;
      if (code === 'already-exists' || code === 'permission-denied') {
        setError(message);
      } else {
        setError('予期せぬエラーが発生しました');
      }
    } finally {
      setSaving(false);
    }
  };

  const onClose = () => {
    setEmail('');
    setError('');
    setRole('member');
    setTeamId('');
    close();
  };

  return (
    <Modal visible={visible} onCancel={onClose} footer={null} width={600}>
      <Title>{store.company.name}にメンバーを招待する</Title>
      <FormWrapper>
        <Form layout="vertical" onSubmit={onSubmit}>
          <Form.Item label="送信先">
            <DefaultInput
              value={email}
              onChange={onChangeEmail}
              placeholder="メールアドレス"
              type="email"
            />
            {error && <ErrorMessage>{error}</ErrorMessage>}
          </Form.Item>
          <Form.Item label="招待の種類">
            <RoleSelect
              onSelect={setRole}
              value={role}
              style={{ width: 488 }}
            />
          </Form.Item>
          <Form.Item label="チーム">
            <Select
              onSelect={setTeamId}
              value={teamId}
              style={{ width: 488 }}
              filterOption={true}
            >
              {/* for some reasons onClear is not working despite listed in antd documentation */}
              <Option value="">(選択なし)</Option>
              {store.teams.map((team) => {
                return (
                  <Option value={team.id} key={team.id}>
                    {team.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <ButtonWrapper>
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              disabled={!Boolean(email) || Boolean(error) || saving}
            >
              送信
            </Button>
          </ButtonWrapper>
        </Form>
      </FormWrapper>
    </Modal>
  );
};

const Title = styled(H1)`
  text-align: center;
  margin-top: 30px;
  padding-bottom: 30px;
`;

const ErrorMessage = styled.div`
  color: red;
`;

const FormWrapper = styled.div`
  margin-top: 16px;

  & .ant-form-item {
      padding: 0 32px;
      margin-bottom: 12px;
      label {
        font-size: 16px;
        font-weight: 600;
        color: ${color.common.text2};
        margin-bottom: 4px;
      }
    }
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: center;
  button {
    height: 40px;
    width: 100px;
    max-width: 100px;
  }
`;
