import { atom } from 'jotai';
import { DraftAttachment } from 'lib';
import { atomEffect } from 'jotai-effect';
import firebase from '../../../../firebase';

const deleteScheduledAttachmentsAtom = atom<DraftAttachment[]>([]);
const deletingAttachmentIdsAtom = atom<string[]>([]);

export const scheduleAttachmentDeletionAtom = atom(
  null,
  (get, set, attachment: DraftAttachment) => {
    const attachments = get(deleteScheduledAttachmentsAtom);
    if (attachments.some((a) => a.uid === attachment.uid)) {
      return;
    }
    set(deleteScheduledAttachmentsAtom, [...attachments, attachment]);
  }
);

export const attachmentDeletionSchedulerEffect = atomEffect((get, set) => {
  const scheduledAttachments = get(deleteScheduledAttachmentsAtom);
  const deletingIds = get(deletingAttachmentIdsAtom);

  if (!scheduledAttachments.length) {
    return;
  }

  const attachments = scheduledAttachments.filter(
    (a) => !deletingIds.includes(a.uid)
  );

  set(deletingAttachmentIdsAtom, [
    ...deletingIds,
    ...attachments.map((a) => a.uid),
  ]);

  attachments.forEach((attachment) => {
    const fileRef = firebase.storage().ref(attachment.storagePath);
    fileRef.delete().then(() => {
      set(
        deleteScheduledAttachmentsAtom,
        get(deleteScheduledAttachmentsAtom).filter(
          (a) => a.uid !== attachment.uid
        )
      );
      set(
        deletingAttachmentIdsAtom,
        get(deletingAttachmentIdsAtom).filter((id) => id !== attachment.uid)
      );
    });
  });
});
