import { useStore } from 'hooks/useStore';
import { BaseMessagePane } from './BaseMessagePane';
import Starred from '../MessageList/Me/Starred';
import { MessageListEmpty } from 'components/MessageList';
import { observer } from 'mobx-react';

export const PATH = '/me/starred';

// 自分 - お気に入り
export const MessagePaneMeStarred: React.FC = observer(() => {
  const store = useStore();

  const isEmpty =
    !store.starredMessagesLoading && store.sortedStarredMessages.length === 0;

  return (
    <BaseMessagePane
      listPagePath={PATH}
      empty={isEmpty}
      emptyComponent={
        <MessageListEmpty description="お気に入りはまだありません" />
      }
      listComponent={<Starred />}
    />
  );
});
