import * as React from 'react';
import type { SVGProps } from 'react';
const SvgFormatStrikethrough = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M3.25 13.74a.725.725 0 0 1-.534-.215.726.726 0 0 1-.216-.535c0-.213.072-.39.216-.534a.726.726 0 0 1 .534-.215h17.5c.212 0 .39.071.534.215a.726.726 0 0 1 .216.535c0 .212-.072.39-.216.534a.726.726 0 0 1-.534.215H3.25Zm7.692-3.48V6.625H6.567c-.294 0-.543-.103-.749-.309a1.022 1.022 0 0 1-.308-.75c0-.294.103-.545.308-.753.206-.209.455-.313.75-.313h10.874c.294 0 .544.103.75.309.205.206.308.456.308.75s-.103.545-.308.753a1.014 1.014 0 0 1-.75.313h-4.375v3.635h-2.125Zm0 5.461h2.125v2.721c0 .294-.103.544-.309.75a1.021 1.021 0 0 1-.75.308 1.03 1.03 0 0 1-.753-.311 1.027 1.027 0 0 1-.313-.756V15.72Z"
    />
  </svg>
);
export default SvgFormatStrikethrough;
