import { estimateServerTimestampsOption } from './common';
import firebase from 'firebase/compat/app';
import { UserData } from './user';

export interface SlackIntegrationData {
  appId: string;
  authedUser: { id: string };
  botUserId: string;
  enterprise: boolean | null;
  integratedUserId: string;
  integratedUserRef: firebase.firestore.DocumentReference<UserData>;
  scope: string;
  team: { id: string; name: string };
  createdAt: firebase.firestore.Timestamp;
  updatedAt: firebase.firestore.Timestamp;
}

export class SlackIntegration {
  private _id: string;
  private _data: SlackIntegrationData;
  private _ref: firebase.firestore.DocumentReference<SlackIntegrationData>;

  constructor(doc: firebase.firestore.DocumentSnapshot<SlackIntegrationData>) {
    this._id = doc.id;
    const data = doc.data(estimateServerTimestampsOption);
    if (!data) {
      throw new Error(`no data for slackIntegration#${doc.id}`);
    }
    this._data = data;
    this._ref = doc.ref;
  }

  get id() {
    return this._id;
  }

  get ref() {
    return this._ref;
  }

  get appId() {
    return this._data.appId;
  }

  get authedUser() {
    return this._data.authedUser;
  }

  get botUserId() {
    return this._data.botUserId;
  }

  get enterprise() {
    return this._data.enterprise;
  }

  get integratedUserId() {
    return this._data.integratedUserId;
  }

  get integratedUserRef() {
    return this._data.integratedUserRef;
  }

  get scope() {
    return this._data.scope;
  }

  get team() {
    return this._data.team;
  }

  get createdAt() {
    return this._data.createdAt;
  }

  get updatedAt() {
    return this._data.updatedAt;
  }
}
