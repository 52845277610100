import { ForwardingAddressField } from '../../inbox/fields/ForwardingAddressField';
import { SmtpField } from '../../inbox/fields/SmtpField';
import { SenderNameField } from '../../inbox/fields/SenderNameField';
import { YaritoriNameField } from '../../inbox/fields/YaritoriNameField';
import { ImapField } from '../../inbox/fields/ImapField';
import { Button } from '../../../../../components/basics';
import { useConfirmDialog } from '../../../../../hooks/confirmDialog';
import { Controller, useForm } from 'react-hook-form';
import { InputGroup } from '../../../../../components/forms/InputGroup/InputGroup';
import { Switch } from '../../../../../components/basics/Switch/Switch';

type MyEmailSettings = {
  name: string;
  yaritoriName: string;
  isOneClickUnsubscribeEnabled: boolean;
};

export type MyEmailSettingsUpdate = MyEmailSettings;

type Props = {
  forwardedEmail: string;
  importImapSupported: boolean;
  smtpEnabled: boolean;
  onOpenSmtpDialog: () => void;
  onOpenImapDialog: () => void;
  settings: MyEmailSettings;
  onUpdateNames: (update: MyEmailSettingsUpdate) => Promise<void>;
  onDelete: () => Promise<void>;
};

export const MyEmailSettings = ({
  forwardedEmail,
  importImapSupported,
  smtpEnabled,
  onOpenSmtpDialog,
  onOpenImapDialog,
  settings,
  onUpdateNames,
  onDelete,
}: Props) => {
  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm<MyEmailSettings>({
    defaultValues: settings,
  });
  const openDialog = useConfirmDialog();
  const handleUpdateNames = async (update: MyEmailSettingsUpdate) => {
    try {
      await onUpdateNames({
        name: update.name.trim(),
        yaritoriName: update.yaritoriName.trim(),
        isOneClickUnsubscribeEnabled: update.isOneClickUnsubscribeEnabled,
      });
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <div className="flex max-w-[500px] flex-col gap-6 text-sm text-black">
      <ForwardingAddressField
        email={forwardedEmail}
        largeLabel
        fontWeight="bold"
      />
      <SmtpField
        enabled={smtpEnabled}
        onOpenDialog={onOpenSmtpDialog}
        readonly={false}
        largeLabel
      />
      {importImapSupported && (
        <ImapField onOpen={onOpenImapDialog} readonly={false} largeLabel />
      )}
      <form
        action=""
        onSubmit={handleSubmit(handleUpdateNames)}
        className="flex flex-col gap-6"
      >
        <Controller
          control={control}
          name="name"
          render={({ field: { value, onChange } }) => (
            <SenderNameField
              value={value}
              onChange={onChange}
              readonly={isSubmitting}
              largeLabel
            />
          )}
        />
        <Controller
          control={control}
          name="yaritoriName"
          render={({ field: { value, onChange } }) => (
            <YaritoriNameField
              value={value}
              onChange={onChange}
              readonly={isSubmitting}
              largeLabel
            />
          )}
        />
        <InputGroup
          label={
            <span className="text-base font-bold">ワンクリック購読解除</span>
          }
        >
          <Controller
            name="isOneClickUnsubscribeEnabled"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Switch
                value={value}
                onChange={onChange}
                label="メールにワンクリック購読解除を追加する"
              />
            )}
          />
        </InputGroup>
        <div className="flex gap-4">
          <Button
            color="danger"
            onClick={() =>
              openDialog({
                title: `メールアドレスを削除しますか？`,
                description:
                  'メールサーバー側の転送設定を必ず解除のうえ削除をお願いいたします',
                okType: 'danger',
                okText: '削除',
                onOk: onDelete,
              })
            }
            disabled={isSubmitting}
          >
            削除
          </Button>
          <Button type="submit" loading={isSubmitting}>
            更新
          </Button>
        </div>
      </form>
    </div>
  );
};
