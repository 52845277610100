import React, { ComponentProps } from 'react';
import { Select } from 'antd';

type Role = 'admin' | 'member' | 'readonly';

type Props = Omit<ComponentProps<typeof Select>, 'onSelect' | 'children'> & {
  onSelect?: (role: Role) => void;
};

export const RoleSelect = ({ onSelect, ...props }: Props) => {
  return (
    <Select onSelect={(v) => onSelect?.(v as Role)} {...props}>
      <Select.Option value="admin">管理者</Select.Option>
      <Select.Option value="member">メンバー</Select.Option>
      <Select.Option value="readonly">閲覧のみ</Select.Option>
    </Select>
  );
};
