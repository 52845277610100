import { BubbleMenu, Editor } from '@tiptap/react';
import { tv } from 'tailwind-variants';
import { useEffect, useRef, useState } from 'react';
import { atom, useAtom, useSetAtom } from 'jotai';
import { atomWithReducer } from 'jotai/utils';
import { uniq } from 'lodash';
import * as Portal from '@radix-ui/react-portal';
import { v4 } from 'uuid';

type Props = {
  editor: Editor | null;
  bubbleMenu?: boolean;
  defaultUrl?: string;
  hidden?: boolean;
  getContainer?: () => HTMLElement;
};

type Action =
  | {
      type: 'add';
      editor: Editor;
    }
  | {
      type: 'remove';
      editor: Editor;
    };

const menu = tv({
  base: 'flex h-8 w-fit items-center rounded border border-sumi-300 bg-white text-xs text-sumi-500 shadow',
});

const editButton = tv({
  base: 'cursor-pointer select-none bg-transparent px-0 text-sea-500',
});

export const newLinkTextAtom = atom<
  { editor: Editor; text: string } | undefined
>(undefined);
export const linkEditingEditorsAtom = atomWithReducer<Editor[], Action>(
  [],
  (prev, action) => {
    switch (action?.type) {
      case 'add':
        return uniq([...prev, action.editor]);
      case 'remove':
        return prev.filter((e) => e !== action.editor);
      default:
        return prev;
    }
  }
);

export const LinkBubbleMenu = ({
  editor,
  defaultUrl = '',
  hidden,
  getContainer,
}: Props) => {
  const [editing, setEditing] = useState(false);
  const dispatchLinkEditingEditors = useSetAtom(linkEditingEditorsAtom);
  const href = editor?.getAttributes('link').href ?? defaultUrl;
  const inputRef = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState(href);
  const [newLinkText, setNewLinkText] = useAtom(newLinkTextAtom);
  const fromIdRef = useRef(`_${v4()}`);
  useEffect(() => {
    setEditing(false);
  }, [href]);
  useEffect(() => {
    if (!editor) {
      return;
    }
    const type = editing || newLinkText?.editor === editor ? 'add' : 'remove';
    dispatchLinkEditingEditors({ type, editor });
  }, [editor, editing, newLinkText, dispatchLinkEditingEditors]);
  useEffect(() => {
    if (!editor) {
      return;
    }

    const onUpdate = () => {
      if (newLinkText) {
        setNewLinkText(undefined);
      }
    };
    editor.on('selectionUpdate', onUpdate);
    return () => {
      editor.off('selectionUpdate', onUpdate);
    };
  }, [editor, newLinkText]);
  useEffect(() => {
    if (newLinkText?.editor === editor) {
      setInputValue(newLinkText.text);
      setTimeout(() => inputRef.current?.select(), 50);
    }
  }, [newLinkText]);
  const editingContent = (
    <>
      {/* form内でエディターを使用している事があるのでPortalで外に描画する */}
      <Portal.Root hidden>
        <form
          action=""
          id={fromIdRef.current}
          onSubmit={(e) => {
            e.stopPropagation();
            e.preventDefault();
            if (!editor) {
              return;
            }

            if (newLinkText) {
              editor.chain().focus().setLink({ href: inputValue }).run();
              setEditing(false);
            } else {
              editor
                .chain()
                .extendMarkRange('link')
                .setLink({ href: inputValue })
                .run();
              setEditing(false);
            }
          }}
        />
      </Portal.Root>
      <div className="grid grid-cols-[auto_1fr_auto] items-center gap-2 px-2">
        <div className="select-none">URL:</div>
        <input
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          className="w-[180px] rounded border border-sumi-300 text-sumi-900 focus-visible:border-sea-500 focus-visible:outline-none"
          autoFocus
          form={fromIdRef.current}
          ref={inputRef}
        />
        <button type="submit" form={fromIdRef.current} className={editButton()}>
          保存
        </button>
      </div>
    </>
  );
  const content = (
    <div className="grid grid-cols-[auto_1fr_auto_auto] items-center gap-2 px-2">
      <div className="select-none">URL:</div>
      <a
        href={href}
        rel="noopener noreferrer"
        target="_blank"
        className="max-w-[200px] truncate whitespace-nowrap text-sea-500"
      >
        {href}
      </a>
      <button
        type="button"
        className={editButton()}
        onClick={() => {
          setInputValue(href);
          setEditing(true);
          setTimeout(() => inputRef.current?.select(), 50);
        }}
      >
        編集
      </button>
      <button
        type="button"
        className={editButton()}
        onClick={() => editor?.commands.unsetLink()}
      >
        削除
      </button>
    </div>
  );
  return editor ? (
    <div>
      <BubbleMenu
        pluginKey="linkMenu"
        editor={editor}
        className={menu()}
        shouldShow={({ editor }) => editor.isActive('link') && !hidden}
        updateDelay={0}
        tippyOptions={{
          onHide: () => setEditing(false),
          appendTo: getContainer,
        }}
      >
        {editing ? editingContent : content}
      </BubbleMenu>
      <BubbleMenu
        pluginKey="newLinkMenu"
        editor={editor}
        shouldShow={({ editor }) => !editor.isActive('link') && !hidden}
        updateDelay={0}
        tippyOptions={{
          appendTo: getContainer,
        }}
      >
        {newLinkText?.editor === editor && (
          <div className={menu()}>{editingContent}</div>
        )}
      </BubbleMenu>
    </div>
  ) : (
    <div className={menu()}>{editing ? editingContent : content}</div>
  );
};
