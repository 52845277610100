import { useSignInCompanyStripeProduct } from 'atoms/firestore/signInCompanyStripeProduct';
import { Loading } from 'components/basics';
import {
  MIN_UI_VERSION,
  Payment as MinV6Payment,
} from './MinV6Payment/Payment';
import BelowV5Payment from './BelowV5Payment';

export const Payments = () => {
  const [stripeProductData, loading] = useSignInCompanyStripeProduct();

  if (!stripeProductData || loading) {
    return (
      <div className="p-8 pr-4">
        <Loading />
      </div>
    );
  }

  if (stripeProductData.version >= MIN_UI_VERSION) {
    return <MinV6Payment />;
  }

  return <BelowV5Payment />;
};
