import { registerUnsubscribe } from '../../../firestore';
import { onSnapshot, query, where } from 'firebase/firestore';
import { MessageStatus } from 'lib';
import { store } from '../../../providers/StoreProvider';
import { meAtom } from '../../auth';
import { sum } from 'lodash';
import { atom } from 'jotai';
import { counterCollection } from './utils';

const writableAssignedCountAtom = atom(0);

writableAssignedCountAtom.onMount = (set) =>
  registerUnsubscribe(
    writableAssignedCountAtom,
    onSnapshot(
      query(
        counterCollection(MessageStatus.Unprocessed, 'assigned'),
        where('assignee', '==', store.get(meAtom).id)
      ),
      (snapshot) => {
        set(sum(snapshot.docs.map((doc) => doc.data().count)));
      }
    )
  );

export const assignedCountAtom = atom((get) => get(writableAssignedCountAtom));
