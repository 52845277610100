import * as React from 'react';
import type { SVGProps } from 'react';
const SvgOpenInNewIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="#4984F2"
      d="M12 12.667H4A.669.669 0 0 1 3.333 12V4c0-.367.3-.667.667-.667h3.333c.367 0 .667-.3.667-.666C8 2.3 7.7 2 7.333 2h-4C2.593 2 2 2.6 2 3.333v9.334C2 13.4 2.6 14 3.333 14h9.334C13.4 14 14 13.4 14 12.667v-4C14 8.3 13.7 8 13.333 8c-.366 0-.666.3-.666.667V12c0 .367-.3.667-.667.667Zm-2.667-10c0 .366.3.666.667.666h1.727L5.64 9.42a.664.664 0 1 0 .94.94l6.087-6.087V6c0 .367.3.667.666.667.367 0 .667-.3.667-.667V2.667C14 2.3 13.7 2 13.333 2H10c-.367 0-.667.3-.667.667Z"
    />
  </svg>
);
export default SvgOpenInNewIcon;
