import { Templates } from './Templates';
import { observer } from 'mobx-react';
import { useStore } from '../../../../../hooks/useStore';
import { chain } from 'lodash';
import { useMemo } from 'react';
import { TemplateEditDrawer } from '../TemplateEditDrawer/TemplateEditDrawer';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { SettingObjectRoutes } from '../../../common';
import { useAtomValue } from 'jotai';
import { companyAtom } from '../../../../../atoms/auth';
import { isPermitAddTemplate } from '../../../../../atoms/firestore/companyPermission';

type Props = {
  teamId: string;
  routes: SettingObjectRoutes<{ teamId: string } | never, 'templateId'>;
};

export const TemplatesWithLogic = observer(({ teamId, routes }: Props) => {
  const store = useStore();
  const templates = store.getTemplates(teamId);
  const categories = useMemo(
    () =>
      chain(templates)
        .map((t) => t.category)
        .filter((c) => c != null)
        .map((c) => c!)
        .uniq()
        .sort((a, b) => a.localeCompare(b, 'ja'))
        .toJSON(),
    [templates]
  );

  const location = useLocation();
  const newRoute = useRouteMatch({
    location,
    path: routes.getNewPathName({ teamId }),
  });
  const detailRoute = useRouteMatch<{ templateId: string }>({
    location,
    path: routes.getDetailPathName({
      teamId,
      templateId: ':templateId',
    }),
  });

  const handleOpenCreateDrawer = () => {
    routes.toNew({ teamId });
  };

  const handleOpenEditDrawer = (templateId: string) => {
    routes.toDetail({ teamId, templateId });
  };

  const handleUpdateCategory = async (
    oldCategory: string,
    newCategory: string
  ) => {
    const promises = templates
      .filter((t) => t.category === oldCategory)
      .map(async (t) => {
        await store.templateStore.updateTemplate(t, {
          category: newCategory,
        });
      });
    await Promise.all(promises);
  };

  const handleDeleteCategory = async (category: string) => {
    const promises = templates
      .filter((t) => t.category === category)
      .map(async (t) => {
        await store.templateStore.updateTemplate(t, {
          category: null,
        });
      });
    await Promise.all(promises);
  };

  const company = useAtomValue(companyAtom);
  const isPermitted = isPermitAddTemplate(company, templates.length);

  return (
    <>
      <Templates
        categories={categories}
        templates={templates.map((t) => ({
          id: t.id,
          title: t.title,
          category: t.category,
        }))}
        onOpenCreateDrawer={handleOpenCreateDrawer}
        onOpenEditDrawer={handleOpenEditDrawer}
        onUpdateCategory={handleUpdateCategory}
        onDeleteCategory={handleDeleteCategory}
        canCreateTemplate={isPermitted}
        loading={store.templatesLoading}
        readonly={store.me.isReadOnly}
      />
      {newRoute && (
        <TemplateEditDrawer
          open={true}
          onOpenChange={() => routes.toIndex({ teamId })}
          teamId={teamId}
          getTemplate={() => undefined}
        />
      )}
      <TemplateEditDrawer
        key={detailRoute?.url}
        open={!newRoute && !!detailRoute}
        onOpenChange={() => routes.toIndex({ teamId })}
        teamId={teamId}
        getTemplate={() =>
          detailRoute
            ? store.getTemplate(detailRoute.params.templateId)
            : undefined
        }
      />
    </>
  );
});
