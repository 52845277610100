import { escapeHtml } from 'lib';

export const reservedWords = {
  toName: '{{to.name}}',
  toEmail: '{{to.email}}',
  meName: '{{me.name}}',
  meEmail: '{{me.email}}',
  fromName: '{{from.name}}',
  fromEmail: '{{from.email}}',
};

export const parseReservedWords = (text, parseItems, parseOptions = {}) => {
  const { to, me, from } = parseItems;
  const { escape, overrideToName } = parseOptions;
  const escapeFunc = escape ? escapeHtml : (str) => str;

  if (overrideToName) {
    to.name = overrideToName;
  }

  // MEMO: "{{from.name}}"自体が予約語を含んでいる可能性があるので、先にパースしている。
  return text
    .replace(
      replaceAllRegExp(reservedWords.fromName),
      escapeFunc(from.name || '')
    )
    .replace(
      replaceAllRegExp(reservedWords.fromEmail),
      escapeFunc(from.email || '')
    )
    .replace(replaceAllRegExp(reservedWords.toName), escapeFunc(to.name || ''))
    .replace(
      replaceAllRegExp(reservedWords.toEmail),
      escapeFunc(to.email || '')
    )
    .replace(replaceAllRegExp(reservedWords.meName), escapeFunc(me.name || ''))
    .replace(
      replaceAllRegExp(reservedWords.meEmail),
      escapeFunc(me.email || '')
    );
};

// MEMO: String.replaceAllを使用したいが、jestでトランスパイルする必要があるため、replaceで代用している
const replaceAllRegExp = (str) => new RegExp(str, 'g');
