import * as React from 'react';
import type { SVGProps } from 'react';
const SvgSend = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="m20.35 12.7-16.3 6.85c-.25.1-.487.08-.712-.062A.682.682 0 0 1 3 18.876V5.126c0-.267.112-.471.337-.613a.747.747 0 0 1 .713-.062l16.3 6.85c.3.133.45.366.45.7 0 .333-.15.566-.45.7ZM4.5 17.677 18.1 12 4.5 6.25v4.2L10.55 12 4.5 13.5v4.175Z"
    />
  </svg>
);
export default SvgSend;
