import cheerio from 'cheerio';
import sanitize from 'sanitize-html';
import Autolinker from 'autolinker';
import { validateEmail } from './validate';

// usage example:
// const a = ['a', 1, 'a', 2, '1'];
// const unique = a.filter(onlyUnique);
export const onlyUnique = <T>(value: T, index: number, self: Array<T>) =>
  self.indexOf(value) === index;

// 検索用に文字列を正規化する
export const normalizeStr = (str: string) =>
  toHanSymbol(toZenHira(str.toLowerCase()));

// 半角・全角カタカナをひらがなに変換する
const toZenHira = (str: string) => toHira(toZenKana(str));

// カタカナをひらがなに変換する
const toHira = (str: string) => {
  return str.replace(/[\u30a1-\u30f6]/g, (match) => {
    const chr = match.charCodeAt(0) - 0x60;
    return String.fromCharCode(chr);
  });
};

// 半角ｶﾀｶﾅを全角カタカナに変換する
const toZenKana = function (str: string) {
  const kanaMap: { [halfWidthKana: string]: string } = {
    ｶﾞ: 'ガ',
    ｷﾞ: 'ギ',
    ｸﾞ: 'グ',
    ｹﾞ: 'ゲ',
    ｺﾞ: 'ゴ',
    ｻﾞ: 'ザ',
    ｼﾞ: 'ジ',
    ｽﾞ: 'ズ',
    ｾﾞ: 'ゼ',
    ｿﾞ: 'ゾ',
    ﾀﾞ: 'ダ',
    ﾁﾞ: 'ヂ',
    ﾂﾞ: 'ヅ',
    ﾃﾞ: 'デ',
    ﾄﾞ: 'ド',
    ﾊﾞ: 'バ',
    ﾋﾞ: 'ビ',
    ﾌﾞ: 'ブ',
    ﾍﾞ: 'ベ',
    ﾎﾞ: 'ボ',
    ﾊﾟ: 'パ',
    ﾋﾟ: 'ピ',
    ﾌﾟ: 'プ',
    ﾍﾟ: 'ペ',
    ﾎﾟ: 'ポ',
    ｳﾞ: 'ヴ',
    ﾜﾞ: 'ヷ',
    ｦﾞ: 'ヺ',
    ｱ: 'ア',
    ｲ: 'イ',
    ｳ: 'ウ',
    ｴ: 'エ',
    ｵ: 'オ',
    ｶ: 'カ',
    ｷ: 'キ',
    ｸ: 'ク',
    ｹ: 'ケ',
    ｺ: 'コ',
    ｻ: 'サ',
    ｼ: 'シ',
    ｽ: 'ス',
    ｾ: 'セ',
    ｿ: 'ソ',
    ﾀ: 'タ',
    ﾁ: 'チ',
    ﾂ: 'ツ',
    ﾃ: 'テ',
    ﾄ: 'ト',
    ﾅ: 'ナ',
    ﾆ: 'ニ',
    ﾇ: 'ヌ',
    ﾈ: 'ネ',
    ﾉ: 'ノ',
    ﾊ: 'ハ',
    ﾋ: 'ヒ',
    ﾌ: 'フ',
    ﾍ: 'ヘ',
    ﾎ: 'ホ',
    ﾏ: 'マ',
    ﾐ: 'ミ',
    ﾑ: 'ム',
    ﾒ: 'メ',
    ﾓ: 'モ',
    ﾔ: 'ヤ',
    ﾕ: 'ユ',
    ﾖ: 'ヨ',
    ﾗ: 'ラ',
    ﾘ: 'リ',
    ﾙ: 'ル',
    ﾚ: 'レ',
    ﾛ: 'ロ',
    ﾜ: 'ワ',
    ｦ: 'ヲ',
    ﾝ: 'ン',
    ｧ: 'ァ',
    ｨ: 'ィ',
    ｩ: 'ゥ',
    ｪ: 'ェ',
    ｫ: 'ォ',
    ｯ: 'ッ',
    ｬ: 'ャ',
    ｭ: 'ュ',
    ｮ: 'ョ',
  };

  const reg = new RegExp('(' + Object.keys(kanaMap).join('|') + ')', 'g');
  return str.replace(reg, (match) => {
    return kanaMap[match];
  });
};

// 全角記号を半角記号に変換する
const toHanSymbol = (str: string) => {
  const symbolMap: { [fullWidthSymbol: string]: string } = {
    '！': '!',
    '”': '"',
    '＃': '#',
    '＄': '$',
    '％': '%',
    '＆': '&',
    '’': `'`,
    '（': '(',
    '）': ')',
    '＊': '*',
    '＋': '+',
    '，': ',',
    ー: '-',
    '．': '.',
    '／': '/',
    '：': ':',
    '；': ';',
    '＜': '<',
    '＝': '=',
    '＞': '>',
    '？': '?',
    '＠': '@',
    '［': '[',
    '＼': `\\`,
    '］': ']',
    '＾': '^',
    '＿': '_',
    '｀': '`',
    '｛': '{',
    '｜': '|',
    '｝': '}',
    '〜': '~',
    '。': '｡',
    '、': '､',
    '「': '｢',
    '」': '｣',
    '・': '･',
    '￥': '¥',
  };

  const reg = new RegExp('(' + Object.keys(symbolMap).join('|') + ')', 'g');
  return str
    .replace(reg, (match) => {
      return symbolMap[match] ?? match;
    })
    .replace(/ﾞ/g, '゛')
    .replace(/ﾟ/g, '゜');
};

// objectの配列からidが重複しているものを取り除く
export const deduplicateById = <T extends { id: string | number }>(
  arr: Array<T>
) => [...new Map(arr.map((item) => [item.id, item])).values()];

export const splitNameEmail = (str: string) => {
  // If no email bracket present, return as is
  if (str.indexOf('<') === -1) {
    return ['', str];
  }

  // Split into name and email
  let [name, email] = str.split('<');

  // Trim and fix up
  name = name.trim();
  email = email.replace('>', '').trim();

  //Return as array
  return [name, email];
};

export const extractName = (str: string) => {
  return splitNameEmail(str)[0];
};

export const extractEmail = (str: string) => {
  return splitNameEmail(str)[1];
};

// 改行コードをbrタグに変換する
export const convertNtoBr = (str: string) => {
  return str.replace(/\n/g, '<br>');
};

// テキストに引用をつける
export const quoteText = (str: string) => {
  // eslint-disable-next-line no-control-regex
  return str.replace(/^/, '> ').replace(new RegExp('\n', 'g'), '\n> ');
};

// htmlをエスケープする
export const escapeHtml = (str: string) => {
  if (typeof str !== 'string') {
    return str;
  }
  return str.replace(/[&'`"<>]/g, (match) => {
    return (
      {
        '&': '&amp;',
        "'": '&#x27;',
        '`': '&#x60;',
        '"': '&quot;',
        '<': '&lt;',
        '>': '&gt;',
      }[match] ?? match
    );
  });
};

export const addDefaultStyle = (html: string) =>
  `<div style="font-size:14px;font-family:'Lato','Noto Sans JP','ヒラギノ角ゴ ProN','Hiragino Kaku Gothic ProN','メイリオ',Meiryo,'ＭＳ Ｐゴシック','MS PGothic',sans-serif;color:rgba(0,0,0,1);">${html}</div>`;

/** @param {string=} html */
export const decorateHtml = (html = '') =>
  addTargetAndRelAttrToAnchorTag(addSizeToImageTag(addDefaultStyle(html)));

// HTMLを引用に変換する
export const convertHtmlToQuote = (html: string) => {
  const $ = cheerio.load(html);
  $('body').each((i, item) => {
    // bodyタグをdivタグに変更する
    if ('tagName' in item) {
      item.tagName = 'div';
    }
  });
  return $('html').html(); // htmlタグの中身を返す（!DOCTYPEおよびhtmlタグを除外するため）
};

const defaultSanitizeOptions: sanitize.IOptions = {
  allowedTags: sanitize.defaults.allowedTags.concat([
    'html',
    'head',
    'title',
    'link',
    'meta',
    'style',
    'body',
    'img',
    // 以下、HTML5で廃止された要素一覧だがメールとしては受信するケースがあるため許可している
    'center',
    'strike',
    'big',
    'blink',
    'dir',
    'font',
    'marquee',
    'tt',
  ]),
  allowedAttributes: false,
  // data（base64）を許可する https://github.com/apostrophecms/sanitize-html#allowed-url-schemes
  allowedSchemes: ['data', 'http', 'https', 'ftp', 'mailto'],
  // styleタグのホワイトリストを許可する
  allowVulnerableTags: true,
  // https://github.com/apostrophecms/sanitize-html/issues/547
  parseStyleAttributes: false,
};

// 表示可能なHTMLタグ以外を消去する
export const sanitizeHtml = (html: string) =>
  sanitize(html, defaultSanitizeOptions);

// aタグにtarget属性及び、rel属性を付与する
export const addTargetAndRelAttrToAnchorTag = (html: string) => {
  const $ = cheerio.load(html);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  $('a').each(function (this: any, _i, _elem) {
    $(this).attr('target', '_blank').attr('rel', 'noopener noreferrer');
  });
  return $.html();
};

// imgタグにmax-width,heightを付与する
export const addSizeToImageTag = (html: string) => {
  const $ = cheerio.load(html);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  $('img').each(function (this: any, _i, _elem) {
    $(this).css({
      ['max-width']: '100%',
      height: 'auto',
    });
  });
  return $.html();
};

// URLをリンク化する
export const linkify = (html: string) =>
  Autolinker.link(html, { stripPrefix: false });

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const removeUndefined = (obj: any) =>
  Object.fromEntries(Object.entries(obj).filter(([, v]) => v !== undefined));

// 例："test@example.com, test2@example.com" => ["test@example.com", "test2@example.com"]
export const toRecipients = (recipients: string) =>
  recipients
    .split(',')
    .map((c) => c.trim())
    .filter((c) => validateEmail(c))
    .filter(onlyUnique);

export const arrayDiff = <T>(arr1: Array<T>, arr2: Array<T>) =>
  arr1.filter((item) => !arr2.includes(item));

export const proxyDataHandler: ProxyHandler<any> = {
  get(target, prop) {
    if (prop in target) {
      return target[prop];
    }
    return target._data[prop];
  },
};
