import React from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import media from 'styled-media-query';
import bg1 from '../../assets/bg/bg1.png';
import * as color from '../../color';
import { DefaultInput } from '../Common/Input';
import { DefaultButton } from '../Common/Button';
import { Footer } from '../Common/Footer';
import firebase from '../../firebase';
import { Icon, Spin } from 'antd';
import { createCompanyAndUserFunction } from '../../functions';
import { Store } from 'store';
import { History } from 'history';

type Props = {
  store: Store;
  history: History;
};

type State = {
  companyName: string;
  email: string;
  password: string;
  isRegistering: boolean;
  errorMessage: string;
  tip: string;
};

class _Register extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      companyName: '',
      email: '',
      password: '',
      isRegistering: false,
      errorMessage: '',
      tip: '',
    };
  }

  submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.setState({ isRegistering: true, tip: 'ユーザを作成中…' });
    const { companyName, email, password } = this.state;

    try {
      // 自動ログインを行わない（ページを再読込するため）
      this.props.store.skipAutoSignIn = true;
      // firebaseに認証ユーザを作成
      await this.createUserWithEmailAndPasswordWithSigninRetry(email, password);
      this.setState({ tip: '会社を作成中…' });
      // 会社とユーザを作成
      await createCompanyAndUserFunction({
        companyName,
      });
      this.setState({ tip: 'ログイン中…' });
      // ユーザプロフィール設定画面へ移動する
      window.location.href = '/settings/teams/';
    } catch (error) {
      console.error('Register.submit:', error);
      const { code } = error as any;
      if (code === 'auth/email-already-in-use' || code === 'already-exists') {
        this.setState({
          errorMessage: '※すでに登録されているメールアドレスです',
          isRegistering: false,
        });
        return;
      }
      this.setState({
        errorMessage: '予期せぬエラーが発生しました',
        isRegistering: false,
      });
    }
  };

  createUserWithEmailAndPasswordWithSigninRetry = async (
    email: string,
    password: string
  ) => {
    let errorOnCreateUser = null;
    try {
      await firebase.auth().createUserWithEmailAndPassword(email, password);
      return;
    } catch (error) {
      errorOnCreateUser = error;
    }
    const { message } = errorOnCreateUser as any; // code = auth/internal-error
    if (errorOnCreateUser && message.includes('deadline exceeded')) {
      const maxSigninRetry = 3;
      for (let i = 0; i < maxSigninRetry; i++) {
        // wait a sec for retry
        await new Promise((r) => setTimeout(r, 2000));
        try {
          await firebase.auth().signInWithEmailAndPassword(email, password);
          if (firebase.auth().currentUser != null) return;
        } catch (error) {
          // ignore errors while retry, just do next try
        }
      }
    }
    // throw original error when all signIn attempts failed
    throw errorOnCreateUser;
  };

  onChangeCompanyName = (e: React.ChangeEvent<HTMLInputElement>) =>
    this.setState({ companyName: e.target.value });

  onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) =>
    this.setState({ email: e.target.value });

  onChangePassword = (e: React.ChangeEvent<HTMLInputElement>) =>
    this.setState({ password: e.target.value });

  toSignIn = () => this.props.history.push('/signIn');

  render() {
    const { isRegistering, errorMessage } = this.state;

    return (
      <Wrapper>
        <Left />
        <Right>
          <div />
          <Content>
            <RegisterWrapper>
              <form onSubmit={this.submit}>
                {errorMessage !== '' && (
                  <span style={{ color: 'red' }}>{errorMessage}</span>
                )}
                <InputWrapper>
                  <InputLabel>会社名</InputLabel>
                  <Input
                    value={this.state.companyName}
                    onChange={this.onChangeCompanyName}
                    placeholder="Onebox株式会社"
                    type="text"
                    disabled={isRegistering}
                    required
                    autoComplete="organization"
                  />
                </InputWrapper>
                <InputWrapper>
                  <InputLabel>
                    メールアドレス
                    <div className="caption">
                      ※ログインで使用するためご自身のアドレスを入力ください
                    </div>
                  </InputLabel>
                  <Input
                    value={this.state.email}
                    onChange={this.onChangeEmail}
                    placeholder="yohei.tsubuku@example.com"
                    type="email"
                    disabled={isRegistering}
                    required
                    autoComplete="username"
                  />
                </InputWrapper>
                <InputWrapper>
                  <InputLabel>パスワード</InputLabel>
                  <Input
                    value={this.state.password}
                    onChange={this.onChangePassword}
                    placeholder="********"
                    type="password"
                    disabled={isRegistering}
                    required
                    minLength={8}
                    autoComplete="new-password"
                  />
                </InputWrapper>
                <Spin
                  tip={this.state.tip}
                  spinning={isRegistering}
                  indicator={<Icon type="loading" spin />}
                >
                  {/* FIXME: any */}
                  <RegisterButton
                    type={'submit' as any}
                    disabled={isRegistering}
                  >
                    会社を作成
                  </RegisterButton>
                </Spin>
                <ToLoginLink onClick={this.toSignIn}>
                  すでにアカウントをお持ちの方はこちら
                </ToLoginLink>
              </form>
            </RegisterWrapper>
          </Content>
          <Footer />
        </Right>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  ${media.lessThan('medium')`
    display: block;
  `}
`;

const Left = styled.div`
  width: 50%;
  background-image: url(${bg1});
  background-size: contain;
  background-color: #f3f8f9;
  background-position: center center;
  background-repeat: no-repeat;
`;

const Right = styled.div`
  width: 50%;
  padding: 40px 20px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  ${media.lessThan('medium')`
    padding: 10px 20px;
    width: 100%;
    height: 100%;
  `}
`;

const Content = styled.div``;

const RegisterWrapper = styled.div`
  padding-bottom: 50px;
  ${media.lessThan('large')`
    padding-top: 32px;
  `}
`;

const InputWrapper = styled.div`
  position: relative;
  margin-bottom: 20px;
`;

const Input = styled(DefaultInput)`
  height: 40px;
  width: 360px;
`;

const InputLabel = styled.label`
  display: block;
  padding-bottom: 0.6em;

  & > .caption {
    font-size: 10px;
    color: #de7171;
  }
`;

const RegisterButton = styled(DefaultButton)`
  margin-top: 40px;
  height: 50px;
  width: 100%;
  max-width: 400px;
`;

const ToLoginLink = styled.a`
  display: block;
  margin-top: 20px;
  color: ${color.a.text.normal};
  font-size: 13px;
  text-decoration: underline;
  text-align: center;
`;

export const Register = compose<Props, Omit<Props, 'store' | 'history'>>(
  withRouter,
  inject('store'),
  observer
)(_Register);
