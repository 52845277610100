import * as React from 'react';
import type { SVGProps } from 'react';
const SvgForum = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={17}
    fill="none"
    viewBox="0 0 18 17"
    {...props}
  >
    <path
      fill="currentColor"
      d="M4.854 14c-.195 0-.386-.097-.573-.291-.188-.195-.282-.39-.282-.584v-2.041h10.417V4h2.083c.195 0 .382.098.563.292.18.194.27.396.27.604v10.917a.57.57 0 0 1-.385.573.578.578 0 0 1-.677-.136L14.02 14H4.854Zm-.855-4.166-2.27 2.27a.578.578 0 0 1-.678.136.57.57 0 0 1-.385-.573V1.542c0-.194.09-.389.27-.583.181-.195.369-.292.563-.292h10.813c.208 0 .403.094.583.281.18.188.271.386.271.594v7.417c0 .194-.09.389-.27.583-.181.194-.376.292-.584.292H3.999Zm7.917-1.25V1.917h-10v6.667h10Z"
    />
  </svg>
);
export default SvgForum;
