import { uploadFileToStorage } from 'App/Top/Conversations/util';
import { Team, Template, TemplateAttatchment, TemplateData } from 'lib';
import { action, makeObservable, observable } from 'mobx';
import { Store } from 'store';
import { generateStorageAttachmentName } from '../util';
import { v4 as uuidv4 } from 'uuid';
import firebase from 'firebase.js';
import { eventNames, logEvent } from 'analytics';
import * as Sentry from '@sentry/react';
import { UploadFile } from 'antd/lib/upload/interface';
import { BaseStore } from './base';
import {
  deleteDoc,
  doc,
  serverTimestamp,
  setDoc,
  Unsubscribe,
  updateDoc,
} from 'firebase/firestore';
import { subscribeQueryInArray } from 'utils/firebase';

export class TemplateStoreError extends Error {
  constructor(
    message: string,
    readonly displayMessage?: string
  ) {
    super(message);
    this.name = 'TemplateStoreError';
  }
}

export const MAX_ATTACHMENTS_SIZE_PER_TEMPLATE_MB = 15 as const;

export type TemplateCreateParams = Omit<
  TemplateData,
  'createdAt' | 'updatedAt'
>;

export interface ITemplateStore {
  isTemplatesLoading: boolean;
  syncTemplates(): void;
  unsubscribeTemplates(): void;
  getTeamTemplates(teamId: string): Template[];
  getTemplate(templateId: string): Template | undefined;

  /**
   * テンプレート追加
   */
  addTemplate(data: TemplateCreateParams): Promise<any>;

  /**
   * テンプレート更新
   */
  updateTemplate(template: Template, data: Partial<TemplateData>): Promise<any>;

  /**
   * テンプレート削除
   */
  removeTemplate(template: Template): Promise<any>;

  /**
   * 添付ファイルをストレージにアップロード(テンプレート未作成時)
   * fileとcombinedAttachmentsとファイルサイズ合計が上限値を超過するとエラー
   * 既存のテンプレートに添付ファイルを追加する場合は `uploadAttachmentAndAddToTemplate()` を使用
   *
   * @param file 添付ファイル
   * @param teamId テンプレートを作成するチームID
   * @param combinedAttachments 同じテンプレートに紐付けられる添付ファイル
   */
  uploadAttachment(
    file: UploadFile | File,
    teamId: string,
    combinedAttachments: TemplateAttatchment[]
  ): Promise<TemplateAttatchment>;

  /**
   * 添付ファイルをアップロードしてテンプレートに追加
   * テンプレートに紐付けられる添付ファイルのファイルサイズ合計が上限値を超過するとエラー
   */
  uploadAttachmentAndAddToTemplate(
    file: UploadFile | File,
    template: Template
  ): Promise<TemplateAttatchment>;

  /**
   * 添付ファイルをストレージから削除(テンプレート未作成時)
   *
   * 指定添付ファイルに紐付けられたテンプレートが存在する場合はエラーを投げる。
   * その場合は `removeAttachmentFromTemplate()` を使用
   */
  removeAttachment(attachment: TemplateAttatchment): Promise<any>;

  /**
   * 添付ファイルをテンプレートとストレージから削除
   */
  removeAttachmentFromTemplate(
    attachment: TemplateAttatchment,
    template: Template
  ): Promise<any>;
}

export class TemplateStore
  extends BaseStore<TemplateData>
  implements ITemplateStore
{
  PATH = `templates`;
  isTemplatesLoading = true;
  templates: Template[] = [];

  private _unsubscribeTemplates?: Unsubscribe;

  // TODO: 循環参照避ける
  constructor(rootStore: Store) {
    super(rootStore);
    makeObservable(this, {
      templates: observable,
      isTemplatesLoading: observable,
      syncTemplates: action,
      addTemplate: action,
      updateTemplate: action,
      uploadAttachmentAndAddToTemplate: action,
      removeAttachmentFromTemplate: action,
      removeTemplate: action,
    });
  }

  get joinedTeams(): Team[] {
    return this.rootStore.joinedTeams;
  }

  get companyId(): string {
    return this.rootStore.signInCompany;
  }

  unsubscribeTemplates(): void {
    this._unsubscribeTemplates && this._unsubscribeTemplates();
  }

  syncTemplates(): void {
    if (this.joinedTeams.length === 0) {
      this.templates = [];
      this.isTemplatesLoading = false;
      return;
    }

    this.isTemplatesLoading = true;
    this.unsubscribeTemplates();
    this._unsubscribeTemplates = subscribeQueryInArray(
      this.collection(),
      'teamId',
      this.joinedTeams.map((t) => t.id),
      (_snap, docs) => {
        this.templates = docs.map((doc) => new Template(doc));
      },
      () => {
        this.isTemplatesLoading = false;
      }
    );
  }

  getTeamTemplates(teamId: string): Template[] {
    return this.templates.filter((t) => t.teamId === teamId);
  }

  getTemplate(templateId: string): Template | undefined {
    return this.templates.find((t) => t.id === templateId);
  }

  async addTemplate(data: TemplateCreateParams): Promise<any> {
    const ref = doc(this.collection());
    await setDoc(ref, {
      ...data,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    });
    logEvent(eventNames.add_template);
  }

  async updateTemplate(
    template: Template,
    data: Partial<TemplateData>
  ): Promise<any> {
    // インスタンスに即時反映. Templateインスタンスをstateに保持していてonSnapshotで更新されないことがあるため
    template.updateData(data);

    await updateDoc(template.ref, {
      ...data,
      updatedAt: serverTimestamp(),
    });
  }

  async removeTemplate(template: Template): Promise<any> {
    await deleteDoc(template.ref);
    try {
      await Promise.all(
        template.attachments.map(async (a) => {
          await this.removeAttachmentFileFromStorage(a);
        })
      );
    } catch (e) {
      // storageの添付ファイル削除が失敗してもfirestoreからtemplateは削除されている
      Sentry.captureException(e);
    }
  }

  async uploadAttachment(
    file: UploadFile | File,
    teamId: string,
    combinedAttachments: TemplateAttatchment[]
  ): Promise<TemplateAttatchment> {
    const totalSize = combinedAttachments.reduce((a, b) => a + b.size, 0);

    const isTotalSizeValid =
      (file.size + totalSize) / 1024 / 1024 <
      MAX_ATTACHMENTS_SIZE_PER_TEMPLATE_MB;

    if (!isTotalSizeValid) {
      throw new TemplateStoreError(
        'file size exceeded',
        `添付ファイルの合計は${MAX_ATTACHMENTS_SIZE_PER_TEMPLATE_MB}MBまでです`
      );
    }

    const storagePath = `companies/${
      this.companyId
    }/templateAttachments/${uuidv4()}/${generateStorageAttachmentName(
      file.name
    )}`;

    // FIXME: UI層に依存しているのでストレージへのアップロード処理を分離
    const attachment = (await uploadFileToStorage(
      file,
      storagePath,
      this.rootStore.me.id,
      teamId
    )) as TemplateAttatchment;

    return attachment;
  }

  async uploadAttachmentAndAddToTemplate(
    file: UploadFile | File,
    template: Template
  ): Promise<TemplateAttatchment> {
    const attachment = await this.uploadAttachment(
      file,
      template.teamId,
      template.attachments
    );
    await this.updateTemplate(template, {
      attachments: [...template.attachments, attachment],
    });
    return attachment;
  }

  async removeAttachment(attachment: TemplateAttatchment): Promise<any> {
    const templateContainingAttachment = this.templates.find((t) =>
      t.attachments.find((a) => a.uid === attachment.uid)
    );
    if (templateContainingAttachment) {
      throw new TemplateStoreError(
        'Template containing the specified attachment exists'
      );
    }
    await this.removeAttachmentFileFromStorage(attachment);
    logEvent(eventNames.remove_template_attachment);
  }

  async removeAttachmentFromTemplate(
    attachment: TemplateAttatchment,
    template: Template
  ): Promise<any> {
    const { attachments } = template;
    const newAttachments = attachments.filter((a) => a.uid !== attachment.uid);
    if (newAttachments.length === attachments.length) {
      throw new TemplateStoreError(
        'Specified attachment is not contained in the template'
      );
    }

    await this.updateTemplate(template, {
      attachments: newAttachments,
    });
    try {
      await this.removeAttachment(attachment);
    } catch (e) {
      // storageの添付ファイル削除が失敗してもfirestoreのtemplateからattachmentは削除されている
      Sentry.captureException(e);
    }
    logEvent(eventNames.remove_template_attachment);
  }

  private async removeAttachmentFileFromStorage(
    attachment: TemplateAttatchment
  ) {
    await firebase.storage().ref().child(attachment.storagePath).delete();
  }
}
