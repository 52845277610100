import { CollectionReference, DocumentReference } from 'firebase/firestore';
import type { Store } from './index';

export abstract class BaseStore<T> {
  abstract readonly PATH: string;

  constructor(protected rootStore: Store) {}

  collection(): CollectionReference<T> {
    return this.rootStore.collection(this.PATH) as CollectionReference<T>;
  }

  doc(id: string): DocumentReference<T> {
    return this.rootStore.doc(this.PATH, id) as DocumentReference<T>;
  }
}
