import React, { useState, FC } from 'react';
import { Icon, Tag, TreeSelect } from 'antd';
import { parseReservedWords } from '../../Common/Editor/util';
import type { Team, User } from 'lib';
import { Inbox } from '../../../firestore/entity/inbox';

const { TreeNode } = TreeSelect;
type UserEmail = Partial<Pick<User, 'name' | 'email'>>;

export const From: FC<{
  inboxId: string;
  teamId: string;
  submitting: boolean;
  joinedTeams: Team[];
  getTeamInboxes: (teamId: string) => Inbox[];
  inbox: Inbox;
  onChange: (inboxId: string, teamId: string) => any;
  isReply: boolean;
  parseItems: Array<{ to: UserEmail; me: UserEmail; from: UserEmail }>;
}> = ({
  inboxId,
  teamId,
  submitting,
  joinedTeams,
  getTeamInboxes,
  inbox,
  onChange,
  isReply,
  parseItems,
}) => {
  const [showTreeSelect, setShowTreeSelect] = useState(false);

  // 返信の場合、個人メール以外の別チームから送信できないようにする
  const teams = joinedTeams.filter(
    (t) => !isReply || t.id === teamId || t.isPrivate
  );
  const inboxIdToTeam: Record<string, Team> = {};
  teams.forEach((team) => {
    getTeamInboxes(team.id).forEach(
      (inbox) => (inboxIdToTeam[inbox.id] = team)
    );
  });

  const _parseReservedWords = (from: string, parseOptions = {}) => {
    return parseReservedWords(from, parseItems, parseOptions);
  };

  return (
    <div className="inline-block w-full">
      <TreeSelect
        dropdownStyle={{ maxHeight: 400, overflow: 'auto', marginRight: 12 }}
        treeDefaultExpandAll
        value={inboxId}
        onSelect={(value: string) => {
          const team = inboxIdToTeam[value];
          onChange(value, isReply && team.isPrivate ? teamId : team.id);
          setShowTreeSelect(false);
        }}
        disabled={submitting}
        className="yaritori-email-from-select"
        style={{ width: 'auto', display: showTreeSelect ? '' : 'none' }}
      >
        {teams.map((team) => (
          <TreeNode
            value={team.id}
            title={team.name}
            selectable={false}
            key={team.id}
          >
            {getTeamInboxes(team.id).map((inbox) => (
              <TreeNode
                value={inbox.id}
                title={_parseReservedWords(inbox.emailWithAlias)}
                key={inbox.id}
                style={{ whiteSpace: 'break-spaces' }}
              />
            ))}
          </TreeNode>
        ))}
      </TreeSelect>

      {inbox && !showTreeSelect && (
        <div className="flex-1 rounded-lg border border-gray-300 p-1">
          <Tag
            onClick={() => setShowTreeSelect(true)}
            style={{
              cursor: 'pointer',
              whiteSpace: 'break-spaces',
              border: 'none',
              backgroundColor: '#F1F1F4',
            }}
            data-testid="draftFrom"
          >
            {/* {inbox.emailWithAlias} */}
            {_parseReservedWords(inbox.emailWithAlias)}
            <Icon type="swap" style={{ marginLeft: 4 }} />
          </Tag>
        </div>
      )}
    </div>
  );
};
