import React, {
  ComponentProps,
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { AvatarGroup } from '../../basics/AvatarGroup/AvatarGroup';
import {
  CommentInput,
  CommentInputHandle,
} from '../../comment/CommentInput/CommentInput';
import * as ScrollArea from '@radix-ui/react-scroll-area';
import { Icon } from '../../basics';
import { CaretDown, Menu } from '../../icons';
import {
  CommentInputReplyProvider,
  useCommentInputReply,
  useUpdateCommentInputReply,
} from '../../comment/CommentInput/CommentInputReplyProvider';
import { MobileNavButton } from '../../../App/Top/MobileNavButton';
import { useStore } from '../../../hooks/useStore';
import { isSP } from '../../../shared/util';
import { twMerge } from 'tailwind-merge';

type Props = {
  subject: string;
  currentUser: ComponentProps<typeof AvatarGroup>['entries'][number];
  users: ComponentProps<typeof AvatarGroup>['entries'];
  onComment: (content: string, replyTo: string | undefined) => Promise<boolean>;
  onOpenMemberList: () => void;
  scrollAreaRef?: RefObject<HTMLDivElement>;
  children: ReactNode;
};

export const ChatRoom = ({
  subject,
  currentUser,
  users,
  onComment,
  onOpenMemberList,
  scrollAreaRef,
  children,
}: Props) => {
  const store = useStore();
  const subjectElement = useMemo(() => {
    return (
      <button
        type="button"
        onClick={() => onOpenMemberList()}
        className="-mx-2 my-0 grid cursor-pointer grid-cols-[1fr_auto] items-center gap-0.5 rounded-lg bg-transparent px-2 py-1 font-bold hover:bg-black/5"
      >
        <div className="truncate">{subject}</div>
        <Icon icon={CaretDown} size={18} />
      </button>
    );
  }, [subject]);
  return (
    <CommentInputReplyProvider>
      <div className="grid h-full w-full grid-rows-[auto_1fr] bg-sumi-50 text-sm">
        <div
          className={twMerge(
            'grid grid-cols-[auto_auto] items-center justify-between gap-4 bg-white',
            isSP() ? 'h-16 px-2' : 'h-12 px-4'
          )}
        >
          <div
            className={twMerge(
              'grid items-center',
              isSP() ? 'grid-cols-[auto_auto] gap-2' : 'grid-cols-1'
            )}
          >
            {isSP() && (
              <MobileNavButton icon={Menu} onClick={store.openDrawer} />
            )}
            {subjectElement}
          </div>
          <div>
            <AvatarGroup size={32} entries={users} max={5} />
          </div>
        </div>
        <ContentWrapper scrollAreaRef={scrollAreaRef}>
          <div className="h-hull absolute inset-0 grid grid-rows-[1fr_auto]">
            <div className="flex flex-col items-start justify-end gap-4 p-4">
              {children}
            </div>
            <div className="sticky bottom-0 z-10 w-full bg-sumi-50 p-4 pt-1">
              <InternalCommentInput
                currentUser={currentUser}
                onComment={onComment}
              />
            </div>
          </div>
        </ContentWrapper>
      </div>
    </CommentInputReplyProvider>
  );
};

const ContentWrapper = ({
  children,
  scrollAreaRef,
}: {
  children: ReactNode;
  scrollAreaRef?: RefObject<HTMLDivElement>;
}) => {
  return (
    <ScrollArea.Root className="h-hull overflow-hidden">
      <ScrollArea.Viewport className="relative h-full" ref={scrollAreaRef}>
        {children}
      </ScrollArea.Viewport>
      <ScrollArea.Scrollbar
        className="w-[7px] p-[2px] hover:w-[11px]"
        orientation="vertical"
      >
        <ScrollArea.Thumb className="relative z-10 flex-1 rounded-full bg-black/50 before:absolute before:left-1/2 before:top-1/2 before:h-full before:min-h-[44px] before:-translate-x-1/2 before:-translate-y-1/2 before:content-['']" />
      </ScrollArea.Scrollbar>
    </ScrollArea.Root>
  );
};

const InternalCommentInput = ({
  currentUser,
  onComment,
}: Pick<Props, 'currentUser' | 'onComment'>) => {
  const inputHandleRef = useRef<CommentInputHandle>(null);
  const replyData = useCommentInputReply();
  const updateReplyData = useUpdateCommentInputReply();

  const handleSubmit: Props['onComment'] = useCallback(
    async (content, replyTo) => {
      const result = onComment(content, replyTo);
      updateReplyData(undefined);
      return result;
    },
    [onComment, updateReplyData]
  );

  useEffect(() => {
    if (replyData) {
      inputHandleRef.current?.focus();
    }
  }, [replyData]);

  return (
    <CommentInput
      user={currentUser}
      attachments={[]}
      onRemoveAttachment={emptyFn}
      onUploadAttachment={emptyFn}
      reply={
        replyData
          ? {
              name: replyData.name,
              content: replyData.content,
            }
          : undefined
      }
      onCancelReply={() => updateReplyData(undefined)}
      onSubmit={(content) => handleSubmit(content, replyData?.id)}
      placeholder="メッセージを送信"
      noUploadButton
      ref={inputHandleRef}
    />
  );
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
const emptyFn: () => any = () => {};
