import { useStore } from '../../hooks/useStore';
import { useHistory, useParams } from 'react-router-dom';
import { SearchQuery } from '../../store/search';
import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { getFilterText } from '../Common/MainNav/filter';
import { Button } from '../../components/basics';
import { useAtom } from 'jotai';
import { dirtyResetAtom } from '../Common/MainNav/useSearch';

export const FilterDisplay = observer((): JSX.Element => {
  const store = useStore();
  const [filters, setFilters] = useState('');
  const [dirty, resetFilter] = useAtom(dirtyResetAtom);
  const { searchStore } = store;
  const { tray, query } = useParams<{ tray: string; query: string }>();
  const history = useHistory();

  useEffect(() => {
    if (
      store.teamsLoading ||
      store.inboxesLoading ||
      store.tagsLoading ||
      store.usersLoading
    ) {
      return;
    }
    let filter: SearchQuery;
    try {
      filter = JSON.parse(decodeURIComponent(query)) as SearchQuery;
    } catch (e) {
      console.error(e);
      history.push('/me/drafts');
      return;
    }
    searchStore.query = filter;
    switch (tray) {
      case 'messages':
        searchStore.searchMessages({});
        break;
      case 'sent':
        searchStore.searchSent({});
        break;
      case 'deleted':
        searchStore.searchMessages({
          deleted: true,
        });
        break;
    }

    setFilters('検索条件：' + getFilterText(store, filter));
  }, [
    store.teamsLoading,
    store.inboxesLoading,
    store.tagsLoading,
    store.usersLoading,
    tray,
    query,
  ]);

  return (
    <div className="hidden min-h-[theme(height.12)] items-center bg-white py-2 pl-4 text-sm font-bold text-sumi-900 sm:flex">
      {filters}
      {dirty && (
        <Button variant="text" className="h-8 text-xs" onClick={resetFilter}>
          フィルターをリセット
        </Button>
      )}
    </div>
  );
});
