// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SMZubmOcg__ArtsIkgiw {\n  /* スクロールバーを表示しない */\n  -ms-overflow-style: none !important;\n  /* IE, Edge 対応 */\n  scrollbar-width: none !important;\n  /* Firefox 対応 */\n}\n.SMZubmOcg__ArtsIkgiw::-webkit-scrollbar {\n  /* Chrome, Safari 対応 */\n  display: none !important;\n}\n.FoEpyPiYU0p0GXMDX9RS {\n  background: linear-gradient(to right, #F1F1F4, transparent);\n}\n.byIEgIkUJT8mf8whFsak {\n  background: linear-gradient(to left, #F1F1F4, transparent);\n}\n", "",{"version":3,"sources":["webpack://./src/components/basics/Tabs.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,mCAAmC;EACnC,gBAAgB;EAChB,gCAAgC;EAChC,eAAe;AACjB;AACA;EACE,sBAAsB;EACtB,wBAAwB;AAC1B;AACA;EACE,2DAA4E;AAC9E;AACA;EACE,0DAA2E;AAC7E","sourcesContent":[".hideScrollbar {\n  /* スクロールバーを表示しない */\n  -ms-overflow-style: none !important;\n  /* IE, Edge 対応 */\n  scrollbar-width: none !important;\n  /* Firefox 対応 */\n}\n.hideScrollbar::-webkit-scrollbar {\n  /* Chrome, Safari 対応 */\n  display: none !important;\n}\n.bgLeft {\n  background: linear-gradient(to right, theme('colors.sumi.100'), transparent);\n}\n.bgRight {\n  background: linear-gradient(to left, theme('colors.sumi.100'), transparent);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hideScrollbar": "SMZubmOcg__ArtsIkgiw",
	"bgLeft": "FoEpyPiYU0p0GXMDX9RS",
	"bgRight": "byIEgIkUJT8mf8whFsak"
};
export default ___CSS_LOADER_EXPORT___;
