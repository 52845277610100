import { tv } from 'tailwind-variants';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  selected: boolean;
};

const messageItemWrapper = tv({
  base: 'relative mb-3 max-h-fit w-full cursor-pointer rounded-lg p-3 transition-colors ease-linear',
  variants: {
    selected: {
      true: 'bg-sumi-200',
      false: 'bg-white hover:bg-sumi-100',
    },
  },
  defaultVariants: {
    selected: false,
  },
});

const MessageItemWrapper = ({
  selected,
  children,
  className,
  ...otherProps
}: Props) => (
  <div
    role="link"
    className={messageItemWrapper({ selected, className })}
    {...otherProps}
  >
    {children}
  </div>
);

export default MessageItemWrapper;
