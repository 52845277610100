import React, { ReactNode } from 'react';
import { Redirect } from 'react-router-dom';
import { observer } from 'mobx-react';
import { AuthenticatingComponent } from './AuthenticatingComponent';
import { useStore } from '../../hooks/useStore';
import { useAtomValue } from 'jotai';
import { allLoadedAtom, authUserLoadingAtom } from '../../atoms/auth';

type Props = {
  children: ReactNode;
};

export const RedirectIfAuthenticated = observer(
  ({ children }: Props): JSX.Element => {
    const mobxStore = useStore();
    const authUserLoading = useAtomValue(authUserLoadingAtom);
    const allLoaded = useAtomValue(allLoadedAtom);

    if (mobxStore.currentUserLoading || authUserLoading) {
      return <AuthenticatingComponent />;
    }

    return mobxStore.isSignedIn && allLoaded ? (
      <Redirect to={'/'} />
    ) : (
      <>{children}</>
    );
  }
);
