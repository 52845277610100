import { InboxAutoReply } from 'lib';
import { Dialog } from '../../../../../components/basics/dialog/Dialog';
import { DialogHeader } from '../../../../../components/basics/dialog/DialogHeader';
import { DialogContent } from '../../../../../components/basics/dialog/DialogContent';
import { DialogFooter } from '../../../../../components/basics/dialog/DialogFooter';
import { Button } from '../../../../../components/basics';
import { Close } from '@radix-ui/react-dialog';
import { useMemo, useState } from 'react';
import { Switch } from '../../../../../components/basics/Switch/Switch';
import { InputGroup } from '../../../../../components/forms/InputGroup/InputGroup';
import { RadioSelect } from '../../../../../components/basics/Radio/RadioSelect/RadioSelect';
import moment from 'moment';
import { TimePicker } from 'antd';
import SimpleBar from 'simplebar-react';

type Props = {
  settings: InboxAutoReply;
  onSave: (settings: InboxAutoReply) => Promise<void>;
  open: boolean;
  onOpenChange: (open: boolean) => void;
};

export const AutoReplyEditDialog = ({
  settings,
  onSave,
  open,
  onOpenChange,
}: Props) => {
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [enabled, setEnabled] = useState(settings.enabled);
  const [allDay, setAllDay] = useState(settings.allDay);
  const [startTime, setStartTime] = useState(
    settings.startTime ? moment(settings.startTime, 'HH:mm') : undefined
  );
  const [endTime, setEndTime] = useState(
    settings.endTime ? moment(settings.endTime, 'HH:mm') : undefined
  );
  const [messageBody, setMessageBody] = useState(settings.messageBody);
  const handleSave = async () => {
    setSubmitted(true);

    if (hasError) {
      return;
    }

    setLoading(true);
    try {
      await onSave({
        enabled,
        allDay,
        startTime: !allDay && startTime ? startTime.format('HH:mm') : null,
        endTime: !allDay && endTime ? endTime.format('HH:mm') : null,
        messageBody,
      });
      setTimeout(() => {
        onOpenChange(false);
      }, 100);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const timeError = useMemo(() => {
    if (!enabled || allDay) {
      return undefined;
    }
    if (!startTime) {
      return '開始時刻を入力してください';
    }
    if (!endTime) {
      return '終了時刻を入力してください';
    }
    return undefined;
  }, [enabled, allDay, startTime, endTime]);
  const messageError = useMemo(() => {
    if (!enabled) {
      return undefined;
    }
    if (messageBody != null && messageBody.length > 0) {
      return undefined;
    }
    return 'メッセージ本文を入力してください';
  }, [enabled, messageBody]);
  const hasError = useMemo(
    () => Boolean(timeError || messageError),
    [timeError, messageError]
  );

  return (
    <Dialog open={open} onOpenChange={onOpenChange} contentClassName="top-[5%]">
      <SimpleBar className="max-h-[90dvh]">
        <form>
          <DialogHeader title="自動返信設定" />
          <DialogContent className="flex flex-col gap-4">
            <Switch
              label="自動返信を有効にする"
              value={enabled}
              onChange={setEnabled}
              disabled={loading}
            />
            <InputGroup
              label="時間帯"
              errorMessage={submitted ? timeError : undefined}
            >
              <RadioSelect
                options={[
                  { value: true, label: 'すべての時間帯で自動返信する' },
                  { value: false, label: '指定した時間帯に自動返信する' },
                ]}
                direction="column"
                value={allDay}
                onChange={setAllDay}
                disabled={!enabled || loading}
              />
            </InputGroup>
            <div className="flex items-center gap-4">
              <TimePicker
                popupClassName="z-60"
                placeholder="開始時刻"
                format="HH:mm"
                value={startTime ?? undefined}
                onChange={setStartTime}
                disabled={!enabled || allDay || loading}
                getPopupContainer={(e) => e}
              />
              <div className="select-none text-center">〜</div>
              <TimePicker
                placeholder="終了時刻"
                format="HH:mm"
                value={endTime ?? undefined}
                onChange={setEndTime}
                disabled={!enabled || allDay || loading}
                getPopupContainer={(e) => e}
              />
            </div>
            <InputGroup
              label="メッセージ本文"
              errorMessage={submitted ? messageError : undefined}
            >
              <textarea
                className="w-full resize-y rounded-lg border border-sumi-300 p-2 outline-none focus-visible:border-sea-500 disabled:text-sumi-500"
                rows={8}
                value={messageBody ?? ''}
                onChange={(e) => setMessageBody(e.target.value)}
                disabled={!enabled || loading}
              />
            </InputGroup>
          </DialogContent>
          <DialogFooter>
            <Close asChild>
              <Button variant="outlined" color="sumi">
                キャンセル
              </Button>
            </Close>
            <Button loading={loading} onClick={() => handleSave()}>
              保存
            </Button>
          </DialogFooter>
        </form>
      </SimpleBar>
    </Dialog>
  );
};
