import { useCallback } from 'react';

export const useImageResize = (): ((file: File) => Promise<File>) => {
  return useCallback(async (file: File) => {
    return new Promise<File>((resolve, reject) => {
      const img = new Image();
      const url = URL.createObjectURL(file);

      img.onload = async () => {
        const maxSize = 256;
        let { width, height } = img;
        if (width > height) {
          if (width > maxSize) {
            height *= maxSize / width;
            width = maxSize;
          }
        } else {
          if (height > maxSize) {
            width *= maxSize / height;
            height = maxSize;
          }
        }

        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        if (!context) {
          reject(new Error('Could not create 2D context.'));
          return;
        }
        canvas.width = width;
        canvas.height = height;
        context.drawImage(img, 0, 0, width, height);
        canvas.toBlob(async (blob) => {
          if (!blob) {
            reject('Could not create blob.');
            return;
          }

          const resizedFile = new File([blob], file.name, { type: blob.type });
          resolve(resizedFile);
        }, file.type);
      };

      img.onerror = () => {
        reject(new Error('Could not load image.'));
      };

      img.src = url;
    });
  }, []);
};
