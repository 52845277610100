import { createConverter, createEntityFactory } from '../converter';
import { Timestamp } from 'firebase/firestore';

export interface GroupData {
  id: string;
  name: string;
  members: string[];
  createdAt: Timestamp;
  updatedAt: Timestamp;
}

export const createGroup = createEntityFactory('Group', (data: GroupData) => ({
  isMember: (userId: string) => data.members.includes(userId),
}));

export type Group = ReturnType<typeof createGroup>;

export const groupConverter = createConverter(createGroup);
