import { Store } from '../../../store';
import { useMsgRouteMatch } from '../../../hooks/useMsgRouteMatch';
import { matchPath, useLocation, useParams } from 'react-router-dom';

type Entry = { href: string | null; label: string };

type UseBreadcrumbListResult = Entry[];

const meLabelMap: { [key: string]: string } = {
  drafts: '下書き',
  scheduled: '予約',
  sent: '送信済み',
  commented: 'コメント',
  mentioned: 'メンション',
  assigned: '自分が担当',
  starred: 'お気に入り',
} as const;

export const useBreadcrumbList = (
  store: Store
): UseBreadcrumbListResult | null => {
  const location = useLocation();
  const match = useMsgRouteMatch();
  const { meTab } = useParams<{ meTab: string | undefined }>();

  if (meTab != null) {
    return [{ href: null, label: meLabelMap[meTab] }];
  }

  if (match?.page == null) {
    return null;
  }
  switch (match.page) {
    case 'team': {
      const entries: Entry[] = [];
      const teamId = match.params.teamId;
      entries.push({
        href: null,
        label: store.getTeam(teamId)?.name ?? '自分',
      });

      const tagId = match.params.tagId;
      const tag = store.getTag(tagId);
      const parentTagId = tag?.parentTagId;
      if (parentTagId != null) {
        const parentTag = store.getTag(parentTagId);
        entries.push({
          href: `/teams/${teamId}/tags/${parentTagId}/messages`,
          label: parentTag?.name ?? '',
        });
      }
      entries.push({
        href: `/teams/${teamId}/tags/${tagId}/messages`,
        label: tag?.name ?? '',
      });

      const inboxId = match.params.inboxId;
      if (inboxId != null) {
        const inbox = store.getInbox(inboxId);
        if (inbox != null) {
          entries.push({
            href: `/teams/${teamId}/inboxes/${inboxId}/tags/${tagId}/messages`,
            label: inbox.yaritoriName || inbox.email,
          });
        }
      }
      return entries;
    }
    case 'line': {
      const entries: Entry[] = [];
      const teamId = match.params.teamId;
      const team = store.getTeam(teamId);
      const lineAccountId = match.params.lineAccountId;
      const lineAccount = store.lineStore.accounts.find(
        (account) => account.teamId === teamId && account.id === lineAccountId
      );
      entries.push({
        label: store.getTeam(teamId)?.name ?? '自分',
        href: null,
      });
      entries.push({ label: 'LINEアカウント', href: null });
      if (team != null && lineAccount != null) {
        entries.push({
          label: lineAccount.name,
          href: `/teams/${teamId}/lineaccounts/${lineAccountId}/messages`,
        });
      }
      return entries;
    }
    case 'me': {
      const entries: Entry[] = [];
      const matched = matchPath<{ tab: string }>(location.pathname, '/me/:tab');
      if (matched != null) {
        entries.push({ label: meLabelMap[matched.params.tab], href: null });
      }
      return entries;
    }
    default:
      return null;
  }
};
