import {
  TemplateEditForm,
  TemplateEditFormDefaultValue,
} from './TemplateEditForm';
import { observer } from 'mobx-react';
import { Template, TemplateAttatchment } from 'lib';
import { useStore } from '../../../../../hooks/useStore';
import { ComponentProps, useMemo } from 'react';
import { chain } from 'lodash';
import { useToast } from '../../../../../hooks/useToast';
import { TemplateStoreError } from '../../../../../store/template';

type Props = {
  teamId: string;
  template: Template | undefined;
  onFinishEdit: () => void;
  defaultValue?: TemplateEditFormDefaultValue;
};

type UpdateTemplate = Parameters<
  ComponentProps<typeof TemplateEditForm>['onUpdate']
>[1];

export const TemplateEditFormWithLogic = observer(
  ({ teamId, template, onFinishEdit, defaultValue }: Props) => {
    const store = useStore();
    const { showToast } = useToast();
    const templates = store.getTemplates(teamId);
    const categories = useMemo(
      () =>
        chain(templates)
          .map((t) => t.category)
          .filter((c) => c != null)
          .map((c) => c!)
          .uniq()
          .sort((a, b) => a.localeCompare(b, 'ja'))
          .toJSON(),
      [templates]
    );

    const handleUploadAttachment = async (
      file: File,
      currentAttachments: TemplateAttatchment[]
    ) => {
      try {
        let attachment: TemplateAttatchment;
        if (template) {
          attachment =
            await store.templateStore.uploadAttachmentAndAddToTemplate(
              file,
              template
            );
        } else {
          attachment = await store.templateStore.uploadAttachment(
            file,
            teamId,
            currentAttachments
          );
        }
        showToast('success', '添付ファイルを追加しました');
        return attachment;
      } catch (e) {
        if (e instanceof TemplateStoreError && e.displayMessage) {
          showToast('error', e.displayMessage);
        } else {
          showToast(
            'error',
            'テンプレートのアップロード中にエラーが発生しました'
          );
        }
        throw e;
      }
    };

    const handleRemoveAttachment = async (attachment: TemplateAttatchment) => {
      try {
        if (template) {
          await store.templateStore.removeAttachmentFromTemplate(
            attachment,
            template
          );
        } else {
          await store.templateStore.removeAttachment(attachment);
        }
        showToast('success', '添付ファイルを削除しました');
      } catch (e) {
        showToast('error', 'テンプレートの削除中にエラーが発生しました');
        throw e;
      }
    };

    const handleUpdate = async (
      id: string | undefined,
      update: UpdateTemplate
    ) => {
      try {
        if (id) {
          await editTemplate(id, update);
        } else {
          await createTemplate(update);
        }
      } catch (e) {
        console.error(e);
      }
    };

    const createTemplate = async (update: UpdateTemplate) => {
      try {
        await store.templateStore.addTemplate({
          teamId,
          title: update.title,
          to: update.to,
          cc: update.cc,
          bcc: update.bcc,
          subject: update.subject,
          body: update.body,
          bodyHtml: update.bodyHtml,
          category: update.category,
          attachments: update.attachments,
        });
        showToast('success', 'テンプレートを作成しました');
        onFinishEdit();
      } catch (e) {
        showToast('error', 'テンプレートの作成中にエラーが発生しました');
        console.error(e);
      }
    };
    const editTemplate = async (id: string, update: UpdateTemplate) => {
      try {
        const template = store.getTemplate(id);
        if (!template) {
          console.error('テンプレートが存在しません', `id=${id}`);
          showToast('error', 'テンプレートの更新中にエラーが発生しました');
          return;
        }
        await store.templateStore.updateTemplate(template, {
          teamId,
          title: update.title,
          to: update.to,
          cc: update.cc,
          bcc: update.bcc,
          subject: update.subject,
          body: update.body,
          bodyHtml: update.bodyHtml,
          category: update.category,
          attachments: update.attachments,
        });
        showToast('success', 'テンプレートを更新しました');
        onFinishEdit();
      } catch (e) {
        showToast('error', 'テンプレートの更新中にエラーが発生しました');
        console.error(e);
      }
    };

    const handleDelete = async (templateId: string) => {
      try {
        const template = store.getTemplate(templateId);
        if (!template) {
          console.error('テンプレートが存在しません', `id=${templateId}`);
          showToast('error', 'テンプレートの削除中にエラーが発生しました');
          return;
        }
        await store.templateStore.removeTemplate(template);
        showToast('success', 'テンプレートを削除しました');
        onFinishEdit();
      } catch (e) {
        console.error(e);
        showToast('error', 'テンプレートの削除中にエラーが発生しました');
      }
    };

    return (
      <TemplateEditForm
        template={
          template
            ? {
                id: template.id,
                title: template.title,
                subject: template.subject,
                body: template.body,
                bodyHtml: template.bodyHtml,
                attachments: template.attachments,
                to: template.to,
                cc: template.cc,
                bcc: template.bcc,
                category: template.category,
              }
            : undefined
        }
        categories={categories}
        onUpdate={handleUpdate}
        onDelete={handleDelete}
        onUploadAttachment={handleUploadAttachment}
        onRemoveAttachment={handleRemoveAttachment}
        defaultValue={defaultValue}
        readonly={store.me.isReadOnly}
      />
    );
  }
);
