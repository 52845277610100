import { Option } from 'components/forms';
import { MessageView } from 'store/messages';
import { CustomStatus, MessageStatus } from 'lib';
import { chain } from 'lodash';
import { flatStatusesDataAtom } from './customStatuses';
import { CustomStatusesData } from 'atoms/firestore/customStatuses';

/**
 * フロント（web）用のメッセージステータス。customStatusとシステムステータスを組み合わせて表示する。
 */
export type FrontMessageStatus = {
  statusName: string;

  /** カスタムステータス、システムステータスを判定する際に使う */
  statusType: MessageStatus;
  customStatusId: string | null;
};

export type UnknownFrontMessageStatus = {
  statusName: null;
  statusType: MessageStatus;
  customStatusId: null;
};

export const buildFrontSystemMessageStatus = (
  statusInFirestore: MessageStatus
): FrontMessageStatus => {
  return {
    statusType: statusInFirestore,
    statusName: statusInFirestore,
    customStatusId: null,
  };
};

/**
 * firestoreに保存されているフォーマットからFrontMessageStatusに変換し、返す。
 * @param statusInFirestore firestoreに保存されているmessageのstatusまたはteams以下のcustomStatuses
 * @param customStatuses atomのcustomStatusesである。もしカスタムステータスのプランに加入していないユーザの場合はundefinedである。
 */
export const buildFrontMessageStatus = (
  statusInFirestore: string | CustomStatus | undefined,
  customStatuses?: CustomStatus[]
): FrontMessageStatus | UnknownFrontMessageStatus => {
  if (typeof statusInFirestore === 'undefined' || statusInFirestore === '') {
    return {
      statusName: null,
      statusType: MessageStatus.None,
      customStatusId: null,
    };
  }

  if (typeof statusInFirestore !== 'string') {
    return {
      statusType: MessageStatus.CustomStatus,
      statusName: statusInFirestore.name,
      customStatusId: statusInFirestore.id,
    };
  }

  const systemStatuses: string[] = [
    MessageStatus.Processed,
    MessageStatus.Unprocessed,
  ];
  const isSystemStatusName = systemStatuses.includes(statusInFirestore);

  if (isSystemStatusName) {
    return {
      statusType: statusInFirestore,
      statusName: statusInFirestore,
      customStatusId: null,
    };
  }

  const customStatus = customStatuses?.find(
    (status) => status.id === statusInFirestore
  );
  if (!customStatus) {
    return {
      statusName: null,
      statusType: MessageStatus.None,
      customStatusId: null,
    };
  }

  return {
    statusName: customStatus.name,
    statusType: MessageStatus.CustomStatus,
    customStatusId: customStatus.id,
  };
};

/**
 * メール返信する際に次に変更すべきステータスを返す
 *
 * @param currentStatus 返信したいスレッドの現在ステータス
 * @param statuses atomからのデータ
 * @returns
 */
export const getNextStatus = (
  currentStatus: FrontMessageStatus | UnknownFrontMessageStatus | undefined,
  statuses: CustomStatus[]
): FrontMessageStatus | UnknownFrontMessageStatus => {
  if (!currentStatus || currentStatus.statusType === MessageStatus.None) {
    return buildFrontMessageStatus(MessageStatus.None);
  }

  if (
    statuses.length === 0 ||
    statuses.filter((s) => s.name !== MessageStatus.Unprocessed).length === 0
  ) {
    // カスタムステータスがない場合
    return currentStatus.statusType === MessageStatus.Processed
      ? buildFrontMessageStatus(MessageStatus.Unprocessed)
      : buildFrontMessageStatus(MessageStatus.Processed);
  }

  if (currentStatus.statusType === MessageStatus.Processed) {
    return buildFrontMessageStatus(MessageStatus.Unprocessed);
  }

  const customStatusIndex = statuses.findIndex((status) => {
    if (currentStatus.statusType !== MessageStatus.CustomStatus) {
      return status.name === currentStatus.statusType;
    }

    return status.id === currentStatus.customStatusId;
  });

  if (customStatusIndex >= 0) {
    const nextStatus = statuses[customStatusIndex]?.nextStatus;
    if (nextStatus) {
      // 次のステータス（nextStatus）が登録されている場合は、優先的に次のステータスを返す
      return buildFrontMessageStatus(nextStatus, statuses);
    }
  }

  // 次のステータス（nextStatus）が登録されていない場合は、index順で次のステータスを返す
  if (customStatusIndex + 1 < statuses.length) {
    return buildFrontMessageStatus(statuses[customStatusIndex + 1], statuses);
  }

  return buildFrontMessageStatus(MessageStatus.Processed);
};

/**
 * customStatusesが存在するときに使うselect optionsの選択肢を返す。
 */
export const statusesOptionsWhenCustomStatusedEnabled = (
  statuses: CustomStatus[]
): Option<string>[] => {
  const mapStatuses = chain(statuses)
    .filter((status) => status.name !== MessageStatus.Unprocessed)
    .orderBy('order', 'asc')
    .map((status) => ({ value: status.id, label: status.name }))
    .value();

  return [
    { value: MessageStatus.Unprocessed, label: MessageStatus.Unprocessed },
    ...mapStatuses,
    { value: MessageStatus.Processed, label: MessageStatus.Processed },
  ];
};

/**
 * メールリストでのタブ表示するステータスを返す。こちらのメソッドは "未対応"、"対応済み" が含まれていない。
 *
 * @param statuses atomからのデータ
 * @returns
 */
export const getStatusesForFront = (
  statuses: CustomStatus[]
): FrontMessageStatus[] => {
  return chain(statuses)
    .filter((status) => status.name !== MessageStatus.Unprocessed)
    .orderBy('order', 'asc')
    .map((s) => buildFrontMessageStatus(s, statuses))
    .filter((s): s is FrontMessageStatus => s.statusType !== MessageStatus.None)
    .value();
};

/** カスタムステータスが登録されている場合は TRUE、登録がない場合は FALSE */
export const hasCustomStatus = (statuses: CustomStatus[]): boolean => {
  return getStatusesForFront(statuses).length > 0;
};

/**
 * 次のパスで指定のステータスを返す。
 * /me/assigned/other
 * /me/assigned/messages
 * /me/assigned/processed
 * /teams/:teamId/inboxes/:inboxId/tags/:tagId/_myCustomStatus
 *
 * @param view
 * @param customStatuses
 * @returns
 */
export const getStatusesFromView = (
  view: string,
  customStatuses: CustomStatusesData
): FrontMessageStatus | undefined => {
  const allStatuses = getStatusesForFront(flatStatusesDataAtom(customStatuses));

  switch (view) {
    case MessageView.Unprocessed:
      return buildFrontSystemMessageStatus(MessageStatus.Unprocessed);
    case MessageView.Processed:
      return buildFrontSystemMessageStatus(MessageStatus.Processed);
    case MessageView.Other:
      // A special tab for all custom statuses on /me/assigned page.
      return;
  }

  if (view.startsWith('_')) {
    const customStatusName = view.slice(1);
    return allStatuses.find(
      (status) => status.customStatusId === customStatusName
    );
  }
};

/**
 * FrontMessageStatus を Firestore に保存する形式に変換する
 *
 * @param status FrontMessageStatus | UnknownFrontMessageStatus
 * @return firestoreに保存されている文字列の形式
 */
export const frontMessageStatusToFirestore = (
  status: FrontMessageStatus | UnknownFrontMessageStatus
): string => {
  if (status.statusType === MessageStatus.CustomStatus) {
    return status.customStatusId || MessageStatus.None;
  }

  return status.statusType;
};
