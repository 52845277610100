import React from 'react';
import styled from 'styled-components';
import { Avatar, Tag } from 'antd';
import * as color from '../../../color';
import level from '../../../font/level';
import media from 'styled-media-query';
import { PCOnly, SPOnly } from '../../Common/MediaQuery';
import moment from 'moment';
import { decorateHtml, generateHtml } from 'lib';
import FullHeightIframe from '../Conversations/Conversation/Message/FullHeightIframe';

const ValueComponent = ({ value, title }) => {
  if (!value) return <div />;

  if (Array.isArray(value))
    return value.length > 0 ? (
      <Addresses>
        {title}:{' '}
        {value.map((v) => (
          <Tag key={v} style={{ whiteSpace: 'break-spaces' }}>
            {v}
          </Tag>
        ))}
      </Addresses>
    ) : (
      <div />
    );

  return (
    <Addresses>
      {title}: <Tag style={{ whiteSpace: 'break-spaces' }}>{value}</Tag>
    </Addresses>
  );
};

export default ({
  to,
  cc,
  bcc,
  subject,
  signature,
  bodyHtml,
  inboxId,
  getInbox,
}) => {
  // 署名
  const signatureBody = signature?.bodyHtml ? '\n\n' + signature.bodyHtml : '';
  // 本文（HTML）
  const willSendHtmlBody = generateHtml({
    body: bodyHtml,
    signatureBody: signatureBody,
    quotedBody: '', // 引用はsending時に表示しない
  });
  const inbox = getInbox(inboxId);

  return (
    <MessageWrapper>
      <Head>
        <SPOnly>
          <SentAt>{moment().format('YYYY年M月D日 HH:mm')}</SentAt>
        </SPOnly>
        <Flex style={{ justifyContent: 'space-between' }}>
          <div>
            <Title>{subject}</Title>
            <Flex>
              <Avatar size="large">{inbox.emailIconName}</Avatar>
              <div style={{ flex: 1, marginLeft: 10 }}>
                <Sender>
                  <span>{inbox.email}</span>
                </Sender>
                <Addresses>
                  <ValueComponent value={to} title="宛先" />
                </Addresses>
                <Addresses>
                  <ValueComponent value={cc} title="Cc" />
                </Addresses>
                <Addresses>
                  <ValueComponent value={bcc} title="Bcc" />
                </Addresses>
              </div>
            </Flex>
          </div>
          <div>
            <Flex style={{ justifyContent: 'space-between' }}>
              <PCOnly>
                <Options>
                  <SentAt>{moment().format('YYYY年M月D日 HH:mm')}</SentAt>
                </Options>
              </PCOnly>
            </Flex>
          </div>
        </Flex>
      </Head>
      <Body>
        <MessageBody>
          <FullHeightIframe srcDoc={decorateHtml(willSendHtmlBody)} />
        </MessageBody>
      </Body>
    </MessageWrapper>
  );
};

const MessageWrapper = styled.div`
  padding: 20px 0;
  ${media.greaterThan('small')`
    margin: -16px;
    border-radius: 10px;
  `};
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.16);
  background-color: ${color.common.white};
`;

const Head = styled.div`
  padding: 0 30px 20px;
  border-bottom: solid 0.5px #f1f1f3;
`;

const Title = styled.h2`
  margin-bottom: 16px;
  font-size: ${level[3]};
  font-weight: bold;
`;

const Flex = styled.div`
  display: flex;
`;

const Sender = styled.div`
  font-size: ${level[2]};
`;

const SentAt = styled.div`
  font-size: ${level[0]};
  color: #8d8d8d;
`;

const Addresses = styled.div`
  margin-top: 4px;
`;

const Body = styled.div`
  padding: 20px 30px 0 30px;
`;

const MessageBody = styled.p`
  margin-bottom: 20px;
  font-size: ${level[1]};
`;

const Options = styled.div`
  display: flex;
  align-items: center;
`;
