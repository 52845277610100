import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { message, Tabs } from 'antd';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { db } from '../../../../firebase';
import firebase from 'firebase.js';
import { eventNames, logEvent } from '../../../../analytics';
import { NewTemplateWithLogic } from './NewTemplateWithLogic';
import Table from './table';
import { isSP } from 'shared/util';

const { TabPane } = Tabs;

class Index extends Component {
  add = async (template) => {
    await db
      .collection(`companies/${this.props.store.signInCompany}/templates`)
      .add({
        ...template,
        teamId: this.props.teamId,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      });
    logEvent(eventNames.add_template, {
      fromDraft: true,
    });
    message.success('テンプレートを作成しました');
  };

  render() {
    const { teamId, setTemplate, parseReservedWords, draft, store } =
      this.props;
    const privateTeamId = store.privateTeam?.id;
    const teamTemplates = this.props.store.getTemplates(teamId);
    const categories = Array.from(
      new Set( // 重複を除外
        teamTemplates
          .map((template) => template.category)
          .filter((filterCategory) => filterCategory) // nullを除外
          .sort((x, y) => x.localeCompare(y, 'ja'))
      )
    );

    return (
      <div>
        {!isSP() && (
          <div className="mb-4">
            <NewTemplateWithLogic
              teamId={teamId}
              initialData={
                draft
                  ? {
                      subject: draft.subject,
                      body: draft.body,
                      bodyHtml: draft.bodyHtml,
                      attachments: draft.attachments,
                      to: draft.to,
                      cc: draft.cc,
                      bcc: draft.bcc,
                    }
                  : {}
              }
            />
          </div>
        )}

        {teamTemplates.length > 0 && (
          <Tabs type="card" className="w-full">
            <TabPane tab="すべて" key="1">
              <Table
                parseReservedWords={parseReservedWords}
                dataSource={teamTemplates}
                onClickRow={setTemplate}
                privateTeamId={privateTeamId}
              />
            </TabPane>
            {categories.map((category) => (
              <TabPane tab={category} key={category}>
                <Table
                  parseReservedWords={parseReservedWords}
                  dataSource={teamTemplates.filter(
                    (t) => t.category === category
                  )}
                  onClickRow={setTemplate}
                  privateTeamId={privateTeamId}
                />
              </TabPane>
            ))}
          </Tabs>
        )}
      </div>
    );
  }
}

export default compose(withRouter, inject('store'), observer)(Index);
