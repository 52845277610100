import { H1 } from 'App/Common/H1';
import { H2 } from 'App/Common/H2';
import styled from 'styled-components';
import { SlackIntegration } from './slack';
import { ChatworkIntegration } from './chatwork';
import { IntegrationRoutes } from './IntegrationRoutes';
import { useStore } from 'hooks/useStore';
import { SalesforceIntegration } from './salesforce/SalesforceIntegration';

type Props = {
  routes: IntegrationRoutes;
};

export const Integrations: React.FC<Props> = (props) => {
  const store = useStore();
  return (
    <Wrapper>
      <H1>外部サービス連携</H1>
      <div className="service">
        <H2>Slack</H2>
        <SlackIntegration />
      </div>
      {store.isV2Plan && (
        <div className="service">
          <H2>Chatwork</H2>
          <ChatworkIntegration routes={props.routes} />
        </div>
      )}
      <div className="service">
        <H2>Salesforce</H2>
        <SalesforceIntegration />
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 32px;
  & > .service {
    margin-bottom: 48px;
  }
`;
