export const storageKeys = {
  localPreferences: 'localPreferences',
  localSystemValues: 'localSystemValues',
  settingsOpenTeams: 'settingsOpenTeams', // 古いキー
  settingsClosedTeams: 'settingsClosedTeams',
  openTags: 'openTags',
  lastVisitedUrl: 'lastVisitedUrl',
  searchHistory: 'searchHistory',
};

// 永続化オブジェクトのラッパー
class LocalStorage {
  getString = (key: string) => {
    return localStorage.getItem(key);
  };

  setString = (key: string, str: string) => {
    localStorage.setItem(key, str);
  };

  removeString = (key: string) => {
    localStorage.removeItem(key);
  };

  getObject = (key: string) => {
    const item = localStorage.getItem(key);
    if (!item) return null;
    return JSON.parse(item);
  };

  setObject = (key: string, arr: unknown) => {
    localStorage.setItem(key, JSON.stringify(arr));
  };

  getOrInitObject = (key: string, initialValue: unknown) => {
    const value = this.getObject(key);
    if (value) return value;
    this.setObject(key, initialValue);
    return initialValue;
  };
}

const lStorage = new LocalStorage();
export default lStorage;
