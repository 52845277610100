import {
  getSalesforceContactFunction,
  getSalesforceRecordsFunction,
  saveSalesforceRecordFunction,
} from '../functions';
import type { Store } from './index';

export type GetRecordType = 'Account' | 'OpportunityStage' | 'TaskStatus';

export type RecordType =
  | 'Account'
  | 'Case'
  | 'Contact'
  | 'Lead'
  | 'Opportunity'
  | 'OpportunityContactRole'
  | 'Task';

type SaveRecordPayload =
  | {
      // Account
      Name: string;
    }
  | {
      // Contact
      Email: string;
      FirstName: string;
      LastName: string;
    }
  | {
      // OpportunityContactRole
      ContactId: string;
      OpportunityId: string;
      IsPrimary: boolean;
    };

export class SalesforceStore {
  constructor(protected rootStore: Store) {}

  async getContactByEmail(payload: any): Promise<any> {
    const res = await getSalesforceContactFunction({
      companyId: this.rootStore.signInCompany,
      payload,
    });
    return res.data;
  }

  async getRecords(type: GetRecordType): Promise<any> {
    const res = await getSalesforceRecordsFunction({
      companyId: this.rootStore.signInCompany,
      type,
    });
    return res.data;
  }

  async saveRecord(type: RecordType, payload: SaveRecordPayload): Promise<any> {
    const res = await saveSalesforceRecordFunction({
      companyId: this.rootStore.signInCompany,
      type,
      payload,
    });
    return res.data;
  }
}
