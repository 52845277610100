import * as React from 'react';
import type { SVGProps } from 'react';
const SvgFormatListBulleted = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M10.058 18.75a.726.726 0 0 1-.535-.216.726.726 0 0 1-.215-.534c0-.213.072-.391.215-.535a.727.727 0 0 1 .535-.215h9.692c.212 0 .39.072.534.216A.726.726 0 0 1 20.5 18c0 .213-.072.39-.216.534a.726.726 0 0 1-.534.216h-9.692Zm0-6a.726.726 0 0 1-.535-.216.726.726 0 0 1-.215-.534c0-.213.072-.391.215-.535a.727.727 0 0 1 .535-.215h9.692c.212 0 .39.072.534.216A.726.726 0 0 1 20.5 12c0 .213-.072.39-.216.534a.726.726 0 0 1-.534.216h-9.692Zm0-6a.726.726 0 0 1-.535-.216A.726.726 0 0 1 9.308 6c0-.213.072-.391.215-.535a.726.726 0 0 1 .535-.215h9.692c.212 0 .39.072.534.216A.726.726 0 0 1 20.5 6c0 .213-.072.39-.216.534a.726.726 0 0 1-.534.216h-9.692ZM5.164 19.663c-.458 0-.85-.163-1.175-.488A1.601 1.601 0 0 1 3.5 18c0-.458.163-.85.489-1.175a1.601 1.601 0 0 1 1.175-.489c.457 0 .849.163 1.174.49.326.325.489.716.489 1.174 0 .457-.163.849-.489 1.175a1.601 1.601 0 0 1-1.175.488Zm0-6c-.458 0-.85-.162-1.175-.488A1.601 1.601 0 0 1 3.5 12c0-.458.163-.85.489-1.175a1.601 1.601 0 0 1 1.175-.489c.457 0 .849.163 1.174.49.326.325.489.716.489 1.174 0 .457-.163.849-.489 1.175a1.601 1.601 0 0 1-1.175.488Zm0-6c-.458 0-.85-.162-1.175-.488A1.601 1.601 0 0 1 3.5 6c0-.458.163-.85.489-1.175a1.601 1.601 0 0 1 1.175-.489c.457 0 .849.163 1.174.49.326.325.489.716.489 1.174 0 .457-.163.849-.489 1.175a1.601 1.601 0 0 1-1.175.488Z"
    />
  </svg>
);
export default SvgFormatListBulleted;
