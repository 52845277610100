import React from 'react';
import { Menu, Tag } from 'antd';

const { SubMenu } = Menu;

export const TagSubOptions = ({ tags, onClickTag, ...props }) => {
  return (
    <SubMenu title="タグを追加" {...props}>
      {tags.map((tag, index) => (
        <Menu.Item key={index} onClick={() => onClickTag(tag)}>
          <Tag key={tag.id} color={tag.color}>
            {tag.name}
          </Tag>
        </Menu.Item>
      ))}
    </SubMenu>
  );
};
