import { Dialog } from '../../../components/basics/dialog/Dialog';
import { FormEvent, useState } from 'react';
import { DialogHeader } from '../../../components/basics/dialog/DialogHeader';
import { DialogContent } from '../../../components/basics/dialog/DialogContent';
import { InputGroup } from '../../../components/forms/InputGroup/InputGroup';
import { Input } from '../../../components/forms';
import { DialogFooter } from '../../../components/basics/dialog/DialogFooter';
import { Button } from '../../../components/basics';

type Props = {
  defaultName: string | undefined;
  email: string;
  onSubmit: (name: string | undefined) => Promise<void>;
  onOpenChange: (open: boolean) => void;
};

export const DeliveryAddressEditDialog = ({
  defaultName,
  email,
  onSubmit,
  onOpenChange,
}: Props) => {
  const [name, setName] = useState(defaultName);
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      await onSubmit(name && name.length > 0 ? name : undefined);
      onOpenChange?.(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };
  return (
    <Dialog open={true} onOpenChange={onOpenChange}>
      <form action="" onSubmit={handleSubmit}>
        <DialogHeader title="配信用メールアドレスを編集" />
        <DialogContent className="flex flex-col gap-4">
          <InputGroup label="表示名">
            <Input
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Onebox株式会社"
              autoFocus
            />
          </InputGroup>
          <InputGroup label="メールアドレス">
            <Input value={email} type="email" disabled />
          </InputGroup>
        </DialogContent>
        <DialogFooter>
          <Button type="submit" loading={loading}>
            保存
          </Button>
        </DialogFooter>
      </form>
    </Dialog>
  );
};
