import { extractEmail } from './util';
import { intersection } from 'lodash';
import freeEmailDomains from 'free-email-domains/domains.json';

/**
 * 渡されたメールアドレスから宛名を削除して小文字に変換します
 * @param address
 */
export const normalizeAddress = (address: string) => {
  return extractEmail(address.trim()).toLowerCase();
};

/**
 * 渡された２つのメールアドレスが一致するか
 * @param a
 * @param b
 */
export const isSimilarAddress = (a: string, b: string) => {
  return normalizeAddress(a) === normalizeAddress(b);
};

/**
 * 渡されたメールアドレス配列全てに含まれるメールアドレスを返す
 * @param addresses
 */
export const intersectingAddresses = (
  ...addresses: Array<ArrayLike<string> | null | undefined>
): string[] => {
  const normalized = addresses.map((e) =>
    Array.from(e ?? []).map((s) => normalizeAddress(s))
  );
  return intersection(...normalized);
};

export const isFreeEmailDomain = (domain: string): boolean => {
  return (freeEmailDomains as string[]).includes(domain.toLowerCase());
};
