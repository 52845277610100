import styled from 'styled-components';
import { Product } from '../../Product';

export const PlanLimitItems: React.FC<{ product: Product }> = ({ product }) => {
  return (
    <Wrapper>
      <PlanLimitItem
        label="共有アドレス数"
        value={
          product.maxInboxes === null ? '無制限' : `${product.maxInboxes}個まで`
        }
      />
      <PlanLimitItem
        label="ユーザー数"
        value={
          product.maxUsers === null ? '無制限' : `${product.maxUsers}名まで`
        }
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  & > * {
    margin-bottom: 24px;
    &:last-of-type {
      margin-bottom: 0px;
    }
  }
`;

// PlanLimitItem

const PlanLimitItem: React.FC<{ label: string; value: string }> = ({
  label,
  value,
}) => {
  return (
    <PlanLimitItemWrapper>
      <div className="label">{label}</div>
      <div className="value">{value}</div>
    </PlanLimitItemWrapper>
  );
};

const PlanLimitItemWrapper = styled.div`
  line-height: 1;
  text-align: center;

  & > .label {
    font-size: 14px;
  }

  & > .value {
    margin-top: 8px;
    font-size: 14px;
    font-weight: 600;
    color: #3f82f9;
  }
`;
