import {
  atomFamilyWithLRU,
  atomWithPaginate,
  LoadingPaginate,
  Paginate,
  PaginateAtom,
} from '../../utils/atom';
import { Sent, sentConverter } from 'lib';
import { orderBy, query, where } from 'firebase/firestore';
import { companyCollection } from '../../firestore';
import { isEqual } from 'lodash';
import { store } from '../../providers/StoreProvider';
import { atom } from 'jotai/index';
import { joinedTeamIdsAtom } from './team';
import { meAtom } from '../auth';

type SentParams = {
  teamId?: string;
  inboxId?: string;
  mySent?: boolean;
};

type SentFilter = {
  teamIds: string[];
  inboxId?: string;
  sender?: string;
};

const writableSentFilterAtom = atom<SentFilter>({
  teamIds: [],
});

export const sentFilterAtom = atom<SentFilter, [SentParams], void>(
  (get) => get(writableSentFilterAtom),
  (get, set, params) => {
    const teamIds = params.teamId ? [params.teamId] : get(joinedTeamIdsAtom);
    set(writableSentFilterAtom, {
      teamIds: teamIds,
      inboxId: params.inboxId,
      sender: params.mySent ? get(meAtom).id : undefined,
    });
  }
);

export const sentFamily = atomFamilyWithLRU<
  SentFilter,
  Paginate<Sent>,
  PaginateAtom<Sent>
>({
  initializeAtom: (filter) => {
    let q = query(
      companyCollection('sent', sentConverter),
      where('teamId', 'in', filter.teamIds),
      orderBy('date', 'desc')
    );
    if (filter.inboxId) {
      q = query(q, where('inboxId', '==', filter.inboxId));
    }
    if (filter.sender) {
      q = query(q, where('sender', '==', filter.sender));
    }
    return atomWithPaginate(q, (snapshot, prev) => {
      snapshot.docChanges().forEach((change) => {
        const sent = change.doc.data();
        switch (change.type) {
          case 'added':
            prev.push(sent);
            break;
          case 'modified':
            prev.splice(
              prev.findIndex((s) => s.id === sent.id),
              1,
              sent
            );
            break;
          case 'removed':
            prev.splice(
              prev.findIndex((s) => s.id === sent.id),
              1
            );
            break;
        }
      });
      return prev.sort((a, b) => b.date.diff(a.date));
    });
  },
  max: 20,
  maxSize: 200,
  sizeCalculator: (value) => {
    if (value.state !== 'hasData') {
      return 0;
    }
    return value.data.length;
  },
  dispose: (anAtom) => store.set(anAtom, 'unsubscribe'),
  areEqual: isEqual,
});

export const sentAtom: PaginateAtom<Sent> = atom(
  (get) => {
    const filter = get(sentFilterAtom);
    if (!filter.teamIds.length) {
      return LoadingPaginate as Paginate<Sent>;
    }
    return get(sentFamily(filter));
  },
  async (get, set) => {
    await set(sentFamily(get(sentFilterAtom)), 'loadMore');
  }
);
