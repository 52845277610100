import { SettingBasicInputInputField } from 'App/Settings/common';
import React from 'react';

export const AutoBccField: React.FC<{
  value?: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
}> = ({ value, onChange, disabled }) => (
  <SettingBasicInputInputField
    label="自動的にBccに追加するメールアドレス"
    value={value}
    placeholder="contact@onebox.tokyo, sales@onebox.tokyo"
    note={`メール作成時、自動的にCcに追加されます。※ ","で複数のメールアドレスを記入可能です。`}
    onChange={onChange}
    disabled={disabled}
  />
);
