import { HorizontalScrollArea } from '../../../../../components/basics/HorizontalScrollArea';
import { tv } from 'tailwind-variants';
import { Icon } from '../../../../../components/basics';
import { Close, Delete, Edit } from '../../../../../components/icons';
import { FormEvent, useRef, useState } from 'react';
import { useConfirmDialog } from '../../../../../hooks/confirmDialog';

type Props = {
  categories: string[];
  value: string | undefined;
  onChange: (category: string | undefined) => void;
  onUpdateCategory: (oldCategory: string, newCategory: string) => Promise<void>;
  onDeleteCategory: (category: string) => Promise<void>;
  readonly: boolean;
};

const tab = tv({
  base: 'flex items-center gap-1 border-l border-l-sumi-200 px-2 first:border-l-transparent',
});

const button = tv({
  base: 'cursor-pointer select-none bg-transparent p-0 hover:underline',
  variants: {
    active: {
      true: 'font-bold',
    },
  },
});

export const TemplateCategoryTabs = ({
  categories,
  value,
  onChange,
  onUpdateCategory,
  onDeleteCategory,
  readonly,
}: Props) => {
  return (
    <HorizontalScrollArea>
      <div className="flex h-8 items-center whitespace-nowrap">
        <Tab
          onClick={() => onChange(undefined)}
          active={value === undefined}
          value="すべて"
          onChange={undefined}
          onDelete={undefined}
          readonly={true}
        />
        {categories.map((category, i) => {
          const active = category === value;
          return (
            <Tab
              key={i}
              onClick={() => onChange(category)}
              active={active}
              value={category}
              onChange={value ? (v) => onUpdateCategory(value, v) : undefined}
              onDelete={() => onDeleteCategory(category)}
              readonly={readonly}
            />
          );
        })}
      </div>
    </HorizontalScrollArea>
  );
};

type TabProps = {
  onClick: () => void;
  active: boolean;
  value: string;
  onChange: ((value: string) => Promise<void>) | undefined;
  onDelete: (() => Promise<void>) | undefined;
  readonly: boolean;
};

const Tab = ({
  onClick,
  active,
  value,
  onChange,
  onDelete,
  readonly,
}: TabProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [edit, setEdit] = useState(false);
  const [editingValue, setEditingValue] = useState(value);
  const openDialog = useConfirmDialog();
  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (editingValue.length <= 0) {
      return;
    }

    try {
      await onChange?.(editingValue);
      setEdit(false);
    } catch (e) {
      console.error(e);
    }
  };
  const startEdit = () => {
    setEditingValue(value);
    setEdit(true);
    setTimeout(() => {
      inputRef.current?.focus();
    }, 50);
  };
  const handleDelete = async () => {
    try {
      await onDelete?.();
      setEdit(false);
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <div className={tab()}>
      {edit ? (
        <form
          className="flex items-center gap-1"
          action=""
          onSubmit={handleSubmit}
        >
          <input
            className="w-32 rounded border border-sumi-200 px-2"
            value={editingValue}
            onChange={(e) => setEditingValue(e.target.value)}
            ref={inputRef}
          />
          <button
            type="button"
            className="flex h-5 w-5 cursor-pointer items-center justify-center rounded-full bg-sumi-100 p-0"
            onClick={() =>
              openDialog({
                title: `カテゴリ名 "${value}" を削除しますか？`,
                description: '一度削除すると元に戻せません',
                okType: 'danger',
                okText: '削除',
                onOk: async () => await handleDelete?.(),
              })
            }
          >
            <Icon icon={Delete} size={14} />
          </button>
          <button
            type="button"
            className="flex h-5 w-5 cursor-pointer items-center justify-center rounded-full bg-sumi-100 p-0"
            onClick={() => setEdit(false)}
          >
            <Icon icon={Close} size={8} />
          </button>
        </form>
      ) : (
        <>
          <button
            type="button"
            className={button({ active })}
            onClick={() => onClick()}
          >
            {value}
          </button>
          {!readonly && active && (
            <button
              type="button"
              className="block h-4 w-4 cursor-pointer bg-transparent p-0 text-sumi-500"
              onClick={() => startEdit()}
            >
              <Icon icon={Edit} size={16} />
            </button>
          )}
        </>
      )}
    </div>
  );
};
