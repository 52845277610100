import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import ChannelService, { canShowChannel } from '../service/channel';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';

class Channel extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      if (
        !canShowChannel(prevProps.location.pathname) &&
        canShowChannel(this.props.location.pathname)
      ) {
        // 表示可能なURLに遷移した場合
        this.boot();
      }
      if (
        canShowChannel(prevProps.location.pathname) &&
        !canShowChannel(this.props.location.pathname)
      ) {
        // 表示不可なURLに遷移した場合
        this.shutdown();
      }
    }
  }

  boot = () => {
    if (this.props.store.isSignedIn) {
      // channelにユーザでログインする
      ChannelService.boot({
        pluginKey: '965ebf54-2c5a-4c56-997e-7691d0a9848e',
        memberId: this.props.store.me.id,
        profile: {
          name: this.props.store.me.name,
          email: this.props.store.me.email,
          companyId: this.props.store.signInCompany,
          tagVersion: process.env.REACT_APP_TAG_VERSION,
          buildVersion: Number(process.env.REACT_APP_BUILD_VERSION),
        },
      });
      return;
    }
    // channelに匿名でログインする
    ChannelService.boot({
      pluginKey: '965ebf54-2c5a-4c56-997e-7691d0a9848e',
    });
  };

  shutdown = () => {
    ChannelService.shutdown();
  };

  render() {
    return this.props.children;
  }
}

export default compose(withRouter, inject('store'), observer)(Channel);
