import { createContext, ReactNode, useContext, useState } from 'react';

type ReplyData = {
  id: string;
  name: string;
  content: string;
  mentionUsers: string[];
};

type CommentInputReplyContext = {
  data: ReplyData | undefined;
  update: (data: ReplyData | undefined) => void;
};

const CommentInputReplyContext = createContext<CommentInputReplyContext>({
  data: undefined,
  update: null as never,
});

export const useCommentInputReply = (): ReplyData | undefined => {
  const { data } = useContext(CommentInputReplyContext);
  return data;
};

export const useUpdateCommentInputReply = (): ((
  data: ReplyData | undefined
) => void) => {
  const { update } = useContext(CommentInputReplyContext);
  return update;
};

export const CommentInputReplyProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [data, setData] = useState<ReplyData | undefined>(undefined);
  return (
    <CommentInputReplyContext.Provider value={{ data, update: setData }}>
      {children}
    </CommentInputReplyContext.Provider>
  );
};
