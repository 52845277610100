import * as React from 'react';
import type { SVGProps } from 'react';
const SvgArrowUp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    className="ml-1 inline-block align-middle"
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="24"
    viewBox="0 0 26 24"
    fill="none"
  >
    <rect width="26" height="24" rx="8" fill="#58DA42" />
    <path
      d="M14.0427 8.4808V18.4768C14.0427 18.7694 13.9421 19.0164 13.7409 19.2177C13.5395 19.4189 13.2925 19.5195 13 19.5195C12.7074 19.5195 12.4604 19.4189 12.2591 19.2177C12.0579 19.0164 11.9573 18.7694 11.9573 18.4768V8.4808L9.54757 10.8905C9.34223 11.0957 9.09909 11.1954 8.81813 11.1896C8.53717 11.1837 8.2941 11.0841 8.08892 10.8905C7.88359 10.6852 7.77802 10.4391 7.77221 10.1523C7.76641 9.86558 7.86609 9.61953 8.07127 9.4142L12.265 5.21495C12.3677 5.11243 12.4817 5.0375 12.6069 4.99014C12.7321 4.94277 12.8631 4.91909 13 4.91909C13.1369 4.91909 13.2679 4.94277 13.393 4.99014C13.5183 5.0375 13.6322 5.11243 13.7349 5.21495L17.9286 9.4142C18.1338 9.61953 18.2335 9.86558 18.2277 10.1523C18.2219 10.4391 18.1163 10.6852 17.911 10.8905C17.7058 11.0841 17.4628 11.1837 17.1818 11.1896C16.9008 11.1954 16.6577 11.0957 16.4524 10.8905L14.0427 8.4808Z"
      fill="white"
    />
  </svg>
);
export default SvgArrowUp;
