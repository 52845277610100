import { DeliveryMessagesPage } from './DeliveryMessagesPage';
import { useAtomValue } from 'jotai';
import { meAtom } from '../../../../atoms/auth';
import { useHistory } from 'react-router-dom';
import { DELIVERY_PATHS } from '../../page/DeliveryPage/deliveryPaths';
import {
  deliveryMessagesAtom,
  deliveryMessagesLoadingAtom,
} from '../../../../atoms/firestore/deliveryMessages';
import { DeliveryMessage } from 'lib';
import { ComponentProps } from 'react';
import { DeliveryMessageCard } from '../DeliveryMessageCard/DeliveryMessageCard';
import { deleteDoc, doc } from 'firebase/firestore';
import { companyCollection } from '../../../../firestore';

export const DeliveryMessagesPageWithLogic = () => {
  const me = useAtomValue(meAtom);
  const history = useHistory();
  const loading = useAtomValue(deliveryMessagesLoadingAtom);
  const messages = useAtomValue(deliveryMessagesAtom);
  const onCreateMessage = () => {
    history.push(DELIVERY_PATHS.getNewPath());
  };

  const onDeleteMessage = async (messageId: string) => {
    const ref = doc(companyCollection('deliveryMessages'), messageId);
    deleteDoc(ref).then();
  };

  return (
    <DeliveryMessagesPage
      loading={loading}
      onCreateMessage={onCreateMessage}
      getMessageHref={(id) => DELIVERY_PATHS.getDetailPath(id)}
      readonly={me.isReadOnly}
    >
      {messages.map((message: DeliveryMessage) => {
        let state: ComponentProps<typeof DeliveryMessageCard>['state'];
        if (message.isSent) {
          state = 'sent';
        } else if (message.isSending) {
          state = 'sending';
        } else {
          state = 'draft';
        }
        return (
          <DeliveryMessageCard
            key={message.id}
            subject={message.subject}
            bodyText={message.text}
            createdAt={message.createdAt?.toDate()}
            sentAt={message.sentAt?.toDate()}
            state={state}
            href={DELIVERY_PATHS.getDetailPath(message.id)}
            onDelete={async () => await onDeleteMessage(message.id)}
          />
        );
      })}
    </DeliveryMessagesPage>
  );
};
