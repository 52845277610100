import React from 'react';
import moment from 'moment';
import { Menu } from 'antd';
import styled from 'styled-components';
import { MenuProps } from 'antd/lib/menu';

const { SubMenu } = Menu;

type Props = {
  handleSchedule: (time: moment.Moment) => void;
  openSchedulePicker: () => void;
  supported: boolean;
} & MenuProps;

export const ScheduleSubMenu: React.FC<Props> = ({
  handleSchedule,
  openSchedulePicker,
  supported,
  ...props
}) => {
  const timeFormat = (time: moment.Moment) =>
    time.isSame(moment(), 'day') ? 'HH:mm' : 'M月D日 HH:mm';

  const choices = [
    {
      name: '10分後',
      time: moment().add(10, 'minutes').startOf('minute'),
    },
    {
      name: '1時間後',
      time: moment().add(1, 'hours').startOf('minute'),
    },
    {
      name: '明日の午前',
      time: moment().add(1, 'days').set('hour', 9).startOf('hour'),
    },
    {
      name: '明日の午後',
      time: moment().add(1, 'days').set('hour', 13).startOf('hour'),
    },
  ];

  if (!supported) {
    return (
      <SubMenu title="送信日時を指定&nbsp;&nbsp;" {...props}>
        <Menu.Item>
          <NotSuported>
            現在のプランで送信予約はサポートされていません
          </NotSuported>
        </Menu.Item>
      </SubMenu>
    );
  }

  return (
    <SubMenu title="送信日時を指定&nbsp;&nbsp;" {...props}>
      {choices.map((choice, index) => (
        <Menu.Item
          key={index}
          onClick={() => {
            handleSchedule(choice.time);
          }}
        >
          {choice.name}（{choice.time.format(timeFormat(choice.time))}）
        </Menu.Item>
      ))}
      <Menu.Item key={choices.length} onClick={openSchedulePicker}>
        日付と時刻を選択
      </Menu.Item>
    </SubMenu>
  );
};

const NotSuported = styled.div`
  color: rgba(0, 0, 0, 0.45);
`;
