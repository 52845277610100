import * as React from 'react';
import type { SVGProps } from 'react';
const SvgFire = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    viewBox="0 0 18 18"
    {...props}
  >
    <path
      fill="currentColor"
      d="M3 10.5c0-1.45.447-2.856 1.34-4.218.894-1.363 2.123-2.526 3.685-3.488a.57.57 0 0 1 .647-.01.645.645 0 0 1 .328.591v1.35c0 .425.147.782.44 1.069.294.288.654.431 1.079.431.225 0 .434-.047.628-.14.194-.094.365-.235.515-.422.1-.113.213-.19.338-.235a.383.383 0 0 1 .356.047 5.965 5.965 0 0 1 1.94 2.175c.47.888.704 1.838.704 2.85 0 1.188-.306 2.26-.919 3.216a5.895 5.895 0 0 1-2.418 2.166c.287-.325.509-.688.665-1.088.156-.4.235-.819.235-1.256 0-.475-.088-.922-.263-1.34a3.509 3.509 0 0 0-.769-1.135L9 8.588l-2.513 2.475c-.35.337-.612.715-.787 1.134a3.442 3.442 0 0 0-.263 1.34c0 .438.079.857.235 1.257.156.4.378.762.666 1.088a5.896 5.896 0 0 1-2.42-2.166C3.307 12.76 3 11.688 3 10.5Zm6-.337 1.725 1.687c.225.225.4.482.525.769.125.287.188.594.188.919 0 .662-.238 1.228-.713 1.697A2.365 2.365 0 0 1 9 15.938c-.675 0-1.25-.235-1.725-.703a2.297 2.297 0 0 1-.713-1.697c0-.325.06-.632.179-.919.118-.287.296-.544.534-.769L9 10.163Z"
    />
  </svg>
);
export default SvgFire;
