import { DeliveryAddresses } from './DeliveryAddresses';
import { useAtomValue } from 'jotai';
import {
  deliveryAddressesAtom,
  deliveryAddressesLoadingAtom,
} from '../../../atoms/firestore/deliveryAddress';
import { meAtom } from '../../../atoms/auth';
import { companyCollection } from '../../../firestore';
import {
  addDoc,
  deleteDoc,
  doc,
  serverTimestamp,
  updateDoc,
} from 'firebase/firestore';
import { useToast } from '../../../hooks/useToast';

export const DeliveryAddressesWithLogic = () => {
  const loading = useAtomValue(deliveryAddressesLoadingAtom);
  const addresses = useAtomValue(deliveryAddressesAtom);
  const me = useAtomValue(meAtom);
  const { showToast } = useToast();
  const onAdd = async (name: string | undefined, email: string) => {
    const addresses = companyCollection('deliveryAddresses');
    try {
      await addDoc(addresses, {
        name: name ?? null,
        email,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      });
      showToast('success', '配信用メールを追加しました');
    } catch (e) {
      console.error(e);
      showToast('error', '配信用メールを追加できませんでした');
    }
  };
  const onDelete = async (id: string) => {
    try {
      const addresses = companyCollection('deliveryAddresses');
      const addressRef = doc(addresses, id);
      await deleteDoc(addressRef);
      showToast('success', '配信用メールを削除しました');
    } catch (e) {
      console.error(e);
      showToast('error', '配信用メールを削除できませんでした');
    }
  };
  const onEditName = async (id: string, name: string | undefined) => {
    try {
      const addresses = companyCollection('deliveryAddresses');
      const addressRef = doc(addresses, id);
      await updateDoc(addressRef, {
        name: name ?? null,
        updatedAt: serverTimestamp(),
      });
      showToast('success', '配信用メールを更新しました');
    } catch (e) {
      console.error(e);
      showToast('error', '配信用メールを更新できませんでした');
    }
  };
  return (
    <DeliveryAddresses
      loading={loading}
      addresses={addresses.map((address) => ({
        id: address.id,
        name: address.name,
        email: address.email,
      }))}
      readonly={me.isReadOnly}
      onAdd={onAdd}
      onDelete={onDelete}
      onEditName={onEditName}
    />
  );
};
