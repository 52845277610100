/**
 * @typedef {object} onCheckBoxArgs
 * @prop {import('firebase/auth').RecaptchaVerifier} recaptchaVerifier
 * @prop {token} token
 */

import { getAuth, RecaptchaVerifier } from 'firebase/auth';
import { useLayoutEffect, useRef, useState } from 'react';

/**
 * @typedef {object} RecaptchaContainerProps
 * @prop {string} siteKey
 * @prop {(string) => void=} onReady
 * @prop {(onCheckBoxArgs) => void=} onCheckBox
 * @prop {() => void=} expiredCallback
 * @prop {(unknown) => void=} onError
 */

/**
 * The RecaptchaContainer
 * @param {RecaptchaContainerProps} props
 * @returns {JSX.Element | null}
 */
export default function RecaptchaContainer(props) {
  const { siteKey, onCheckBox, onReady, expiredCallback, onError } = props;
  const ref = useRef();
  const [state, setState] = useState();
  useLayoutEffect(() => {
    function clear() {}

    if (!ref.current) {
      return clear;
    }

    if (state) {
      return clear;
    }

    if (ref.current.childElementCount === 1) {
      ref.current.removeChild(ref.current.children[0]);
    }

    const container = document.createElement('div');
    container.setAttribute('sitekey', siteKey);
    ref.current.appendChild(container);

    const recaptchaVerifier = new RecaptchaVerifier(
      container,
      {
        callback: (token) => {
          if (onCheckBox) {
            onCheckBox({ recaptchaVerifier, token });
          }
        },
        'expired-callback': expiredCallback,
        'error-callback': onError,
      },
      getAuth()
    );

    recaptchaVerifier
      .render()
      .then((widgetId) => {
        setState({
          widgetId,
          recaptchaVerifier,
          container,
        });
        if (onReady) {
          onReady(widgetId);
        }
      })
      .catch((err) => {
        if (onError) {
          onError(err);
        }
      });

    return clear;
  }, [expiredCallback, onCheckBox, onError, onReady, siteKey, state]);
  return (
    <div
      style={
        state
          ? {
              opacity: 1,
              transition: 'opacity 1.0s linear 0.3s',
              background: '#f9f9f9',
              width: '304px',
              height: '78px',
            }
          : {
              opacity: 0,
              transition: 'opacity 0.2s linear 0.2s',
              background: '#f9f9f9',
              width: '304px',
              height: '78px',
              border: '1px solid #d3d3d3',
              borderRadius: '3px',
            }
      }
      ref={ref}
    />
  );
}
