import qs from 'qs';
import { matchPath } from 'react-router-dom';
import { Router } from '../common';

export type IntegrationPaths = {
  index: string;
  callbacks: {
    slack: string;
    chatwork: string;
  };
};

type Service = keyof IntegrationPaths['callbacks'];

export class IntegrationRoutes {
  constructor(
    readonly paths: IntegrationPaths,
    private router: Router,
    private origin: string
  ) {}

  get indexPathName(): string {
    return this.paths.index;
  }

  getCallbackPathName(service: Service): string {
    return this.paths.callbacks[service];
  }

  getCallbackUrl(service: Service): string {
    return this.origin + this.getCallbackPathName(service);
  }

  matchCallbackPath(
    pathname: string,
    queryString: string,
    service: keyof IntegrationPaths['callbacks']
  ): { code?: string } | undefined {
    if (matchPath(pathname, { path: this.getCallbackPathName(service) })) {
      const query = qs.parse(queryString, {
        ignoreQueryPrefix: true,
      });
      const { code } = query;
      if (code && typeof code === 'string') {
        return { code };
      }
      return {};
    }
  }

  toIndex(): void {
    this.router.push(this.indexPathName);
  }
}
