import {
  onSnapshot,
  query,
  QueryDocumentSnapshot,
  setDoc,
  getDoc,
  updateDoc,
} from 'firebase/firestore';
import { makeObservable, observable } from 'mobx';

import type { Store } from './index';
import { BaseStore } from './base';

export interface SettingsData {
  id: string;
}

export class SettingsStore extends BaseStore<SettingsData> {
  PATH = 'settings';
  salesforce?: QueryDocumentSnapshot<SettingsData> = undefined;

  _unsubscribeSettings?: () => void;

  constructor(rootStore: Store) {
    super(rootStore);

    makeObservable(this, {
      salesforce: observable,
    });
  }

  async syncSettings(): Promise<void> {
    this._unsubscribeSettings?.();

    this._unsubscribeSettings = onSnapshot(query(this.collection()), (snap) => {
      this.salesforce = undefined;
      snap.docs.forEach((doc) => {
        switch (doc.id) {
          case 'salesforce':
            this.salesforce = doc;
            break;
        }
      });
    });
  }

  async unsubscribeSyncs(): Promise<void> {
    if (this._unsubscribeSettings) {
      this._unsubscribeSettings();
    }
  }

  async updateDoc(docId: string, data: Record<string, any>): Promise<void> {
    const docRef = this.rootStore.doc(this.PATH, docId);
    const doc = await getDoc(docRef);
    if (!doc.exists()) {
      await setDoc(docRef, data);
      return;
    }
    await updateDoc(docRef, data);
  }
}
