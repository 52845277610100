import { useConfirmDialog } from 'hooks/confirmDialog';
import { useEffect, useRef } from 'react';

export const GlobalErrorHandler = () => {
  const countRef = useRef(0);
  const openDialog = useConfirmDialog();

  useEffect(() => {
    const onError = (event: ErrorEvent) => {
      if (!event.message.includes('Transaction timed out due to inactivity')) {
        // https://github.com/firebase/firebase-js-sdk/issues/939
        return;
      }
      countRef.current += 1;
      if (countRef.current > 4) {
        openDialog({
          title: 'エラー',
          description:
            '予期せぬエラーが発生しました、リロードする必要があります。',
          okText: 'リロード',
          onOk: () => document.location.reload(),
        });
      }
    };
    window.addEventListener('error', onError);
    return () => {
      window.removeEventListener('error', onError);
    };
  }, []);

  return null;
};
