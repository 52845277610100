import { useCallback, useEffect, useRef, useState } from 'react';

/**
 * Promiseの関数をラップする
 * @param func
 */
export const useLoadableFunc = <ARGS extends Array<any>, R>(
  func: (...args: ARGS) => PromiseLike<R>
): {
  func: (...args: ARGS) => Promise<R>;
  loading: boolean;
  error?: Error;
} => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error>();
  const mounted = useRef(false);
  const wrappedFunc: (...args: ARGS) => Promise<R> = useCallback(
    async (...args) => {
      setLoading(true);
      try {
        return await func(...args);
      } catch (e) {
        if (mounted.current) {
          setError(e as never);
        }
        throw e;
      } finally {
        if (mounted.current) {
          setLoading(false);
        }
      }
    },
    []
  );
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);
  return {
    func: wrappedFunc,
    loading,
    error,
  };
};
