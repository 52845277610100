import { Extension } from '@tiptap/core';

export type FontType = 'serif' | 'sans-serif' | 'monospace';

const INTERNAL_EDITOR_FONTS = {
  ms_gothic: {
    fontFamily: 'MS Gothic, sans-serif',
    display: 'MS ゴシック',
  },
  ms_mincho: {
    fontFamily: 'MS Mincho, serif',
    display: 'MS 明朝',
  },
  ms_p_mincho: {
    fontFamily: 'MS PMincho, serif',
    display: 'MS P 明朝',
  },
  yu_gothic: {
    fontFamily: 'Yu Gothic, YuGothic, sans-serif',
    display: '游ゴシック',
  },
  yu_mincho: {
    fontFamily: 'Yu Mincho, YuMincho, serif',
    display: '游明朝',
  },
  hiragino_kaku_gothic: {
    fontFamily: 'Hiragino Kaku Gothic ProN, ヒラギノ角ゴ ProN W3, sans-serif',
    display: 'ヒラギノ角ゴシック',
  },
  hiragino_mincho: {
    fontFamily: 'Hiragino Mincho ProN, ヒラギノ明朝 ProN W3, serif',
    display: 'ヒラギノ明朝',
  },
  times_new_roman: {
    fontFamily: 'Times New Roman, serif',
    display: 'Times New Roman',
  },
  sans_serif: {
    fontFamily: 'sans-serif',
    display: 'Sans serif',
  },
  serif: {
    fontFamily: 'serif',
    display: 'Serif',
  },
  monospace: {
    fontFamily: 'monospace',
    display: '固定幅',
  },
} as const;

export type EditorFont = keyof typeof INTERNAL_EDITOR_FONTS;

export const EDITOR_FONTS: {
  [key in EditorFont]: {
    fontFamily: `${string}, ${FontType}` | FontType;
    display: string;
  };
} = INTERNAL_EDITOR_FONTS;

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    fontFamily: {
      setFontFamily: (font: EditorFont) => ReturnType;
      unsetFontFamily: () => ReturnType;
    };
  }
}

export const FontFamily = Extension.create({
  name: 'fontFamily',

  addGlobalAttributes() {
    return [
      {
        types: ['textStyle'],
        attributes: {
          fontFamily: {
            default: null,
            parseHTML: (element) => {
              const fontFamily = element.style.fontFamily?.replace(
                /['"]+/g,
                ''
              );
              if (fontFamily) {
                const found = Object.entries(INTERNAL_EDITOR_FONTS).find(
                  ([, f]) => f.fontFamily === fontFamily
                );
                return found ? found[0] : null;
              }
              return null;
            },
            renderHTML: (attribute) => {
              const font = INTERNAL_EDITOR_FONTS[
                attribute.fontFamily as never
              ] as {
                fontFamily: `${string}, ${FontType}` | FontType;
                display: string;
              };
              if (!font) {
                return {};
              }
              return {
                style: `font-family: ${font.fontFamily}`,
              };
            },
          },
        },
      },
    ];
  },

  addCommands() {
    return {
      setFontFamily:
        (fontFamily: EditorFont) =>
        ({ chain }) =>
          chain().setMark('textStyle', { fontFamily }).run(),
      unsetFontFamily:
        () =>
        ({ chain }) =>
          chain()
            .setMark('textStyle', { fontFamily: null })
            .removeEmptyTextStyle()
            .run(),
    };
  },
});
