import { Icon, Spin } from 'antd';
import React from 'react';

export const AuthenticatingComponent = () => {
  return (
    <div className="flex h-[100vh] w-[100vw] items-center justify-center">
      <Spin
        size="large"
        tip="ログインしています..."
        indicator={<Icon type="loading" spin />}
      />
    </div>
  );
};
