import { H1 } from '../../Common/H1';
import React, { useState } from 'react';
import Tooltip from '../../Common/Tooltip';
import { DefaultButton } from '../../Common/Button';
import { AccountAddModal } from './AccountAddModal';
import { H2 } from '../../Common/H2';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react';
import { LineAccount } from 'lib';
import { message, Modal, Typography } from 'antd';
import { deleteDoc } from 'firebase/firestore';

const { confirm } = Modal;
const { Text } = Typography;

export const LineSettings = observer((): JSX.Element => {
  const [modalVisible, setModalVisible] = useState(false);
  const store = useStore();
  const { lineStore } = store;

  const addDisabled = () =>
    !store.me.isAdmin ||
    lineStore.accounts.length >= store.lineIntegrationCount;

  const onClickDelete = async (account: LineAccount) => {
    confirm({
      title: `LINEアカウント "${account.name}" を削除しますか？`,
      content: (
        <div>
          <div>
            <p>
              <Text type="danger">削除前に下記の2点を必ずご確認下さい。</Text>
            </p>
          </div>
          <div>
            <p>
              <Text strong>
                ①アカウントに紐づくデータがすべて削除されます。
              </Text>
            </p>
            <ul>
              <li>チャット履歴</li>
              <li>アカウント設定</li>
            </ul>
            <p>
              がすべて削除されます。
              <br />
              ※一度削除すると元に戻せません。
            </p>
          </div>

          <div>
            <p>
              <Text strong>②Webhook設定の解除をお願いします。</Text>
            </p>
            <p>
              削除対象のアカウントの
              <Text underline>Webhook設定が解除されているか</Text>
              を今一度ご確認下さい。
            </p>
          </div>
        </div>
      ),
      onOk: async () => {
        await deleteDoc(account.ref);
        message.success('アカウントを削除しました');
      },
      okText: '削除',
      cancelText: 'キャンセル',
      okType: 'danger',
      maskClosable: true,
    });
  };

  return (
    <div className="p-8">
      <H1>LINEアカウント設定</H1>
      <Tooltip
        title={
          store.me.isAdmin
            ? 'アカウント数が上限に達しています'
            : '権限がありません'
        }
        visible={addDisabled()}
      >
        <div className="w-fit">
          <DefaultButton
            type="primary"
            className="!h-10 !w-40"
            disabled={addDisabled()}
            onClick={() => setModalVisible(true)}
          >
            アカウント追加
          </DefaultButton>
        </div>
      </Tooltip>
      <H2 className="!mt-8">LINEアカウント一覧</H2>
      {lineStore.accounts.length ? (
        lineStore.accounts.map((account) => (
          <div key={account.id} className="mt-2 flex items-center">
            <div className="!w-52 overflow-hidden text-ellipsis text-lg">
              {account.name}
            </div>
            <Tooltip title="権限がありません" visible={!store.me.isAdmin}>
              <div className="w-fit">
                <DefaultButton
                  type="danger"
                  className="!ml-3 !h-10 !w-20"
                  onClick={() => onClickDelete(account)}
                  disabled={!store.me.isAdmin}
                >
                  削除
                </DefaultButton>
              </div>
            </Tooltip>
          </div>
        ))
      ) : (
        <div className="mt-4">まだアカウントが追加されていません</div>
      )}
      <AccountAddModal
        visible={modalVisible}
        close={() => setModalVisible(false)}
      />
    </div>
  );
});
