/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Message } from './Message';
import { MessageView } from 'store/messages';
import { Icon, Spin } from 'antd';
import { WrappedInfiniteScroll } from '../../../../../components/WrappedInfiniteScroll';
import { isDeletedPaneLocation } from 'utils/messagePane';
import { getMessageLink } from 'utils/messageListItem';
import { useAtom, useAtomValue } from 'jotai';
import { messagesAtom } from '../../../../../atoms/firestore/message';
import { threadViewAtom } from '../../../../../atoms/auth';
import { useStore } from '../../../../../hooks/useStore';
import { CheckOptions } from './options/CheckOptions';

export const Messages = observer((): JSX.Element => {
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch<{
    teamId?: string;
    inboxId?: string;
    tagId?: string;
  }>();
  const [messages, dispatch] = useAtom(messagesAtom);
  const threadView = useAtomValue(threadViewAtom);
  const { teamId, inboxId, tagId } = match.params;
  const store = useStore();
  const {
    checkedMessages,
    selectedMessageView,
    searchStore: {
      inSearch,
      hasMore: hasMoreMessagesOnSearch,
      searching: searchingMessages,
      sortedMessages: sortedSearchedMessages,
    },
  } = store;

  useEffect(() => {
    return () => {
      store.checkedMessages = [];
    };
  }, []);

  useEffect(() => {
    store.checkedMessages = [];
  }, [teamId, inboxId, tagId]);

  const to = (link?: string) => {
    if (link) {
      history.push(link);
    }
  };

  const toLink = (messageId: string) => {
    return getMessageLink(match, messageId, threadView);
  };

  const isSelectedInboxOrInboxTag = () => {
    return Boolean(
      inboxId || (tagId ? store.getTag(tagId) : undefined)?.isInbox
    );
  };

  const loadMoreOnSearch = async () => {
    await store.searchStore.searchMessages({
      deleted: isDeletedPaneLocation(location),
      withOffset: true,
    });
  };

  const loadMore = async () => {
    if (store.searchStore.inSearch) {
      await loadMoreOnSearch();
    } else {
      await dispatch('loadMore');
    }
  };

  const hasMore = inSearch ? hasMoreMessagesOnSearch : messages.hasMore;
  const loading = inSearch ? searchingMessages : messages.state === 'loading';

  const messageList = inSearch
    ? sortedSearchedMessages
    : messages.state === 'hasData'
    ? messages.data
    : [];

  const hideInboxTag =
    isSelectedInboxOrInboxTag() || isDeletedPaneLocation(location);
  const hideStatusTag = selectedMessageView !== MessageView.All && !inSearch;
  const isReadOnly = store.me.isReadOnly;
  return (
    <WrappedInfiniteScroll
      initialLoad={false}
      pageStart={0}
      loadMore={loadMore}
      hasMore={hasMore}
      useWindow={false}
      data-testid="messageList"
    >
      <div className="sticky top-0 z-10 bg-sumi-50 pt-4">
        <Spin spinning={loading} indicator={<Icon type="loading" spin />}>
          {messageList.length === 0 && loading && (
            <div style={{ height: 100 }} />
          )}

          {!isReadOnly && !loading && checkedMessages.length > 0 && (
            <CheckOptions messages={messageList as never} />
          )}
        </Spin>
      </div>
      {messageList.map((message) => (
        <Message
          message={message}
          isReadOnly={isReadOnly}
          checkMessage={store.checkMessage}
          uncheckMessage={store.uncheckMessage}
          checkedMessages={checkedMessages}
          onClick={() => to(toLink(message.id))}
          to={toLink(message.id)}
          key={message.id}
          me={store.me}
          getTag={store.getTag}
          hideInboxTag={hideInboxTag}
          hideStatusTag={hideStatusTag}
        />
      ))}
    </WrappedInfiniteScroll>
  );
});
