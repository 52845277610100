import { Atom, atom, PrimitiveAtom } from 'jotai';
import {
  DocumentReference,
  DocumentSnapshot,
  FirestoreError,
  onSnapshot,
  Query,
  QuerySnapshot,
  Unsubscribe,
} from 'firebase/firestore';
import { store } from '../providers/StoreProvider';
import { splitAtom } from 'jotai/utils';

export const atomWithSubscribeDoc = <T>(
  ref: () => DocumentReference<T>,
  onNext?: (snapshot: DocumentSnapshot<T>) => T | void
): [Atom<boolean>, Atom<T>] => {
  const loadingAtom = atom(true);
  const writableAtom = atom<T | undefined>(undefined);
  writableAtom.onMount = () => {
    if (isSubscribed(writableAtom)) {
      return;
    }
    const unsubscribe = onSnapshot(ref(), (snapshot) => {
      const data = onNext?.(snapshot);
      if (data) {
        store.set(writableAtom, data);
      } else {
        store.set(writableAtom, snapshot.data());
      }
      store.set(loadingAtom, false);
    });
    registerUnsubscribe(writableAtom, unsubscribe);
  };
  const readonlyAtom = atom((get) => {
    const value = get(writableAtom);
    if (!value) {
      throw new Error('data not loaded.');
    }
    return value;
  });
  return [loadingAtom, readonlyAtom];
};

export const atomWithSubscribeCollection = <T>(
  q: () => Query<T>,
  onNext?: (snapshot: QuerySnapshot<T>) => T[] | void
): [Atom<boolean>, Atom<T[]>, Atom<Atom<T>[]>] => {
  const loadingAtom = atom(true);
  const writableAtom = atom<T[]>([]);
  writableAtom.onMount = () => {
    if (isSubscribed(writableAtom)) {
      return;
    }
    const unsubscribe = onSnapshot(q(), (snapshot) => {
      const data = onNext?.(snapshot);
      if (data) {
        store.set(writableAtom, data);
      } else {
        store.set(
          writableAtom,
          snapshot.docs.map((doc) => doc.data())
        );
      }
      store.set(loadingAtom, false);
    });
    registerUnsubscribe(writableAtom, unsubscribe);
  };
  const readonlyAtom = atom((get) => get(writableAtom));
  return [loadingAtom, readonlyAtom, splitAtom(readonlyAtom)];
};

export const subscribeDoc = <T>(
  anAtom: PrimitiveAtom<T | undefined>,
  ref: DocumentReference<T>,
  onNext?: (snapshot: DocumentSnapshot<T>) => T | void,
  onError?: (error: FirestoreError) => void
): Unsubscribe => {
  const unsubscribe = onSnapshot(
    ref,
    (snapshot) => {
      const data = onNext?.(snapshot);
      if (data) {
        store.set(anAtom, data);
      } else {
        store.set(anAtom, snapshot.data());
      }
    },
    onError
  );

  return registerUnsubscribe(anAtom, unsubscribe);
};

export const subscribeCollection = <T>(
  anAtom: PrimitiveAtom<T[]>,
  q: Query<T>,
  onNext?: (snapshot: QuerySnapshot<T>) => T[] | void,
  onError?: (error: FirestoreError) => void
): Unsubscribe => {
  const unsubscribe = onSnapshot(
    q,
    (snapshot) => {
      const data = onNext?.(snapshot);
      if (data) {
        store.set(anAtom, data);
      } else {
        store.set(
          anAtom,
          snapshot.docs.map((doc) => doc.data())
        );
      }
    },
    onError
  );

  return registerUnsubscribe(anAtom, unsubscribe);
};

const unsubscribesAtom = atom<Map<unknown, Unsubscribe>>(new Map());

export const isSubscribed = (key: unknown) => {
  return store.get(unsubscribesAtom).has(key);
};

export const registerUnsubscribe = (
  key: unknown,
  unsubscribe: Unsubscribe
): Unsubscribe => {
  store.get(unsubscribesAtom).get(key)?.();
  store.set(unsubscribesAtom, (prev) => {
    prev.set(key, unsubscribe);
    return new Map(prev);
  });
  return () => {
    unsubscribe();
    store.get(unsubscribesAtom).delete(key);
  };
};

export const unsubscribe = (key: unknown) => {
  const unsubscribes = store.get(unsubscribesAtom);
  unsubscribes.get(key)?.();
  unsubscribes.delete(key);
};

export const unsubscribeAll = () => {
  store.get(unsubscribesAtom).forEach((unsubscribe) => unsubscribe());
  store.set(unsubscribesAtom, new Map());
};
