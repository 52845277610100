import {
  MessageLike,
  messageLikeConverter,
  starConverter,
  starredThreadConverter,
  StarredThreads,
} from 'lib';
import {
  companyCollection,
  companyDoc,
  registerUnsubscribe,
  unsubscribe,
} from '../../../firestore';
import {
  DocumentSnapshot,
  getDoc,
  onSnapshot,
  orderBy,
  query,
} from 'firebase/firestore';
import { store } from '../../../providers/StoreProvider';
import { meAtom, threadViewAtom } from '../../auth';
import { atom } from 'jotai';
import { omit, take } from 'lodash';
import { joinedTeamIdsAtom } from '../team';
import {
  atomWithPaginate,
  LoadingPaginate,
  Paginate,
  PaginateAtom,
} from '../../../utils/atom';
import * as Sentry from '@sentry/react';

export const starredMessagesPaginateAtom: PaginateAtom<MessageLike> = atom(
  (get) => {
    const threadView = get(threadViewAtom);
    const anAtom = threadView
      ? getStarredThreadsPaginateAtom()
      : starredNonThreadMessagesPaginateAtom;
    return get(anAtom);
  },
  async (get, set, args) => {
    const threadView = get(threadViewAtom);
    const anAtom = threadView
      ? getStarredThreadsPaginateAtom()
      : starredNonThreadMessagesPaginateAtom;
    await set(anAtom, args);
  }
);

const cachedStarredMessagesAtom = atom<Record<string, MessageLike>>({});

const starredNonThreadMessagesPaginateAtom: PaginateAtom<MessageLike> =
  atomWithPaginate(
    () =>
      query(
        companyCollection(`users/${store.get(meAtom).id}/stars`, starConverter),
        orderBy('messageDate', 'asc')
      ),
    async (snapshot) => {
      const starsData = snapshot.docs.map((doc) => doc.data());
      const cachedStarredMessages = store.get(cachedStarredMessagesAtom);

      const messages: MessageLike[] = [];
      for (const starData of starsData) {
        if (starData.messageId in cachedStarredMessages) {
          messages.push(cachedStarredMessages[starData.messageId]);
          continue;
        }

        const message = (
          await getDoc(
            companyDoc('messages', starData.messageId, messageLikeConverter)
          )
        ).data();

        if (!message) {
          continue;
        }

        messages.push(message);
        store.set(cachedStarredMessagesAtom, (prev) => ({
          ...prev,
          [message.id]: message,
        }));
      }

      return messages;
    }
  );

const THREADS_PER_PAGE = 40;

const createStarredThreadsPaginateAtom = (): PaginateAtom<MessageLike> => {
  const ref = companyDoc(
    `users/${store.get(meAtom).id}/private`,
    'starredThreads',
    starredThreadConverter
  );
  const limitAtom = atom(THREADS_PER_PAGE);
  const starredThreadsAtom = atom<StarredThreads | undefined>(undefined);
  const dataAtom = atom<Paginate<MessageLike> | undefined>(undefined);
  const fetchThreads = async (threadIds: string[]): Promise<MessageLike[]> => {
    const promises = threadIds.map(async (id) => {
      const cachedStarredMessages = store.get(cachedStarredMessagesAtom);
      if (id in cachedStarredMessages) {
        return cachedStarredMessages[id];
      }
      const doc = await getDoc(companyDoc('threads', id, messageLikeConverter))
        .then((r) => r.data())
        .catch(() => null);
      if (doc != null) {
        cachedStarredMessages[id] = doc;
      }
      return doc;
    });

    return (await Promise.all(promises))
      .filter((d) => d)
      .map((d) => d!)
      .filter((d) => !d.deleted);
  };
  const subscribe = (limitNum: number) => {
    const unsub = onSnapshot(
      ref,
      async (snapshot: DocumentSnapshot<StarredThreads>) => {
        if (snapshot.exists()) {
          const ids = getSortedIds(snapshot.data());
          store.set(starredThreadsAtom, snapshot.data());
          store.set(dataAtom, {
            state: 'hasData',
            data: await fetchThreads(take(ids, limitNum)),
            currentLimit: limitNum,
            hasMore: limitNum < ids.length,
          });
        } else {
          store.set(dataAtom, {
            state: 'hasError',
            error: 'データがありません',
            hasMore: false,
          });
        }
      },
      (error) => {
        Sentry.captureException(error);
        store.set(dataAtom, {
          state: 'hasError',
          error,
          hasMore: false,
        });
      }
    );
    registerUnsubscribe(dataAtom, unsub);
  };
  return atom(
    (get) => {
      const data = get(dataAtom);
      const currentLimit = get(limitAtom);
      if (data) {
        if (data.state === 'hasData' && data.currentLimit !== currentLimit) {
          subscribe(currentLimit);
        }
        return data;
      }

      subscribe(THREADS_PER_PAGE);
      store.set(dataAtom, LoadingPaginate as Paginate<MessageLike>);
      return LoadingPaginate as Paginate<MessageLike>;
    },
    async (_get, set, action) => {
      switch (action) {
        case 'reset':
          set(limitAtom, THREADS_PER_PAGE);
          break;
        case 'loadMore':
          set(limitAtom, (prev) => prev + THREADS_PER_PAGE);
          break;
        case 'unsubscribe':
          unsubscribe(dataAtom);
      }
    }
  );
};

let starredThreadsPaginateAtom: PaginateAtom<MessageLike> | undefined;

const getStarredThreadsPaginateAtom = (): PaginateAtom<MessageLike> => {
  if (starredThreadsPaginateAtom) {
    return starredThreadsPaginateAtom;
  }
  const anAtom = createStarredThreadsPaginateAtom();
  starredThreadsPaginateAtom = anAtom;
  return anAtom;
};

const getSortedIds = (starredThreads: StarredThreads): string[] => {
  const joinedTeamIds = store.get(joinedTeamIdsAtom);

  return Object.entries(omit(starredThreads, 'updatedAt'))
    .filter(([, data]) => joinedTeamIds.includes(data.teamId))
    .sort(
      ([, data1], [, data2]) => data2.date.toMillis() - data1.date.toMillis()
    )
    .map(([id]) => id);
};
