import { RecordType } from 'store/salesforce';
import { ReactComponent as ExternalIcon } from 'assets/icons/external.svg';
import { ReactNode } from 'react';

interface Props {
  classic: boolean;
  instanceUrl: string;
  record: RecordType;
  id: string;
  children?: ReactNode;
}

export const SalesforceLink = ({
  classic,
  instanceUrl,
  record,
  id,
  children,
}: Props): JSX.Element => {
  children = children || <ExternalIcon style={{ height: '1rem' }} />;
  const url = classic
    ? `${instanceUrl}/${id}`
    : `${instanceUrl}/lightning/r/${record}/${id}/view`;
  return (
    <a href={url} target="_blank" rel="noreferrer">
      {children}
    </a>
  );
};
