import { estimateServerTimestampsOption } from './common';
import firebase from 'firebase/compat/app';

export interface LockData {
  messageId: string;
  locker: string;
  createdAt: firebase.firestore.Timestamp;
  updatedAt: firebase.firestore.Timestamp;
}

export class Lock {
  private _id: string;
  private _data: LockData;
  private _teamId: string;
  private _ref: firebase.firestore.DocumentReference<LockData>;

  constructor(
    doc: firebase.firestore.DocumentSnapshot<LockData>,
    teamId: string
  ) {
    this._id = doc.id;
    const data = doc.data(estimateServerTimestampsOption);
    if (!data) {
      throw new Error(`no data for lock#${doc.id}`);
    }
    this._data = data;
    this._teamId = teamId;
    this._ref = doc.ref;
  }

  get id() {
    return this._id;
  }

  get teamId() {
    return this._teamId;
  }

  get ref() {
    return this._ref;
  }

  get messageId() {
    return this._data.messageId;
  }

  get locker() {
    return this._data.locker;
  }

  get createdAt() {
    return this._data.createdAt;
  }

  get updatedAt() {
    return this._data.updatedAt;
  }
}
