import * as Toolbar from '@radix-ui/react-toolbar';
import { Icon } from '../../../../components/basics';
import {
  AddColumnLeft,
  AddColumnRight,
  AddLink,
  AddPhotoAlternate,
  AddRowAbove,
  AddRowBelow,
  Close,
  Delete,
  FormatAlignCenter,
  FormatAlignLeft,
  FormatAlignRight,
  FormatBold,
  FormatClear,
  FormatItalic,
  FormatListBulleted,
  FormatListNumbered,
  FormatQuote,
  FormatStrikethrough,
  FormatUnderlined,
  More,
  Table,
  Title,
  Html,
  TextFields,
} from '../../../../components/icons';
import { tv } from 'tailwind-variants';
import React, {
  ComponentProps,
  ComponentType,
  SVGProps,
  useMemo,
  useState,
} from 'react';
import { range, uniq } from 'lodash';
import { Tooltip } from '../../../../components/basics/Tooltip/Tooltip';
import { atom, useAtomValue, useSetAtom } from 'jotai';
import { ToolbarSelect } from '../ToolbarSelect/ToolbarSelect';
import { EDITOR_FONTS, EditorFont } from '../extension/fontFamily';
import { ToolbarColorPalette } from '../ToolbarColorPalette/ToolbarColorPalette';
import { EDITOR_DEFAULT_FONT_SIZE } from '../extension/fontSize';
import { ToolbarTextColorIcon } from './ToolbarTextColorIcon';
import { ToolbarBackgroundColorIcon } from './ToolbarBackgroundColorIcon';
import * as Popover from '@radix-ui/react-popover';
import * as Portal from '@radix-ui/react-portal';
import { ToolbarTablePopover } from '../ToolbarTablePopover/ToolbarTablePopover';
import SimpleBar from 'simplebar-react';

const tooltipCountAtom = atom(0);
const changeCountAtom = atom(null, (get, set, action: 'inc' | 'dec') => {
  if (action === 'inc') {
    set(tooltipCountAtom, get(tooltipCountAtom) + 1);
  } else if (action === 'dec') {
    set(tooltipCountAtom, get(tooltipCountAtom) - 1);
  }
});

export type EditorTextAlign = 'left' | 'center' | 'right';
export type EditorList = 'bulleted' | 'numbered';

type SetType<T> = {
  value: T;
  canSet: () => boolean;
  set: (value: T) => void;
};

type FunctionType = {
  canSet: () => boolean;
  set: () => void;
};

type TableRowColumn = {
  canAddBefore: () => boolean;
  canAddAfter: () => boolean;
  canDelete: () => boolean;
  add: (pos: 'before' | 'after') => void;
  delete: () => void;
};

type LinkType = {
  value: string | undefined;
  canSet: () => boolean;
  openInput: () => void;
};

type ImageType = {
  canSet: () => boolean;
  openInput: () => void;
};

type TableType = {
  value: boolean;
  canInsert: () => boolean;
  insert: (rows: number, cols: number) => void;
};

type TableHeaderType = {
  value: boolean;
  canToggle: () => boolean;
  toggle: () => void;
};

type Props = {
  fontFamily: SetType<EditorFont | undefined>;
  fontSize: SetType<number | undefined>;
  textColor: SetType<string | undefined>;
  backgroundColor: SetType<string | undefined>;
  bold: SetType<boolean>;
  italic: SetType<boolean>;
  underline: SetType<boolean>;
  strikethrough: SetType<boolean>;
  blockquote: SetType<boolean>;
  textAlign: SetType<EditorTextAlign>;
  list: SetType<EditorList | undefined>;
  link: LinkType;
  clearTextStyles: FunctionType;
  image: ImageType;
  hideImage?: boolean;
  table: TableType;
  tableHeader: TableHeaderType;
  row: TableRowColumn;
  column: TableRowColumn;
  plaintextMode?: {
    value: boolean;
    onChange: (value: boolean) => void;
  };
  closable?: boolean;
  onClose?: () => void;
  onOpenChangeDropdown?: (open: boolean) => void;
  disabled?: boolean;
};

const wrapper = tv({
  variants: {
    closable: {
      true: 'grid grid-cols-[1fr_auto] items-center gap-1 text-black',
    },
  },
});

const button = tv({
  base:
    'flex h-6 w-6 min-w-[theme(width.6)] cursor-pointer items-center justify-center rounded p-0 focus-visible:outline-1 focus-visible:outline-sea-500/50 disabled:cursor-not-allowed disabled:text-sumi-500 ' +
    'bg-transparent enabled:data-[state=off]:hover:bg-sumi-100 ' +
    'data-[state=on]:bg-sumi-200 enabled:data-[state=on]:hover:bg-sumi-300',
});

const separator = tv({
  base: 'h-6 w-[1px] bg-sumi-200',
});

export const EditorToolbar = ({
  fontFamily,
  fontSize,
  textColor,
  backgroundColor,
  bold,
  italic,
  underline,
  strikethrough,
  blockquote,
  textAlign,
  list,
  link,
  clearTextStyles,
  image,
  hideImage,
  table,
  tableHeader,
  row,
  column,
  plaintextMode,
  closable = true,
  onClose,
  onOpenChangeDropdown,
  disabled,
}: Props) => {
  const [container, setContainer] = useState<HTMLDivElement | null>(null);
  const formatValues = useMemo(() => {
    const arr: string[] = [];
    if (bold.value) {
      arr.push('bold');
    }
    if (italic.value) {
      arr.push('italic');
    }
    if (underline.value) {
      arr.push('underline');
    }
    if (strikethrough.value) {
      arr.push('strikethrough');
    }
    return arr;
  }, [bold.value, italic.value, underline.value, strikethrough.value]);
  const onFormatChange = (newValue: string[]) => {
    const unique = uniq([...formatValues, ...newValue]);
    if (unique.includes('bold')) {
      bold.set(newValue.includes('bold'));
    }
    if (unique.includes('italic')) {
      italic.set(newValue.includes('italic'));
    }
    if (unique.includes('underline')) {
      underline.set(newValue.includes('underline'));
    }
    if (unique.includes('strikethrough')) {
      strikethrough.set(newValue.includes('strikethrough'));
    }
  };
  return (
    <div className={wrapper({ closable })}>
      <Portal.Root className="relative z-[1001]" ref={setContainer} />
      <SimpleBar className="overflow-x-auto overflow-y-hidden">
        <Toolbar.Root className="flex w-full items-center gap-2">
          <FontFamilySelect
            fontFamily={fontFamily}
            disabled={disabled || !fontFamily.canSet()}
          />
          <ToolbarSelect
            value={fontSize.value}
            onChange={fontSize.set}
            options={range(10, 33).map((i) => ({
              value: i === EDITOR_DEFAULT_FONT_SIZE ? undefined : i,
              label: `${i}px`,
            }))}
            disabled={disabled || !fontSize.canSet()}
            className="min-w-[60px]"
          />
          <ToolbarColorPalette
            iconRender={(color) => <ToolbarTextColorIcon color={color} />}
            color={textColor.value}
            defaultColor="#000000"
            setColor={textColor.set}
            disabled={disabled || !textColor.canSet()}
          />
          <ToolbarColorPalette
            iconRender={(color) => <ToolbarBackgroundColorIcon color={color} />}
            color={backgroundColor.value}
            defaultColor="#ffffff"
            placeholderColor="#ccc"
            setColor={backgroundColor.set}
            disabled={disabled || !backgroundColor.canSet()}
          />
          <Toolbar.ToggleGroup
            type="multiple"
            className="flex gap-1"
            value={formatValues}
            onValueChange={onFormatChange}
          >
            <TextDecorationButton
              icon={FormatBold}
              type={bold}
              value="bold"
              label="太字"
              disabled={disabled}
            />
            <TextDecorationButton
              icon={FormatItalic}
              type={italic}
              value="italic"
              label="斜体"
              disabled={disabled}
            />
            <TextDecorationButton
              icon={FormatUnderlined}
              type={underline}
              value="underline"
              label="下線"
              disabled={disabled}
            />
            <TextDecorationButton
              icon={FormatStrikethrough}
              type={strikethrough}
              value="strikethrough"
              label="打ち消し"
              disabled={disabled}
            />
            <ToolbarTooltip content="リンクを挿入">
              <Toolbar.Button
                onClick={() => link.openInput()}
                className={button()}
                disabled={disabled || !link.canSet()}
                aria-label="リンクを挿入"
                data-state={link.value ? 'on' : 'off'}
              >
                <Icon icon={AddLink} size={20} />
              </Toolbar.Button>
            </ToolbarTooltip>
            <ToolbarTooltip content="書式をリセット">
              <Toolbar.Button
                onClick={() => clearTextStyles.set()}
                className={button()}
                disabled={disabled || !clearTextStyles.canSet()}
                aria-label="書式をリセット"
                data-state="off"
              >
                <Icon icon={FormatClear} size={20} />
              </Toolbar.Button>
            </ToolbarTooltip>
            {table.value ? (
              <Popover.Root
                onOpenChange={(open) => onOpenChangeDropdown?.(open)}
              >
                <ToolbarTooltip content="テーブルを編集">
                  <Popover.Trigger asChild>
                    <Toolbar.Button
                      className={button()}
                      disabled={disabled}
                      data-state="on"
                    >
                      <Icon icon={Table} size={20} />
                    </Toolbar.Button>
                  </Popover.Trigger>
                </ToolbarTooltip>
                <Popover.Portal container={container}>
                  <Popover.Content
                    collisionPadding={16}
                    className="z-50 overflow-hidden rounded-lg bg-white p-1.5 shadow-dropdown"
                    align="end"
                    sideOffset={5}
                  >
                    <Toolbar.Root className="flex flex-nowrap gap-2">
                      <Toolbar.ToggleGroup
                        type="single"
                        className="flex gap-2"
                        value={undefined}
                        onValueChange={(v: 'delete' | 'before' | 'after') => {
                          switch (v) {
                            case 'delete':
                              row.delete();
                              break;
                            default:
                              row.add(v);
                              break;
                          }
                        }}
                        disabled={disabled}
                      >
                        <ToolbarToggleButton
                          icon={Delete}
                          value="delete"
                          label="行を削除"
                          active={false}
                          disabled={!row.canDelete()}
                        />
                        <ToolbarToggleButton
                          icon={AddRowAbove}
                          value="before"
                          label="上に行を追加"
                          active={false}
                          disabled={!row.canAddBefore()}
                        />
                        <ToolbarToggleButton
                          icon={AddRowBelow}
                          value="after"
                          label="下に行を追加"
                          active={false}
                          disabled={!row.canAddAfter()}
                        />
                      </Toolbar.ToggleGroup>
                      <Toolbar.Separator className={separator()} />
                      <Toolbar.ToggleGroup
                        type="single"
                        className="flex gap-2"
                        value={undefined}
                        onValueChange={(v: 'delete' | 'before' | 'after') => {
                          switch (v) {
                            case 'delete':
                              column.delete();
                              break;
                            default:
                              column.add(v);
                              break;
                          }
                        }}
                        disabled={disabled}
                      >
                        <ToolbarToggleButton
                          icon={Delete}
                          value="delete"
                          label="列を削除"
                          active={false}
                          disabled={!column.canDelete()}
                        />
                        <ToolbarToggleButton
                          icon={AddColumnLeft}
                          value="before"
                          label="左に列を追加"
                          active={false}
                          disabled={!column.canAddBefore()}
                        />
                        <ToolbarToggleButton
                          icon={AddColumnRight}
                          value="after"
                          label="右に列を追加"
                          active={false}
                          disabled={!column.canAddAfter()}
                        />
                      </Toolbar.ToggleGroup>
                      <Toolbar.Separator className={separator()} />
                      <Toolbar.ToggleGroup
                        type="single"
                        className="flex gap-2"
                        value={tableHeader ? 'true' : undefined}
                        onValueChange={() => tableHeader.toggle()}
                        disabled={disabled || !tableHeader.canToggle()}
                      >
                        <ToolbarToggleButton
                          icon={Title}
                          value="true"
                          label="見出し切り替え"
                          active={tableHeader.value}
                        />
                      </Toolbar.ToggleGroup>
                    </Toolbar.Root>
                  </Popover.Content>
                </Popover.Portal>
              </Popover.Root>
            ) : (
              <ToolbarTablePopover
                onInsert={(rows, cols) => table.insert(rows, cols)}
                button={
                  <ToolbarTooltip content="表を追加">
                    <Popover.Trigger asChild>
                      <Toolbar.Button
                        className={button()}
                        disabled={disabled || !table.canInsert()}
                        aria-label="表を追加"
                        data-state="off"
                      >
                        <Icon icon={Table} size={20} />
                      </Toolbar.Button>
                    </Popover.Trigger>
                  </ToolbarTooltip>
                }
              />
            )}
          </Toolbar.ToggleGroup>
          <Popover.Root onOpenChange={(open) => onOpenChangeDropdown?.(open)}>
            <Popover.Trigger asChild>
              <Toolbar.Button
                className={button()}
                disabled={disabled && !plaintextMode}
                data-state="off"
              >
                <Icon icon={More} size={20} />
              </Toolbar.Button>
            </Popover.Trigger>
            <Popover.Portal container={container}>
              <Popover.Content
                collisionPadding={16}
                className="z-50 overflow-hidden rounded-lg bg-white p-1.5 shadow-dropdown"
                align="end"
                sideOffset={5}
              >
                <Toolbar.Root className="flex flex-nowrap gap-2">
                  {plaintextMode && (
                    <>
                      <Toolbar.ToggleGroup
                        type="single"
                        value={plaintextMode.value ? 'true' : undefined}
                        onValueChange={(v) => plaintextMode.onChange(!!v)}
                      >
                        <ToolbarToggleButton
                          icon={plaintextMode.value ? Html : TextFields}
                          value="true"
                          label={
                            plaintextMode.value
                              ? 'プレーンテキストモードを オフ にする'
                              : 'プレーンテキストモードを オン にする'
                          }
                          active={plaintextMode.value}
                        />
                      </Toolbar.ToggleGroup>
                      <Toolbar.Separator className={separator()} />
                    </>
                  )}
                  <Toolbar.ToggleGroup
                    type="single"
                    className="flex gap-2"
                    value={textAlign.value}
                    onValueChange={(v: EditorTextAlign | '') => {
                      if (v) {
                        textAlign.set(v);
                      }
                    }}
                    disabled={disabled || !textAlign.canSet()}
                  >
                    <ToolbarToggleButton
                      icon={FormatAlignLeft}
                      value="left"
                      label="左寄せ"
                      active={textAlign.value === 'left'}
                    />
                    <ToolbarToggleButton
                      icon={FormatAlignCenter}
                      value="center"
                      label="中央寄せ"
                      active={textAlign.value === 'center'}
                    />
                    <ToolbarToggleButton
                      icon={FormatAlignRight}
                      value="right"
                      label="右寄せ"
                      active={textAlign.value === 'right'}
                    />
                  </Toolbar.ToggleGroup>
                  <Toolbar.Separator className={separator()} />
                  <Toolbar.ToggleGroup
                    type="single"
                    className="flex gap-2"
                    value={list.value}
                    onValueChange={(v: EditorList | '') =>
                      list.set(v ? v : undefined)
                    }
                    disabled={disabled || !list.canSet()}
                  >
                    <ToolbarToggleButton
                      icon={FormatListBulleted}
                      value="bulleted"
                      label="箇条書きリスト"
                      active={list.value === 'bulleted'}
                    />
                    <ToolbarToggleButton
                      icon={FormatListNumbered}
                      value="numbered"
                      label="番号付きリスト"
                      active={list.value === 'numbered'}
                    />
                  </Toolbar.ToggleGroup>
                  <Toolbar.Separator className={separator()} />
                  {!hideImage && (
                    <ToolbarTooltip content="画像を挿入">
                      <Toolbar.Button
                        onClick={() => image.openInput()}
                        className={button()}
                        disabled={disabled || !image.canSet()}
                        data-state="off"
                      >
                        <Icon icon={AddPhotoAlternate} size={20} />
                      </Toolbar.Button>
                    </ToolbarTooltip>
                  )}
                  <Toolbar.ToggleGroup
                    type="single"
                    className="flex gap-2"
                    value={blockquote.value ? 'true' : undefined}
                    onValueChange={(v) => blockquote.set(!!v)}
                    disabled={disabled || !blockquote.canSet()}
                  >
                    <ToolbarToggleButton
                      icon={FormatQuote}
                      value="true"
                      label="引用"
                      active={blockquote.value}
                    />
                  </Toolbar.ToggleGroup>
                </Toolbar.Root>
              </Popover.Content>
            </Popover.Portal>
          </Popover.Root>
        </Toolbar.Root>
      </SimpleBar>
      {closable && (
        <ToolbarTooltip content="閉じる">
          <button
            onClick={() => onClose?.()}
            className={button()}
            aria-label="閉じる"
            data-state="off"
          >
            <Icon icon={Close} size={12} />
          </button>
        </ToolbarTooltip>
      )}
    </div>
  );
};

type ToolbarToggleButtonProps = {
  icon: ComponentType<SVGProps<SVGSVGElement>>;
  value: string;
  label: string;
  active: boolean;
  disabled?: boolean;
};

const ToolbarToggleButton = ({
  icon,
  value,
  label,
  active,
  disabled,
}: ToolbarToggleButtonProps) => {
  return (
    <ToolbarTooltip content={label}>
      <Toolbar.ToggleItem
        className={button()}
        value={value}
        disabled={disabled}
        aria-label={label}
        data-state={active ? 'on' : 'off'}
      >
        <Icon icon={icon} size={20} />
      </Toolbar.ToggleItem>
    </ToolbarTooltip>
  );
};

const ToolbarTooltip = ({
  children,
  ...props
}: ComponentProps<typeof Tooltip>) => {
  const count = useAtomValue(tooltipCountAtom);
  const change = useSetAtom(changeCountAtom);
  return (
    <Tooltip
      {...props}
      delayDuration={count > 0 ? 0 : undefined}
      onOpenChange={(open) => change(open ? 'inc' : 'dec')}
    >
      {children}
    </Tooltip>
  );
};

const TextDecorationButton = ({
  disabled,
  type,
  ...props
}: Pick<ToolbarToggleButtonProps, 'icon' | 'value' | 'label' | 'disabled'> & {
  type: SetType<boolean>;
}) => {
  return (
    <ToolbarToggleButton
      {...props}
      disabled={disabled || !type.canSet}
      active={type.value}
    />
  );
};

const FontFamilySelect = ({
  fontFamily,
  disabled,
}: Pick<Props, 'fontFamily' | 'disabled'>) => {
  return (
    <ToolbarSelect
      className="w-full min-w-[64px] max-w-[110px]"
      value={fontFamily.value}
      onChange={fontFamily.set}
      options={[
        { value: undefined, label: '' },
        ...Object.entries(EDITOR_FONTS)
          .sort(([, a], [, b]) => a.display.localeCompare(b.display))
          .map(([k, v]) => ({
            value: k as EditorFont,
            label: v.display,
          })),
      ]}
      labelRenderer={(v) => {
        if (!v) {
          return <span>デフォルト</span>;
        }
        const font = EDITOR_FONTS[v as EditorFont];
        return (
          <span style={{ fontFamily: font?.fontFamily }}>{font?.display}</span>
        );
      }}
      disabled={disabled}
    />
  );
};
