import * as React from 'react';
import type { SVGProps } from 'react';
const SvgLock = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M4.584 18.334c-.344 0-.638-.123-.883-.368a1.204 1.204 0 0 1-.367-.883V8.043c0-.344.122-.638.367-.883s.54-.367.883-.367h1.458v-2c0-1.095.386-2.029 1.159-2.8.772-.773 1.706-1.159 2.802-1.159s2.029.386 2.8 1.158c.77.772 1.156 1.706 1.156 2.8v2h1.458c.344 0 .638.123.883.368s.367.54.367.883v9.042c0 .343-.122.637-.367.882a1.204 1.204 0 0 1-.883.367H4.584Zm0-1.25h10.833V8.042H4.584v9.042Zm5.42-2.917c.442 0 .82-.153 1.132-.46a1.49 1.49 0 0 0 .469-1.103c0-.416-.158-.795-.472-1.135-.315-.34-.694-.51-1.136-.51-.442 0-.82.17-1.132.51-.312.34-.469.722-.469 1.146 0 .423.158.788.473 1.094.315.305.693.458 1.135.458ZM7.292 6.792h5.417v-2c0-.752-.263-1.392-.79-1.919a2.608 2.608 0 0 0-1.916-.79c-.752 0-1.391.264-1.919.79a2.609 2.609 0 0 0-.792 1.919v2Z"
    />
  </svg>
);
export default SvgLock;
