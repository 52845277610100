import { useCallback, useMemo } from 'react';
import {
  DropEvent,
  DropzoneOptions,
  DropzoneState,
  FileRejection,
  useDropzone,
} from 'react-dropzone';
import { UploadOptions, UploadState } from './types';
import { useUpload } from './useUpload';

export type UploadWithDropzoneOptions = UploadOptions & DropzoneOptions;

export type UploadWithDropzoneState = UploadState & DropzoneState;

export const useUploadWithDropzone = ({
  uploadPath,
  validate,
  metadata,
  onUpload,
  onRemove,
  ...dropzoneOptions
}: UploadWithDropzoneOptions): UploadWithDropzoneState => {
  const uploadState = useUpload({
    uploadPath,
    validate,
    metadata,
    onUpload,
    onRemove,
  });

  const onDrop = useCallback(
    async (
      acceptedFiles: File[],
      fileRejections: FileRejection[],
      event: DropEvent
    ) => {
      await Promise.allSettled(
        acceptedFiles.map((file) => uploadState.upload(file))
      );
      dropzoneOptions?.onDrop?.(acceptedFiles, fileRejections, event);
    },
    [uploadState.upload, dropzoneOptions?.onDrop]
  );

  const dropzoneState = useDropzone({
    ...dropzoneOptions,
    onDrop,
  });

  return useMemo(
    () => ({
      ...uploadState,
      ...dropzoneState,
      open: () => dropzoneState.inputRef.current?.click(),
    }),
    [uploadState, dropzoneState]
  );
};
