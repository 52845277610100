// antd v3 の型定義があまりメンテされていないためビルドエラーを回避するため型定義上書き

import React from 'react';
import Button, { ButtonProps } from 'antd/lib/button';
import Dropdown, { DropDownProps } from 'antd/lib/dropdown';
import Icon, { IconProps } from 'antd/lib/icon';
import Menu, { MenuProps } from 'antd/lib/menu';
import Popover, { PopoverProps } from 'antd/lib/popover';
import Select, {
  SelectProps,
  OptionProps,
  OptGroupProps,
} from 'antd/lib/select';
import Tooltip, { TooltipProps } from 'antd/lib/tooltip';

type WithChildren = { children: React.ReactNode };

export const CmpButton = Button as any as React.FC<ButtonProps & WithChildren>;
export const CmpDropdown = Dropdown as any as React.FC<
  DropDownProps & WithChildren
>;
export const CmpMenu = Menu as any as React.FC<MenuProps & WithChildren>;
export const CmpIcon = Icon as any as React.FC<IconProps>;
export const CmpPopover = Popover as any as React.FC<PopoverProps>;
export const CmpSelect = Select as any as React.FC<SelectProps & WithChildren>;
export const CmpOption = Select.Option as any as React.FC<
  OptionProps & WithChildren
>;
export const CmpOptGroup = Select.OptGroup as any as React.FC<
  OptGroupProps & WithChildren
>;
export const CmpTooltip = Tooltip as any as React.FC<
  TooltipProps & WithChildren
>;
