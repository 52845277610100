import React from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import media from 'styled-media-query';
import bg1 from '../../assets/bg/bg1.png';
import * as color from '../../color';
import { DefaultInput } from '../Common/Input';
import { DefaultButton } from '../Common/Button';
import { Footer } from '../Common/Footer';
import firebase from '../../firebase';
import { Modal } from 'antd';
import * as Sentry from '@sentry/react';

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      isSendingPasswordResetEmail: false,
    };
  }

  submit = async (e) => {
    e.preventDefault();
    this.setState({ errorMessage: '', isSendingPasswordResetEmail: true });

    const auth = firebase.auth();
    const { email } = this.state;

    try {
      await auth.sendPasswordResetEmail(email);
      Modal.success({
        title: 'パスワードの再設定リンクが送信されました。',
        content: `${email} をご確認ください。`,
      });
    } catch (e) {
      console.error('ResetPassword.submit:', e);
      // "auth/user-not-found"
      const { code } = e;
      let errorMessage;
      switch (code) {
        case 'auth/user-not-found':
          errorMessage = '※登録されていないメールアドレスです';
          break;
        case 'auth/too-many-requests':
          errorMessage =
            '※リクエスト回数が上限を超えました。時間をおいてから再度お試し下さい。';
          break;
        default:
          Sentry.captureMessage(e);
          errorMessage = '予期せぬエラーが発生しました';
      }
      this.setState({ errorMessage, isSendingPasswordResetEmail: false });
    }
    this.setState({ email: '', isSendingPasswordResetEmail: false });
  };

  onChangeEmail = (e) => this.setState({ email: e.target.value });

  toSignIn = () => this.props.history.push('/signIn');

  render() {
    const { isSendingPasswordResetEmail, email, errorMessage } = this.state;

    return (
      <Wrapper>
        <Left />
        <Right>
          <div />
          <Content>
            <RegisterWrapper>
              <form onSubmit={this.submit}>
                {errorMessage !== '' && (
                  <span style={{ color: 'red' }}>{errorMessage}</span>
                )}
                <InputWrapper>
                  <InputLabel>パスワード再設定用メールアドレス</InputLabel>
                  <Input
                    value={email}
                    onChange={this.onChangeEmail}
                    placeholder="yohei.tsubuku@example.com"
                    type="email"
                    disabled={isSendingPasswordResetEmail}
                    required
                  />
                </InputWrapper>
                <RegisterButton
                  type="submit"
                  disabled={isSendingPasswordResetEmail}
                >
                  パスワードを再設定
                </RegisterButton>
                <ToLoginLink onClick={this.toSignIn}>
                  ログインする場合はこちら
                </ToLoginLink>
              </form>
            </RegisterWrapper>
          </Content>
          <Footer />
        </Right>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  ${media.lessThan('medium')`
    display: block;
  `}
`;

const Left = styled.div`
  width: 50%;
  background-image: url(${bg1});
  background-size: cover;
`;

const Right = styled.div`
  width: 50%;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  ${media.lessThan('medium')`
    width: 100%;
    height: 100%;
  `}
`;

const Content = styled.div``;

const RegisterWrapper = styled.div`
  padding-bottom: 50px;
`;

const InputWrapper = styled.div`
  position: relative;
  margin-bottom: 20px;
`;

const Input = styled(DefaultInput)`
  height: 40px;
  width: 400px;
  max-width: calc(100vw - 60px);
`;

const InputLabel = styled.label`
  display: block;
  padding-bottom: 1em;
`;

const RegisterButton = styled(DefaultButton)`
  height: 50px;
  width: 100%;
  max-width: 400px;
  margin-top: 20px;
`;

const ToLoginLink = styled.a`
  display: block;
  margin-top: 20px;
  color: ${color.a.text.normal};
  font-size: 15px;
  text-decoration: underline;
`;

export default compose(withRouter, inject('store'), observer)(ResetPassword);
