import { BaseMessagePane } from './BaseMessagePane';
import Mentioned from '../MessageList/Me/Mentioned';
import { MessageListEmpty } from 'components/MessageList';
import { useAtomValue } from 'jotai';
import { mentionedMessagesAtom } from '../../../atoms/firestore/mention';

export const PATH = '/me/mentioned';

// 自分 - メンション
export const MessagePaneMeMentioned: React.FC = () => {
  const mentionedMessagesPaginate = useAtomValue(mentionedMessagesAtom);

  const isEmpty =
    mentionedMessagesPaginate.state === 'hasData' &&
    mentionedMessagesPaginate.data.length === 0;

  return (
    <BaseMessagePane
      listPagePath={PATH}
      empty={isEmpty}
      emptyComponent={<MessageListEmpty description="メンションはありません" />}
      listComponent={<Mentioned />}
    />
  );
};
