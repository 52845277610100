import { FirestoreDataConverter, Timestamp } from 'firebase/firestore';
import { createConverter, createEntityFactory } from '../converter';

const backgroundColors = ['#f56a00', '#7265e6', '#ffbf00', '#00a2ae'];

export interface UserData {
  iconBackgroundColorNumber: number;
  notificationSettings?: UserNotificationSettings;
  plainTextMode: boolean;
  defaultInboxId: string | null;
  email: string;
  name: string;
  role: string;
  avatarURL: string;
  avatarStoragePath: string;
  companies: string[];
  createdAt: Timestamp;
  updatedAt: Timestamp;
}

export const UserWebNotificationType = [
  'newMessage',
  'newComment',
  'mentionedMessage',
  'newChat',
  'opened',
] as const;

export type UserWebNotificationType = typeof UserWebNotificationType[number];

export type UserWebNotificationSettings = {
  enabled: boolean;
  types: UserWebNotificationType[];
};

export const UserSlackNotificationType = [
  'assigned',
  'mentionedMessage',
  'opened',
] as const;

export type UserSlackNotificationType =
  typeof UserSlackNotificationType[number];

export type UserSlackNotificationSettings = {
  enabled: boolean;
  types: UserSlackNotificationType[];
  channelId: string | null;
};

export const UserChatworkNotificationType = [
  'assigned',
  'mentionedMessage',
] as const;

export type UserChatworkNotificationType =
  typeof UserChatworkNotificationType[number];

export type UserChatworkNotificationSettings = {
  enabled: boolean;
  types: UserChatworkNotificationType[];
  roomId: number | null;
};

export type UserNotificationSettings = {
  web?: UserWebNotificationSettings;
  slack?: UserSlackNotificationSettings;
  chatwork?: UserChatworkNotificationSettings;
};

export type PreferencesData = {
  threadView?: boolean;
  tagIndexes?: { [id: string]: number };
};

export const createPreferences = createEntityFactory<
  'Preferences',
  PreferencesData
>('Preferences');

export type Preferences = ReturnType<typeof createPreferences>;

export const preferencesConverter: FirestoreDataConverter<Preferences> =
  createConverter(createPreferences);

export type RefreshTokenData = {
  updatedAt: Timestamp;
};

export const createUser = createEntityFactory('User', (data: UserData) => ({
  get isAdmin() {
    return data.role === 'admin';
  },

  get isReadOnly() {
    return data.role === 'readonly';
  },

  get plainTextMode() {
    return data.plainTextMode || false;
  },

  get notificationSettings() {
    return data.notificationSettings || {};
  },

  get webNotificationEnabled() {
    return this.notificationSettings?.web?.enabled || false;
  },

  get webNotificationTypes() {
    return this.notificationSettings?.web?.types || [];
  },

  get slackNotificationEnabled() {
    return this.notificationSettings?.slack?.enabled || false;
  },

  get slackNotificationChannelId() {
    return this.notificationSettings?.slack?.channelId || null;
  },

  get slackNotificationTypes() {
    return this.notificationSettings?.slack?.types || [];
  },

  get chatworkNotificationEnabled() {
    return this.notificationSettings?.chatwork?.enabled || false;
  },

  get chatworkNotificationRoomId() {
    return this.notificationSettings?.chatwork?.roomId || null;
  },

  get chatworkNotificationTypes() {
    return this.notificationSettings?.chatwork?.types || [];
  },

  get iconName() {
    const name = data.name;
    if (name.length === 0) return '';
    return name.length > 1 ? (name[0] + name[1]).toUpperCase() : name[0];
  },

  get iconBackgroundColor() {
    return backgroundColors[
      data.iconBackgroundColorNumber % backgroundColors.length
    ];
  },
}));

export type User = ReturnType<typeof createUser>;

export const userConverter: FirestoreDataConverter<User> =
  createConverter(createUser);
