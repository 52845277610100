import { matchPath, useLocation } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import {
  SearchHistoryEntry,
  SearchQuery,
  SearchStore,
} from '../../../store/search';
import { useWindowEvent } from '../../../hooks/useWindowEvent';

export const useSearchHistoryListener = (searchStore: SearchStore) => {
  const location = useLocation();
  const [lastEntry, setLastEntry] = useState<SearchHistoryEntry | undefined>(
    undefined
  );

  // 検索ページから離れたら最後の検索内容を履歴に追加する
  useEffect(() => {
    try {
      const routeMatch = matchPath<{ tray: string; query: string }>(
        location.pathname,
        {
          path: '/search/:tray/:query',
        }
      );
      if (routeMatch) {
        const params = routeMatch.params;
        const query = JSON.parse(
          decodeURIComponent(params.query)
        ) as SearchQuery;
        setLastEntry({ tray: params.tray, query });
      } else if (lastEntry) {
        searchStore.addHistory(lastEntry);
      }
    } catch (e) {
      console.error(e);
    }
  }, [location.pathname, searchStore.addHistory]);

  // ページを離れるときに検索していたら、履歴に追加する
  const onBeforeUnload = useCallback(() => {
    if (lastEntry) {
      searchStore.addHistory(lastEntry);
    }
  }, [lastEntry, searchStore.addHistory]);
  useWindowEvent('beforeunload', onBeforeUnload);
};
