type Props = {
  color: string;
};

export const ToolbarTextColorIcon = ({ color }: Props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="h-auto max-w-full"
    >
      <path
        d="M7.06998 15.25C6.77998 15.25 6.55998 15.13 6.38998 14.89C6.22998 14.65 6.18998 14.4 6.29998 14.13L10.95 2.27C11.01 2.11 11.1 1.99 11.24 1.89C11.38 1.8 11.53 1.75 11.69 1.75H12.32C12.49 1.75 12.65 1.8 12.78 1.89C12.91 1.99 13 2.11 13.06 2.27L17.72 14.12C17.82 14.4 17.79 14.66 17.62 14.89C17.45 15.13 17.23 15.25 16.94 15.25C16.76 15.25 16.6 15.2 16.45 15.1C16.3 15 16.19 14.86 16.13 14.69L14.99 11.63H9.00998L7.83998 14.72C7.77998 14.89 7.67998 15.02 7.53998 15.11C7.39998 15.2 7.24998 15.25 7.06998 15.25ZM9.52998 10.15H14.42L12.04 3.85H11.92L9.51998 10.15H9.52998Z"
        fill="currentColor"
      />
      <path
        d="M3.5 22.725C3.09 22.725 2.74 22.575 2.44 22.285C2.14 21.995 2 21.635 2 21.225C2 20.815 2.15 20.465 2.44 20.165C2.73 19.865 3.09 19.725 3.5 19.725H20.5C20.91 19.725 21.26 19.875 21.56 20.165C21.86 20.455 22 20.815 22 21.225C22 21.635 21.85 21.985 21.56 22.285C21.26 22.585 20.91 22.725 20.5 22.725H3.5Z"
        fill={color}
      />
    </svg>
  );
};
