import { Modal } from 'antd';
import styled from 'styled-components';
import { PlanCard } from './PlanCard';
import { Product } from '../Product';

type Props = {
  visible?: boolean;
  currentProduct?: Product;
  products: Product[];
  inboxCount?: number;
  userCount?: number;
  onSelect: (product: Product) => any;
  onClose?: () => void;
};

type ProductOption = {
  product: Product;
  exceeds: {
    inbox: boolean;
    users: boolean;
  };
  isCurrent: boolean;
};

export const PlanSelectModal: React.FC<Props> = (props) => {
  const options = props.products.map<ProductOption>((p) => {
    const inboxExceeded =
      p.maxInboxes !== null && (props.inboxCount ?? 0) > Number(p.maxInboxes);
    const usersExceeded =
      p.maxUsers !== null && (props.userCount ?? 0) > Number(p.maxUsers);
    const isCurrent = p.id === props.currentProduct?.id;

    return {
      product: p,
      exceeds: {
        inbox: inboxExceeded,
        users: usersExceeded,
      },
      isCurrent,
    };
  });

  const handleSelectProduct = (op: ProductOption) => {
    if (op.isCurrent || op.exceeds.inbox || op.exceeds.users) {
      return;
    }

    Modal.confirm({
      title: `${op.product.name}プランに変更してもよろしいですか？`,
      onOk: () => {
        props.onSelect(op.product);
      },
      onCancel: () => {
        //
      },
    });
  };

  return (
    <Modal
      visible={props.visible}
      onCancel={props.onClose}
      footer={null}
      width={1100}
    >
      <Wrapper>
        <div className="plans">
          {options.map((op) => {
            const p = op.product;
            let tooltip: string | undefined;
            if (op.exceeds.inbox) {
              tooltip = `このプランで使用できる共有メールアドレス数(${p.maxInboxes}個まで)を超えているため選択できません`;
            } else if (op.exceeds.users) {
              tooltip = `このプランで使用できるユーザー数(招待中のユーザー含め${p.maxUsers}名まで)を超えているため選択できません`;
            }

            return (
              <PlanCard
                key={p.id}
                product={p}
                disabled={op.isCurrent || op.exceeds.inbox || op.exceeds.users}
                buttonLabel={op.isCurrent ? '選択中のプランです' : undefined}
                tooltip={tooltip}
                onSelect={() => handleSelectProduct(op)}
              ></PlanCard>
            );
          })}
        </div>
        <div className="notes">
          <ul>
            <li>価格は税抜きです。最低ご利用人数は2ユーザーからになります。</li>
            <li>
              保存容量が10GB（100万通程度）を超えた場合は、追加で10GBごとに月額1,000円が企業アカウントに加算されます。
            </li>
            <li>
              IPアドレス制限、LINE連携、IMAPインポート機能がオプションでご利用いただけます。セールス担当にご相談ください。
            </li>
          </ul>
        </div>
      </Wrapper>
    </Modal>
  );
};

const Wrapper = styled.div`
  padding: 56px 24px 32px;

  & > .plans {
    display: flex;
    justify-content: center;
    gap: 24px;
    & > * {
      flex: 1;
      max-width: 340px;
    }
  }

  & > .notes {
    margin-top: 32px;
    font-size: 13px;
    line-height: 1.4;
    & > ul {
      padding-left: 1.2rem;
      & > li {
        margin-bottom: 4px;
      }
    }
  }
`;
