import { Dialog } from '../../../../../components/basics/dialog/Dialog';
import { DialogHeader } from '../../../../../components/basics/dialog/DialogHeader';
import { DialogContent } from '../../../../../components/basics/dialog/DialogContent';
import { DialogFooter } from '../../../../../components/basics/dialog/DialogFooter';
import { Button } from '../../../../../components/basics';
import { InputGroup } from '../../../../../components/forms/InputGroup/InputGroup';
import { Input } from '../../../../../components/forms';
import { RadioSelect } from '../../../../../components/basics/Radio/RadioSelect/RadioSelect';
import { Controller, useForm } from 'react-hook-form';

export type ImapUpdate = {
  host: string;
  port: 993 | 143;
  username: string;
  password: string;
};

type Props = {
  onImport: (update: ImapUpdate) => Promise<void>;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  modal?: boolean;
  container?: HTMLElement;
};

export const ImapImportDialog = ({
  onImport,
  open,
  onOpenChange,
  container,
  modal,
}: Props) => {
  const {
    control,
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<ImapUpdate>({
    defaultValues: {
      host: '',
      port: 993,
      username: '',
      password: '',
    },
  });

  const onSubmit = async (update: ImapUpdate) => {
    try {
      await onImport(update);
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <Dialog
      width="sm"
      open={open}
      onOpenChange={(open) => {
        if (!open && isSubmitting) {
          return;
        }
        onOpenChange(open);
      }}
      container={container}
      contentClassName="top-[20%]"
      modal={modal}
    >
      <form action="" onSubmit={handleSubmit(onSubmit)}>
        <DialogHeader title="IMAPによるメールのインポート" />
        <DialogContent className="flex flex-col gap-4">
          <Controller
            name="host"
            control={control}
            rules={{
              required: '入力してください',
            }}
            render={({ fieldState: { error } }) => (
              <InputGroup label="サーバー" errorMessage={error?.message}>
                <Input placeholder="smtp.example.com" {...register('host')} />
              </InputGroup>
            )}
          />
          <Controller
            name="port"
            control={control}
            render={({ field: { value, onChange } }) => (
              <InputGroup label="ポート番号">
                <RadioSelect
                  options={[
                    { value: 993, label: '993' },
                    { value: 143, label: '143' },
                  ]}
                  value={value}
                  onChange={onChange}
                />
              </InputGroup>
            )}
          />
          <Controller
            name="username"
            control={control}
            rules={{
              required: '入力してください',
            }}
            render={({ fieldState: { error } }) => (
              <InputGroup label="ユーザー名" errorMessage={error?.message}>
                <Input
                  placeholder="name@example.com"
                  {...register('username')}
                />
              </InputGroup>
            )}
          />
          <Controller
            name="password"
            control={control}
            rules={{
              required: '入力してください',
            }}
            render={({ fieldState: { error } }) => (
              <InputGroup label="パスワード" errorMessage={error?.message}>
                <Input {...register('password')} />
              </InputGroup>
            )}
          />
        </DialogContent>
        <DialogFooter>
          <Button type="submit" loading={isSubmitting}>
            インポート開始
          </Button>
        </DialogFooter>
      </form>
    </Dialog>
  );
};
