import { Timestamp } from 'firebase/firestore';
import moment from 'moment/moment';

export const formatTimestamp = (timestamp: Timestamp) => {
  const lastReceivedAt = moment(timestamp.toMillis());
  if (lastReceivedAt.isSame(moment(), 'day')) {
    return lastReceivedAt.format('HH:mm');
  } else {
    return lastReceivedAt.format('M月D日');
  }
};
