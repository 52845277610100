import { arrayRemove, query, serverTimestamp, where } from 'firebase/firestore';
import type { Store } from './index';
import { fetchQueryInArray } from 'utils/firebase';
import { WriteBatch } from 'utils/firestore';

export class ServerCounterStore {
  constructor(private rootStore: Store) {}

  collection() {
    const name = this.rootStore.isInThreadView ? 'threads' : 'messages';
    return this.rootStore.collection(name);
  }

  async markAllMentionedRead() {
    const docs = await fetchQueryInArray(
      query(
        this.rootStore.collection('comments'),
        where('unreadUsers', 'array-contains', this.rootStore.me.id)
      ),
      'teamId',
      this.rootStore.joinedTeamIds
    );
    const batch = new WriteBatch(9);
    docs.forEach((doc) => {
      batch.update(doc.ref, {
        unreadUsers: arrayRemove(this.rootStore.me.id),
        updatedAt: serverTimestamp(),
      });
    });
    await batch.commit();
  }
}
