import { Select } from '../../../forms';
import { Icon } from '../../../basics';
import { Plus } from '../../../icons';
import { useState } from 'react';

type Props = {
  value: string | undefined;
  onChange: (value: string) => void;
  onAddEmailAddress: () => void;
  addresses: {
    id: string;
    name?: string;
    address: string;
  }[];
  disabled?: boolean;
};

export const DeliveryEmailAddressSelect = ({
  value,
  onChange,
  onAddEmailAddress,
  addresses,
  disabled,
}: Props) => {
  const [visible, setVisible] = useState(false);
  return (
    <Select
      visible={visible}
      onVisibleChange={setVisible}
      placeholder="選択してください"
      value={value}
      onChange={(v) => v && onChange(v)}
      options={addresses.map((addr) => ({
        value: addr.id,
        label: addr.name ? `${addr.name} <${addr.address}>` : addr.address,
      }))}
      variants={{ size: 'lg', rounded: 'lg', width: 'full' }}
      footerElement={
        <button
          type="button"
          onClick={() => {
            onAddEmailAddress();
            setVisible(false);
          }}
          className="m-0 grid h-8 cursor-pointer grid-cols-[auto_1fr] items-center gap-2 whitespace-nowrap rounded-lg bg-transparent bg-white p-0 px-1.5 py-1 text-left text-sm text-sea-500 outline-none hover:bg-sumi-100"
        >
          <Icon icon={Plus} className="h-3.5 w-3.5" />
          <span>Eメールアドレスを追加</span>
        </button>
      }
      disabled={disabled}
    />
  );
};
