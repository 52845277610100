import { Button, Loading } from '../../../../../components/basics';
import { Alert } from '../../../../../components/basics/Alert/Alert';

type Signature = {
  id: string;
  title: string;
};

type Props = {
  signatures: Signature[];
  onOpenCreateDrawer: () => void;
  onOpenEditDrawer: (id: string) => void;
  loading: boolean;
  readonly: boolean;
};

export const Signatures = ({
  signatures,
  onOpenCreateDrawer,
  onOpenEditDrawer,
  loading,
  readonly,
}: Props) => {
  return (
    <div className="text-sm">
      {signatures.length <= 0 && !loading && (
        <Alert className="mb-4">
          署名はメール作成・返信・転送時に、自動的に末尾に文章を追加する機能です。
        </Alert>
      )}
      <Button onClick={() => onOpenCreateDrawer()} disabled={readonly}>
        署名を作成
      </Button>
      <div className="mt-8 flex flex-col items-start gap-4">
        <div className="text-base font-bold">作成済みの署名</div>
        {loading && <Loading />}
        {!loading && signatures.length <= 0 && (
          <div className="text-sumi-500">まだ署名が作成されていません</div>
        )}
        {!loading &&
          signatures.map((s) => (
            <button
              key={s.id}
              type="button"
              className="cursor-pointer bg-transparent p-0 text-sea-500 hover:underline"
              onClick={() => onOpenEditDrawer(s.id)}
            >
              {s.title}
            </button>
          ))}
      </div>
    </div>
  );
};
