import { matchPath, useLocation } from 'react-router-dom';
import { MessageView } from 'store/messages';

type Page = 'team' | 'me' | 'line';

const MSG_PATH_MAP: Map<string, Page> = new Map([
  ['/teams/:teamId/tags/:tagId/:tab/:msgId?', 'team'],
  ['/teams/:teamId/inboxes/:inboxId/tags/:tagId/:tab/:msgId?', 'team'],
  ['/me/assigned/:tab/:msgId?', 'me'],
  ['/teams/:teamId/lineaccounts/:lineAccountId/:tab/:lineThreadId?', 'line'],
]);

export const MSG_PATHS = Array.from(MSG_PATH_MAP.keys());

export const MSG_LIST_PATHS = [
  '/teams/:teamId/tags/:tagId/:tab',
  '/teams/:teamId/inboxes/:inboxId/tags/:tagId/:tab',
  '/me/assigned/:tab',
  '/teams/:teamId/lineaccounts/:lineAccountId/:tab',
];

export const useMsgRouteMatch = () => {
  const location = useLocation();
  const match = matchPath<{
    teamId?: string;
    inboxId?: string;
    tagId?: string;
    tab?: string;
    lineAccountId?: string;
    lineThreadId?: string;
  }>(decodeURI(location.pathname), MSG_PATHS);
  return {
    params: {},
    ...match,
    page: match?.path && MSG_PATH_MAP.get(match.path),
    tab: (match?.params.tab || MessageView.Unprocessed) as MessageView,
  };
};

export type MsgRouteMatch = ReturnType<typeof useMsgRouteMatch>;
