import { Alert } from '../../../../../components/basics/Alert/Alert';
import { Button, Loading } from '../../../../../components/basics';
import { TagColor } from 'lib';
import { Tag } from '../../../../../components/basics/Tag/Tag';

type TagEntry = {
  id: string;
  name: string;
  color: TagColor | null;
  isDefault: boolean;
};

type Props = {
  tags: TagEntry[];
  onOpenCreateDrawer: () => void;
  onOpenEditDrawer: (id: string) => void;
  loading: boolean;
  readonly: boolean;
};

export const Tags = ({
  tags,
  onOpenCreateDrawer,
  onOpenEditDrawer,
  loading,
  readonly,
}: Props) => {
  return (
    <div className="text-sm">
      {tags.filter((t) => !t.isDefault).length <= 0 && !loading && (
        <Alert className="mb-4">
          タグはメールを分類するための機能です。メールに複数のタグをつけることが可能です。
        </Alert>
      )}
      <Button onClick={() => onOpenCreateDrawer()} disabled={readonly}>
        タグを作成
      </Button>
      <div className="mt-8 flex flex-col items-start gap-4">
        <div className="text-base font-bold">作成済みのタグ</div>
        {loading && <Loading />}
        {!loading &&
          tags.map((t) =>
            t.isDefault ? (
              <Tag key={t.id} color={t.color}>
                {t.name}
              </Tag>
            ) : (
              <Tag
                key={t.id}
                color={t.color}
                role="button"
                tabIndex={0}
                className="cursor-pointer"
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    onOpenEditDrawer(t.id);
                  }
                }}
                onClick={() => onOpenEditDrawer(t.id)}
              >
                {t.name}
              </Tag>
            )
          )}
      </div>
    </div>
  );
};
