import { Team } from 'lib';
import { observer } from 'mobx-react';
import { LeftSidebarTagList } from './tag/LeftSidebarTagList';
import { useStore } from '../../../hooks/useStore';
import { useCollapseState } from './hooks/useCollapseState';
import { Forum, Inbox } from '../../../components/icons';
import { LeftSidebarCollapse } from './button/LeftSidebarCollapse';
import { LeftSidebarLink } from './button/LeftSidebarLink';
import { matchPath, useLocation } from 'react-router-dom';
import { LeftSidebarLinkWithCount } from './button/LeftSidebarLinkWithCount';
import { LeftSidebarCollapseWithCount } from './button/LeftSidebarCollapseWithCount';
import { SidebarSectionTitleWithCount } from './SidebarSectionTitleWithCount';

type Props = {
  team: Team;
};

export const LeftSidebarTeam = observer(({ team }: Props): JSX.Element => {
  const location = useLocation();
  const store = useStore();

  const [teamOpen, setTeamOpen] = useCollapseState(`team:${team.id}`);
  const [inboxesOpen, setInboxesOpen] = useCollapseState(
    `team:${team.id}:inboxes`
  );
  const [tagsOpen, setTagsOpen] = useCollapseState(`team:${team.id}:tags`);
  const [lineAccountsOpen, setLineAccountsOpen] = useCollapseState(
    `team:${team.id}:lines`
  );
  const teamName = team.isPrivate ? '自分' : team.name;
  const inboxTag = store.getInboxTag(team.id);

  const tags = store.getTags(team.id).filter((t) => t.id !== inboxTag?.id);
  const lineAccounts = store.lineStore.accounts.filter(
    (account) => account.teamId === team.id
  );

  let rootInbox: JSX.Element | null = null;
  if (inboxTag) {
    const parentPath = `/teams/${team.id}/tags/${inboxTag.id}`;
    rootInbox = (
      <LeftSidebarCollapseWithCount
        teamId={team.id}
        tagId={inboxTag.id}
        icon={null}
        label={inboxTag.name}
        to={`${parentPath}/messages`}
        opened={inboxesOpen}
        onToggleOpen={setInboxesOpen}
        active={{
          type: 'parentPath',
          path: parentPath,
        }}
        bold
      />
    );
  }

  let childInboxes: JSX.Element[] | null = null;
  if (inboxTag && teamOpen && inboxesOpen) {
    childInboxes = store.getTeamInboxes(team.id).map((inbox) => {
      const parentPath = `/teams/${team.id}/inboxes/${inbox.id}/tags/${inboxTag.id}`;
      return (
        <LeftSidebarLinkWithCount
          teamId={team.id}
          tagId={inboxTag.id}
          inboxId={inbox.id}
          key={inbox.id}
          icon={Inbox}
          label={inbox.yaritoriName || inbox.email}
          to={`${parentPath}/messages`}
          level={2}
          active={{
            type: 'parentPath',
            path: parentPath,
          }}
        />
      );
    });
  }

  let rootTag: JSX.Element | null = null;
  if (tags.length > 0) {
    rootTag = (
      <LeftSidebarCollapse
        icon={null}
        label={'タグ'}
        opened={tagsOpen}
        onToggleOpen={setTagsOpen}
        bold
      />
    );
  }

  const childTags = (
    <LeftSidebarTagList teamId={team.id} tags={tags} level={2} />
  );

  let rootLineAccount: JSX.Element | null = null;
  if (lineAccounts.length > 0) {
    rootLineAccount = (
      <LeftSidebarCollapse
        icon={null}
        label={'LINEアカウント'}
        opened={lineAccountsOpen}
        onToggleOpen={setLineAccountsOpen}
        bold
      />
    );
  }

  const childLineAccounts = lineAccounts.map((account) => {
    const active =
      matchPath(location.pathname, {
        path: `/teams/${account.teamId}/lineaccounts/${account.id}/:tab`,
      }) != null;
    return (
      <LeftSidebarLink
        key={account.id}
        icon={Forum}
        count={store.lineStore.threadCounts[account.id]}
        label={account.name}
        level={2}
        to={`/teams/${team.id}/lineaccounts/${account.id}/messages`}
        active={active}
      />
    );
  });

  return (
    <>
      {inboxTag && (
        <SidebarSectionTitleWithCount
          teamId={team.id}
          tagId={inboxTag.id}
          onOpenChange={setTeamOpen}
          open={teamOpen}
        >
          {teamName}
        </SidebarSectionTitleWithCount>
      )}
      {teamOpen && (
        <>
          {rootInbox}
          {inboxesOpen && childInboxes}

          {rootTag}
          {tagsOpen && childTags}

          {rootLineAccount}
          {lineAccountsOpen && childLineAccounts}
        </>
      )}
    </>
  );
});
