import * as React from 'react';
import type { SVGProps } from 'react';
const SvgAi = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={13}
    height={10}
    fill="none"
    viewBox="0 0 13 10"
    {...props}
  >
    <path
      fill="url(#a)"
      d="M5.868 6.64 4.812 3.628 3.756 6.64h2.112Zm.624 1.788h-3.36L2.592 10H.084L3.528.952h2.568L9.54 10H7.032l-.54-1.572Zm6.43-7.476V10h-2.353V.952h2.352Z"
    />
    <defs>
      <linearGradient
        id="a"
        x1={7}
        x2={7}
        y1={-4}
        y2={14}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4E8BE6" />
        <stop offset={0.703} stopColor="#60CBED" />
        <stop offset={1} stopColor="#60DCED" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgAi;
