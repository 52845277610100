import { useLocation, useParams } from 'react-router-dom';
import { useStore } from './useStore';
import { useMemo } from 'react';

export const useCurrentPageLabel = () => {
  const store = useStore();
  const location = useLocation();
  const pathname = location.pathname;
  const params = useParams<{
    inboxId?: string;
    tagId?: string;
  }>();
  const { query } = store.searchStore;
  const { sender, assignee } = query;
  const inboxId = params.inboxId ?? query.inboxId;
  const tagId = params.tagId ?? query.tags?.[0];
  return useMemo<string>(() => {
    if (store.inboxesLoading || store.tagsLoading) {
      return '';
    }
    if (pathname.startsWith('/me/sent') || sender === store.me.id) {
      return '送信済み';
    } else if (
      pathname.startsWith('/me/assigned') ||
      assignee === store.me.id
    ) {
      return '自分が担当';
    } else if (inboxId) {
      const inbox = store.getInbox(inboxId);
      return inbox?.yaritoriName || inbox?.email || '';
    } else if (tagId) {
      const tag = store.getTag(tagId);
      return tag?.name ?? '';
    } else if (query.inbox) {
      return '受信トレイ';
    }
    return '';
  }, [
    store.inboxesLoading,
    store.tagsLoading,
    pathname,
    sender,
    assignee,
    inboxId,
    tagId,
  ]);
};
