import {
  atomWithSubscribeCollection,
  companyCollection,
} from '../../firestore';
import { orderBy, query, where } from 'firebase/firestore';
import { roomConverter } from '../../firestore/entity/room';
import { meAtom } from '../auth';
import { store } from '../../providers/StoreProvider';

export const [roomsLoadingAtom, roomsAtom] = atomWithSubscribeCollection(
  () =>
    query(
      companyCollection('rooms', roomConverter),
      where('members', 'array-contains', store.get(meAtom).id),
      orderBy('updatedAt', 'desc')
    ),
  (snapshot) => snapshot.docs.map((doc) => doc.data())
);
