import firebase from 'firebase/compat/app';
const maxBatchSize = 200;

export class WriteBatch {
  db: firebase.firestore.Firestore;
  batches: firebase.firestore.WriteBatch[];
  count: number;

  constructor(db: firebase.firestore.Firestore) {
    this.db = db;
    this.batches = [this.db.batch()];
    this.count = 0;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  addOperation(addFn: (batch: firebase.firestore.WriteBatch) => any) {
    addFn(this.batches[this.batches.length - 1]);
    this.count++;

    if (this.count === maxBatchSize) {
      this.batches.push(this.db.batch());
      this.count = 0;
    }
  }

  async commit() {
    await Promise.all(this.batches.map((b) => b.commit()));
  }
}
