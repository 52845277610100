import { QuotedBodyGenerator, StorageFetcher } from './quotedBodyGenerator';
import { extractUsedAttachments } from './attachment';
import { generateHtml } from './html';

export type MessageConfig = {
  inboxId: string;
  teamId: string;
  messageId?: string;
  isReply: boolean;
  isForwarded: boolean;
  useQuote: boolean;
  plainTextMode: boolean;
  inReplyToMessageId?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inReplyToMessageRef?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inReplyToSentRef?: any;
  inReplyToSentId?: string;
  to: string[];
  cc: string[];
  bcc: string[];
  subject: string;
  body: string;
  bodyHtml: string;
  signature: MessageBodySignature | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  attachments: any[];
};

export type MessageBodySignature = {
  id: string;
  teamId: string;
  title: string;
  body: string;
  bodyHtml: string;
};

export type ComposedMessageData = {
  inboxId: string;
  teamId: string;
  to: string[];
  cc: string[];
  bcc: string[];
  subject: string;
  body: string;
  isReply: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  attachments: any[];
  html?: string;
};

export class MessageComposer {
  constructor(
    private storageFetcher: StorageFetcher,
    private draft: MessageConfig
  ) {}

  get signatureBody() {
    return this.draft.signature ? '\n' + this.draft.signature.body : '';
  }

  async generateQuotedBody() {
    return this.draft.useQuote
      ? await new QuotedBodyGenerator(
          this.storageFetcher,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          this.draft as any
        ).execute()
      : ['', ''];
  }

  text(quotedBodyText: string) {
    return this.draft.body + this.signatureBody + quotedBodyText;
  }

  html(quotedBodyHtml: string) {
    return generateHtml({
      body: this.draft.bodyHtml,
      signatureBody: this.draft.signature?.bodyHtml || '',
      quotedBody: quotedBodyHtml,
    });
  }

  async execute() {
    const [quotedBodyText, quotedBodyHtml] = await this.generateQuotedBody();

    const html = this.html(quotedBodyHtml);

    const message: ComposedMessageData = {
      inboxId: this.draft.inboxId,
      teamId: this.draft.teamId,
      to: this.draft.to,
      cc: this.draft.cc,
      bcc: this.draft.bcc,
      subject: this.draft.subject || ' ',
      body: this.text(quotedBodyText) || ' ',
      attachments: extractUsedAttachments(html, this.draft.attachments),
      isReply: this.draft.isReply,
    };

    // プレーンテキストモードではない場合、HTMLを送信する
    if (!this.draft.plainTextMode) message.html = html || ' ';

    return message;
  }
}
