import firebase from './firebase';

export const eventNames = {
  /* page_view */
  page_view: 'page_view',

  /* 設定関連ここから */
  /* profile */
  update_username: 'update_username',
  update_icon: 'update_icon',
  remove_icon: 'remove_icon',
  update_email: 'update_email',
  reset_password: 'reset_password',

  /* company */
  invite_user: 'invite_user',

  /* team */
  add_team: 'add_team',
  delete_team: 'delete_team',
  update_team_name: 'update_team_name',
  add_member_to_team: 'add_member_to_team',
  remove_member_from_team: 'remove_member_from_team',
  add_inbox: 'add_inbox',
  add_contact: 'add_contact',
  add_template: 'add_template',
  add_signature: 'add_signature',
  add_tag: 'add_tag',
  add_filter: 'add_filter',

  /* slack */
  integrate_slack: 'integrate_slack',
  unintegrate_slack: 'unintegrate_slack',

  /* chatwork */
  integrate_chatwork: 'integrate_chatwork',
  unintegrate_chatwork: 'unintegrate_chatwork',

  /* login/logout */
  login: 'login',
  logout: 'logout',
  /* 設定関連ここまで */

  /* トップ関連ここから */
  /* search */
  search_messages: 'search_messages',

  /* download */
  download_eml: 'download_eml',
  batch_download_eml: 'batch_download_eml',
  download_sent_eml: 'download_sent_eml',
  batch_download_sent_eml: 'batch_download_sent_eml',

  /* template */
  show_template: 'show_template',
  apply_template: 'apply_template',
  add_template_attachment: 'add_template_attachment',
  remove_template_attachment: 'remove_template_attachment',
  show_reserved_word: 'show_reserved_word',
  insert_reserved_word: 'insert_reserved_word',

  /* html_toolbar */
  toggle_html_toolbar: 'toggle_html_toolbar',

  /* attachment */
  add_attachment: 'add_attachment',
  remove_attachment: 'remove_attachment',

  /* comment */
  add_comment: 'add_comment',

  /* message */
  update_assignee: 'update_assignee',
  attach_tag: 'attach_tag',
  update_status: 'update_status',
  update_status_of_all_messages_to_processed:
    'update_status_of_all_messages_to_processed',
  batch_update_status: 'batch_update_status',
  remove_message: 'remove_message',
  batch_remove_messages: 'batch_remove_messages',

  /* message_histories */
  show_message_histories: 'show_message_histories',
  hide_message_histories: 'hide_message_histories',
  open_message_on_new_tab: 'open_message_on_new_tab',

  /* draft/message */
  add_draft: 'add_draft',
  add_draft_from_contacts: 'add_draft_from_contacts',
  remove_draft: 'remove_draft',
  start_reply: 'start_reply',
  start_reply_to_sent: 'start_reply_to_sent',
  start_forwarding: 'start_forwarding',
  start_forwarding_to_sent: 'start_forwarding_to_sent',
  send_message: 'send_message',

  /* sent */
  remove_sent: 'remove_sent',
  batch_remove_sent: 'batch_remove_sent',

  /* chat */
  add_chat: 'add_chat',
  /* トップ関連ここまで */
};

export const setUserProperties = ({
  company_id,
  company_name,
  user_id,
  user_name,
}) => {
  firebase.analytics().setUserProperties({
    company_id,
    company_name,
    user_id,
    user_name,
  });
};

export const logEvent = (eventName, eventParams, options) => {
  firebase.analytics().logEvent(eventName, eventParams, options);
};
