import * as React from 'react';
import type { SVGProps } from 'react';
const SvgPatient = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M16 13.925c-.85 0-1.554-.28-2.113-.838-.558-.558-.837-1.262-.837-2.112 0-.85.28-1.554.837-2.112.559-.559 1.263-.838 2.113-.838.85 0 1.554.28 2.113.838.558.558.837 1.262.837 2.112 0 .85-.28 1.554-.837 2.113-.559.558-1.263.837-2.113.837ZM10.75 20a.726.726 0 0 1-.534-.216.726.726 0 0 1-.216-.534v-.9a1.9 1.9 0 0 1 .225-.898c.15-.285.35-.485.6-.602.75-.533 1.57-.93 2.463-1.188A9.702 9.702 0 0 1 16 15.275a8.79 8.79 0 0 1 2.7.425c.883.283 1.708.667 2.475 1.15.233.167.43.38.587.637.159.259.238.546.238.863v.9c0 .212-.072.39-.216.534a.726.726 0 0 1-.534.216h-10.5Zm.625-1.775v.275h9.25v-.275a8.759 8.759 0 0 0-2.25-1.05c-.85-.267-1.642-.4-2.375-.4-.733 0-1.53.133-2.387.4-.859.267-1.605.617-2.238 1.05Zm4.625-5.8c.433 0 .783-.133 1.05-.4s.4-.617.4-1.05c0-.433-.133-.783-.4-1.05s-.617-.4-1.05-.4c-.433 0-.783.133-1.05.4s-.4.617-.4 1.05c0 .433.133.783.4 1.05s.617.4 1.05.4ZM9.9 13.75H3.75a.723.723 0 0 1-.534-.217.732.732 0 0 1-.216-.537.731.731 0 0 1 .75-.746H9.9c.213 0 .39.072.534.217a.732.732 0 0 1 .216.537.731.731 0 0 1-.75.746Zm4.175-8.25H3.75a.724.724 0 0 1-.534-.217A.732.732 0 0 1 3 4.746.731.731 0 0 1 3.75 4h10.325c.213 0 .39.072.534.217a.732.732 0 0 1 .216.537.731.731 0 0 1-.75.746Zm-2.35 4.125H3.75a.724.724 0 0 1-.534-.217A.732.732 0 0 1 3 8.871a.731.731 0 0 1 .75-.746h8.75a3.744 3.744 0 0 0-.775 1.5Z"
    />
  </svg>
);
export default SvgPatient;
