import { atom } from 'jotai';
import { allTeamStatusesInGroupAtom } from '../customStatuses';
import { onSnapshot, query, Unsubscribe, where } from 'firebase/firestore';
import { registerUnsubscribe } from '../../../firestore';
import { MessageStatus } from 'lib';
import { atomEffect } from 'jotai-effect';
import { counterCollection } from './utils';
import { sum } from 'lodash';

type TabCountsFilter = {
  teamId: string;
  tagId: string;
  inboxId?: string;
};

export const tabCountsFilterAtom = atom<TabCountsFilter | undefined>(undefined);

const writableTabCountsAtom = atom<Record<string, number>>({});

export const tabCountsAtom = atom((get) => get(writableTabCountsAtom));

export const tabCountsEffect = atomEffect((get, set) => {
  const filter = get(tabCountsFilterAtom);
  if (!filter) {
    return;
  }
  const { teamId, tagId, inboxId } = filter;
  const [, customStatusesData] = get(allTeamStatusesInGroupAtom);
  const customStatuses =
    teamId in customStatusesData ? customStatusesData[teamId] : [];
  const unsubscribes: Unsubscribe[] = [];
  [
    MessageStatus.Unprocessed,
    ...customStatuses.map((status) => status.id),
  ].forEach((status) => {
    let q = query(
      counterCollection(status, 'tag'),
      where('teamId', '==', teamId),
      where('tagId', '==', tagId)
    );
    if (inboxId) {
      q = query(q, where('inboxId', '==', inboxId));
    }
    unsubscribes.push(
      onSnapshot(q, (snapshot) => {
        set(writableTabCountsAtom, (prev) => ({
          ...prev,
          [status]: sum(snapshot.docs.map((doc) => doc.data().count)),
        }));
      })
    );
  });
  return registerUnsubscribe(writableTabCountsAtom, () => {
    unsubscribes.forEach((f) => f());
  });
});
