import moment from 'moment';
import { estimateServerTimestampsOption } from './common';
import firebase from 'firebase/compat/app';
import { TagColor } from '../tagColor';

export interface ContactTagData {
  name: string;
  color: TagColor | null | undefined;
  teamId: string;
  createdAt: firebase.firestore.Timestamp;
  updatedAt: firebase.firestore.Timestamp;
}

export class ContactTag {
  private _id: string;
  private _data: ContactTagData;
  private _date: moment.Moment;
  private _ref: firebase.firestore.DocumentReference<ContactTagData>;

  constructor(doc: firebase.firestore.DocumentSnapshot<ContactTagData>) {
    this._id = doc.id;
    const data = doc.data(estimateServerTimestampsOption);
    if (!data) {
      throw new Error(`no data for contactTagData#${doc.id}`);
    }
    this._data = data;
    this._date = moment(this._data.createdAt.toMillis()); // 毎回生成するのはコストがかかるため
    this._ref = doc.ref;
  }

  get id() {
    return this._id;
  }

  get ref() {
    return this._ref;
  }

  get date() {
    return this._date;
  }

  get teamId() {
    return this._data.teamId;
  }

  get name() {
    return this._data.name;
  }

  get color() {
    return this._data.color;
  }

  get createdAt() {
    return this._data.createdAt;
  }

  get updatedAt() {
    return this._data.updatedAt;
  }
}
