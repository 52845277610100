import { Route, Switch } from 'react-router-dom';
import { ChatRoomWithLogic } from '../../../components/chat/ChatRoom/ChatRoomWithLogic';
import { MessagePaneMeDraft, PATH as meDraftPath } from './MessagePaneMeDraft';
import { MessagePaneMeSent, PATH as meSentPath } from './MessagePaneMeSent';
import {
  MessagePaneMeCommented,
  PATH as meCommentedPath,
} from './MessagePaneMeCommented';
import {
  MessagePaneMeMentioned,
  PATH as meMentionedPath,
} from './MessagePaneMeMentioned';
import {
  MessagePaneMeScheduled,
  PATH as meScheduledPath,
} from './MessagePaneMeScheduled';
import {
  MessagePaneMeStarred,
  PATH as meStarredPath,
} from './MessagePaneMeStarred';
import {
  MessagePaneTeamSent,
  PATHS as teamSentPaths,
} from './MessagePaneTeamSent';
import {
  MessagePaneTeamMessages,
  PATHS as teamMessagesPaths,
} from './MessagePaneTeamMessages';
import {
  MessagePaneLineThreads,
  PATHS as teamLineThreadPaths,
} from './MessagePaneLineThreads';
import { MessagePaneMessages } from './MessagePaneMessages';

export const MessagePanes = () => {
  return (
    <Switch>
      <Route path="/rooms/:roomId" exact component={ChatRoomWithLogic} />
      <Route path={meDraftPath} component={MessagePaneMeDraft} />
      <Route path={meSentPath} component={MessagePaneMeSent} />
      <Route path={meCommentedPath} component={MessagePaneMeCommented} />
      <Route path={meMentionedPath} component={MessagePaneMeMentioned} />
      <Route path={meScheduledPath} component={MessagePaneMeScheduled} />
      <Route path={meStarredPath} component={MessagePaneMeStarred} />
      <Route path={teamSentPaths} component={MessagePaneTeamSent} />
      <Route path={teamMessagesPaths} component={MessagePaneTeamMessages} />
      <Route path={teamLineThreadPaths} component={MessagePaneLineThreads} />
      <Route
        path={'/teams/:teamId/messages/:messageId'}
        component={MessagePaneMessages}
      />
      <Route>
        <p>404</p>
      </Route>
    </Switch>
  );
};
