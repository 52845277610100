import React from 'react';
import { MessagePanes } from './MessagePanes';

export const SPTop: React.FC = () => {
  return (
    <div className="relative h-full">
      <div className="bg-sumi-50">
        <MessagePanes />
      </div>
    </div>
  );
};
