import { Button } from '../../../../../components/basics';
import { InputGroup } from '../../../../../components/forms/InputGroup/InputGroup';

type Props = {
  onOpen: () => void;
  readonly: boolean;
  largeLabel?: boolean;
};

export const ImapField = ({ onOpen, readonly, largeLabel }: Props) => {
  return (
    <InputGroup
      label={
        <span className={largeLabel ? 'text-base font-bold' : undefined}>
          IMAPによるメールのインポート
        </span>
      }
    >
      <Button variant="text" onClick={() => onOpen()} disabled={readonly}>
        メールのインポート
      </Button>
    </InputGroup>
  );
};
