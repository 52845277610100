import { tv } from 'tailwind-variants';
import moment from 'moment';
import { Icon } from '../../../../../../components/basics';
import { Schedule } from '../../../../../../components/icons';

type Props = {
  subject: string;
  body: string;
  toAddresses: string[];
  scheduledAt: Date;
  active: boolean;
  failed: boolean;
  onClick: () => void;
};

const item = tv({
  base: 'w-full cursor-pointer rounded-lg py-3 pl-8 pr-3 text-start text-sm transition-colors',
  variants: {
    active: {
      true: 'bg-sumi-200',
      false: 'bg-white hover:bg-sumi-100',
    },
  },
});

const badge = tv({
  base: 'mb-1 flex h-6 w-fit select-none items-center gap-1 rounded-lg px-2 text-xs',
  variants: {
    failed: {
      true: 'bg-sun-50 text-sun-600',
      false: 'bg-sea-50 text-sea-600',
    },
  },
});

export const ScheduledDraftItem = ({
  subject,
  body,
  toAddresses,
  scheduledAt,
  active,
  failed,
  onClick,
}: Props) => {
  return (
    <button
      type="button"
      className={item({ active })}
      onClick={() => onClick()}
    >
      <div className={badge({ failed })}>
        <Icon icon={Schedule} size={14} />
        {moment(scheduledAt).format('M/D HH:mm')}
      </div>
      <div className="mb-1 truncate whitespace-nowrap font-bold">
        {toAddresses.join(' ')}
      </div>
      <div className="mb-1 truncate whitespace-nowrap text-xs">
        {subject.length ? subject : '(件名がありません)'}
      </div>
      <div className="truncate whitespace-nowrap text-xs text-sumi-600">
        {body.length ? body : '(本文がありません)'}
      </div>
    </button>
  );
};
