import { MessageData } from 'lib';
import { action, computed, makeObservable, observable } from 'mobx';
import { FetchMessagesOptions, MessageView } from './types';
import firebase from '../../firebase';
import type { Store } from 'store';

export class MessageFilterStore {
  options: FetchMessagesOptions = {};
  view: MessageView = MessageView.Unprocessed;

  constructor(private rootStore: Store) {
    makeObservable(this, {
      options: observable,
      view: observable,
      teamId: computed,
      inboxId: computed,
      tagId: computed,
      assignedMe: computed,
      startAfter: computed,
      deleted: computed,
      setOptions: action,
      setView: action,
    });
  }

  get teamId(): string | null {
    return this.options.teamId ?? null;
  }

  get inboxId(): string | null {
    return this.options.inboxId ?? null;
  }

  get tagId(): string | null {
    const { tagId } = this.options;
    if (tagId) {
      const tag = this.rootStore.getTag(tagId);
      if (tag?.isDefault) {
        if (
          this.view === MessageView.All ||
          this.view === MessageView.Deleted
        ) {
          return null;
        }
      }
      return tagId;
    }
    return null;
  }

  get assignedMe(): boolean {
    return this.options.assignedMe ?? false;
  }

  get startAfter(): firebase.firestore.DocumentSnapshot<MessageData> | null {
    return this.options.startAfter ?? null;
  }

  get deleted(): boolean {
    return this.view === MessageView.Deleted;
  }

  setOptions(options: FetchMessagesOptions): void {
    this.options = options;
  }

  setView(view: MessageView): void {
    this.view = view;
  }
}
