import { observer } from 'mobx-react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Close, Filter, Search } from '../../../components/icons';
import { Dropdown, Icon } from '../../../components/basics';
import { Select } from '../../../components/forms';
import { SearchFilterForm } from './SearchFilterForm';
import { Tray, useSearch } from './useSearch';

export type SearchFilterData = {
  tray: Tray;
  status: string;
  from: string;
  toOrCc: string;
  subjectOrText: string;
  tag: string;
  assignee: string | null;
  after: Date | null;
  before: Date | null;
  hasAttachments: boolean;
  attachmentsFilename: string;
};

export const MainSearch = observer((): JSX.Element => {
  const [focused, setFocused] = useState(false);
  const [filterOpened, setFilterOpened] = useState(false);
  const [width, setWidth] = useState<number>();
  const searchRef = useRef<HTMLDivElement>();
  const inputRef = useRef<HTMLInputElement>(null);

  const search = useSearch(() => setFocused(false));

  useEffect(() => {
    if (!focused) {
      return;
    }
    const handleClick = (e: MouseEvent) => {
      const elem = searchRef.current;
      if (!elem) {
        return;
      }
      const node = e.target as HTMLElement;
      const menu = document.getElementById('searchRangeMenu');
      if (!elem.contains(node) && !menu?.contains(node)) {
        setFocused(false);
      }
    };
    document.addEventListener('click', handleClick, true);
    return () => document.removeEventListener('click', handleClick, true);
  }, [focused, inputRef.current]);

  useEffect(() => {
    if (focused) {
      inputRef.current?.focus();
    }
  }, [focused, inputRef.current]);

  const setSearchRef = useCallback(
    (elem: HTMLDivElement | null) => {
      searchRef.current = elem ?? undefined;
      setWidth(elem?.offsetWidth);
    },
    [filterOpened]
  );

  return focused || search.keyword ? (
    <div
      className="flex h-full w-full items-center rounded-lg border border-sea-500 text-sumi-900 shadow-dropdown"
      onClick={() => setFocused(true)}
      ref={setSearchRef}
    >
      <Select
        optionsId="searchRangeMenu"
        value={search.searchRange}
        onChange={search.onChangeSearchRange}
        renderLabel={search.renderSearchRangeLabel}
        options={search.searchRangeOptions}
        className="h-6 border-r border-r-sumi-300 pl-2 text-sm"
        gap={10}
        variants={{
          rounded: 'none',
          border: false,
        }}
      />

      <div className="flex flex-1 items-center gap-2 pl-2 pr-4">
        <form
          onSubmit={search.filterForm.handleSubmit(search.search)}
          className="flex-1"
        >
          <input
            type="text"
            value={search.keyword}
            onChange={(e) => search.onChangeKeyword(e.target.value)}
            ref={inputRef}
            spellCheck={false}
            className="h-full w-full text-sm text-sumi-900 outline-none"
          />
        </form>
        {search.keyword && (
          <button
            type={'button'}
            className="flex h-[32px] w-[32px] cursor-pointer items-center justify-center rounded-full bg-transparent p-0 text-sumi-900 hover:bg-sumi-100"
            onClick={() => {
              search.onChangeKeyword('');
              inputRef.current?.focus();
            }}
          >
            <Icon icon={Close} size={14} />
          </button>
        )}
        <Dropdown
          onVisibleChange={setFilterOpened}
          overlay={
            <SearchFilterForm
              form={search.filterForm}
              onSubmit={search.search}
            />
          }
          placement="bottomLeft"
          width={width}
          offsetX={17}
          offsetY={4}
        >
          <div className="grid content-center rounded-full p-1 hover:bg-sumi-100">
            <Icon icon={Filter} />
          </div>
        </Dropdown>
      </div>
    </div>
  ) : (
    <div className="flex h-full w-full items-center gap-[10px] rounded-lg bg-sumi-50 px-4 py-2.5 text-sumi-500">
      <Icon icon={Search} />
      <div
        onClick={() => setFocused(true)}
        className="flex-1 cursor-text text-sm"
      >
        検索
      </div>
    </div>
  );
});
