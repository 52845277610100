import React, { useState } from 'react';
import styled from 'styled-components';
import { Icon, Popover } from 'antd';
import { isSP } from '../../../shared/util';
import { CreateMessageWysiwygEditor } from './CreateMessageWysiwygEditor';

export default ({
  teamSignatures,
  signature,
  submitting,
  setSignature,
  setSignatureBody,
  addSignature,
  removeSignature,
  readOnly,
  plainTextMode,
  htmlToolbarVisible,
  checkSignatureToolbarVisible,
  initEditorHandle,
  uploadImage,
  bubbleMenuContainerId,
}) => {
  const [hover, setHover] = useState(false);

  if (!signature) {
    if (readOnly || !teamSignatures || teamSignatures.length === 0) {
      return <div />;
    }
    return (
      <button
        type="button"
        className="block w-fit cursor-pointer rounded bg-transparent p-1 text-xs text-[#c5c6c5] hover:bg-[#F0F0F1] hover:text-[#000000a6]"
        onClick={addSignature}
      >
        <AddIcon type="plus" />
        署名を追加
      </button>
    );
  }

  const onFocus = () => {
    if (htmlToolbarVisible) checkSignatureToolbarVisible(true);
  };

  const onBlur = () => {
    checkSignatureToolbarVisible(false);
  };

  return (
    <Wrapper
      onMouseEnter={() => !isSP() && setHover(true)}
      onMouseLeave={() => !isSP() && setHover(false)}
    >
      {!readOnly && (
        <SignatureOptions>
          <Popover
            content={
              <Signatures>
                {teamSignatures.map((s) => (
                  <Signature
                    onClick={() => setSignature(s.signature)}
                    key={s.id}
                  >
                    <CheckIcon type="check" visible={signature.id === s.id} />
                    {s.title}
                  </Signature>
                ))}
              </Signatures>
            }
            trigger={isSP() ? 'click' : 'hover'}
          >
            <ExIcon type="up-circle" theme="filled" visible={isSP() || hover} />
          </Popover>

          <ExIcon
            type="close-circle"
            theme="filled"
            visible={isSP() || hover}
            onClick={removeSignature}
          />
        </SignatureOptions>
      )}
      <CreateMessageWysiwygEditor
        editorClassName="hover:bg-sumi-100"
        plainTextMode={plainTextMode}
        defaultValue={signature.bodyHtml}
        onChange={setSignatureBody}
        disabled={submitting || readOnly}
        onFocus={readOnly ? undefined : onFocus}
        onBlur={readOnly ? undefined : onBlur}
        initEditorHandle={initEditorHandle}
        uploadImage={uploadImage}
        bubbleMenuContainerId={bubbleMenuContainerId}
      />
    </Wrapper>
  );
};

const AddIcon = styled(Icon)`
  margin-right: 4px;
`;

const Wrapper = styled.div`
  position: relative;
`;

const SignatureOptions = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  padding-right: 8px;
`;

const ExIcon = styled(({ visible, ...props }) => <Icon {...props} />)`
  cursor: pointer;
  margin-right: 8px;
  ${({ visible }) => (visible ? 'visibility: visible' : 'visibility: hidden')};
`;

const CheckIcon = styled(({ visible, ...props }) => <Icon {...props} />)`
  ${({ visible }) => (visible ? 'visibility: visible' : 'visibility: hidden')};
  margin-right: 4px;
`;

const Signatures = styled.div`
  margin: -12px -16px;
`;

const Signature = styled.div`
  cursor: pointer;
  padding: 8px;

  :hover {
    background-color: #f0f0f1;
  }
`;
