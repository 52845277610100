import momentTZ from 'moment-timezone';
import { convertHtmlToQuote, decorateHtml, linkify, quoteText } from '../util';

export interface StorageSentData {
  teamId: string;
  inboxId: string;
  isReply: boolean;
  inReplyToMessageId: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inReplyToMessageRef: any;
  subject: string;
  text: string;
  html: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  attachments: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  from: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  to: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cc: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  bcc: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  emlStoragePath: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createdAt: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updatedAt: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  date: any;
}

export class StorageSent {
  private _id: string;
  private _data: StorageSentData;
  private _date: momentTZ.Moment;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _ref?: any;
  private _decoratedHtml?: string;

  constructor(id: string, data: StorageSentData) {
    this._id = id;
    this._data = data;

    let date;
    if (this._data.date._seconds) {
      // 2020/08/27日以前（当日を含む）はfirestoreのtimestampが文字列で入っているため
      date = momentTZ.unix(this._data.date._seconds);
    } else {
      date = momentTZ(this._data.date);
    }
    this._date = date; // 毎回生成するのはコストがかかるため
  }

  get id() {
    return this._id;
  }

  get ref() {
    return this._ref;
  }

  get date() {
    return this._date;
  }

  get teamId() {
    return this._data.teamId;
  }

  get inboxId() {
    return this._data.inboxId;
  }

  get isReply() {
    return this._data.isReply;
  }

  get inReplyToMessageId() {
    return this._data.inReplyToMessageId;
  }

  get inReplyToMessageRef() {
    return this._data.inReplyToMessageRef;
  }

  get subject() {
    return this._data.subject;
  }

  get text() {
    return this._data.text;
  }

  get quotedText() {
    return quoteText(this.text);
  }

  get html() {
    const html = this._data.html;
    if (!html) return html;
    if (!this._decoratedHtml) this._decoratedHtml = decorateHtml(linkify(html));
    return this._decoratedHtml;
  }

  get htmlForQuote() {
    const html = this._data.html;
    if (!html) return html; // html対応ではないメールの場合
    return convertHtmlToQuote(this._data.html);
  }

  get attachments() {
    return this._data.attachments;
  }

  get from() {
    return this._data.from;
  }

  get to() {
    return this._data.to;
  }

  get cc() {
    return this._data.cc;
  }

  get bcc() {
    return this._data.bcc;
  }

  get emlStoragePath() {
    return this._data.emlStoragePath;
  }

  get fromIconName() {
    return this.from.length > 0 ? this.from[0].toUpperCase() : '';
  }

  get createdAt() {
    return this._data.createdAt;
  }

  get updatedAt() {
    return this._data.updatedAt;
  }

  generateQuotedHeader() {
    return `${this.date.format('YYYY年M月D日(ddd) HH:mm')} ${this.from}:\n`;
  }

  generateForwardedHeader() {
    let body = `---------- Forwarded message ---------
From: ${this.from}
Date: ${this.date.format('YYYY年M月D日(ddd) HH:mm')}
Subject: ${this.subject}
To: ${this.to.join(', ')}
`;
    if (this.cc.length > 0) {
      body += `Cc: ${this.cc.join(', ')}\n`;
    }

    return body + `\n`;
  }
}
