import { useMemo } from 'react';

type Props = {
  count: number | null | undefined;
  className?: string;
};

export const LeftSidebarCountText = ({
  count,
  className,
}: Props): JSX.Element | null => {
  const numberText = useMemo(() => {
    if (count == null || count <= 0) {
      return null;
    }
    if (count >= 1000) {
      return '999+';
    } else {
      return `${count}`;
    }
  }, [count]);
  return numberText ? (
    <span className={`${className} font-bold text-sea-500`}>{numberText}</span>
  ) : null;
};
