import React, { Component } from 'react';
import { AutoComplete, Button, Icon, message, Tag, Tooltip } from 'antd';
import styled from 'styled-components';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { Link, withRouter } from 'react-router-dom';
import firebase from 'firebase.js';
import { db } from '../../../../../firebase';
import { Tag as TagEntity, TAG_COLORS } from 'lib';
import { convertTagColorToHexColor } from '../../../../../color';
import { eventNames, logEvent } from '../../../../../analytics';
import { getTagLabel } from '../../../../../util';
import NewTagModal from './newTagModal';
import { ReadOnlyTooltip } from '../../../../../components/ReadOnlyTooltip';
import { Loading } from 'components/basics';

const { Option, OptGroup } = AutoComplete;

class Tags extends Component {
  /**
   * @param props {
   *   message: Object
   * }
   */
  constructor(props) {
    super(props);

    this.state = {
      inputVisible: false,
      inputValue: '',
      searchText: '',
      options: [],
      isNewTagModalVisible: false,
      isLoading: false,
    };
  }

  componentDidMount() {
    this.setOptions('');
  }

  createDataSource = (tags, addTag) => {
    const datasource = [];
    if (tags.length > 0) {
      datasource.push({
        title: '作成済みのタグ',
        children: tags,
      });
    }

    if (addTag) {
      datasource.push({
        title: 'タグを新しく作る',
        children: [addTag],
      });
    }

    return datasource;
  };

  createOptions = (datasource) =>
    datasource.map((group) => (
      <OptGroup key={group.title} label={this.renderTitle(group.title)}>
        {group.children.map((tag) => {
          const parentTag = group.children.find(
            (t) => t.id === tag.parentTagId
          );
          const { shortLabel, label } = getTagLabel(tag.name, {
            parentTagLabel: parentTag?.name,
          });
          return (
            <Option key={tag.id} value={tag.id}>
              <Tag key={tag.id} color={tag.color}>
                {shortLabel ?? label}
              </Tag>
            </Option>
          );
        })}
      </OptGroup>
    ));

  renderTitle = (title) => {
    return (
      <>
        <span>
          {title}
          {title === '作成済みのタグ' && (
            <div style={{ float: 'right' }}>
              <Button
                type="link"
                style={{ marginRight: 10, padding: 0 }}
                onClick={() => this.setState({ isNewTagModalVisible: true })}
              >
                新規作成
              </Button>
              <Link to={`/settings/teams/${this.props.message.teamId}/tags`}>
                設定
              </Link>
            </div>
          )}
        </span>
      </>
    );
  };

  handleClose = (removedTag) => {
    const { store, messages } = this.props;
    store.messageStore.removeTags(
      messages.map((x) => x.id),
      removedTag
    );
    this.props.onRemoveTag?.(removedTag.id);
  };

  showInput = () => {
    this.setState({ inputVisible: true }, () => this.setOptions(''));
  };

  handleInputChange = (value) => {
    this.setState({
      inputValue: value,
    });
    if (value !== 'newTag') {
      this.setState({ searchText: value });
    }
  };

  cancelInput = () => {
    this.setState({
      inputVisible: false,
      inputValue: '',
    });
    this.onSearch('');
  };

  handleInputConfirm = async () => {
    const { message, messages, store } = this.props;
    const { teamId } = message;
    const { inputValue, searchText } = this.state;

    if (inputValue === '') {
      this.setState({
        inputVisible: false,
      });
      return;
    }

    // チームのタグを取得する
    const teamTags = this.getTags();
    let tag = teamTags.find((t) => t.id === inputValue);
    if (!tag) {
      // チームにタグが存在しない場合、タグを作成する
      // まだ使われていない色を抽出する
      let unusedColors = TAG_COLORS.filter(
        (c) => !teamTags.some((t) => t.color === c)
      );
      if (unusedColors.length === 0) unusedColors = TAG_COLORS;
      // ランダムに選択
      const color =
        unusedColors[Math.floor(Math.random() * unusedColors.length)];

      const ref = await db
        .collection(`companies/${this.props.store.signInCompany}/tags`)
        .add({
          name: searchText,
          color,
          teamId,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
      tag = new TagEntity(await ref.get());
      logEvent(eventNames.add_tag);
    }

    // メッセージにタグを追加する
    await store.messageStore.addTags([messages.slice(-1)[0].id], tag);

    logEvent(eventNames.attach_tag);

    this.setState({
      inputVisible: false,
      inputValue: '',
      searchText: '',
      isLoading: false,
    });
    this.onSearch('');
  };

  onSelect = (value) => {
    this.setState({ isLoading: true });
    this.setState({ inputValue: value }, this.handleInputConfirm);
  };

  onSearch = (searchText) => {
    this.setOptions(searchText);
  };

  setOptions = (searchText) => {
    const { message } = this.props;
    const tags = this.getTags();

    let searchedTags = tags
      .filter((t) => !message.tags.some((tt) => t.id === tt)) // すでにメッセージに紐付いているタグは表示しない
      .filter((t) => t.name.indexOf(searchText) !== -1); // 検索
    // タグが存在しない場合は、新規作成として選択肢を出す
    const tagExists =
      searchText === '' || tags.some((t) => t.name === searchText);

    const datasource = this.createDataSource(
      searchedTags,
      tagExists ? null : { id: 'newTag', name: searchText }
    );
    const options = this.createOptions(datasource);

    this.setState({
      options,
    });
  };

  getTags = () =>
    this.props.message.deleted
      ? this.props.store.getTagsExceptInbox(this.props.message.teamId)
      : this.props.store.getTags(this.props.message.teamId);

  add = async (tag) => {
    const { name, color, parentTagId } = tag;
    const { teamId } = this.props.match.params;
    // チームのタグを取得する
    const teamTags = this.props.store.getTags(teamId);
    // すでに存在する場合、作成しない
    if (teamTags.some((t) => t.name === name)) {
      throw Error('すでに同一のタグが存在します');
    }

    await db
      .collection(`companies/${this.props.store.signInCompany}/tags`)
      .add({
        name,
        color,
        parentTagId,
        teamId: this.props.match.params.teamId,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      });
    logEvent(eventNames.add_tag);
    message.success('タグを作成しました');

    this.setState({ inputValue: name }, this.handleInputConfirm);
  };

  render() {
    const { message, showLess } = this.props;
    const { inputVisible, inputValue, options, isLoading } = this.state;

    const teamTags = this.getTags();
    const messageTags = message.tags
      .map((t) => teamTags.find((tt) => tt.id === t))
      .filter((t) => t); // 削除されたタグは表示しない

    const isReadOnly = this.props.store.me.isReadOnly || this.props.isReadOnly;

    const messageTagsShow = showLess ? messageTags.slice(0, 3) : messageTags;

    return (
      <Wrapper>
        {messageTagsShow.map((tag) => {
          const parentTag = teamTags.find((t) => t.id === tag.parentTagId);
          const { shortLabel, label } = getTagLabel(tag.name, {
            parentTagLabel: parentTag?.name,
          });
          const tagElem = (
            <ExTag
              key={tag.id}
              closable={!isReadOnly}
              onClose={() => !isReadOnly && this.handleClose(tag)}
              color={tag.color}
            >
              {shortLabel ?? label}
            </ExTag>
          );
          return shortLabel ? (
            <Tooltip title={label} key={tag.id}>
              {tagElem}
            </Tooltip>
          ) : (
            tagElem
          );
        })}
        {showLess && messageTags.length > 3 && (
          <span>+{messageTags.length - 3}</span>
        )}
        {!isReadOnly && (
          <div className="w-full sm:w-auto">
            {isLoading && <Loading />}
            {!isLoading && inputVisible && (
              <ExAutoComplete
                dropdownMatchSelectWidth={false}
                dropdownStyle={{ width: 300 }}
                size="small"
                value={inputValue}
                onSelect={this.onSelect}
                onSearch={this.onSearch}
                onChange={this.handleInputChange}
                onBlur={this.cancelInput}
                dataSource={options}
                placeholder="タグ名"
                optionLabelProp="value"
                autoFocus
                defaultOpen
              />
            )}
            {!isLoading && !inputVisible && (
              <ReadOnlyTooltip action={'change'} enabled={isReadOnly}>
                <AddTag
                  onClick={!isReadOnly && this.showInput}
                  disabled={isReadOnly}
                >
                  <Icon type="plus" /> タグを追加
                </AddTag>
              </ReadOnlyTooltip>
            )}
          </div>
        )}
        <NewTagModal
          open={this.state.isNewTagModalVisible}
          onClose={() => this.setState({ isNewTagModalVisible: false })}
          add={(tag) => {
            this.add(tag);
            this.setState({ isNewTagModalVisible: false });
          }}
          tags={teamTags}
        />
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 6px;
`;

const ExTag = styled(Tag)`
  margin: 0 !important;
  line-height: 30px;
  border-radius: 8px;

  // クローズの☓アイコンの色
  i {
    color: ${({ color }) =>
      color ? convertTagColorToHexColor(color) : ''} !important;
  }
`;

const AddTag = styled(Tag)`
  margin: 0 !important;
  background: #fff;
  border-style: dashed;
  line-height: 30px;
  border-radius: 8px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`;

const ExAutoComplete = styled(AutoComplete)`
  margin: 2px 4px !important;
  width: 91px;
`;

export default compose(withRouter, inject('store'), observer)(Tags);
