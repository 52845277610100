import React, { FC } from 'react';

type Props = {
  width: string | number;
};

export const CommentSkeleton: FC<Props> = ({ width }) => {
  const cssWidth = typeof width === 'number' ? `${width}px` : width;
  return (
    <div
      className="grid grid-cols-[auto_1fr] items-start gap-2"
      style={{ width: cssWidth }}
    >
      <div className="h-9 w-9 animate-pulse rounded-full bg-sumi-200" />
      <div className="flex flex-col gap-1">
        <div className="h-4 w-1/3 animate-pulse rounded bg-sumi-200" />
        <div className="h-9 w-full animate-pulse rounded-lg bg-sumi-200" />
      </div>
    </div>
  );
};
