import { Alert, Checkbox, Icon, Modal, Radio, Switch, TreeSelect } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import firebase from 'firebase.js';
import { History } from 'history';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import styled from 'styled-components';
import * as color from '../../../color';
import { Store } from '../../../store';
import { H1 } from '../../Common/H1';
import { H2 } from '../../Common/H2';
import { List } from '../common';
import { IntegrationRoutes } from '../integration';
import { MyNotification } from './MyNotifications';
import { updateDoc } from 'firebase/firestore';
import { UserWebNotificationType } from 'lib';

const { TreeNode } = TreeSelect;

const CheckboxGroup = Checkbox.Group;

const radioStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '30px',
};

type Props = {
  store: Store;
  history: History;
  integrationRoutes: IntegrationRoutes;
};

class General extends React.Component<Props> {
  onChangeWebEnabled = async (enabled: boolean) => {
    await updateDoc(this.props.store.me.ref, {
      'notificationSettings.web.enabled': enabled,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
  };

  onChangeWebTypes = async (types: UserWebNotificationType[]) => {
    if (
      (['newComment', 'mentionedMessage'] as UserWebNotificationType[]).every(
        (t) => types.includes(t)
      )
    ) {
      // すべての新規コメントがONになった場合、自分宛て（@メンション）の新規コメントもONにする
      types = [...types, 'mentionedMessage'];
    }
    await updateDoc(this.props.store.me.ref, {
      'notificationSettings.web.types': types,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
  };

  getOptions = (
    types: string[]
  ): {
    label: string;
    value: UserWebNotificationType;
    disabled?: boolean;
  }[] => [
    { label: 'すべての新規メール', value: 'newMessage' },
    { label: 'すべての新規コメント', value: 'newComment' },
    {
      label: '自分宛て（@メンション）の新規コメント',
      value: 'mentionedMessage',
      disabled: types.indexOf('newComment') !== -1,
    },
    { label: '新規チャット', value: 'newChat' },
    {
      label: 'メールの開封',
      value: 'opened',
      disabled: !this.props.store.isSeenNotificationSupported,
    },
  ];

  setDefaultInboxId = async (value: string) => {
    await updateDoc(this.props.store.me.ref, {
      defaultInboxId: value,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
  };

  setPlainTextMode = async (e: RadioChangeEvent) => {
    await updateDoc(this.props.store.me.ref, {
      plainTextMode: e.target.value,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
  };

  toggleThreadView = (enable: boolean) => {
    Modal.confirm({
      title: '',
      content: (
        <div>
          <p>スレッド表示を変更するには、リロードする必要があります。</p>
        </div>
      ),
      onOk: () => this.props.store.privateStore.toggleThreadView(enable),
      onCancel: () => {
        //
      },
      okText: 'リロード',
      cancelText: 'キャンセル',
      maskClosable: true,
    });
  };

  render() {
    const { store } = this.props;
    const {
      me,
      notificationPermission,
      notificationPermissionError,
      getTeamInboxes,
      teamsLoading,
      usersLoading,
      joinedTeams,
    } = store;
    const { webNotificationEnabled, webNotificationTypes } = me;

    return (
      <List>
        <H1>個人設定</H1>
        <Title>
          <Icon type="mail" style={{ marginRight: 4, fontSize: 21 }} />
          新規作成時のメールアドレス
        </Title>
        <Content>
          <TreeSelect
            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
            treeDefaultExpandAll
            value={teamsLoading ? undefined : me.defaultInboxId ?? undefined}
            loading={teamsLoading || usersLoading}
            onSelect={this.setDefaultInboxId}
            style={{ minWidth: '160px' }}
            disabled={this.props.store.me.isReadOnly}
          >
            {joinedTeams.map((team) => (
              <TreeNode
                value={team.id}
                title={team.name}
                selectable={false}
                key={team.id}
              >
                {getTeamInboxes(team.id).map((inbox) => (
                  <TreeNode
                    value={inbox.id}
                    title={inbox.emailWithAlias}
                    key={inbox.id}
                  />
                ))}
              </TreeNode>
            ))}
          </TreeSelect>
        </Content>

        <Title>
          <Icon type="font-colors" style={{ marginRight: 4, fontSize: 21 }} />
          メール作成時のモード
        </Title>
        <Content>
          <Radio.Group
            onChange={this.setPlainTextMode}
            value={me.plainTextMode}
            disabled={this.props.store.me.isReadOnly}
          >
            <Radio style={radioStyle} value={false}>
              HTML
            </Radio>
            <Radio style={radioStyle} value={true}>
              プレーンテキスト
            </Radio>
          </Radio.Group>
        </Content>

        <Title>
          <Icon type="build" style={{ marginRight: 4, fontSize: 21 }} />
          <label className="flex items-center">
            スレッド表示
            <Switch
              checked={store.isInThreadView}
              onChange={this.toggleThreadView}
              style={{ marginLeft: 12 }}
            />
          </label>
        </Title>

        <Title>
          <Icon type="notification" style={{ marginRight: 4, fontSize: 21 }} />
          ブラウザ通知
          <Switch
            checked={webNotificationEnabled}
            onChange={this.onChangeWebEnabled}
            style={{ marginLeft: 12 }}
          />
        </Title>
        <Content>
          {notificationPermission === 'denied' && (
            <Alert
              message="通知が拒否されているため、このブラウザへの通知が無効になっています。"
              description="ブラウザの通知設定から通知を許可するよう設定してください"
              type="error"
              showIcon
              style={{ marginBottom: 20 }}
            />
          )}
          {notificationPermission === 'default' && (
            <Alert
              message="通知が許可されていないため、このブラウザへの通知が無効になっています。"
              description="ブラウザの通知設定から通知を許可するよう設定してください"
              type="warning"
              showIcon
              style={{ marginBottom: 20 }}
            />
          )}
          {notificationPermissionError && (
            <Alert
              message={notificationPermissionError}
              type="error"
              showIcon
              style={{ marginBottom: 20 }}
            />
          )}
          <CheckboxGroup
            options={this.getOptions(webNotificationTypes)}
            value={webNotificationTypes}
            onChange={(value) =>
              this.onChangeWebTypes(value as UserWebNotificationType[])
            }
            disabled={!webNotificationEnabled}
          />
        </Content>

        {this.props.store.isV2Plan && (
          <div style={{ marginTop: 32 }}>
            <MyNotification integrationRoutes={this.props.integrationRoutes} />
          </div>
        )}
      </List>
    );
  }
}

const Content = styled.div`
  margin-top: 20px;
  color: ${color.common.text2};
`;

const Title = styled(H2)`
  margin-top: 30px;
  display: flex;
  align-items: center;
`;

export default compose<Props, Omit<Props, 'store' | 'history'>>(
  withRouter,
  inject('store'),
  observer
)(General);
