import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Button, Empty, Icon, Spin } from 'antd';
import { Message } from '../../Teams/Messages/Message';
import { useStore } from 'hooks/useStore';
import { Comment } from 'lib';
import { WrappedInfiniteScroll } from '../../../../../components/WrappedInfiniteScroll';
import { useAtom, useAtomValue } from 'jotai';
import { mentionedMessagesAtom } from '../../../../../atoms/firestore/mention';
import { mentionedCountAtom } from '../../../../../atoms/firestore/count/mentioned';

const Mentioned = observer(() => {
  const store = useStore();
  const [mentionedMessagesPaginate, dispatch] = useAtom(mentionedMessagesAtom);
  const mentionedCount = useAtomValue(mentionedCountAtom);
  const history = useHistory();
  const [markingAsRead, setMarkingAsRead] = useState(false);

  const to = (link: string) => history.push(link);

  const loadMore = async () => {
    await dispatch('loadMore');
  };

  const toLink = (comment: Comment) => {
    const messageId = store.isInThreadView
      ? comment.threadId
      : comment.messageId;
    let url = `/me/mentioned/messages/${messageId}/comments/${comment.id}`;
    if (store.preferences.threadView) {
      url += '?view=thread';
    }
    return url;
  };

  const markAllAsRead = async () => {
    setMarkingAsRead(true);
    await store.serverCounterStore.markAllMentionedRead();
    setMarkingAsRead(false);
  };

  const isEmpty =
    mentionedMessagesPaginate.state === 'hasData' &&
    mentionedMessagesPaginate.data.length === 0;

  return (
    <WrappedInfiniteScroll
      initialLoad={false}
      pageStart={0}
      loadMore={loadMore}
      hasMore={mentionedMessagesPaginate.hasMore}
      useWindow={false}
      className="pt-4"
    >
      <Spin
        spinning={mentionedMessagesPaginate.state === 'loading'}
        indicator={<Icon type="loading" spin />}
      >
        {isEmpty ? (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="メンションはありません"
          />
        ) : (
          mentionedCount > 0 && (
            <div className="px-5 py-2 text-right">
              <Button
                size="small"
                loading={markingAsRead}
                onClick={markAllAsRead}
              >
                すべて既読にする
              </Button>
            </div>
          )
        )}

        {mentionedMessagesPaginate.state === 'hasData' &&
          mentionedMessagesPaginate.data.map((mentionedMessage) => {
            const { comment, message } = mentionedMessage;
            return (
              <Message
                message={message}
                comment={comment}
                checkMessage={store.checkMessage}
                uncheckMessage={store.uncheckMessage}
                checkedMessages={store.checkedMessages}
                onClick={() => to(toLink(comment))}
                to={toLink(comment)}
                key={comment.id}
                me={store.me}
                checkDisabled={true}
                getTag={store.getTag}
                isReadOnly={store.me.isReadOnly}
              />
            );
          })}
      </Spin>
    </WrappedInfiniteScroll>
  );
});

export default Mentioned;
