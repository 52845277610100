import { ComponentPropsWithoutRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { SidebarSectionTitle } from '../../../components/sidebar/SidebarSectionTitle/SidebarSectionTitle';

type Props = Omit<ComponentPropsWithoutRef<'div'>, 'title'> & {
  title: string;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
};

export const SettingsSidebarSection = ({
  title,
  open,
  onOpenChange,
  className,
  children,
}: Props) => {
  return (
    <div className={twMerge('flex flex-col', className)}>
      <SidebarSectionTitle open={open} onOpenChange={onOpenChange}>
        {title}
      </SidebarSectionTitle>
      {(open == null || open) && (
        <div className="flex flex-col gap-[1px]">{children}</div>
      )}
    </div>
  );
};
