import * as React from 'react';
import type { SVGProps } from 'react';
const SvgAddColumnRight = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="currentColor"
    viewBox="0 -960 960 960"
    {...props}
  >
    <path d="M411.15-760H172.31q-5.39 0-8.85 3.46t-3.46 8.85v535.38q0 5.39 3.46 8.85t8.85 3.46h238.84v-560ZM172.31-140q-29.83 0-51.07-21.24Q100-182.48 100-212.31v-535.38q0-29.83 21.24-51.07Q142.48-820 172.31-820h535.38q29.83 0 51.07 21.24Q780-777.52 780-747.69v40q0 12.75-8.63 21.37-8.63 8.63-21.38 8.63-12.76 0-21.37-8.63-8.62-8.62-8.62-21.37v-40q0-5.39-3.46-8.85t-8.85-3.46H471.15v560h236.54q5.39 0 8.85-3.46t3.46-8.85v-40q0-12.75 8.63-21.37 8.63-8.63 21.38-8.63 12.76 0 21.37 8.63 8.62 8.62 8.62 21.37v40q0 29.83-21.24 51.07Q737.52-140 707.69-140H172.31Zm577.68-230q-12.76 0-21.37-8.63Q720-387.25 720-400v-50h-50q-12.75 0-21.38-8.63-8.62-8.63-8.62-21.38 0-12.76 8.62-21.37Q657.25-510 670-510h50v-50q0-12.75 8.63-21.37 8.63-8.63 21.38-8.63 12.76 0 21.37 8.63Q780-572.75 780-560v50h50q12.75 0 21.37 8.63 8.63 8.63 8.63 21.38 0 12.76-8.63 21.37Q842.75-450 830-450h-50v50q0 12.75-8.63 21.37-8.63 8.63-21.38 8.63ZM471.15-480Zm-60 0h60-60Zm0 0Z" />
  </svg>
);
export default SvgAddColumnRight;
