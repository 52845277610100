import React from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Button, Table, Tag } from 'antd';
import { db } from '../../../firebase';
import styled from 'styled-components';
import { Account } from 'lib';

const pageSize = 15;

class Index extends React.Component {
  state = {
    accounts: [],
    accountsLoading: false,
    hasPrevious: false,
    hasPreviousLoading: false,
    hasNext: false,
    hasNextLoading: false,
    orderByField: 'name',
    order: 'asc',
  };

  componentDidMount() {
    this.getAccounts();
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  getQuery = () => {
    const { teamId } = this.props.match.params;
    const { orderByField, order } = this.state;
    let query = db
      .collection(`companies/${this.props.store.signInCompany}/accounts`)
      .where('teamId', '==', teamId)
      .orderBy(orderByField, order);
    return query;
  };

  unsubscribe = () => {
    if (this._unsubscribe) this._unsubscribe();
  };

  getAccounts = ({ startAfter, endBefore } = {}) => {
    this.unsubscribe();

    let query = this.getQuery();

    if (endBefore) {
      query = query.limitToLast(pageSize);
    } else {
      query = query.limit(pageSize);
    }

    if (startAfter) query = query.startAfter(startAfter);
    if (endBefore) query = query.endBefore(endBefore);

    this.setState({
      accountsLoading: true,
      hasPreviousLoading: true,
      hasNextLoading: true,
    });

    this._unsubscribe = query.onSnapshot(
      (snapshot) => {
        let accounts = [];
        let existingDomains = [];
        snapshot.forEach((doc) => {
          accounts.push(new Account(doc));
        });
        this.setState(
          {
            accounts,
            accountsLoading: false,
            existingDomains,
          },
          () => {
            this.setHasPrevious();
            this.setHasNext();
          }
        );
      },
      (err) => {
        // MEMO: チーム権限が外れた場合にエラーとなるが、リスナーがデタッチされるのは正しい挙動なので問題なし
        console.log(err);
      }
    );
  };

  setHasPrevious = async () => {
    const firstAccount = await this.getFirstAccountSnapshot();
    if (!firstAccount) {
      this.setState({ hasPrevious: false, hasPreviousLoading: false });
      return;
    }

    const snapshot = await this.getQuery()
      .limitToLast(1)
      .endBefore(firstAccount)
      .get();

    this.setState({ hasPrevious: !snapshot.empty, hasPreviousLoading: false });
  };

  setHasNext = async () => {
    const lastAccount = await this.getLastAccountSnapshot();
    if (!lastAccount) {
      this.setState({ hasNext: false, hasNextLoading: false });
      return;
    }

    const snapshot = await this.getQuery()
      .limit(1)
      .startAfter(lastAccount)
      .get();

    this.setState({ hasNext: !snapshot.empty, hasNextLoading: false });
  };

  getFirstAccountSnapshot = async () => {
    const { accounts } = this.state;
    if (accounts.length === 0) return null;
    return await accounts[0].ref.get();
  };

  getLastAccountSnapshot = async () => {
    const { accounts } = this.state;
    if (accounts.length === 0) return null;
    return await accounts[accounts.length - 1].ref.get();
  };

  goPrevious = async () => {
    const endBefore = await this.getFirstAccountSnapshot();
    this.getAccounts({ endBefore });
  };

  goNext = async () => {
    const startAfter = await this.getLastAccountSnapshot();
    this.getAccounts({ startAfter });
  };

  onChange = (pagination, filter, sorter) => {
    const { field, order } = sorter;
    this.setState(
      {
        // 他のフィールドのソートを外した状態でtagsをチェックすると、fieldがundefinedになり、ページングでエラーとなるため前回のソートを残している
        orderByField: field || this.state.orderByField,
        order: order === 'descend' ? 'desc' : 'asc',
      },
      this.getAccounts
    );
  };

  componentDidUpdate(prevProps) {
    if (prevProps.keyword !== this.props.keyword) {
      this.search(this.props.keyword);
    }
  }

  columns = () => {
    return [
      {
        title: '会社名',
        dataIndex: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name, 'ja'),
        sortDirections: ['ascend', 'descend'],
        defaultSortOrder: 'ascend',
      },

      {
        title: 'ドメイン',
        dataIndex: 'domains',
        render: (domains) =>
          domains.length > 0
            ? domains.map((domain) => {
                return (
                  <Tag style={{ marginBottom: 4 }} key={domain}>
                    {domain}
                  </Tag>
                );
              })
            : null,
      },
      {
        title: '外部ID',
        dataIndex: 'externalId',
      },
    ];
  };

  search = async (keyword) => {
    this.setState({
      accountsLoading: true,
      hasPreviousLoading: true,
      hasNextLoading: true,
    });
    const { teamId } = this.props.match.params;
    const accounts = await this.props.store.searchAccounts(teamId, keyword, 60);
    this.setState(
      {
        accounts,
        accountsLoading: false,
      },
      () => {
        this.setHasPrevious();
        this.setHasNext();
      }
    );
  };

  render() {
    const isReadOnly = this.props.store.me.isReadOnly;
    const { selectedAccountIds } = this.props;
    const { teamId } = this.props.match.params;
    const {
      accounts,
      accountsLoading,
      hasPrevious,
      hasPreviousLoading,
      hasNext,
      hasNextLoading,
    } = this.state;
    return (
      <div>
        {!isReadOnly && selectedAccountIds.length > 0 && (
          <Button
            size="small"
            type="link"
            onClick={() => this.props.setSelectedAccountIds([])}
          >
            選択をすべて解除
          </Button>
        )}
        <Table
          rowKey="id"
          rowSelection={{
            selectedRowKeys: selectedAccountIds,
            onChange: this.props.setSelectedAccountIds,
            getCheckboxProps: () => ({
              disabled: isReadOnly,
            }),
          }}
          pagination={false}
          columns={this.columns()}
          dataSource={accounts}
          loading={accountsLoading}
          onChange={this.onChange}
          locale={{
            filterConfirm: '適用',
            filterReset: 'クリア',
          }}
          size="small"
          onRowClick={(record) =>
            !isReadOnly &&
            this.props.history.push(
              `/contacts/${teamId}/contacts/accounts/${record.id}`
            )
          }
        />
        <Pagination>
          <Button
            icon="left"
            style={{ marginRight: 8 }}
            onClick={this.goPrevious}
            loading={hasPreviousLoading}
            disabled={!hasPrevious}
          />
          <Button
            icon="right"
            onClick={this.goNext}
            loading={hasNextLoading}
            disabled={!hasNext}
          />
        </Pagination>
      </div>
    );
  }
}

export default compose(withRouter, inject('store'), observer)(Index);

const Pagination = styled.div`
  float: right;
  margin: 16px 0;
  padding-bottom: 16px;
`;
