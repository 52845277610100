import { Button, message, Modal, Table, Tag, Upload } from 'antd';
import { AddButton } from '../../Settings/common';
import {
  batchAddOrUpdateContacts,
  csvFieldNames,
  deduplicateContactValues,
  detectFileEncoding,
  filterContactValues,
  hasValidFields,
} from '../Team/importCsv';
import React, { ReactNode, useState } from 'react';
import { UploadProps } from 'antd/lib/upload/interface';
import Papa, { ParseResult } from 'papaparse';
import { useParams } from 'react-router-dom';
import { useStore } from '../../../hooks/useStore';
import { useAtomValue } from 'jotai';
import { companyAtom } from '../../../atoms/auth';

type Props = {
  open: boolean;
  onOpenChange: (open: boolean) => void;
};

const MAX_ROW_IMPORT = 10000;

export const ContactsImportDialog = ({ open, onOpenChange }: Props) => {
  const store = useStore();
  const { teamId } = useParams<{ teamId: string }>();
  const [contactsToImport, setContactsToImport] = useState<any[]>([]);
  const [duplicatedContacts, setDuplicatedContacts] = useState<any[]>([]);
  const [overwriteDuplicated, setOverwriteDuplicated] = useState(false);
  const [importing, setImporting] = useState(false);
  const { id: companyId } = useAtomValue(companyAtom);

  const validateCsvData = (result: ParseResult<any>) => {
    const showErrorModal = (content: ReactNode) =>
      Modal.error({
        title: 'インポート作業に失敗しました',
        content: content,
        width: 600,
      });
    if (result.data.length > MAX_ROW_IMPORT) {
      showErrorModal(
        <p>一度にインポートできる最大件数(10,000件)を超えています。</p>
      );
      return false;
    }
    if (!hasValidFields(result.meta.fields)) {
      showErrorModal(
        <>
          <p>
            以下の原因が考えられるので、確認・修正のうえ再度お試しください。
          </p>
          <ul>
            <li>ヘッダ行（1行目の列名が書かれている行）がない</li>
            <li>
              必要な列が欠けている
              <ul>
                <li>例:「タグ」の列がない</li>
              </ul>
            </li>
            <li>
              不要な列がある
              <ul>
                <li>例:「ふりがな」の列がある</li>
              </ul>
            </li>
            <li>
              列の順番が異なる
              <ul>
                <li>
                  例:「名前」「メールアドレス」「電話番号」「タグ」...
                  の順になっている
                </li>
              </ul>
            </li>
          </ul>
        </>
      );
      return false;
    }
    return true;
  };

  const validateContact = async (rows: { email: string }[]) => {
    rows = rows.filter((line) => line.email);

    const existing = await store.getContactsByTeamId(teamId);
    const { add: contactsToAdd, update: contactsToUpdate } =
      filterContactValues(existing, rows);

    setContactsToImport(contactsToAdd);
    setDuplicatedContacts(deduplicateContactValues(contactsToUpdate));
  };

  const uploadProps: UploadProps = {
    accept: '.csv',
    customRequest: () => {
      //
    },
    name: 'file',
    multiple: false,
    showUploadList: false,
    beforeUpload: async (file) => {
      const encoding = await detectFileEncoding(file);

      Papa.parse(file as never, {
        encoding,
        header: true,
        skipEmptyLines: 'greedy',
        transformHeader: (header) => {
          const fieldName = csvFieldNames.find((v) => v[0] === header);
          return fieldName ? fieldName[1] : header;
        },
        transform: (value, field) =>
          field === 'tags'
            ? value
                .split(',')
                .filter((v) => v)
                .sort()
            : value,
        complete: (result) => {
          const valid = validateCsvData(result);
          if (!valid) {
            return;
          }
          validateContact(result.data as never[]);
        },
      });
    },
  };

  const importContact = async () => {
    setImporting(true);
    try {
      const existing = await store.getContactsByTeamId(teamId);
      await batchAddOrUpdateContacts(
        companyId,
        teamId,
        store.getContactTags(teamId),
        existing,
        contactsToImport,
        overwriteDuplicated
      );

      message.success('コンタクトをインポートしました');
    } catch (e) {
      message.error('コンタクトのインポートに失敗しました');
      console.error(e);
    }

    onOpenChange(false);
    setContactsToImport([]);
    setDuplicatedContacts([]);
    setOverwriteDuplicated(false);
    setImporting(false);
  };

  return (
    <>
      <Modal
        visible={open}
        onCancel={() => onOpenChange(false)}
        footer={null}
        width={800}
      >
        <div className="my-8 text-center text-lg font-bold">
          コンタクト情報をインポート
        </div>
        <ul>
          <li>
            コンタクト情報
            (名前、メールアドレス、タグ、会社名、電話番号、メモ)をCSVファイルでインポートすることができます。
            <br />
            CSVテンプレートは
            <a href={`${process.env.PUBLIC_URL}/contactTemplate.csv`}>こちら</a>
            です
          </li>
          <li>メールアドレスの入力は必須かつ重複は不可です</li>
        </ul>
        <Upload.Dragger {...uploadProps}>
          <p>ここにCSVファイルをドロップ</p>
          または
          <div className="mb-2 mt-6 flex justify-center">
            <AddButton>ファイルを選択</AddButton>
          </div>
          <p>一度に10,000件までインポート可能です</p>
        </Upload.Dragger>
      </Modal>
      <Modal
        width="100%"
        style={{ top: 0 }}
        visible={duplicatedContacts.length > 0}
        onCancel={() => {
          setContactsToImport([]);
          setDuplicatedContacts([]);
        }}
        title="重複するコンタクトがあります"
        footer={[
          <Button key="ignore" onClick={() => setDuplicatedContacts([])}>
            無視
          </Button>,
          <Button
            key="overwrite"
            onClick={() => {
              setContactsToImport(
                deduplicateContactValues([
                  ...contactsToImport,
                  ...duplicatedContacts,
                ])
              );
              setDuplicatedContacts([]);
              setOverwriteDuplicated(true);
            }}
            type="primary"
          >
            上書き
          </Button>,
        ]}
      >
        <Table
          size="small"
          columns={csvFieldNames.map((field) => ({
            title: field[0],
            dataIndex: field[1],
            render: (text) =>
              Array.isArray(text)
                ? text.map((t) => <Tag key={t}>{t}</Tag>)
                : text,
          }))}
          dataSource={duplicatedContacts.sort((a, b) =>
            a.email.localeCompare(b.email)
          )}
          rowKey={(_, index) => index.toString()}
        />
      </Modal>
      <Modal
        width="100%"
        style={{ top: 0 }}
        confirmLoading={importing}
        visible={duplicatedContacts.length === 0 && contactsToImport.length > 0}
        title={
          <>
            {contactsToImport.length}
            件のコンタクトをインポートします。よろしいですか？
          </>
        }
        onCancel={() => setContactsToImport([])}
        onOk={() => importContact()}
        okText={'インポート'}
        cancelText={'キャンセル'}
      >
        <Table
          size="small"
          columns={csvFieldNames.map((field) => ({
            title: field[0],
            dataIndex: field[1],
            render: (text) =>
              Array.isArray(text)
                ? text.map((t) => <Tag key={t}>{t}</Tag>)
                : text,
          }))}
          dataSource={contactsToImport}
          rowKey={(_, index) => index.toString()}
        />
      </Modal>
    </>
  );
};
