import React from 'react';
import { SPMessageListNav } from './SPMessageListNav';

type Props = {
  children?: React.ReactNode;
};

export const SPMessageListLayout: React.FC<Props> = ({ children }) => {
  return (
    <div className="flex h-screen flex-col">
      <SPMessageListNav />
      <div className="h-full flex-1 overflow-auto px-3">{children}</div>
    </div>
  );
};
