import { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { Button, Icon, Spin, Tooltip } from 'antd';
import styled from 'styled-components';

import { useStore } from 'hooks/useStore';
import { Label } from './ContactInfo';
import moment from 'moment';
import { SalesforceForm } from './SalesforceForm';
import { RecordType } from 'store/salesforce';
import { SalesforceDetails } from './SalesforceDetails';
import { SalesforceLink } from './SalesforceLink';

const styles = {
  seperator: 'border-b-[.5px] border-[#f1f1f3] pb-2 mb-2',
};

export const SalesforceContact = observer((): JSX.Element => {
  const { contactStore, salesforceStore, oauthStore, settingsStore } =
    useStore();
  const [contact, setContact] = useState<any>();
  const [lead, setLead] = useState<any>();
  const [cases, setCases] = useState<any[]>([]);
  const [opportunities, setOpportunities] = useState<any[]>([]);
  const [tasks, setTasks] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [editingType, setEditingType] = useState<RecordType>();
  const [formData, setFormData] = useState<any>();

  const instanceUrl =
    oauthStore.salesforceIntegration?.get('data.instance_url');
  const isClassic =
    settingsStore.salesforce?.get('interfaceType') === 'classic';

  const fetchSalesforceRecord = async (payload?: any) => {
    if (contactStore.selectedContact?.email) {
      payload = payload || {
        email: contactStore.selectedContact.email,
      };
      setLoading(true);
      const data = await salesforceStore.getContactByEmail(payload);
      const type = data?.attributes?.type;
      if (type === 'Contact') {
        setLead(undefined);
        setContact(data);
        setCases(data.Cases?.records || []);
        setOpportunities(data.Opportunities?.records || []);
        setTasks(data.Tasks?.records || []);
      } else if (type === 'Lead') {
        setContact(undefined);
        setLead(data);
        setTasks(data.Tasks?.records || []);
      } else {
        setLead(undefined);
        setContact(undefined);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSalesforceRecord();
  }, [contactStore.selectedContact]);

  const contactFormData = useMemo(() => {
    const name = contactStore.selectedContact?.name || '';
    let FirstName,
      LastName = name;
    if (name.includes(' ')) {
      const space = name.indexOf(' ');
      LastName = name.slice(0, space);
      FirstName = name.slice(space + 1);
    }
    return {
      Email: contactStore.selectedContact?.email,
      FirstName,
      LastName,
    };
  }, [contactStore.selectedContact]);

  const startEditingEntry = async (type: RecordType, entry = {}) => {
    setEditingType(type);
    setFormData(entry);
  };

  const onSubmit = async (form: any) => {
    if (!editingType) {
      return;
    }

    if (formData.Id) {
      form.Id = formData.Id;
    }
    if (editingType === 'Case') {
      form.ContactId = contact.Id;
    } else if (editingType === 'Task') {
      form.WhoId = lead?.Id || contact.Id;
    }

    const record = await salesforceStore.saveRecord(editingType, form);
    if (editingType === 'Opportunity' && !formData.Id) {
      await salesforceStore.saveRecord('OpportunityContactRole', {
        ContactId: contact.Id,
        OpportunityId: record.id,
        IsPrimary: true,
      });
    }

    setEditingType(undefined);
    let payload;
    if (contact || editingType === 'Contact') {
      payload = { contactId: contact?.Id || record.id };
    } else if (lead || editingType === 'Lead') {
      payload = { leadId: lead?.Id || record.id };
    }
    fetchSalesforceRecord(payload);
  };

  if (loading) {
    return (
      <div style={{ padding: '1rem' }}>
        <Spin
          indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />}
        />
      </div>
    );
  }

  if (editingType && contactStore.selectedContact?.email) {
    return (
      <div style={{ padding: '1rem', overflowY: 'auto' }}>
        <SalesforceForm
          type={editingType}
          value={
            editingType === 'Contact' ? contact || contactFormData : formData
          }
          salesforceStore={salesforceStore}
          onCancel={() => setEditingType(undefined)}
          onSubmit={onSubmit}
        />
      </div>
    );
  }

  if (!contact && !lead) {
    return (
      <div style={{ padding: '1rem', overflowY: 'auto' }}>
        <div>Salesforceに情報はありませんでした</div>
        <Button
          style={{ marginTop: '1rem', display: 'block' }}
          onClick={() => startEditingEntry('Contact', contactFormData)}
        >
          <Icon type="plus" />
          新規取引先責任者
        </Button>
        <Button
          style={{ marginTop: '1rem' }}
          onClick={() => startEditingEntry('Lead', contactFormData)}
        >
          <Icon type="plus" />
          新規リード
        </Button>
      </div>
    );
  }

  // Task can be created for both lead and contact.
  const taskList = (
    <SalesforceDetails
      label="ToDo"
      actions={
        <Icon
          type="plus-circle"
          style={{ fontSize: 16, paddingRight: 2 }}
          onClick={() => startEditingEntry('Task')}
        />
      }
    >
      {tasks.map(
        (
          t: {
            Id: string;
            Subject: string;
            Status: string;
            ActivityDate: string;
            Owner: { Name: string };
          },
          index
        ) => (
          <div
            key={t.Id}
            className={index < tasks.length - 1 ? styles.seperator : ''}
          >
            <CaseDetails>
              <StyledLabel>
                <span>件名</span>
                <div>{t.Subject}</div>
              </StyledLabel>
              <div>
                <StyledIcon
                  type="edit"
                  style={{
                    fontSize: '1.125rem',
                    marginTop: '-2px',
                    marginRight: '0.5rem',
                  }}
                  onClick={() => startEditingEntry('Task', t)}
                />
                <Tooltip title="Salesforceへ遷移します" placement="topRight">
                  <SalesforceLink
                    classic={isClassic}
                    instanceUrl={instanceUrl}
                    record="Task"
                    id={t.Id}
                  />
                </Tooltip>
              </div>
            </CaseDetails>
            <StyledLabel>
              <span>状況</span>
              <div>{t.Status}</div>
            </StyledLabel>
            <StyledLabel>
              <span>期日</span>
              <div>{t.ActivityDate}</div>
            </StyledLabel>
            <StyledLabel>
              <span>割り当て先</span>
              <div>{t.Owner.Name}</div>
            </StyledLabel>
          </div>
        )
      )}
    </SalesforceDetails>
  );

  if (lead) {
    return (
      <div style={{ padding: '1rem', overflowY: 'auto' }}>
        <SalesforceDetails
          label="リード"
          actions={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Icon
                type="edit"
                style={{
                  fontSize: '1.125rem',
                  marginTop: '-2px',
                  marginRight: '0.5rem',
                }}
                onClick={() => startEditingEntry('Lead', lead)}
              />
              <Tooltip title="Salesforceへ遷移します" placement="topRight">
                <SalesforceLink
                  classic={isClassic}
                  instanceUrl={instanceUrl}
                  record="Lead"
                  id={lead.Id}
                />
              </Tooltip>
            </div>
          }
        >
          <StyledLabel>
            <span>名前</span>
            <div>{lead.Name}</div>
          </StyledLabel>
          <StyledLabel>
            <span>メール</span>
            <div>{lead.Email}</div>
          </StyledLabel>
          <StyledLabel>
            <span>会社名</span>
            <div>{lead.Company}</div>
          </StyledLabel>
          <StyledLabel>
            <span>所有者</span>
            <div>{lead.Owner?.Name}</div>
          </StyledLabel>
        </SalesforceDetails>
        <div style={{ height: '1rem' }} />
        {taskList}
      </div>
    );
  }

  return (
    <div style={{ padding: '1rem 1rem 4rem', overflowY: 'auto' }}>
      <SalesforceDetails
        label="取引先責任者"
        actions={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Icon
              type="edit"
              style={{
                fontSize: '1.125rem',
                marginTop: '-2px',
                marginRight: '0.5rem',
              }}
              onClick={() => startEditingEntry('Contact', contact)}
            />
            <Tooltip title="Salesforceへ遷移します" placement="topRight">
              <SalesforceLink
                classic={isClassic}
                instanceUrl={instanceUrl}
                record="Contact"
                id={contact.Id}
              />
            </Tooltip>
          </div>
        }
      >
        <StyledLabel>
          <span>名前</span>
          <div>{contact.Name}</div>
        </StyledLabel>
        <StyledLabel>
          <span>メール</span>
          <div>{contact.Email}</div>
        </StyledLabel>
        <StyledLabel>
          <span>電話番号</span>
          <div>{contact.Phone}</div>
        </StyledLabel>
        <StyledLabel>
          <span>所有者</span>
          <div>{contact.Owner?.Name}</div>
        </StyledLabel>
      </SalesforceDetails>

      <div style={{ height: '1rem' }} />
      <SalesforceDetails
        label="取引先"
        actions={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Icon
              type="edit"
              style={{
                fontSize: '1.125rem',
                marginTop: '-2px',
                marginRight: '0.5rem',
              }}
              onClick={() => startEditingEntry('Contact', contact)}
            />
            {contact.AccountId && (
              <Tooltip title="Salesforceへ遷移します" placement="topRight">
                <SalesforceLink
                  classic={isClassic}
                  instanceUrl={instanceUrl}
                  record="Account"
                  id={contact.AccountId}
                />
              </Tooltip>
            )}
          </div>
        }
      >
        <StyledLabel>
          <span>名前</span>
          <div>{contact.Account?.Name}</div>
        </StyledLabel>
        <StyledLabel>
          <span>所有者</span>
          <div>{contact.Account?.Owner?.Name}</div>
        </StyledLabel>
      </SalesforceDetails>
      <div style={{ height: '1rem' }} />
      <SalesforceDetails
        label="ケース"
        actions={
          <Icon
            type="plus-circle"
            style={{ fontSize: 16, paddingRight: 2 }}
            onClick={() => startEditingEntry('Case')}
          />
        }
      >
        {cases.map(
          (c: { Id: string; Subject: string; CreatedDate: string }, index) => (
            <div
              key={c.Id}
              className={index < cases.length - 1 ? styles.seperator : ''}
            >
              <CaseDetails key={c.Id}>
                <StyledLabel>
                  <span>件名</span>
                  <div>{c.Subject}</div>
                </StyledLabel>
                <div>
                  <StyledIcon
                    type="edit"
                    style={{
                      fontSize: '1.125rem',
                      marginTop: '-2px',
                      marginRight: '0.5rem',
                    }}
                    onClick={() => startEditingEntry('Case', c)}
                  />
                  <Tooltip title="Salesforceへ遷移します" placement="topRight">
                    <SalesforceLink
                      classic={isClassic}
                      instanceUrl={instanceUrl}
                      record="Case"
                      id={c.Id}
                    />
                  </Tooltip>
                </div>
              </CaseDetails>
              <StyledLabel>
                <span>オープン日</span>
                <div>{moment(c.CreatedDate).format('YYYY-MM-DD')}</div>
              </StyledLabel>
            </div>
          )
        )}
      </SalesforceDetails>
      <div style={{ height: '1rem' }} />
      <SalesforceDetails
        label="商談"
        actions={
          <Icon
            type="plus-circle"
            style={{ fontSize: 16, paddingRight: 2 }}
            onClick={() => startEditingEntry('Opportunity')}
          />
        }
      >
        {opportunities.map(
          (
            o: {
              Id: string;
              Amount?: number;
              Name: string;
              CloseDate: string;
              StageName: string;
            },
            index
          ) => (
            <div
              key={o.Id}
              className={
                index < opportunities.length - 1 ? styles.seperator : ''
              }
            >
              <CaseDetails>
                <StyledLabel>
                  <span>商談名</span>
                  <div>{o.Name}</div>
                </StyledLabel>
                <div>
                  <StyledIcon
                    type="edit"
                    style={{
                      fontSize: '1.125rem',
                      marginTop: '-2px',
                      marginRight: '0.5rem',
                    }}
                    onClick={() => startEditingEntry('Opportunity', o)}
                  />
                  <Tooltip title="Salesforceへ遷移します" placement="topRight">
                    <SalesforceLink
                      classic={isClassic}
                      instanceUrl={instanceUrl}
                      record="Opportunity"
                      id={o.Id}
                    />
                  </Tooltip>
                </div>
              </CaseDetails>
              <StyledLabel>
                <span>金額</span>
                <div>{o.Amount}</div>
              </StyledLabel>
              <StyledLabel>
                <span>フェーズ</span>
                <div>{o.StageName}</div>
              </StyledLabel>
              <StyledLabel>
                <span>完了予定日</span>
                <div>{o.CloseDate}</div>
              </StyledLabel>
            </div>
          )
        )}
      </SalesforceDetails>
      <div style={{ height: '1rem' }} />
      {taskList}
    </div>
  );
});

const StyledLabel = styled(Label)`
  > span {
    width: 4.5rem;
  }
`;

const CaseDetails = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 26px;
`;

const StyledIcon = styled(Icon)`
  color: #9d9da0;
  &:hover {
    color: rgba(0, 0, 0);
  }
`;
