import { companyAtom, meAtom } from 'atoms/auth';
import { atom } from 'jotai';
import { Message, MessageStatus } from 'lib';
import firebase, { companyCollection, db } from 'firebase.js';
import { eventNames, logEvent } from 'analytics';
import { updateDoc } from 'firebase/firestore';
import {
  FrontMessageStatus,
  frontMessageStatusToFirestore,
  UnknownFrontMessageStatus,
} from 'utils/frontMessageStatus';

type UpdateBatchMessagesStatusArgs = {
  messages: Message[];
  status: FrontMessageStatus | UnknownFrontMessageStatus;
};

/**
 * スレッドのstatusを更新する際にスレッド内のstatusを更新するのではなくて、messageを更新する仕様である。
 * そのため、スレッド内の全てのmessageのstatusを更新するためのatom
 *
 * messageを更新すると onUpdateMessage functionが発火し、threadが更新される仕様である。
 */
export const updateBatchMessagesStatusAtom = atom(
  null,
  async (get, _, { messages, status }: UpdateBatchMessagesStatusArgs) => {
    // eventsをセットする場合のみ、一番最後のメッセージを取得
    const message = messages.slice(-1)[0];
    const me = get(meAtom);
    const signInCompany = get(companyAtom);

    if (messages.length === 0) {
      console.error('updateBatchMessagesStatusAtom: messages is empty');
      return;
    }

    if (status.statusName === '未対応' && status.customStatusId !== null) {
      /**
       * データ整合性の問題になるため、エラーを出力する。
       */
      console.error('「未対応」のcustomStatusId!=null');
    }

    const statusToProcessed = status.statusType === MessageStatus.Processed;

    const batch = db.batch();
    messages.forEach((message) => {
      batch.update(db.doc(message.ref.path), {
        status: frontMessageStatusToFirestore(status),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      });
    });

    batch.set(companyCollection(signInCompany.id, 'events').doc(), {
      user: me.id,
      name: me.name,
      teamId: message.teamId,
      messageId: message.id,
      type: statusToProcessed
        ? 'status:update:processed'
        : 'status:update:backlog',
      text: `${me.name}が「${status.statusName}」に変更しました。`,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
    await batch.commit();
    logEvent(eventNames.update_status, { status });
  }
);

type UpdateMessageStatusArgs = {
  message: Message;
  status: FrontMessageStatus;
};

export const updateMessageStatusAtom = atom(
  null,
  async (get, _, { message, status }: UpdateMessageStatusArgs) => {
    const me = get(meAtom);
    const signInCompany = get(companyAtom);
    const statusToProcessed = status.statusType === MessageStatus.Processed;

    await updateDoc(message.ref, {
      status: frontMessageStatusToFirestore(status),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
    await companyCollection(signInCompany.id, 'events').add({
      user: me.id,
      name: me.name,
      teamId: message.teamId,
      messageId: message.id,
      type: statusToProcessed
        ? 'status:update:processed'
        : 'status:update:backlog',
      text: `${me.name}が「${status.statusName}」に変更しました。`,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    });

    logEvent(eventNames.update_status, { status });
  }
);
