import { SettingPageDrawer } from '../../../common/SettingPageDrawer/SettingPageDrawer';
import { ComponentProps, useState } from 'react';
import { Inboxes } from '../Inboxes/Inboxes';
import { InputGroup } from '../../../../../components/forms/InputGroup/InputGroup';
import { Controller, useForm } from 'react-hook-form';
import { Input } from '../../../../../components/forms';
import { Button, Icon } from '../../../../../components/basics';
import { useConfirmDialog } from '../../../../../hooks/confirmDialog';
import { Tooltip } from '../../../../../components/basics/Tooltip/Tooltip';
import { Gmail, Microsoft } from '../../../../../components/icons';
import { InboxOAuthButtons } from '../InboxOAuthButtons/InboxOAuthButtons';
import { ForwardingAddressField } from '../fields/ForwardingAddressField';
import { SmtpField } from '../fields/SmtpField';
import { ImapField } from '../fields/ImapField';
import { SenderNameField } from '../fields/SenderNameField';
import { YaritoriNameField } from '../fields/YaritoriNameField';
import { validateCcAndBcc } from '../validate';
import { SignatureField } from '../fields/SignatureField';
import { Switch } from '../../../../../components/basics/Switch/Switch';

export type EmailEditDrawerUpdate = {
  name: string;
  yaritoriName: string;
  autoCc: string;
  autoBcc: string;
  defaultSignatureId: string | null;
  isOneClickUnsubscribeEnabled: boolean;
};

type Props = {
  inbox: ComponentProps<typeof Inboxes>['inboxes'][number];
  signatures: ComponentProps<typeof Inboxes>['signatures'];
  autoReplySupported: boolean;
  importImapSupported: boolean;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onReAuth: () => Promise<void>;
  onOpenSmtpDialog: () => void;
  onOpenAutoReplyDialog: () => void;
  onOpenImportImapDialog: () => void;
  onUpdate: (update: EmailEditDrawerUpdate) => Promise<void>;
  onDelete: () => Promise<void>;
  readonly: boolean;
  container?: HTMLElement;
};

export const EmailEditDrawer = ({
  inbox,
  signatures,
  autoReplySupported,
  importImapSupported,
  open,
  onOpenChange,
  onReAuth,
  onOpenSmtpDialog,
  onOpenAutoReplyDialog,
  onOpenImportImapDialog,
  onUpdate,
  onDelete,
  readonly,
  container,
}: Props) => {
  const openDialog = useConfirmDialog();
  const [updating, setUpdating] = useState(false);
  const { register, control, handleSubmit, watch, clearErrors, setError } =
    useForm<EmailEditDrawerUpdate>({
      defaultValues: {
        name: inbox.name,
        yaritoriName: inbox.yaritoriName,
        autoCc: inbox.autoCc,
        autoBcc: inbox.autoBcc,
        defaultSignatureId: inbox.defaultSignatureId,
        isOneClickUnsubscribeEnabled: inbox.isOneClickUnsubscribeEnabled,
      },
    });

  const autoCcValue = watch('autoCc');
  const autoBccValue = watch('autoBcc');
  const validate = (): boolean => {
    if (validateCcAndBcc(autoCcValue, autoBccValue)) {
      clearErrors('autoCc');
      clearErrors('autoBcc');
      return true;
    } else {
      const message = '自動Ccと自動Bccに同じ宛先を含めることはできません';
      setError('autoCc', {
        type: 'manual',
        message: message,
      });
      setError('autoBcc', {
        type: 'manual',
        message: message,
      });
      return false;
    }
  };

  const onSubmit = async (update: EmailEditDrawerUpdate) => {
    if (!validate()) {
      return;
    }

    setUpdating(true);
    try {
      await onUpdate(update);
    } catch (e) {
      console.error(e);
    } finally {
      setUpdating(false);
    }
  };

  const handleReAuth = async () => {
    setUpdating(true);
    try {
      await onReAuth();
    } catch (e) {
      console.error(e);
    } finally {
      setUpdating(false);
    }
  };

  const inboxType = inbox.type;
  return (
    <SettingPageDrawer
      title={inbox?.email ?? ''}
      open={open}
      onOpenChange={onOpenChange}
      container={container}
    >
      {inboxType === 'email' && inbox.forwardTo && (
        <ForwardingAddressField email={inbox.forwardTo} />
      )}
      {inbox.error && (
        <div className="flex flex-col gap-2">
          <div className="text-sun-500">
            エラーが発生しました、再認証してください。
          </div>
          <InboxOAuthButtons
            type={inboxType}
            onClick={async () => await handleReAuth()}
            readonly={readonly}
          />
        </div>
      )}
      {!inbox.error && inboxType === 'google' && (
        <div className="flex h-[24px] items-center gap-2 text-sea-500">
          <Icon icon={Gmail} size={24} />
          <div>Gmailに連携されています</div>
        </div>
      )}
      {!inbox.error && inboxType === 'outlook' && (
        <div className="flex h-[24px] items-center gap-2 text-sea-500">
          <Icon icon={Microsoft} size={20} />
          <div>Outlookに連携されています</div>
        </div>
      )}
      <form
        action=""
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col gap-4"
      >
        <Controller
          name="name"
          control={control}
          render={({ field: { value, onChange } }) => (
            <SenderNameField
              value={value}
              onChange={onChange}
              readonly={readonly}
            />
          )}
        />
        <Controller
          name="yaritoriName"
          control={control}
          render={({ field: { value, onChange } }) => (
            <YaritoriNameField
              value={value}
              onChange={onChange}
              readonly={readonly}
            />
          )}
        />
        <Controller
          name="autoCc"
          control={control}
          render={({ fieldState: { error } }) => (
            <InputGroup
              label="自動的にCcに追加するメールアドレス"
              description={`メール作成時、自動的にCcに追加されます。※ ","で複数のメールアドレスを記入可能です。`}
              errorMessage={error?.message}
            >
              <Input
                placeholder="contact@onebox.tokyo, sales@onebox.tokyo"
                {...register('autoCc', { onBlur: () => validate() })}
                disabled={readonly}
              />
            </InputGroup>
          )}
        />
        <Controller
          name="autoBcc"
          control={control}
          render={({ fieldState: { error } }) => (
            <InputGroup
              label="自動的にBccに追加するメールアドレス"
              description={`メール作成時、自動的にBccに追加されます。※ ","で複数のメールアドレスを記入可能です。`}
              errorMessage={error?.message}
            >
              <Input
                placeholder="contact@onebox.tokyo, sales@onebox.tokyo"
                {...register('autoBcc', { onBlur: () => validate() })}
                disabled={readonly}
              />
            </InputGroup>
          )}
        />
        <InputGroup
          label="デフォルトの署名"
          description="メール作成時、自動的に署名に追加されます。"
        >
          <Controller
            name="defaultSignatureId"
            control={control}
            render={({ field: { value, onChange } }) => (
              <SignatureField
                value={value}
                onChange={onChange}
                signatures={signatures}
                disabled={readonly}
              />
            )}
          />
        </InputGroup>
        {inboxType === 'email' && (
          <SmtpField
            enabled={inbox.smtp}
            onOpenDialog={onOpenSmtpDialog}
            readonly={readonly}
          />
        )}
        {inboxType === 'email' && importImapSupported && (
          <ImapField onOpen={onOpenImportImapDialog} readonly={readonly} />
        )}
        <InputGroup
          label="自動返信"
          description="メールを受信した際に、予め設定したメッセージを自動的に差出人に返信します。"
        >
          <div className="flex items-center gap-4">
            <Tooltip
              content="自動返信は現在のプランではサポートされていません"
              visible={!autoReplySupported}
            >
              <Button
                variant="outlined"
                onClick={() => onOpenAutoReplyDialog()}
                disabled={readonly || !autoReplySupported}
              >
                設定
              </Button>
            </Tooltip>
            {inbox.autoReply?.enabled ? (
              <div className="font-bold text-forest-500">
                自動返信は現在有効です
              </div>
            ) : (
              <div>自動返信は現在無効です</div>
            )}
          </div>
        </InputGroup>
        <InputGroup label="ワンクリック購読解除">
          <Controller
            name="isOneClickUnsubscribeEnabled"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Switch
                value={value}
                onChange={onChange}
                label="メールにワンクリック購読解除を追加する"
              />
            )}
          />
        </InputGroup>
        <div className="flex gap-4">
          <Button
            variant="outlined"
            color="danger"
            disabled={updating || readonly}
            onClick={() =>
              openDialog({
                title: `"${inbox.email}"を削除しますか？`,
                description:
                  'メールサーバー側の転送設定を必ず解除のうえ削除をお願いいたします。',
                okType: 'danger',
                okText: '削除',
                onOk: onDelete,
              })
            }
          >
            削除
          </Button>
          <Button type="submit" loading={updating} disabled={readonly}>
            更新
          </Button>
        </div>
      </form>
    </SettingPageDrawer>
  );
};
