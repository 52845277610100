import { NewTemplate } from './NewTemplate';
import { TemplateEditFormWithLogic } from '../../../Settings/email/templates/TemplateEditForm/TemplateEditFormWithLogic';
import { TemplateEditFormDefaultValue } from '../../../Settings/email/templates/TemplateEditForm/TemplateEditForm';
import { observer } from 'mobx-react';
import { useStore } from '../../../../hooks/useStore';
import { useAtomValue } from 'jotai';
import { companyAtom } from '../../../../atoms/auth';
import { isPermitAddTemplate } from '../../../../atoms/firestore/companyPermission';

type Props = {
  teamId: string;
  initialData: TemplateEditFormDefaultValue;
};

export const NewTemplateWithLogic = observer(
  ({ teamId, initialData }: Props) => {
    const store = useStore();
    const company = useAtomValue(companyAtom);
    const isPermitted = isPermitAddTemplate(
      company,
      store.getTemplates(teamId).length
    );
    return (
      <NewTemplate
        canCreateTemplate={isPermitted}
        renderDialog={(close) => (
          <TemplateEditFormWithLogic
            teamId={teamId}
            template={undefined}
            defaultValue={initialData}
            onFinishEdit={() => close()}
          />
        )}
      />
    );
  }
);
