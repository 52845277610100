import * as React from 'react';
import type { SVGProps } from 'react';
const SvgMail = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M3.5 20c-.4 0-.75-.15-1.05-.45-.3-.3-.45-.65-.45-1.05v-13c0-.4.15-.75.45-1.05.3-.3.65-.45 1.05-.45h17c.4 0 .75.15 1.05.45.3.3.45.65.45 1.05v13c0 .4-.15.75-.45 1.05-.3.3-.65.45-1.05.45h-17Zm17-13.125-8.1 5.3a3.59 3.59 0 0 1-.188.087.539.539 0 0 1-.212.038.539.539 0 0 1-.213-.038 3.59 3.59 0 0 1-.187-.087l-8.1-5.3V18.5h17V6.875ZM12 10.95l8.4-5.45H3.625L12 10.95Zm-8.5-3.9v-.983.018V5.5v.575-.023.998Z"
    />
  </svg>
);
export default SvgMail;
