import styled from 'styled-components';

export const SettingFields = styled.div`
  max-width: 440px;
  & > * {
    margin-bottom: 36px;
    &:last-of-type {
      margin-bottom: 0px;
    }
  }
`;
