import React from 'react';
import styled from 'styled-components';
import FullHeightIframe from '../Conversations/Conversation/Message/FullHeightIframe';
import { Icon, Spin } from 'antd';

export default ({ header, text, html, plainTextMode, loading }) => {
  if (loading) {
    return <Spin indicator={<Icon type="loading" spin />} />;
  }

  return (
    <div>
      <QuoteHeader>{header}</QuoteHeader>

      {plainTextMode ? (
        <pre>{text}</pre>
      ) : (
        <Quote>
          <FullHeightIframe srcDoc={html} />
        </Quote>
      )}
    </div>
  );
};

const Quote = styled.blockquote`
  margin: 0 0 0 0.8ex;
  border-left: 1px solid rgb(204, 204, 204);
  padding-left: 1ex;
  color: rgba(0, 0, 0, 1);
`;

const QuoteHeader = styled.pre`
  font-family: 'Hiragino Kaku Gothic ProN', 'Lato', 'Noto Sans JP',
    'ヒラギノ角ゴ ProN', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', 'MS PGothic',
    sans-serif;
  color: rgba(0, 0, 0, 1);
`;
