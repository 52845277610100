import moment from 'moment';
import { Timestamp } from 'firebase/firestore';
import { createConverter, createEntityFactory } from '../converter';

export interface ChatData {
  id: string;
  type: string;
  text: string;
  creator: string;
  replyTo?: string;
  edited?: boolean;
  createdAt: Timestamp;
  updatedAt: Timestamp;
}

export const createChat = createEntityFactory('Chat', (data: ChatData) => ({
  date: moment(data.createdAt.toMillis()),
}));

export type Chat = ReturnType<typeof createChat>;

export const chatConverter = createConverter(createChat);
