import { FirestoreDataConverter } from 'firebase/firestore';
import { createConverter, createEntityFactory } from '../converter';

export type UnsubscribeData = {
  email: string;
  targets: string[];
};

export const createUnsubscribe = createEntityFactory<
  'Unsubscribe',
  UnsubscribeData
>('Unsubscribe');

export type UnsubscribeEntry = ReturnType<typeof createUnsubscribe>;

export const unsubscribeConverter: FirestoreDataConverter<UnsubscribeEntry> =
  createConverter(createUnsubscribe);
