import {
  DocumentReference,
  DocumentSnapshot,
  QueryDocumentSnapshot,
} from 'firebase/firestore';
import { estimateServerTimestampsOption } from './common';

export type Entity<T> = {
  [K in keyof T]: T[K];
} & {
  id: string;
  ref: DocumentReference<T>;
  doc: DocumentSnapshot<T>;
  data: T;
};

export const createEntity = <T>(
  doc: DocumentSnapshot<T> | QueryDocumentSnapshot<T>
): Entity<T> => {
  if (!doc.exists()) {
    throw new Error(`no data for ${doc.ref.path}`);
  }
  const data = doc.data(estimateServerTimestampsOption);
  return {
    ...data,
    id: doc.id,
    ref: doc.ref,
    doc,
    data,
  };
};
