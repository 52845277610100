import { InboxAutoReply } from 'lib';
import { EmailEditDrawer, EmailEditDrawerUpdate } from './EmailEditDrawer';
import { observer } from 'mobx-react';
import { useStore } from '../../../../../hooks/useStore';
import React, { useState } from 'react';
import { SmtpEditDialogWithLogic } from '../SmtpEditDialog/SmtpEditDialogWithLogic';
import { AutoReplyEditDialog } from '../AutoReplyEditDialog/AutoReplyEditDialog';
import { serverTimestamp, updateDoc } from 'firebase/firestore';
import { useToast } from '../../../../../hooks/useToast';
import { useOAuthHandler } from '../Inboxes/useOAuthHandler';
import { updateInboxFunction } from '../../../../../functions';
import { CompanyEmailRoutes } from '../../CompnayEmail/CompanyEmailRoutes';
import { useHistory, useLocation } from 'react-router-dom';
import { ImapImportDialogWithLogic } from '../ImapImportDialog/ImapImportDialogWithLogic';
import { Inbox } from '../../../../../firestore/entity/inbox';

type Props = {
  teamId: string;
  inbox: Inbox;
  onClose: () => void;
  routes: CompanyEmailRoutes;
  readonly: boolean;
};

export const EmailEditDrawerWithLogic = observer(
  ({ teamId, inbox, onClose, routes, readonly }: Props) => {
    const store = useStore();
    const signatures = store.getSignatures(teamId);
    const location = useLocation();
    const history = useHistory();
    const openDialogId = new URLSearchParams(location.search).get('dialog');
    const [smtpDialogOpen, setSmtpDialogOpen] = useState(false);
    const [importDialogOpen, setImportDialogOpen] = useState(false);

    const { showToast } = useToast();

    const handleSaveAutoReplySettings = async (settings: InboxAutoReply) => {
      if (!inbox) {
        return;
      }
      await updateDoc(inbox.ref, {
        autoReply: settings,
        updatedAt: serverTimestamp(),
      }).then(() => {
        showToast('success', '更新しました');
      });
    };

    const handleUpdate = async (update: EmailEditDrawerUpdate) => {
      await updateDoc(inbox.ref, {
        name: update.name,
        yaritoriName: update.yaritoriName,
        autoCc: update.autoCc,
        autoBcc: update.autoBcc,
        defaultSignatureId: update.defaultSignatureId,
        isOneClickUnsubscribeEnabled: update.isOneClickUnsubscribeEnabled,
        updatedAt: serverTimestamp(),
      })
        .then(() => {
          showToast('success', '更新しました');
          routes.inboxRoutes.toIndex({ teamId });
        })
        .catch((e) => {
          console.error(e);
          showToast('error', '更新に失敗しました');
        });
    };

    const handleDelete = async () => {
      await updateDoc(inbox.ref, {
        deleted: true,
      })
        .then(() => {
          showToast('success', 'メールアドレスを削除しました');
          routes.inboxRoutes.toIndex({ teamId });
        })
        .catch((e) => {
          console.error(e);
          showToast('error', 'メールアドレスの削除に失敗しました');
        });
    };

    const handleReAuth = useOAuthHandler(
      inbox.type,
      store.signInCompany,
      teamId,
      async ({ email, id }) => {
        if (email !== inbox.email) {
          showToast('error', 'メールアドレスが一致しません');
          return;
        }
        await updateInboxFunction({
          companyId: store.signInCompany,
          teamId,
          inboxId: inbox.id,
          tokenId: id,
        });
      }
    );

    return (
      <>
        <EmailEditDrawer
          inbox={inbox}
          signatures={signatures.map((s) => ({ id: s.id, name: s.title }))}
          autoReplySupported={store.isAutoReplySupported}
          importImapSupported={store.isImportImapSupported}
          open={true}
          onOpenChange={() => onClose()}
          onReAuth={handleReAuth}
          onOpenSmtpDialog={() => setSmtpDialogOpen(true)}
          onOpenAutoReplyDialog={() => {
            const detailPath = routes.inboxRoutes.getDetailPathName({
              teamId,
              inboxId: inbox.id,
            });
            history.push(`${detailPath}?dialog=autoReply`);
          }}
          onOpenImportImapDialog={() => setImportDialogOpen(true)}
          onUpdate={handleUpdate}
          onDelete={handleDelete}
          readonly={readonly}
        />
        {smtpDialogOpen && (
          <SmtpEditDialogWithLogic
            inboxId={inbox.id}
            onClose={() => setSmtpDialogOpen(false)}
          />
        )}
        {openDialogId === 'autoReply' && (
          <AutoReplyEditDialog
            settings={
              inbox.autoReply ?? {
                enabled: false,
                allDay: false,
                startTime: null,
                endTime: null,
                messageBody: '',
              }
            }
            onOpenChange={() =>
              routes.inboxRoutes.toDetail({ teamId, inboxId: inbox.id })
            }
            open={true}
            onSave={handleSaveAutoReplySettings}
          />
        )}
        {importDialogOpen && (
          <ImapImportDialogWithLogic
            inboxId={inbox.id}
            onClose={() => setImportDialogOpen(false)}
          />
        )}
      </>
    );
  }
);
