import {
  ComponentProps,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useAtomValue } from 'jotai';
import { meAtom } from '../../../atoms/auth';
import { useParams } from 'react-router-dom';
import { roomsAtom } from '../../../atoms/firestore/room';
import { companyCollection } from '../../../firestore';
import { chatHistoriesDelimiter, Room } from '../../../firestore/entity/room';
import { Chat, chatConverter, ChatData } from '../../../firestore/entity/chat';
import { ChatRoom } from './ChatRoom';
import { usersAtom } from '../../../atoms/firestore/user';
import { CommentSkeleton } from '../../comment/CommentSkeleton/CommentSkeleton';
import { ChatWithLogic } from './ChatWithLogic';
import {
  doc,
  orderBy,
  query,
  runTransaction,
  serverTimestamp,
  Timestamp,
  updateDoc,
} from 'firebase/firestore';
import { LRUCache } from 'lru-cache';
import { db9 } from '../../../firebase';
import { eventNames, logEvent } from '../../../analytics';
import { v4 } from 'uuid';
import { debounce } from 'lodash';
import { ChatUpdateDialogWithLogic } from '../ChatSettingsDialog/ChatUpdateDialogWithLogic';
import { isSP, isTablet } from '../../../shared/util';
import { tv } from 'tailwind-variants';
import { useSubscribeCollection } from '../../../hooks/firestore/subscription';

const wrapper = tv({
  variants: {
    sp: {
      false: '',
      true: '',
    },
    tablet: {
      false: '',
      true: '',
    },
  },
  compoundVariants: [
    {
      sp: false,
      tablet: false,
      className: 'h-full',
    },
    {
      sp: false,
      tablet: true,
      className: 'h-[calc(100dvh_-_64px)]',
    },
    {
      sp: true,
      tablet: true,
      className: 'h-[100dvh]',
    },
    {},
  ],
});

export const ChatRoomWithLogic: FC = () => {
  const { roomId } = useParams<{ roomId: string }>();
  const rooms = useAtomValue(roomsAtom);
  const room = rooms.find((r) => r.id === roomId);

  if (!room) {
    return <div>Not Found</div>;
  }

  return <InternalChatRoom key={room.id} room={room} />;
};

const chatsCache = new LRUCache<string, Chat[]>({
  maxSize: 128,
  sizeCalculation: (chats) => Math.max(chats.length, 1),
});

export const InternalChatRoom = ({ room }: { room: Room }) => {
  const scrollAreaRef = useRef<HTMLDivElement>(null);
  const me = useAtomValue(meAtom);
  const users = useAtomValue(usersAtom);
  const [intersectingChatIds, setIntersectingChatIds] = useState<string[]>([]);
  const [showUpdateDialog, setShowUpdateDialog] = useState(false);

  const [loading, realtimeChats] = useSubscribeCollection(
    () =>
      query<Chat>(
        companyCollection<Chat>(`rooms/${room.id}/chats`, chatConverter),
        orderBy('createdAt')
      ),
    [room.id]
  );

  const cachedChats = useMemo(() => chatsCache.get(room.id), [room.id]);

  // 既読にする
  const markAsRead = useCallback(async () => {
    await updateDoc(doc(companyCollection('rooms'), room.id), {
      [`readers.${me.id}`]: serverTimestamp(),
    });
  }, [room.id, me.id]);

  useEffect(() => {
    if (loading) {
      return;
    }
    chatsCache.set(room.id, realtimeChats);
    markAsRead().then();
  }, [room.id, loading, realtimeChats, markAsRead]);

  const subject = useMemo(() => {
    if (room.subject) {
      return room.subject;
    }
    return room.members
      .filter((memberId) => memberId !== me.id)
      .map(
        (memberId) =>
          users.find((u) => u.id === memberId)?.name || '削除されたユーザー'
      )
      .join(', ');
  }, [room.subject, room.members, users]);

  const skeletons = useMemo(
    () => (
      <>
        <CommentSkeleton width="50%" />
        <CommentSkeleton width="70%" />
        <CommentSkeleton width="40%" />
        <CommentSkeleton width="45%" />
      </>
    ),
    []
  );

  const chatRoomUsers: ComponentProps<typeof ChatRoom>['users'] =
    useMemo(() => {
      return users
        .filter((u) => room.members.includes(u.id))
        .map((u) => ({
          name: u.name,
          src: u.avatarURL,
          baseColor: u.iconBackgroundColor,
        }));
    }, [users, room.members]);

  const onComment = useCallback(
    async (content: string, replyTo: string | undefined) => {
      const chatData: Omit<ChatData, 'id'> = {
        type: 'comment',
        text: content,
        creator: me.id,
        createdAt: serverTimestamp() as Timestamp,
        updatedAt: serverTimestamp() as Timestamp,
      };

      if (replyTo) {
        chatData.replyTo = replyTo;
      }

      await runTransaction(db9, async (tx) => {
        tx.set(doc(companyCollection(`rooms/${room.id}/chats`)), chatData);
        tx.update(room.ref, {
          [`chatHistories.${me.id}${chatHistoriesDelimiter}${v4()}`]:
            serverTimestamp(),
          updatedAt: serverTimestamp(),
        });
      });

      markAsRead().then();
      logEvent(eventNames.add_chat);

      return true;
    },
    [room, me, markAsRead]
  );

  // 初期スクロール
  useEffect(() => {
    const scrollArea = scrollAreaRef.current;
    if (!scrollArea) {
      return;
    }

    if (cachedChats?.length || !loading) {
      setTimeout(() => {
        scrollArea.scrollTo({
          top: scrollArea.scrollHeight,
        });
      });
    }
  }, [loading]);

  // チャット更新時のスクロール
  useEffect(() => {
    const scrollArea = scrollAreaRef.current;
    if (!scrollArea) {
      return;
    }
    const thresholdHeight = scrollArea.clientHeight * 0.5;
    const shouldScrollToBottom =
      scrollArea.scrollHeight - scrollArea.scrollTop - scrollArea.clientHeight <
      thresholdHeight;
    if (shouldScrollToBottom) {
      scrollArea.scrollTo({
        top: scrollArea.scrollHeight,
      });
    }
  }, [realtimeChats]);

  const chats = loading ? cachedChats : realtimeChats;

  useEffect(() => {
    const scrollArea = scrollAreaRef.current;
    if (!scrollArea) {
      return;
    }

    const updateIntersectingChats = () => {
      if (!chats) {
        return;
      }
      const targets =
        (
          chats
            .map((c) => document.getElementById(c.id))
            .filter((el) => el) as HTMLElement[]
        )
          .filter(
            (el) =>
              scrollArea.scrollTop + scrollArea.clientHeight > el.offsetTop
          )
          .filter((el) => scrollArea.scrollTop - el.clientHeight < el.offsetTop)
          .map((el) => el.id) ?? [];
      setIntersectingChatIds(targets);
    };

    updateIntersectingChats();
    const throttled = debounce(updateIntersectingChats, 200);
    scrollArea.addEventListener('scroll', throttled);
    return () => scrollArea.removeEventListener('scroll', throttled);
  }, [chats]);

  return (
    <div className={wrapper({ sp: isSP(), tablet: isTablet() })}>
      <ChatRoom
        subject={subject}
        currentUser={me}
        users={chatRoomUsers}
        onComment={onComment}
        onOpenMemberList={() => setShowUpdateDialog(true)}
        scrollAreaRef={scrollAreaRef}
      >
        {loading && !cachedChats && skeletons}
        {chats?.map((chat) => (
          <div key={chat.id} id={chat.id}>
            <ChatWithLogic
              roomId={room.id}
              chat={chat}
              chats={chats}
              intersecting={intersectingChatIds.includes(chat.id)}
            />
          </div>
        ))}
      </ChatRoom>
      {showUpdateDialog && (
        <ChatUpdateDialogWithLogic
          room={room}
          open
          onOpenChange={setShowUpdateDialog}
        />
      )}
    </div>
  );
};
