// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*noinspection CssUnresolvedCustomProperty*/\n.mhSw69LdZhCqX7LjRKz3,\n.mhSw69LdZhCqX7LjRKz3 * {\n  display: block !important;\n  width: var(--emoji-size);\n  height: var(--emoji-size);\n}\n", "",{"version":3,"sources":["webpack://./src/components/basics/Emoji/Emoji.module.css"],"names":[],"mappings":"AAAA,2CAA2C;AAC3C;;EAEE,yBAAyB;EACzB,wBAAwB;EACxB,yBAAyB;AAC3B","sourcesContent":["/*noinspection CssUnresolvedCustomProperty*/\n.emoji,\n.emoji * {\n  display: block !important;\n  width: var(--emoji-size);\n  height: var(--emoji-size);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"emoji": "mhSw69LdZhCqX7LjRKz3"
};
export default ___CSS_LOADER_EXPORT___;
