import { onSnapshot, query, QueryDocumentSnapshot } from 'firebase/firestore';
import { computed, makeObservable, observable } from 'mobx';

import { removeOAuth } from 'functions';
import type { Store } from './index';
import { BaseStore } from './base';

export interface SalesforceData {
  service: 'salesforce';
  data: {
    error?: string;
    error_description?: string;
  };
}

type IntegrationData = SalesforceData;

type Service = IntegrationData['service'];

export class OAuthStore extends BaseStore<IntegrationData> {
  PATH = 'oauthIntegrations';

  salesforceIntegration?: QueryDocumentSnapshot<SalesforceData> = undefined;
  _unsubscribeIntegrations?: () => void;

  constructor(rootStore: Store) {
    super(rootStore);

    makeObservable(this, {
      salesforceIntegration: observable,
      salesforceIntegrated: computed,
    });
  }

  async syncIntegrations(): Promise<void> {
    this._unsubscribeIntegrations?.();

    this._unsubscribeIntegrations = onSnapshot(
      query(this.collection()),
      (snap) => {
        this.salesforceIntegration = undefined;
        snap.docs.forEach((doc) => {
          switch (doc.get('service')) {
            case 'salesforce':
              this.salesforceIntegration = doc;
              break;
          }
        });
      }
    );
  }

  async unintegrate(service: Service): Promise<void> {
    await removeOAuth({ service, companyId: this.rootStore.signInCompany });
  }

  get salesforceIntegrated(): boolean {
    return !!this.salesforceIntegration?.get('data').id;
  }
}
