import { Template } from 'lib';
import { SettingPageDrawer } from '../../../common/SettingPageDrawer/SettingPageDrawer';
import React from 'react';
import { TemplateEditFormWithLogic } from '../TemplateEditForm/TemplateEditFormWithLogic';

type Props = {
  teamId: string;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  getTemplate: () => Template | undefined;
};

export const TemplateEditDrawer = ({
  teamId,
  open,
  onOpenChange,
  getTemplate,
}: Props) => {
  return (
    <SettingPageDrawer
      title={open && getTemplate() ? 'テンプレート編集' : '新規テンプレート'}
      open={open}
      onOpenChange={onOpenChange}
      drawerClassName="w-[560px]"
    >
      <div data-testid="template-edit-drawer">
        <TemplateEditFormWithLogic
          teamId={teamId}
          template={getTemplate()}
          onFinishEdit={() => onOpenChange(false)}
        />
      </div>
    </SettingPageDrawer>
  );
};
