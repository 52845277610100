import { Controller, useForm } from 'react-hook-form';
import { MessageStatus } from 'lib';
import {
  Checkbox,
  DatePicker,
  Input,
  Label,
  Options,
  Select,
} from '../../../components/forms';
import { memo, PropsWithChildren, useEffect, useState } from 'react';
import { SearchFilterData } from './MainSearch';
import { useStore } from '../../../hooks/useStore';
import { useLocation } from 'react-router-dom';
import { Button } from '../../../components/basics';
import { Tray } from './useSearch';

type Props = {
  form: ReturnType<typeof useForm<SearchFilterData>>;
  onSubmit: (data: SearchFilterData) => void;
};

export const SearchFilterForm = memo(
  ({ form, onSubmit }: Props): JSX.Element => {
    const {
      setValue,
      watch,
      handleSubmit,
      register,
      control,
      reset,
      formState,
    } = form;
    const [tags, setTags] = useState<Options<string>>([]);
    const [members, setMembers] = useState<Options<string | null>>([]);

    const location = useLocation();
    const store = useStore();
    const { searchStore } = store;
    const teamIds = searchStore.query.teamIds;
    const tray = watch('tray');

    useEffect(() => {
      const ids = teamIds ?? store.joinedTeamIds;
      const teams = store.joinedTeams.filter((team) => ids.includes(team.id));
      const tags: Options<string> = store.tags
        .filter((tag) => !tag.isInbox)
        .filter((tag) => ids.includes(tag.teamId))
        .map((tag) => ({ value: tag.id, label: tag.name }));
      const members: Options<string> = store.users
        .filter((user) => teams.some((team) => team.isMember(user.id)))
        .map((user) => ({ value: user.id, label: user.name }));
      setTags([{ value: '', label: '' }, ...tags]);
      setMembers([
        { value: '', label: '' },
        { value: null, label: 'なし' },
        ...members,
      ]);
    }, [teamIds]);

    useEffect(() => {
      if (tray === 'sent') {
        reset(
          {
            ...formState.defaultValues,
            tray: 'sent',
          },
          {
            keepDefaultValues: true,
          }
        );
      }
      const range = searchStore.searchRange;
      if (
        tray === 'sent' &&
        range !== 'allSent' &&
        !(range === 'currentSelect' && location.pathname.includes('/sent'))
      ) {
        searchStore.searchRange = 'allSent';
      }
      if (tray !== 'sent' && range === 'allSent') {
        searchStore.searchRange = 'allInboxes';
      }
    }, [tray]);

    const hasAttachments = watch('hasAttachments');
    useEffect(() => {
      if (!hasAttachments) {
        setValue('attachmentsFilename', '');
      }
    }, [hasAttachments]);

    const trayOptions: Options<string> = Object.entries(Tray).map(
      ([value, label]) => ({ value, label })
    );

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-4 p-4">
          <LabeledInput id="tray" label="トレイ">
            <Controller
              control={control}
              render={({ field: { ref, ...field } }) => (
                <Select
                  id="tray"
                  {...field}
                  options={trayOptions}
                  variants={{ width: 'full', rounded: 'lg' }}
                />
              )}
              name="tray"
            />
          </LabeledInput>
          {tray !== 'sent' && (
            <>
              <LabeledInput id="status" label="ステータス">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Select
                      id="status"
                      {...field}
                      options={[
                        MessageStatus.None,
                        MessageStatus.Unprocessed,
                        MessageStatus.Processed,
                      ]}
                      placeholder="選択してください"
                      variants={{ width: 'full', rounded: 'lg' }}
                    />
                  )}
                  name="status"
                />
              </LabeledInput>
              <LabeledInput id="from" label="From">
                <Input
                  id="from"
                  {...register('from')}
                  placeholder="入力してください"
                  size="sm"
                />
              </LabeledInput>
              <LabeledInput id="toOrCc" label="To,Cc">
                <Input
                  id="toOrCc"
                  {...register('toOrCc')}
                  placeholder="入力してください"
                  size="sm"
                />
              </LabeledInput>
              <LabeledInput id="subjectOrText" label="件名,本文">
                <Input
                  id="subjectOrText"
                  {...register('subjectOrText')}
                  placeholder="入力してください"
                  size="sm"
                />
              </LabeledInput>
              <LabeledInput id="tag" label="タグ">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Select
                      id="tag"
                      {...field}
                      options={tags}
                      placeholder="選択してください"
                      variants={{ width: 'full', rounded: 'lg' }}
                    />
                  )}
                  name="tag"
                />
              </LabeledInput>
              <LabeledInput id="assignee" label="担当者">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Select
                      id="assignee"
                      {...field}
                      options={members}
                      placeholder="選択してください"
                      variants={{ width: 'full', rounded: 'lg' }}
                    />
                  )}
                  name="assignee"
                />
              </LabeledInput>
              <LabeledInput id="after" label="受信日時">
                <div className="flex gap-2.5">
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <DatePicker
                        id="after"
                        mode="single"
                        onSelect={(date) => onChange(date)}
                        selected={value ?? undefined}
                        onDayBlur={onBlur}
                        ref={ref}
                        toDate={watch('before') ?? undefined}
                        placement="bottomLeft"
                      />
                    )}
                    name="after"
                  />
                  <div className="flex select-none items-center justify-center text-lg text-sumi-500">
                    ~
                  </div>
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <DatePicker
                        mode="single"
                        onSelect={(date) => onChange(date)}
                        selected={value ?? undefined}
                        onDayBlur={onBlur}
                        ref={ref}
                        fromDate={watch('after') ?? undefined}
                        placement="bottomLeft"
                      />
                    )}
                    name="before"
                  />
                </div>
              </LabeledInput>
              <Checkbox
                label="添付ファイルあり"
                {...register('hasAttachments')}
              />
              {watch('hasAttachments') && (
                <LabeledInput id="attachmentsFilename" label="添付ファイル名">
                  <Input
                    id="attachmentsFilename"
                    size="sm"
                    {...register('attachmentsFilename')}
                  />
                </LabeledInput>
              )}
            </>
          )}
          <div className="flex justify-end gap-2.5 text-sm">
            <Button
              color="primary"
              variant="outlined"
              type="button"
              onClick={() => reset()}
              className="h-8 w-[72px] p-0 sm:p-0"
            >
              リセット
            </Button>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              className="h-8 w-[72px] rounded p-0"
            >
              検索
            </Button>
          </div>
        </div>
      </form>
    );
  }
);

type LabeledInputProps = {
  id: string;
  label: string;
};

const LabeledInput = ({
  id,
  label,
  children,
}: PropsWithChildren<LabeledInputProps>) => {
  return (
    <Label htmlFor={id} label={label} className="w-1/6">
      {children}
    </Label>
  );
};
