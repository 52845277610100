import { atomWithRefresh, unwrap } from 'jotai/utils';
import {
  getCountFromServer,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import { companyCollection, registerUnsubscribe } from '../../../firestore';
import { joinedTeamIdsAtom } from '../team';
import { meAtom } from '../../auth';
import { store } from '../../../providers/StoreProvider';

export const mentionedCountAtom = unwrap(
  atomWithRefresh(async (get) => {
    const snap = await getCountFromServer(
      query(
        companyCollection('comments'),
        where('teamId', 'in', get(joinedTeamIdsAtom)),
        where('unreadUsers', 'array-contains', get(meAtom).id)
      )
    );
    return snap.data().count;
  }),
  (prev) => prev ?? 0
);

mentionedCountAtom.onMount = (set) =>
  registerUnsubscribe(
    mentionedCountAtom,
    onSnapshot(
      query(
        companyCollection('comments'),
        where('teamId', 'in', store.get(joinedTeamIdsAtom)),
        where('mentionedUsers', 'array-contains', store.get(meAtom).id),
        orderBy('updatedAt', 'desc'),
        limit(1)
      ),
      () => set()
    )
  );
