// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MDAWE8fTUS2QCAhcQMiP {\n  background: linear-gradient(to top, transparent 0%, #ffffff 80%) !important;\n}\n.hQB4gUuBjicGZM0dH3gp {\n  background: linear-gradient(to bottom, transparent 0%, #ffffff 80%) !important;\n}\n", "",{"version":3,"sources":["webpack://./src/components/chat/ChatSettingsDialog/ChatSettingsDialog.module.css"],"names":[],"mappings":"AAAA;EACE,2EAA2E;AAC7E;AACA;EACE,8EAA8E;AAChF","sourcesContent":[".gradientTop {\n  background: linear-gradient(to top, transparent 0%, #ffffff 80%) !important;\n}\n.gradientBottom {\n  background: linear-gradient(to bottom, transparent 0%, #ffffff 80%) !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gradientTop": "MDAWE8fTUS2QCAhcQMiP",
	"gradientBottom": "hQB4gUuBjicGZM0dH3gp"
};
export default ___CSS_LOADER_EXPORT___;
