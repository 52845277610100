import AntdAvatar, { AvatarProps as AntdAvatarProps } from 'antd/lib/avatar';
import { InvitedUser, User } from 'lib';
import React, { CSSProperties } from 'react';
import Tooltip from './Tooltip';

type Props = {
  user?: User | InvitedUser;
  name?: string;
  showNameOnHover?: boolean;
  style?: CSSProperties;
} & AntdAvatarProps;

export const Avatar: React.FC<Props> = ({
  user,
  name,
  showNameOnHover,
  ...props
}) => (
  <Tooltip
    //FIXME: any
    title={(user as any)?.name || name || '削除されたユーザ'}
    visible={showNameOnHover}
  >
    <AntdAvatar
      //FIXME: any
      src={(user as any)?.avatarURL}
      size="large"
      {...props}
      style={{
        userSelect: 'none',
        flexShrink: 0,
        //FIXME: any
        backgroundColor: (user as any)?.avatarURL
          ? '#ffffff'
          : user?.iconBackgroundColor,
        ...(props.style || {}),
      }}
    >
      {user?.iconName || name || '?'}
    </AntdAvatar>
  </Tooltip>
);

export default Avatar;
