import { Icon } from '../../../../components/basics';
import { Close } from '../../../../components/icons';
import SimpleBar from 'simplebar-react';
import { twMerge } from 'tailwind-merge';
import { ReactNode } from 'react';
import * as Portal from '@radix-ui/react-portal';

type Props = {
  title: string;
  portalClassName?: string;
  drawerClassName?: string;
  contentClassName?: string;
  children: ReactNode;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  container?: HTMLElement;
};

export const SettingPageDrawer = ({
  title,
  children,
  onOpenChange,
  open,
  portalClassName,
  drawerClassName,
  contentClassName,
  container,
}: Props) => {
  return (
    <Portal.Root container={container} asChild>
      <div
        className={twMerge(
          'pointer-events-none fixed right-0 top-0 z-20 h-full w-full',
          portalClassName
        )}
      >
        <div
          className={twMerge(
            'pointer-events-auto absolute right-0 h-full w-[400px] text-sm shadow-settings-drawer transition-transform',
            drawerClassName
          )}
          style={{
            transform: `translateX(${open ? '0%' : '100%'})`,
          }}
          role="dialog"
        >
          <div className="flex h-14 items-center justify-between border-y border-b-sumi-300 border-t-transparent bg-white px-4">
            <div className="m-0 overflow-hidden truncate text-[16px] font-bold">
              {title}
            </div>
            <button
              type="button"
              className="flex h-6 w-6 cursor-pointer items-center justify-center rounded-full bg-transparent p-0"
              onClick={() => onOpenChange?.(false)}
            >
              <Icon icon={Close} size={14} />
            </button>
          </div>
          <SimpleBar
            className="h-[calc(100dvh_-_theme(height.14))]"
            classNames={{
              contentWrapper: 'simplebar-content-wrapper outline-none',
              contentEl: 'simplebar-content relative h-full',
            }}
          >
            {open && (
              <div
                className={twMerge(
                  'flex h-full flex-col gap-4 bg-white',
                  contentClassName
                )}
              >
                <div className="p-4">{children}</div>
              </div>
            )}
          </SimpleBar>
        </div>
      </div>
    </Portal.Root>
  );
};
