import { Icon } from '../../../../../components/basics';
import { DragIndicator } from '../../../../../components/icons';
import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

type Props = {
  id: string;
  name: string;
  onClick: () => void;
};

export const FilterListItem = ({ id, name, onClick }: Props) => {
  const {
    attributes,
    listeners,
    isDragging,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? 10 : undefined,
  };

  return (
    <div className="flex justify-start" ref={setNodeRef}>
      <div
        className="relative grid w-fit grid-cols-[auto_1fr] items-center rounded-lg border border-sumi-300 bg-white text-base"
        style={style}
        {...attributes}
      >
        <button
          type="button"
          className="h-full cursor-grab bg-transparent p-0 text-sumi-500"
          {...listeners}
        >
          <Icon icon={DragIndicator} size={20} className="block" />
        </button>
        <button
          type="button"
          className="cursor-pointer select-none bg-transparent py-3 pl-[12px] pr-[32px] font-bold"
          onClick={() => onClick()}
        >
          {name}
        </button>
      </div>
    </div>
  );
};
