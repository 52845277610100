import React, { ReactNode } from 'react';
import { Redirect } from 'react-router-dom';
import { useStore } from '../../hooks/useStore';
import { useAtomValue } from 'jotai';
import {
  allLoadedAtom,
  authUserAtom,
  authUserLoadingAtom,
} from '../../atoms/auth';
import { AuthenticatingComponent } from './AuthenticatingComponent';

type Props = {
  children: ReactNode;
};

export const Auth = ({ children }: Props) => {
  const mobxStore = useStore();
  const authUserLoading = useAtomValue(authUserLoadingAtom);
  const authUser = useAtomValue(authUserAtom);
  const allLoaded = useAtomValue(allLoadedAtom);

  if (
    mobxStore.currentUserLoading ||
    authUserLoading ||
    (mobxStore.isSignedIn && authUser && !allLoaded)
  ) {
    return <AuthenticatingComponent />;
  }

  return mobxStore.isSignedIn && authUser ? (
    <>{children}</>
  ) : (
    <Redirect to={'/signIn'} />
  );
};
