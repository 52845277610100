import { toRecipients } from 'lib';

export function autoRecipientsDuplicated(
  autoCc: string,
  autoBcc: string
): boolean {
  const autoCcs = toRecipients(autoCc);
  const autoBccs = toRecipients(autoBcc);
  return autoCcs.some((acc) => autoBccs.some((abcc) => acc === abcc));
}
