import * as React from 'react';
import type { SVGProps } from 'react';
const SvgDelete = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M6.525 21c-.4 0-.75-.15-1.05-.45-.3-.3-.45-.65-.45-1.05V5.25H4.75a.724.724 0 0 1-.534-.217A.732.732 0 0 1 4 4.496a.731.731 0 0 1 .75-.746H8.7A.731.731 0 0 1 9.45 3h5.1c.212 0 .39.072.534.216a.726.726 0 0 1 .216.534h3.95c.212 0 .39.072.534.217a.732.732 0 0 1 .216.537.731.731 0 0 1-.75.746h-.275V19.5c0 .4-.15.75-.45 1.05-.3.3-.65.45-1.05.45H6.525Zm0-15.75V19.5h10.95V5.25H6.525Zm2.65 11.35c0 .212.072.39.217.534a.732.732 0 0 0 .537.216.731.731 0 0 0 .746-.75V8.125a.723.723 0 0 0-.217-.534.732.732 0 0 0-.537-.216.731.731 0 0 0-.746.75V16.6Zm4.15 0c0 .212.072.39.217.534a.732.732 0 0 0 .537.216.731.731 0 0 0 .746-.75V8.125a.723.723 0 0 0-.217-.534.732.732 0 0 0-.537-.216.731.731 0 0 0-.746.75V16.6Z"
    />
  </svg>
);
export default SvgDelete;
