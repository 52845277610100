import {
  ComponentProps,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { tv } from 'tailwind-variants';
import { throttle } from 'lodash';
import { twMerge } from 'tailwind-merge';

type Props = Omit<ComponentProps<'div'>, 'children'> & {
  value: number;
};

const num = tv({
  base: 'absolute',
  variants: {
    animation: {
      up: 'animate-count-up',
      down: 'animate-count-down',
    },
    offset: {
      top: '-top-full',
      bottom: '-bottom-full',
    },
  },
});

export const Count = ({ value: _value, className, ...props }: Props) => {
  const [value, setValue] = useState(_value);
  const prevValueRef = useRef(value);
  const timerIdRef = useRef<unknown>();
  const [prevValue, setPrevValue] = useState(value);
  const [animation, setAnimation] = useState<
    keyof typeof num.variants.animation | undefined
  >();
  const [offset, setOffset] = useState<
    keyof typeof num.variants.offset | undefined
  >();

  const updateValue = useMemo(
    () => throttle((value: number) => setValue(value), 170),
    []
  );
  useLayoutEffect(() => updateValue(_value), [_value, updateValue]);

  useLayoutEffect(() => {
    if (prevValueRef.current === value) {
      return;
    }

    setPrevValue(prevValueRef.current);
    const added = value - prevValueRef.current > 0;

    setAnimation(added ? 'up' : 'down');
    setOffset(added ? 'bottom' : 'top');

    timerIdRef.current = setTimeout(() => setAnimation(undefined), 150);

    prevValueRef.current = value;

    return () => {
      clearTimeout(timerIdRef.current as never);
      setAnimation(undefined);
    };
  }, [value]);
  return (
    <div
      className={twMerge('relative inline-block overflow-hidden', className)}
      {...props}
    >
      <div key={`${value}-0`} className={num({ animation })}>
        {animation ? prevValue : value}
      </div>
      <div className="pointer-events-none invisible select-none" aria-hidden>
        {value}
      </div>
      {animation && offset && (
        <div key={`${value}-1`} className={num({ animation, offset })}>
          {value}
        </div>
      )}
    </div>
  );
};
