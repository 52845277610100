import { sendLineMessageFunction } from '../../../functions';
import { useStore } from 'hooks/useStore';
import React, { useState } from 'react';
import { message as antdMessage } from 'antd';
import { LineEvent, LineImageEvent, LineThread, User } from 'lib';
import Avatar from '../../Common/Avatar';
import { extractFileExtension, isCmdOrCtrlEnter } from '../../../util';
import {
  UploadedFile,
  UploadFile,
  UploadingFile,
  useUploadWithDropzone,
} from '../../../hooks/upload';
import {
  AddCommentSubmit,
  Attach,
  Close,
  Plus,
} from '../../../components/icons';
import { Icon, Loading } from '../../../components/basics';
import { useImageResize } from './useImageResize';
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytes,
} from 'firebase/storage';
import { storage } from '../../../firebase';
import { useToast } from '../../../hooks/useToast';
import { twMerge } from 'tailwind-merge';
import TextareaAutosize from 'react-textarea-autosize';

type Props = {
  user: User;
  thread: LineThread;
  setPreviewImageUrl: (url: string) => void;
  disabled?: boolean;
};

export const SendMessage = ({
  user,
  thread,
  setPreviewImageUrl,
  disabled = false,
}: Props): JSX.Element => {
  const [message, setMessage] = useState('');
  const [sending, setSending] = useState(false);
  const store = useStore();
  const resize = useImageResize();
  const { showToast } = useToast();

  const toPreviewPath = (storagePath: string) => {
    const ext = `.${extractFileExtension(storagePath)}`;
    return storagePath.replace(ext, `-preview${ext}`);
  };

  const uploadPath = (uploadingFile: UploadingFile) => {
    return `companies/${store.signInCompany}/lineFiles/${uploadingFile.id}/${uploadingFile.file.name}`;
  };

  const validate = (file: File) => {
    if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
      return false;
    }
    if (file.size / 1024 / 1024 > 10) {
      showToast('error', '10MBを超えるファイルは送信できません');
      return false;
    }
    return true;
  };

  const metadata = () => {
    return {
      customMetadata: {
        teamId: thread.teamId,
        uploader: store.me.id,
      },
    };
  };

  const onUpload = async (uploadedFile: UploadedFile) => {
    const resizedFile = await resize(uploadedFile.file);
    const previewPath = toPreviewPath(uploadedFile.ref.fullPath);
    await uploadBytes(ref(storage, previewPath), resizedFile, metadata());
  };

  const onRemove = async (uploadedFile: UploadedFile) => {
    const previewPath = toPreviewPath(uploadedFile.ref.fullPath);
    await deleteObject(ref(storage, previewPath)).catch((e) => {
      if (e.code !== 'storage/object-not-found') {
        throw e;
      }
    });
  };

  const {
    files,
    uploadedFiles,
    allUploaded,
    remove,
    clearFiles,
    getRootProps,
    getInputProps,
    open,
    isDragActive,
  } = useUploadWithDropzone({
    uploadPath,
    validate,
    metadata,
    onUpload,
    onRemove,
    noClick: true,
    noKeyboard: true,
    accept: {
      'image/jpeg': [],
      'image/png': [],
    },
    disabled,
  });

  const isEmptyMessage = /^\s*$/.test(message);

  const cannotSend =
    sending ||
    (isEmptyMessage && !uploadedFiles.length) ||
    !allUploaded ||
    disabled;

  const onClickFile = async (uploadFile: UploadFile) => {
    if (uploadFile.uploading) {
      setPreviewImageUrl(URL.createObjectURL(uploadFile.file));
    } else {
      setPreviewImageUrl(await getDownloadURL(uploadFile.ref));
    }
  };

  const sendMessage = async () => {
    if (cannotSend) {
      return;
    }
    setSending(true);

    const key = `lineThread-${thread.id}`;
    antdMessage.loading({
      content: 'メッセージを送信中です',
      key,
      duration: 0,
    });
    try {
      const messages: LineEvent[] = [];

      if (!isEmptyMessage) {
        messages.push({ type: 'text', text: message });
      }

      const imageEvents = await Promise.all(
        uploadedFiles.map(async (f) => {
          return {
            type: 'image',
            originalContentUrl: await getDownloadURL(f.ref),
            previewImageUrl: await getDownloadURL(
              ref(storage, toPreviewPath(f.ref.fullPath))
            ),
          } as LineImageEvent;
        })
      );

      messages.push(...imageEvents);

      await sendLineMessageFunction({
        companyId: store.signInCompany,
        lineThreadId: thread.id,
        messages,
      });
      antdMessage.success({
        content: 'メッセージを送信しました',
        key,
      });
      setMessage('');
      clearFiles();
    } catch (e) {
      antdMessage.error({
        content: 'メッセージの送信に失敗しました',
        key,
      });
      throw e;
    }
    setSending(false);
  };

  return (
    <div
      {...getRootProps()}
      className="relative flex w-full items-center gap-4"
    >
      {isDragActive && (
        <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center bg-white/50">
          <Icon icon="file-add" className="text-[32px] text-[#595959]" />
          ドロップしてファイルをアップロード
        </div>
      )}
      <input {...getInputProps()} />
      <Avatar user={user} name={user.name} />
      <div
        className={twMerge(
          'flex flex-1 items-center gap-2 rounded border-[1px] border-sumi-300 px-2',
          disabled ? 'cursor-not-allowed bg-[#f5f5f5]' : ''
        )}
      >
        <button
          className="flex cursor-pointer items-center justify-center bg-transparent text-sea-500 disabled:cursor-not-allowed disabled:text-sumi-400"
          onClick={open}
          disabled={disabled}
        >
          <Icon icon={Plus} size={16} />
        </button>
        <div className="flex flex-1 flex-col gap-1 py-1">
          <TextareaAutosize
            placeholder="メッセージを入力してください"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyDown={(e) => isCmdOrCtrlEnter(e) && sendMessage()}
            disabled={disabled}
            className="w-full resize-none border-none placeholder-sumi-500 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-[#f5f5f5]"
            rows={1}
            maxRows={2}
          />
          {!!files.length && (
            <div className="flex gap-2">
              {files.map((f) => (
                <div
                  key={f.id}
                  className="flex h-9 gap-2 rounded border-[1px] border-sumi-300 p-2"
                >
                  <div
                    onClick={() => onClickFile(f)}
                    className="flex cursor-pointer text-sea-500"
                  >
                    <Icon icon={Attach} />
                    <div className=" underline">{f.file.name}</div>
                  </div>
                  <div
                    className="flex cursor-pointer items-center justify-center rounded-full px-1 hover:bg-sumi-100"
                    onClick={() => remove(f.id)}
                  >
                    {f.uploading ? (
                      <Loading size={11} />
                    ) : (
                      <Icon icon={Close} className="text-sumi-900" size={11} />
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        <button
          className="flex cursor-pointer items-center justify-center bg-transparent p-0 text-sea-500 disabled:cursor-not-allowed disabled:text-sumi-400 disabled:hover:bg-transparent"
          onClick={sendMessage}
          disabled={cannotSend}
        >
          <Icon icon={AddCommentSubmit} size={24} />
        </button>
      </div>
    </div>
  );
};
