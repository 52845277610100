import { ForwardEmail } from '../ForwardEmail/ForwardEmail';
import { InputGroup } from '../../../../../components/forms/InputGroup/InputGroup';

type Props = {
  email: string;
  fontWeight?: 'normal' | 'bold';
  largeLabel?: boolean;
};

export const ForwardingAddressField = ({
  email,
  fontWeight,
  largeLabel,
}: Props) => {
  return (
    <InputGroup
      label={
        <span className={largeLabel ? 'text-base font-bold' : undefined}>
          転送コード
        </span>
      }
      description={
        <>
          お使いのメールサーバー（Gmail・Xserverなど）の設定から「転送先」に指定ください。
          転送方法がわからない場合は
          <a
            href="https://efficacious-eggplant-e38.notion.site/dc339f420d6047c0b0c233fc6a1b418e"
            target="_blank"
            rel="noopener noreferrer"
            className="underline"
          >
            こちら
          </a>
          をご参考ください
        </>
      }
    >
      <ForwardEmail
        value={email}
        className={fontWeight === 'bold' ? 'font-bold' : ''}
      />
    </InputGroup>
  );
};
