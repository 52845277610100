import React from 'react';
import styled from 'styled-components';
import * as color from '../../../color';

export const Footer = () => (
  <div>
    <ToCompanyLink
      href="https://onebox.tokyo"
      target="_blank"
      rel="noopener noreferrer"
    >
      運営会社
    </ToCompanyLink>
  </div>
);

const ToCompanyLink = styled.a`
  display: block;
  margin-top: 20px;
  color: ${color.a.text.normal};
  font-size: 15px;
  :hover {
    color: inherit;
  }
`;
