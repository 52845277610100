import {
  ComponentProps,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Dialog } from '../../basics/dialog/Dialog';
import { DialogHeader } from '../../basics/dialog/DialogHeader';
import { Button } from '../../basics';
import { DialogContent } from '../../basics/dialog/DialogContent';
import { DialogFooter } from '../../basics/dialog/DialogFooter';
import { InputGroup } from '../../forms/InputGroup/InputGroup';
import { Input } from '../../forms';
import { Avatar } from '../../basics/Avatar/Avatar';
import { throttle, uniq } from 'lodash';
import SimpleBar from 'simplebar-react';
import SimpleBarCore from 'simplebar-core';
import styles from './ChatSettingsDialog.module.css';
import { twMerge } from 'tailwind-merge';

type Member = {
  id: string;
  avatar: NonNullable<ComponentProps<typeof Avatar>['user']>;
  name: string;
  email: string;
};

type Props = Pick<
  ComponentProps<typeof Dialog>,
  'open' | 'onOpenChange' | 'container' | 'modal'
> & {
  members: Member[];
  onSubmit: (subject: string, members: string[]) => Promise<boolean>;
  defaultValues?: {
    subject: string;
    memberIds: string[];
  };
};

export const ChatSettingsDialog = ({
  members,
  onSubmit,
  defaultValues,
  ...props
}: Props) => {
  const [subject, setSubject] = useState(defaultValues?.subject ?? '');
  const [loading, setLoading] = useState(false);
  const [selectedMemberIds, setSelectedMemberIds] = useState<string[]>(
    defaultValues?.memberIds ?? []
  );
  const unselectedMembers = useMemo(
    () =>
      members
        .filter((m) => !selectedMemberIds.includes(m.id))
        .sort((a, b) => a.name.localeCompare(b.name, 'ja')),
    [members, selectedMemberIds]
  );
  const selectedMembers = useMemo(
    () =>
      members
        .filter((m) => selectedMemberIds.includes(m.id))
        .sort((a, b) => a.name.localeCompare(b.name, 'ja')),
    [members, selectedMemberIds]
  );

  const handleSubmit = async () => {
    setLoading(true);
    if (await onSubmit(subject.trim(), selectedMemberIds)) {
      setSubject('');
      setSelectedMemberIds([]);
    }
    setLoading(false);
  };

  return (
    <Dialog width="sm" contentClassName="top-[10%]" {...props}>
      <DialogHeader title="チャット設定" />
      <DialogContent>
        <div className="flex flex-col gap-4">
          <InputGroup label={<span className="font-bold">チャットの件名</span>}>
            <Input
              placeholder="チャットの件名を入力してください"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              autoFocus
              disabled={loading}
            />
          </InputGroup>
          {selectedMembers.length > 0 && (
            <InputGroup
              label={<span className="font-bold">追加済みのメンバー</span>}
            >
              <Members>
                {selectedMembers.map((member) => (
                  <UserEntry
                    key={member.id}
                    member={member}
                    type="remove"
                    onClick={() =>
                      setSelectedMemberIds(
                        selectedMemberIds.filter((id) => id !== member.id)
                      )
                    }
                    disabled={loading}
                  />
                ))}
              </Members>
            </InputGroup>
          )}
          <InputGroup
            label={<span className="font-bold">メンバーを追加する</span>}
          >
            <>
              {unselectedMembers.length === 0 ? (
                <div className="flex h-[40px] items-center justify-center text-sumi-600">
                  追加できるメンバーがいません
                </div>
              ) : (
                <Members>
                  {unselectedMembers.map((member) => (
                    <UserEntry
                      key={member.id}
                      member={member}
                      type="add"
                      onClick={() =>
                        setSelectedMemberIds(
                          uniq([...selectedMemberIds, member.id])
                        )
                      }
                      disabled={loading}
                    />
                  ))}
                </Members>
              )}
            </>
          </InputGroup>
        </div>
      </DialogContent>
      <DialogFooter>
        <Button
          variant="outlined"
          onClick={() => props.onOpenChange?.(false)}
          disabled={loading}
        >
          キャンセル
        </Button>
        <Button
          onClick={() => handleSubmit()}
          disabled={!selectedMemberIds.length}
          loading={loading}
        >
          {defaultValues ? '更新' : '作成'}
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

const UserEntry = ({
  member,
  type,
  onClick,
  disabled,
}: {
  member: Member;
  type: 'add' | 'remove';
  onClick: () => void;
  disabled: boolean;
}) => {
  const isAdd = type === 'add';
  return (
    <div className="grid grid-cols-[auto_1fr_auto] items-center gap-2">
      <Avatar size={40} user={member.avatar} />
      <div className="grid grid-rows-2 leading-5">
        <div
          className="overflow-x-hidden truncate font-bold"
          title={member.name}
        >
          {member.name}
        </div>
        <div
          className="overflow-x-hidden truncate text-sumi-600"
          title={member.email}
        >
          {member.email}
        </div>
      </div>
      <Button
        variant="text"
        color={isAdd ? 'primary' : 'danger'}
        onClick={() => onClick()}
        disabled={disabled}
      >
        {isAdd ? '追加' : '除外'}
      </Button>
    </div>
  );
};

const Members = ({ children }: { children: ReactNode }) => {
  const simpleBarRef = useRef<SimpleBarCore>(null);
  const [showTopGradient, setShowTopGradient] = useState(false);
  const [showBottomGradient, setShowBottomGradient] = useState(false);
  useEffect(() => {
    const instance = simpleBarRef.current;
    if (!instance) {
      return;
    }

    const wrapperEl = instance.contentWrapperEl;
    if (!wrapperEl) {
      return;
    }

    const update = throttle(() => {
      const scrollTop = wrapperEl.scrollTop;
      const scrollBottom =
        wrapperEl.scrollHeight - wrapperEl.scrollTop - wrapperEl.clientHeight;

      if (scrollTop > 16) {
        setShowTopGradient(true);
      } else {
        setShowTopGradient(false);
      }

      if (scrollBottom > 16) {
        setShowBottomGradient(true);
      } else {
        setShowBottomGradient(false);
      }
    }, 100);

    const observer = new ResizeObserver(update);

    update();
    observer.observe(wrapperEl);
    wrapperEl.addEventListener('scroll', update);
    return () => {
      wrapperEl.removeEventListener('scroll', update);
      observer.disconnect();
    };
  }, []);
  return (
    <div className="relative">
      <SimpleBar
        className="max-h-[calc(40px_*_5)] overflow-x-hidden"
        ref={simpleBarRef}
      >
        <div className="flex flex-col gap-2">{children}</div>
      </SimpleBar>
      <div
        className={twMerge(
          'pointer-events-none absolute top-0 h-4 w-full transition-opacity',
          showTopGradient ? 'opacity-100' : 'opacity-0',
          styles.gradientTop
        )}
      />
      <div
        className={twMerge(
          'pointer-events-none absolute bottom-0 h-4 w-full transition-opacity',
          showBottomGradient ? 'opacity-100' : 'opacity-0',
          styles.gradientBottom
        )}
      />
    </div>
  );
};
