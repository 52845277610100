import { Store } from './index';
import {
  CollectionReference,
  getCountFromServer,
  limit,
  orderBy,
  query,
  Query,
  startAfter,
  Timestamp,
  where,
} from 'firebase/firestore';
import { fetchQueryInArray, subscribeQueryInArray } from '../utils/firebase';
import { makeObservable, observable } from 'mobx';
import _ from 'lodash';
import { createEntity } from 'lib/dist/entity/entity';
import {
  LineAccount,
  LineAccountData,
  LineContactData,
  LineMessageData,
  LineThread,
  LineThreadData,
  LineThreadStatus,
} from 'lib';

const threadsPerPage = 40;

export class LineStore {
  unsubscribeAccounts?: () => void;
  unsubscribeThreads?: () => void;

  accounts: LineAccount[] = [];
  threads: LineThread[] = [];

  threadCounts: Record<string, number> = {};

  hasMoreThreads = false;
  loadingThread = false;

  lineAccountId: string | undefined = undefined;

  constructor(private rootStore: Store) {
    makeObservable(this, {
      accounts: observable,
      threads: observable,

      threadCounts: observable,

      hasMoreThreads: observable,
      loadingThread: observable,

      lineAccountId: observable,
    });
  }

  accountCollection = () => {
    return this.rootStore.collection(
      'lineAccounts'
    ) as CollectionReference<LineAccountData>;
  };

  contactCollection = () => {
    return this.rootStore.collection(
      'lineContacts'
    ) as CollectionReference<LineContactData>;
  };

  threadCollection = () => {
    return this.rootStore.collection(
      'lineThreads'
    ) as CollectionReference<LineThreadData>;
  };

  messageCollection = () => {
    return this.rootStore.collection(
      'lineMessages'
    ) as CollectionReference<LineMessageData>;
  };

  syncLine = () => {
    if (!this.rootStore.joinedTeamIds.length) {
      return;
    }

    this.syncLineAccounts();
    this.syncLineThreads();
  };

  syncLineAccounts = () => {
    this.unsubscribeAccounts?.();

    this.unsubscribeAccounts = subscribeQueryInArray(
      query(this.accountCollection(), orderBy('createdAt')),
      'teamId',
      this.rootStore.joinedTeamIds,
      async (snap, docs) => {
        this.accounts = docs.map((doc) => createEntity(doc));
        await Promise.all(
          this.accounts.map((account) =>
            this.fetchThreadCount(account.teamId, account.id)
          )
        );
      }
    );
  };

  withFilter = (q: Query<LineThreadData>): Query<LineThreadData> => {
    let withFilter = q;
    if (this.lineAccountId) {
      withFilter = query(
        withFilter,
        where('lineAccountId', '==', this.lineAccountId)
      );
    }

    const view: string = this.statusFilter();
    if (view !== 'all') {
      withFilter = query(withFilter, where('status', '==', view));
    }
    return withFilter;
  };

  syncLineThreads = async () => {
    this.unsubscribeThreads?.();
    this.loadingThread = true;

    const docs = await fetchQueryInArray(
      this.withFilter(
        query(
          this.threadCollection(),
          limit(threadsPerPage),
          orderBy('updatedAt', 'desc')
        )
      ),
      'teamId',
      this.rootStore.joinedTeamIds
    );
    this.hasMoreThreads = docs.length === threadsPerPage;
    this.threads = _.orderBy(
      docs.map((doc) => createEntity(doc)),
      (thread) => thread.updatedAt.toDate(),
      'desc'
    );
    this.loadingThread = false;

    const view = this.statusFilter();
    this.unsubscribeThreads = subscribeQueryInArray(
      query(this.threadCollection(), where('updatedAt', '>', Timestamp.now())),
      'teamId',
      this.rootStore.joinedTeamIds,
      async (snap) => {
        const threads = [...this.threads];
        await Promise.all(
          snap.docChanges().map(async (change) => {
            if (change.type === 'added' || change.type === 'modified') {
              const doc = change.doc;
              const data = doc.data();
              _.remove(threads, (thread) => thread.id === doc.id);
              await this.fetchThreadCount(data.teamId, data.lineAccountId);
              if (
                this.lineAccountId &&
                data.lineAccountId !== this.lineAccountId
              ) {
                return;
              }
              if (view !== 'all' && view !== data.status) {
                return;
              }
              threads.push(createEntity(doc));
            }
          })
        );
        this.threads = _.orderBy(threads, (thread) => thread.updatedAt, 'desc');
      }
    );
  };

  loadMoreLineThreads = async () => {
    let q = query(
      this.threadCollection(),
      limit(threadsPerPage),
      orderBy('updatedAt', 'desc')
    );
    if (this.threads.length) {
      q = query(q, startAfter(this.threads.slice(-1)[0].doc));
    }
    const docs = await fetchQueryInArray(
      this.withFilter(q),
      'teamId',
      this.rootStore.joinedTeamIds
    );
    this.hasMoreThreads = docs.length === threadsPerPage;
    this.threads.push(...docs.map((doc) => createEntity(doc)));
  };

  fetchThreadCount = async (teamId: string, lineAccountId: string) => {
    const snap = await getCountFromServer(
      query(
        this.threadCollection(),
        where('teamId', '==', teamId),
        where('lineAccountId', '==', lineAccountId),
        where('status', '==', LineThreadStatus.Unprocessed)
      )
    );

    this.threadCounts[lineAccountId] = snap.data().count;
  };

  statusFilter = () => {
    const view = this.rootStore.selectedMessageView;
    return view === 'messages' ? 'unprocessed' : view;
  };
}
