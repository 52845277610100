import { Router, SettingObjectPaths, SettingObjectRoutes } from './common';
import {
  CompanyEmailPaths,
  CompanyEmailRoutes,
} from './email/CompnayEmail/CompanyEmailRoutes';
import { MyEmailPaths, MyEmailRoutes } from './email/MyEmail/MyEmailRoutes';
import { IntegrationPaths, IntegrationRoutes } from './integration';

export const MY_EMAIL_PATHS: MyEmailPaths = {
  base: `/settings/me/mail`,
  inbox: `/settings/me/mail/inbox`,
  inboxNew: `/settings/me/mail/inbox/new`,
  inboxDetail: `/settings/me/mail/inbox/:inboxId`,
  autoReply: `/settings/me/mail/autoreply`,
  detail: `/settings/me/mail/detail`,
} as const;

export const MY_TEMPLATE_PATHS: SettingObjectPaths = {
  index: `/settings/me/mail/templates`,
  new: `/settings/me/mail/templates/new`,
  detail: `/settings/me/mail/templates/:templateId`,
} as const;

export const MY_SIGNATURE_PATHS: SettingObjectPaths = {
  index: `/settings/me/mail/signatures`,
  new: `/settings/me/mail/signatures/new`,
  detail: `/settings/me/mail/signatures/:signatureId`,
} as const;

export const MY_TAG_PATHS: SettingObjectPaths = {
  index: `/settings/me/mail/tags`,
  new: `/settings/me/mail/tags/new`,
  detail: `/settings/me/mail/tags/:tagId`,
} as const;

export const MY_FILTER_PATHS: SettingObjectPaths = {
  index: `/settings/me/mail/filters`,
  new: `/settings/me/mail/filters/new`,
  detail: `/settings/me/mail/filters/:filterId`,
} as const;

export const COMPANY_EMAIL_PATHS: CompanyEmailPaths = {
  base: `/settings/teams/:teamId/mail`,
} as const;

export const TEAM_INBOX_PATHS: SettingObjectPaths = {
  index: `/settings/teams/:teamId/mail/inboxes`,
  new: `/settings/teams/:teamId/mail/inboxes/new`,
  detail: `/settings/teams/:teamId/mail/inboxes/:inboxId`,
} as const;

export const TEAM_TEMPLATE_PATHS: SettingObjectPaths = {
  index: `/settings/teams/:teamId/mail/templates`,
  new: `/settings/teams/:teamId/mail/templates/new`,
  detail: `/settings/teams/:teamId/mail/templates/:templateId`,
} as const;

export const TEAM_SIGNATURE_PATHS: SettingObjectPaths = {
  index: `/settings/teams/:teamId/mail/signatures`,
  new: `/settings/teams/:teamId/mail/signatures/new`,
  detail: `/settings/teams/:teamId/mail/signatures/:signatureId`,
} as const;

export const TEAM_TAG_PATHS: SettingObjectPaths = {
  index: `/settings/teams/:teamId/mail/tags`,
  new: `/settings/teams/:teamId/mail/tags/new`,
  detail: `/settings/teams/:teamId/mail/tags/:tagId`,
} as const;

export const TEAM_FILTER_PATHS: SettingObjectPaths = {
  index: `/settings/teams/:teamId/mail/filters`,
  new: `/settings/teams/:teamId/mail/filters/new`,
  detail: `/settings/teams/:teamId/mail/filters/:filterId`,
} as const;

export const INTEGATION_PATHS: IntegrationPaths = {
  index: `/settings/integrations`,
  callbacks: {
    slack: `/settings/integrations/slack`,
    chatwork: `/settings/integrations/chatwork`,
  },
};

export const createMyEmailRoutes = (router: Router): MyEmailRoutes =>
  new MyEmailRoutes(
    MY_EMAIL_PATHS,
    new SettingObjectRoutes(MY_TEMPLATE_PATHS, router),
    new SettingObjectRoutes(MY_SIGNATURE_PATHS, router),
    new SettingObjectRoutes(MY_TAG_PATHS, router),
    new SettingObjectRoutes(MY_FILTER_PATHS, router),
    router
  );

export const createCompanyEmailRoutes = (router: Router): CompanyEmailRoutes =>
  new CompanyEmailRoutes(
    MY_EMAIL_PATHS,
    new SettingObjectRoutes(TEAM_INBOX_PATHS, router),
    new SettingObjectRoutes(TEAM_TEMPLATE_PATHS, router),
    new SettingObjectRoutes(TEAM_SIGNATURE_PATHS, router),
    new SettingObjectRoutes(TEAM_TAG_PATHS, router),
    new SettingObjectRoutes(TEAM_FILTER_PATHS, router),
    router
  );

export const createIntegrateRoutes = (router: Router): IntegrationRoutes =>
  new IntegrationRoutes(INTEGATION_PATHS, router, window.location.origin);
