import { Dialog } from '../../../basics/dialog/Dialog';
import { DialogHeader } from '../../../basics/dialog/DialogHeader';
import { DialogContent } from '../../../basics/dialog/DialogContent';
import { DialogFooter } from '../../../basics/dialog/DialogFooter';
import { InputGroup } from '../../../forms/InputGroup/InputGroup';
import { Input } from '../../../forms';
import { Button } from '../../../basics';
import { useForm } from 'react-hook-form';
import { useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash';
import { normalizeAddress } from 'lib';

type Props = {
  onAdd: (name: string | undefined, address: string) => Promise<void>;
  checkDuplicate: (address: string) => Promise<boolean>;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  container?: HTMLElement;
  modal?: boolean;
};

type FormData = {
  name: string;
  address: string;
};

export const DeliveryAddEmailAddressDialog = ({
  open,
  onOpenChange,
  onAdd,
  checkDuplicate: checkDuplicateInstantly,
  container,
  modal,
}: Props) => {
  const [emailDuplicated, setEmailDuplicated] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting, isValid },
  } = useForm<FormData>({
    defaultValues: {
      name: '',
      address: '',
    },
  });
  const onSubmit = async (data: FormData) => {
    if (await checkDuplicateInstantly(data.address)) {
      return;
    }
    await onAdd(data.name.length > 0 ? data.name : undefined, data.address);
    onOpenChange(false);
  };

  const checkDuplicate = useMemo(
    () =>
      debounce(async (email: string) => {
        const result = await checkDuplicateInstantly(normalizeAddress(email));
        setEmailDuplicated(result);
        return result;
      }, 200),
    [checkDuplicateInstantly]
  );
  const addressValue = watch('address');
  useEffect(() => {
    if (!addressValue) {
      setEmailDuplicated(false);
      return;
    }

    checkDuplicate(normalizeAddress(addressValue));
  }, [addressValue]);

  return (
    <Dialog
      open={open}
      onOpenChange={onOpenChange}
      container={container}
      modal={modal}
    >
      <form action="" onSubmit={handleSubmit(onSubmit)}>
        <DialogHeader title="配信用メールアドレスを追加" />
        <DialogContent className="flex flex-col gap-4">
          <InputGroup label="表示名" errorMessage={errors.name?.message}>
            <Input
              {...register('name', {
                setValueAs: (v) => (typeof v === 'string' ? v.trim() : v),
              })}
              placeholder="Onebox株式会社"
              autoFocus
            />
          </InputGroup>
          <InputGroup
            label="メールアドレス"
            errorMessage={
              emailDuplicated
                ? '既に登録されているメールアドレスです'
                : errors.address?.message
            }
            required
          >
            <Input
              {...register('address', {
                setValueAs: (v) => (typeof v === 'string' ? v.trim() : v),
                required: '入力してください',
              })}
              placeholder="noreply@example.com"
              type="email"
            />
          </InputGroup>
        </DialogContent>
        <DialogFooter>
          <Button
            type="submit"
            disabled={!isValid || emailDuplicated}
            loading={isSubmitting}
          >
            追加
          </Button>
        </DialogFooter>
      </form>
    </Dialog>
  );
};
