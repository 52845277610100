import { useStore } from 'hooks/useStore';
import { BaseMessagePane } from './BaseMessagePane';
import { useLocation } from 'react-router-dom';
import { Messages } from '../MessageList/Teams/Messages';
import { MessageView } from 'store/messages';
import { TeamMessageListEmpty } from 'components/MessageList';
import { observer } from 'mobx-react';
import { isDeletedPaneLocation } from 'utils/messagePane';
import { useAtomValue } from 'jotai';
import { messagesAtom } from '../../../atoms/firestore/message';

export const PATHS = [
  '/teams/:teamId/tags/:tagId/:tab',
  '/teams/:teamId/inboxes/:inboxId/tags/:tagId/:tab',
  '/me/assigned/:tab',
  '/search/messages/:query',
  '/search/deleted/:query',
];

// チーム - 受信
// チーム - 検索
export const MessagePaneTeamMessages: React.FC = observer(() => {
  const store = useStore();
  const location = useLocation();
  const messages = useAtomValue(messagesAtom);
  const {
    searching: searchingMessages,
    sortedMessages: sortedSearchedMessages,
    inSearch,
  } = store.searchStore;

  const messageList = inSearch
    ? sortedSearchedMessages
    : messages.state === 'hasData'
    ? messages.data
    : [];

  const loading = inSearch ? searchingMessages : messages.state === 'loading';

  const empty = messageList.length === 0 && !loading;

  const getEmptyDescription = () => {
    const {
      selectedMessageView,
      searchStore: { inSearch },
    } = store;
    if (inSearch) {
      return '検索条件と一致するメールはありません';
    }

    if (isDeletedPaneLocation(location)) return 'ゴミ箱にメールはありません';

    return (() => {
      switch (selectedMessageView) {
        case MessageView.Unprocessed:
          return '未対応のメールはありません🎉';
        case MessageView.Processed:
          return '対応済みのメールはありません';
        case MessageView.All:
          return 'すべてのメールはありません';
        default:
          return 'メールはありません';
      }
    })();
  };

  return (
    <BaseMessagePane
      listPagePath={PATHS}
      empty={empty}
      emptyComponent={
        <TeamMessageListEmpty description={getEmptyDescription()} />
      }
      listComponent={<Messages />}
    />
  );
});
