import { ContactsSidebar } from './ContactsSidebar';
import { atom, useAtomValue } from 'jotai';
import {
  joinedTeamsAtom,
  privateTeamAtom,
} from '../../../atoms/firestore/team';

const nonPrivateTeamsAtom = atom((get) =>
  get(joinedTeamsAtom).filter((t) => !t.isPrivate)
);

export const ContactsSidebarWithLogic = () => {
  const privateTeam = useAtomValue(privateTeamAtom);
  const teams = useAtomValue(nonPrivateTeamsAtom);
  return (
    <ContactsSidebar
      privateTeamId={privateTeam.id}
      teams={teams.map((team) => ({
        id: team.id,
        name: team.name,
      }))}
    />
  );
};
