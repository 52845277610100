import * as React from 'react';
import type { SVGProps } from 'react';
const SvgHistory = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M11.98 20.5c-2.01 0-3.782-.613-5.315-1.84-1.534-1.227-2.516-2.797-2.948-4.71a.622.622 0 0 1 .097-.548.693.693 0 0 1 .504-.29.778.778 0 0 1 .55.12.844.844 0 0 1 .339.476c.38 1.544 1.2 2.813 2.46 3.804C8.928 18.505 10.366 19 11.98 19c1.95 0 3.604-.68 4.963-2.038C18.3 15.604 18.98 13.95 18.98 12c0-1.95-.68-3.604-2.037-4.963C15.584 5.68 13.93 5 11.98 5a6.685 6.685 0 0 0-3.071.728A7.558 7.558 0 0 0 6.442 7.73h1.865c.212 0 .39.072.534.215a.726.726 0 0 1 .216.535c0 .213-.072.39-.216.534a.726.726 0 0 1-.534.216H4.884a.874.874 0 0 1-.644-.26.875.875 0 0 1-.26-.644V4.904c0-.213.072-.39.216-.535a.726.726 0 0 1 .534-.215c.213 0 .391.072.535.215a.726.726 0 0 1 .215.535v1.62a8.488 8.488 0 0 1 2.915-2.23A8.369 8.369 0 0 1 11.98 3.5c1.18 0 2.285.223 3.316.67a8.609 8.609 0 0 1 2.696 1.818 8.61 8.61 0 0 1 1.819 2.696c.446 1.03.669 2.136.669 3.315 0 1.18-.223 2.286-.67 3.316a8.61 8.61 0 0 1-1.818 2.697 8.607 8.607 0 0 1-2.696 1.819 8.254 8.254 0 0 1-3.316.669Zm.779-8.804 2.75 2.75c.138.139.21.313.212.522a.707.707 0 0 1-.212.532.718.718 0 0 1-.527.217.718.718 0 0 1-.527-.217l-2.925-2.925a.9.9 0 0 1-.271-.648V7.75c0-.213.072-.39.216-.534A.726.726 0 0 1 12.009 7c.213 0 .39.072.534.216a.726.726 0 0 1 .216.534v3.946Z"
    />
  </svg>
);
export default SvgHistory;
