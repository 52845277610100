import * as React from 'react';
import type { SVGProps } from 'react';
const SvgMessageReplyAll = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    viewBox="0 0 18 18"
    {...props}
  >
    <path
      fill="currentColor"
      d="M3.112 8.175 6.337 11.4a.508.508 0 0 1 .16.394.575.575 0 0 1-.178.393.55.55 0 0 1-.403.17.55.55 0 0 1-.404-.17L1.894 8.57a.571.571 0 0 1-.132-.188.55.55 0 0 1-.037-.206.55.55 0 0 1 .037-.206.571.571 0 0 1 .132-.188L5.53 4.144a.55.55 0 0 1 .403-.169.55.55 0 0 1 .403.169.55.55 0 0 1 .17.403.55.55 0 0 1-.17.403L3.112 8.175Zm3.938.563L9.712 11.4a.508.508 0 0 1 .16.394.575.575 0 0 1-.178.393.55.55 0 0 1-.403.17.55.55 0 0 1-.404-.17L5.27 8.57a.571.571 0 0 1-.132-.188.55.55 0 0 1-.037-.206.55.55 0 0 1 .037-.206.571.571 0 0 1 .132-.188l3.637-3.637a.55.55 0 0 1 .403-.169.55.55 0 0 1 .403.169.55.55 0 0 1 .17.403.55.55 0 0 1-.17.403L7.05 7.613h5.719c1.062 0 1.947.353 2.653 1.059s1.06 1.59 1.06 2.653v2.363a.546.546 0 0 1-.563.562.546.546 0 0 1-.563-.563v-2.362c0-.75-.243-1.369-.731-1.856-.488-.488-1.106-.731-1.856-.731H7.05Z"
    />
  </svg>
);
export default SvgMessageReplyAll;
