import { matchPath, useHistory, useLocation } from 'react-router-dom';
import { useStore } from '../../../../../hooks/useStore';
import { Empty } from 'antd';
import React from 'react';
import { observer } from 'mobx-react';
import { ScheduledDraftItem } from './ScheduledDraftItem/ScheduledDraftItem';
import { useAtom } from 'jotai';
import { scheduledDraftsAtom } from '../../../../../atoms/firestore/draft';
import { WrappedInfiniteScroll } from '../../../../../components/WrappedInfiniteScroll';

export const Scheduled = observer(() => {
  const history = useHistory();
  const location = useLocation();
  const store = useStore();
  const [scheduledDraftsPaginate, dispatch] = useAtom(scheduledDraftsAtom);

  const getEmptyDescription = () => {
    const {
      searchStore: { inSearch },
    } = store;
    return inSearch
      ? `検索条件と一致する送信予約はありません`
      : '送信予約はありません';
  };

  return (
    <WrappedInfiniteScroll
      initialLoad={false}
      pageStart={0}
      loadMore={() => dispatch('loadMore')}
      hasMore={scheduledDraftsPaginate.hasMore}
      useWindow={false}
    >
      {scheduledDraftsPaginate.state === 'hasData' &&
        scheduledDraftsPaginate.data.length === 0 && (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={getEmptyDescription()}
          />
        )}
      {scheduledDraftsPaginate.state === 'hasData' &&
        scheduledDraftsPaginate.data.map((d) => {
          const link = `/me/scheduled/${d.id}`;
          const active =
            matchPath(location.pathname, {
              path: link,
            }) != null;
          return (
            <ScheduledDraftItem
              key={d.id}
              subject={d.subject}
              body={d.body}
              scheduledAt={d.scheduledAt!.toDate()}
              toAddresses={d.to}
              active={active}
              failed={d.isFailed}
              onClick={() => history.push(link)}
            />
          );
        })}
    </WrappedInfiniteScroll>
  );
});
