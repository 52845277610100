import { tv } from 'tailwind-variants';

type Props = {
  text: string;
  editable?: boolean;
  onClick?: () => void;
};

const item = tv({
  base: 'h-full select-none bg-transparent text-start text-sea-500',
  variants: {
    editable: {
      true: 'cursor-pointer hover:underline',
    },
  },
});

export const StatusListItemText = ({ text, onClick, editable }: Props) => {
  return (
    <div className="h-10">
      <button type="button" className={item({ editable })} onClick={onClick}>
        {text}
      </button>
    </div>
  );
};
