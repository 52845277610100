import momentTZ from 'moment-timezone';
import {
  addTargetAndRelAttrToAnchorTag,
  convertHtmlToQuote,
  decorateHtml,
  quoteText,
  sanitizeHtml,
} from '../util';

export interface StorageMessageData {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  attachments: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  from: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  headerLines: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  headers: any[];
  html: string;
  messageId: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  replyTo: any;
  subject: string;
  text: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  textAsHtml: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  to: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cc: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  bcc: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  storagePath: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  date: any;
}

export class StorageMessage {
  private _id: string;
  private _data: StorageMessageData;
  private _date: momentTZ.Moment;
  private _sanitizedTextAsHtml?: string;
  private _decoratedHtml?: string;

  constructor(id: string, data: StorageMessageData) {
    this._id = id;
    this._data = data;
    this._date = momentTZ(this._data.date); // 毎回生成するのはコストがかかるため
  }

  get id() {
    return this._id;
  }

  get attachments() {
    return this._data.attachments;
  }

  get date() {
    return this._date;
  }

  get from() {
    return this._data.from;
  }

  get fromText() {
    return this._data.from.text || '';
  }

  get fromIconName() {
    return this.fromText.length > 0 ? this.fromText[0].toUpperCase() : '';
  }

  get headerLines() {
    return this._data.headerLines;
  }

  get headers() {
    return this._data.headers;
  }

  get html() {
    const html = this._data.html;
    if (!html) return html;
    if (!this._decoratedHtml) this._decoratedHtml = decorateHtml(html);
    return this._decoratedHtml;
  }

  get htmlForQuote() {
    if (!this._data.html) return this._data.html; // html対応ではないメールの場合
    return convertHtmlToQuote(this._data.html);
  }

  get messageId() {
    return this._data.messageId;
  }

  get replyTo() {
    return this._data.replyTo;
  }

  get subject() {
    return this._data.subject || '';
  }

  get text() {
    return this._data.text || ''; // textがundefinedの場合がある。（例: Thunderbirdから転送された場合）
  }

  get quotedText() {
    return quoteText(this.text);
  }

  get sanitizedTextAsHtml() {
    if (!this._sanitizedTextAsHtml)
      this._sanitizedTextAsHtml = addTargetAndRelAttrToAnchorTag(
        sanitizeHtml(this.textAsHtml)
      );
    return this._sanitizedTextAsHtml;
  }

  get textAsHtml() {
    return this._data.textAsHtml;
  }

  get to() {
    return this._data.to;
  }

  get cc() {
    return this._data.cc;
  }

  get bcc() {
    return this._data.bcc;
  }

  get storagePath() {
    return this._data.storagePath;
  }

  generateQuotedHeader() {
    return `${this.date.format('YYYY年M月D日(ddd) HH:mm')} ${this.fromText}:\n`;
  }

  generateForwardedHeader() {
    let body = `---------- Forwarded message ---------
From: ${this.from.text}
Date: ${this.date.format('YYYY年M月D日(ddd) HH:mm')}
Subject: ${this.subject}
To: ${this.to?.text || ''}
`;

    if (this.cc) {
      body += `Cc: ${this.cc.text}\n`;
    }
    return body + `\n`;
  }
}
