import * as React from 'react';
import type { SVGProps } from 'react';
const SvgAutoReplied = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4.566 0 2 5.583l6.22.58-.976-1.646a9.168 9.168 0 0 1 4.661-1.262c5.01 0 9.071 3.98 9.071 8.891 0 4.91-4.061 8.89-9.071 8.89a9.12 9.12 0 0 1-7.227-3.516l-2.41 1.79A12.16 12.16 0 0 0 11.906 24C18.585 24 24 18.693 24 12.146 24 5.599 18.585.292 11.905.292c-2.258 0-4.372.606-6.18 1.662L4.564 0Zm12.821 12.145-9.071 5.133V7.012l9.071 5.133Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgAutoReplied;
