import { Timestamp } from 'firebase/firestore';
import { filter } from 'lodash';
import { createConverter, createEntityFactory } from '../converter';

export const chatHistoriesDelimiter = '_chatHistories_';

export interface RoomData {
  id: string;
  subject?: string;
  members: string[];
  readers: { [userId: string]: Timestamp };
  chatHistories: { [key: string]: Timestamp };
  createdAt: Timestamp;
  updatedAt: Timestamp;
}

export const createRoom = createEntityFactory('Room', (data: RoomData) => ({
  readers: data.readers ?? {},
  chatHistories: data.chatHistories ?? {},
  unreadCount: (userId: string): number => {
    const readers = data?.readers ?? {};
    const chatHistories = data?.chatHistories ?? {};

    // ユーザの既読時間を取得
    const readTimestamp = (readers ?? {})[userId];

    // 既読時間がない場合、すべて未読
    if (!readTimestamp) {
      return Object.keys(chatHistories).length;
    }

    // 自分以外、かつ未読であるメッセージを取得する
    const unreadChatHistoriesExceptMe = filter(chatHistories, (v, k) => {
      return !k.startsWith(userId) && v.seconds > readTimestamp.seconds;
    });

    return Object.keys(unreadChatHistoriesExceptMe).length;
  },
}));

export type Room = ReturnType<typeof createRoom>;

export const roomConverter = createConverter(createRoom);
