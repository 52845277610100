import { useStore } from 'hooks/useStore';
import { useLayoutEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';

export const TeamsRedirect = observer((): JSX.Element | null => {
  const store = useStore();
  const history = useHistory();
  useLayoutEffect(() => {
    const teams = store.teams;
    if (teams.length <= 0) {
      return;
    }
    const team = teams[0];
    history.replace(`/settings/teams/${team.id}/mail`);
  }, [store.teamsLoading]);
  return null;
});
