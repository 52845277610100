import { CollectionReference } from 'firebase/firestore';
import { Counter, counterConverter } from 'lib';
import { companyCollection } from '../../../firestore';
import { threadViewAtom } from '../../auth';
import { store } from '../../../providers/StoreProvider';

type CounterType = 'assigned' | 'tag';

export const counterCollection = (
  status: string,
  type: CounterType
): CollectionReference<Counter> => {
  return companyCollection(
    `${
      store.get(threadViewAtom) ? 'threadCounters' : 'counters'
    }/${status}/${type}`,
    counterConverter
  );
};
