import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import * as color from '../../../../../color';
import CreateMessage from '../../../CreateMessage';
import media from 'styled-media-query';
import { Draft as DraftEntity } from '../../../../../firestore/entity/draft';
import { getScrollContainerId } from '../../util';
import { useAtomValue } from 'jotai';
import { modalDraftAtom } from '../../../../../atoms/firestore/draft';

type Props = {
  draft: DraftEntity;
};

export const Draft = ({ draft }: Props): JSX.Element => {
  const history = useHistory();
  const frameRef = useRef<HTMLDivElement>(null);
  const modalDraft = useAtomValue(modalDraftAtom);

  const onEnter = () => {
    const container = document.querySelector(`#${getScrollContainerId()}`);
    if (container) {
      container.scrollTop = container.scrollHeight;
    }
  };

  const deleteCallback = () => {
    if (!draft.isReply && !draft.inReplyToSentId) history.push('/me/drafts');
  };

  const editingInModal = modalDraft && modalDraft.id === draft.id;

  return (
    <Wrapper ref={frameRef} className="rounded-xl shadow-card">
      {editingInModal ? (
        <div>
          別ウィンドウで編集中です
          <span role="img" aria-label="memo">
            📝
          </span>
        </div>
      ) : (
        /* 返信 */
        <CreateMessage
          draft={draft}
          onEnter={onEnter}
          deleteCallback={deleteCallback}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 16px;
  margin-top: 16px;
  ${media.greaterThan('small')`
    margin: 16px;
    border-radius: 10px;
  `};
  background-color: ${color.common.white};
`;
