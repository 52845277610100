import React, { RefObject, useEffect, useRef, useState } from 'react';
import { Icon } from 'antd';
import styled from 'styled-components';

interface Props {
  value: string;
  multiLine?: boolean;
  readOnly?: boolean;
  onChange: (v: string) => void;
}

export const LineInput = ({
  value,
  multiLine,
  readOnly,
  onChange,
}: Props): JSX.Element => {
  const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null);
  const [text, setText] = useState(value);
  const [editing, setEditing] = useState(false);
  useEffect(() => {
    setText(value);
  }, [value]);

  const startEditing = () => {
    setEditing(true);
    inputRef.current?.focus();
  };
  const submit = () => {
    onChange(text);
    setEditing(false);
  };
  const onBlur = () => {
    if (editing) {
      submit();
    }
  };
  const onClick = (e: React.MouseEvent) => {
    if (!readOnly && e.detail === 2) {
      startEditing();
    }
  };
  const onKeyDown = (e: React.KeyboardEvent) => {
    switch (e.key) {
      case 'Escape':
        setText(value);
        setEditing(false);
        return;
      case 'Enter':
        submit();
        setEditing(false);
        return;
    }
  };

  const inputProps = {
    value: text,
    readOnly: !editing,
    onBlur,
    onClick,
    onKeyDown,
    onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
      setText(e.target.value),
  };
  return (
    <LineInputRoot>
      {multiLine ? (
        <textarea
          ref={inputRef as RefObject<HTMLTextAreaElement>}
          {...inputProps}
        />
      ) : (
        <input
          type="text"
          ref={inputRef as RefObject<HTMLInputElement>}
          {...inputProps}
        />
      )}
      {!readOnly && <Icon type="edit" onClick={startEditing} />}
    </LineInputRoot>
  );
};

export const LineInputRoot = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  overflow: hidden;

  input,
  textarea {
    outline: none;
    border: 1px solid var(--blue-4);
    width: 100%;
    border-radius: 2px;
    padding: 0 0.25rem;
  }

  input[readOnly],
  textarea[readOnly] {
    border-color: transparent;
  }

  > i > svg {
    margin: 0 1rem;
    visibility: hidden;
    width: 1rem;
    height: 1rem;
    background-color: white;
    fill: var(--gray-5);
    margin-top: 0.25rem;

    :hover {
      fill: black;
    }
  }
  :hover > i > svg {
    visibility: visible;
    display: block;
  }
`;
