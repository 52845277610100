import { Alert, Button } from 'antd';
import logo from 'assets/icons/chatwork_logo.png';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { IntegrationStatus } from '../../IntegrationStatus';

export const ChatworkIntegrationDisabled: React.FC<{
  noPermission?: boolean;
  notSupported?: boolean;
  isIntegrating?: boolean;
  onIntegrate?: () => void;
}> = observer((props) => {
  const integratable = !props.noPermission && !props.notSupported;
  return (
    <Wrapper>
      <IntegrationStatus className="status" />

      {integratable ? (
        <Button
          className="integrate-button"
          size="large"
          onClick={props.onIntegrate}
          loading={props.isIntegrating}
          disabled={props.isIntegrating}
        >
          <img className="logo" src={logo} alt="chatwork_logo"></img>
          <span className="service-name">Chatwork</span>と連携
        </Button>
      ) : (
        <div className="alerts">
          {props.noPermission && (
            <Alert type="warning" message={'連携を実行する権限がありません'} />
          )}
          {props.notSupported && (
            <Alert
              type="warning"
              message={'現在のプランではChatwork連携はサポートされていません'}
            />
          )}
        </div>
      )}
    </Wrapper>
  );
});

const Wrapper = styled.div`
  & > .integrate-button {
    margin-top: 12px;
    display: flex;
    align-items: center;
    border: 1px solid #c6cacc;
    border-radius: 8px;
    letter-spacing: 0.02em;
    height: 40px;

    & .service-name {
      font-weight: 600;
    }

    & .logo {
      width: 24px;
      height: 24px;
      margin-right: 4px;
    }
  }

  & > .alerts {
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
`;
