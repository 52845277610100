import { DefaultButton } from '../../Common/Button';
import { sendEmailVerification } from 'firebase/auth';
import { useCallback, useState } from 'react';

export const VerifyEmailForm = (props) => {
  /** @type {import('firebase/auth').User} */
  const currentUser = props.currentUser;

  const [loadingVerifyEmailPromise, setLoadingVerifyEmailPromise] =
    useState(null);

  const verifyEmail = useCallback(
    (event) => {
      event.preventDefault();
      if (loadingVerifyEmailPromise !== null) {
        return;
      }

      setLoadingVerifyEmailPromise(sendEmailVerification(currentUser));
    },
    [currentUser, loadingVerifyEmailPromise]
  );

  if (props.disabled) {
    return null;
  }

  return (
    <form onSubmit={verifyEmail}>
      {!loadingVerifyEmailPromise ? (
        <p>
          2段階認証の登録をする前に、メールアドレスの検証をおこなってください。
        </p>
      ) : (
        <p>
          確認メールが送信されました。メールボックスに確認メールが届いていない場合は、迷惑メールに入っていないか確認してみてください。
          メールの確認後は、ブラウザをリロードして再度このページにアクセスしてみてください。
        </p>
      )}
      <DefaultButton type="primary" disabled={!!loadingVerifyEmailPromise}>
        メールアドレスの確認メールの送信
      </DefaultButton>
    </form>
  );
};
