import * as React from 'react';
import type { SVGProps } from 'react';
const SvgAddRowAbove = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="currentColor"
    viewBox="0 -960 960 960"
    {...props}
  >
    <path d="M200-411.15v238.84q0 5.39 3.46 8.85t8.85 3.46h535.38q5.39 0 8.85-3.46t3.46-8.85v-238.84H200ZM212.31-100q-29.83 0-51.07-21.24Q140-142.48 140-172.31v-535.38q0-29.83 21.24-51.07Q182.48-780 212.31-780h40q12.75 0 21.37 8.63 8.63 8.63 8.63 21.38 0 12.76-8.63 21.37-8.62 8.62-21.37 8.62h-40q-5.39 0-8.85 3.46t-3.46 8.85v236.54h560v-236.54q0-5.39-3.46-8.85t-8.85-3.46h-40q-12.75 0-21.37-8.63-8.63-8.63-8.63-21.38 0-12.76 8.63-21.37 8.62-8.62 21.37-8.62h40q29.83 0 51.07 21.24Q820-737.52 820-707.69v535.38q0 29.83-21.24 51.07Q777.52-100 747.69-100H212.31ZM450-720h-50q-12.75 0-21.37-8.63-8.63-8.63-8.63-21.38 0-12.76 8.63-21.37Q387.25-780 400-780h50v-50q0-12.75 8.63-21.37 8.63-8.63 21.38-8.63 12.76 0 21.37 8.63Q510-842.75 510-830v50h50q12.75 0 21.37 8.63 8.63 8.63 8.63 21.38 0 12.76-8.63 21.37Q572.75-720 560-720h-50v50q0 12.75-8.63 21.38-8.63 8.62-21.38 8.62-12.76 0-21.37-8.62Q450-657.25 450-670v-50Zm30 248.85Zm0 60v-60 60Zm0 0Z" />
  </svg>
);
export default SvgAddRowAbove;
