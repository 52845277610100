import moment from 'moment';
import { estimateServerTimestampsOption } from './common';
import firebase from 'firebase/compat/app';

export type FilterTrigger = 'receive' | 'reply';

export type FilterActionType =
  | 'assign'
  | 'assignSender'
  | 'updateStatusAsProcessed'
  | 'addTag'
  | 'skipInbox';

// TODO: union typeにする
export type FilterAction = {
  id: string;
  tags?: string[];
  type: FilterActionType;
  assignee?: string | null;
};

export type FilterCondition = {
  equalType: string;
  filterText: string;
  caseSensitive?: boolean;
  id: string;
  isOr?: boolean;
  type: string;
};

export interface FilterData {
  teamId: string;
  name: string;
  order: number;
  inboxIds: string[];
  trigger: FilterTrigger;
  conditions: FilterCondition[];
  actions: FilterAction[];
  stopOtherFilters?: boolean;
  createdAt: firebase.firestore.Timestamp;
  updatedAt: firebase.firestore.Timestamp;
}

export class Filter {
  private _id: string;
  private _data: FilterData;
  private _date: moment.Moment;
  private _ref: firebase.firestore.DocumentReference<FilterData>;

  constructor(doc: firebase.firestore.DocumentSnapshot<FilterData>) {
    this._id = doc.id;
    const data = doc.data(estimateServerTimestampsOption);
    if (!data) {
      throw new Error(`no data for filter#${doc.id}`);
    }
    this._data = data;
    this._date = moment(this._data.createdAt.toMillis()); // 毎回生成するのはコストがかかるため
    this._ref = doc.ref;
  }

  get id() {
    return this._id;
  }

  get ref() {
    return this._ref;
  }

  get date() {
    return this._date;
  }

  get teamId() {
    return this._data.teamId;
  }

  get name() {
    return this._data.name;
  }

  get order() {
    return this._data.order;
  }

  get inboxIds() {
    return this._data.inboxIds;
  }

  get trigger() {
    return this._data.trigger;
  }

  get conditions() {
    return this._data.conditions;
  }

  get actions() {
    return this._data.actions;
  }

  get stopOtherFilters() {
    return this._data.stopOtherFilters;
  }

  get createdAt() {
    return this._data.createdAt;
  }

  get updatedAt() {
    return this._data.updatedAt;
  }
}
