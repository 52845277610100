import { observer } from 'mobx-react';
import { useToast } from '../../../../../hooks/useToast';
import { ImapImportDialog, ImapUpdate } from './ImapImportDialog';
import { importFromImapFunction } from '../../../../../functions';
import { useStore } from '../../../../../hooks/useStore';

type Props = {
  inboxId: string;
  onClose: () => void;
};

export const ImapImportDialogWithLogic = observer(
  ({ inboxId, onClose }: Props) => {
    const store = useStore();
    const { showToast } = useToast();
    const handleImport = async (update: ImapUpdate) => {
      importFromImapFunction({
        companyId: store.signInCompany,
        inboxId,
        host: update.host,
        port: update.port,
        username: update.username,
        password: update.password,
      }).catch((e) => {
        console.error(e);
        showToast('error', 'インポートに失敗しました');
      });
      showToast('success', 'インポートを開始しました');
      onClose();
    };
    return (
      <ImapImportDialog
        onImport={handleImport}
        open={true}
        onOpenChange={() => onClose()}
      />
    );
  }
);
