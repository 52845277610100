import { ComponentProps } from 'react';
import { LeftSidebarCollapse } from './LeftSidebarCollapse';
import { useAtomValue } from 'jotai/index';
import { tagCountAtomFamily } from '../../../../atoms/firestore/count/tag';

type Props = ComponentProps<typeof LeftSidebarCollapse> & {
  teamId: string;
  tagId: string;
  inboxId?: string;
};

export const LeftSidebarCollapseWithCount = ({
  teamId,
  tagId,
  inboxId,
  ...props
}: Props): JSX.Element => {
  const count = useAtomValue(
    tagCountAtomFamily({
      teamId,
      tagId,
      inboxId,
    })
  );
  return <LeftSidebarCollapse {...props} count={count} />;
};
