import { Comment } from 'App/Common/Comment';
import { DefaultInput } from 'App/Common/Input';
import styled from 'styled-components';
import { SettingField } from './SettingField';

export const SettingBasicInputInputField: React.FC<{
  label: string;
  value?: string;
  placeholder?: string;
  note?: string;
  readonly?: boolean;
  disabled?: boolean;
  onChange?: (value: string) => void;
}> = ({ label, value, placeholder, note, readonly, disabled, onChange }) => (
  <SettingField label={label}>
    <SettingBasicInputInputFieldInner>
      <DefaultInput
        className="input"
        value={value}
        placeholder={placeholder}
        onChange={(e) => onChange && onChange(e.target.value)}
        readOnly={readonly}
        disabled={disabled}
      ></DefaultInput>
      <Comment className="note">{note}</Comment>
    </SettingBasicInputInputFieldInner>
  </SettingField>
);

const SettingBasicInputInputFieldInner = styled.div`
  & > .input {
    height: 40px;
  }

  & > .note {
    margin-top: 14px;
  }
`;
