import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { Avatar, List, message } from 'antd';
import firebase from 'firebase.js';
import * as color from '../../../../../color';
import PreviewImageModal, {
  isPreviewableImageMimeType,
} from '../../../../Common/Modal/previewImageModal';
import PreviewPdfModal, {
  isPdfMimeType,
} from '../../../../Common/Modal/previewPdfModal';
import {
  downloadFromUrl,
  fetchFromAttachment,
  generateFileFromAttachment,
} from '../../../../../util';

export default ({ attachments }) => {
  const [previewImage, setPreviewImage] = useState(null);
  const [previewImageUrl, setPreviewImageUrl] = useState(null);
  const [previewPdf, setPreviewPdf] = useState(null);
  const [previewPdfFile, setPreviewPdfFile] = useState(null);

  const hidePreviewImageModal = useCallback(() => {
    setPreviewImage(null);
    setPreviewImageUrl(null);
  }, []);

  const hidePreviewPdfModal = useCallback(() => {
    setPreviewPdf(null);
    setPreviewPdfFile(null);
  }, []);

  const onClickItem = useCallback(async (attachment) => {
    if (isPdfMimeType(attachment.contentType)) {
      const file = await generateFileFromAttachment(attachment);
      if (!file) {
        message.error('pdfファイルの取得に失敗しました。');
        return;
      }

      setPreviewPdf(attachment);
      setPreviewPdfFile(file);
      return;
    }

    if (!isPreviewableImageMimeType(attachment.type)) {
      await downloadAttachment(attachment);
      return;
    }

    const { ok, url } = await fetchFromAttachment(attachment);
    if (!ok) {
      message.error('画像ファイルの取得に失敗しました。');
      return;
    }

    setPreviewImage(attachment);
    setPreviewImageUrl(url);
  }, []);

  const downloadAttachment = async (attachment) => {
    const ref = firebase.storage().ref(attachment.storagePath);
    const url = await ref.getDownloadURL();
    downloadFromUrl(url, attachment.name);
  };

  if (attachments.length === 0) return <></>;

  return (
    <>
      <List
        itemLayout="horizontal"
        dataSource={attachments}
        renderItem={(attachment) => (
          <List.Item>
            <Attachments>
              <List.Item.Meta
                avatar={<Avatar icon="paper-clip" />}
                title={
                  <DownloadLink onClick={() => onClickItem(attachment)}>
                    {attachment.name || 'file'}
                  </DownloadLink>
                }
                style={{ alignItems: 'center' }}
              />
            </Attachments>
          </List.Item>
        )}
      />
      <PreviewImageModal
        open={previewImage && previewImageUrl}
        onClose={hidePreviewImageModal}
        url={previewImageUrl}
        onDownload={() => downloadAttachment(previewImage)}
      />
      <PreviewPdfModal
        open={previewPdf && previewPdfFile}
        onClose={hidePreviewPdfModal}
        onDownload={() => downloadAttachment(previewPdf)}
        file={previewPdfFile}
      />
    </>
  );
};

const Attachments = styled.div`
  h4 {
    margin: 0;
  }
`;

const DownloadLink = styled.a`
  color: ${color.common.text3};
  text-decoration: underline;
`;
