import { useStore } from 'hooks/useStore';
import { MessageHeaderProps } from './type';
import { StarButton } from 'App/Common/Icons/StarButton';
import { MessageReplyAll } from 'components/icons';
import ExAvatar from '../../../../Common/Avatar';
import { Avatar, Tooltip } from 'antd';
import { IconButton } from 'components/basics';
import { MessageRecipient } from './MessageRecipient';
import moment from 'moment';

const SPMessageHeader: React.FC<MessageHeaderProps> = ({
  message,
  lock,
  startReplyAll,
}) => {
  const store = useStore();
  const user = store.getUserByEmail(message.from.value[0].address);
  const locked = Boolean(lock);
  const isStarred = store.isStarredMessage(message.id);

  const timestamp = message.date
    .format('YYYY年M月D日 HH:mm')
    .replace(`${moment().format('YYYY')}年`, '');

  const isReadOnly = store.me.isReadOnly;

  const showReaders = (readers: { [uid: string]: any }) => {
    const readerUids = Object.keys(readers);
    const avatars = readerUids.slice(0, 2).map((uid, index) => (
      <>
        <ExAvatar
          size="small"
          user={store.getUser(uid)}
          className={index > 0 ? '-ml-1' : ''}
          showNameOnHover={true}
        />
      </>
    ));

    return (
      <>
        {avatars}
        {readerUids.length > 2 && (
          <span className="ml-1 align-sub">{`+${readerUids.length - 2}`}</span>
        )}
      </>
    );
  };

  return (
    <div className="flex items-center justify-between pb-3">
      <div>
        <div className="flex items-center gap-2 pb-0.5">
          {/* <Avatar className='w-8 h-8 flex items-center justify-center' /> */}
          {user ? (
            <ExAvatar size="small" user={user} />
          ) : (
            <Avatar size="small">{message.fromIconName}</Avatar>
          )}
          <p className="mb-0 text-xs text-sumi-600">{timestamp}</p>
        </div>
        <div className="flex items-center gap-1">
          <p className="mb-0 max-w-[190px] overflow-hidden whitespace-nowrap text-xs text-sumi-600">
            {
              <MessageRecipient
                subject={message.subject}
                mailAddresses={{
                  from: message.from.value,
                  to: message.to?.value,
                  cc: message.cc?.value,
                  bcc: message.bcc?.value,
                }}
              />
            }
          </p>
        </div>
      </div>
      <div>
        <div className="flex items-center gap-2">
          <div className="flex -space-x-2 overflow-hidden">
            <div>{showReaders(message.readers)}</div>
          </div>

          <StarButton
            onClick={() => store.toggleStar(message)}
            starred={isStarred}
            disabled={isReadOnly}
          />

          {!locked && (
            <Tooltip title="全員に返信" mouseEnterDelay={0.5}>
              <IconButton
                color="sumi"
                onClick={startReplyAll}
                component={MessageReplyAll}
                disabled={isReadOnly}
              />
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};

export default SPMessageHeader;
