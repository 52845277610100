import * as React from 'react';
import type { SVGProps } from 'react';
const SvgStar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M8.074 18.876 12 16.526l3.925 2.375-1.05-4.45 3.45-3-4.55-.4L12 6.85l-1.775 4.175-4.55.4 3.45 3-1.05 4.45Zm3.925-.6-4.65 2.8a.745.745 0 0 1-.825-.025.739.739 0 0 1-.3-.775l1.225-5.3-4.1-3.575a.671.671 0 0 1-.237-.388.98.98 0 0 1 .012-.412.61.61 0 0 1 .225-.338.8.8 0 0 1 .425-.162L9.2 9.626l2.1-5a.7.7 0 0 1 .3-.338.81.81 0 0 1 .4-.112.81.81 0 0 1 .4.112.7.7 0 0 1 .3.338l2.1 5 5.425.475a.8.8 0 0 1 .425.162.61.61 0 0 1 .225.338.98.98 0 0 1 .013.412.671.671 0 0 1-.238.388l-4.1 3.575 1.225 5.3a.74.74 0 0 1-.3.775.746.746 0 0 1-.825.025l-4.65-2.8Z"
    />
  </svg>
);
export default SvgStar;
