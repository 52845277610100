export const DUPLICATE_EMAIL_ADDRESS_ERROR =
  'Each email address in the personalization block should be unique between to, cc, and bcc.';
export const INVALID_EMAIL_ADDRESS_ERROR = 'Does not contain a valid address.';
export const TOO_LARGE_ERROR = 'Request Entity Too Large';

export type SendMessageError = {
  response?: {
    body?: {
      errors?: {
        message: string;
      }[];
    };
  };
  message?: string;
};

type Props = {
  error: SendMessageError;
};

export const SendMessageErrorContent: React.FC<Props> = ({ error }) => {
  const errors = error?.response?.body?.errors;
  // エラーメッセージがresponse.bodyではなくe.messageから取れる場合がある (e.g. 413 Request Entity Too Large)
  const message = errors ? errors?.[0]?.message : error?.message;
  if (message?.includes(DUPLICATE_EMAIL_ADDRESS_ERROR)) {
    // アドレス重複
    return (
      <p>
        送信先のメールアドレス（ to / cc /
        bcc）で重複するアドレスがないことを確認してください。
      </p>
    );
  } else if (message?.includes(INVALID_EMAIL_ADDRESS_ERROR)) {
    // 不正なアドレス
    return (
      <>
        <p>
          送信先のメールアドレスが正しく入力されていることを確認してください。
        </p>
        <p>よくある例：</p>
        <ul>
          <li>メールアドレスの前後に余計なスペースが入っている</li>
          <li>複数のメールアドレスが繋がってしまっている</li>
          <li>メールアドレスで使用してはいけない文字が含まれている</li>
        </ul>
      </>
    );
  } else if (message?.includes(TOO_LARGE_ERROR)) {
    // サイズ超過
    return (
      <>
        <p>
          メールのサイズが大きすぎます。
          <br />
          添付ファイルのサイズを小さくして再度送信してください。
        </p>
      </>
    );
  } else {
    // その他のエラー
    return (
      <>
        <p>
          SMTP連携を実施している場合、SMTPサーバー側の不具合か国外IPアドレス制限が原因で送信に失敗している可能性がございます。
          <br />
          改めて設定画面をご確認いただけると幸いです。
        </p>
        <p>
          上記に当てはまらない場合は、弊社にて調査させていただきますので、
          <br />
          右上のチャット画面「不具合」よりお問い合わせいただけると幸いです。
        </p>
        <pre className="max-h-[10rem] overflow-auto rounded bg-gray-100 p-2">
          {JSON.stringify(error)}
        </pre>
      </>
    );
  }
};
