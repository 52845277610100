import * as React from 'react';
import type { SVGProps } from 'react';
const SvgCalendar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M5.1 21.75c-.516 0-.957-.184-1.325-.552a1.805 1.805 0 0 1-.55-1.324v-13.8c0-.515.183-.957.55-1.324.368-.367.809-.55 1.325-.55h1.125v-2H8.1v2h7.8v-2h1.875v2H18.9c.515 0 .957.183 1.324.55.367.367.55.809.55 1.324v13.8c0 .516-.183.957-.55 1.324-.367.368-.809.551-1.324.551H5.1Zm0-1.876h13.8V10.05H5.1v9.825Zm0-11.7h13.8v-2.1H5.1v2.1Zm6.9 5.8a.947.947 0 0 1-.694-.28.94.94 0 0 1-.281-.694c0-.275.093-.507.28-.694a.94.94 0 0 1 .693-.282c.276 0 .508.094.695.28a.94.94 0 0 1 .282.694.947.947 0 0 1-.28.695.94.94 0 0 1-.694.281Zm-4 0a.947.947 0 0 1-.694-.28.94.94 0 0 1-.281-.694c0-.275.093-.507.28-.694a.94.94 0 0 1 .693-.282c.276 0 .508.094.695.28a.94.94 0 0 1 .282.694.947.947 0 0 1-.28.695.94.94 0 0 1-.694.281Zm8 0a.947.947 0 0 1-.694-.28.94.94 0 0 1-.281-.694c0-.275.093-.507.28-.694a.94.94 0 0 1 .693-.282c.276 0 .508.094.695.28a.94.94 0 0 1 .282.694.947.947 0 0 1-.28.695.94.94 0 0 1-.694.281Zm-4 4a.947.947 0 0 1-.694-.28.94.94 0 0 1-.281-.694c0-.275.093-.507.28-.695a.94.94 0 0 1 .693-.28c.276 0 .508.093.695.28a.94.94 0 0 1 .282.693.947.947 0 0 1-.28.695.94.94 0 0 1-.694.281Zm-4 0a.947.947 0 0 1-.694-.28.94.94 0 0 1-.281-.694c0-.275.093-.507.28-.695a.94.94 0 0 1 .693-.28c.276 0 .508.093.695.28a.94.94 0 0 1 .282.693.947.947 0 0 1-.28.695.94.94 0 0 1-.694.281Zm8 0a.947.947 0 0 1-.694-.28.94.94 0 0 1-.281-.694c0-.275.093-.507.28-.695a.94.94 0 0 1 .693-.28c.276 0 .508.093.695.28a.94.94 0 0 1 .282.693.947.947 0 0 1-.28.695.94.94 0 0 1-.694.281Z"
    />
  </svg>
);
export default SvgCalendar;
