import {
  forwardRef,
  MouseEvent as ReactMouseEvent,
  MouseEventHandler,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import FocusTrap from 'focus-trap-react';
import { v4 } from 'uuid';

export type DropdownPlacement = 'bottomLeft' | 'bottomRight';

type Props = {
  id?: string;
  overlay: ReactNode;
  placement?: DropdownPlacement;
  onVisibleChange?: (visible: boolean) => void;
  onClick?: MouseEventHandler<HTMLDivElement>;
  // Close Dropdown if return value is true
  onClickOutside?: (event: MouseEvent) => boolean;
  onBlur?: () => void;
  className?: string;
  width?: number;
  offsetX?: number;
  offsetY?: number;
};

export type DropdownHandler = {
  open: () => void;
  close: () => void;
  width?: number;
  height?: number;
};

export const Dropdown = forwardRef<DropdownHandler, PropsWithChildren<Props>>(
  (
    {
      id,
      overlay,
      placement = 'bottomRight',
      onVisibleChange,
      onClick,
      onClickOutside,
      onBlur,
      children,
      className = '',
      width,
      offsetX = 0,
      offsetY = 0,
    },
    ref
  ) => {
    const [visible, setVisible] = useState(false);

    const overlayId = useMemo(() => '_' + v4().replace(/-/g, ''), []);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const overlayRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      onVisibleChange?.(visible);
    }, [visible]);

    useEffect(() => {
      if (!visible) {
        return;
      }
      const handleClick = (e: MouseEvent) => {
        const elem = overlayRef.current;
        if (!elem) {
          return;
        }
        const node = e.target as HTMLElement;
        if (
          node.closest(`#${overlayId}`) == null &&
          (!onClickOutside || onClickOutside(e))
        ) {
          close();
        }
      };
      document.addEventListener('click', handleClick, true);
      return () => document.removeEventListener('click', handleClick, true);
    }, [visible, overlayRef.current]);

    const open = () => {
      const d = dropdownRef.current;
      if (!d) {
        return;
      }
      const o = overlayRef.current;
      if (!o) {
        return;
      }

      o.style.top = `${d.offsetHeight + offsetY}px`;
      switch (placement) {
        case 'bottomLeft':
          o.style.right = `${-offsetX}px`;
          break;
        case 'bottomRight':
          o.style.left = `${offsetX}px`;
          break;
      }
      setVisible(true);
    };

    const close = () => setVisible(false);

    const onClickButton = (e: ReactMouseEvent<HTMLDivElement>) => {
      if (!visible) {
        open();
      }
      onClick?.(e);
    };

    useImperativeHandle(ref, () => ({
      open,
      close,
      width: dropdownRef.current?.offsetWidth,
      height: dropdownRef.current?.offsetHeight,
    }));

    return (
      <div className={'relative ' + className} ref={dropdownRef}>
        <div
          id={id}
          tabIndex={0}
          role="button"
          onClick={onClickButton}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              e.currentTarget.click();
            }
          }}
          onBlur={() => onBlur?.()}
          className="h-full w-full cursor-pointer"
        >
          {children}
        </div>
        <FocusTrap
          active={visible}
          focusTrapOptions={{
            allowOutsideClick: true,
            fallbackFocus: overlayRef.current ?? undefined,
          }}
        >
          <div
            id={overlayId}
            className={`absolute z-50 rounded-lg bg-white shadow-dropdown ${
              visible ? '' : 'hidden'
            }`}
            ref={overlayRef}
            style={{
              minWidth: 'fit-content',
              width: width ? width : 'auto',
            }}
          >
            {overlay}
          </div>
        </FocusTrap>
      </div>
    );
  }
);
