import React from 'react';
import { isSP } from 'shared/util';
import { Modal } from 'antd';
import { Button } from 'components/basics';
import SPCreateMesssageToolModal from '../SPCreateMesssageToolModal';

type Props = {
  templateVisible: boolean;
  hideTemplateModal: () => void;
  showTemplateModal: () => void;
  children: React.ReactNode;
};

export const TemplateModal: React.FC<Props> = ({
  templateVisible,
  hideTemplateModal,
  showTemplateModal,
  children,
}) => {
  if (isSP()) {
    return (
      <SPCreateMesssageToolModal
        title="テンプレートから作成"
        onCancel={hideTemplateModal}
        isModalOpen={templateVisible}
        footerElement={
          <Button className="w-full" onClick={showTemplateModal}>
            生成
          </Button>
        }
      >
        {children}
      </SPCreateMesssageToolModal>
    );
  }

  return (
    <Modal
      title="テンプレート"
      visible={templateVisible}
      onCancel={hideTemplateModal}
      cancelText="キャンセル"
      width="70%"
      okButtonProps={{ style: { display: 'none' } }}
    >
      {children}
    </Modal>
  );
};
