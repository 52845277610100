export const common = {
  white: '#ffffff',
  text1: '#A7A7AA',
  text2: '#44425E',
  text3: '#808495',
  success: '#5FE2A0',
  danger: '#ff6565',
  selected: '#F0F0F1',
  hover: '#f6f6f7',
  link: '',
};

export const sidebar = {
  background: {
    normal: '#F8F8FB',
    selected: '#E4E4E5',
    // selected: "#e9e9f0",
    // selected: "#f5f6fa",
    hover: '#E4E4E5',
    // hover: "#f7f8fc",
  },
};

export const icon = {
  border: {
    normal: '#E8E9EC',
  },
};

export const input = {
  border: {
    focus: '#3B86FF',
  },
  text: {
    placeholder: '#BCBCCB',
  },
};

export const a = {
  text: {
    normal: '#43425D',
  },
};

export const button = {
  background: {
    default: '#44435e',
    primary: '#3B86FF',
    danger: '#ff6565',
    disable: '#E8E9EC',
  },
  border: {
    default: '#44435e',
    primary: '#3B86FF',
    danger: '#ff6565',
  },
};

/**
 * TagColorの対応表
 * @type {Object.<TagColor, string>}
 */
const tagColorsAndHexColorsCombination = {
  magenta: '#eb2f96',
  red: '#f5222d',
  volcano: '#fa541c',
  orange: '#fa8c16',
  gold: '#faad14',
  lime: '#a0d911',
  green: '#52c41a',
  cyan: '#13c2c2',
  blue: '#1890ff',
  geekblue: '#2f54eb',
  purple: '#722ed1',
};

/**
 * TagColorをCSS形式のカラーコードに変換する
 * @param tagColor {TagColor}
 * @return {string}
 */
export const convertTagColorToHexColor = (tagColor) =>
  tagColorsAndHexColorsCombination[tagColor];
