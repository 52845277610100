import React from 'react';

export const shouldSubmit = (
  e: KeyboardEvent | React.KeyboardEvent
): boolean => {
  const userAgent = window.navigator.userAgent.toLowerCase();

  let ctrlPressed: boolean;
  if (userAgent.includes('windows nt')) {
    ctrlPressed = e.ctrlKey;
  } else if (userAgent.includes('mac os x')) {
    ctrlPressed = e.metaKey;
  } else {
    ctrlPressed = e.ctrlKey || e.metaKey;
  }

  return e.key === 'Enter' && ctrlPressed;
};
