const level = [
  '10px', // 0
  '12px', // 1
  '14px', // 2
  '16px', // 3
  '18px', // 4
  '20px', // 5
  '22px', // 6
  '24px', // 7
  '26px', // 8
  '28px', // 9
];
export default level;
