import * as React from 'react';
import type { SVGProps } from 'react';
const SvgFormatListNumbered = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M4.096 21.5a.579.579 0 0 1-.596-.596.58.58 0 0 1 .596-.596H6V19.25h-.904a.579.579 0 0 1-.596-.596.58.58 0 0 1 .596-.596H6V17H4.096a.579.579 0 0 1-.596-.596.58.58 0 0 1 .596-.596h2.25c.24 0 .44.08.603.243a.819.819 0 0 1 .243.603v1.192c0 .24-.08.44-.243.603a.818.818 0 0 1-.603.243c.24 0 .44.081.603.244a.819.819 0 0 1 .243.602v1.116c0 .24-.08.44-.243.603a.819.819 0 0 1-.603.243h-2.25Zm.087-6.654a.66.66 0 0 1-.485-.198.659.659 0 0 1-.198-.484V12.25c0-.24.081-.44.243-.603a.819.819 0 0 1 .603-.243H6v-1.058H4.096a.579.579 0 0 1-.596-.595.58.58 0 0 1 .596-.597h2.25c.24 0 .44.08.603.243a.82.82 0 0 1 .243.603v1.75a.82.82 0 0 1-.243.603.818.818 0 0 1-.603.243H4.692v1.058h1.904a.579.579 0 0 1 .596.595.58.58 0 0 1-.596.597H4.183Zm1.414-6.654A.58.58 0 0 1 5 7.596V3.692h-.904a.579.579 0 0 1-.596-.595.58.58 0 0 1 .596-.597H5.52c.191 0 .351.064.48.193a.651.651 0 0 1 .193.48v4.423a.58.58 0 0 1-.595.596Zm4.46 10.558a.726.726 0 0 1-.534-.216.727.727 0 0 1-.215-.534c0-.213.072-.391.215-.535a.726.726 0 0 1 .535-.215h9.692c.212 0 .39.072.534.216A.726.726 0 0 1 20.5 18c0 .213-.072.391-.216.535a.726.726 0 0 1-.534.215h-9.692Zm0-6a.726.726 0 0 1-.534-.216.727.727 0 0 1-.215-.534c0-.213.072-.391.215-.534a.726.726 0 0 1 .535-.216h9.692c.212 0 .39.072.534.216A.726.726 0 0 1 20.5 12c0 .213-.072.391-.216.534a.726.726 0 0 1-.534.216h-9.692Zm0-6a.726.726 0 0 1-.534-.216A.726.726 0 0 1 9.308 6c0-.213.072-.391.215-.535a.726.726 0 0 1 .535-.215h9.692c.212 0 .39.072.534.216A.726.726 0 0 1 20.5 6c0 .213-.072.391-.216.535a.726.726 0 0 1-.534.215h-9.692Z"
    />
  </svg>
);
export default SvgFormatListNumbered;
