import { Dropdown, Menu } from 'antd';
import { ScheduleSubMenu } from './ScheduleSubMenu';
import {
  intersectingAddresses,
  MessageStatus,
  normalizeAddress,
  unsubscribeConverter,
} from 'lib';
import { Button, Icon } from 'components/basics';
import { CaretDown } from 'components/icons';
import { useStore } from 'hooks/useStore';
import { FollowUpSubMenu } from './FollowUpSubMenu';
import { useToast } from '../../../hooks/useToast';
import { useAtomValue } from 'jotai';
import { companyCollection } from '../../../firestore';
import { observer } from 'mobx-react';
import { getDocs, limit, query, where } from 'firebase/firestore';
import { Moment } from 'moment';
import { useConfirmDialog } from '../../../hooks/confirmDialog';
import { statusesAtomFamily } from 'atoms/firestore/customStatuses';
import {
  buildFrontMessageStatus,
  FrontMessageStatus,
  getNextStatus,
  hasCustomStatus,
  statusesOptionsWhenCustomStatusedEnabled,
  UnknownFrontMessageStatus,
} from 'utils/frontMessageStatus';
import { Draft } from '../../../firestore/entity/draft';

type DatePickerTarget = 'schedule' | 'followup';

export type Props = {
  draft: Draft;
  disabled: boolean;
  handleSubmit: (
    nextStatus: FrontMessageStatus | UnknownFrontMessageStatus | null
  ) => void;
  handleDatePicker: (date: moment.Moment, ignoreUnsubscribes: boolean) => void;

  openDatePicker: (
    target: DatePickerTarget,
    ignoreUnsubscribes: boolean
  ) => void;
  isScheduledDraftSupported: boolean;
  isFollowUpMessageSupported: boolean;
};

const CreateMessageSubmitButton = observer(
  ({
    draft,
    disabled,
    handleSubmit,
    handleDatePicker,
    openDatePicker,
    isScheduledDraftSupported,
    isFollowUpMessageSupported,
  }: Props) => {
    const { showToast } = useToast();
    const store = useStore();
    const openDialog = useConfirmDialog();
    const getUnsubscribedAddresses = async (inboxEmail: string) => {
      const collection = companyCollection(
        'unsubscribes',
        unsubscribeConverter
      );
      const q = query(
        collection,
        where('email', '==', normalizeAddress(inboxEmail)),
        limit(1)
      );
      return await getDocs(q).then(
        (snap) => snap.docs.at(0)?.data().targets ?? []
      );
    };
    const checkUnsubscribes = async (
      buttonLabel: string | null,
      callback: (force: boolean) => void
    ) => {
      const inbox = store.getInbox(draft.inboxId);
      if (!inbox) {
        showToast('error', 'エラーが発生しました');
        console.error(
          'インボックスが見つかりませんでした',
          `inboxId=${draft.inboxId}`
        );
        return;
      }

      if (!inbox.isOneClickUnsubscribeEnabled) {
        callback(false);
        return;
      }

      const unsubscribedAddresses = intersectingAddresses(
        [...draft.to, ...draft.cc, ...draft.bcc],
        await getUnsubscribedAddresses(inbox.email)
      );
      if (unsubscribedAddresses.length === 0) {
        callback(false);
        return;
      }

      openDialog({
        title: 'メールを送信しますか？',
        description: (
          <div>
            <p>
              メールを送信しようとしている宛先は、このアドレスからの配信を停止にしています。
            </p>
            <p>強制的に送信しますか？</p>
            <div>
              <div>配信停止している宛先：</div>
              <ul className="list-outside list-disc pl-6">
                {unsubscribedAddresses.map((addr) => (
                  <li key={addr}>{addr}</li>
                ))}
              </ul>
            </div>
          </div>
        ),
        okText: buttonLabel ?? 'メールを送信',
        onOk: () => callback(true),
      });
    };
    const onSubmit: Props['handleSubmit'] = async (status) => {
      await checkUnsubscribes(null, () => handleSubmit(status));
    };
    const onSchedule = async (date: Moment) => {
      await checkUnsubscribes('送信予約する', (force) =>
        handleDatePicker(date, force)
      );
    };
    const onOpenDatePicker = async (target: DatePickerTarget) => {
      await checkUnsubscribes('日時を選択する', (force) =>
        openDatePicker(target, force)
      );
    };

    const [, customStatuses] = useAtomValue(statusesAtomFamily(draft.teamId));
    const messageStatus = buildFrontMessageStatus(draft.status, customStatuses);

    if (draft.isReply) {
      let sendSubMenu = null;

      if (hasCustomStatus(customStatuses)) {
        sendSubMenu = (
          <Menu.SubMenu title="送信">
            {statusesOptionsWhenCustomStatusedEnabled(customStatuses).map(
              ({ label, value }) => (
                <Menu.Item
                  key={value}
                  onClick={() =>
                    onSubmit(buildFrontMessageStatus(value, customStatuses))
                  }
                >
                  送信（{label}）
                </Menu.Item>
              )
            )}
          </Menu.SubMenu>
        );
      }

      const nextStatus = getNextStatus(messageStatus, customStatuses);

      return (
        <div className="flex items-center">
          <Button
            color="primary"
            variant="contained"
            disabled={disabled}
            className="h-10 rounded-l py-2 pl-3 pr-2 font-bold"
            onClick={() => onSubmit(nextStatus)}
          >
            送信
            {nextStatus.statusType !== MessageStatus.None &&
              `(${nextStatus.statusName})`}
          </Button>
          <div className="relative z-10 -ml-px h-7 w-px bg-white"></div>
          <Dropdown
            disabled={disabled}
            overlay={
              <Menu>
                {sendSubMenu || (
                  <Menu.Item onClick={() => onSubmit(null)} key="1">
                    送信
                  </Menu.Item>
                )}
                <ScheduleSubMenu
                  handleSchedule={onSchedule}
                  openSchedulePicker={() => onOpenDatePicker('schedule')}
                  supported={isScheduledDraftSupported}
                />
                {isFollowUpMessageSupported && (
                  <FollowUpSubMenu
                    openDatePicker={() => onOpenDatePicker('followup')}
                    supported={isFollowUpMessageSupported}
                  />
                )}
              </Menu>
            }
          >
            <Button
              color="primary"
              variant="contained"
              disabled={disabled}
              className="flex items-center rounded-l-none px-2"
            >
              <Icon icon={CaretDown} size={20} />
            </Button>
          </Dropdown>
        </div>
      );
    }

    return (
      <div className="flex items-center">
        <Button
          color="primary"
          variant="contained"
          disabled={disabled}
          className="rounded-r-none"
          onClick={() => onSubmit(null)}
        >
          送信
        </Button>
        <div className="relative z-10 -ml-px h-7 w-px bg-white"></div>
        <Dropdown
          disabled={disabled}
          overlay={
            <Menu>
              <ScheduleSubMenu
                handleSchedule={onSchedule}
                openSchedulePicker={() => onOpenDatePicker('schedule')}
                supported={isScheduledDraftSupported}
              />
              {isFollowUpMessageSupported && (
                <FollowUpSubMenu
                  openDatePicker={() => onOpenDatePicker('followup')}
                  supported={isFollowUpMessageSupported}
                />
              )}
            </Menu>
          }
        >
          <Button
            color="primary"
            variant="contained"
            disabled={disabled}
            className="flex items-center rounded-l-none px-2"
          >
            <Icon icon={CaretDown} size={20} />
          </Button>
        </Dropdown>
      </div>
    );
  }
);

export default CreateMessageSubmitButton;
