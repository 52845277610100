import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import level from '../../../font/level';
import { Icon, Tooltip } from 'antd';
import media from 'styled-media-query';

class Event extends Component {
  render() {
    const { event } = this.props;

    switch (event.type) {
      case 'assignee:update':
      case 'status:update:backlog':
      case 'status:update:processed':
      case 'tags:add':
      case 'tags:remove':
      case 'reminder:update': //指定したタイプのイベントのみ表示する
        return (
          <Wrapper>
            <div className="message">
              <div className="icon-wrapper">
                <Icon type="bell"></Icon>
              </div>
              <div className="text">{event.text}</div>
            </div>
            <div className="time-wrapper">
              <Tooltip title={event.date.format('YYYY年M月D日 HH:mm')}>
                <CommentedAt>{event.date.fromNow()}</CommentedAt>
              </Tooltip>
            </div>
          </Wrapper>
        );
      default:
        return <></>; // 指定したタイプでないイベントは表示しない
    }
  }
}

export default compose(withRouter, inject('store'), observer)(Event);

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 8px 10px;
  ${media.greaterThan('small')`
    margin: 16px 10px;
    padding: 0 6px;
  `}

  & > .message {
    display: flex;
    align-items: center;
    & > .icon-wrapper {
      color: rgba(0, 0, 0, 0.5);
      font-size: 14px;
      margin-right: 6px;
    }

    & > .text {
      color: rgba(0, 0, 0, 0.65);
    }
  }

  & > .time-wrapper {
    flex-shrink: 0;
  }
`;

const CommentedAt = styled.span`
  font-size: ${level[0]};
  color: #8d8d8d;
`;
