import { message as antdMessage } from 'antd';
import { useCallback } from 'react';

export type ToastType = 'success' | 'error';

const toastFunctions: {
  [K in ToastType]: (message: string) => void;
} = {
  success: antdMessage.success,
  error: antdMessage.error,
};

export const useToast = () => {
  const showToast = useCallback((type: ToastType, message: string): void => {
    const showToastFunction = toastFunctions[type];
    showToastFunction ? showToastFunction(message) : antdMessage.info(message);
  }, []);

  return {
    showToast,
  };
};
