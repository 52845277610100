import { HTMLProps } from 'react';
import styled from 'styled-components';
import * as color from '../../../color';

// FIXME: HTMLButtonElementとかぶるtypeを使っているので無理やりキャストしている
export const DefaultButton = styled.button`
  display: block;
  color: white;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  outline: none;

  :hover {
    opacity: 0.8;
  }

  ${({ type }) => {
    switch (type as 'primary' | 'danger') {
      case 'primary':
        return `
      background-color: ${color.button.background.primary};
      border-color: ${color.button.border.primary};
      `;
      case 'danger':
        return `
      background-color: ${color.common.danger};
      border-color: ${color.common.danger};
      `;
      default:
        return `
      background-color: ${color.button.background.default};
      border-color: ${color.button.border.default};
      `;
    }
  }}

  ${({ disabled }) =>
    disabled &&
    `
      background: ${color.button.background.disable};
      border: none;
      pointer-events: none;
  `}
` as React.FC<HTMLProps<HTMLButtonElement> & { type?: 'primary' | 'danger' }>;
