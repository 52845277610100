import moment from 'moment';
import { estimateServerTimestampsOption } from './common';
import firebase from 'firebase/compat/app';

export interface EventData {
  user: string;
  name: string;
  type: string;
  text: string;
  createdAt: firebase.firestore.Timestamp;
}

export class Event {
  private _id: string;
  private _data: EventData;
  private _date: moment.Moment;
  private _ref: firebase.firestore.DocumentReference<EventData>;

  constructor(doc: firebase.firestore.DocumentSnapshot<EventData>) {
    this._id = doc.id;
    const data = doc.data(estimateServerTimestampsOption);
    if (!data) {
      throw new Error(`no data for event#${doc.id}`);
    }
    this._data = data;
    this._date = moment(this._data.createdAt.toMillis()); // 毎回生成するのはコストがかかるため
    this._ref = doc.ref;
  }

  get id() {
    return this._id;
  }

  get date() {
    return this._date;
  }

  get ref() {
    return this._ref;
  }

  get user() {
    return this._data.user;
  }

  get name() {
    return this._data.name;
  }

  get iconName() {
    if (this.name.length === 0) return '';
    return this.name.length > 1
      ? (this.name[0] + this.name[1]).toUpperCase()
      : this.name[0];
  }

  get type() {
    return this._data.type;
  }

  get text() {
    return this._data.text;
  }

  get createdAt() {
    return this._data.createdAt;
  }
}
