import firebase from '../firebase';
import { eventNames, logEvent } from '../analytics';
import lStorage, { storageKeys } from '../localStorage';
import ChannelService from '../service/channel';

export const useLogout = (): (() => Promise<void>) => {
  return async () => {
    await firebase
      .auth()
      .signOut()
      .then(() => {
        logEvent(eventNames.logout);
        lStorage.removeString(storageKeys.lastVisitedUrl);
        // channelからログアウト
        ChannelService.shutdown();
        // ログイン画面へ移動する
        window.location.href = '/signIn';
      })
      .catch((error) => {
        console.error('ログアウト時にエラーが発生しました', error);
      });
  };
};
