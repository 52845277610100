import { makeObservable, observable } from 'mobx';

import { FieldValue } from '../firebase';

export class PrivateStore {
  constructor(rootStore) {
    this.rootStore = rootStore;

    makeObservable(this, {
      starredThreads: observable,
    });
  }

  unsubscribeSyncs() {
    this._unsubscribeSyncStars?.();
  }

  /**
   * @returns {CollectionReference}
   */
  collection() {
    return this.rootStore.companyCollection(
      `users/${this.rootStore.me.id}/private`
    );
  }

  toggleThreadView = async (threadView) => {
    await this.collection().doc('preferences').set({
      threadView,
      updatedAt: FieldValue.serverTimestamp(),
    });
    document.location.reload();
  };

  /* stars */
  starredThreads = {};

  get starredThreadIdsOrderByDate() {
    return Object.entries(this.starredThreads)
      .filter(([threadId, data]) =>
        this.rootStore.joinedTeamIds.includes(data.teamId)
      )
      .sort(([id1, data1], [id2, data2]) => data2.date - data1.date)
      .map(([id]) => id);
  }

  isStarredThread(threadId) {
    return !!this.starredThreads[threadId];
  }

  syncStars() {
    this._unsubscribeSyncStars?.();

    this._unsubscribeSyncStars = this.collection()
      .doc('starredThreads')
      .onSnapshot(
        (doc) => {
          if (doc.exists) {
            this.starredThreads = doc.data();
          }
        },
        (err) => console.error('PrivateStore.syncStars:', err)
      );
  }

  toggleThreadStar = async (thread) => {
    let isStarred = this.isStarredThread(thread.id);
    await this.collection()
      .doc('starredThreads')
      .set(
        {
          [thread.id]: isStarred
            ? FieldValue.delete()
            : {
                date: thread.data.date,
                teamId: thread.teamId,
              },
          updatedAt: FieldValue.serverTimestamp(),
        },
        { merge: true }
      );
    if (isStarred) {
      let index = this.rootStore.starredMessages.findIndex(
        (m) => m.id === thread.id
      );
      if (index !== -1) {
        this.rootStore.starredMessages.splice(index, 1);
      }
    }
  };

  starThread = async (teamId, threadId, date) => {
    if (this.isStarredThread(threadId)) {
      return;
    }
    await this.collection()
      .doc('starredThreads')
      .set(
        {
          [threadId]: {
            date,
            teamId,
          },
          updatedAt: FieldValue.serverTimestamp(),
        },
        { merge: true }
      );
  };
}
