import { CheckCircleFilled } from '@ant-design/icons';
import styled from 'styled-components';

export const PlanFeatures: React.FC<{
  items: PlanFeatureItemProps[];
}> = ({ items }) => {
  return (
    <>
      {items.map((item, i) => (
        <PlanFeatureItem {...item} key={`feature${i}`} />
      ))}
    </>
  );
};

// PlanFeatureItem
export type PlanFeatureItemProps = {
  text: string;
  bold?: boolean;
};

const PlanFeatureItem: React.FC<PlanFeatureItemProps> = ({ text, bold }) => {
  return (
    <PlanFeatureItemWrapper className={bold ? 'bold' : ''}>
      <CheckCircleFilled className="icon" />
      {text}
    </PlanFeatureItemWrapper>
  );
};

const PlanFeatureItemWrapper = styled.div`
  &.bold {
    font-weight: 600;
  }
  & > .icon {
    color: #6beba0;
    margin-right: 6px;
  }
  font-size: 14px;
`;
