import { Timestamp } from 'firebase/firestore';
import { createConverter, createEntityFactory } from '../converter';
import { toRecipients } from 'lib';

export type InboxType = 'email' | 'google' | 'outlook';

export type InboxAutoReply = {
  enabled: boolean;
  allDay: boolean;
  startTime: string | null;
  endTime: string | null;
  messageBody: string;
};

export type InboxData = {
  teamId: string;
  name: string;
  yaritoriName?: string;
  email: string;
  forwardTo: string;
  slackIntegrationChannel?: string;
  chatworkIntegrationRoomId?: number;
  autoCc: string;
  autoBcc: string;
  defaultSignatureId: string | null;
  smtp?: boolean;
  autoReply?: InboxAutoReply;
  type: InboxType;
  tokenId?: string;
  error?: string;
  isOneClickUnsubscribeEnabled?: boolean;
  deleted?: boolean;
  createdAt: Timestamp;
  updatedAt: Timestamp;
  syncedAt?: Timestamp;
};

export const createInbox = createEntityFactory('Inbox', (data: InboxData) => ({
  get name() {
    return data.name || '';
  },

  get yaritoriName() {
    return data.yaritoriName || '';
  },

  get email() {
    return data.email;
  },

  get emailWithAlias() {
    return this.name.length > 0 ? `${this.name} <${this.email}>` : this.email;
  },

  get autoCc() {
    return data.autoCc || '';
  },

  get autoCcs() {
    return toRecipients(this.autoCc);
  },

  get autoBcc() {
    return data.autoBcc || '';
  },

  get autoBccs() {
    return toRecipients(this.autoBcc);
  },

  get autoRecipients() {
    return [...this.autoCcs, ...this.autoBccs];
  },

  get defaultSignatureId() {
    return data.defaultSignatureId || null;
  },

  get smtp() {
    return data.smtp === true;
  },

  get emailIconName() {
    return this.email.length > 0 ? this.email[0].toUpperCase() : '';
  },

  get isOneClickUnsubscribeEnabled() {
    return data.isOneClickUnsubscribeEnabled === true;
  },
}));

export type Inbox = ReturnType<typeof createInbox>;

export const inboxConverter = createConverter(createInbox);
