import { ComponentPropsWithoutRef, forwardRef } from 'react';
import { Icon } from '../Icon';
import { Check } from '../../icons';
import { twMerge } from 'tailwind-merge';

type Props = ComponentPropsWithoutRef<'div'>;

export const Checkmark = forwardRef<HTMLDivElement, Props>(
  ({ children, className, ...props }, ref) => {
    return (
      <div
        className={twMerge(
          'grid grid-cols-[auto_1fr] items-center gap-4 rounded p-2 text-sm leading-6',
          className
        )}
        {...props}
        ref={ref}
      >
        <div className="h-full">
          <Icon icon={Check} size={24} className="block text-sea-500" />
        </div>
        {children}
      </div>
    );
  }
);

Checkmark.displayName = 'Check';
