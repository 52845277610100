import { useState, useEffect } from 'react';
import { db } from '../../../../firebase';
import { useStore } from 'hooks/useStore';
import {
  createPortalSession,
  fetchSubscription,
  fetchSubscriptionItem,
  updateSubscriptionOptions,
  updateSubscriptionProduct,
} from '../../../../functions';
import { message as toast } from 'antd';
import { List } from '../../common';
import { Product } from '../Product';
import { PlanCard } from './PlanCard';
import { StripeProductOptionDialog } from './StripeProductOptionDialog';
import { OpenInNewIcon } from 'components/icons';
import {
  StripeProductOption,
  StripeCard,
  ProductOption,
  StripePaymentMethod,
  StripeProductOptionValue,
  StripeProductOptionType,
} from './types';
import moment from 'moment';
import { Button, Loading } from 'components/basics';

/**
 * バージョン６またはそれ以上
 */
export const MIN_UI_VERSION = 6;

const formatFileSize = (size: number) => {
  const gb = size / 1024 / 1024 / 1024;
  return `${gb.toFixed(2)} GB`;
};

export const getOptions = async (
  interval: 'month' | 'year'
): Promise<StripeProductOption[]> => {
  const snapshot = await db
    .collection('stripe')
    .doc('collections')
    .collection('products')
    .where('active', '==', true)
    .where('metadata.itemType', '==', 'option')
    .get();

  return Promise.all(
    snapshot.docs.map<Promise<StripeProductOption>>(async (doc) => {
      const priceSnapshot = await doc.ref
        .collection('prices')
        .where('active', '==', true)
        .where('interval', '==', interval)
        .get();

      let price = 0;
      let priceId = null;
      if (priceSnapshot.docs.length > 0) {
        const priceData = priceSnapshot.docs[0].data();

        price = priceData?.unit_amount || 0;
        priceId = priceSnapshot.docs[0].id;
      }

      return {
        id: doc.id,
        name: doc.data().name,
        optionType: doc.data().metadata?.optionType,
        price,
        priceId,
        interval,
      };
    })
  );
};

export const getProducts = async (): Promise<Product[]> => {
  const snapshot = await db
    .collection('stripe')
    .doc('collections')
    .collection('products')
    .where('active', '==', true)
    .get();

  return (
    snapshot.docs
      // FIXME: any
      .map((doc) => new Product({ id: doc.id, ...doc.data() } as any))
      .sort((a, b) => a.order - b.order)
  );
};

/**
 * MinV6PaymentのPaymentコンポーネント
 * V6またはそれ以上のバージョンの場合はMinV6Payment配下のコンポーネントを利用する。
 */
export const Payment = () => {
  const store = useStore();
  const [loading, setLoading] = useState(true);
  const [currentProduct, setCurrentProduct] = useState<
    Product | null | undefined
  >(null);
  const [updating, setUpdating] = useState(false);
  const [customerPortalLoading, setCustomerPortalLoading] = useState(false);
  const [currentPeriodEnd, setCurrentPeriodEnd] = useState<number | null>(null);
  const [products, setProducts] = useState<Product[]>([]);
  const [paymentMethod, setPaymentMethod] =
    useState<StripePaymentMethod | null>(null);

  const [stripeCard, setStripeCard] = useState<StripeCard | null>(null);
  const [stripeProductOptions, setStripeProductOptions] = useState<
    StripeProductOption[]
  >([]);
  const [purchasedOptions, setPurchasedOptions] = useState<
    StripeProductOptionValue[]
  >([]);
  const [openChange, setOpenChange] = useState<boolean>(false);

  const loadSubscription = async () => {
    const [subscription, resp, ps] = await Promise.all([
      fetchSubscription({
        companyId: store.signInCompany,
      }),
      fetchSubscriptionItem({
        companyId: store.signInCompany,
        itemType: 'plan',
      }).catch((e) => {
        if (e.code !== 'not-found') throw e;
      }),
      getProducts(),
    ]);

    const currentProductId = resp?.data.price.product;
    const interval = resp?.data.price.recurring.interval;
    const current = ps.find(
      (p) => p.id === currentProductId && p.metadata.itemType === 'plan'
    );

    if (current === undefined)
      console.log('current contract is not found on DB');
    setCurrentProduct(current);

    const newProducts = ps
      .filter((p) => p.version === current?.version)
      .map((p) => ({
        ...p,
        badge: p.name === 'ビジネス',
      }));

    setProducts(newProducts);

    const subscriptionItems = subscription.data?.items?.data || [];

    if (interval) {
      const options = await getOptions(interval);
      setStripeProductOptions(options);

      if (subscriptionItems.length > 0) {
        const mappedPurchasedOptions = subscriptionItems
          .filter((item: any) => {
            return options.find(
              (productOption) => productOption.id === item?.plan?.product
            );
          })
          .map((item: any) => ({
            optionId: item?.plan?.product,
            priceId: item?.price?.id,
            checked: true,
            seat: item?.quantity || 0,
          }));

        setPurchasedOptions(mappedPurchasedOptions);
      }
    }

    setPaymentMethod(StripePaymentMethod.invoice);

    setCurrentPeriodEnd(subscription.data?.current_period_end);

    const sourceData = subscription.data?.customer?.sources?.data || [];
    if (sourceData.length > 0 && sourceData[0].object === 'card') {
      setStripeCard({
        last4: sourceData[0].last4,
        exp_month: sourceData[0].exp_month,
        exp_year: sourceData[0].exp_year,
        brand: sourceData[0].brand,
      });
      setPaymentMethod(StripePaymentMethod.card);
    }

    setLoading(false);
  };

  useEffect(() => {
    loadSubscription();
  }, []);

  const redirectToCustomerPortal = async () => {
    setCustomerPortalLoading(true);
    const resp = await createPortalSession({
      companyId: store.signInCompany,
      returnUrl: window.location.href,
    });
    if (!resp?.data?.url) {
      setCustomerPortalLoading(false);
      return;
    }
    window.location.assign(resp.data.url);
  };

  const updateProduct = async (product?: Product | null) => {
    if (!product || updating) {
      return;
    }

    const key = 'updateProduct';
    toast.loading({ content: 'プランを変更中です…', duration: 0, key });
    setUpdating(true);
    await updateSubscriptionProduct({
      companyId: store.signInCompany,
      plan: product.plan,
    });
    toast.success({ content: 'プランを変更しました', key });
    setUpdating(false);
    setCurrentProduct(product);
  };

  const joinableUserCount = store.users.length + store.invitedUsers.length;

  const plans = products.map<ProductOption>((p) => {
    const inboxExceeded =
      p.maxInboxes !== null &&
      (store.inboxesLength ?? 0) > Number(p.maxInboxes);
    const usersExceeded =
      p.maxUsers !== null && (joinableUserCount ?? 0) > Number(p.maxUsers);
    const isCurrent = p.id === currentProduct?.id;

    return {
      product: p,
      exceeds: {
        inbox: inboxExceeded,
        users: usersExceeded,
      },
      isCurrent,
    };
  });

  const onSubmitOption = async (
    newProductOptions: StripeProductOptionValue[]
  ) => {
    const key = 'updateProductOption';
    toast.loading({ content: 'オプションを変更中です…', duration: 0, key });

    setOpenChange(false);

    await updateSubscriptionOptions({
      companyId: store.signInCompany,
      values: newProductOptions.map((productOption) => {
        const productOptionDetail = stripeProductOptions.find(
          (productOptionInner) =>
            productOptionInner.id === productOption.optionId
        );

        let quantity = 0;
        if (productOption.checked) {
          quantity =
            productOptionDetail?.optionType === StripeProductOptionType.seat
              ? productOption?.seat || 0
              : 1;
        }

        return {
          priceId: productOptionDetail?.priceId || null,
          quantity,
        };
      }),
    });

    setTimeout(async () => {
      await loadSubscription();
      toast.success({ content: 'オプションを変更しました', key });
    }, 1000);
  };

  if (loading) {
    return (
      <List>
        <Loading />
      </List>
    );
  }

  return (
    <>
      <List>
        <h1 className="mb-6 text-xl font-bold leading-8">お支払い</h1>
        <h1 className="mb-0 text-lg font-bold">料金</h1>

        <div className="pb-2 pt-4">
          <div className="flex justify-start gap-4">
            {plans.map((plan) => {
              const p = plan.product;
              let tooltip: string | undefined;
              if (plan.exceeds.inbox) {
                tooltip = `このプランで使用できる共有メールアドレス数(${p.maxInboxes}個まで)を超えているため選択できません`;
              } else if (plan.exceeds.users) {
                tooltip = `このプランで使用できるユーザー数(招待中のユーザー含め${p.maxUsers}名まで)を超えているため選択できません`;
              }

              const disabled =
                plan.isCurrent || plan.exceeds.inbox || plan.exceeds.users;

              return (
                <PlanCard
                  key={p.id}
                  product={p}
                  updating={!disabled && updating}
                  disabled={disabled}
                  buttonLabel={
                    plan.isCurrent ? '選択中のプランです' : undefined
                  }
                  version={currentProduct?.version || MIN_UI_VERSION}
                  tooltip={tooltip}
                  onSelect={() => updateProduct(plan.product)}
                ></PlanCard>
              );
            })}
          </div>
        </div>

        <a
          href="https://yaritori.jp/plan/"
          target="_blank"
          rel="noreferrer"
          className="mb-6 flex items-center gap-0.5 text-sm font-bold"
        >
          料金プランの詳細を確認
          <OpenInNewIcon />
        </a>

        <div className="w-full max-w-[752px]">
          <div className="flex items-start gap-2 border-b border-sumi-300 pb-4">
            <p className="w-[136px] shrink-0 text-sm text-sumi-900">
              オプション
            </p>
            <div className="text-base text-sumi-600">
              {purchasedOptions.length === 0 ? (
                <p className="mb-0">現在オプションは購入していません</p>
              ) : (
                <div className="mb-2 flex items-center">
                  <p className="mb-0">
                    {stripeProductOptions
                      .filter((option1) =>
                        purchasedOptions.some(
                          (option2) => option1.id === option2.optionId
                        )
                      )
                      .map((option) => option.name)
                      .join('・')}
                    はオプションでご利用いただけます
                  </p>
                </div>
              )}
              <Button
                onClick={() => setOpenChange(!openChange)}
                className="h-8 py-0 font-bold"
              >
                オプションの購入
              </Button>
            </div>
          </div>

          <div className="flex items-start gap-2 border-b border-sumi-300 py-4">
            <p className="w-[136px] shrink-0 text-sm text-sumi-900">使用容量</p>
            <div>
              <p className="mb-0 text-base text-sumi-900">
                {formatFileSize(store.company?.storageSize || 0)}
              </p>
              <p className="mb-0 text-sumi-600">
                使用容量が10GB(100万通程度)を超えた場合は、追加で10GBごとに月額1,000円が企業アカウントに加算されます。
              </p>
            </div>
          </div>

          <h1 className="my-6 text-lg font-bold text-sumi-900">請求方法</h1>

          <div className="flex items-start gap-2 border-b border-sumi-300 pb-4">
            <p className="w-[136px] shrink-0 text-sm text-sumi-900">
              お支払方法
            </p>
            <div>
              <p className="mb-0 text-base text-sumi-900">
                {paymentMethod === StripePaymentMethod.card &&
                  'クレジットカード決済'}
                {paymentMethod === StripePaymentMethod.invoice && '請求書発行'}
              </p>
              {stripeCard && (
                <p className="mb-0 text-base  text-sumi-900">
                  {stripeCard?.last4} {stripeCard?.brand}{' '}
                  {stripeCard?.exp_month}/{stripeCard?.exp_year}
                </p>
              )}
              {paymentMethod === StripePaymentMethod.card && (
                <p className="mb-2 text-sumi-600">
                  請求書発行をご希望の場合は担当者にお伝えください。
                </p>
              )}
              {customerPortalLoading ? (
                <Loading />
              ) : (
                <Button
                  variant="outlined"
                  onClick={redirectToCustomerPortal}
                  className="h-8 py-0 font-bold"
                >
                  変更
                </Button>
              )}
            </div>
          </div>

          <div className="flex items-start gap-2 border-b border-sumi-300 py-4">
            <p className="w-[136px] shrink-0 text-sm text-sumi-900">
              次の支払発生日
            </p>
            <div>
              {currentPeriodEnd && (
                <p className="mb-0 text-base text-sumi-900">
                  {moment.unix(currentPeriodEnd).format('YYYY年MM月DD日')}
                </p>
              )}
              {customerPortalLoading ? (
                <Loading />
              ) : (
                <Button
                  onClick={redirectToCustomerPortal}
                  variant="text"
                  className="flex h-auto items-center gap-0.5 p-0 font-bold underline sm:p-0"
                >
                  ご請求の詳細
                  <OpenInNewIcon />
                </Button>
              )}
            </div>
          </div>
        </div>

        <StripeProductOptionDialog
          stripeProductOptions={stripeProductOptions}
          openChange={openChange}
          setOpenChange={setOpenChange}
          defaultValues={purchasedOptions}
          onSubmit={onSubmitOption}
        />
      </List>
    </>
  );
};
