import * as React from 'react';
import type { SVGProps } from 'react';
const SvgCaretRight = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M15.075 12c0 .1-.017.191-.05.275a.764.764 0 0 1-.175.25l-4.95 4.95c-.133.133-.312.196-.537.187a.77.77 0 0 1-.538-.212c-.167-.167-.237-.346-.212-.538a.94.94 0 0 1 .237-.512l4.4-4.4-4.4-4.4a.816.816 0 0 1-.225-.538.592.592 0 0 1 .225-.537.55.55 0 0 1 .525-.213c.217.025.4.105.55.238l4.925 4.925a.762.762 0 0 1 .175.25c.033.083.05.175.05.275Z"
    />
  </svg>
);
export default SvgCaretRight;
