import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import level from '../../../font/level';

class Index extends Component {
  render() {
    return <HeaderWrapper>{this.props.children}</HeaderWrapper>;
  }
}

export default compose(withRouter, inject('store'), observer)(Index);

const HeaderWrapper = styled.div`
  word-break: break-word;
  font-size: ${level[3]};
  font-weight: bold;
`;
