import React, { ComponentProps } from 'react';
import { SettingsWysiwygEditor } from '../SettingsWysiwygEditor/SettingsWysiwygEditor';

export const SettingWysiwygEditor = ({
  ...props
}: ComponentProps<typeof SettingsWysiwygEditor>) => {
  return (
    <div data-testid="setting-wysiwyg-editor">
      <SettingsWysiwygEditor {...props} />
    </div>
  );
};
