import {
  EditorHandle,
  WysiwygEditor,
} from '../../../Common/Editor/WysiwygEditor/WysiwygEditor';
import React, { useEffect, useState } from 'react';
import { EditorToolbarWithControls } from '../../../Common/Editor/EditorToolbar/EditorToolbarWithControls';
import SimpleBar from 'simplebar-react';
import { debounce } from 'lodash';
import { reservedWords } from '../../../Common/Editor/util';
import { ReservedWordsModal } from '../../../Common/Editor/ReservedWordsModal/ReservedWordsModal';

type Props = {
  placeholder?: string;
  defaultValue?: string;
  onChange: (plainText: string, html: string) => void;
  disabled?: boolean;
};

const RESERVED_WORDS = [
  {
    name: '送信先の名前',
    description: '送信先のメールアドレスに設定されている名前',
    value: reservedWords.toName,
  },
  {
    name: '送信先のメールアドレス',
    description: '送信先のメールアドレス',
    value: reservedWords.toEmail,
  },
  {
    name: 'ログインユーザの名前',
    description: 'いまログインしているご自身の名前',
    value: reservedWords.meName,
  },
  {
    name: 'ログインユーザのメールアドレス',
    description: 'いまログインしているご自身のメールアドレス',
    value: reservedWords.meEmail,
  },
  {
    name: '返信元の名前',
    description: '返信元のメールアドレスに設定している名前',
    value: reservedWords.fromName,
  },
  {
    name: '返信元のメールアドレス',
    description: '返信元のメールアドレス',
    value: reservedWords.fromEmail,
  },
] as const;

export const SettingsWysiwygEditor = ({
  placeholder,
  defaultValue,
  onChange,
  disabled,
}: Props) => {
  const [handle, setHandle] = useState<EditorHandle | undefined>();
  const [showReservedWords, setShowReservedWords] = useState(false);
  useEffect(() => {
    const editor = handle?.editor;
    if (!editor) {
      return;
    }
    const onUpdate = debounce(
      () => {
        onChange(handle.getText(), handle.getHtml());
      },
      200,
      {
        maxWait: 1000,
      }
    );
    editor.on('update', onUpdate);
    return () => {
      editor.off('update', onUpdate);
    };
  }, [handle?.editor]);
  return (
    <>
      <div className="rounded border border-sumi-200 bg-white">
        <SimpleBar
          className="h-52"
          classNames={{
            contentEl: 'simplebar-content h-full',
          }}
        >
          <WysiwygEditor
            placeholder={placeholder}
            defaultValue={defaultValue}
            initEditorHandle={setHandle}
            disabled={disabled}
            editorClassName="px-2.5 py-2"
          />
        </SimpleBar>
        <div className="mx-2.5 h-[1px] bg-sumi-200" />
        <div className="grid grid-cols-[1fr_auto] items-center gap-2 p-2.5">
          <EditorToolbarWithControls
            handle={handle}
            disabled={disabled}
            closable={false}
          />
          <button
            type="button"
            className="cursor-pointer bg-transparent p-0"
            onClick={() => {
              setShowReservedWords(!showReservedWords);
              handle?.editor?.commands.focus();
            }}
            disabled={disabled}
          >
            予約語
          </button>
        </div>
      </div>
      <ReservedWordsModal
        open={showReservedWords}
        onClose={() => setShowReservedWords(false)}
        description={
          <>
            テンプレート使用時に「送信先の名前」などを自動でメール本文に埋め込むことができる機能です。
            <br />
            ※情報がない場合は空欄で表示されます。
          </>
        }
        words={RESERVED_WORDS}
        focusEditor={() => handle?.editor?.commands.focus()}
        insertReservedWord={(text) => handle?.insertText(text)}
      />
    </>
  );
};
