import { useState } from 'react';
import { useCurrentConfirmDialog } from '../../../hooks/confirmDialog';
import { Dialog } from './Dialog';
import { DialogHeader } from './DialogHeader';
import { DialogContent } from './DialogContent';
import { DialogFooter } from './DialogFooter';
import Button from '../Button';
import { Loading } from '../Loading';

export const ConfirmDialogContainer = () => {
  const [loading, setLoading] = useState(false);
  const dialog = useCurrentConfirmDialog();
  const onOpenChange = (open: boolean) => {
    if (dialog && !open && !loading) {
      dialog.onCancel();
    }
  };
  const onOk = async () => {
    if (!dialog) {
      return;
    }
    setLoading(true);
    await dialog.onOk();
    setLoading(false);
  };

  if (!dialog) {
    return null;
  }
  return (
    <Dialog
      open={true}
      onOpenChange={onOpenChange}
      width={dialog.context.width ?? 'sm'}
    >
      <DialogHeader
        title={dialog.context.title}
        border={!!dialog.context.description}
      />
      {dialog.context.description && (
        <DialogContent>
          <div className="text-sm">{dialog.context.description}</div>
        </DialogContent>
      )}
      <DialogFooter border={!!dialog.context.description}>
        {dialog.context.onOk && (
          <Button
            variant="outlined"
            color="sumi"
            onClick={() => dialog.onCancel()}
            disabled={loading}
          >
            {dialog.context.cancelText ?? 'キャンセル'}
          </Button>
        )}
        {dialog.context.onOk && (
          <Button
            color={dialog.context.okType ?? 'primary'}
            onClick={onOk}
            className="flex items-center justify-center gap-2"
            disabled={loading}
          >
            {loading && <Loading />}
            {dialog.context.okText ?? 'OK'}
          </Button>
        )}
        {!dialog.context.onOk && (
          <Button
            color={dialog.context.okType ?? 'primary'}
            variant="outlined"
            onClick={onOk}
            className="flex items-center gap-2"
          >
            {dialog.context.okText ?? '閉じる'}
          </Button>
        )}
      </DialogFooter>
    </Dialog>
  );
};
