import * as React from 'react';
import type { SVGProps } from 'react';
const SvgInfo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M12.08 17a.731.731 0 0 0 .746-.75v-4.5a.724.724 0 0 0-.218-.534.732.732 0 0 0-.537-.216.731.731 0 0 0-.746.75v4.5c0 .212.072.39.217.534a.732.732 0 0 0 .537.216ZM12 9.15c.233 0 .43-.077.588-.23a.762.762 0 0 0 .237-.57.836.836 0 0 0-.237-.606.786.786 0 0 0-.587-.244.787.787 0 0 0-.588.244.835.835 0 0 0-.238.606c0 .227.079.417.237.57a.81.81 0 0 0 .587.23ZM12.006 22a9.68 9.68 0 0 1-3.888-.788 10.162 10.162 0 0 1-3.181-2.15 10.155 10.155 0 0 1-2.15-3.183A9.698 9.698 0 0 1 2 11.988c0-1.38.263-2.678.788-3.892a10.006 10.006 0 0 1 2.15-3.171c.908-.9 1.969-1.612 3.183-2.137A9.698 9.698 0 0 1 12.012 2c1.38 0 2.678.263 3.892.788a10.111 10.111 0 0 1 3.171 2.137c.9.9 1.613 1.958 2.137 3.175A9.721 9.721 0 0 1 22 11.993a9.68 9.68 0 0 1-.788 3.888 10.214 10.214 0 0 1-2.137 3.177c-.9.905-1.958 1.622-3.175 2.15a9.672 9.672 0 0 1-3.893.792Zm.005-1.5c2.359 0 4.363-.83 6.013-2.488 1.65-1.658 2.475-3.666 2.475-6.024 0-2.359-.823-4.363-2.47-6.013C16.383 4.325 14.373 3.5 12 3.5c-2.35 0-4.354.823-6.013 2.47C4.33 7.617 3.5 9.627 3.5 12c0 2.35.83 4.354 2.487 6.012 1.659 1.659 3.667 2.488 6.025 2.488Z"
    />
  </svg>
);
export default SvgInfo;
