import { deleteDoc, serverTimestamp, updateDoc } from 'firebase/firestore';

import { BaseStore } from './base';
import { CommentData } from 'lib';

export class CommentStore extends BaseStore<CommentData> {
  PATH = 'comments';

  updateComment(id: string, data: Partial<CommentData>): Promise<void> {
    return updateDoc(this.doc(id), {
      ...data,
      edited: true,
      updatedAt: serverTimestamp(),
    });
  }

  deleteComment(id: string): Promise<void> {
    return deleteDoc(this.doc(id));
  }
}
