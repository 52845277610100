import React from 'react';
import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { useSignInCompanyStripeProduct } from '../../../../atoms/firestore/signInCompanyStripeProduct';
import { useMsgRouteMatch } from '../../../../hooks/useMsgRouteMatch';

type MessageStatusButtonProps = {
  label: string;
};

export const MessageStatusAddButton: React.FC<MessageStatusButtonProps> = ({
  label,
}) => {
  const history = useHistory();
  const [stripeProductData] = useSignInCompanyStripeProduct();
  const match = useMsgRouteMatch();
  const { teamId } = match.params;
  const moveToStatuses = () => {
    history.push(`/settings/teams/${teamId}/mail/statuses`);
  };

  const canAddStatus = useMemo(() => {
    return (
      stripeProductData?.plan === 'business' ||
      stripeProductData?.plan === 'enterprise'
    );
  }, [stripeProductData]);

  return (
    <div className="group relative max-h-full">
      <button
        type="button"
        onClick={() => {
          if (canAddStatus) {
            moveToStatuses();
          }
        }}
        className={twMerge(
          'relative z-0 h-full cursor-pointer select-none whitespace-nowrap bg-transparent px-4 text-sm leading-4 text-sea-500 outline-none before:absolute before:inset-x-0 before:inset-y-[4px] before:z-[-1] before:rounded-md before:content-[""] hover:before:bg-sumi-50 focus-visible:before:bg-sumi-50',
          !canAddStatus && 'group-hover:block'
        )}
        disabled={!canAddStatus}
      >
        {label}
      </button>
      {!canAddStatus && (
        <div
          className={twMerge(
            'absolute left-[60px] top-[40px] hidden w-[410px] rounded bg-sumi-600 p-[12px] text-white group-hover:block'
          )}
        >
          <p className="m-0 text-center text-sm">
            対応中・保留中などステータスをカスタマイズすることができます。ビジネスプラン以上で利用できるので、ご興味がある方は右上のサポートチャットからお問い合わせください。
          </p>
        </div>
      )}
    </div>
  );
};
