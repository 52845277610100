import * as React from 'react';
import type { SVGProps } from 'react';
const SvgFormatQuote = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M14.5 11.5h4v-4h-4v4Zm-9 0h4v-4h-4v4ZM17.425 17c-.417 0-.73-.17-.938-.512-.208-.342-.22-.696-.037-1.063L17.65 13H14.5c-.417 0-.77-.146-1.063-.438A1.446 1.446 0 0 1 13 11.5v-4c0-.417.146-.77.438-1.063A1.447 1.447 0 0 1 14.5 6h4c.417 0 .77.146 1.063.438.291.291.437.645.437 1.062v5.35c0 .117-.012.233-.038.35a1.36 1.36 0 0 1-.112.325L18.4 16.4a.984.984 0 0 1-.387.438c-.176.108-.371.162-.588.162Zm-9 0c-.417 0-.73-.17-.938-.512-.208-.342-.22-.696-.037-1.063L8.65 13H5.5c-.417 0-.77-.146-1.063-.438A1.447 1.447 0 0 1 4 11.5v-4c0-.417.146-.77.438-1.063A1.447 1.447 0 0 1 5.5 6h4c.417 0 .77.146 1.063.438.291.291.437.645.437 1.062v5.35c0 .117-.012.233-.037.35a1.36 1.36 0 0 1-.113.325L9.4 16.4a.984.984 0 0 1-.388.438c-.175.108-.37.162-.587.162Z"
    />
  </svg>
);
export default SvgFormatQuote;
