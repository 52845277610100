import * as React from 'react';
import type { SVGProps } from 'react';
const SvgFormatAlignLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M4.25 20.5a.725.725 0 0 1-.534-.216.726.726 0 0 1-.216-.534c0-.213.072-.391.216-.535A.725.725 0 0 1 4.25 19h15.5c.212 0 .39.072.534.216a.726.726 0 0 1 .216.534c0 .213-.072.391-.216.535a.726.726 0 0 1-.534.215H4.25Zm0-3.875a.725.725 0 0 1-.534-.216.726.726 0 0 1-.216-.534c0-.213.072-.391.216-.534a.725.725 0 0 1 .534-.216h9.5c.213 0 .39.072.534.216a.726.726 0 0 1 .216.534c0 .213-.072.391-.216.535a.726.726 0 0 1-.534.215h-9.5Zm0-3.875a.725.725 0 0 1-.534-.216A.726.726 0 0 1 3.5 12c0-.213.072-.391.216-.534a.725.725 0 0 1 .534-.216h15.5c.212 0 .39.072.534.216A.726.726 0 0 1 20.5 12c0 .213-.072.391-.216.534a.726.726 0 0 1-.534.216H4.25Zm0-3.875a.725.725 0 0 1-.534-.216.726.726 0 0 1-.216-.534c0-.213.072-.391.216-.535a.725.725 0 0 1 .534-.215h9.5c.213 0 .39.072.534.216a.726.726 0 0 1 .216.534c0 .213-.072.391-.216.534a.726.726 0 0 1-.534.216h-9.5ZM4.25 5a.725.725 0 0 1-.534-.216.726.726 0 0 1-.216-.534c0-.213.072-.391.216-.534A.725.725 0 0 1 4.25 3.5h15.5c.212 0 .39.072.534.216a.726.726 0 0 1 .216.534c0 .213-.072.391-.216.535A.726.726 0 0 1 19.75 5H4.25Z"
    />
  </svg>
);
export default SvgFormatAlignLeft;
