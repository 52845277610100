import { InboxesEmpty } from '../../inbox/InboxEmpty/InboxesEmpty';
import { observer } from 'mobx-react';
import { useStore } from '../../../../../hooks/useStore';
import {
  MyEmailAddData,
  MyEmailAddDrawer,
} from '../MyEmailAddDrawer/MyEmailAddDrawer';
import { MyEmailRoutes } from '../MyEmailRoutes';
import { useLocation } from 'react-router-dom';
import { addInboxFunction } from '../../../../../functions';
import { eventNames, logEvent } from '../../../../../analytics';
import { useToast } from '../../../../../hooks/useToast';

type Props = {
  routes: MyEmailRoutes;
};

export const MyEmailEmptyWithLogic = observer(({ routes }: Props) => {
  const store = useStore();
  const { showToast } = useToast();
  const me = store.me;
  const teamId = store.privateTeam?.id;
  const location = useLocation();
  const open = routes.matchInboxNew(location.pathname);
  if (!teamId) {
    return null;
  }
  const signatures = store
    .getSignatures(teamId)
    .map((s) => ({ id: s.id, name: s.title }));

  const handleCreate = async (data: MyEmailAddData) => {
    await addInboxFunction({
      companyId: store.signInCompany,
      teamId: teamId,
      type: 'email',
      email: me.email,
      name: data.name,
      autoCc: data.autoCc,
      autoBcc: data.autoBcc,
      defaultSignatureId: data.defaultSignatureId,
    })
      .then(() => {
        showToast('success', 'メールアドレスを追加しました');
        logEvent(eventNames.add_inbox);
        routes.toInbox();
      })
      .catch((e) => {
        console.error(e);
        showToast('error', 'メールアドレスの追加に失敗しました');
      });
  };

  return (
    <>
      <InboxesEmpty
        onAdd={() => routes.toInboxNew()}
        isPrivateTeam
        readonly={store.me.isReadOnly}
      />
      <MyEmailAddDrawer
        email={me.email}
        signatures={signatures}
        open={open}
        onOpenChange={() => routes.toInbox()}
        onCreate={handleCreate}
      />
    </>
  );
});
