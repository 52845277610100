import { Tooltip } from 'antd';
import React from 'react';
import { isSP } from '../../../shared/util';

const CommonTooltip = ({ visible, children, ...props }) => (
  <Tooltip overlayStyle={visible ? {} : { display: 'none' }} {...props}>
    {children}
  </Tooltip>
);
export default CommonTooltip;

export const PCTooltip = ({ children, ...props }) =>
  isSP() ? children : <Tooltip {...props}>{children}</Tooltip>;
