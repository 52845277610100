import { FormEvent, useMemo, useState } from 'react';
import { InputGroup } from '../../../../../components/forms/InputGroup/InputGroup';
import { Input } from '../../../../../components/forms';
import { Button } from '../../../../../components/basics';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { arrayMove, SortableContext } from '@dnd-kit/sortable';
import { StatusListItem } from '../StatusListItem/StatusListItem';
import { Tooltip } from '../../../../../components/basics/Tooltip/Tooltip';
import { StatusListItemText } from '../StatusListItem/StatusListItemText';
import { CustomStatus, MessageStatus } from 'lib';

type FormStatus = Omit<CustomStatus, 'id'>;

type Props = {
  statuses: CustomStatus[];

  // 新規作成時のイベント
  onCreate: (status: FormStatus) => void;
  readonly: boolean;
  supported: boolean;
  maxStatuses: number;
  onOpenDrawer: (status: CustomStatus | string) => void;
  onDrag: (data: CustomStatus[]) => void;
};

export const Statuses = ({
  statuses,
  onCreate,
  readonly,
  supported,
  maxStatuses,
  onOpenDrawer,
  onDrag,
}: Props) => {
  const [newStatus, setNewStatus] = useState('');

  const isValidNewStatus = (status: string): boolean => {
    const registeredStatusName: string[] = [
      MessageStatus.Unprocessed,
      ...statuses.map((s) => s.name),
      MessageStatus.Processed,
    ];
    return !registeredStatusName.includes(status.trim());
  };

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();

    const value = newStatus.trim();
    if (!value) {
      return;
    }

    const newobj: FormStatus = {
      name: value,
      nextStatus: null,
      order: statuses.length + 1,
    };

    onCreate(newobj);
    setNewStatus('');
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id == null || over == null || active.id === over.id) {
      return;
    }

    const oldIndex = statuses[0].id.indexOf(active.id as string);
    const newIndex = statuses[0].id.indexOf(over.id as string);
    const sorted = arrayMove(statuses, oldIndex, newIndex);

    onDrag(sorted);
  };

  const statusesLength = useMemo(
    () =>
      statuses.filter((status) => status.name !== MessageStatus.Unprocessed)
        .length,
    [statuses]
  );
  const isCreatable = supported && statusesLength < maxStatuses;

  return (
    <div className="flex flex-col gap-6 text-sm">
      <Tooltip
        content={
          !supported
            ? '現在のプランではサポートされていません'
            : `ステータスは ${maxStatuses}つ まで作成可能です`
        }
        visible={!isCreatable}
      >
        <form action="" onSubmit={onSubmit}>
          <InputGroup
            label="ステータスを作成する"
            description="例：対応中、保留中"
          >
            <div className="grid grid-cols-[1fr_auto] gap-4">
              <Input
                value={newStatus}
                onChange={(e) => setNewStatus(e.target.value)}
                placeholder="ステータス名"
                disabled={readonly || !isCreatable}
              />
              <Button
                type="submit"
                disabled={
                  readonly || !isCreatable || !isValidNewStatus(newStatus)
                }
              >
                作成
              </Button>
            </div>
          </InputGroup>
        </form>
      </Tooltip>
      <div className="flex flex-col">
        <div className="mb-4 text-base font-bold">作成済みのステータス</div>
        <DndContext onDragEnd={handleDragEnd}>
          <SortableContext items={statuses}>
            <StatusListItemText
              text={MessageStatus.Unprocessed}
              editable
              onClick={() => {
                onOpenDrawer(MessageStatus.Unprocessed);
              }}
            />

            {statuses
              .filter((status) => status.name !== MessageStatus.Unprocessed)
              .map((status, i) => (
                <StatusListItem
                  key={i}
                  status={status}
                  draggable={!readonly}
                  editable={!readonly}
                  onOpenDrawer={onOpenDrawer}
                />
              ))}
            <StatusListItemText text={MessageStatus.Processed} />
          </SortableContext>
        </DndContext>
      </div>
    </div>
  );
};
