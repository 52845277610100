import { Controller, useFieldArray, useForm } from 'react-hook-form';
import React, {
  ComponentProps,
  MutableRefObject,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Dialog } from '../../../basics/dialog/Dialog';
import { DialogContent } from '../../../basics/dialog/DialogContent';
import { DialogHeader } from '../../../basics/dialog/DialogHeader';
import SimpleBar from 'simplebar-react';
import * as RadixDialog from '@radix-ui/react-dialog';
import { InputGroup } from '../../../forms/InputGroup/InputGroup';
import { DeliveryEmailAddressSelect } from '../DeliveryEmailAddressSelect/DeliveryEmailAddressSelect';
import {
  Attach,
  Close,
  Error,
  NoteAdd,
  OpenInNew,
  Upload,
  Verified,
} from '../../../icons';
import { Button, Icon, Loading } from '../../../basics';
import { Input } from '../../../forms';
import { DeliveryMessageEditor } from '../DeliveryMessageEditor/DeliveryMessageEditor';
import { FormButton } from '../../../forms/FormButton/FormButton';
import Dropzone from 'react-dropzone';
import { EditorHandle } from '../../../../App/Common/Editor/WysiwygEditor/WysiwygEditor';
import { debounce, isEqual, uniq } from 'lodash';
import moment from 'moment';
import { useToast } from '../../../../hooks/useToast';
import {
  DraftAttachment,
  extractEmail,
  isFreeEmailDomain,
  TagColor,
} from 'lib';
import bytes from 'bytes';
import { Tooltip } from 'components/basics/Tooltip/Tooltip';
import { useSetAtom } from 'jotai';
import { scheduleAttachmentDeletionAtom } from './attachmentDeletionScheduler';
import { twMerge } from 'tailwind-merge';
import { useConfirmDialog } from '../../../../hooks/confirmDialog';
import { DeliveryTagsInput } from '../DeliveryTagsInput/DeliveryTagsInput';

export type DeliveryMessageFormData = {
  from: string;
  tags: string[];
  subject: string;
  body?: string | null;
  bodyText: string;
  attachments: DraftAttachment[];
  isPlaintext: boolean;
};

type EditorProps = ComponentProps<typeof DeliveryMessageEditor>;

export type DeliveryFormHandle = {
  setValue: (id: string, value: unknown) => void;
  handle: EditorHandle;
  notifyAutoSave: () => Promise<void>;
};

type Props = Pick<
  ComponentProps<typeof Dialog>,
  'open' | 'onOpenChange' | 'container' | 'modal'
> & {
  addresses: {
    id: string;
    name?: string;
    address: string;
  }[];
  groups: {
    team: string;
    tags: {
      id: string;
      name: string;
      color: TagColor | null | undefined;
    }[];
  }[];
  onAddEmailAddress: () => void;
  onGenerate: (prompt: string, handle: EditorHandle) => void;
  onUploadAttachment: (
    file: File,
    currentAttachments: DraftAttachment[]
  ) => Promise<DraftAttachment | undefined>;
  onDeleteAttachment: (attachment: DraftAttachment) => Promise<void>;
  data?: Partial<DeliveryMessageFormData>;
  onSubmit: (data: DeliveryMessageFormData) => void;
  onAutoSave: (data: DeliveryMessageFormData) => Promise<void>;
  onUploadImage: (
    file: File,
    currentAttachments: DraftAttachment[]
  ) => Promise<
    { src: string; contentId: string; attachment: DraftAttachment } | undefined
  >;
  onUpdateRecipientCount: (empty: boolean) => Promise<void>;
  checkDomainAuth: (
    domain: string
  ) => Promise<{ spfDkim: boolean; dmarc: boolean }>;
  isYaritoriAISupported: boolean;
  recipients: {
    total: number;
    count: number;
  };
  formHandleRef: MutableRefObject<DeliveryFormHandle | undefined>;
  editorChildren?: (handle: EditorHandle) => ReactNode;
  disabled?: boolean;
  readonly?: boolean;
};

export const DeliveryMessageCreateForm = ({
  addresses,
  groups,
  onAddEmailAddress,
  onGenerate,
  onUploadAttachment,
  onDeleteAttachment,
  data,
  onSubmit,
  onAutoSave,
  onUploadImage,
  onUpdateRecipientCount,
  checkDomainAuth,
  isYaritoriAISupported,
  recipients,
  formHandleRef,
  editorChildren,
  disabled,
  readonly,
  open,
  onOpenChange,
  modal,
  container,
}: Props) => {
  const showDialog = useConfirmDialog();
  const [autoSavedAt, setAutoSavedAt] = useState<Date>();
  const [saving, setSaving] = useState(false);
  const [deletingAttachmentIds, setDeletingAttachmentIds] = useState<string[]>(
    []
  );
  const [editorHandle, setEditorHandle] = useState<EditorHandle>();
  const [spfDkimState, setSpfDkimState] = useState<
    'free-domain' | 'verified' | 'not-verified' | 'loading'
  >('loading');
  const [dmarcState, setDmarcState] = useState<
    'verified' | 'not-verified' | 'loading'
  >('loading');
  const [recipientCountLoading, setRecipientCountLoading] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [hasUnsavedEditorChanges, setHasUnsavedEditorChanges] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const defaultValues = {
    from: data?.from ?? '',
    tags: data?.tags ?? [],
    subject: data?.subject ?? '',
    body: data?.body ?? '',
    bodyText: data?.bodyText ?? '',
    attachments: data?.attachments ?? [],
    isPlaintext: data?.isPlaintext ?? false,
  };
  const lastSavedDataRef = useRef<DeliveryMessageFormData | undefined>(
    defaultValues
  );
  const {
    control,
    register,
    setValue,
    watch,
    formState: { isSubmitting, isValid, isDirty },
    handleSubmit,
  } = useForm<DeliveryMessageFormData>({
    defaultValues,
  });
  const lateDeleteAttachmentsRef = useRef<DraftAttachment[]>([]);

  const { showToast } = useToast();

  const lastFromRef = useRef<string>(defaultValues.from);
  const lastTagsRef = useRef<string[]>(defaultValues.tags);
  const save = useCallback(
    async (update: DeliveryMessageFormData) => {
      setSaving(true);

      // 画像アップロード中は保存を後回しにする
      if (await editorHandle?.hasUploadingImages()) {
        debouncedSave(update);
        return;
      }

      try {
        await onAutoSave(update);
        setAutoSavedAt(new Date());

        if (
          lastFromRef.current !== update.from ||
          !isEqual(lastTagsRef.current, update.tags)
        ) {
          lastFromRef.current = update.from;
          lastTagsRef.current = update.tags;
          setRecipientCountLoading(true);
          onUpdateRecipientCount(!update.from || update.tags.length === 0).then(
            () => setRecipientCountLoading(false)
          );
        }
      } catch (e) {
        console.error(e);
        showToast('error', '保存に失敗しました');
      } finally {
        setSaving(false);
        setHasUnsavedChanges(false);
      }
    },
    [editorHandle, onAutoSave, onUpdateRecipientCount]
  );

  const debouncedSave = useMemo(() => debounce(save, 1000), [save]);

  const watchedData = watch();

  const canClose = !hasUnsavedChanges && !hasUnsavedEditorChanges;
  const canSubmit =
    !recipientCountLoading &&
    recipients.count &&
    isValid &&
    !isSubmitting &&
    watchedData.tags.length > 0 &&
    canClose;

  useEffect(() => {
    const id = watchedData.from;
    const address = addresses.find((a) => a.id === id);
    if (!address) {
      return;
    }
    const domain = extractEmail(address.address).split('@')[1];

    if (isFreeEmailDomain(domain)) {
      setSpfDkimState('free-domain');
      return;
    }

    setSpfDkimState('loading');
    setDmarcState('loading');
    checkDomainAuth(domain)
      .then(({ spfDkim, dmarc }) => {
        setSpfDkimState(spfDkim ? 'verified' : 'not-verified');
        setDmarcState(dmarc ? 'verified' : 'not-verified');
      })
      .catch((err) => {
        console.error(err);
      });
  }, [addresses, watchedData.from]);

  useEffect(() => {
    if (isEqual(watchedData, lastSavedDataRef.current)) {
      return;
    }

    setHasUnsavedChanges(true);
    debouncedSave(watchedData);
    lastSavedDataRef.current = watchedData;
  }, [debouncedSave, watchedData, isDirty]);

  useImperativeHandle(formHandleRef, () => ({
    handle: editorHandle!,
    setValue: (id: string, value: unknown) => {
      setValue(id as never, value as never, { shouldDirty: true });
    },
    notifyAutoSave: async () => await save(watchedData),
  }));

  const attachmentsField = useFieldArray({ control, name: 'attachments' });

  const onDrop = (acceptedFiles: File[]) => {
    const promises = acceptedFiles.map((file) =>
      onUploadAttachment(file, attachmentsField.fields).then((res) => {
        if (res) {
          attachmentsField.append(res);
        }
      })
    );
    Promise.allSettled(promises).catch((e) => console.error(e));
  };

  const handleFileChange = async (file: File) => {
    fileInputRef.current!.value = '';
    try {
      const attachment = await onUploadAttachment(
        file,
        attachmentsField.fields
      );
      if (attachment) {
        attachmentsField.append(attachment);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleDeleteAttachment = async (attachment: DraftAttachment) => {
    setDeletingAttachmentIds(uniq([...deletingAttachmentIds, attachment.uid]));
    try {
      await onDeleteAttachment(attachment);
      const index = attachmentsField.fields.findIndex(
        (a) => a.uid === attachment.uid
      );
      if (index >= 0) {
        attachmentsField.remove(index);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setDeletingAttachmentIds(
        deletingAttachmentIds.filter((uid) => uid !== attachment.uid)
      );
    }
  };

  const onInsertImage: EditorProps['onInsertImage'] = (contentId) => {
    const attachment = lateDeleteAttachmentsRef.current.find(
      (a) => a.uid === contentId
    );
    if (!attachment) {
      return;
    }

    lateDeleteAttachmentsRef.current = lateDeleteAttachmentsRef.current.filter(
      (a) => a.uid !== attachment.uid
    );
    if (attachmentsField.fields.some((a) => a.uid === attachment.uid)) {
      return;
    }
    attachmentsField.append(attachment);
  };

  const onDeleteImage: EditorProps['onDeleteImage'] = (contentId) => {
    const index = attachmentsField.fields.findIndex((a) => a.uid === contentId);
    if (index < 0) {
      return;
    }
    const attachment = attachmentsField.fields[index];
    attachmentsField.remove(index);
    lateDeleteAttachmentsRef.current = [
      ...lateDeleteAttachmentsRef.current,
      attachment,
    ];
  };

  const scheduleDeletion = useSetAtom(scheduleAttachmentDeletionAtom);
  useEffect(() => {
    return () => {
      const attachments = lateDeleteAttachmentsRef.current;
      if (!attachments.length) {
        return;
      }
      attachments.forEach((attachment) => scheduleDeletion(attachment));
    };
  }, [scheduleDeletion]);

  const handleGenerate = async (prompt: string, handle: EditorHandle) => {
    await save(watchedData);
    onGenerate(prompt, handle);
  };

  return (
    <RadixDialog.Root
      open={open}
      onOpenChange={(open) => {
        if (!canClose) {
          return;
        }
        onOpenChange?.(open);
      }}
      modal={modal}
    >
      <RadixDialog.Portal container={container}>
        <RadixDialog.Overlay className="fixed inset-0 z-50 bg-black/50 data-[state=open]:animate-dialog-overlay-show" />
        <RadixDialog.Content className="pointer-events-none absolute left-0 top-0 z-50 h-full w-full">
          <SimpleBar
            className="max-h-[100dvh] py-12 outline-none"
            classNames={{
              contentWrapper: 'simplebar-content-wrapper outline-none',
            }}
          >
            <div className="flex w-full justify-center">
              <form onSubmit={handleSubmit(onSubmit)}>
                <fieldset
                  className="pointer-events-auto w-[800px] max-w-[95dvw] animate-dialog-content-show rounded bg-white text-black"
                  disabled={disabled}
                >
                  <Dropzone
                    onDrop={onDrop}
                    noClick
                    noKeyboard
                    disabled={isSubmitting}
                  >
                    {({ getRootProps, getInputProps, isDragActive }) => (
                      <div
                        {...getRootProps({
                          className: 'h-full relative',
                        })}
                      >
                        <input {...getInputProps()} />
                        {isDragActive && (
                          <div className="absolute left-0 top-0 z-10 flex h-full w-full flex-col items-center justify-center gap-4 rounded bg-white/50 text-sumi-800">
                            <Icon icon={NoteAdd} size={32} />
                            <div>ドロップしてファイルをアップロード</div>
                          </div>
                        )}

                        <DialogHeader title="メールを作成" />
                        <DialogContent>
                          <div className="flex flex-col gap-6 text-sm text-black">
                            <Controller
                              name="from"
                              control={control}
                              render={({
                                field: { value, onChange, disabled },
                              }) => (
                                <InputGroup
                                  label="送信元アドレス"
                                  descriptionTop="Fromとして表示されるメールアドレスです。メール一斉送信用として登録します。"
                                  description={
                                    value ? (
                                      <>
                                        {(spfDkimState === 'verified' ||
                                          dmarcState === 'verified') && (
                                          <div className="flex items-center gap-2">
                                            {spfDkimState === 'verified' ? (
                                              <div className="grid grid-cols-[auto_1fr] items-center gap-1.5 text-forest-600">
                                                <Icon
                                                  icon={Verified}
                                                  size={20}
                                                />
                                                <span>SPF/DKIM認証済み</span>
                                              </div>
                                            ) : (
                                              <div className="grid grid-cols-[auto_1fr] items-center gap-1.5 text-sun-500">
                                                <Icon icon={Error} size={20} />
                                                <span>SPF/DKIM未認証</span>
                                              </div>
                                            )}
                                            {dmarcState === 'verified' ? (
                                              <div className="grid grid-cols-[auto_1fr] items-center gap-1.5 text-forest-600">
                                                <Icon
                                                  icon={Verified}
                                                  size={20}
                                                />
                                                <span>DMARC認証済み</span>
                                              </div>
                                            ) : (
                                              <div className="grid grid-cols-[auto_1fr] items-center gap-1.5 text-sun-500">
                                                <Icon icon={Error} size={20} />
                                                <span>DMARC未認証</span>
                                              </div>
                                            )}
                                          </div>
                                        )}
                                        {spfDkimState === 'not-verified' &&
                                          dmarcState === 'not-verified' && (
                                            <div className="grid grid-cols-[auto_1fr] items-center gap-1.5 text-sun-500">
                                              <Icon icon={Error} size={20} />
                                              <span>
                                                ドメイン認証が未設定のメールアドレスです。必要に応じて{' '}
                                                <a
                                                  href="/settings/company/domain-auth"
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  className="inline-grid grid-cols-[1fr_auto] items-center gap-[2px] text-sun-500 underline"
                                                >
                                                  <span>ドメイン認証</span>
                                                  <Icon
                                                    icon={OpenInNew}
                                                    size="1.1em"
                                                  />
                                                </a>
                                                から設定してください
                                              </span>
                                            </div>
                                          )}
                                        {spfDkimState === 'loading' && (
                                          <div className="grid h-[20px] grid-cols-[auto_1fr] items-center gap-2">
                                            <Loading size={18} />
                                            <span>読み込み中</span>
                                          </div>
                                        )}
                                        {spfDkimState === 'free-domain' && (
                                          <div className="grid grid-cols-[auto_1fr] items-center gap-1.5 text-sun-500">
                                            <Icon icon={Error} size={20} />
                                            <span>
                                              フリーメールアドレスのためドメイン認証できません
                                            </span>
                                          </div>
                                        )}
                                      </>
                                    ) : undefined
                                  }
                                  required
                                >
                                  <DeliveryEmailAddressSelect
                                    value={value}
                                    onChange={onChange}
                                    onAddEmailAddress={onAddEmailAddress}
                                    addresses={addresses}
                                    disabled={disabled}
                                  />
                                </InputGroup>
                              )}
                            />
                            <InputGroup
                              label="送信先グループ"
                              descriptionTop={
                                <>
                                  送信先グループはコンタクトのタグで指定します。
                                  <a
                                    href="/contacts"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="inline-grid grid-cols-[1fr_auto] items-center gap-[2px] text-sea-500 hover:underline"
                                  >
                                    <span>コンタクト画面</span>
                                    <Icon icon={OpenInNew} size="1.1em" />
                                  </a>
                                  でタグを設定してください。
                                </>
                              }
                              required
                            >
                              <Controller
                                name="tags"
                                control={control}
                                render={({
                                  field: { value, onChange, disabled },
                                }) => (
                                  <DeliveryTagsInput
                                    value={value ?? []}
                                    onChange={onChange}
                                    groups={groups}
                                    disabled={disabled}
                                  />
                                )}
                              />
                            </InputGroup>
                            <InputGroup label="件名" required>
                              <Input
                                size="md"
                                placeholder="入力してください"
                                {...register('subject', {
                                  required: '入力してください',
                                })}
                              />
                            </InputGroup>
                            <InputGroup label="本文" required>
                              <Controller
                                name="bodyText"
                                control={control}
                                render={({ field: { onChange, disabled } }) => (
                                  <DeliveryMessageEditor
                                    disabled={disabled}
                                    defaultValue={
                                      defaultValues.body ??
                                      defaultValues.bodyText
                                    }
                                    isYaritoriAISupported={
                                      isYaritoriAISupported
                                    }
                                    uploadImage={async (file) => {
                                      const data = await onUploadImage(
                                        file,
                                        attachmentsField.fields
                                      );
                                      if (!data) {
                                        return undefined;
                                      }

                                      attachmentsField.append(data.attachment);
                                      return {
                                        src: data.src,
                                        contentId: data.contentId,
                                      };
                                    }}
                                    onInsertImage={onInsertImage}
                                    onDeleteImage={onDeleteImage}
                                    onChange={(plainText, html) => {
                                      onChange(plainText);
                                      setValue(
                                        'body',
                                        watchedData.isPlaintext ? null : html
                                      );
                                    }}
                                    onGenerate={handleGenerate}
                                    initEditorHandle={setEditorHandle}
                                    editorChildren={editorChildren}
                                    readonly={readonly}
                                    isPlaintext={watchedData.isPlaintext}
                                    onChangePlaintext={(plaintext) =>
                                      plaintext
                                        ? showDialog({
                                            title:
                                              'プレーンテキストモード切り替え',
                                            description: (
                                              <>
                                                設定されている装飾がすべてリセットされます。
                                                <br />
                                                プレーンテキストモードに切り替えますか？
                                              </>
                                            ),
                                            onOk: () =>
                                              setValue(
                                                'isPlaintext',
                                                plaintext
                                              ),
                                          })
                                        : setValue('isPlaintext', plaintext)
                                    }
                                    setHasUnsavedChanges={
                                      setHasUnsavedEditorChanges
                                    }
                                  />
                                )}
                                rules={{
                                  required: '入力してください',
                                }}
                              />
                            </InputGroup>
                            {attachmentsField.fields.length > 0 && (
                              <div className="flex flex-col gap-1.5">
                                {attachmentsField.fields.map((attachment) => {
                                  const isDeleting =
                                    deletingAttachmentIds.includes(
                                      attachment.uid
                                    );
                                  return (
                                    <div
                                      key={attachment.uid}
                                      className="grid grid-cols-[auto_auto_auto] items-center justify-start"
                                    >
                                      <div className="mr-1 flex h-5 w-5 items-center justify-center">
                                        {isDeleting ? (
                                          <Loading size={16} />
                                        ) : (
                                          <Icon icon={Attach} size={20} />
                                        )}
                                      </div>
                                      <span className="truncate">
                                        {attachment.name} (
                                        {bytes.format(attachment.size)})
                                      </span>
                                      <div className="relative ml-4 h-4 w-4">
                                        {!isDeleting && (
                                          <Tooltip content="添付ファイルを削除">
                                            <button
                                              type="button"
                                              className="absolute -left-1.5 -top-1.5 flex h-7 w-7 cursor-pointer items-center justify-center rounded-full bg-transparent p-0 hover:bg-black/10"
                                              onClick={() =>
                                                handleDeleteAttachment(
                                                  attachment
                                                )
                                              }
                                            >
                                              <Icon
                                                icon={Close}
                                                size={12}
                                                className="block"
                                              />
                                            </button>
                                          </Tooltip>
                                        )}
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            )}
                            <InputGroup label="添付ファイル">
                              <FormButton
                                icon={Upload}
                                onClick={() => fileInputRef.current?.click()}
                              >
                                ファイルをアップロード
                              </FormButton>
                            </InputGroup>
                          </div>
                        </DialogContent>
                        <div className="grid h-[76px] grid-cols-[auto_auto] items-center justify-between gap-4 border-y border-b-transparent border-t-sumi-300 px-4">
                          <div
                            className={twMerge(
                              'flex flex-nowrap items-end gap-1.5 text-xs transition-opacity',
                              recipientCountLoading ? 'opacity-50' : ''
                            )}
                          >
                            <div>{recipients.total}件中</div>
                            <div className="text-[30px] font-bold leading-8">
                              {recipients.count}
                            </div>
                            <div>件の宛先に送信します</div>
                          </div>
                          <div className="flex items-center gap-2">
                            <div className="text-xs">
                              {saving && <>下書きを保存中...</>}
                              {!saving && autoSavedAt && (
                                <>
                                  {moment(autoSavedAt).format('HH:mm')}
                                  に自動保存しました
                                </>
                              )}
                            </div>
                            <RadixDialog.Close asChild>
                              <Button
                                variant="outlined"
                                disabled={isSubmitting || !canClose}
                              >
                                キャンセル
                              </Button>
                            </RadixDialog.Close>
                            <Button
                              type="submit"
                              disabled={!canSubmit}
                              loading={isSubmitting}
                            >
                              確認して送信
                            </Button>
                          </div>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                </fieldset>
              </form>
              <input
                type="file"
                onChange={(e) => handleFileChange(e.target.files![0])}
                ref={fileInputRef}
                hidden
              />
            </div>
          </SimpleBar>
        </RadixDialog.Content>
      </RadixDialog.Portal>
    </RadixDialog.Root>
  );
};
