import { useEffect } from 'react';
import { atom, useSetAtom } from 'jotai';
import { getAuth, onAuthStateChanged, User as AuthUser } from 'firebase/auth';
import {
  Company,
  companyConverter,
  preferencesConverter,
  PreferencesData,
  User,
  userConverter,
} from 'lib';
import { doc, getDoc } from 'firebase/firestore';
import { db9 } from '../firebase';
import { subscribeDoc, unsubscribeAll } from '../firestore';
import mobxStore from '../store';

const writableAuthUserLoadingAtom = atom(true);
const writableAuthUserAtom = atom<AuthUser | undefined>(undefined);
export const authUserLoadingAtom = atom((get) =>
  get(writableAuthUserLoadingAtom)
);
export const authUserAtom = atom((get) => get(writableAuthUserAtom));

// Logged-in user company
const writableCompanyLoadingAtom = atom(true);
const writableCompanyAtom = atom<Company | undefined>(undefined);
export const companyLoadingAtom = atom((get) =>
  get(writableCompanyLoadingAtom)
);
export const companyAtom = atom((get) => {
  const company = get(writableCompanyAtom);
  if (!company) {
    throw new Error('Company not loaded.');
  }
  return company;
});

// Logged-in company user
const writableMeLoadingAtom = atom(true);
const writableMeAtom = atom<User | undefined>(undefined);
export const meLoadingAtom = atom((get) => get(writableMeLoadingAtom));
export const meAtom = atom((get) => {
  const user = get(writableMeAtom);
  if (!user) {
    throw new Error('User not sign in.');
  }
  return user;
});

const writablePreferencesLoadingAtom = atom(true);
const writablePreferencesAtom = atom<PreferencesData | undefined>(undefined);
export const preferencesLoadingAtom = atom((get) =>
  get(writablePreferencesLoadingAtom)
);
export const preferencesAtom = atom((get) => {
  const preferences = get(writablePreferencesAtom);
  if (!preferences) {
    return {};
  }
  return preferences;
});

export const allLoadedAtom = atom(
  (get) =>
    !!get(writableAuthUserAtom) &&
    !!get(writableCompanyAtom) &&
    !!get(writableMeAtom) &&
    !get(preferencesLoadingAtom)
);
export const threadViewAtom = atom(
  (get) => get(preferencesAtom).threadView ?? false
);

export const useSubscribeAuthState = () => {
  const setAuthUserLoading = useSetAtom(writableAuthUserLoadingAtom);
  const setAuthUser = useSetAtom(writableAuthUserAtom);
  const setCompanyLoading = useSetAtom(writableCompanyLoadingAtom);
  const setCompany = useSetAtom(writableCompanyAtom);
  const setUserLoading = useSetAtom(writableMeLoadingAtom);
  const setUser = useSetAtom(writableMeAtom);
  const setPreferencesLoading = useSetAtom(writablePreferencesLoadingAtom);
  const setPreferences = useSetAtom(writablePreferencesAtom);

  const setCurrentUser = async (authUser: AuthUser | null) => {
    await mobxStore.init(authUser);
    if (authUser) {
      const userSnap = await getDoc(
        doc(db9, 'users', authUser.uid).withConverter(userConverter)
      );

      if (!userSnap.exists()) {
        throw new Error('Unable to find the signed in user.');
      }
      const user = userSnap.data();
      const companyId = user.companies[0];
      subscribeDoc(
        writableCompanyAtom,
        doc(db9, 'companies', companyId).withConverter(companyConverter),
        () => setCompanyLoading(false)
      );
      subscribeDoc(
        writableMeAtom,
        doc(db9, 'companies', companyId, 'users', user.id).withConverter(
          userConverter
        ),
        () => setUserLoading(false)
      );
      subscribeDoc(
        writablePreferencesAtom,
        doc(
          db9,
          'companies',
          companyId,
          'users',
          user.id,
          'private',
          'preferences'
        ).withConverter(preferencesConverter),
        () => setPreferencesLoading(false),
        () => setPreferencesLoading(false)
      );
    } else {
      unsubscribeAll();
      setCompanyLoading(true);
      setCompany(undefined);
      setUserLoading(true);
      setUser(undefined);
      setPreferencesLoading(true);
      setPreferences(undefined);
    }
    setAuthUser(authUser ?? undefined);
    setAuthUserLoading(false);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(), setCurrentUser);
    return () => unsubscribe();
  }, []);
};
