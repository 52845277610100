import { matchPath, useHistory } from 'react-router-dom';
import { throttle } from 'lodash';
import firebase from '../../../../firebase';
import { eventNames, logEvent } from '../../../../analytics';
import { useStore } from '../../../../hooks/useStore';
import {
  modalDraftAddingAtom,
  modalDraftAtom,
} from '../../../../atoms/firestore/draft';
import { useAtom, useSetAtom } from 'jotai';
import { addDoc, getDoc } from 'firebase/firestore';
import { companyCollection } from '../../../../firestore';
import { draftConverter } from '../../../../firestore/entity/draft';

type UseDraftResult = {
  createDraft: () => void;
  hasDraft: boolean;
};

export const useDraft = (): UseDraftResult => {
  const store = useStore();
  const history = useHistory();
  const [modalDraft, setModalDraft] = useAtom(modalDraftAtom);
  const setModalDraftAdding = useSetAtom(modalDraftAddingAtom);

  const getCurrentInbox = () => {
    const currentInboxId = (
      matchPath(history.location.pathname, {
        path: '/teams/:teamId/inboxes/:inboxId',
      })?.params as any
    )?.inboxId;

    if (currentInboxId) {
      return store.getInbox(currentInboxId);
    }

    const currentTeamId = (
      matchPath(history.location.pathname, {
        path: '/teams/:teamId',
      })?.params as any
    )?.teamId;

    if (currentTeamId) {
      return store.getTeamFirstInbox(currentTeamId);
    }
  };

  const createDraft = throttle(
    async () => {
      setModalDraftAdding(true);
      const { me } = store;
      const currentInbox = getCurrentInbox();
      const privateInbox =
        store.privateTeam && store.getTeamFirstInbox(store.privateTeam.id);
      const firstInbox = store.firstInbox;
      const defaultInbox = store.defaultInbox;
      // 送信元アドレスにセットする優先順位: 表示中Inbox, デフォルトInbox, 個人Inbox, 最初のInbox
      const inbox = currentInbox || defaultInbox || privateInbox || firstInbox;

      if (!inbox) {
        return;
      }

      const draftRef = await addDoc(companyCollection('drafts'), {
        inboxId: inbox.id,
        teamId: inbox.teamId,
        to: [],
        cc: inbox.autoCcs, // 自動cc
        bcc: inbox.autoBccs, // 自動Bcc
        subject: '',
        body: '',
        signature: inbox.defaultSignatureId
          ? store.getSignature(inbox.defaultSignatureId)?.signature || null
          : null,
        attachments: [],
        plainTextMode: me.plainTextMode,
        drafter: store.me.id,
        isReply: false,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      });

      logEvent(eventNames.add_draft);
      const draftSnap = await getDoc(draftRef.withConverter(draftConverter));
      if (!draftSnap.exists) {
        console.error('LeftSidebar.createDraft: !draftSnapshot.exists:', {
          draftId: draftSnap.id,
        });
      }
      setModalDraft(draftSnap.data());
      setModalDraftAdding(false);
      store.closeDrawer();
    },
    2000,
    { trailing: false }
  );

  return {
    createDraft,
    hasDraft: store.inboxes.length === 0 || Boolean(modalDraft),
  };
};
