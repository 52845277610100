import * as React from 'react';
import type { SVGProps } from 'react';
const SvgChat = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M6.75 14.025h6.325c.213 0 .39-.072.534-.217a.732.732 0 0 0 .216-.537.731.731 0 0 0-.75-.746H6.75a.724.724 0 0 0-.534.217.732.732 0 0 0-.216.537.731.731 0 0 0 .75.746Zm0-3.25h10.5c.212 0 .39-.072.534-.217a.732.732 0 0 0 .216-.537.731.731 0 0 0-.75-.746H6.75a.724.724 0 0 0-.534.217.732.732 0 0 0-.216.537.731.731 0 0 0 .75.746Zm0-3.25h10.5c.212 0 .39-.072.534-.217A.732.732 0 0 0 18 6.771a.731.731 0 0 0-.75-.746H6.75a.724.724 0 0 0-.534.217.732.732 0 0 0-.216.537.731.731 0 0 0 .75.746ZM6 18l-2.725 2.725c-.233.233-.504.286-.813.159C2.155 20.756 2 20.528 2 20.2V3.5c0-.4.15-.75.45-1.05.3-.3.65-.45 1.05-.45h17c.4 0 .75.15 1.05.45.3.3.45.65.45 1.05v13c0 .4-.15.75-.45 1.05-.3.3-.65.45-1.05.45H6Zm-2.5-1.5h17v-13h-17v13Z"
    />
  </svg>
);
export default SvgChat;
