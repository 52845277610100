import styled from 'styled-components';
import { Comment } from '../../../Common/Comment';
import React from 'react';
import * as color from '../../../../color';
import { useStore } from '../../../../hooks/useStore';
import Tooltip from '../../../Common/Tooltip';
import { DefaultButton } from '../../../Common/Button';
import { message, Modal } from 'antd';
import { Group as GroupEntity } from '../../../../store/entity';

type Props = {
  group: GroupEntity;
  onEdit: (group: GroupEntity) => void;
};

export const Group = ({ group, onEdit }: Props): JSX.Element => {
  const { me, groupStore } = useStore();

  const isAdmin = me.isAdmin;

  const onClickDelete = () => {
    Modal.confirm({
      title: `グループ "${group.name}" を削除しますか？`,
      content: '一度削除すると元に戻せません',
      onOk: async () => {
        await groupStore.deleteGroup(group.id);
        message.success('グループを削除しました');
      },
      okText: '削除',
      cancelText: 'キャンセル',
      okType: 'danger',
      maskClosable: true,
    });
  };

  return (
    <Wrapper>
      <GroupDetail>
        <Name>{group.name}</Name>
        <Comment>{`${group.members.length}名のメンバー`}</Comment>
      </GroupDetail>
      <Tooltip title="権限がありません" visible={!isAdmin}>
        <ButtonWrapper>
          <Button onClick={() => onEdit(group)} disabled={!isAdmin}>
            編集
          </Button>
        </ButtonWrapper>
      </Tooltip>
      <Tooltip title="権限がありません" visible={!isAdmin}>
        <ButtonWrapper>
          <Button type="danger" onClick={onClickDelete} disabled={!isAdmin}>
            削除
          </Button>
        </ButtonWrapper>
      </Tooltip>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 450px;
  margin-top: 16px;
  display: flex;
  align-items: center;
`;

const GroupDetail = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
`;

const Name = styled.div`
  font-size: 13px;
  font-weight: bold;
  color: ${color.common.text2};
`;

const ButtonWrapper = styled.div`
  margin-left: 12px;
`;

const Button = styled(DefaultButton)`
  height: 40px;
  width: 100px;
`;
