import {
  AutoComplete,
  Button,
  Icon,
  Input,
  message,
  Tag,
  Typography,
} from 'antd';
import { emailText, extractEmail, extractName, validateEmail } from 'lib';
import { inject, observer } from 'mobx-react';
import { TweenOneGroup } from 'rc-tween-one';
import React, { Component } from 'react';
import { compose } from 'recompose';
import styled from 'styled-components';
import { maxRecipientsCount } from '../../../shared/constants';
import { highlight, isBackspaceOrDelete, isEnter } from '../../../util';
import { MultipleCopy } from '../../Common/MultipleCopy';
import { PCTooltip } from '../../Common/Tooltip';
import { parseEmailAddresses, extractLabelFromAddress } from './email';

const { Option } = AutoComplete;

const { Text } = Typography;

const Address = (props) => {
  // ドラッグ可能な場合、ドラッグによるテキストコピーの動作は不可能になるのでMultipleCopyを外している。
  if (props.draggable) {
    return (
      <div
        draggable={props.draggable}
        onDragStart={props.onDragStart}
        onDoubleClick={props.onEdit}
      >
        <Tag
          closable={props.closable}
          onClose={props.onClose}
          className="address_string my-1 whitespace-break-spaces break-all border-sumi-100 bg-sumi-100"
        >
          {props.address}
        </Tag>
      </div>
    );
  }

  return (
    <MultipleCopy style={{ display: 'inline-block' }}>
      <Tag
        closable={props.closable}
        onClose={props.onClose}
        className="address_string my-1 whitespace-break-spaces break-all border-sumi-100 bg-sumi-100"
      >
        {props.address}
      </Tag>
    </MultipleCopy>
  );
};

const AddressInput = (props) => {
  return (
    <AutoComplete
      ref={props.setInputRef}
      value={props.value}
      dataSource={props.dataSource}
      size="small"
      onSelect={props.onSelect}
      onSearch={props.onSearch}
      onChange={props.onChange}
      onBlur={props.onBlur}
      disabled={props.disabled}
    >
      <ExInput onKeyDown={props.onKeyDown} onPaste={props.onPaste} />
    </AutoComplete>
  );
};

class EditableTagGroup extends Component {
  constructor(props) {
    super(props);
    this._valueConfirmed = false;
  }

  componentDidMount() {
    const { autoFocus } = this.props;
    if (autoFocus) {
      this.showNewInput();
    }
  }

  state = {
    // tags: ['Tag 1', 'Tag 2', 'Tag 3'],
    tags: this.props.values,
    visibleInputType: null, // type = null | 'new' | 'edit'
    inputIndex: null, // tag's index
    inputValue: '',
    searchedContacts: [],
    showAll: false,
  };

  handleClose = (removedTag) => {
    const tags = this.props.values.filter((tag) => tag !== removedTag);
    this.props.setValues(tags);
  };

  showNewInput = () => {
    this.setState({ visibleInputType: 'new' }, () => this.newInput.focus());
  };

  showEditInput = () => {
    this.setState({ visibleInputType: 'edit' }, () => this.editInput.focus());
  };

  onBlur = (value) => {
    const { visibleInputType, inputIndex } = this.state;

    if (visibleInputType === 'new') {
      this.handleNewInputConfirm(value, false);
    }

    if (visibleInputType === 'edit' && inputIndex !== null) {
      this.handleEditInputConfirm(inputIndex, value, false);
    }
  };

  onPaste = (e) => {
    const { visibleInputType } = this.state;
    if (visibleInputType === 'edit') return;

    const pasteText = (e.clipboardData || window.clipboardData).getData('text');
    const pasteSplitTexts = parseEmailAddresses(pasteText.trim());
    if (pasteSplitTexts.length < 2) return;

    e.preventDefault();

    // マルチコピーだった場合の処理
    const { values, isDuplicatedRecipients, getRecipientsLength } = this.props;
    let newValues = values;

    for (const splitText of pasteSplitTexts) {
      // @NOTE 前後の空白は無視するようtrimしています
      const email = extractEmail(splitText).trim();

      if (!validateEmail(email)) {
        message.error('メールアドレスの形式が不正です');
        return;
      }

      if (isDuplicatedRecipients(email)) {
        message.error('To, Cc, Bcc内で同じメールアドレスは指定できません');
        return;
      }

      if (getRecipientsLength() >= maxRecipientsCount) {
        message.error(`To, Cc, Bccの宛先は最大${maxRecipientsCount}件です`);
        return;
      }

      newValues = [...newValues, emailText(extractName(splitText), email)];
    }

    this.props.setValues(newValues);
    this.setState(
      {
        visibleInputType: null,
        inputIndex: null,
        inputValue: '',
        showAll: true,
      },
      () => this.showNewInput()
    );
  };

  handleNewInputConfirm = (value, pressEnter) => {
    const { values, isDuplicatedRecipients, getRecipientsLength } = this.props;
    let newValues = values;

    // @NOTE 前後の空白は無視するようtrimしています
    const email = extractEmail(value).trim();

    if (email && values.indexOf(email) === -1) {
      if (validateEmail(email)) {
        if (isDuplicatedRecipients(email)) {
          message.error('To, Cc, Bcc内で同じメールアドレスは指定できません');
        } else if (getRecipientsLength() >= maxRecipientsCount) {
          message.error(`To, Cc, Bccの宛先は最大${maxRecipientsCount}件です`);
        } else {
          newValues = [...values, emailText(extractName(value), email)];
        }
      } else {
        message.error('メールアドレスの形式が不正です');
      }
    }

    this.props.setValues(newValues);
    this.setState(
      {
        newInputVisible: false,
        inputIndex: null,
        inputValue: '',
        showAll: true, // 追加された場合、全て表示する
      },
      () => {
        if (value.trim().length === 0) {
          // 空文字の状態でEnterが押された場合、次にfocusする
          if (pressEnter && this.props.onEnterEmptyValue)
            this.props.onEnterEmptyValue();
        }
        if (newValues.length > values.length) this.showNewInput(); // 追加された場合は次のInputへ
      }
    );
  };

  handleEditInputConfirm = (index, value, pressEnter) => {
    const { values, isDuplicatedOtherRecipients } = this.props;
    let newValues = values;

    // @NOTE 前後の空白は無視するようtrimしています
    const email = extractEmail(value).trim();

    if (email && values.indexOf(email) === -1) {
      if (validateEmail(email)) {
        if (isDuplicatedOtherRecipients(email)) {
          message.error('To, Cc, Bcc内で同じメールアドレスは指定できません');
        } else {
          newValues.splice(index, 1, emailText(extractName(value), email));
        }
      } else {
        message.error('メールアドレスの形式が不正です');
      }
    }

    this.props.setValues(newValues);
    this.setState({
      newInputVisible: false,
      inputIndex: null,
      inputValue: '',
      showAll: true, // 追加された場合、全て表示する
    });
  };

  saveNewInputRef = (input) => (this.newInput = input);

  saveEditInputRef = (input) => (this.editInput = input);

  forMap = (tag, index) => {
    const { disabled, droppableId, droppable, onDragStart } = this.props;
    const { inputIndex, inputValue } = this.state;

    if (inputIndex === index) {
      return (
        <div key={tag}>
          <AddressInput
            inputRef={this.editInput}
            setInputRef={this.saveEditInputRef}
            value={inputValue}
            dataSource={this.getOptions()}
            onSelect={this.onSelect}
            onSearch={this.onSearch}
            onChange={this.onChange}
            onBlur={this.onBlur}
            disabled={disabled}
            onKeyDown={this.onKeyDown}
          />
        </div>
      );
    }

    return (
      <div key={tag}>
        <Address
          draggable={droppable}
          onDragStart={onDragStart.bind(null, {
            source: droppableId,
            address: tag,
          })}
          address={extractLabelFromAddress(tag)}
          closable={!disabled}
          onClose={(e) => {
            e.preventDefault();
            this.handleClose(tag);
          }}
          onEdit={(e) => {
            this.setState(
              {
                visibleInputType: 'edit',
                inputIndex: index,
                inputValue: tag,
              },
              () => this.showEditInput()
            );
            e.preventDefault();
          }}
        />
      </div>
    );
  };

  onSelect = (inputValue) => {
    const { visibleInputType, inputIndex } = this.state;
    // MEMO: onSelect => onKeydownの順番で関数が発火するが、onSelectをした場合にはonKeydownのEnterを発火させたくない。
    this._valueConfirmed = true;

    if (visibleInputType === 'new') {
      this.handleNewInputConfirm(inputValue, true);
    }

    if (visibleInputType === 'edit' && inputIndex !== null) {
      this.handleEditInputConfirm(inputIndex, inputValue, true);
    }
  };

  onSearch = async (searchText) => {
    if (!searchText) {
      this.setState({ searchedContacts: [] });
      return;
    }

    const searchedContacts = await this.props.store.contactStore.autoComplete(
      this.props.teamId,
      searchText,
      10
    );

    this.setState({ searchedContacts });
  };

  onChange = (inputValue) => {
    this.setState({ inputValue });
  };

  onKeyDown = (e) => {
    const { visibleInputType, inputIndex, inputValue } = this.state;
    const { values } = this.props;
    if (
      !inputValue &&
      values.length > 0 &&
      isBackspaceOrDelete(e) &&
      visibleInputType === 'new'
    ) {
      // 空文字でbackspace or deleteが押された場合、末尾のvaluesを削除する
      this.handleClose(values[values.length - 1]);
      this.setState({ showAll: true }); // 削除された場合、全て表示する
    }

    if (isEnter(e) && !this._valueConfirmed && visibleInputType === 'new') {
      // Enterかつまだ確定していない場合
      this.handleNewInputConfirm(inputValue, true);
    }

    if (
      isEnter(e) &&
      !this._valueConfirmed &&
      visibleInputType === 'edit' &&
      inputIndex !== null
    ) {
      // Enterかつまだ確定していない場合
      this.handleEditInputConfirm(inputIndex, inputValue, true);
    }

    // valueConfirmedをリセットする
    this._valueConfirmed = false;
  };

  getOptions = () =>
    this.state.searchedContacts.map((c) => (
      <Option value={c.emailText} key={c.id}>
        {/* AutoCompleteのテキスト入力中にvalueに完全一致した場合、childrenの値が自動で代入されるので一時的にプレーンテキストにしている */}
        {this.state.inputValue === c.emailText ? (
          c.emailText
        ) : (
          <>
            <div>
              {highlight(c.name, this.state.inputValue)}
              {' <'}
              {highlight(c.email, this.state.inputValue)}
              {'>'}
            </div>
            <div>
              <Text type="secondary">
                {highlight(c.companyName, this.state.inputValue)}{' '}
              </Text>
              {c.tags.map((t) => (
                <Tag key={t}>{highlight(t, this.state.inputValue)}</Tag>
              ))}
            </div>
          </>
        )}
      </Option>
    ));

  showAll = () => {
    this.setState({ showAll: true });
  };

  render() {
    const { visibleInputType, inputValue, showAll } = this.state;
    const { values, disabled, droppableId, onDragEnd } = this.props;
    const tagChild = values.map(this.forMap);

    return (
      <div
        onDrop={onDragEnd.bind(null, droppableId)}
        className="flex w-full flex-wrap"
      >
        <div>
          <TweenOneGroup
            enter={{
              scale: 0.8,
              opacity: 0,
              type: 'from',
              duration: 100,
              onComplete: (e) => {
                e.targets.children[e.index].style = '';
              },
            }}
            leave={{ opacity: 0, width: 0, scale: 0, duration: 200 }}
            appear={false}
            style={{ display: 'flex', flexWrap: 'wrap' }}
          >
            {showAll ? tagChild : tagChild.slice(0, 5)}
            {tagChild.length > 5 && !showAll && (
              <PCTooltip
                placement="left"
                title={values.slice(5).join('\n')}
                overlayStyle={{
                  maxHeight: 'calc(100vh - 200px)',
                  overflow: 'scroll',
                }}
              >
                <Button
                  type="link"
                  size="small"
                  style={{ padding: 0 }}
                  onClick={this.showAll}
                >
                  他{tagChild.length - 5}件
                </Button>
              </PCTooltip>
            )}
          </TweenOneGroup>
        </div>
        {visibleInputType === 'new' && (
          <AddressInput
            inputRef={this.newInput}
            setInputRef={this.saveNewInputRef}
            value={inputValue}
            dataSource={this.getOptions()}
            onSelect={this.onSelect}
            onSearch={this.onSearch}
            onChange={this.onChange}
            onBlur={this.onBlur}
            onPaste={this.onPaste}
            disabled={disabled}
            onKeyDown={this.onKeyDown}
          />
        )}
        {visibleInputType === null && !disabled && (
          <Tag
            onClick={this.showNewInput}
            style={{
              background: '#fff',
              borderStyle: 'dashed',
              whiteSpace: 'break-spaces',
            }}
            className="add_mail_address my-1"
          >
            <Icon type="plus" /> 追加
          </Tag>
        )}
      </div>
    );
  }
}

export default compose(inject('store'), observer)(EditableTagGroup);

const ExInput = styled(Input)`
  padding: 0 !important;
  border: none !important;

  :focus {
    box-shadow: none !important;
  }
`;
