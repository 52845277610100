import React from 'react';
import styled from 'styled-components';
import { Icon } from 'antd';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const PreviewImageModal = ({ open, onClose, url, onDownload }) => {
  if (!open) {
    return <></>;
  }

  return (
    <Lightbox
      mainSrc={url}
      onCloseRequest={onClose}
      toolbarButtons={[
        onDownload && (
          <PreviewImageButton onClick={onDownload}>
            <Icon
              type="download"
              style={{ fontSize: 24, verticalAlign: 'initial' }}
            />
          </PreviewImageButton>
        ),
      ]}
    />
  );
};

const PreviewImageButton = styled.button`
  width: 40px;
  height: 35px;
  cursor: pointer;
  border: none;
  opacity: 0.7;
  padding: 1px 6px;
  vertical-align: middle;
  background-color: transparent;
  &:hover {
    opacity: 1;
  }
`;

export default PreviewImageModal;

export const isPreviewableImageMimeType = (mimeType = '') =>
  mimeType.startsWith('image/') && mimeType !== 'image/tiff';
