import * as React from 'react';
import type { SVGProps } from 'react';
const SvgFormatClear = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M12.873 10.158 11.24 8.525l.394-.952h-1.307a4.166 4.166 0 0 1-.51-.451 7.581 7.581 0 0 1-.44-.5c-.195-.226-.228-.48-.098-.76s.357-.42.678-.42H18.5c.294 0 .544.103.75.308.205.205.307.455.307.75s-.102.545-.307.75a1.02 1.02 0 0 1-.75.308h-4.522l-1.105 2.6Zm6.169 10.992-7.61-7.62-1.869 4.41c-.085.2-.216.36-.394.478a1.046 1.046 0 0 1-.592.178c-.38 0-.675-.16-.885-.478a1.003 1.003 0 0 1-.087-1.007l2.21-5.198-7.061-7.052a.724.724 0 0 1-.213-.522.706.706 0 0 1 .213-.531.717.717 0 0 1 .526-.218c.207 0 .383.073.527.218l16.289 16.288c.138.138.209.312.212.522a.706.706 0 0 1-.212.532.717.717 0 0 1-.527.217.717.717 0 0 1-.527-.217Z"
    />
  </svg>
);
export default SvgFormatClear;
