import { observer } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';
import {
  UserSlackNotification,
  UserChatworkNotification,
  IntegrationRoutes,
} from '../integration';

type Props = {
  integrationRoutes: IntegrationRoutes;
};

export const MyNotification: React.FC<Props> = observer(
  ({ integrationRoutes }) => {
    return (
      <Wrapper>
        <div className="destination">
          <UserSlackNotification />
        </div>
        <div className="destination">
          <UserChatworkNotification integrationRoutes={integrationRoutes} />
        </div>
      </Wrapper>
    );
  }
);

const Wrapper = styled.div`
  & > .destination {
    margin-bottom: 32px;
  }
`;
