import React, { VFC } from 'react';
import {
  SidebarCommonButton,
  SidebarCommonButtonProps,
} from '../../../../components/sidebar/SidebarCommonButton/SidebarCommonButton';

type Props = SidebarCommonButtonProps & {
  onClick: () => void;
};

export const LeftSidebarButton: VFC<Props> = ({ onClick, ...props }) => {
  return <SidebarCommonButton onClick={() => onClick()} {...props} />;
};
