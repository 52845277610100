import { makeAutoObservable } from 'mobx';
import { Store } from 'store';
import {
  ChatworkIntegrationStore,
  IChatworkIntegrationStore,
} from './chatwork';

export interface IIntegrationStore {
  chatworkStore: IChatworkIntegrationStore;
  isSlackNotificationV2Supported: boolean;

  init(): void;
  unsubscribeSyncs(): void;
}

export class IntegrationStore implements IIntegrationStore {
  chatworkStore: ChatworkIntegrationStore;

  // TODO: 循環参照避ける
  constructor(private rootStore: Store) {
    makeAutoObservable(this);
    this.chatworkStore = new ChatworkIntegrationStore(this.rootStore, this);
  }

  init(): void {
    this.chatworkStore.syncIntegrations();
  }

  unsubscribeSyncs(): void {
    this.chatworkStore.unsubscribeSyncs();
  }

  get isV2Plan(): boolean {
    return this.rootStore.isV2Plan;
  }

  get isSlackNotificationV2Supported(): boolean {
    return (
      this.rootStore.stripeProduct?.metadata?.slackNotificationSupported ===
      'true'
    );
  }
}
