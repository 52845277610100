import { User } from 'lib';
import ExAvatar from '../../../Common/Avatar';
import { Avatar } from 'antd';
import { ReactNode } from 'react';

type Props = {
  user: User | undefined;
  iconName: string;
  contactElement: ReactNode;
  recipientElement: ReactNode;
};

export const HeaderContactBase = ({
  user,
  iconName,
  contactElement,
  recipientElement,
}: Props) => {
  return (
    <div
      className={
        'grid w-full min-w-[40px] max-w-full grid-cols-[auto_1fr] gap-2 overflow-hidden'
      }
    >
      <div className={'w-[40px]'}>
        {user ? (
          <ExAvatar user={user} />
        ) : (
          <Avatar size="large">{iconName}</Avatar>
        )}
      </div>
      <div className={'mt-[-1px] flex flex-col items-start overflow-hidden'}>
        {contactElement}
        <div className={'max-w-full'}>{recipientElement}</div>
      </div>
    </div>
  );
};
