// Create a promise, and return the promise with its resolve/reject functions.

export interface Promised<T> {
  done: boolean;
  resolve: (value: T) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  reject: (reason: any) => void;
  promise: Promise<T>;
}

export const createPromised = <T>(): Promised<T> => {
  const promised: Promised<T> = {
    done: false,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    resolve: null!,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    reject: null!,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    promise: null!,
  };

  promised.promise = new Promise<T>((resolve, reject) => {
    promised.resolve = resolve;
    promised.reject = reject;
  }).finally(() => (promised.done = true));

  return promised;
};
