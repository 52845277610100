import { Comment as LibComment, commentConverter } from 'lib';
import { Comment } from '../../../../components/comment/Comment/Comment';
import { useAtomValue } from 'jotai';
import { usersAtom } from '../../../../atoms/firestore/user';
import { ComponentProps, useEffect, useMemo, useState } from 'react';
import { meAtom } from '../../../../atoms/auth';
import { companyCollection, companyDoc } from '../../../../firestore';
import {
  arrayRemove,
  arrayUnion,
  deleteDoc,
  getDoc,
  onSnapshot,
  runTransaction,
  serverTimestamp,
  setDoc,
  updateDoc,
} from 'firebase/firestore';
import { reactionsConverter } from 'lib/dist/entity/reactions';
import { uniq } from 'lodash';
import { message } from 'antd';
import { openOrDownloadAttachment } from '../../../../util';
import { db9, storage } from '../../../../firebase';
import { deleteObject, ref } from 'firebase/storage';
import {
  signInCompanyStripeProductAtom,
  signInCompanyStripeProductLoadingAtom,
} from '../../../../atoms/firestore/signInCompanyStripeProduct';
import { groupsAtom } from '../../../../atoms/firestore/group';
import { useUpdateCommentInputReply } from '../../../../components/comment/CommentInput/CommentInputReplyProvider';

type Props = {
  comment: LibComment;
  highlight?: boolean;
};

export const ConversationCommentWithLogic = ({ comment, highlight }: Props) => {
  const me = useAtomValue(meAtom);
  const users = useAtomValue(usersAtom);
  const featuresLoading = useAtomValue(signInCompanyStripeProductLoadingAtom);
  const product = useAtomValue(signInCompanyStripeProductAtom);
  const groups = useAtomValue(groupsAtom);
  const updateReplyData = useUpdateCommentInputReply();
  const reply = useReplyData(comment.replyTo);
  const [reactions, setReactions] = useState<Record<string, string[]>>({});
  const user: NonNullable<ComponentProps<typeof Comment>['user']> =
    useMemo(() => {
      const user = users.find((u) => u.id === comment.commenter);
      if (!user) {
        return {
          iconBackgroundColor: '#cccccc',
        };
      }
      return {
        id: user.id,
        name: user.name,
        iconName: user.iconName,
        iconBackgroundColor: user.iconBackgroundColor,
        avatarURL: user.avatarURL,
      };
    }, [users, comment.commenter]);

  useEffect(() => {
    if (comment.unreadUsers?.includes(me.id)) {
      // Mark as read.
      updateDoc(companyDoc('comments', comment.id), {
        unreadUsers: arrayRemove(me.id),
        updatedAt: serverTimestamp(),
      }).then();
    }
  }, []);

  useEffect(() => {
    const unsubscribe = onSnapshot(
      companyCollection(`comments/${comment.id}/reactions`, reactionsConverter),
      (snapshot) => {
        const reactionsArray = snapshot.docs.map((d) => d.data());
        const allEmojis = uniq(reactionsArray.flatMap((r) => r.emojis));
        const convertedReactions = Object.fromEntries(
          allEmojis
            .map((emoji) => ({
              emoji,
              users: reactionsArray
                .filter((r) => r.emojis.includes(emoji))
                .map((r) => r.id),
            }))
            .map((r) => [r.emoji, r.users])
        );
        setReactions(convertedReactions);
      }
    );
    return () => unsubscribe();
  }, [comment, users]);

  const onToggleEmoji = async (emoji: string) => {
    const doc = companyDoc(`comments/${comment.id}/reactions`, me.id);
    if (reactions[emoji]?.includes(me.id)) {
      await updateDoc(doc, {
        emojis: arrayRemove(emoji),
        updatedAt: serverTimestamp(),
      });
    } else {
      const d = await getDoc(doc);
      if (d.exists()) {
        await updateDoc(doc, {
          emojis: arrayUnion(emoji),
          updatedAt: serverTimestamp(),
        });
      } else {
        await setDoc(doc, {
          emojis: arrayUnion(emoji),
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
        });
      }
    }
  };

  const onEditContent = async (text: string) => {
    await updateDoc(companyDoc('comments', comment.id), {
      text,
      edited: true,
      updatedAt: serverTimestamp(),
    });
  };

  const onDelete = async () => {
    try {
      const doc = companyDoc('comments', comment.id);
      await deleteDoc(doc);
      message.success('コメントを削除しました');
      return true;
    } catch (e) {
      message.error('コメントの削除中にエラーが発生しました');
      console.error(e);
      return false;
    }
  };

  const reactionSupportStatus = useMemo(() => {
    if (featuresLoading) {
      return 'loading';
    }
    return product?.messageReactionSupported ? 'supported' : 'unsupported';
  }, [featuresLoading, product]);

  const replySupportStatus = useMemo(() => {
    if (featuresLoading) {
      return 'loading';
    }
    return product?.messageReplySupported ? 'supported' : 'unsupported';
  }, [featuresLoading, product]);

  const onDownloadAttachment = async (id: string) => {
    const attachment = comment.attachments.find((a) => a.storagePath === id);
    if (!attachment) {
      return;
    }

    await openOrDownloadAttachment(attachment, true);
  };
  const onRemoveAttachment = async (id: string) => {
    const attachment = comment.attachments.find((a) => a.storagePath === id);
    if (!attachment) {
      return;
    }

    try {
      await runTransaction(db9, async (tx) => {
        tx.update(comment.ref, {
          attachments: comment.attachments.filter(
            (a) => a.storagePath !== attachment.storagePath
          ),
        });
        await deleteObject(ref(storage, attachment.storagePath));
      });
    } catch (e) {
      console.error(e);
      message.error('ファイルの削除に失敗しました');
    }
  };

  const replyMentionUserIds =
    typeof reply === 'object' ? reply.mentionedUsers : [];
  const mentionTargetUsers = [...comment.mentionedUsers, ...replyMentionUserIds]
    .map((userId) => users.find((u) => u.id === userId)?.name)
    .filter((name) => name) as string[];
  const mentionTargetGroups = groups.map((g) => g.name);

  return (
    <Comment
      id={comment.id}
      user={user}
      content={comment.text}
      timestamp={comment.createdAt.toDate()}
      mentionTargets={uniq([...mentionTargetUsers, ...mentionTargetGroups])}
      reply={reply}
      onReply={() =>
        updateReplyData({
          id: comment.id,
          name: user.name ?? '削除されたユーザー',
          content: comment.text,
          mentionUsers: comment.mentionedUsers,
        })
      }
      onToggleEmoji={onToggleEmoji}
      onEditContent={onEditContent}
      onDelete={onDelete}
      reactionSupportStatus={reactionSupportStatus}
      replySupportStatus={replySupportStatus}
      isAuthor={comment.commenter === me.id}
      highlight={highlight}
      currentUser={{ id: me.id, name: me.name }}
      reactions={Object.fromEntries(
        Object.entries(reactions).map(([emoji, userIds]) => [
          emoji,
          userIds.map((id) => ({
            id,
            name: users.find((u) => u.id === id)?.name ?? '',
          })),
        ])
      )}
      attachments={comment.attachments.map((a) => ({
        id: a.storagePath,
        name: a.filename,
      }))}
      onDownloadAttachment={onDownloadAttachment}
      onRemoveAttachment={onRemoveAttachment}
      isEdited={comment.edited ?? false}
      readonly={me.isReadOnly}
      collisionBoundary={document.getElementById('ConversationContainer')}
    />
  );
};

const useReplyData = (replyId: string | undefined) => {
  const users = useAtomValue(usersAtom);
  const [reply, setReply] = useState<ComponentProps<typeof Comment>['reply']>(
    replyId ? 'loading' : undefined
  );
  useEffect(() => {
    if (!replyId) {
      return;
    }
    const unsubscribe = onSnapshot(
      companyDoc('comments', replyId, commentConverter),
      (snap) => {
        if (!snap.exists()) {
          setReply('deleted');
          return;
        }
        const data = snap.data();
        const name =
          users.find((u) => u.id === data.commenter)?.name ?? '不明なユーザー';

        setReply({
          name,
          content: data.text,
          mentionedUsers: data.mentionedUsers,
        });
      }
    );
    return () => unsubscribe();
  }, [replyId, users]);
  return reply;
};
