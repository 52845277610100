import { Timestamp } from 'firebase/firestore';
import { createConverter, createEntityFactory } from '../converter';

export type LockData = {
  teamId: string;
  inboxId: string;
  messageId: string;
  locker: string;
  createdAt: Timestamp;
  updatedAt: Timestamp;
};

export const createLock = createEntityFactory<'Lock', LockData>('Lock');

export type Lock = ReturnType<typeof createLock>;

export const lockConverter = createConverter(createLock);
