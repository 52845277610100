import { useEffect, useRef } from 'react';
import styled from 'styled-components';
import * as color from '../../../color';
import { DefaultButton } from '../../Common/Button';

export const Wrapper = styled.div`
  display: flex;
  height: 100%;

  div:only-child {
    // 新規作成や更新画面が出ていない場合
    max-width: 100%;
  }
`;

export const List = styled.div`
  flex: 1;
  padding: 32px 16px 32px 32px;
  max-width: 100%;
  overflow: auto;

  z-index: 0;
`;

export const Detail = styled.div`
  padding: 32px 32px 32px 16px;
  background: #f6f6f7;
  position: absolute;
  right: 0;
  z-index: 20;
  width: 37%;
  overflow: auto;
  top: 0;
  bottom: 0;
`;

export const AddButton = styled(DefaultButton)`
  height: 40px;
  width: 100%;
  max-width: 100px;
  margin-bottom: 32px;
`;

/*
  @NOTE:
  disableの場合Tooltipが正常に動作しないため
  別途かませて使用するWrapper
  参考: https://github.com/react-component/tooltip/issues/18#issuecomment-411476678
*/
export const ButtonWrapper = styled.div`
  height: 40px;
  width: 100%;
  max-width: 100px;
  margin-right: 16px;
`;

// ButtonWrapperのAddButtonバージョン
export const AddButtonWrapper = styled(ButtonWrapper)`
  margin-bottom: 32px;
`;

export const ShowButton = styled(AddButton)`
  margin-bottom: 0;
`;

export const Item = styled.div`
  width: 350px;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ItemName = styled.div`
  font-size: 16px;
  color: ${color.common.text2};
`;

export function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export * from './router';
export * from './SettingFields';
export * from './settingObjectRoutes';
