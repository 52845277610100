import JSZip from 'jszip';

export class ZipWriter {
  _files = new Map();

  _genUniqueFilename(name, n = 0) {
    const newName = `${name}.${n}`;
    if (this._files.has(newName)) {
      return this._genUniqueFilename(name, n + 1);
    }
    return newName;
  }

  /**
   * Windows のファイル名に使用できない文字をエスケープ
   * Mac や Linux より Windows の方がファイル名の制限が厳しいため、Windows に合わせる
   */
  _restrictFileName = (name) =>
    name.replace(/[\\/:*?"<>|]/g, (c) => '%' + c.charCodeAt(0).toString(16));

  addEntry(name, url) {
    name = this._restrictFileName(name);
    if (this._files.has(name)) {
      name = this._genUniqueFilename(name);
    }
    this._files.set(name, url);
  }

  async getBlob(suffix = '') {
    const zip = new JSZip();

    await Promise.all(
      [...this._files].map(async ([name, url]) => {
        const content = await (await fetch(url)).blob();
        zip.file(`${name}${suffix}`, content);
      })
    );

    return zip.generateAsync({ type: 'blob' });
  }
}
