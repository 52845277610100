import { Impression } from 'lib';
import { ComponentType, SVGProps, useMemo } from 'react';
import { Icon } from '../../../../../../components/basics';
import { Fire } from '../../../../../../components/icons';

type Props = {
  impression?: Impression;
};

export const ImpressionIcon = ({
  impression = 'neutral',
}: Props): JSX.Element | null => {
  return useMemo(() => {
    let icon: ComponentType<SVGProps<SVGSVGElement>> | null = null;
    switch (impression) {
      case 'complaint':
        icon = Fire;
    }
    if (!icon) {
      return null;
    }
    return (
      <Icon
        icon={icon}
        size={18}
        className="text-sun-500"
        style={{
          minWidth: 18,
          minHeight: 18,
        }}
      />
    );
  }, [impression]);
};
