import { estimateServerTimestampsOption } from './common';
import moment from 'moment';
import firebase from 'firebase/compat/app';

export interface InvitationData {
  email: string;
  expiredAt: firebase.firestore.Timestamp;
}

export class Invitation {
  private _id: string;
  private _data: InvitationData;
  private _ref: firebase.firestore.DocumentReference<InvitationData>;
  private _expiredAt: moment.Moment;

  constructor(doc: firebase.firestore.DocumentSnapshot<InvitationData>) {
    this._id = doc.id;
    const data = doc.data(estimateServerTimestampsOption);
    if (!data) {
      throw new Error(`no data for invitation#${doc.id}`);
    }
    this._data = data;
    this._ref = doc.ref;
    this._expiredAt = moment(this._data.expiredAt.toMillis()); // 毎回生成するのはコストがかかるため
  }

  get id() {
    return this._id;
  }

  get ref() {
    return this._ref;
  }

  get email() {
    return this._data.email;
  }

  get expiredAt() {
    return this._expiredAt;
  }

  get isExpired() {
    return moment().isAfter(this.expiredAt);
  }
}
